import {APP_SEARCH_ENDPOINT} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function search(query, mapId, searchApiKey){
    const sendData = {
        query: query
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchArea(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            type: 'area'
        },
        search_fields: {
            name: {}
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchContentItem(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            type: 'content_item'
        },
        search_fields: {
            name: {},
            notes: {}
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchOnMap(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            type: 'content_item'
        },
        page: {
            size: 8
        },
        search_fields: {
            name: {}
        },
        result_fields: {
            name: {
                raw: {}
            },
            id: {
                raw: {}
            },
            zlevel2: {
                raw: {}
            },
            x: {
                raw: {}
            },
            y: {
                raw: {}
            },
            icon_code: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchContentItemForAssociation(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            type: 'content_item'
        },
        page: {
            size: 5
        },
        search_fields: {
            name: {}
        },
        result_fields: {
            name: {
                raw: {}
            },
            id: {
                raw: {}
            },
            item_type: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchContactForAssociation(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            all: [
                {type: 'content_item'},
                {item_type: 'CONTACT'}
            ]
        },
        page: {
            size: 5
        },
        search_fields: {
            name: {}
        },
        result_fields: {
            name: {
                raw: {}
            },
            id: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchCompanyForAssociation(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            all: [
                {type: 'content_item'},
                {item_type: 'COMPANY'}
            ]
        },
        page: {
            size: 5
        },
        search_fields: {
            name: {}
        },
        result_fields: {
            name: {
                raw: {}
            },
            id: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchContactItemForAssociation(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            all: [
                {type: 'content_item'},
                {item_type: 'CONTACT'}
            ]
        },
        search_fields: {
            name: {}
        },
        result_fields: {
            name: {
                raw: {}
            },
            id: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchCompanyItemForAssociation(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            all: [
                {type: 'content_item'},
                {item_type: 'COMPANY'}
            ]
        },
        search_fields: {
            name: {}
        },
        result_fields: {
            name: {
                raw: {}
            },
            id: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchOverlay(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            type: 'overlay'
        },
        search_fields: {
            name: {}
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchOwner(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            type: 'content_item'
        },
        search_fields: {
            owner: {}
        },
        result_fields: {
            owner: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 */
function searchTags(query, mapId, searchApiKey){
    const sendData = {
        query: query,
        filters: {
            type: 'content_item_tag'
        },
        search_fields: {
            name: {}
        },
        result_fields: {
            name: {
                raw: {}
            }
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

/**
 * @param {string} query
 * @param {string} mapId
 * @param {string} searchApiKey
 * @param {array} selectedOverlay
 * @param {array} selectedOwner
 * @param {array} selectedTags
 * @param {object} filters
 */
function searchItemWithFilter(query, mapId, searchApiKey, selectedOverlay, selectedOwner, selectedTags, filters){
    const sendDataFilters = [
        {type: 'content_item'}
    ]

    //Add creation date filter
    if (filters['creationDateStart'] !== "" && filters['creationDateEnd'] !== ""){
        const startDate = new Date(filters['creationDateStart'])
        const endDate = new Date(filters['creationDateEnd'])
        const filter = {
            creation_date: {
                from: startDate.toISOString(),
                to: endDate.toISOString()
            }
        }

        sendDataFilters.push(filter)
    }

    //Add last update date filter
    if (filters['lastUpdateDateStart'] !== "" && filters['lastUpdateDateEnd'] !== ""){
        const startDate = new Date(filters['lastUpdateDateStart'])
        const endDate = new Date(filters['lastUpdateDateEnd'])
        const filter = {
            last_update: {
                from: startDate.toISOString(),
                to: endDate.toISOString()
            }
        }

        sendDataFilters.push(filter)
    }

    //Add category filter
    if (filters['category'] !== ""){
        const filter = {item_category: filters['category']}
        sendDataFilters.push(filter)
    }

    //Add type filter
    if (filters['type'] !== ""){
        const filter = {item_type: filters['type']}
        sendDataFilters.push(filter)
    }

    //Add overlay filter
    if (selectedOverlay.length > 0){
        const overlaysId = []
        for (let i = 0; i < selectedOverlay.length; i++){
            overlaysId.push(selectedOverlay[i].id)
        }

        const filter = {overlay: overlaysId}
        sendDataFilters.push(filter)
    }

    //Add owner filter
    if (selectedOwner.length > 0){
        const filter = {owner: selectedOwner}
        sendDataFilters.push(filter)
    }

    //Add tag filter
    if (selectedTags.length > 0){
        const filter = {tags: selectedTags}
        sendDataFilters.push(filter)
    }

    //Add color filter
    if (filters['color'] !== ""){
        const filter = {color: filters['color']}
        sendDataFilters.push(filter)
    }

    const minZoomFilter = {min_zoom: {from: filters['minZoomStart'], to: filters['minZoomEnd']}}
    sendDataFilters.push(minZoomFilter)

    const maxZoomFilter = {max_zoom: {from: filters['maxZoomStart'], to: filters['maxZoomEnd']}}
    sendDataFilters.push(maxZoomFilter)

    const sizeFilter = {size: {from: filters['sizeMin'], to: filters['sizeMax']}}
    sendDataFilters.push(sizeFilter)

    const sendData = {
        'query': query,
        'filters': {
            'all': sendDataFilters
        },
        'search_fields': {
            name: {},
            notes: {}
        }
    }

    //Execute search query to app search
    return getAxiosInstance().post(APP_SEARCH_ENDPOINT + "/engines/" + mapId.toLowerCase() + "/search", sendData, {
        headers: {
            "Authorization": "Bearer " + searchApiKey
        }
    })
}

export default {
    search,
    searchArea,
    searchContentItem,
    searchOverlay,
    searchItemWithFilter,
    searchOwner,
    searchTags,
    searchContentItemForAssociation,
    searchOnMap,
    searchContactForAssociation,
    searchCompanyForAssociation
}