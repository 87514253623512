import {useSidebarMapContentItemStore} from "../Stores/SidebarMapContentItemStore";
import {useContentItemStore} from "../Stores/ContentItemStore";
import {renderLevel2Items, updateLevel2Content} from "./RenderLevel2Utils";
import {useMapStore} from "../Stores";
import RefreshTilesUtils from "../RefreshTilesUtils";
import L from "leaflet";

export function updateMapLocation(bounds){
	console.log("update", bounds)
	//Update map layer
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_items', bounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_level2_items_source', bounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_shapes', bounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_areas', bounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_area_labels', bounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_text_items', bounds, true).then()

			setTimeout(() => {
				renderLevel2Items()
			}, 2000)
		}
	})
}

export function globalUpdateMapContentItemName(uuid, name, currentLocation = null){
	//Check if sidebar is open
	if (useSidebarMapContentItemStore.getState().state){
		//Check if opened sidebar item is currently updated item
		if (Object.keys(useSidebarMapContentItemStore.getState().data).length > 0 && useSidebarMapContentItemStore.getState().data.uuid === uuid){
			//Update name in sidebar data
			useSidebarMapContentItemStore.getState().updateItemName(name)
		}
	}

	//Check if updated item is currently rendered as level 2 for update level 2 or update tiles for items layer
	if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid).length > 0){
		//Update name for item in rendered level 2 list
		useContentItemStore.getState().updateRenderedLevel2Name(uuid, name)

		//Update level 2 popup content
		updateLevel2Content(uuid)
	}

	//Update item layer for update name
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			//Calculate bounds
			const bounds = [
				parseFloat(currentLocation.x),
				parseFloat(currentLocation.y),
			]

			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_items', bounds, true).then()
		}
	})


	//TODO update in fullscreen
}

export function globalUpdateMapContentItemThumbnail(uuid, url){
	//Check if sidebar is open
	if (useSidebarMapContentItemStore.getState().state){
		//Check if sidebar opened item is currently updated item
		if (Object.keys(useSidebarMapContentItemStore.getState().data).length > 0 && useSidebarMapContentItemStore.getState().data.uuid === uuid){
			//Update name in sidebar data
			useSidebarMapContentItemStore.getState().updateItemThumbnailUrl(url)
		}
	}

	//Check if updated item is currently rendered as level 2
	if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid).length > 0){
		//Update name for item in rendered level 2 list
		useContentItemStore.getState().updateRenderedLevel2ThumbnailUrl(uuid, url)

		//Update level 2 popup content
		updateLevel2Content(uuid)
	}
}

export function globalUpdateMapContentItemAttributes(uuid, attributes) {
	//Check if sidebar is open
	if (useSidebarMapContentItemStore.getState().state){
		//Check if sidebar opened item is currently updated item
		if (Object.keys(useSidebarMapContentItemStore.getState().data).length > 0 && useSidebarMapContentItemStore.getState().data.uuid === uuid){
			//Update name in sidebar data
			useSidebarMapContentItemStore.getState().updateItemAttribute(attributes)
		}
	}

	//Check if updated item is currently rendered as level 2
	if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid).length > 0){
		//Update name for item in rendered level 2 list
		useContentItemStore.getState().updateRenderedLevel2Attributes(uuid, attributes)

		//Update level 2 popup content
		updateLevel2Content(uuid)
	}
}

export function globalUpdateMapContentItemColorTag(uuid, color) {
	//Check if sidebar is open
	if (useSidebarMapContentItemStore.getState().state){
		//Check if sidebar opened item is currently updated item
		if (Object.keys(useSidebarMapContentItemStore.getState().data).length > 0 && useSidebarMapContentItemStore.getState().data.uuid === uuid){
			//Update name in sidebar data
			useSidebarMapContentItemStore.getState().updateItemColor(color)
		}
	}

	//Check if updated item is currently rendered as level 2
	if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid).length > 0){
		//Update name for item in rendered level 2 list
		useContentItemStore.getState().updateRenderedLevel2Color(uuid, color)

		//Update level 2 popup content
		updateLevel2Content(uuid)
	}
}

export function globaleUpdateMapContentItemLocation(uuid, newLocation, oldLocation = null, local = false){
	//Check if update is local
	if (local){
		//Check if updated item is currently rendered as level 2
		if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid).length > 0){
			//Convert new location to latLng
			const newLatLng = L.Projection.SphericalMercator.unproject(new L.Point(parseFloat(newLocation.x), parseFloat(newLocation.y)))

			//Update leaflet popup location
			useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid)[0].popup.setLatLng(newLatLng)
		}
	}

	//Update item layer tiles
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_items', [parseFloat(newLocation.x), parseFloat(newLocation.y)], true).then()
			if (oldLocation){
				RefreshTilesUtils.invalidateArea(layer, 'weeki_map_items', [parseFloat(oldLocation.x), parseFloat(oldLocation.y)], true).then()
			}
		}
	})
}

export function globalDeleteMapContentItem(uuid, location){
	//Check if deleted item is currently rendered as level 2
	if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid).length > 0){
		//Remove leaflet popup
		useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid)[0].popup.remove()

		//Disable item render
		useContentItemStore.getState().disableLevel2Render(uuid)
	}

	//Update item layer tiles
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_items', [parseFloat(location.x), parseFloat(location.y)], true).then()
		}
	})

	//Check if sidebar is open on deleted item
	if (useSidebarMapContentItemStore.getState().state){
		//Check if sidebar opened item is currently updated item
		if (Object.keys(useSidebarMapContentItemStore.getState().data).length > 0 && useSidebarMapContentItemStore.getState().data.uuid === uuid){
			//Close sidebar
			useSidebarMapContentItemStore.getState().resetStore()
		}
	}
}