import getAxiosInstance from "../../AxiosInstance";
import {USER_MAP_COLLABORATOR_API} from "../../../../Router";

function getAllMapCollaborators(mapUuid){
    return getAxiosInstance().get(USER_MAP_COLLABORATOR_API + '/get_all_map_collaborators', {params: {mapUuid: mapUuid}})
}

function inviteCollaborator(mapUuid, userEmail){
    const sendData = new FormData()
    sendData.append('mapUuid', mapUuid)
    sendData.append('userEmail', userEmail)

    return getAxiosInstance().post(USER_MAP_COLLABORATOR_API + "/invite", sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            return false
        })
}

function removeCollaborator(collaboratorUuid){
    return getAxiosInstance().delete(USER_MAP_COLLABORATOR_API + '/remove_collaborator', {params: {collaboratorUuid: collaboratorUuid}})
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

function acceptInvitation(collaboratorUuid, notificationUuid){
    const sendData = new FormData()
    sendData.append('collaboratorUuid', collaboratorUuid)
    sendData.append('notificationUuid', notificationUuid)

    return getAxiosInstance().post(USER_MAP_COLLABORATOR_API + '/accept_invitation', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

function declineInvitation(collaboratorUuid, notificationUuid){
    const sendData = new FormData()
    sendData.append('collaboratorUuid', collaboratorUuid)
    sendData.append('notificationUuid', notificationUuid)

    return getAxiosInstance().post(USER_MAP_COLLABORATOR_API + '/decline_invitation', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

export default {
    getAllMapCollaborators,
    inviteCollaborator,
    removeCollaborator,
    acceptInvitation,
    declineInvitation
}