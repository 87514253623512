import getAxiosInstance from "../../AxiosInstance";
import {USER_WORKSPACE_API} from "../../../../Router";
import {Form} from "react-hook-form";

function userGetAllUserWorkspace(){
	return getAxiosInstance().get(USER_WORKSPACE_API + '/get_all')
}

function userGetAllWorkspaceInvitations(workspaceUuid){
	return getAxiosInstance().get(USER_WORKSPACE_API + '/get_all_invitations', {params: {workspaceUuid: workspaceUuid}})
}

function userCreateWorkspace(data){
	const sendData = new FormData()
	sendData.append('name', data.name)
	if (data.logo){
		sendData.append('logo', data.logo)
	}

	return getAxiosInstance().post(USER_WORKSPACE_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data
			}

			return false
		})
		.catch(() => {
			return false
		})
}

function userInviteUser(workspaceUuid, userEmail){
	const sendData = new FormData()
	sendData.append('workspaceUuid', workspaceUuid)
	sendData.append('userEmail', userEmail)

	return getAxiosInstance().post(USER_WORKSPACE_API + '/invite_user', sendData)
		.then(response => {
			if (response.status === 200){
				return response.data.uuid
			} else {
				return false
			}
		})
		.catch(() => false)
}

function userRevokeUser(workspaceUuid, invitationUuid){
	const sendData = new FormData()
	sendData.append('workspaceUuid', workspaceUuid)
	sendData.append('invitationUuid', invitationUuid)

	return getAxiosInstance().post(USER_WORKSPACE_API + '/revoke_user', sendData)
		.then(response => {
			return response.status === 200
		})
		.catch(() => false)
}

function userUpdateWorkspace(workspaceUuid, data){
	const sendData = new FormData()
	sendData.append('workspaceUuid', workspaceUuid)

	Object.keys(data).forEach(key => {
		sendData.append(key, data[key])
	})

	return getAxiosInstance().post(USER_WORKSPACE_API + '/update', sendData)
		.then(response => {
			return response.status === 200
		})
		.catch(() => false)
}

function userDeleteWorkspace(workspaceUuid){
	return getAxiosInstance().delete(USER_WORKSPACE_API + '/delete', {params: {workspaceUuid: workspaceUuid}})
		.then(response => {
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	userGetAllUserWorkspace,
	userGetAllWorkspaceInvitations,
	userCreateWorkspace,
	userInviteUser,
	userRevokeUser,
	userUpdateWorkspace,
	userDeleteWorkspace
}