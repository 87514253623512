import React from "react";
import {useMoocQuizStore} from "../../services/Stores/MoocQuizStore";
import {MdDelete} from "react-icons/md";
import UserMoocQuizQuestionAPI from "../../services/API/User/Mooc/UserMoocQuizQuestionAPI";
import {useMoocStore} from "../../services/Stores/MoocStore";

const QuizQuestionCreation = ({question, questionIndex, currentChapter, currentQuiz}) => {
	const handleAddNewResponse = () => {
		const newResponseData = {
			'text': "",
			'valid': false
		}

		useMoocQuizStore.getState().addQuestionResponse(questionIndex, newResponseData)
	}

	const handleDeleteQuizQuestion = async () => {
		//Remove question in edit questions list
		useMoocQuizStore.getState().removeEditQuestion(questionIndex)

		//Check if question have uuid
		if (question.uuid) {
			//Remove question in api
			if (await UserMoocQuizQuestionAPI.userDeleteQuizQuestion(question.uuid)) {
				//Remove question in current quiz and in mooc chapter list quiz
				useMoocStore.getState().removeCurrentQuizQuestion(question.uuid)
				useMoocStore.getState().removeQuizQuestion(currentChapter.uuid, currentQuiz.uuid, question.uuid)

			}
		}
	}

	return (
		<div className="mooc__quiz-question-creation-container">
			<div className="question-header">
				<input value={question.point} onChange={(event) => useMoocQuizStore.getState().updateEditQuestionPoint(questionIndex, event.currentTarget.value)} type="text" placeholder="Point" defaultValue={question.point} />
				<input value={question.question} onChange={(event) => useMoocQuizStore.getState().updateEditQuestionText(questionIndex, event.currentTarget.value)} type="text" placeholder="Question" defaultValue={question.text} />
			</div>
			<textarea value={question.responseExplication} onChange={(event) => useMoocQuizStore.getState().updateEditQuestionResponseExplication(questionIndex, event.currentTarget.value)} placeholder={"Answer explanation"} defaultValue={question.responseExplication} rows={3}></textarea>
			<div className="btn-container">
				<p className="btn" onClick={handleAddNewResponse}>Add answer</p>
				<p className="btn delete" onClick={handleDeleteQuizQuestion}>Remove question</p>
			</div>
			{question.responses.length > 0 && (
				<div className="responses-container">
					{question.responses.map((r, index) => (
						<div className="response" key={index}>
							<input checked={r.valid} type="checkbox" onChange={(event) => useMoocQuizStore.getState().updateQuestionResponseValidState(questionIndex, index, event.currentTarget.checked)}/>
							<input value={r.text} onChange={(event) => useMoocQuizStore.getState().updateQuestionResponseText(questionIndex, index, event.currentTarget.value)} type="text" placeholder="Response" />
							<p className="delete-btn" onClick={() => useMoocQuizStore.getState().removeQuestionResponse(questionIndex, index)}><MdDelete/></p>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default QuizQuestionCreation