import React from "react";
import {BiSolidFolder} from "react-icons/bi";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";

const ProjectCard = ({project, handleOpenContextMenu}) => {
	//Get current selected projects in store
	const currentSelectedProjects = useDashboardStore(state => state.currentSelectedProjects)

	//Toggle project selection
	const handleToggleProjectSelection = () => {
		if (!currentSelectedProjects.includes(project.uuid)){
			useDashboardStore.getState().selectProject(project.uuid)
		} else {
			useDashboardStore.getState().unselectProject(project.uuid)
		}
	}

	return (
		<div onContextMenu={(event) => handleOpenContextMenu(event, project)} onDoubleClick={() => useDashboardStore.setState({currentOpenedProject: project})} className={currentSelectedProjects.includes(project.uuid) ? "custom__project-card-container selected" : "custom__project-card-container"} onClick={handleToggleProjectSelection}>
			<div className="project-card-icon-container">
				<div className="icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-folder"><path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2Z"/></svg>
					<span className="icon-badge">
						{project.mapsCount}
					</span>
				</div>
			</div>
			<div className="project-card-infos-container">
				<p className="project-name">{project.name}</p>
				<p className="project-menu-btn" onClick={(event) => handleOpenContextMenu(event, project)}><BsThreeDotsVertical /></p>
			</div>
		</div>
	)
}

export default ProjectCard