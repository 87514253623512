import React, {useEffect, useRef, useState} from "react";
import {FaChevronDown} from "react-icons/fa";
import {MdAddAPhoto, MdContentCopy} from "react-icons/md";
import ContentItemTypeAPI from "../../../../services/ContentItemTypeAPI";
import {toast} from "react-toastify";
import ContentItemAPI from "../../../../services/ContentItemAPI";
import contentItemAPI from "../../../../services/ContentItemAPI";
import {createErrorToast} from "../../../../services/CustomToastUtils";
import {globalUpdateMapContentItemThumbnail} from "../../../../services/Utils/GlobalMapContentItemUpdateUtils";
import {VscChromeClose} from "react-icons/vsc";
import {CiFileOn} from "react-icons/ci";
import {BsDownload} from "react-icons/bs";
import {IoMdAdd} from "react-icons/io";
import CustomTooltip from "../../../customs/CustomTooltip";
import UserBibtexItemAPI from "../../../../services/API/User/UserBibtexItemAPI";
import {useSidebarMapContentItemStore} from "../../../../services/Stores/SidebarMapContentItemStore";
import SimpleFullscreenContainer from "../../SimpleFullscreenContainer";

const BibtexObjectSidebar = ({item, handleUpdateAttributes, handleValidAttributes, reloadItem}) => {
    //Define default state for get bibtex reference request status
    const [getBibtexReferenceStatus, setGetBibtexReferenceStatus] = useState(false)

    const [referenceTypeSelectorStatus, setReferenceTypeSelectorStatus] = useState(false)

    const [bibtexCode, setBibtexCode] = useState("")

    //Define default state for bibtex reference list
    const [bibtexReferences, setBibtexReferences] = useState([{
        'code': "",
        'name': "",
        'availableFields': [],
        'category': "",
        'typeCode': ""
    }])

    //Get reference is type code is BIBTEX
    useEffect(() => {
        if (item.uuid !== ""){
            ContentItemTypeAPI.getAllBibtexReference().then((response) => {
                if (response.status === 200){
                    setBibtexReferences(response.data)
                    handleFormatBibtexCode(response.data)

                } else {
                    setBibtexReferences([])
                }
                setGetBibtexReferenceStatus(true)
            })

        }
    }, [item.uuid])

    const handleFormatBibtexCode = (refList = null) => {
        let itemAttributes = item.attributes
        let bibtexRef = []

        if (refList !== null){
            bibtexRef = refList
        } else {
            bibtexRef = [...bibtexReferences]
        }

        const typeCode = bibtexRef.filter(ref => ref.code === itemAttributes.filter(attr => attr.name === "reference-type")[0].value)
        const citeKey = itemAttributes.filter(attr => attr.name === "citekey")[0].value
        let attrList= ""

        itemAttributes.forEach(attr => {
            if (attr.name !== "reference-type" && attr.name !== "citekey"){
                if (attr.value !== ""){
                    attrList = attrList + `    ${attr.name} = "${attr.value}"\n`
                }
            }
        })

        let bibtexCode = `@${typeCode}{${citeKey},\n${attrList.slice(0, -1)}\n}`

        setBibtexCode(bibtexCode)
    }

    const handleUpdateReferenceType = (event, newValue) => {
        handleUpdateAttributes("reference-type", newValue)
        handleValidAttributes(event, "reference-type", newValue)
    }

    const [copyStyleMenuStatus, setCopyStyleMenuStatus] = useState(false)
    const [selectedCopyStyle, setSelectedCopyStyle] = useState("apa style")

    const [copyCodeMenuStatus, setCopyCodeMenuStatus] = useState(false)
    const [selectedCopyCode, setSelectedCopyCode] = useState("bibtex")

    const handleCopyBibtexCode = () => {
        navigator.clipboard.writeText(bibtexCode).then(r => null)
        toast.success("Copied")
    }

    //Update thumbnail
    const thumbnailInputRef = useRef(null)
    const handleUpdateThumbnail = async () => {
        if (!item.thumbnailStatus){
            return;
        }

        if (thumbnailInputRef.current.files[0]) {
            const files = thumbnailInputRef.current.files

            if (files[0].type !== "image/png" && files[0].type !== "image/jpg" && files[0].type !== "image/jpeg"){
                createErrorToast("Image format invalid")
                return
            }

            const url = await ContentItemAPI.updateContentItemThumbnail(item.uuid, files[0])
            thumbnailInputRef.current.value = ""

            if (url){
                globalUpdateMapContentItemThumbnail(item.uuid, url)
            }
        }
    }

    const linkFileInputRef = useRef(null)
    const handleLinkFile = async () => {
        //Get first file from input
        const file = linkFileInputRef.current.files[0]
        linkFileInputRef.current.value = ""

        //Check if file exist
        if (file) {
            //Check file mime type
            if (file.type !== "application/pdf") {
                createErrorToast("Unsupported file type, only PDF file can be link")
                return
            }

            const key = await UserBibtexItemAPI.userLinkFileToBibtexItem(item.uuid, file)

            if (key) {
                //Add new linked file to store data
                useSidebarMapContentItemStore.getState().addLinkedFile({
                    'name': file.name,
                    'key': key
                })
            }
        }
    }

    const handleUnlinkFile = async (key) => {
        if (await UserBibtexItemAPI.userUnlinkBibtexItemFile(item.uuid, key)) {
            useSidebarMapContentItemStore.getState().removeLinkedFile(key)
        }
    }

    const handleDownloadLinkedFile = async (key) => {
        const downloadUrl = await UserBibtexItemAPI.userDownloadBibtexLinkedFile(item.uuid, key)

        if (downloadUrl){
            //Create link element for download file
            const link = document.createElement("a")
            link.id = "download-linked-file"
            link.setAttribute('href', downloadUrl)
            link.setAttribute('download', item.linkedFiles.filter(f => f.key === key)[0].name)
            document.body.appendChild(link)
            document.querySelector('#download-linked-file').click()
            document.body.removeChild(link)
        }
    }

    const [currentReadLinkedFileUrl, setCurrentReadLinkedFileUrl] = useState(null)
    const handleReadLinkedFile = async (key) => {
        const downloadUrl = await UserBibtexItemAPI.userDownloadBibtexLinkedFile(item.uuid, key)

        if (downloadUrl){
            setCurrentReadLinkedFileUrl(downloadUrl)
        }
    }

    return (
        <>
            {currentReadLinkedFileUrl !== null && (
                <SimpleFullscreenContainer fileUrl={currentReadLinkedFileUrl} setFileUrl={setCurrentReadLinkedFileUrl} />
            )}
            <div className="bibtex-container">
                <div className="left">
                    <div className="thumbnail-container">
                        <div className="update-overlay" onClick={() => thumbnailInputRef.current.click()}><MdAddAPhoto /></div>
                        <input onChange={handleUpdateThumbnail} ref={thumbnailInputRef} type="file" multiple={false}/>
                        {item.thumbnailUrl !== "" && (
                            <img src={item.thumbnailUrl} alt="Item thumbnail"/>
                        )}
                    </div>
                </div>
                <div className="right">
                    <div className="copy-container">
                        <p className="copy-title">Export</p>
                        <div className="copy">
                            <div className="selector">
                                <div className="selector-btn" onClick={() => setCopyStyleMenuStatus(!copyStyleMenuStatus)}>
                                    <p className="text">{selectedCopyStyle}</p>
                                    <p className="icon"><FaChevronDown /></p>
                                    <div className={copyStyleMenuStatus ? "option-list open": "option-list"}>
                                        <div className="option" onClick={() => setSelectedCopyStyle("apa style")}>APA Style</div>
                                    </div>
                                </div>
                            </div>
                            <p className="copy-btn"><MdContentCopy /></p>
                        </div>
                    </div>
                    <div className="linked-files-container">
                        <input type="file" multiple={false} onChange={handleLinkFile} ref={linkFileInputRef}/>
                        <p className="title">Files</p>
                        {(item.linkedFiles !== null && item.linkedFiles.length > 0) && (
                            <div className="files-list">
                                {item.linkedFiles.map((f, index) => (
                                    <>
                                        {f !== null && (
                                            <div className="file" key={index}>
                                                <div className="read-btn custom__tooltip-container" onClick={() => handleReadLinkedFile(f.key)}>
                                                    <p className="icon"><CiFileOn/></p>
                                                    <p className="text">Read</p>
                                                    <CustomTooltip text="Read file"/>
                                                </div>
                                                <div className="file-infos">
                                                    <p className="name">{f.name.slice(0, 25)}{f.name.length > 25 ? " ..." : ""}</p>
                                                    <p className="icon custom__tooltip-container" onClick={() => handleDownloadLinkedFile(f.key)}>
                                                        <BsDownload/><CustomTooltip
                                                        text="Download file"/></p>
                                                </div>
                                                <p className="delete-btn custom__tooltip-container"
                                                   onClick={() => handleUnlinkFile(f.key)}>
                                                    <VscChromeClose/><CustomTooltip
                                                    text="Remove file"/></p>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}

                        {((item.linkedFiles !== null && item.linkedFiles.length < 3) || item.linkedFiles === null) && (
                            <p className="link-btn custom__tooltip-container"
                               onClick={() => linkFileInputRef.current.click()}><IoMdAdd/><CustomTooltip
                                text="Link file"/>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BibtexObjectSidebar