import {USER_ON_MAP_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function getAllUsersOnMap(mapUuid){
    return getAxiosInstance().get(USER_ON_MAP_API + '/get_all', {params: {mapUuid: mapUuid}})
}

function addUserOnMap(mapUuid){
    const sendData = new FormData()
    sendData.append('mapUuid', mapUuid)

    return getAxiosInstance().post(USER_ON_MAP_API + '/add', sendData)
        .then((response) => {
            return response.status === 200;
        })
        .catch(() => {
            return false
        })
}

function removeUserOnMap(mapUuid){
    const sendData = new FormData()
    sendData.append('mapUuid', mapUuid)

    return getAxiosInstance().post(USER_ON_MAP_API + '/remove', sendData)
        .then((response) => {
            return response.status === 200;
        })
        .catch(() => {
            return false
        })
}

function checkUserOnMap(mapUuid){
    const sendData = new FormData()
    sendData.append('mapUuid', mapUuid)

    return getAxiosInstance().post(USER_ON_MAP_API + '/check', sendData)
        .then((response) => {
            return response.status === 200
        })
}

export default {
    getAllUsersOnMap,
    removeUserOnMap,
    addUserOnMap,
    checkUserOnMap
}