import L from "leaflet";
import ContentItemAPI from "../ContentItemAPI";
import {useMapStore} from "../Stores";
import TextItemAPI from "../API/User/TextItemAPI";
import {useMapContextMenuStore} from "../Stores/MapContextMenuStore";
import {selectMapContentItem, selectMapTextItem} from "./ItemUtils";
import {updateRenderedTextItemPopup} from "./MapPopupUtils";
import {useContentItemStore} from "../Stores/ContentItemStore";
import {useSidebarMapContentItemStore} from "../Stores/SidebarMapContentItemStore";
import {globaleUpdateMapContentItemLocation} from "./GlobalMapContentItemUpdateUtils";
import {useTextItemStore} from "../Stores/TextItemStore";
import {generateNewTextItem} from "./RenderTextItemsUtils";
import {generateContentItemSelectedMenu, updateSelectedMenuPosition} from "./MapSelectedMenusUtils";
import {useMoocStore} from "../Stores/MoocStore";
import {clearHoveredLevel1} from "./Level1ItemUtils";
import {createSuccessToast} from "../CustomToastUtils";
import {textItemSize} from "./TextItemUtils";

export function addLevel2PopupEvent(popup, feature){
    //Add click event on popup for select item and open object sidebar
    addItemLevel2ClickEvent(popup, feature)

    //Add event for contact item
    if (feature.properties.type_code === "CONTACT"){
        addContactItemEvents(popup, feature)
    }

    //Add context menu event on popup for open object context menu
    if (!useMapStore.getState().publicMap) {
        L.DomEvent.on(popup._contentNode, 'contextmenu', (event) => {
            event.preventDefault()
            event.stopPropagation()

            //Open object context menu
            useMapContextMenuStore.getState().openItemMenu(feature.properties.id, event.clientX, event.clientY, feature)
        })

        //Enable draggable for popup
        let draggable = new L.Draggable(popup._container, popup._wrapper)
        draggable.enable()

        //Remove click event on drag
        draggable.on('drag', () => {
            L.DomEvent.off(popup._contentNode, 'click')

            //Close selected popup if exist
            if (useContentItemStore.getState().selectedItemPopup !== null) {
                useContentItemStore.getState().selectedItemPopup.close()
            }
        })

        //Update item position in api on drag end AND update popup position
        draggable.on("dragend", async (event) => {
            //Get old popup position
            const oldLatLng = useMapStore.getState().mapInstance.layerPointToLatLng(event.sourceTarget._startPos)

            //Get new popup position
            const newLatLng = useMapStore.getState().mapInstance.layerPointToLatLng(event.sourceTarget._newPos)
            popup.setLatLng(newLatLng)

            //Add selected menu popup
            generateContentItemSelectedMenu(popup, feature.properties)

            //Get new item point
            const newPoint = L.Projection.SphericalMercator.project(newLatLng)
            const oldPoint = L.Projection.SphericalMercator.project(oldLatLng)

            //Update popup position with api
            if (await ContentItemAPI.updateContentItemLocation(feature.properties.id, newPoint.x, newPoint.y)) {
                globaleUpdateMapContentItemLocation(feature.properties.id, newPoint, oldPoint, false)
            }

            //Re-active popup event after move
            setTimeout(() => {
                //Add click event on popup for select item and open object sidebar
                addItemLevel2ClickEvent(popup, feature)

                //Add context menu event on popup for open object context menu
                L.DomEvent.on(popup._contentNode, 'contextmenu', (event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    //Open object context menu
                    useMapContextMenuStore.getState().openItemMenu(feature.properties.id, event.clientX, event.clientY, feature)
                })
            }, 300)
        })
    }
}

function addContactItemEvents(popup, feature){
    const attributes = JSON.parse(feature.properties.attributes)

    //Get email attribute and check if he exists and not empty
    const emailAttribute = attributes.filter(a => a.name === "email")[0]
    if (emailAttribute && emailAttribute.value !== ""){
        L.DomEvent.on(popup._contentNode.querySelector("#contact-copy-email-btn"), 'click', async (event) => {
            event.stopPropagation()

            //Copy email in clipboard
            await navigator.clipboard.writeText(emailAttribute.value)
            createSuccessToast("Contact email copied")
        })
    }

    //Get phone attribute and check if he exists and not empty
    const phoneAttribute = attributes.filter(a => a.name === "phone")[0]
    if (phoneAttribute && phoneAttribute.value !== ""){
        L.DomEvent.on(popup._contentNode.querySelector("#contact-copy-phone-btn"), 'click', async (event) => {
            event.stopPropagation()

            //Copy email in clipboard
            await navigator.clipboard.writeText(phoneAttribute.value)
            createSuccessToast("Contact email copied")
        })
    }
}

export function addCreateTextItemPopupEvents(){
    const popup = useMapStore.getState().createTextPopup

    popup._contentNode.querySelector("#add-text-item-input").focus()

    L.DomEvent.on(popup._contentNode.querySelector('#add-text-item-input'), 'keypress', async (event) => {
        if (event.key === "Enter") {
            const value = event.target.value

            if (value.length > 0 && value.length <= 255) {
                const createPopup = useMapStore.getState().createTextPopup
                const currentZoom = useMapStore.getState().mapInstance.getZoom()

                //Get create popup lat lng
                const latLng = createPopup.getLatLng()

                //Convert lat lng to point
                const point = L.Projection.SphericalMercator.project(L.latLng(latLng.lat, latLng.lng))

                //Remove create text item popup
                useMapStore.getState().createTextPopup.remove()
                useMapStore.setState({createTextPopup: null})

                //Create text item with api
                const uuid = await TextItemAPI.createTextItem(point.x, point.y, value, useMapStore.getState().currentOverlay, currentZoom)

                //Generate new text item
                if (uuid.length > 0) {
                    generateNewTextItem(uuid, value, (currentZoom + textItemSize.small.zoomOffset) - 1, createPopup.getLatLng().lat, createPopup.getLatLng().lng, useMapStore.getState().currentOverlay, 'normal')
                }
            }
        }
    })
}

export function addTextItemPopupEvents(popup, feature){
    L.DomEvent.off(popup._contentNode)

    //Enable draggable for popup
    const draggable = new L.Draggable(popup._container, popup._wrapper)
    draggable.enable()

    //Get input element
    const element = popup._contentNode.querySelector('#update-text-item-input')
    const textHelper = popup._contentNode.querySelector("#textWidthHelper")


    if (useTextItemStore.getState().currentSelectedItem === feature.properties.uuid){
        element.focus()
    }

    // Copier le style de l'input au helper pour qu'ils soient identiques
    textHelper.style.fontFamily = window.getComputedStyle(element).fontFamily;
    textHelper.style.fontSize = window.getComputedStyle(element).fontSize;
    textHelper.style.padding = window.getComputedStyle(element).padding;
    textHelper.style.border = window.getComputedStyle(element).border;

    textHelper.textContent = feature.properties.text
    const width = textHelper.offsetWidth
    element.style.width = width + 'px'

    L.DomEvent.on(popup._contentNode, 'click', (event) => {
        event.stopPropagation()
        event.preventDefault()

        //Close all map context menu
        useMapContextMenuStore.getState().resetStore()

        //Check if map is not public and select text item
        if (!useMapStore.getState().publicMap){
            if (useTextItemStore.getState().currentSelectedItem !== feature.properties.uuid){
                selectMapTextItem(feature.properties.uuid)
            } else {
                element.style.caretColor = "auto"
                draggable.disable()
            }
        }
    })

    L.DomEvent.on(popup._contentNode, 'dblclick', (event) => {
        draggable.disable()

        //Set input caret color
        element.style.caretColor = "auto"

        //Select all input value
        element.select()
    })

    if (useTextItemStore.getState().currentSelectedItem === feature.properties.uuid){
        L.DomEvent.on(popup._contentNode.querySelector('#update-text-item-input'), 'blur', (event) => {
            if (useTextItemStore.getState().currentSelectedItem === feature.properties.uuid){
                //Get new value
                const newValue = popup._contentNode.querySelector('#update-text-item-input').value

                //Update text value in api
                TextItemAPI.updateValue(feature.properties.uuid, newValue)
            }
        })
    }

    L.DomEvent.on(popup._contentNode.querySelector('#update-text-item-input'), 'input', (event) => {
        draggable.disable()

        //Get input element
        const element = popup._contentNode.querySelector('#update-text-item-input')
        const textHelper = popup._contentNode.querySelector("#textWidthHelper")

        const caretColor = getComputedStyle(element).caretColor

        let newValue;
        if (caretColor === "rgba(0, 0, 0, 0)"){
            newValue = ""
            element.value = ""
            element.style.caretColor = "auto"
        } else {
            newValue = element.value
        }

        // Copier le style de l'input au helper pour qu'ils soient identiques
        textHelper.style.fontFamily = window.getComputedStyle(element).fontFamily;
        textHelper.style.fontSize = window.getComputedStyle(element).fontSize;
        textHelper.style.padding = window.getComputedStyle(element).padding;
        textHelper.style.border = window.getComputedStyle(element).border;

        textHelper.textContent = newValue
        const width = textHelper.offsetWidth

        element.style.width = width + 'px'

        //Update selected menu position
        if (useTextItemStore.getState().selectedItemPopup){
            updateSelectedMenuPosition(popup, useTextItemStore.getState().selectedItemPopup, feature.properties)
        }

        //Update text in store
        useTextItemStore.getState().updateRenderedTextItemValue(feature.properties.uuid, newValue)
    })

    L.DomEvent.on(popup._contentNode.querySelector('#update-text-item-input'), 'keypress', (event) => {
        if (useTextItemStore.getState().currentSelectedItem === feature.properties.uuid){
            if (event.key === "Enter"){
                //Get new value
                const newValue = popup._contentNode.querySelector('#update-text-item-input').value

                TextItemAPI.updateValue(feature.properties.uuid, newValue)

                //Get input element
                const element = popup._contentNode.querySelector('#update-text-item-input')
                element.style.caretColor = "rgba(0, 0, 0, 0)"

                draggable.enable()
            }
        }
    })

    if (draggable){
        //Remove click event on drag
        draggable.on('drag', () => {
            L.DomEvent.off(popup._contentNode, 'click')

            //Close selected popup if exist
            if (useTextItemStore.getState().selectedItemPopup !== null) {
                useTextItemStore.getState().selectedItemPopup.close()
            }
        })

        draggable.on("dragend", (event) => {
            //Get new popup position
            const newLatLng = useMapStore.getState().mapInstance.layerPointToLatLng(event.sourceTarget._newPos)
            popup.setLatLng(newLatLng)

            //Convert lat lng to point
            const point = L.Projection.SphericalMercator.project(L.latLng(newLatLng.lat, newLatLng.lng))

            selectMapTextItem(feature.properties.uuid)

            //Update text item location in api
            TextItemAPI.updateLocation(feature.properties.uuid, point.x, point.y)

            //Re-active popup event after move
            setTimeout(() => {
                addTextItemPopupEvents(popup, {properties: useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === feature.properties.uuid)[0].properties})
            }, 300)
        })
    }




}

export function addLevel1PopupEvents(popup, feature){
    //Enable draggable for popup
    let draggable = new L.Draggable(popup._container, popup._wrapper)
    draggable.enable()

    addItemLevel2ClickEvent(popup, feature, false)

    //Remove click event on drag
    draggable.on('drag', () => {
        L.DomEvent.off(popup._contentNode, 'click')
        useContentItemStore.setState({hoveredLevel1DragState: true})
    })

    //Update item position in api on drag end AND update popup position
    draggable.on("dragend", async (event) => {
        //Get old popup position
        const oldLatLng = useMapStore.getState().mapInstance.layerPointToLatLng(event.sourceTarget._startPos)

        //Get new popup position
        const newLatLng = useMapStore.getState().mapInstance.layerPointToLatLng(event.sourceTarget._newPos)

        //Get new item point
        const newPoint = L.Projection.SphericalMercator.project(newLatLng)
        const oldPoint = L.Projection.SphericalMercator.project(oldLatLng)

        useContentItemStore.setState({hoveredLevel1DragState: false})

        //Update item position with api
        if (await ContentItemAPI.updateContentItemLocation(feature.properties.id, newPoint.x, newPoint.y)) {
            globaleUpdateMapContentItemLocation(feature.properties.id, newPoint, oldPoint, true)
        }

        //Re-active popup event after move
        setTimeout(() => {
            //Add click event on popup for select item and open object sidebar
            addItemLevel2ClickEvent(popup, feature, false)
        }, 300)

        clearHoveredLevel1()
    })
}

function addItemLevel2ClickEvent(popup, feature, select = true){
    L.DomEvent.on(popup._contentNode, 'click', (event) => {
        event.stopPropagation()
        event.preventDefault()

        //Close all map context menu
        useMapContextMenuStore.getState().resetStore()

        //Open object sidebar
        if (!useMoocStore.getState().setPositionState) {
            if (useSidebarMapContentItemStore.getState().properties === null || useSidebarMapContentItemStore.getState().properties.id !== feature.properties.id) {
                useSidebarMapContentItemStore.getState().resetStore()
                useSidebarMapContentItemStore.setState({state: true, properties: feature.properties})
            }
        }

        //Check if map is not public for select item
        if (!useMapStore.getState().publicMap && select){
            selectMapContentItem(feature.properties)
        }
    })
}