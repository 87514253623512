import React, {Fragment, useEffect, useRef, useState} from "react";
import UserProjectsAPI from "../../../services/API/User/UserProjectsAPI";
import ProjectCard from "./ProjectCard";
import ContextMenuContainer from "../content_menu/ContextMenuContainer";
import ContextMenuItem from "../content_menu/ContextMenuItem";
import {MdDelete, MdOutlineDriveFileRenameOutline} from "react-icons/md";
import {getContextMenuPosition} from "../../../services/Utils";
import ConfirmDeletePopup from "../popups/ConfirmDeletePopup";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import RenameProjectPopup from "../dashboard-popups/RenameProjectPopup";

const ProjectsListContainer = () => {
	const contextMenuRef = useRef(null)
	const [contextMenuState, setContextMenuState] = useState(false)
	const [confirmDeletePopupState, setConfirmDeletePopupState] = useState(false)
	const [renameProjectPopupState, setRenameProjectPopupState] = useState(false)

	//Get current projects and selected projects in store
	const currentProjects = useDashboardStore(state => state.currentProjects)

	//Open project context menu
	const handleOpenContextMenu = (event, project) => {
		event.stopPropagation()
		event.preventDefault()

		useDashboardStore.setState({currentContextMenuProject: project})
		setContextMenuState(true)
		getContextMenuPosition(event, contextMenuRef.current)
	}

	//Delete project
	const handleDeleteProject = async () => {
		if (await UserProjectsAPI.userDeleteProject(useDashboardStore.getState().currentContextMenuProject.uuid)){
			useDashboardStore.getState().removeProject(useDashboardStore.getState().currentContextMenuProject.uuid)
		}
	}

	return (
		<>
			{/*PROJECT CONTEXT MENU*/}
			<ContextMenuContainer ref={contextMenuRef} state={contextMenuState} setState={setContextMenuState}>
				<ContextMenuItem handler={() => {
					setContextMenuState(false)
					setRenameProjectPopupState(true)
				}} text="Rename project" icon={<MdOutlineDriveFileRenameOutline />}  />
				<ContextMenuItem handler={() => {
					setConfirmDeletePopupState(true)
					setContextMenuState(false)
				}} text="Delete project" icon={<MdDelete />}  />
			</ContextMenuContainer>
			<ConfirmDeletePopup state={confirmDeletePopupState} setState={setConfirmDeletePopupState} handler={handleDeleteProject} deleteType="project" children={null} />
			<RenameProjectPopup state={renameProjectPopupState} setState={setRenameProjectPopupState} />
			<div className="custom__project-list-container">
				{currentProjects !== null && (
					<>
						{currentProjects.length > 0 && (
							<>
								{currentProjects.map((project, index) => (
									<Fragment key={index}>
										<ProjectCard project={project} handleOpenContextMenu={handleOpenContextMenu} />
									</Fragment>
								))}
							</>
						)}
					</>
				)}

			</div>
		</>
	)
}

export default ProjectsListContainer