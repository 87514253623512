import React, {useContext, useEffect, useRef, useState} from "react";
import {useToastStore} from "../contexts/CustomToastStore";
import {AiOutlineCheck, AiOutlineInfoCircle} from "react-icons/ai";
import {VscChromeClose} from "react-icons/vsc";
import {BiErrorCircle} from "react-icons/bi";
import {TailSpin} from "react-loader-spinner";
import AuthContext from "../contexts/AuthContext";

const CustomToastContainer = () => {
    const toastList = useToastStore(state => state.toastList)
    const removeToast =  useToastStore(state => state.removeToast)

    const [defaultToastBaseHeight, setDefaultToastBaseHeight] = useState(85)

    const {isAuthenticated} = useContext(AuthContext)

    useEffect(() => {
        if (!isAuthenticated){
            setDefaultToastBaseHeight(30)
        } else {
            setDefaultToastBaseHeight(85)
        }
    }, [isAuthenticated])


    const handleCancel = async (toastId) => {
        const toast = toastList.filter(t => t.id === toastId)[0]
        toast.callback()
        removeToast(toastId)
    }

    const toastContainerRef = useRef(null)

    const getPreviousElementHeight = (index) => {

        let height = 0

        const nodes = toastContainerRef.current.childNodes

        for (let i = 0; i < index; i++){
            if (nodes[i] !== undefined){
                height = height + nodes[i].offsetHeight + 12
            }
        }

        return height
    }

    return (
        <>
            <div className="custom-toast-container" ref={toastContainerRef} style={{zIndex: Number.MAX_SAFE_INTEGER}}>
                {toastContainerRef.current && (
                    <>
                        {toastList.length > 0 && (
                            <>
                                {toastList.map((toast, index) => (
                                    <div key={index} id={toast.id} className={"toast " + toast.type} onClick={(event) => console.log(getPreviousElementHeight(index))}
                                         style={{zIndex: Number.MAX_SAFE_INTEGER, top: "calc(" + getPreviousElementHeight(index) + "px + " + defaultToastBaseHeight + "px)"}}
                                    >
                                        {toast.type === "info" && (
                                            <div className="icon"><AiOutlineInfoCircle /></div>
                                        )}
                                        {toast.type === "error" && (
                                            <div className="icon"><BiErrorCircle /></div>
                                        )}
                                        {toast.type === "success" && (
                                            <div className="icon"><AiOutlineCheck /></div>
                                        )}
                                        {toast.type === "loading" && (
                                            <TailSpin height="16" width="16" color="#FFFFFF" />
                                        )}
                                        <p className="message">{toast.message}</p>
                                        {toast.cancellable && (
                                            <div className="cancel-btn" onClick={() => handleCancel(toast.id)}>Cancel</div>
                                        )}
                                        <div className="close-icon" onClick={() => removeToast(toast.id)}><VscChromeClose /></div>

                                    </div>
                                ))}
                            </>
                        )}

                    </>
                )}

            </div>
        </>
    )
}

export default CustomToastContainer