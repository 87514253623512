import React, {useContext, useState} from "react";
import {AiOutlineBarChart} from "react-icons/ai";
import {BiDollar, BiHistory, BiUser} from "react-icons/bi";
import {FaRegObjectGroup, FaTelegramPlane, FaWrench} from "react-icons/fa";
import {ImMap2} from "react-icons/im";
import { PiHouseLineBold } from "react-icons/pi";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import {IoMdCart} from "react-icons/io";
import {MdOutlineSupport} from "react-icons/md";
import {useHistory, useLocation} from "react-router-dom";
import PricingPopup from "./PricingPopup";
import SubscribePopupContext from "../contexts/SubscribePopupContext";
import {ReactComponent as MyBoardsIcon} from "../../icons/sidebar/my-boards.svg"
import {DASHBOARD_MAP} from "../DashboardMap";
import {useDashboardStore} from "../services/Stores/DashboardStore";
import {useUserStore} from "../services/Stores";
import {useSubscribePopupStore} from "../services/Stores/SubscribePopupStore";


const SideBarNav = ({dashboardType, page, currentDashboard}) => {
    //Import location
    const location = useLocation()

    //Import history
    const history = useHistory()

    const confirmedAccount = useUserStore(state => state.userLoad ? state.confirmedAccount : true)
    const userSubscription = useUserStore(state => state.userSubscription)

    //Change current dashboard
    const handleChangeCurrentDashboard = (key) => {
        //Check if clicked dashboard have route
        if (DASHBOARD_MAP[dashboardType][key].route === undefined){
            //Execute dashboard action
            DASHBOARD_MAP[dashboardType][key].action()
        } else {
            //Set dashboard in store
            useDashboardStore.setState({currentDashboard: DASHBOARD_MAP[dashboardType][key]})

            //Redirect to dashboard
            history.push(DASHBOARD_MAP[dashboardType][key].route)
        }

    }

    return (
        <>
            <div className={confirmedAccount ? "sidebar-container" : "sidebar-container header-alert-active"}>
                <div className="main-title">{dashboardType === "user" ? "" : dashboardType === "admin" ? "Administration" : ""}</div>
                <div className="menu-container">
                    {Object.keys(DASHBOARD_MAP[dashboardType]).map((key, index) => (
                        <div key={index} onClick={() => handleChangeCurrentDashboard(key)} className={location.pathname === DASHBOARD_MAP[dashboardType][key].route ? "btn selected": "btn"}>
                            <div className="icon">{DASHBOARD_MAP[dashboardType][key].icon}</div>
                            <div className="text">{DASHBOARD_MAP[dashboardType][key].name}</div>
                        </div>
                    ))}
                </div>
                {userSubscription !== null && (
                    <>
                        {!userSubscription.team && (
                            <>
                                {userSubscription.planName === "free" && (
                                    <div className="sidebar-subscribe-panel">
                                        <div className="icon">⚡</div>
                                        <h1 className="title">Boost your efficiency</h1>
                                        <p className="text">Upgrade your plan from a Free trial, to Business Plan.</p>
                                        <div className="btn-container">
                                            <p className="btn" onClick={() => useSubscribePopupStore.setState({state: true, view: 'plans'})}>Upgrade plan</p>
                                             {/*<p className="close">Maybe later</p>*/}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                {/*{location.pathname.split("/")[1] === "admin" && (*/}
                {/*    <>*/}
                {/*        <div className="menu-container">*/}
                {/*            <div onClick={() => history.push("/admin/dashboard")} className={location.pathname === "/admin/dashboard" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><AiOutlineBarChart /></div>*/}
                {/*                <div className="text">Synthesis</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/plans")} className={location.pathname === "/admin/dashboard/plans" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><BiDollar /></div>*/}
                {/*                <div className="text">Plans</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/maps")} className={location.pathname === "/admin/dashboard/maps" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><ImMap2 /></div>*/}
                {/*                <div className="text">Maps</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/object-type")} className={location.pathname === "/admin/dashboard/object-type" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><ImMap2 /></div>*/}
                {/*                <div className="text">Object types</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/store")} className={location.pathname === "/admin/dashboard/store" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><IoMdCart /></div>*/}
                {/*                <div className="text">Store</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/users")} className={location.pathname === "/admin/dashboard/users" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><BiUser /></div>*/}
                {/*                <div className="text">Users</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/emails")} className={location.pathname === "/admin/dashboard/emails" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><FaTelegramPlane /></div>*/}
                {/*                <div className="text">Emails</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/history")} className={location.pathname === "/admin/dashboard/history" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><BiHistory /></div>*/}
                {/*                <div className="text">History</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/support")} className={location.pathname === "/admin/dashboard/support" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><MdOutlineSupport /></div>*/}
                {/*                <div className="text">Support</div>*/}
                {/*            </div>*/}
                {/*            <div onClick={() => history.push("/admin/dashboard/maintenance")} className={location.pathname === "/admin/dashboard/maintenance" ? "btn selected": "btn"}>*/}
                {/*                <div className="icon"><FaWrench /></div>*/}
                {/*                <div className="text">Maintenance</div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
        </>
    )
}

export default SideBarNav