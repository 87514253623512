import React, {useEffect, useRef, useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import {IoMdAdd, IoMdRemove} from "react-icons/io";
import {globalLicenseType} from "../../../App";
import {FaFilter} from "react-icons/fa";
import {BiSearch} from "react-icons/bi";
import UserMoocAPI from "../../services/API/User/Mooc/UserMoocAPI";
import {useMapStore} from "../../services/Stores";
import {useMoocStore} from "../../services/Stores/MoocStore";
import {mockComponent} from "react-dom/test-utils";
import {EMAIL_REGEX} from "../../../Regex";
import {createErrorToast, createInfoToast, createSuccessToast} from "../../services/CustomToastUtils";
import UserInvitationAPI from "../../services/API/User/UserInvitationAPI";
import {ThreeDots} from "react-loader-spinner";
import {convertSecondToStringTime} from "../../services/Utils";
import userMoocAPI from "../../services/API/User/Mooc/UserMoocAPI";
import {BsMortarboardFill} from "react-icons/bs";
import {PiBookBold} from "react-icons/pi";
import SizedTextarea from "./SizedTextarea";
import UploadFileComponent from "./UploadFileComponent";
import activity_domains from "../../../json/activity-domain.json"

const StorySettingsPanel = ({state, setState}) => {
	//Define default component state
	const [currentPanel, setCurrentPanel] = useState("config")
	const [userInvitations, setUserInvitations] = useState([])
	const [availableAuthorizations, setAvailableAuthorizations] = useState([])
	const [userInvitationLoadingState, setUserInvitationLoadingState] = useState(true)

	//Get current opened mooc
	const currentMooc = useMoocStore(storeState => storeState.openedMooc)

	//Define default mooc data
	const [moocData, setMoocData] = useState({})
	console.log(moocData)

	useEffect(() => {
		if (state){
			if (!currentMooc){
				setState(false)
			} else {
				setMoocData(structuredClone(currentMooc))
			}
		} else {
			setMoocData({})
		}
	},[state])

	const handleUpdateMooc = async () => {
		if (currentMooc) {
			const updatedData = {}
			Object.keys(moocData).forEach(newDataKey => {
				if (newDataKey !== "userAuthorizations" && newDataKey !== "thumbnail" && moocData[newDataKey] !== currentMooc[newDataKey]){
					updatedData[newDataKey] = moocData[newDataKey]
				}
			})

			if (await userMoocAPI.userUpdateMooc(currentMooc.uuid, updatedData)){
				//Update current mooc in store
				useMoocStore.getState().updateMoocData(currentMooc.uuid, moocData)
			}
		}
	}

	const handleAddTag = (tag) => {
		setMoocData({...moocData, tags: [...moocData.tags, tag]})
	}

	useEffect(() => {
		setInviteUserPanelState(false)
		setUserInvitations([])
		setAvailableAuthorizations([])
		setUserInvitationLoadingState(true)

		//Get viewer authorization if current panel is participants
		if (currentPanel === "participants"){
			UserInvitationAPI.userGetAllEntityInvitations(currentMooc.uuid, 'mooc', 'viewer').then((response) => {
				setUserInvitationLoadingState(false)
				if (response.status === 200){
					setUserInvitations(response.data.invitations)
					setAvailableAuthorizations(response.data.availableAuthorizations)
				} else {
					setUserInvitations([])
					setAvailableAuthorizations([])
				}
			})
		}

		//Get editor authorization if current panel is collaborator
		if (currentPanel === "collab"){
			UserInvitationAPI.userGetAllEntityInvitations(currentMooc.uuid, 'mooc', 'editor').then((response) => {
				setUserInvitationLoadingState(false)
				if (response.status === 200){
					setUserInvitations(response.data.invitations)
					setAvailableAuthorizations(response.data.availableAuthorizations)
				} else {
					setUserInvitations([])
					setAvailableAuthorizations([])
				}
			})
		}

	}, [currentPanel]);

	const [inviteUserPanelState, setInviteUserPanelState] = useState(false)
	const [inviteUserEmail, setInviteUserEmail] = useState("")
	const handleInviteUser = async (inviteType) => {
		const email = inviteUserEmail

		if (email.length <= 0 || !EMAIL_REGEX.test(email)) {
			createErrorToast("Invalid email address")
			return
		}

		const uuid = await UserInvitationAPI.userInviteUser('mooc', currentMooc.uuid, inviteType, email)

		if (uuid){
			setUserInvitations([...userInvitations, {'uuid': uuid, 'state': 'pending', 'userEmail': email}])
			setInviteUserEmail("")
		}
	}

	const getAdvancePercentage = (invitation) => {
		return invitation.validatedCourseCount > 0 ? ((100 * invitation.validatedCourseCount) / currentMooc.totalCourseCount).toFixed(0) : 0
	}

	const getAdvanceColor = (advancePercent) => {
		if (advancePercent < 25){
			return "#D6D3D3"
		} else if (advancePercent > 24 && advancePercent <= 50){
			return "#FF5252"
		} else if (advancePercent > 49 && advancePercent <= 75){
			return "#F3D369"
		} else if (advancePercent > 74 && advancePercent <= 100){
			return "#2DD7A7"
		}
	}

	const getAverageAdvance = () => {
		const averageAdvance = {
			tier1: 0,
			tier2: 0,
			tier3: 0,
			tier4: 0
		}

		userInvitations.forEach(i => {
			const invitationAdvancePercent = getAdvancePercentage(i)
			if (invitationAdvancePercent < 25){
				averageAdvance.tier1 = averageAdvance.tier1 + 1
			} else if (invitationAdvancePercent > 24 && invitationAdvancePercent <= 50){
				averageAdvance.tier2 = averageAdvance.tier2 + 1
			} else if (invitationAdvancePercent > 49 && invitationAdvancePercent <= 75){
				averageAdvance.tier3 = averageAdvance.tier3 + 1
			} else if (invitationAdvancePercent > 74 && invitationAdvancePercent <= 100){
				averageAdvance.tier4 = averageAdvance.tier4 + 1
			}
		})

		return {
			tier1: {
				percent: (averageAdvance.tier1 * 100) / userInvitations.length,
				color: "#D6D3D3"
			},
			tier2: {
				percent: (averageAdvance.tier2 * 100) / userInvitations.length,
				color: "#FF5252"
			},
			tier3: {
				percent: (averageAdvance.tier3 * 100) / userInvitations.length,
				color: "#F3D369"
			},
			tier4: {
				percent: (averageAdvance.tier4 * 100) / userInvitations.length,
				color: "#2DD7A7"
			},
		}
	}

	const getAveragePresenceTime = () => {
		let totalPresenceTime = 0

		if (userInvitations.length <= 0){
			return 0
		}

		userInvitations.forEach(i => {
			totalPresenceTime = totalPresenceTime + i["presenceTime"]
		})

		return totalPresenceTime / userInvitations.length
	}

	const updateThumbnailInputRef = useRef(null)
	const handleUpdateThumbnail = () => {
		//Get input first files
		const file = updateThumbnailInputRef.current.files[0]

		//Check if file exist
		if (file) {
			//Check if file type is valid
			if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg"){
				createErrorToast("Image format invalid")
				return
			}

			//Create image preview
			const imagePreview = URL.createObjectURL(file)

			//Update mooc thumbnail in store
			useMoocStore.getState().updateCurrentMoocThumbnail(currentMooc.uuid, imagePreview)

			setMoocData({...moocData, thumbnail: imagePreview})

			//Update mooc thumbnail in api
			userMoocAPI.userUpdateMooc(currentMooc.uuid, {thumbnail: file})
		}

		//Reset input value
		updateThumbnailInputRef.current.value = ""
	}

	const [prerequisites, setPrerequisites] = useState(moocData.prerequisites)
	const [skills, setSkills] = useState(moocData.skills)
	const [organisationDescription, setOrganisationDescription] = useState(moocData.organisationDescription)

	useEffect(() => {
		setMoocData({...moocData, prerequisites: prerequisites})
	}, [prerequisites])

	useEffect(() => {
		setMoocData({...moocData, skills: skills})
	}, [skills])

	useEffect(() => {
		setMoocData({...moocData, organisationDescription: organisationDescription})
	}, [organisationDescription])

	const [courseFile, setCourseFile] = useState([])

	const handleUploadCourseFile = async (files) => {
		//Get file type and file extension
		const file = files[0]
		const fileExtension = files[0].name.split(".").slice(-1)[0]
		const fileType = files[0].type

		//Check if file is PDF
		if (fileExtension !== "pdf" || fileType !== "application/pdf") {
			createErrorToast("Invalid file, please upload PDF file")
			return
		}

		//Upload file in api
		if (await UserMoocAPI.userUploadMoocCourseFile(currentMooc.uuid, file)) {
			createSuccessToast("File upload successfully")
		}

	}

	if (!currentMooc){
		return null
	}

	if (!state){
		return null
	}

	if (Object.keys(moocData).length <= 0){
		return null
	}

	const handleOpenParticipantTab = () => {
		//Check if mooc is free
		if (currentMooc.price <= 0){
			createInfoToast("This feature is reserved for paying courses. You can make your course chargeable from the pricing tab.")
			return
		}

		//Check if panel is already participant
		if (currentPanel !== "participants"){
			setCurrentPanel("participants")
		}
	}

	return (
		<>
			<div className="story-settings-panel-container">
				<div className="header">
					<p className="close-btn" onClick={() => setState(false)}><VscChromeClose /></p>
					<div className="sub-header">
						<h1>Story settings</h1>
						<h2>Let's set up your story</h2>
					</div>
				</div>
				<div className="panels-container">
					<div className="panels-selector">
						<p onClick={() => currentPanel !== "config" ? setCurrentPanel('config') : null}
						   className={currentPanel === "config" ? "active" : ""}>Configuration</p>
						<p onClick={() => currentPanel !== "eval" ? setCurrentPanel('eval') : null}
						   className={currentPanel === "eval" ? "active" : ""}>Evaluation</p>
						<p onClick={() => currentPanel !== "pricing" ? setCurrentPanel('pricing') : null}
						   className={currentPanel === "pricing" ? "active" : ""}>Pricing</p>
						<p onClick={() => currentPanel !== "publish" ? setCurrentPanel('publish') : null}
						   className={currentPanel === "publish" ? "active" : ""}>Publish</p>
						<p onClick={handleOpenParticipantTab}
						   className={currentPanel === "participants" ? "active" : ""}>Participants</p>
						<p onClick={() => currentPanel !== "collab" ? setCurrentPanel('collab') : null}
						   className={currentPanel === "collab" ? "active" : ""}>Collaborators</p>
					</div>
					{currentPanel === "config" && (
						<div className="panel-content">
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Language</label>
										<select value={moocData.language} onChange={(event) => setMoocData({
											...moocData,
											language: event.currentTarget.value
										})}>
											<option value="french">French</option>
											<option value="english">English</option>
											<option value="spanish">Spanish</option>
										</select>
									</div>
									<div className="panel-input">
										<label>Level</label>
										<select value={moocData.level} onChange={(event) => setMoocData({
											...moocData,
											level: event.currentTarget.value
										})}>
											<option value="beginner">Beginner</option>
											<option value="intermediate">Intermediate</option>
											<option value="advanced">Advanced</option>
										</select>
									</div>
									<div className="panel-input">
										<label>Duration</label>
										<div className="multi-input">
											<input type="number" value={moocData.duration}
												   onChange={(event) => setMoocData({
													   ...moocData,
													   duration: parseInt(event.currentTarget.value)
												   })}/>
											<select value={moocData.durationUnit} onChange={(event) => setMoocData({
												...moocData,
												durationUnit: event.currentTarget.value
											})}>
												<option value="hours">Hours</option>
											</select>
										</div>
									</div>
								</div>
								<div className="line">
									<div className="panel-input">
										<label>Theme</label>
										<select value={moocData.theme} onChange={(event) => setMoocData({
											...moocData,
											theme: event.currentTarget.value
										})}>
											<option value="">Choose mooc theme</option>
											{activity_domains.map((domain) => (
												<option value={domain}>{domain}</option>
											))}
										</select>
									</div>
								</div>
								<div className="line">
									<SizedTextarea label={"Prerequisites"} maxSize={200} value={moocData.prerequisites} setValue={(val) => setMoocData({...moocData, prerequisites: val})} />
								</div>
								<div className="line">
									<SizedTextarea label={"Skills"} maxSize={220} value={moocData.skills} setValue={(val) => setMoocData({...moocData, skills: val})} />
								</div>
							</div>
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Type your University, laboratory, other,....</label>
										<input type="text" value={moocData.organisation}
											   onChange={(event) => setMoocData({
												   ...moocData,
												   organisation: event.currentTarget.value
											   })}/>
									</div>
								</div>
							</div>
							<div className="thumbnail-section">
								<p className="title">Upload thumbnail</p>
								<div className="thumbnail-uploader">
									<input type="file" onChange={handleUpdateThumbnail} ref={updateThumbnailInputRef} />
									<div className="thumbnail">
										{moocData.thumbnail === null && (
											<div className="icon-thumbnail"><PiBookBold /></div>
										)}
										{moocData.thumbnail !== null && (
											<img src={moocData.thumbnail} alt="mooc thumbnail"/>
										)}
									</div>
									<div className="thumbnail-actions">
									<div className="btn-container">
											<p className="btn invert" onClick={() => updateThumbnailInputRef.current.click()}>Update</p>
											<p className="btn" onClick={() => useMoocStore.getState().updateCurrentMoocThumbnail(currentMooc.uuid, null)}>Remove</p>
										</div>
										<p className="infos">Image format with max size 3mb</p>
									</div>

								</div>
							</div>
						</div>
					)}
					{currentPanel === "eval" && (
						<div className="panel-content">
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Specify if there is a minimum score required for course
											progression.*</label>
										<div className="custom-selector">
											<div className="option-list">
												<p onClick={() => setMoocData({
													...moocData,
													minimumScoreRequired: true
												})} className={moocData.minimumScoreRequired ? "active" : ""}>Yes</p>
												<p onClick={() => setMoocData({
													...moocData,
													minimumScoreRequired: false
												})} className={!moocData.minimumScoreRequired ? "active" : ""}>No</p>
											</div>
										</div>
									</div>
								</div>
								{moocData.minimumScoreRequired && (
									<div className="line">
										<div className="panel-input">
											<label>Percentage of correct answers (%)</label>
											<input className="small" type="text" value={moocData.correctAnswersPercent}
												   onChange={(event) => setMoocData({
													   ...moocData,
													   correctAnswersPercent: event.currentTarget.value
												   })}/>
										</div>
									</div>
								)}
								<div className="line">
									<div className="panel-input">
										<label>MCQ attempt limit for course validation.*</label>
										<div className="custom-number-selector">
											<div className="btn"
												 onClick={() => moocData.quizAttemptLimit > 1 ? setMoocData({
													 ...moocData,
													 quizAttemptLimit: moocData.quizAttemptLimit - 1
												 }) : null}><IoMdRemove/></div>
											<p>{moocData.quizAttemptLimit}</p>
											<div className="btn" onClick={() => setMoocData({
												...moocData,
												quizAttemptLimit: moocData.quizAttemptLimit + 1
											})}><IoMdAdd/></div>
										</div>
									</div>
								</div>
							</div>
							<div className="panel-section">
								<h1 className="section-title">Certification</h1>
								<div className="line">
									<div className="panel-input">
										<label>Specify whether a certification is issued by Weeki or by the
											establishment</label>
										<select className="small" value={moocData.certificationIssuer}
												onChange={(event) => setMoocData({
													...moocData,
													certificationIssuer: event.currentTarget.value
												})}>
											<option value="none">None</option>
											<option value="weeki">Weeki</option>
											<option value="creator">Creator</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					)}
					{currentPanel === "pricing" && (
						<div className="panel-content">
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Pricing Type *</label>
										<div className="custom-selector">
											<div className="option-list">
												<p onClick={() => setMoocData({...moocData, price: 0})} className={moocData.price <= 0 ? "active": ""}>Free</p>
												<p onClick={() => setMoocData({...moocData, price: 1})} className={moocData.price > 0 ? "active": ""}>Paid</p>
											</div>
										</div>
									</div>
								</div>
								{moocData.price > 0 && (
									<div className="line">
										<div className="panel-input">
											<label>Story price (€) *</label>
											<input value={moocData.price} onChange={(event) => setMoocData({
												...moocData,
												price: parseInt(event.currentTarget.value)
											})} type="number" className="small"/>
										</div>
									</div>
								)}
							</div>
							{/*<div className="panel-section">*/}
							{/*	<h1 className="section-title">Special offer</h1>*/}
							{/*	<div className="line">*/}
							{/*		<div className="panel-input">*/}
							{/*			<label>Schedule a sales period ?</label>*/}
							{/*			<input className="small" type="date"/>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}
						</div>
					)}
					{currentPanel === "publish" && (
						<div className="panel-content">
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Publish the course on Weeki website</label>
										<div className="custom-selector">
											<div className="option-list">
												<p onClick={() => !moocData.publish ? setMoocData({...moocData, publish: true, publishState: 1}) : null} className={moocData.publish ? "active": ""}>Yes</p>
												<p onClick={() => moocData.publish ? setMoocData({...moocData, publish: false, publishState: 0}) : null} className={!moocData.publish ? "active": ""}>No</p>
											</div>
										</div>
									</div>
									<div className="panel-input">
										<label>Publication status</label>
										<div className="state-infos">
											<div className="state">
												{moocData.publishState === 0 && ("Not published")}
												{moocData.publishState === 1 && ("Requested")}
												{moocData.publishState === 2 && ("Published")}
												{moocData.publishState === 3 && ("Refused")}
											</div>
											{/*<p className="state-text">Cause: Your story does not comply with the*/}
											{/*	regulations...<br/>Find the publication rules on Weeki.io/cgv</p>*/}
										</div>
									</div>
								</div>
								<div className="line">
									<div className="panel-input">
										<label>Organisation</label>
										<div className="custom-selector">
											<div className="option-list">
												<p onClick={() => !moocData.organisationState ? setMoocData({
													...moocData,
													organisationState: true,
												}) : null} className={moocData.organisationState ? "active" : ""}>Yes</p>
												<p onClick={() => moocData.organisationState ? setMoocData({
													...moocData,
													organisationState: false,
												}) : null} className={!moocData.organisationState ? "active" : ""}>No</p>
											</div>
										</div>
									</div>
								</div>
								{moocData.organisationState && (
									<div className="line">
										<SizedTextarea label="Organisation description" maxSize={200} value={moocData.organisationDescription} setValue={(val) => setMoocData({...moocData, organisationDescription: val})} />
									</div>
								)}
							</div>
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Assign credits to the different collaborators or creators of the
											course</label>
										<input type="text" placeholder="Enter the name of a collaborator"/>
									</div>
								</div>
							</div>
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Add tags</label>
										<input onKeyDown={(event) => event.key === "Enter" ? handleAddTag(event.currentTarget.value) : null} type="text" className="small" placeholder="Tag"/>
									</div>
								</div>
								<div className="tags-list">
									{moocData.tags && (
										<>
											{moocData.tags.map((tag) => (
												<div className="tag">
													<p className="text">{tag}</p>
													<p className="icon" onClick={() => setMoocData({...moocData, tags: moocData.tags.filter(t => t !== tag)})}><VscChromeClose/></p>
												</div>
											))}
										</>
									)}
								</div>
							</div>
							<div className="panel-section">
								<div className="line">
									<div className="panel-input">
										<label>Allow participant to download the story</label>
										<div className="custom-selector">
											<div className="option-list">
												<p onClick={() => setMoocData({...moocData, allowDownload: true})} className={moocData.allowDownload ? "active" : ""}>Yes</p>
												<p onClick={() => setMoocData({...moocData, allowDownload: false})} className={!moocData.allowDownload ? "active" : ""}>No</p>
											</div>
										</div>
									</div>
									<div className="panel-input">
										<label>Choose licence</label>
										<select onChange={(event) => setMoocData({...moocData, license: event.currentTarget.value})} value={moocData.license}>
											<option value="">Choose license type</option>
											{Object.keys(globalLicenseType).map((key) => (
												<option value={globalLicenseType[key]}>{key}</option>
											))}
										</select>
									</div>
								</div>
								<div className="line">
									<UploadFileComponent multiple={false} processUploadFile={handleUploadCourseFile} uploadedFiles={courseFile} />
								</div>
							</div>
						</div>
					)}
					{(currentPanel === "participants" && moocData.pricingType !== "free" && moocData.price > 0) && (
						<div className="panel-content">
							<div className="panel-section">
								<div className="action-bar">
									<div className="left">
										<p className="icon-btn"><FaFilter/></p>
										<div className="search-bar">
											<p className="icon"><BiSearch/></p>
											<input type="text" placeholder="Search"/>
										</div>
									</div>
									<div className="right">
										<div className="btn" onClick={() => setInviteUserPanelState(!inviteUserPanelState)}>New invite</div>
										{inviteUserPanelState && (
											<div className="invite-user-panel">
												<div className="header">
													<h1>Invite user</h1>
													<p className="close-btn" onClick={() => setInviteUserPanelState(false)}><VscChromeClose/></p>
												</div>
												<div className="content">
													<input value={inviteUserEmail} onChange={(event) => setInviteUserEmail(event.currentTarget.value)} type="text" placeholder="user@email.com"/>
													<p onClick={() => handleInviteUser("viewer")} className="send-btn">Send</p>
												</div>
											</div>
										)}

									</div>
								</div>
								<h1 className="section-title">Overview</h1>
								<div className="panel-card-container">
									<div className="panel-card">
										<p className="card-title">Number of participants</p>
										<p className="card-value">{userInvitations.length}</p>
									</div>
									<div className="panel-card">
										<p className="card-title">Average presence time</p>
										<p className="card-value">{convertSecondToStringTime(getAveragePresenceTime())}</p>
									</div>
									<div className="panel-card">
										<p className="card-title">Average results</p>
										<p className="card-value">0%</p>
									</div>
									<div className="panel-card large">
										<p className="card-title">Average advances</p>
										<div className="multi-progress-bar">
											{userInvitations.length > 0 && (
												<>
													{getAverageAdvance().tier1.percent > 0 && (
														<div className="bar"
															 style={{
																 width: getAverageAdvance().tier1.percent + '%',
																 backgroundColor: getAverageAdvance().tier1.color
															 }}>{getAverageAdvance().tier1.percent}%
														</div>
													)}
													{getAverageAdvance().tier2.percent > 0 && (
														<div className="bar"
															 style={{
																 width: getAverageAdvance().tier2.percent + '%',
																 backgroundColor: getAverageAdvance().tier2.color
															 }}>{getAverageAdvance().tier2.percent}%
														</div>
													)}
													{getAverageAdvance().tier3.percent > 0 && (
														<div className="bar"
															 style={{
																 width: getAverageAdvance().tier3.percent + '%',
																 backgroundColor: getAverageAdvance().tier3.color
															 }}>{getAverageAdvance().tier3.percent}%
														</div>
													)}
													{getAverageAdvance().tier4.percent > 0 && (
														<div className="bar"
															 style={{
																 width: getAverageAdvance().tier4.percent + '%',
																 backgroundColor: getAverageAdvance().tier4.color
															 }}>{getAverageAdvance().tier4.percent}%
														</div>
													)}
												</>
											)}
											{userInvitations.length <= 0 && (
												<div className="bar"
													 style={{
														 width: '100%',
														 backgroundColor: '#52A6FF'
													 }}>N/A
												</div>
											)}

										</div>
										{/*<div className="multi-progress-bar">*/}
										{/*	<div className="bar"*/}
										{/*		 style={{width: "4%", backgroundColor: "#D6D3D3"}}>4%*/}
										{/*	</div>*/}
										{/*	<div className="bar"*/}
										{/*		 style={{width: "12%", backgroundColor: "#FF5252"}}>12%*/}
										{/*	</div>*/}
										{/*	<div className="bar"*/}
										{/*		 style={{width: "66%", backgroundColor: "#F3D369"}}>66%*/}
										{/*	</div>*/}
										{/*	<div className="bar"*/}
										{/*		 style={{width: "18%", backgroundColor: "#2DD7A7"}}>18%*/}
										{/*	</div>*/}
										{/*</div>*/}
									</div>
								</div>
								<div className="panel-table">
									<table>
										<thead>
										<tr>
											<td>Last name</td>
											<td>First name</td>
											<td>Email</td>
											<td>Advance</td>
											<td>Evaluation results</td>
											<td>Attendance time</td>
											<td>Origin</td>
											<td>Actions</td>
										</tr>
										</thead>
										<tbody>
										{userInvitationLoadingState && (
											<tr>
												<td colSpan={8}><ThreeDots
													wrapperStyle={{display: 'flex', justifyContent: 'center'}}
													color="#2953e8" width={35} height={30}/></td>
											</tr>
										)}
										{!userInvitationLoadingState && (
											<>
												{userInvitations.length > 0 && (
													<>
														{userInvitations.map((invitation) => (
															<tr>
																<td>{invitation.firstname ? invitation.firstname : "N/A"}</td>
																<td>{invitation.lastname ? invitation.lastname : "N/A"}</td>
																<td>{invitation.userEmail}</td>
																<td className="bar-cell">
																	<span style={{width: getAdvancePercentage(invitation) + "%", backgroundColor: getAdvanceColor(getAdvancePercentage(invitation))}} className="bar">{getAdvancePercentage(invitation)}%</span>
																</td>
																<td>0%</td>
																<td>{convertSecondToStringTime(invitation.presenceTime)}</td>
																<td>{invitation.origin}</td>
																<td>
																	{invitation.state === "pending" ? "Pending invitation" : ""}
																</td>
															</tr>
														))}
													</>
												)}
												{userInvitations.length === 0 && (
													<tr><td colSpan={8}>No participants</td></tr>
												)}
											</>
										)}
										{/*<tr>*/}
										{/*	<td>Doe</td>*/}
										{/*	<td>John</td>*/}
										{/*	<td>test@test.fr</td>*/}
										{/*	<td className="bar-cell">*/}

										{/*		<span className="bar">40%</span>*/}
										{/*	</td>*/}
										{/*	<td>19%</td>*/}
										{/*	<td>1h49</td>*/}
										{/*	<td>External (from Weeki)</td>*/}
										{/*	<td></td>*/}
										{/*</tr>*/}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}
					{currentPanel === "collab" && (
						<div className="panel-content">
							<div className="panel-section">
								<div className="action-bar">
									<div className="left">
										<p className="icon-btn"><FaFilter/></p>
										<div className="search-bar">
											<p className="icon"><BiSearch/></p>
											<input type="text" placeholder="Search"/>
										</div>
									</div>
									<div className="right">
										<div className="btn" onClick={() => setInviteUserPanelState(!inviteUserPanelState)}>New invite</div>
										{inviteUserPanelState && (
											<div className="invite-user-panel">
												<div className="header">
													<h1>Invite user</h1>
													<p className="close-btn" onClick={() => setInviteUserPanelState(false)}><VscChromeClose/></p>
												</div>
												<div className="content">
													<input value={inviteUserEmail} onChange={(event) => setInviteUserEmail(event.currentTarget.value)} type="text" placeholder="user@email.com"/>
													<p onClick={() => handleInviteUser("editor")} className="send-btn">Send</p>
												</div>
											</div>
										)}

									</div>
								</div>
								<h1 className="section-title">Overview</h1>
								<div className="panel-table">
									<table>
										<thead>
										<tr>
											<td>Last name</td>
											<td>First name</td>
											<td>Email</td>
											<td>Actions</td>
										</tr>
										</thead>
										<tbody>
										{userInvitationLoadingState && (
											<tr>
												<td colSpan={8}><ThreeDots
													wrapperStyle={{display: 'flex', justifyContent: 'center'}}
													color="#2953e8" width={35} height={30}/></td>
											</tr>
										)}
										{!userInvitationLoadingState && (
											<>
												{userInvitations.length > 0 && (
													<>
														{userInvitations.map((invitation) => (
															<tr>
																<td>{invitation.firstname ? invitation.firstname : "N/A"}</td>
																<td>{invitation.lastname ? invitation.lastname : "N/A"}</td>
																<td>{invitation.userEmail}</td>
																<td>
																	{invitation.state === "pending" ? "Pending invitation" : ""}
																</td>
															</tr>
														))}
													</>
												)}
												{userInvitations.length === 0 && (
													<tr><td colSpan={4}>No collaborator</td></tr>
												)}
											</>
										)}

										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}
					<div className="panel-footer">
						<div className="save-btn" onClick={() => handleUpdateMooc()}>Save</div>
					</div>
				</div>

			</div>
		</>
	)
}

export default StorySettingsPanel