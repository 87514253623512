import {create} from "zustand";

export const useTextItemStore = create((set) => ({
	renderedTextItems: [],
	currentSelectedItem: null,
	selectedItemPopup: null,
	addRenderedTextItem: (data) => set((state) => ({
		renderedTextItems: [...state.renderedTextItems, data],
	})),
	removeRenderedTextItem: (uuid) => set((state) => ({
		renderedTextItems: state.renderedTextItems.filter(r => r.uuid !== uuid),
	})),
	disableTextRender: (uuid) => set((state) => ({
		renderedTextItems: state.renderedTextItems.map((o) =>
			o.uuid === uuid
				? ({...o, canRender: false})
				: o
		),
	})),
	updateRenderedTextItemValue: (uuid, value) => set((state) => ({
		renderedTextItems: state.renderedTextItems.map((o) =>
			o.uuid === uuid
				? ({...o, properties: {...o.properties, text: value}})
				: o
		),
	})),
	updateRenderedTextItemUpdateState: (uuid, value) => set((state) => ({
		renderedTextItems: state.renderedTextItems.map((o) =>
			o.uuid === uuid
				? ({...o, update: value})
				: o
		),
	})),
	updateRenderedTextItemSize: (uuid, size, minZlevel) => set((state) => ({
		renderedTextItems: state.renderedTextItems.map((o) =>
			o.uuid === uuid
				? ({...o, properties: {...o.properties, size: size, min_zlevel: minZlevel}, currentRenderedSize: size})
				: o
		),
	})),
	updateRenderedTextItemCanRemoveState: (uuid, value) => set((state) => ({
		renderedTextItems: state.renderedTextItems.map((o) =>
			o.uuid === uuid
				? ({...o, canRemove: value})
				: o
		),
	})),
	resetStore: () => set((state) => ({
		renderedTextItems: []
	}))
}))