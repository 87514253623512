import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {useMapZoneStore} from "../../services/Stores/MapZoneStore";
import UserAreaAPI from "../../services/API/User/UserAreaAPI";
import AreaAPI from "../../services/AreaAPI";
import {deleteZone} from "../../services/Utils/ZoneUtils";

const AreaConfirmMoveOverlay = ({state, setState, area, handler}) => {

	const handleMoveArea = (withContent) => {
		handler(withContent)
	}

	return (createPortal(
			<div className="custom__confirm-delete-popup-overlay">
				<div className="container">
					<div className="header">
						<p className="title">Move zone</p>
						<p className="icon" onClick={() => setState(false)}><VscChromeClose/></p>
					</div>
					<div className="content">
						<p className="text mb">Please confirm zone move</p>
					</div>
					<div className="footer">
						<div className="btn invert" onClick={() => handleMoveArea(false)}>Move zone</div>
						<div className="btn" onClick={() => handleMoveArea(true)}>Move with content</div>
					</div>
				</div>
			</div>, document.body)
	)

}

export default AreaConfirmMoveOverlay