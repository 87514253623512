import React, {useState} from "react";
import {IoIosSettings} from "react-icons/io";

const StoreDashboardStatCard = ({icon, title, value, augment = false, augmentPercent = 0, selectedYear, setSelectedYear, selectedType, setSelectedType, selectedField, setSelectedField, yearList}) => {
    const [menuStatus, setMenuStatus] = useState(false)

    return (
        <div className="admin-card">
            <div className="left">
                <img src={icon} alt="Card icon"/>
                <div className="text">
                    <p>{title}</p>
                    <p>{value}</p>
                </div>
            </div>
            <div className="right">
                <div className="selector store">
                    <div className="btn" onClick={() => setMenuStatus(!menuStatus)}>
                        <p><IoIosSettings /></p>
                    </div>
                    <div className={menuStatus ? "menu open" : "menu"}>
                        <p className="title">Select parameters</p>
                        <div className="select-container">
                            <div className="selector">
                                <p className="title">Year</p>
                                <div className="item">
                                    <div className="input">
                                        <input checked={selectedYear === "All"} onChange={() => selectedYear !== "All" ? setSelectedYear("All") : null} type="checkbox"/>
                                        <p className="label">All</p>
                                    </div>
                                    {yearList.map((y, index) => (
                                        <div key={index} className="input">
                                            <input checked={selectedYear === y} onChange={() => selectedYear !== y ? setSelectedYear(y) : null} type="checkbox"/>
                                            <p className="label">{y}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="selector">
                                <p className="title">Type</p>
                                <div className="item">
                                    <div className="input">
                                        <input checked={selectedType === "All"} onChange={() => selectedType !== "All" ? setSelectedType("All") : null} type="checkbox"/>
                                        <p className="label">All</p>
                                    </div>
                                    <div className="input">
                                        <input type="checkbox" checked={selectedType === "Mooc"} onChange={() => selectedType !== "Mooc" ? setSelectedType("Mooc") : null}/>
                                        <p className="label">Mooc</p>
                                    </div>
                                    <div className="input">
                                        <input type="checkbox" checked={selectedType === "Webinar"} onChange={() => selectedType !== "Webinar" ? setSelectedType("Webinar") : null}/>
                                        <p className="label">Webinar</p>
                                    </div>
                                    <div className="input">
                                        <input type="checkbox" checked={selectedType === "Model"} onChange={() => selectedType !== "Model" ? setSelectedType("Model") : null}/>
                                        <p className="label">Model</p>
                                    </div>
                                </div>
                            </div>
                            <div className="selector">
                                <p className="title">Field</p>
                                <div className="item">
                                    <div className="input">
                                        <input checked={selectedField === "All"} onChange={() => selectedField !== "All" ? setSelectedField("All") : null} type="checkbox"/>
                                        <p className="label">All</p>
                                    </div>
                                    <div className="input">
                                        <input type="checkbox" checked={selectedField === "Cybersecurity"} onChange={() => selectedField !== "Cybersecurity" ? setSelectedField("Cybersecurity") : null}/>
                                        <p className="label">Cybersecurity</p>
                                    </div>
                                    <div className="input">
                                        <input type="checkbox" checked={selectedField === "Statistics"} onChange={() => selectedField !== "Statistics" ? setSelectedField("Statistics") : null}/>
                                        <p className="label">Statistics</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoreDashboardStatCard