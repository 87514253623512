import {ADMIN_SUB_PLAN_API, SUBSCRIPTION_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function adminGetAll(){
    return getAxiosInstance().get(ADMIN_SUB_PLAN_API + "/get_all")
}

function getCustomPlanByCode(code){
    return getAxiosInstance().get(SUBSCRIPTION_API + "/get_custom_plan_by_code", {
        params: {
            code: code
        }
    })
}

function addNewSubscriptionPlan(planInfo){
    toast.loading("Subscription plan creation in progress", {toastId: "loadAlert"})
    const sendData = new FormData()
    sendData.append("name", planInfo.name)
    sendData.append("monthlyPriceHT", planInfo.monthlyPriceHT)
    sendData.append("yearlyPriceHT", planInfo.yearlyPriceHT)
    sendData.append("storageLimit", planInfo.storageLimit)
    sendData.append("guestLimit", planInfo.guestLimit)
    sendData.append("weeklyBackupLimit", planInfo.weeklyBackupLimit)
    sendData.append("customCode", planInfo.customCode)

    return getAxiosInstance().post(ADMIN_SUB_PLAN_API + "/add", sendData)
        .then((response) =>{
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function updateSubscriptionPlan(planInfo){
    toast.loading("Subscription plan update in progress", {toastId: "loadAlert"})
    const sendData = new FormData()
    sendData.append("id", planInfo.id)
    sendData.append("name", planInfo.name)
    sendData.append("monthlyPriceHT", planInfo.monthlyPriceHT)
    sendData.append("yearlyPriceHT", planInfo.yearlyPriceHT)
    sendData.append("storageLimit", planInfo.storageLimit)
    sendData.append("guestLimit", planInfo.guestLimit)
    sendData.append("weeklyBackupLimit", planInfo.weeklyExportLimit)
    sendData.append("_method", "PUT")

    return getAxiosInstance().post(ADMIN_SUB_PLAN_API + "/update", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if(response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function deleteSubscriptionPlan(planId){
    toast.loading("Subscription plan delete in progress", {toastId: "loadAlert"})
    return getAxiosInstance().delete(ADMIN_SUB_PLAN_API + "/delete", {
        params: {
            id: planId
        }
    })
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response) {
                if (response.status === 200) {
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

export default {
    adminGetAll,
    deleteSubscriptionPlan,
    addNewSubscriptionPlan,
    updateSubscriptionPlan,
    getCustomPlanByCode
}