import { create } from "zustand";

export const useSearchStore = create((set) => ({
    isOpen: false,
    query: "",
    selectedItem: {},
    toggleOpenState: () => set((state) => ({ isOpen: !state.isOpen })),
    setQuery: (query) => set({ query }),
    setSelectedItem: (item) => set({ selectedItem: item }),

    // ResetStore
    reset: () => set(() => ({ isOpen: false, query: "", selectedItem: {} })),
}))