import React, {Fragment, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {useUserStore} from "../../../services/Stores";
import {createErrorToast} from "../../../services/CustomToastUtils";
import UserUserAuthorizationAPI from "../../../services/API/User/UserUserAuthorizationAPI";

const ManageAuthorizationArrayPopup = ({setState, userInvitations, availableAuthorizations, entityUuid, setUserInvitations}) => {
	const [customUserInvitation, setCustomUserInvitation] = useState([])

	useEffect(() => {
		const temp = structuredClone(userInvitations)
		setCustomUserInvitation(temp)
	}, [userInvitations, availableAuthorizations]);

	//Toggle authorization in invitation
	const handleToggleAuthorization = (invitation, authorization) => {
		if (invitation.owner){
			createErrorToast("You can't update owner authorizations")
			return
		}

		if (invitation.userEmail === useUserStore.getState().user.email){
			createErrorToast("You can't manage your own authorizations")
			return;
		}

		if (invitation.userAuthorizations.includes(authorization.key)){
			setCustomUserInvitation(customUserInvitation.map((ui) => ui.uuid === invitation.uuid ? {...ui, userAuthorizations: ui.userAuthorizations.filter(a => a !== authorization.key)} : ui))
		} else {
			setCustomUserInvitation(customUserInvitation.map((ui) => ui.uuid === invitation.uuid ? {...ui, userAuthorizations: [...ui.userAuthorizations, authorization.key]} : ui))
		}
	}

	const handleSavePermission = () => {
		customUserInvitation.forEach(ci => {
			//Get related invitation
			const invitation = userInvitations.filter(i => i.uuid === ci.uuid)[0]

			availableAuthorizations.forEach(aa => {
				//Add authorization
				if (ci.userAuthorizations.includes(aa.key) && !invitation.userAuthorizations.includes(aa.key)){
					UserUserAuthorizationAPI.addUserAuthorization(entityUuid, aa.uuid, invitation.uuid)
				}

				//Remove authorization
				if (invitation.userAuthorizations.includes(aa.key) && !ci.userAuthorizations.includes(aa.key)){
					UserUserAuthorizationAPI.removeUserAuthorization(entityUuid, aa.uuid, invitation.uuid)
				}
			})
		})

		setUserInvitations(customUserInvitation)
		setState(false)
	}

	return createPortal(
		<div className="custom__dashboard-popup-overlay">
			<div className="container large">
				<div className="popup-header">
					<h1 className="popup-title">Manage board authorization</h1>
					<p className="popup-close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
				</div>
				<div className="popup-content">
					<div className="authorization-table-container">
						<table>
							<thead>
								<tr>
									<td>User</td>
									{availableAuthorizations.map((aa) => (
										<td>{aa.description}</td>
									))}
								</tr>
							</thead>
							<tbody>
								{customUserInvitation.filter(ui => ui.state === "accepted").map((ui) => (
									<tr>
										<td>{ui.userEmail}</td>
										{availableAuthorizations.map((aa) => (
											<td><input onChange={() => handleToggleAuthorization(ui, aa)} type="checkbox" checked={ui.userAuthorizations.includes(aa.key)} disabled={ui.owner || ui.userEmail === useUserStore.getState().user.email} /></td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className="popup-footer">
					<p className="popup-btn" onClick={() => setState(false)}>Cancel</p>
					<p className="popup-btn valid" onClick={handleSavePermission}>Save</p>
				</div>
			</div>
		</div>
		, document.body)
}

export default ManageAuthorizationArrayPopup