import React, {Fragment, useEffect, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import MapNavBar from "../components/MapNavBar";
import MapAPI from "../services/MapAPI";
import {HiEllipsisHorizontal} from "react-icons/hi2";
import {IoMdSettings} from "react-icons/io";
import {MdClose, MdOutlinePolyline, MdOutlineTextFields, MdPolyline} from "react-icons/md";
import {VscChromeClose} from "react-icons/vsc";
import {toast} from "react-toastify";
import SavingBarPopup from "../components/SavingBarPopup";
import OfflinePopup from "../components/OfflinePopup";
import MapObjectsTable from "../components/MapObjectsTable";
import {useMenuState} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css"
import "@szhsin/react-menu/dist/index.css"
import OverlaySideMenu from "../components/Map/OverlaySideMenu";
import AppSearchAPI from "../services/AppSearchAPI";
import {useOnClickOutside} from "../services/Utils";
import ObjectsAPI from "../services/ObjectsAPI";
import {Range} from "rc-slider";
import LeafletMap from "../components/LeafletMap";

//IMPORT ICON
import {ReactComponent as SearchIcon} from "../../icons/map/search.svg"
import { PiFunnelBold } from "react-icons/pi"
import { PiStackBold } from "react-icons/pi"
import { PiSelectionAllBold } from "react-icons/pi"
import { PiTextTBold } from "react-icons/pi";
import { PiUploadSimpleBold } from "react-icons/pi"
import {ReactComponent as CenterIcon} from "../../icons/map/center.svg"
import { PiMinusBold } from "react-icons/pi";
import { PiPlusBold } from "react-icons/pi";
import {ReactComponent as ZoneOutIcon} from "../../icons/map/zone.svg"
import {ReactComponent as AddLineIcon} from "../../icons/map/add-line.svg"
import {ReactComponent as MoocIcon} from "../../icons/map/mooc.svg"
import UsersOnMapAPI from "../services/UsersOnMapAPI";
import {MERCURE_URL} from "../../Router";
import {useCookies} from "react-cookie";
import AddObjectPopup from "../components/Map/ObjectPopup/AddObjectPopup";
import ObjectLevel3Popup from "../components/Map/ObjectPopup/ObjectLevel3Popup";

//IMPORT OBJECT NO BACKGROUND ICONS
import FullScreenPDF from "../components/Map/FullScreenPDF";
import ContentItemAPI from "../services/ContentItemAPI";
import ObjectSidebar from "../components/Map/ObjectSidebar/ObjectSidebar";
import ContentItemTypeAPI from "../services/ContentItemTypeAPI";
import ObjectFilterPanel from "../components/Map/ObjectFilterPanel";
import ManageOverlayAndZoneSideMenu from "../components/Map/ManageOverlayAndZoneSideMenu";
import ObjectFullscreen from "../components/Map/ObjectFullscreen";
import {useMapStore} from "../services/Stores";
import CreateObjectPopup from "../components/Map/CreateObjectPopup";
import {BiShapePolygon, BiShapeSquare, BiShapeTriangle, BiText} from "react-icons/bi";
import PublicsOverlayAPI from "../services/API/Publics/PublicsOverlayAPI";
import OverlayAPI from "../services/OverlayAPI";
import {useSidebarMapContentItemStore} from "../services/Stores/SidebarMapContentItemStore";
import MoocStoriesSidebar from "../components/moocs/MoocStoriesSidebar";
import {useMoocStore} from "../services/Stores/MoocStore";
import MoocCourseSidebar from "../components/moocs/MoocCourseSidebar";
import {useContentItemStore} from "../services/Stores/ContentItemStore";
import {updateMoocCoursePosition} from "../services/Utils/MoocUtils";
import MoocQuizSidebar from "../components/moocs/MoocQuizSidebar";
import AuthAPI from "../services/AuthAPI";
import L from "leaflet";
import TutorialTooltip from "../components/customs/TutorialTooltip";
import {useTutorialStore} from "../services/Stores/TutorialStore";
import {checkMapTutorial, endMapTuto, openMapTutorial, restartMapTutorial} from "../services/Utils/TutorialUtils";
import AreaConfirmDeletePopup from "../components/Map/AreaConfirmDeletePopup";
import {BsCircle} from "react-icons/bs";
import {useObjectTableStore} from "../services/Stores/ObjectTableStore";
import UserSearchAPI from "../services/API/User/UserSearchAPI";
import {FaRegSquareFull} from "react-icons/fa6";
import {flyToCoordinates} from "../services/Utils/MapUtils";
import {GrSelect} from "react-icons/gr";
import {FaList} from "react-icons/fa";
import {useMapGlobalSelectionStore} from "../services/Stores/MapGlobalSelectionStore";

const Map = () => {
    //Get data in store
    const mapInstance = useMapStore(state => state.mapInstance)
    const userAuthorizations = useMapStore(state => state.userAuthorizations)

    //Get map uuid in route params
    const {mapUuid} = useParams()

    //Get hide interface state from store
    const hideInterfaceState = useMapStore(state => state.hideInterface)

    //Get mooc set position state
    const moocSetPositionState = useMoocStore(state => state.setPositionState)

    //Get react cookie
    const [cookies, setCookie] = useCookies(['mercureAuthorization', 'maps-default-overlay', 'maps-tutorial'])

    //Define default state for opened sidebar object
    const [openedSidebarObject, setOpenedSidebarObject] = useState("")
    const [openedSidebarPanel, setOpenedSidebarPanel] = useState("info")

    //Define default state for opened full screen object
    const [openedFullscreenObject, setOpenedFullscreenObject] = useState("")

    //Get overlay list on map load
    useEffect(() => {
        useMapStore.setState({mapUuid: mapUuid})
        useMapStore.setState({overlayLoading: true})
        useMapStore.setState({publicMap: false})
        useMapStore.setState({overlays: []})

        checkMapTutorial()

        OverlayAPI.getMapOverlays(mapUuid).then((response) => {
            if (response.status === 200) {

                if (cookies['maps-default-overlay'] !== undefined) {
                    if (cookies['maps-default-overlay'][mapUuid] !== undefined) {
                        useMapStore.setState({currentOverlay: cookies['maps-default-overlay'][mapUuid]})
                    } else {
                        const cookieValue = {}
                        cookieValue[mapUuid] = response.data[0].uuid
                        setCookie('maps-default-overlay', cookieValue)
                        useMapStore.setState({currentOverlay: response.data[0].uuid})
                    }
                } else {
                    const cookieValue = {}
                    cookieValue[mapUuid] = response.data[0].uuid
                    setCookie('maps-default-overlay', cookieValue)
                    useMapStore.setState({currentOverlay: response.data[0].uuid})
                }

                let overlaysList = structuredClone(response.data)
                overlaysList = overlaysList.map(ol => ({...ol, default: useMapStore.getState().currentOverlay === ol.uuid, active: true}))

                const activeOverlay = overlaysList.filter(o => o.active).map(o => o.uuid)
                useMapStore.getState().updateOverlayFilterList(activeOverlay)

                useMapStore.setState({overlays: overlaysList})
                useMapStore.setState({overlayLoading: false})
            }
        })
    }, [])

    const overlays = useMapStore(state => state.overlays)
    useEffect(() => {
        const activeOverlay = overlays.filter(o => o.active).map(o => o.uuid)
        useMapStore.getState().updateOverlayFilterList(activeOverlay)
    }, [overlays]);

    const handleOpenSidebarObject = (itemUuid, panel = null) => {
        setOpenedSidebarObject(itemUuid)

        if (panel !== null){
            setOpenedSidebarPanel(panel)
        }
    }

    //Define default state for content item categories list
    const [contentItemCategoriesList, setContentItemCategoriesList] = useState([{
        'name': "",
        'code': "",
        'types': []
    }])

    //Define default state for user content item type list
    const [contentItemTypeUserList, setContentItemTypeUserList] = useState([{
        'code': "",
        'name': "",
        'category': "",
        'icon': "",
        'mimeType': [],
        'authorizedDomain': [],
        'availableFields': [],
        'thumbnail': false,
        'needObjectName': false,
        'authorizedExtensions': [],
        'needCreateFile': false
    }])

    //Get all user on map
    useEffect(() => {
        //Get content item categories list
        ContentItemTypeAPI.getContentItemCategories().then((response) => {
            if (response.status === 200){
                setContentItemCategoriesList(response.data)
                useMapStore.setState({itemCategories: response.data})
            }
        })

        //Get content item type user list
        ContentItemTypeAPI.getContentItemTypeUserList().then((response) => {
            if (response.status === 200){
                setContentItemTypeUserList(response.data)
            }
        })

        if (mapUuid){
            UsersOnMapAPI.addUserOnMap(mapUuid).then(r => null)

            UsersOnMapAPI.getAllUsersOnMap(mapUuid).then((response) => {
                if (response.status === 200){
                    useMapStore.setState({usersOnMap: response.data})
                }
            })
        }

        return () => {
            UsersOnMapAPI.removeUserOnMap(mapUuid).then()
        };
    }, [mapUuid])

    //Define default state for map object
    const [map, setMap] = useState({
        "id": "",
        "name": "",
        "public": false,
        "password": false,
        "searchApiKey": "",
        'mercureTilesRefreshTopic': "",
        'ownerUuid': ""
    })

    const location = useLocation()
    const [mapLoadingState, setMapLoadingState] = useState(false)
    //Get map info and get map items
    useEffect(() => {
        const params = new URLSearchParams(location.search)
        MapAPI.getMapByUuid(mapUuid).then((response) => {
            if (response.status === 200){
                useMapStore.setState({userAuthorizations: response.data.userAuthorizations})
                if (params.get('x') && params.get('y') && params.get('z')){
                    const latLng = L.Projection.SphericalMercator.unproject(L.point(params.get('x'), params.get('y')))
                    useMapStore.setState({mapCenter: latLng})
                    useMapStore.setState({mapZoom: parseInt(params.get('z'))})

                } else {
                    useMapStore.setState({mapCenter: response.data.mapCenter})
                    useMapStore.setState({mapZoom: response.data.mapZoom})
                }
                setZoomLevel(response.data.mapZoom)
                setMap(response.data)
                useMapStore.setState({searchApiKey: response.data.searchApiKey})
                setMapLoadingState(true)
            }
        })
    }, [mapUuid])

    //Define default state for item category object
    const [contentItemCategories, setContentItemCategories] = useState([{
        "code": "",
        "name": ""
    }])

    //Define default state for item type object
    const [contentItemTypes, setContentItemTypes] = useState([{
        "code": "",
        "name": ""
    }])

    //Define default state for zoom level
    const [zoomLevel, setZoomLevel] = useState(10)
    const maxZoom = 18
    const minZoom = 1

    //Define default state for coordinates
    const [coordinates, setCoordinates] = useState({x: 1234, y: 1234})

    const copyCoordinatesToClipboard = () => {
        navigator.clipboard.writeText(coordinates.x + "," + coordinates.y)
        toast.success("Coordinates copied to clipboard")
    }

    //Define default state for objects table
    const [objectsTableStatus, setObjectsTableStatus] = useState(false)

    const updateMapInfos = () => {
        MapAPI.getMapByUuid(mapUuid).then((response) => setMap(response.data))
    }


    const [mapContextMenuState, setMapContextMenuState] = useMenuState({
        transition: true
    })
    const [mapContextMenuAnchorPoint, setMapContextMenuAnchorPoint] = useState({x: 0, y: 0})

    const openMapContextMenu = (event) => {
        event.preventDefault();
        setMapContextMenuAnchorPoint({x: event.clientX, y: event.clientY})
        setMapContextMenuState(true)
    }


    const [zoneContextMenuState, setZoneContextMenuState] = useMenuState({
        transition: true
    })
    const [zoneContextMenuAnchorPoint, setZoneContextMenuAnchorPoint] = useState({x: 0, y: 0})

    const openZoneContextMenu = (event) => {
        event.stopPropagation()
        event.preventDefault();
        setZoneContextMenuAnchorPoint({x: event.clientX, y: event.clientY})
        setZoneContextMenuState(true)
    }


    const [objectContextMenuState, setObjectContextMenuState] = useMenuState({
        transition: true
    })

    const [objectContextMenuAnchorPoint, setObjectContextMenuAnchorPoint] = useState({x: 0, y: 0})

    const openObjectContextMenu = (event) => {
        event.stopPropagation()
        event.preventDefault();
        setObjectContextMenuAnchorPoint({x: event.clientX, y: event.clientY})
        setObjectContextMenuState(true)
    }

    //Panel sub menu
    const [addObjectSubMenu, setAddObjectSubMenu] = useState(false)

    /******************SAVING BAR********************/
    const [savingBarStatus, setSavingBarStatus] = useState(false)
    const [savingBarValue, setSavingBarValue] = useState(0)
    const [savingBarRunning, setSavingBarRunning] = useState(false)
    let interval = undefined;

    const testSavingBar = () => {
        setSavingBarStatus(true)
        setSavingBarRunning(true)
    }

    useEffect(() => {
        if (savingBarRunning){
            interval = setInterval(() => {
                setSavingBarValue((prevState) => prevState + 1)
            }, 50)
            return () => clearInterval(interval)
        }
    }, [savingBarRunning])

    useEffect(() => {
        if (savingBarValue >= 100){
            setSavingBarRunning(false)
            setSavingBarValue(0)
            setSavingBarStatus(false)
            return () => clearInterval(interval)
        }
    }, [savingBarValue])
    /************************************************/

    /*******************SETTINGS PANEL***********************/
    const [settingsPanelStatus, setSettingsPanelStatus] = useState(false)

    const [zoomSensitivityValue, setZoomSensitivityValue] = useState(1)
    const [selectedNavigationMode, setSelectedNavigationMode] = useState("mouse")
    /********************************************************/

    //Define default state for object modal
    const [objectModalStatus, setObjectModalStatus] = useState(false)
    const [zoneModalStatus, setZoneModalStatus] = useState(false)
    const [exportModalStatus, setExportModalStatus] = useState(false)
    const [selectedExportType, setSelectedExportType] = useState("")
    const [selectedExportFormat, setSelectedExportFormat] = useState("")
    const [exportFileName, setExportFileName] = useState("")


    useEffect(() => {
        if (selectedExportType !== ""){
            setSelectedExportFormat("")
        }
    }, [selectedExportType])

    const exportData = () => {
        setExportModalStatus(false)
        toast.success("Data exported")
    }

    const [overlaySideMenuStatus, setOverlaySideMenuStatus] = useState(false)
    const [zoneSideMenuStatus, setZoneSideMenuStatus] = useState(false)

    /***********************************APP SEARCH**************************************/
    const [globalSearchInput, setGlobalSearchInput] = useState("")
    const [globalSearchScope, setGlobalSearchScope] = useState("area")
    const [globalSearchFiltersModalStatus, setGlobalSearchFiltersModalStatus] = useState(false)
    const [globalSearchResults, setGlobalSearchResults] = useState([])

    useEffect(() => {
        setGlobalSearchResults([])
    }, [globalSearchScope])

    useEffect(() => {
        if (globalSearchFiltersModalStatus){
            ObjectsAPI.getContentItemCategories().then((response) => setContentItemCategories(response.data))
            ObjectsAPI.getContentItemTypes().then((response) => setContentItemTypes(response.data))
        }
    }, [globalSearchFiltersModalStatus])

    const [globalSearchFilters, setGlobalSearchFilters] = useState({
        "creationDateStart": "",
        "creationDateEnd": "",
        "lastUpdateDateStart": "",
        "lastUpdateDateEnd": "",
        "category": "",
        "type": "",
        "minZoomStart": 0,
        "minZoomEnd": 50,
        "maxZoomStart": 0,
        "maxZoomEnd": 50,
        "sizeMin": 0,
        "sizeMax": 100,
        "color": ""
    })

    const closeGlobalSearchFilterModal = () => {
        //Check creation date filter
        if ((globalSearchFilters.creationDateStart !== "" && globalSearchFilters.creationDateEnd === "") || (globalSearchFilters.creationDateEnd !== "" && globalSearchFilters.creationDateStart === "")){
            toast.error("For filter by creation date please select start and end date")
            return
        }
        //Check last update date filter
        if ((globalSearchFilters.lastUpdateDateStart !== "" && globalSearchFilters.lastUpdateDateEnd === "") || (globalSearchFilters.lastUpdateDateEnd !== "" && globalSearchFilters.lastUpdateDateStart === "")){
            toast.error("For filter by last update date please select start and end date")
            return
        }

        setSearchOverlayResults([])
        setSearchOwnerResults([])
        setSearchTagsResults([])
        setSearchOverlayInputValue("")
        setSearchOwnerInputValue("")
        setSearchTagsInputValue("")

        setGlobalSearchFiltersModalStatus(false)
    }

    const clearGlobalSearchFilter = () => {
        setGlobalSearchFilters({
            "creationDateStart": "",
            "creationDateEnd": "",
            "lastUpdateDateStart": "",
            "lastUpdateDateEnd": "",
            "category": "",
            "type": "",
            "minZoomStart": 0,
            "minZoomEnd": 50,
            "maxZoomStart": 0,
            "maxZoomEnd": 50,
            "sizeMin": 0,
            "sizeMax": 100,
            "color": ""
        })

        setSelectedOverlays([])
        setSelectedOwner([])
        setSelectedTags([])
        setSearchOverlayResults([])
        setSearchOwnerResults([])
        setSearchTagsResults([])
        setSearchOverlayInputValue("")
        setSearchOwnerInputValue("")
        setSearchTagsInputValue("")
    }

    //Object owner filter
    const [searchOwnerResults, setSearchOwnerResults] = useState([])
    const [searchOwnerInputValue, setSearchOwnerInputValue] = useState("")
    const [selectedOwner, setSelectedOwner] = useState([])

    const searchOwner = async (event) => {
        const query = event.currentTarget.value

        setSearchOwnerInputValue(query)

        await AppSearchAPI.searchOwner(query, map.id, map.searchApiKey).then((response) => {
            const results = response.data.results

            const ownerList = []

            for (let i = 0; i < results.length; i++){
                if (!ownerList.includes(results[i].owner.raw)){
                    ownerList.push(results[i].owner.raw)
                }
            }

            setSearchOwnerResults(ownerList)
        })
    }

    const addOwnerToSelectedArray = (event) => {
        const owner = event.currentTarget.id

        const ownerExist = selectedOwner.some(e => {
            if (e === owner){
                toast.error("User already add to filter")
                return true
            }

            return false
        })

        if (!ownerExist){
            setSelectedOwner(selectedOwner => [...selectedOwner, owner])
        }
    }

    const removeOwnerFromSelectedArray = (event) => {
        const owner = event.currentTarget.id

        setSelectedOwner(selectedOwner.filter(o => o !== owner))
    }

    //Object tags filter
    const [searchTagsResults, setSearchTagsResults] = useState([])
    const [searchTagsInputValue, setSearchTagsInputValue] = useState("")
    // const [selectedTags, setSelectedTags] = useState([])

    const searchTags = async (event) => {
        const query = event.currentTarget.value

        setSearchTagsInputValue(query)

        await AppSearchAPI.searchTags(query, map.id, map.searchApiKey).then((response) => {
            const results = response.data.results

            const tagList = []

            for (let i = 0; i < results.length; i++){
                if (!tagList.includes(results[i].name.raw)){
                    tagList.push(results[i].name.raw)
                }
            }

            setSearchTagsResults(tagList)
        })
    }

    const addTagToSelectedArray = (event) => {
        const tag = event.currentTarget.id

        const tagExist = selectedTags.some(e => {
            if (e === tag){
                toast.error("Tag already add to filter")
                return true
            }

            return false
        })

        if (!tagExist){
            setSelectedTags(selectedTags => [...selectedTags, tag])
        }
    }

    const removeTagFromSelectedArray = (event) => {
        const tag = event.currentTarget.id

        setSelectedTags(selectedTags.filter(o => o !== tag))
    }

    //Overlay filter
    const [searchOverlayResults, setSearchOverlayResults] = useState([])
    const [searchOverlayInputValue, setSearchOverlayInputValue] = useState("")
    const [selectedOverlays, setSelectedOverlays] = useState([])

    const searchOverlay = async (event) => {
        const query = event.currentTarget.value

        setSearchOverlayInputValue(query)

        await AppSearchAPI.searchOverlay(query, map.id, map.searchApiKey).then((response) => {
            setSearchOverlayResults(response.data.results)
        })
    }

    const addOverlayToSelectedArray = (event) => {
        const overlayId = event.currentTarget.id.split('_')[0]
        const overlayName = event.currentTarget.id.split('_')[1]

        const overlayExist = selectedOverlays.some(e => {
            if (e.id === overlayId){
                toast.error("Overlay already add to filter")
                return true
            }

            return false
        })

        if (!overlayExist){
            setSelectedOverlays(selectedOverlays => [...selectedOverlays, {id: overlayId, name: overlayName}])
        }
    }

    const removeOverlayFromSelectedArray = (event) => {
        const overlayId = event.currentTarget.id

        setSelectedOverlays(selectedOverlays.filter(overlay => overlay.id !== overlayId))
    }

    //Global search
    const search = async (event) => {
        const searchInputValue = event.currentTarget.value

        setGlobalSearchInput(searchInputValue)

        if (globalSearchScope === 'area'){
            await AppSearchAPI.searchArea(searchInputValue, map.id, map.searchApiKey).then((response) => {
                setGlobalSearchResults(response.data.results)
            })
        } else {
            await AppSearchAPI.searchItemWithFilter(searchInputValue, map.id, map.searchApiKey, selectedOverlays, selectedOwner, selectedTags, globalSearchFilters).then((response) => {
                setGlobalSearchResults(response.data.results)
            })
        }
    }
    /***********************************************************************************/


    /***********************************TASK BAR****************************************/
    const [taskBarItems, setTaskBarItems] = useState([])

    const openItem = (item) => {
        item.open = true

        const itemExist = taskBarItems.some(e => {
            return e.id === item.id;
        })

        if (!itemExist){
            setTaskBarItems(taskBarItems => [...taskBarItems, item])
        }
    }

    const closeItem = (itemUuid) => {
        setTaskBarItems(taskBarItems.filter(i => i.id !== itemUuid))
    }

    const minimizeItem = (index) => {
        const newArray = [...taskBarItems]
        newArray[index].open = false
        setTaskBarItems(newArray)
    }

    const maximizeItem = (index) => {
        const newArray = [...taskBarItems]
        newArray[index].open = true
        setTaskBarItems(newArray)
    }
    /***********************************************************************************/

    /*********************************OBJECT PROPERTIES MODAL***************************/
    const [defaultObjectPropertiesModalStatus, setDefaultObjectPropertiesModalStatus] = useState(false)
    const [offlineVideoObjectPropertiesModalStatus, setOfflineVideoObjectPropertiesModalStatus] = useState(false)
    const [textObjectPropertiesModalStatus, setTextObjectPropertiesModalStatus] = useState(false)
    const [scriptObjectPropertiesModalStatus, setScriptObjectPropertiesModalStatus] = useState(false)
    const [pictureObjectPropertiesModalStatus, setPictureObjectPropertiesModalStatus] = useState(false)
    const [contactObjectPropertiesModalStatus, setContactObjectPropertiesModalStatus] = useState(false)
    const [audioObjectPropertiesModalStatus, setAudioObjectPropertiesModalStatus] = useState(false)
    const [contactObjectPopupStatus, setContactObjectPopupStatus] = useState(false)
    /***********************************************************************************/

    /*********************************MAP***********************************************/
    const [currentLayerPoint, setCurrentLayerPoint] = useState({
        x: 0,
        y: 0,
        long: 0,
        lat: 0
    })
    /***********************************************************************************/

    /***********************************MAP DRAG & DROP*********************************/
    const [mapDragBoxStatus, setMapDragBoxStatus] = useState(false)

    const dragLeave = () => {
        setTimeout(() => {
            setMapDragBoxStatus(false)
        }, 50)
    }

    const dropFiles = (event) => {
        event.preventDefault()
    }
    /***********************************************************************************/

    /**********************************ADD OBJECT POPUP*********************************/
    const [panelAddObjectStatus, setPanelAddObjectStatus] = useState(false)

    const [addObjectPopupStatus, setAddObjectPopupStatus] = useState(false)
    const [addObjectPopupType, setAddObjectPopupType] = useState(null)

    const handleCreateObject = (type) => {
        if (type !== null){
            setAddObjectPopupType(type)
        }

        setAddObjectPopupStatus(true)
        setPanelAddObjectStatus(false)
    }
    /***********************************************************************************/

    /**************************************MAP SEARCH***********************************/
    const [mapSearchQuery, setMapSearchQuery] = useState("")
    const [mapSearchQueryResults, setMapSearchQueryResults] = useState([])
    const mapSearchRef = useRef(null)
    useOnClickOutside(mapSearchRef, () => {
        setMapSearchQueryResults([])
        setMapSearchQuery("")
    })

    const handleMapSearch = async (event) => {
        const query = event.currentTarget.value

        if (query.length <= 0){
            setMapSearchQueryResults([])
            setMapSearchQuery("")
            return
        }

        setMapSearchQuery(query)

        UserSearchAPI.userSearchOnMap(query, map.id).then((response) => {
            setMapSearchQueryResults(response.data)
        })
    }

    const [viewObjectOnMapData, setViewObjectOnMapData] = useState({
        'location': "",
        'zlevel': ""
    })

    const viewObjectOnMap = (x, y, zlevel) => {
        let movePoint = L.point(x, y)
        let movePointLatLng = L.Projection.SphericalMercator.unproject(movePoint)

        useMapStore.getState().mapInstance.flyTo(movePointLatLng, zlevel, {
            animate: true,
            duration: 2
        })
    }
    /***********************************************************************************/


    const [openedContentItem, setOpenedContentItem] = useState([])

    const handleOpenObject = (objectProperties, clientX, clientY) => {
        objectProperties.active = true
        objectProperties.clientX = clientX
        objectProperties.clientY = clientY

        const alreadyOpen = openedContentItem.some(item => {
            return item.uuid === objectProperties.id
        })

        if (!alreadyOpen){
            setOpenedContentItem(openedContentItem => [...openedContentItem, objectProperties])
        }
    }

    const [fullScreenObjectProperties, setFullScreenObjectProperties] = useState({
        "type_code": ""
    })

    const handleCloseItem = (objectUuid) => {
        setOpenedContentItem(openedContentItem.filter(oci => oci.id !== objectUuid))
    }

    /**********************************OBJECT SIDEBAR*****************************/
    const [bibtexSidebarUuid, setBibtexSidebarUuid] = useState("")
    const [bibtexSidebarState, setBibtexSidebarState] = useState(false)

    const [objectSidebarUuid, setObjectSidebarUuid] = useState("")
    const [objectSidebarState, setObjectSidebarState] = useState(false)

    const handleOpenBibtexSidebar = (objectUuid) => {
        if (objectSidebarState){
            handleCloseObjectSidebar()
        }
        setBibtexSidebarUuid(objectUuid)
        setBibtexSidebarState(true)
        handleCloseItem(objectUuid)
    }

    const handleOpenObjectSidebar = (objectUuid) => {
        if (bibtexSidebarState){
            handleCloseBibtexSidebar()
        }
        setObjectSidebarUuid(objectUuid)
        setObjectSidebarState(true)
        handleCloseItem(objectUuid)
    }

    const handleCloseBibtexSidebar = () => {
        setBibtexSidebarState(false)
        setBibtexSidebarUuid("")
    }

    const handleCloseObjectSidebar = () => {
        setObjectSidebarState(false)
        setObjectSidebarUuid("")
    }

    const handleMinimizeBibtexSidebar = () => {
        setBibtexSidebarState(!bibtexSidebarState)
    }

    const handleMinimizeObjectSidebar = () => {
        setObjectSidebarState(!objectSidebarState)
    }
    /*****************************************************************************/

    const handleDeleteContentItem = async (itemUuid) => {
        if (await ContentItemAPI.deleteContentItem(itemUuid)){
            toast.success("delete ok")
        }
    }

    const [currentMapCenter, setCurrentMapCenter] = useState({
        "lat": 0,
        "long": 0,
        "x": 0,
        "y": 0
    })

    const [objectFilterPanelStatus, setObjectFilterPanelStatus] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedType, setSelectedType] = useState( [])
    const [selectedColors, setSelectedColors] = useState([])
    const [selectedTags, setSelectedTags] = useState([])

    useEffect(() => {
        useMapStore.getState().updateCategoryFilterList(selectedCategory)
    }, [selectedCategory])
    useEffect(() => {
        useMapStore.getState().updateTypeFilterList(selectedType)
    }, [selectedType])
    useEffect(() => {
        useMapStore.getState().updateColorFilterList(selectedColors)
    }, [selectedColors])
    useEffect(() => {
        useMapStore.getState().updateTagFilterList(selectedTags)
    }, [selectedTags])

    const [manageSideMenuStatus, setManageSideMenuStatus] = useState(false)
    const [currentOverlay, setCurrentOverlay] = useState("")
    const [visibleOverlayList, setVisibleOverlayList] = useState([])

    const handleOpenFullScreen = (itemId) => {
        setOpenedFullscreenObject(itemId)
        setOpenedSidebarObject("")
    }

    const handleResetZoomAndPosition = () => {
        if (mapInstance){
            const mapCenter = useMapStore.getState().mapCenter
            const mapZoom = useMapStore.getState().mapZoom
            mapInstance.setView([mapCenter.lat, mapCenter.lng], mapZoom)
        }
    }

    const [createTextStatus, setCreateTextStatus] = useState(false)

    const createTextState = useMapStore(state => state.createTextStatus)

    const mapFilterPopupRef = useRef(null)

    const sidebarMapContentItemState = useSidebarMapContentItemStore(state => state.state)

    //Get state for create object popup from store
    const createObjectPopupState = useMapStore(state => state.createItemPopupState)

    //Get current fullscreen item uuid in store
    const currentFullscreenItemUuid = useContentItemStore(state => state.currentFullscreenItemUuid)

    //Get mooc sidebar state
    const moocSidebarState = useMoocStore(state => state.storiesSidebarState)
    const drawAreaState = useMapStore(state => state.drawAreaStatus)

    const mapTutoSequence = useTutorialStore(state => state.mapTutoSequence)
    const mapTutoState = useTutorialStore(state => state.mapTutoState)

    const overlayLoadingState = useMapStore(state => state.overlayLoading)

    const [shapeItemSubPanelState, setShapeItemSubPanelState] = useState(false)

    const selectMode = useMapStore(state => state.selectMode)
    const copySelectionState = useMapGlobalSelectionStore(state => state.copySelectionState)
    const copyIndicatorPosition = useMapGlobalSelectionStore(state => state.copyIndicatorPosition)

    const handleFocusDataOnMap = (res) => {
        if (res.data_type === 'content_item'){
            flyToCoordinates(res.x, res.y, res.zlevel2)
        }
        if (res.data_type === 'zone'){
            flyToCoordinates(res.x, res.y, res.ref_zlevel)
        }
        if (res.data_type === 'text'){
            flyToCoordinates(res.x, res.y)
        }

        setMapSearchQuery("")
        setMapSearchQueryResults([])
    }

    return (
        <>
            {!hideInterfaceState && (
                <>
                    {/*CONFIRM ZONE DELETE POPUP*/}
                    <AreaConfirmDeletePopup />
                    {/*OBJECT SIDEBAR*/}
                    {sidebarMapContentItemState && (
                        <ObjectSidebar />
                    )}
                    {/*ADD OBJECT POPUP*/}
                    {createObjectPopupState && (
                        <CreateObjectPopup categoryList={contentItemCategoriesList} position={currentLayerPoint} overlay={currentOverlay} zoom={zoomLevel} mapCenter={currentMapCenter}/>
                    )}

                    {/*OBJECT FULL SCREEN*/}
                    {currentFullscreenItemUuid !== null && (
                        <ObjectFullscreen mapName={map.name} />
                    )}
                    <ManageOverlayAndZoneSideMenu visibleOverlayList={visibleOverlayList} setVisibleOverlayList={setVisibleOverlayList} currentOverlay={currentOverlay} setCurrentOverlay={setCurrentOverlay} state={manageSideMenuStatus} setState={setManageSideMenuStatus} mapUuid={mapUuid} />
                    {/*Page view*/}
                    {/*MOOC COMPONENTS*/}
                    <MoocStoriesSidebar />
                    <MoocCourseSidebar />
                    <MoocQuizSidebar />
                </>
            )}
            <MapNavBar map={map} updateMapInfo={updateMapInfos} handleRestartTutorial={restartMapTutorial} />
            <OfflinePopup />
            {mapTutoState && (
                <TutorialTooltip sequence={mapTutoSequence} handleEnd={endMapTuto}/>
            )}
            <div className="map-container" onContextMenu={openMapContextMenu}>
                {!hideInterfaceState && (
                    <>
                        {settingsPanelStatus && (
                            <div className={settingsPanelStatus ? "settings-panel open" : "settings-panel"}>
                                <div className="header">
                                    <div className="left">
                                        <p className="icon"><IoMdSettings /></p>
                                        <p className="title">Settings</p>
                                    </div>
                                    <p className="close-icon" onClick={() => setSettingsPanelStatus(false)}><MdClose /></p>
                                </div>
                                <div className="zoom-container">
                                    <p className="title">Zoom scale sensitivity:</p>
                                    <div className="input-box">
                                        <input type="range" min={1} max={16} value={zoomSensitivityValue} onChange={(event) => setZoomSensitivityValue(parseInt(event.currentTarget.value))}/>
                                        <p className="range-value">{zoomSensitivityValue}</p>
                                    </div>
                                </div>
                                <div className="navigation-mode">
                                    <p className="title">Preferred mode for navigation:</p>
                                    <div className="selector">
                                        <p className={selectedNavigationMode === "mouse" ? "selected" : null} onClick={() => selectedNavigationMode === "trackpad" ? setSelectedNavigationMode("mouse") : null}>Mouse</p>
                                        <p className={selectedNavigationMode === "trackpad" ? "selected" : null} onClick={() => selectedNavigationMode === "mouse" ? setSelectedNavigationMode("trackpad") : null}>Trackpad</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="map-search-bar-container" ref={mapSearchRef}>
                            <div className="map-search-bar">
                                <SearchIcon className="icon" />
                                <input value={mapSearchQuery} onChange={handleMapSearch} type="text" name="map-search" placeholder="Search for objects" />
                            </div>
                            {mapSearchQueryResults.length > 0 && (
                                <>
                                    <div className="map-search-result-container">
                                        {mapSearchQueryResults.map((res, index) => (
                                            <div key={index} className="map-search-result" onClick={() => handleFocusDataOnMap(res)}>
                                                <div className="left">
                                                    {res.data_type === "content_item" && (
                                                        <img src={res.icon_code !== undefined ? "/static/map/svg/" + res.icon_code + '.svg' : ""} alt="Item icon"/>
                                                    )}
                                                    {res.data_type === "zone" && (
                                                        <FaRegSquareFull />
                                                    )}
                                                    {res.data_type === "shape" && (
                                                        <BiShapeSquare />
                                                    )}
                                                    {res.data_type !== "text" && (
                                                        <p className="text">{res.name}</p>
                                                    )}
                                                    {res.data_type === "text" && (
                                                        <>
                                                            <MdOutlineTextFields />
                                                            <p className="text">{res.text}</p>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                        {copySelectionState && (
                            <div style={{top: copyIndicatorPosition.y - 120 + "px", left: copyIndicatorPosition.x + "px"}} className="copy-cursor-indicator">Drag selection for copy</div>
                        )}
                        <div className={"map-buttons-panel-container left"}>
                            <div className="panel-container">
                                <div className="panel">
                                    {/*<div className="panel-btn">*/}
                                    {/*    <ObjectTableIcon />*/}
                                    {/*    <p className="button-tooltip">Open objects table</p>*/}
                                    {/*</div>*/}
                                    <div className="panel-btn fill-icon" onClick={() => setObjectFilterPanelStatus(!objectFilterPanelStatus)}>
                                        <div className="hover-btn">
                                            <PiFunnelBold fontSize="1.8em" color="#4d4f5c"/>
                                        </div>
                                        {!objectFilterPanelStatus && (
                                            <p className="button-tooltip">Filter objects</p>
                                        )}
                                    </div>
                                    <div className="panel-btn fill-icon" onClick={() => setManageSideMenuStatus(true)}>
                                        <div className="hover-btn">
                                            <PiStackBold fontSize="1.8em" color="#4d4f5c"/>
                                        </div>
                                        <p className="button-tooltip">Manage overlays and zones</p>
                                    </div>
                                    <div className={"panel-btn fill-icon " + (selectMode ? "selected" : "")} onClick={() => useMapStore.setState({selectMode: !useMapStore.getState().selectMode})}>
                                        <div className="hover-btn">
                                            <GrSelect fontSize="1.8em" color="#4d4f5c"/>
                                        </div>
                                        <p className="button-tooltip">Select mode</p>
                                    </div>
                                    <div className="panel-btn fill-icon" onClick={() => useObjectTableStore.setState({openState: true})}>
                                        <div className="hover-btn">
                                            <FaList fontSize="1.8em" color="#4d4f5c"/>
                                        </div>
                                        <p className="button-tooltip">Object table</p>
                                    </div>
                                    {/*{AuthAPI.isAdmin() && (*/}
                                        <div className={!moocSidebarState ? "panel-btn fill-icon" : "panel-btn fill-icon selected"} onClick={() => useMoocStore.setState({storiesSidebarState: !useMoocStore.getState().storiesSidebarState})}>
                                            <div className="hover-btn">
                                                <MoocIcon fontSize="1.8em"/>
                                            </div>
                                            <p className="button-tooltip">Stories</p>
                                        </div>
                                    {/*// )}*/}
                                    {objectFilterPanelStatus && (
                                        <ObjectFilterPanel setState={setObjectFilterPanelStatus}
                                                           objectCategories={contentItemCategoriesList}
                                                           selectedCategory={selectedCategory}
                                                           setSelectedCategory={setSelectedCategory}
                                                           selectedType={selectedType} setSelectedType={setSelectedType}
                                                           selectedColors={selectedColors}
                                                           setSelectedColors={setSelectedColors}
                                                           selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                                    )}
                                </div>
                                {userAuthorizations.includes('map.edit') && (
                                    <div className="panel">
                                        <div className="panel-btn fill-icon" 
                                            onClick={() => {
                                                useMapStore.setState({
                                                    itemCreateFrom: 'panel',
                                                    createItemPopupState: true
                                                })
                                            }} >
                                            <div className="hover-btn">
                                                <PiPlusBold fontSize="1.8em" color="#4d4f5c"/>
                                            </div>
                                            <p className="button-tooltip">Add object</p>
                                        </div>
                                        <div className={!drawAreaState ? "panel-btn fill-icon" : "panel-btn fill-icon selected"}
                                            onClick={() => useMapStore.setState({drawAreaStatus: !useMapStore.getState().drawAreaStatus})}>
                                            <div className="hover-btn">
                                                <ZoneOutIcon fontSize="1.8em"/>
                                            </div>
                                            <p className="button-tooltip">Create zone</p>
                                        </div>
                                        <div className={"panel-btn fill-icon " + (shapeItemSubPanelState ? 'sub-open selected' : '')} onClick={() => setShapeItemSubPanelState(!shapeItemSubPanelState)}>
                                            <div className="hover-btn">
                                                <AddLineIcon fontSize="1.8em"/>
                                            </div>
                                            <p className="button-tooltip">Create shape</p>
                                            {shapeItemSubPanelState && (
                                                <div className="sub-panel-container">
                                                    <div className="panel-btn fill-icon"
                                                         onClick={() => useMapStore.setState({
                                                             drawShapeStatus: true,
                                                             drawShapeType: 'line'
                                                         })}>
                                                        <div className="hover-btn">
                                                            <AddLineIcon fontSize="1.8em"/>
                                                        </div>
                                                        <p className="button-tooltip">Create line</p>
                                                    </div>
                                                    <div className="panel-btn fill-icon"
                                                         onClick={() => useMapStore.setState({
                                                             drawShapeStatus: true,
                                                             drawShapeType: 'polyline'
                                                         })}>
                                                        <div className="hover-btn">
                                                            <MdOutlinePolyline />
                                                        </div>
                                                        <p className="button-tooltip">Create polyline</p>
                                                    </div>
                                                    <div className="panel-btn fill-icon" onClick={() => useMapStore.setState({
                                                        drawShapeStatus: true,
                                                        drawShapeType: 'polygon'
                                                    })}>
                                                        <div className="hover-btn">
                                                            <BiShapePolygon />
                                                        </div>
                                                        <p className="button-tooltip">Create polygon</p>
                                                    </div>
                                                    <div className="panel-btn fill-icon" onClick={() => useMapStore.setState({
                                                        drawShapeStatus: true,
                                                        drawShapeType: 'rectangle'
                                                    })}>
                                                        <div className="hover-btn ">
                                                            <BiShapeSquare />
                                                        </div>
                                                        <p className="button-tooltip">Create rectangle</p>
                                                    </div>
                                                    <div className="panel-btn fill-icon" onClick={() => useMapStore.setState({
                                                        drawShapeStatus: true,
                                                        drawShapeType: 'circle'
                                                    })}>
                                                        <div className="hover-btn ">
                                                            <BsCircle />
                                                        </div>
                                                        <p className="button-tooltip">Create circle</p>
                                                    </div>
                                                    {/*<div className="panel-btn fill-icon">*/}
                                                    {/*    <div className="hover-btn ">*/}
                                                    {/*        <AddLineIcon fontSize="1.8em"/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <p className="button-tooltip">Create circle</p>*/}
                                                    {/*</div>*/}
                                                </div>
                                            )}
                                        </div>
                                        <div className="panel-btn fill-icon">
                                            <div className="hover-btn">
                                                <PiUploadSimpleBold fontSize="1.8em" color="#4d4f5c"/>
                                            </div>
                                            <p className="button-tooltip">Upload</p>
                                        </div>
                                        <div className="panel-btn fill-icon"
                                             onClick={() => useMapStore.setState({createTextStatus: !useMapStore.getState().createTextStatus})}>
                                            <div className="hover-btn">
                                                <PiTextTBold fontSize="1.8em" color="#4d4f5c"/>
                                            </div>
                                            <p className="button-tooltip">Add text</p>
                                        </div>
                                        <div className={panelAddObjectStatus ? "sub-panel open" : "sub-panel"}>
                                            {contentItemTypeUserList.map((type, index) => (
                                                <Fragment key={index}>
                                                    {index < 10 && (
                                                        <>
                                                            <div className="sub-panel-btn"
                                                                 onClick={() => handleCreateObject(type)}>
                                                                <img src={"/static/map/svg/" + type.icon + '.svg'}
                                                                     alt={type.name + ' icon'}/>
                                                                <p className="button-tooltip">{type.name}</p>
                                                            </div>
                                                        </>
                                                    )}
                                                </Fragment>
                                            ))}
                                            <div className="sub-panel-btn" onClick={() => handleCreateObject(null)}>
                                                <p className="other-icon"><HiEllipsisHorizontal/></p>
                                                <p className="button-tooltip">Other</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/*<div className="panel-container">*/}
                            {/*    <div className="panel">*/}
                            {/*        <div className="panel-btn">*/}
                            {/*            <UndoIcon style={{transform: "rotate(-90deg)"}} />*/}
                            {/*            <p className="button-tooltip">Undo (CTRL + Z)</p>*/}
                            {/*        </div>*/}
                            {/*        <div className="panel-btn">*/}
                            {/*            <RedoIcon style={{transform: "rotate(-90deg)"}} />*/}
                            {/*            <p className="button-tooltip">Redo (CTRL + Y)</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </>
                )}
                {/*MOOC POSITION SETTER*/}
                {moocSetPositionState && (
                    <div className="map-define-coordinates-menu-container">
                        <h1>Define position or click on item for link to course</h1>
                        <div className="btn-container">
                            <p className="btn inverted" onClick={() => {
                                useMapStore.setState({hideInterface: false})
                                useMoocStore.setState({setPositionState: false, setPositionCourseUuid: null})
                            }}>Cancel</p>
                            <p className="btn" onClick={updateMoocCoursePosition}>Confirm</p>
                        </div>
                    </div>
                )}
                <div className="map-buttons-panel-container right">
                    <div className="panel-container"></div>
                    <div className="panel-container">
                        {/*<div className="panel">*/}
                        {/*    <div className="panel-btn fill-icon" onClick={() => setSettingsPanelStatus(true)}>*/}
                        {/*        <SettingsIcon />*/}
                        {/*        <p className="button-tooltip">Settings</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="panel">*/}
                        {/*    <div className="panel-btn fill-icon">*/}
                        {/*        <PlayIcon style={{transform: "rotate(-90deg)"}} />*/}
                        {/*        <p className="button-tooltip">Presentation mode</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="panel">*/}
                        {/*    <div className="panel-btn fill-icon">*/}
                        {/*        <FullscreenIcon />*/}
                        {/*        <p className="button-tooltip">Full screen mode</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="panel">
                            {/*<div className="panel-btn fill-icon" onClick={() => navigator.clipboard.writeText("https://app.weeki.io/public/map/" + mapUuid)}>*/}
                            {/*    <div className="hover-btn">*/}
                            {/*        <CenterIcon />*/}
                            {/*    </div>*/}
                            {/*    <p className="button-tooltip">Copy public URL</p>*/}
                            {/*</div>*/}
                            <div className="panel-btn fill-icon" onClick={handleResetZoomAndPosition}>
                                <div className="hover-btn">
                                    <CenterIcon/>
                                </div>
                                <p className="button-tooltip">Reset zoom and position</p>
                            </div>
                        </div>
                        <div className="panel">
                            <div className="panel-btn fill-icon" onClick={() => zoomLevel < maxZoom ? setZoomLevel(Math.floor(zoomLevel) + 1) : null}>
                                <div className="hover-btn">
                                    <PiPlusBold fontSize="1.8em" color="#4d4f5c"/>
                                </div>
                                <p className="button-tooltip">Zoom in</p>
                            </div>
                            <p className="panel-zoom-level">{Math.floor(zoomLevel)}</p>
                            <div className="panel-btn fill-icon" onClick={() => zoomLevel > minZoom ? setZoomLevel(Math.floor(zoomLevel) - 1) : null}>
                                <div className="hover-btn">
                                    <PiMinusBold fontSize="1.8em" color="#4d4f5c" />
                                </div>
                                <p className="button-tooltip">Zoom out</p>
                            </div>
                        </div>
                    </div>
                </div>
                {(!overlayLoadingState && mapLoadingState) && (
                    <LeafletMap createTextStatus={createTextStatus} setCreateTextStatus={setCreateTextStatus} setOpenedSidebarObject={setOpenedSidebarObject} handleOpenSidebarObject={handleOpenSidebarObject} mapSearchApiKey={map.searchApiKey} currentOverlay={currentOverlay} currentMapCenter={currentMapCenter} setCurrentMapCenter={setCurrentMapCenter} zoneSideMenuStatus={zoneSideMenuStatus} setZoneSideMenuStatus={setZoneSideMenuStatus} currentLayerPoint={currentLayerPoint} handleDeleteContentItem={handleDeleteContentItem} viewObjectOnMap={viewObjectOnMapData} setViewObjectOnMap={setViewObjectOnMapData} setCurrentLayerPoint={setCurrentLayerPoint} zoom={zoomLevel} setZoom={setZoomLevel} mapUuid={mapUuid} handleOpenObject={handleOpenObject} handleOpenFullScreen={handleOpenFullScreen}/>
                )}
                <div className="coordinates-container" onClick={copyCoordinatesToClipboard}>
                    <p className="coordinate">X: {currentMapCenter.x}</p>
                    <p className="separator">|</p>
                    <p className="coordinate">Y: {currentMapCenter.y}</p>
                </div>
            </div>
        </>
    )
}

export default Map