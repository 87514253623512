import {create} from "zustand";

export const useMercureStore = create((set) => ({
    mercureEventSource: null,
    mercureToken: ""
}))

export const useUserStore = create((set) => ({
    user: {},
    userLoad: false,
    userSubscription: null,
    confirmedAccount: false,
    updateUserValue: (key, value) => set((state) => ({
        user: {...state.user, [key]: value}
    }))
}))

export const useMapStore = create((set) => ({
    userAuthorizations: [],
    mapCenter: {},
    mapZoom: 10,
    mapUuid: "",
    publicMap: false,
    searchApiKey: null,
    mapInstance: null,
    ignoreUpdates: [],
    createTextStatus: false,
    createTextPopup: null,
    ignoreRender: [],
    currentDrag: false,
    drawAreaStatus: false,
    currentOverlay: null,
    overlays: [],
    overlayLoading: true,
    drawShapeStatus: false,
    drawShapeType: "line",
    screenState: false,
    itemCreateFrom: 'panel',
    createItemPopupState: false,
    usersOnMap: [],
    renderedLevel2Items: [],
    renderedWordItems: [],
    leafletLayer: [],
    hideInterface: false,
    itemCategories: [],
    mouseLatLng: {lat: 0, lng: 0},
    mapFilters: {},
    selectMode: false,
    removeOverlay: (uuid) => set((state) => ({
        overlays: state.overlays.filter(o => o.uuid !== uuid)
    })),
    updateOverlayName: (uuid, name) => set((state) => ({
        overlays: state.overlays.map((o) =>
            o.uuid === uuid
                ? ({...o, name: name})
                : o
        ),
    })),
    addNewUpdateId: (id) => set((state) => ({
        ignoreUpdates: [id, ...state.ignoreUpdates],
    })),
    removeUpdateId: (id) => set((state) => ({
        ignoreUpdates: state.ignoreUpdates.filter(i => i !== id),
    })),
    addNewIgnoreRender: (id) => set((state) => ({
        ignoreRender: [id, ...state.ignoreRender],
    })),
    removeIgnoreRender: (id) => set((state) => ({
        ignoreRender: state.ignoreRender.filter(i => i !== id),
    })),
    addRenderedLevel2: (data) => set((state) => ({
        renderedLevel2Items: [data, ...state.renderedLevel2Items],
    })),
    removeRenderedLevel2: (uuid) => set((state) => ({
        renderedLevel2Items: state.renderedLevel2Items.filter(r => r.uuid !== uuid),
    })),
    updateRenderedLevel2ThumbnailUrl: (uuid, url) => set((state) => ({
        renderedLevel2Items: state.renderedLevel2Items.map((o) =>
            o.uuid === uuid
                ? ({...o, thumbnailUrl: url})
                : o
        ),
    })),
    updateRenderedLevel2Name: (uuid, name) => set((state) => ({
        renderedLevel2Items: state.renderedLevel2Items.map((o) =>
            o.uuid === uuid
                ? ({...o, properties: {...o.properties, name: name}})
                : o
        ),
    })),
    addLeafletLayer: (layer) => set((state) => ({
        leafletLayer: [layer, ...state.leafletLayer]
    })),
    addNewOverlay: (data) => set((state) => ({
        overlays: [...state.overlays, data]
    })),
    updateOverlayState: (uuid, newState) => set((state) => ({
        overlays: state.overlays.map((o) =>
            o.uuid === uuid
                ? ({...o, active: newState})
                : o
        )
    })),
    updateOverlayFilterList: (uuids) => set((state) => ({
        mapFilters: {...state.mapFilters, overlay: uuids}
    })),
    updateTagFilterList: (tags) => set((state) => ({
        mapFilters: {...state.mapFilters, tag: tags}
    })),
    updateColorFilterList: (colors) => set((state) => ({
        mapFilters: {...state.mapFilters, color: colors}
    })),
    updateTypeFilterList: (types) => set((state) => ({
        mapFilters: {...state.mapFilters, type: types}
    })),
    updateCategoryFilterList: (categories) => set((state) => ({
        mapFilters: {...state.mapFilters, category: categories}
    })),
    updateOverlayAreas: (overlayUuid, areas) => set((state) => ({
        overlays: state.overlays.map((o) =>
            o.uuid === overlayUuid
                ? ({...o, areas: areas})
                : o
        )
    })),
    updateOverlayShapes: (overlayUuid, shapes) => set((state) => ({
        overlays: state.overlays.map((o) =>
            o.uuid === overlayUuid
                ? ({...o, shapes: shapes})
                : o
        )
    })),
    resetStore: () => set((state) => ({
        userAuthorizations: [],
        mapCenter: {},
        mapZoom: 10,
        mapUuid: "",
        publicMap: false,
        searchApiKey: null,
        mapInstance: null,
        ignoreUpdates: [],
        createTextStatus: false,
        createTextPopup: null,
        ignoreRender: [],
        currentDrag: false,
        drawAreaStatus: false,
        currentOverlay: null,
        overlays: [],
        overlayLoading: true,
        drawShapeStatus: false,
        drawShapeType: "line",
        screenState: false,
        itemCreateFrom: 'panel',
        createItemPopupState: false,
        usersOnMap: [],
        renderedLevel2Items: [],
        renderedWordItems: [],
        hideInterface: false,
        itemCategories: [],
        mouseLatLng: {lat: 0, lng: 0},
        mapFilters: {},
    }))
}))