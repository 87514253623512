import {MathJax} from "better-react-mathjax";
import UserMoocCourseAPI from "../API/User/Mooc/UserMoocCourseAPI";
import {useMoocStore} from "../Stores/MoocStore";

export function customLatexParser(data, config){
	return `<div class='parsed-latex-container' id="latex-parsed">${data.math}</div>`
}

export function customImageParser(data, config) {
	return `<div class="parsed-image-container"><img src="${data.file.url}" alt="${data.file.caption}" /></div>`
}

export function customSpaceParser(data, config){
	return `<div style="height: ${data.height * 20}px" class='parsed-space-container'></div>`
}