import {generateRandomId} from "./Utils";
import {useContentItemStore} from "./Stores/ContentItemStore";

async function invalidateArea(layer, source, area, rebuild = true) {
    //Block lvl2 refresh
    if (source === "weeki_map_items"){
        console.log("refresh item")
        useContentItemStore.setState({blockRefresh: true})
    }

    await layer.scene.invalidateSourceArea(source, area, generateRandomId())
    if (rebuild){
        setTimeout(async () => {
            await layer.scene.rebuild()
        }, 300)
    }
}

function getCurrentSource(layer) {
    // Returns last registered source
    let source = null;

    for(let s in layer.scene.sources) {
        source = layer.scene.sources[s];
    }
    return source.name;
}

export default {
    invalidateArea
}