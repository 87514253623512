import {useMoocStore} from "../Stores/MoocStore";
import {useMapStore} from "../Stores";
import L from "leaflet"
import UserMoocCourseAPI from "../API/User/Mooc/UserMoocCourseAPI";
import UserMoocQuizAPI from "../API/User/Mooc/UserMoocQuizAPI";

export function addNewMoocToStore(uuid){
	//Generate new mooc data
	const moocData = {
		'uuid': uuid,
		'name': "New story",
		'price': null,
		'available': false,
		'thumbnail': null,
		'author': "Weeki",
		'overview': null,
		'overviewName': "Overview"
	}

	//Add mooc to store
	useMoocStore.getState().addMoocToList(moocData)
}

export function addNewMoocChapterToStore(uuid){
	//Generate new chapter data
	const chapterData = {
		'uuid': uuid,
		'name': "New section",
		'open': false,
		'rename': false,
		'position': useMoocStore.getState().openedMoocChapters.length
	}

	//Add chapter to store
	useMoocStore.getState().addMoocChapter(chapterData)
}

export function addNewMoocCourseToStore(newCourseData, chapterUuid){
	//Generate new course data
	const courseData = {
		'uuid': newCourseData.uuid,
		'name': 'New subsection',
		'position': parseInt(newCourseData.position),
		'content': null,
		'type': "course"
	}

	//Add course to store
	if (useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid)[0].courses === undefined || useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid)[0].courses === null){
		useMoocStore.getState().setChapterCourses(chapterUuid, [courseData])
	} else {
		useMoocStore.getState().addChapterCourse(chapterUuid, courseData)
	}
}

export function addNewQuizToStore(newQuizData, chapterUuid) {
	//Generate new quiz data
	const quizData = {
		'uuid': newQuizData.uuid,
		'name': "New quiz",
		'position': parseInt(newQuizData.position),
		'validPercent': 100,
		'questions': [],
		'type': "quiz",
		'maxAttempt': 3,
		'userResult': null,
		'retryCountLeft': 1
	}

	//Add quiz to store
	if (useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid)[0].quizzes === undefined || useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid)[0].quizzes === null){
		useMoocStore.getState().setChapterQuiz(chapterUuid, [quizData])
	} else {
		useMoocStore.getState().addChapterQuiz(chapterUuid, quizData)
	}
}

export function updateMoocCoursePosition(){
	//Get updated course uuid
	const courseUuid = useMoocStore.getState().setPositionCourseUuid

	//Get map center and convert to point
	const mapCenterLatLng = useMapStore.getState().mapInstance.getCenter()
	const mapCenterPoint = L.Projection.SphericalMercator.project(mapCenterLatLng)

	//Get map current zoom
	const mapZoom = useMapStore.getState().mapInstance.getZoom()

	//Update course location with api
	UserMoocCourseAPI.userUpdateCourseLocation(courseUuid, mapCenterPoint.x, mapCenterPoint.y, mapZoom)

	//Update course position in store
	useMoocStore.getState().updateCourseLocation(
		useMoocStore.getState().openedMoocChapters.filter(c => c.courses !== undefined && c.courses !== null && c.courses.some(e => e.uuid === courseUuid))[0].uuid,
		courseUuid, {x: mapCenterPoint.x, y: mapCenterPoint.y}, mapZoom
	)

	//Set data in store
	useMoocStore.setState({setPositionCourseUuid: null, setPositionState: false})
	useMapStore.setState({hideInterface: false})
}

export function linkItemToMoocCourse(itemUuid) {
	//Get updated course uuid
	const courseUuid = useMoocStore.getState().setPositionCourseUuid

	//Get current map zoom
	const zoom = useMapStore.getState().mapInstance.getZoom()

	UserMoocCourseAPI.userLinkCourseItem(courseUuid, itemUuid, zoom)

	//Set data in store
	useMoocStore.setState({setPositionCourseUuid: null, setPositionState: false})
	useMapStore.setState({hideInterface: false})
}

export function reIndexSubsectionAfterPosition(chapterUuid, startPosition)
{
	//Get affected course and quiz
	const courses = useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid && c.courses !== undefined)[0].courses.filter(c => c.position > startPosition)
	const quizzes = useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid && c.quizzes !== undefined)[0].quizzes.filter(q => q.position > startPosition)

	console.log(courses, quizzes)

	courses.forEach(c => {
		useMoocStore.getState().updateCoursePosition(chapterUuid, c.uuid, c.position - 1)
	})

	quizzes.forEach(q => {
		useMoocStore.getState().updateQuizPosition(chapterUuid, q.uuid, q.position - 1)
	})
}

export function moveUpSection(currentPosition){

}

export function moveUpSubsection(chapterUuid, currentPosition) {
	const currentChapter = useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid)[0]
	const chapterSubsections = currentChapter.courses !== undefined ? currentChapter.courses.concat(currentChapter.quizzes !== undefined ? currentChapter.quizzes : []) : currentChapter.quizzes !== undefined ? currentChapter.quizzes : []

	if (chapterSubsections.length > 0){
		const currentSubsection = chapterSubsections.filter(s => s.position === currentPosition)[0]
		const previousSubsection = chapterSubsections.filter(s => s.position === currentPosition - 1)[0]

		if (currentSubsection.type === "course"){
			useMoocStore.getState().updateCoursePosition(chapterUuid, currentSubsection.uuid, currentSubsection.position - 1)
			UserMoocCourseAPI.userUpdateCoursePosition(currentSubsection.uuid, currentSubsection.position - 1)
		} else {
			useMoocStore.getState().updateQuizPosition(chapterUuid, currentSubsection.uuid, currentSubsection.position - 1)
			UserMoocQuizAPI.userUpdateQuizPosition(currentSubsection.uuid, currentSubsection.position - 1)
		}

		if (previousSubsection.type === "course"){
			useMoocStore.getState().updateCoursePosition(chapterUuid, previousSubsection.uuid, previousSubsection.position + 1)
			UserMoocCourseAPI.userUpdateCoursePosition(previousSubsection.uuid, previousSubsection.position + 1)
		} else {
			useMoocStore.getState().updateQuizPosition(chapterUuid, previousSubsection.uuid, previousSubsection.position + 1)
			UserMoocQuizAPI.userUpdateQuizPosition(previousSubsection.uuid, previousSubsection.position + 1)
		}
	}
}

export function moveDownSubsection(chapterUuid, currentPosition) {
	const currentChapter = useMoocStore.getState().openedMoocChapters.filter(c => c.uuid === chapterUuid)[0]
	const chapterSubsections = currentChapter.courses !== undefined ? currentChapter.courses.concat(currentChapter.quizzes !== undefined ? currentChapter.quizzes : []) : currentChapter.quizzes !== undefined ? currentChapter.quizzes : []

	if (chapterSubsections.length > 0){
		const currentSubsection = chapterSubsections.filter(s => s.position === currentPosition)[0]
		const nextSubsection = chapterSubsections.filter(s => s.position === currentPosition + 1)[0]

		if (currentSubsection.type === "course"){
			useMoocStore.getState().updateCoursePosition(chapterUuid, currentSubsection.uuid, currentSubsection.position + 1)
			UserMoocCourseAPI.userUpdateCoursePosition(currentSubsection.uuid, currentSubsection.position + 1)
		} else {
			useMoocStore.getState().updateQuizPosition(chapterUuid, currentSubsection.uuid, currentSubsection.position + 1)
			UserMoocQuizAPI.userUpdateQuizPosition(currentSubsection.uuid, currentSubsection.position + 1)
		}

		if (nextSubsection.type === "course"){
			useMoocStore.getState().updateCoursePosition(chapterUuid, nextSubsection.uuid, nextSubsection.position - 1)
			UserMoocCourseAPI.userUpdateCoursePosition(nextSubsection.uuid, nextSubsection.position - 1)
		} else {
			useMoocStore.getState().updateQuizPosition(chapterUuid, nextSubsection.uuid, nextSubsection.position - 1)
			UserMoocQuizAPI.userUpdateQuizPosition(nextSubsection.uuid, nextSubsection.position - 1)

		}
	}
}

export function getCurrentChapterBySubsectionUuid(uuid){
	return useMoocStore.getState().openedMoocChapters.filter(c => ((c.courses !== undefined && c.courses.some(co => co.uuid === uuid)) || (c.quizzes !== undefined && c.quizzes.some(co => co.uuid === uuid))))[0]
}

export function flyToMoocLocation(location, zoom){
	// console.log(location, zoom)
	let movePoint = L.point(location.x, location.y)
	let movePointLatLng = L.Projection.SphericalMercator.unproject(movePoint)
	// console.log(useMapStore.getState().mapInstance.getSize())
	// movePoint = useMapStore.getState().mapInstance.latLngToContainerPoint(movePointLatLng)
	// movePoint = movePoint.add([22, 0])
	// console.log(movePoint)

	// movePoint = movePoint.add([435, 0])
	// movePoint = movePoint.add([-435, 0])

	// movePoint = movePoint.subtract([0, 550])


	// console.log(movePoint)
	//Convert move point to lat lng
	// const movePointLatLng =
	//
	useMapStore.getState().mapInstance.flyTo(movePointLatLng, zoom, {
		animate: true,
		duration: 2
	})
}