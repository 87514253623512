import {useTextItemStore} from "../Stores/TextItemStore";
import TextItemAPI from "../API/User/TextItemAPI";

export const textItemSize = {
	'small': {
		'fontSize': 13,
		'fontWeight': 400,
		'zoomOffset': -1,
		'minZlevelOffset': 1
	},
	'normal': {
		'fontSize': 15,
		'fontWeight': 500,
		'zoomOffset': 0,
		'minZlevelOffset': 2
	},
	'large': {
		'fontSize': 18,
		'fontWeight': 500,
		'zoomOffset': 1,
		'minZlevelOffset': 3
	},
	'huge': {
		'fontSize': 22,
		'fontWeight': 500,
		'zoomOffset': 2,
		'minZlevelOffset': 4
	}
}

export function deleteSelectedTextItem(){
	//Get text item uuid
	const uuid = useTextItemStore.getState().currentSelectedItem

	//Delete text in api
	TextItemAPI.deleteTextItem(uuid)

	//Unselect text item
	if (useTextItemStore.getState().selectedItemPopup){
		useTextItemStore.getState().selectedItemPopup.remove()
	}
	if (useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === uuid)[0]){
		if (useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === uuid)[0].popup){
			useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === uuid)[0].popup.remove()
		}
	}

	//Disable text item render
	useTextItemStore.getState().disableTextRender(uuid)
}