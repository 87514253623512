import getAxiosInstance from "../../AxiosInstance";
import {USER_NOTIFICATION_API} from "../../../../Router";
import Axios from "axios";


function getAllUserNotification(){
    return getAxiosInstance().get(USER_NOTIFICATION_API + '/get_all_user_notification')
}

function readAllUserNotification(){
    return Axios.post(USER_NOTIFICATION_API + '/read_all_user_notification')
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
}

export default {
    getAllUserNotification,
    readAllUserNotification
}