import React, {useEffect} from "react";
import {useObjectTableStore} from "../../../../services/Stores/ObjectTableStore";
import {HiChevronDown, HiChevronRight} from "react-icons/hi";
import {BiSolidFolder} from "react-icons/bi";
import UserMapAPI from "../../../../services/API/User/UserMapAPI";

const ProjectLine = ({p, panel ,w=null}) => {
	//Get data from store
	// const myBoardsProjects = useObjectTableStore(state => state.myBoardsProjects)
	const currentSelectedProject = useObjectTableStore(state => state.currentSelectedProject)
	const currentSelectedMap = useObjectTableStore(state => state.currentSelectedMap)

	//Get my board project map
	const handleGetProjectMaps = () => {
		UserMapAPI.userGetProjectMap(p.uuid).then((response) => {
			if (response.status === 200){
				if (panel === 'myboard'){
					useObjectTableStore.getState().updateMyBoardsProjectMaps(p.uuid, response.data)
				} else {
					useObjectTableStore.getState().updateWorkspaceProjectMapList(w.uuid, p.uuid, response.data)
				}
			} else {
				if (panel === 'myboard'){
					useObjectTableStore.getState().updateMyBoardsProjectMaps(p.uuid, [])
				} else {
					useObjectTableStore.getState().updateWorkspaceProjectMapList(w.uuid, p.uuid, [])
				}
			}	
		})
	}

	useEffect(() => {
		if (p.open){
			handleGetProjectMaps()
		}
	}, [p.open]);

	return (
		<>
			<div
				className={"open-line " + (currentSelectedProject === p.uuid && currentSelectedMap === "" ? "active" : "")}
				onClick={(event) => {
					event.stopPropagation()
					useObjectTableStore.setState({
						currentSelectedProject: p.uuid,
						currentSelectedMap: '',
						currentSelectedArea: "",
						currentSelectedOverlay: ''
					})
					if (panel === 'workspace') {
						useObjectTableStore.getState().updateWorkspaceProjectOpenState(w.uuid, p.uuid, true)
					} else if (
						panel === 'myboard'
					) {
						useObjectTableStore.getState().updateMyBoardsProjectOpenState(p.uuid, true)
					}
					handleGetProjectMaps()
				}}>
				<div className="open-icon" onClick={(event) => {
					event.stopPropagation()
					if (panel === 'workspace') {
						useObjectTableStore.getState().updateWorkspaceProjectOpenState(w.uuid, p.uuid, !p.open)
					} else if (
						panel === 'myboard'
					) {
						useObjectTableStore.getState().updateMyBoardsProjectOpenState(p.uuid, !p.open)
					}
					handleGetProjectMaps()
				}}>{p.open ? <HiChevronDown size={20}/> : <HiChevronRight size={20}/>}</div>
				<div className="line-icon"><BiSolidFolder size={14}/></div>
				<p className="line-text">{p.name}</p>
			</div>
		</>
	)
}

export default ProjectLine