import React, {useEffect, useRef, useState} from "react";
import BibtexObjectSidebar from "./BibtexObjectSidebar";
import {IoCamera} from "react-icons/io5";
import {WaveForm, WaveSurfer} from "wavesurfer-react";
import {RxPause, RxPlay} from "react-icons/rx";
import Latex from "react-latex-next";
import {VscChromeClose} from "react-icons/vsc";
import ContentItemAPI from "../../../../services/ContentItemAPI";
import ContentItemTagAPI from "../../../../services/ContentItemTagAPI";
import {toast} from "react-toastify";
import AppSearchAPI from "../../../../services/AppSearchAPI";
import PublicsContentItemAPI from "../../../../services/API/Publics/PublicsContentItemAPI";
import {getOnlineVideoId} from "../../../../services/Utils/ItemUtils";
import {useMapStore} from "../../../../services/Stores";
import {useSidebarMapContentItemStore} from "../../../../services/Stores/SidebarMapContentItemStore";
import {
    globalUpdateMapContentItemAttributes, globalUpdateMapContentItemColorTag,
    globalUpdateMapContentItemName
} from "../../../../services/Utils/GlobalMapContentItemUpdateUtils";
import {createErrorToast} from "../../../../services/CustomToastUtils";
import {useContentItemStore} from "../../../../services/Stores/ContentItemStore";
import fullscreenScriptEditor from "../../FullscreenObjects/FullscreenScriptEditor";
import LatexEquationRenderer from "../../../customs/LatexEquationRenderer";

const ObjectSidebarInformations = ({fullscreen = false}) => {
    const availableColors = [
        "#ED1C24",
        "#FF7F27",
        "#FFF200",
        "#22B14C",
        "#00A2E8",
        "#3F48CC",
        "#A349A4",
        "#B97A57",
        "#FFAEC9",
        "#FFC90E",
        "#EFE4B0",
        "#B5E61D",
        "#99D9EA",
        "#7092BE",
        "#C8BFE7"
    ]

    //Get item data in store
    const item = useSidebarMapContentItemStore(state => state.data)
    const itemTags = useSidebarMapContentItemStore(state => state.tags)

    const typeIconThumbnail = ['GDOCS', 'GSHEET', 'GSLIDES', 'GCOLLAB', 'GIT_REPOSITORY']
    const [iconThumbnail, setIconThumbnail] = useState(false)

    //Get object data
    useEffect(() => {
        reloadItem()
    }, [])

    const reloadItem = () => {
        let promise
        let itemUuid

        if (fullscreen){
            itemUuid = useContentItemStore.getState().currentFullscreenItemUuid
        } else {
            itemUuid = useSidebarMapContentItemStore.getState().properties.id
        }

        if (useMapStore.getState().publicMap){
            promise = PublicsContentItemAPI.getPublicsContentItemByUuid(itemUuid)
        } else {
            promise =  ContentItemAPI.getContentItemByUuid(itemUuid)
        }

        //Get content item data
        promise.then((response) => {
            if (response.status === 200){
                useSidebarMapContentItemStore.setState({data: response.data})
                if (typeIconThumbnail.includes(response.data.type)){
                    setIconThumbnail(true)
                }
            }
        })

        //Get content item tags
        ContentItemTagAPI.getObjectTags(itemUuid).then((response) => {
            if (response.status === 200){
                useSidebarMapContentItemStore.setState({tags: response.data})
            }
        })
    }

    //Define default state for edit input
    const [editTitleStatus, setEditTitleStatus] = useState(false)
    const handleValidObjectTitle = async (event) => {
        const newTitle = event.currentTarget.value

        if (newTitle === ""){
            toast.error("Object name cannot be empty")
            return
        }

        if (newTitle === item.name){
            setEditTitleStatus(false)
            return
        }

        if (await ContentItemAPI.updateContentItemName(item.uuid, newTitle)){
            globalUpdateMapContentItemName(item.uuid, newTitle, item.location)
            setEditTitleStatus(false)
        }
    }

    //Update thumbnail
    const thumbnailInputRef = useRef(null)
    const handleUpdateThumbnail = async () => {
        if (!item.thumbnailStatus){
            return;
        }

        if (thumbnailInputRef.current.files[0]) {
            const files = thumbnailInputRef.current.files

            if (files[0].type !== "image/png" && files[0].type !== "image/jpg" && files[0].type !== "image/jpeg"){
                createErrorToast("Image format invalid")
                return
            }

            //Update thumbnail in api and get new URL
            const url = await ContentItemAPI.updateContentItemThumbnail(item.uuid, files[0])

            if (url){
                console.log(url)
            }
        }
    }

    //Associate object
    const [associatedObjectSearchValue, setAssociatedObjectSearchValue] = useState("")
    const [searchObjectResults, setSearchObjectResult] = useState([])

    const handleSearchObjects = (event) => {
        const searchQuery = event.currentTarget.value
        setAssociatedObjectSearchValue(searchQuery)

        if (searchQuery !== ""){
            AppSearchAPI.searchOnMap(searchQuery, useMapStore.getState().mapUuid, useMapStore.getState().searchApiKey).then((response) => {
                setSearchObjectResult(response.data.results)
            })
        } else {
            setSearchObjectResult([])
        }
    }

    const handleAssociateItem = async (targetItemUuid) => {
        const targetData = await ContentItemAPI.associateContentItem(item.uuid, targetItemUuid)

        if (targetData){
            useSidebarMapContentItemStore.getState().addAssociatedItem(targetData)
            setSearchObjectResult([])
            setAssociatedObjectSearchValue("")
        }
    }

    const handleDissociateItem = async (targetItemUuid) => {
        if (await ContentItemAPI.dissociateContentItem(item.uuid, targetItemUuid)){
            useSidebarMapContentItemStore.getState().removeAssociatedItem(targetItemUuid)
        }
    }

    const handleUpdateNote = async () => {
        await ContentItemAPI.updateContentItemNote(item.uuid, item.note)
    }

    const [colorTagPickerStatus, setColorTagPickerStatus] = useState(false)
    const handleUpdateColorTag = async (newColor) => {
        await ContentItemAPI.updateContentItemColor(item.uuid, newColor)
        setColorTagPickerStatus(false)
        globalUpdateMapContentItemColorTag(item.uuid, newColor)
    }

    const tagInputRef = useRef(null)
    const handleCreateTag = async () => {
        const name = tagInputRef.current.value

        //Check new tag name
        if (name.length <= 0){
            createErrorToast("New tag can't be empty")
            return
        }

        const uuid = await ContentItemTagAPI.addItemTag(item.uuid, name)

        if (uuid){
            useSidebarMapContentItemStore.getState().addItemTag({uuid: uuid, name: name})
            tagInputRef.current.value = ""
        }
    }

    const handleDeleteTag = async (tagUuid) => {
        if (await ContentItemTagAPI.deleteItemTag(tagUuid)){
            useSidebarMapContentItemStore.getState().removeItemTag(tagUuid)
        }
    }

    const handleUpdateAttributes = (attributeName, newValue) => {
        //Get attributes
        const itemAttributes = item.attributes

        const attributeIndex = itemAttributes.findIndex(a => a.name === attributeName)

        itemAttributes[attributeIndex].value = newValue

        useSidebarMapContentItemStore.getState().updateItemAttribute(itemAttributes)
    }

    const handleValidAttributes = async (event, attributeName, value = null) => {
        let newValue
        if (value === null){
            newValue = event.currentTarget.value
        } else {
            newValue = value
        }

        const itemAttributes = item.attributes
        const attributeIndex = itemAttributes.findIndex(a => a.name === attributeName)


        if(itemAttributes[attributeIndex].required){
            if (newValue.length === 0){
                event.currentTarget.focus()
                return
            }
        }

        if (item.type === "VIDEOS_ONLINE"){
            const videoIdIndex = itemAttributes.findIndex(a => a.name === "video-id")
            const videoUrlIndex = itemAttributes.findIndex(a => a.name === "url")

            try {
                const url = new URL(itemAttributes[videoUrlIndex].value)
                let videoId = url.searchParams.get('v')

                if (videoId === null){
                    videoId = url.pathname.replace("/", "")
                }

                itemAttributes[videoIdIndex].value = videoId
            } catch (error){
                toast.error("Invalid video URL")
                return
            }
        }

        if (await ContentItemAPI.updateContentItemAttributes(item.uuid, item.attributes)){
            globalUpdateMapContentItemAttributes(item.uuid, item.attributes)
        }
    }

    //Generate sound player
    const waveformRef = useRef(null)
    const [soundDuration, setSoundDuration] = useState("")
    const [soundCurrentTime, setSoundCurrentTime] = useState("")
    const [soundPlayingStatus, setSoundPlayingStatus] = useState(false)

    const mountWaveSurfer = (wavesurfer) => {
        waveformRef.current = wavesurfer
        wavesurfer.load(item.fileUrl)

        if (waveformRef.current){
            waveformRef.current.on('ready', () => {
                let minutes = Math.floor(waveformRef.current.getDuration().toFixed(0) / 60)
                let seconds = waveformRef.current.getDuration().toFixed(0) - (minutes * 60)

                setSoundDuration(minutes + ":" + seconds)

                minutes = Math.floor(waveformRef.current.getCurrentTime().toFixed(0) / 60)
                seconds = waveformRef.current.getCurrentTime().toFixed(0) - (minutes * 60)

                setSoundCurrentTime(minutes + ":" + seconds)
            })
            waveformRef.current.on('audioprocess', () => {
                let minutes = Math.floor(waveformRef.current.getCurrentTime().toFixed(0) / 60)
                let seconds = waveformRef.current.getCurrentTime().toFixed(0) - (minutes * 60)

                setSoundCurrentTime(minutes + ":" + seconds)
            })
            waveformRef.current.on('play', () => {
                setSoundPlayingStatus(true)
            })
            waveformRef.current.on('pause', () => {
                setSoundPlayingStatus(false)
            })
        }
    }

    //UPDATE FILE
    const updateFIleInputRef = useRef(null)

    const handleUpdateFile = async (event) => {
        const file = updateFIleInputRef.current.files[0]
        updateFIleInputRef.current.value = ""

        if (item.mimeType === null){
            return
        }

        if (!item.mimeType.includes(file.type)){
            toast.error("Unsupported file format")
            return
        }

        await ContentItemAPI.updateContentItemFile(item.uuid, file)

        // ContentItemAPI.getContentItemByUuid(itemId).then((response) => {
        //     if (response.status === 200){
        //         // setItem(response.data)
        //     }
        // })
    }

    const handleAddTask = async (event) => {
        if (event.key === "Enter") {
            const value = event.currentTarget.value
            const input = event.currentTarget

            const itemAttributes = item.attributes
            itemAttributes.push({taskName: value, checked: false})

            if (await ContentItemAPI.updateContentItemAttributes(item.uuid, itemAttributes)) {
                input.value = ""
                globalUpdateMapContentItemAttributes(item.uuid, item.attributes)
            }
        }
    }

    const handleCheckTask = async (event, taskIndex) => {
        const itemAttributes = item.attributes

        itemAttributes[taskIndex].checked = !itemAttributes[taskIndex].checked;

        if (await ContentItemAPI.updateContentItemAttributes(item.uuid, itemAttributes)){
            globalUpdateMapContentItemAttributes(item.uuid, item.attributes)
        }
    }

    const handleDeleteTask = async (taskIndex) => {
        const itemAttributes = item.attributes

        itemAttributes.splice(taskIndex, 1)

        if (await ContentItemAPI.updateContentItemAttributes(item.uuid, itemAttributes)){
            globalUpdateMapContentItemAttributes(item.uuid, item.attributes)
        }
    }

    const handleOpenItemAction = () => {
        //Check if user can full screen
        if (useSidebarMapContentItemStore.getState().properties.fullscreen){
            //Get item uuid
            const itemUuid = useSidebarMapContentItemStore.getState().properties.id

            //Open fullscreen
            useContentItemStore.setState({currentFullscreenItemUuid: itemUuid})

            //Close sidebar
            useSidebarMapContentItemStore.getState().resetStore()
            return
        }

        //Check if user have url to open
        if (item.attributes.filter(a => a.type === 'url')[0] !== undefined){
            //Get url in item attributes
            const url = item.attributes.filter(a => a.type === "url")[0].value

            //Open url
            window.open(url)

            return
        }
    }

    const handleRenameTask = async (taskIndex, value) => {
        //Check new value
        if (value.length <= 0) {
            return
        }

        const itemAttributes = item.attributes
        itemAttributes[taskIndex].rename = false
        itemAttributes[taskIndex].taskName = value

        if (await ContentItemAPI.updateContentItemAttributes(item.uuid, itemAttributes)) {
            globalUpdateMapContentItemAttributes(item.uuid, item.attributes)
        }
    }

    return (
        <>
            <div className={!fullscreen ? "info-container" : "info-container fullscreen"}>
                {Object.keys(item).length > 0 && (
                    <>
                        {!editTitleStatus && (
                            <h1 onClick={() => !useMapStore.getState().publicMap ? setEditTitleStatus(true) : null} className={!useMapStore.getState().publicMap ? "item-title" : "item-title public"}>{item.name}</h1>
                        )}
                        {editTitleStatus && (
                            <input autoFocus={true} type="text" className="title-input" defaultValue={item.name} onBlur={handleValidObjectTitle}/>
                        )}
                        {item.type === "BIBTEX" && (
                            <>
                                <BibtexObjectSidebar reloadItem={reloadItem} item={item} handleUpdateAttributes={handleUpdateAttributes} handleValidAttributes={handleValidAttributes} />
                                {item.attributes.length > 0 && (
                                    <div className="item-attributes-container">
                                        {item.attributes.map((attribute, index) => (
                                            <>
                                                {(attribute.name !== "reference-type" && !attribute.nameBuilder) && (
                                                    <>
                                                        <p className="fields-title">{attribute.name}</p>
                                                        <div className="attributes-list">
                                                            <div key={index} className="item-attribute">
                                                                {attribute.type === "string" && (
                                                                    <input disabled={useMapStore.getState().publicMap} onBlur={(event) => handleValidAttributes(event, attribute.name)} onChange={(event) => handleUpdateAttributes(attribute.name, event.currentTarget.value)} type="text" value={attribute.value} placeholder={attribute.required ? attribute.name + "*" : attribute.name}/>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                        {item.type !== "BIBTEX" && (
                            <>
                                {!fullscreen && (
                                    <>
                                        {iconThumbnail && (
                                            <>
                                                <div className={item.thumbnailStatus ? "thumbnail-container updatable" : "thumbnail-container"}>
                                                    <input type="file" className="thumbnail-input" onChange={handleUpdateThumbnail} ref={thumbnailInputRef} />
                                                    <div className="image-container icon" onClick={handleOpenItemAction}>
                                                        <img src={"/static/map/svg/" + item.icon + '.svg'} alt="Item thumbnail"/>
                                                    </div>
                                                    <div className="update-thumbnail-overlay" onClick={() => !useMapStore.getState().publicMap ? thumbnailInputRef.current.click() : null}>
                                                        <IoCamera />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {item.thumbnailUrl !== "" && (
                                            <>
                                                {item.type !== "VIDEOS_OFFLINE" && (
                                                    <>
                                                        <div className={item.thumbnailStatus ? "thumbnail-container updatable" : "thumbnail-container"}>
                                                            <input type="file" className="thumbnail-input" onChange={handleUpdateThumbnail} ref={thumbnailInputRef} />
                                                            <div className="image-container" onClick={handleOpenItemAction}>
                                                                <img src={item.thumbnailUrl} className={item.type === "PICTURES" ? "pictures" : ""} alt="Item thumbnail"/>
                                                            </div>
                                                            <div className="update-thumbnail-overlay" onClick={() => !useMapStore.getState().publicMap ? thumbnailInputRef.current.click() : null}>
                                                                <IoCamera />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {item.thumbnailUrl === "" && (
                                            <>
                                                {/*RENDER SOUND PLAYER*/}
                                                {item.type === "SOUND" && (
                                                    <>
                                                        <div className="sound-container">
                                                            <div className="wave">
                                                                <WaveSurfer plugins={[]} onMount={mountWaveSurfer}>
                                                                    <WaveForm id="waveform" waveColor="#E1E1E1" progressColor="#4D4F5C" barWidth={5} barGap={5} cursorColor="transparent">
                                                                    </WaveForm>
                                                                </WaveSurfer>
                                                            </div>
                                                            <div className="wave-controller">
                                                                <div className="time-container">
                                                                    <p>{soundCurrentTime}</p>
                                                                    <p>{soundDuration}</p>
                                                                </div>
                                                                <div className="button-container">
                                                                    <p onClick={() => soundPlayingStatus ? waveformRef.current.pause() : waveformRef.current.play()}>
                                                                        {soundPlayingStatus
                                                                            ? <RxPause />
                                                                            : <RxPlay />
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {/*RENDER LATEX*/}
                                        {item.type === "LATEX" && (
                                            <div className="latex-container">
                                                <LatexEquationRenderer latex={item.attributes.filter(a => a.type === "latex")[0].value} />
                                            </div>
                                        )}
                                        {/*RENDER OFFLINE VIDEOS*/}
                                        {item.type === "VIDEOS_OFFLINE" && (
                                            <div className="offline-video-container">
                                                <video src={item.fileUrl} controls={true}></video>
                                            </div>
                                        )}
                                        {/*RENDER ONLINE VIDEOS*/}
                                        {item.type === "VIDEOS_ONLINE" && (
                                            <div className="offline-video-container">
                                                <iframe id="ytplayer" allowFullScreen={true} type="text/html" src={"https://www.youtube.com/embed/" + getOnlineVideoId(new URL(item.attributes.filter(a => a.name === "url")[0].value), () => {}, () => {})} frameBorder="0"/>
                                            </div>
                                        )}
                                        {/*RENDER TASK*/}
                                        {item.type === "TASKS" && (
                                            <div className="task-container">
                                                {item.attributes.length > 0 && (
                                                    <div className="task-list">
                                                        {item.attributes.map((task, index) => (
                                                            <div key={index} className={task.checked ? "task checked" : "task"}>
                                                                <div className="left">
                                                                    <input onChange={(event) => !useMapStore.getState().publicMap ? handleCheckTask(event, index) : null} checked={task.checked} className="task-checkbox" type="checkbox" style={{width: "15px"}}/>
                                                                    <p onClick={() => !item.attributes[index].rename ? item.attributes[index].rename = true : null}
                                                                       className="task-name">
                                                                        {task.rename && (
                                                                            <input autoFocus={true} onKeyDown={(event) => event.key === "Enter" ? handleRenameTask(index, event.currentTarget.value) : null} onBlur={(event) => handleRenameTask(index, event.currentTarget.value)} type="text" placeholder="Task name" defaultValue={task.taskName}/>
                                                                        )}
                                                                        {!task.rename && (
                                                                            task.taskName
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <p className="task-delete-btn"
                                                                   onClick={() => !useMapStore.getState().publicMap ? handleDeleteTask(index) : null}>
                                                                    <VscChromeClose/></p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {!useMapStore.getState().publicMap && (
                                                    <input className="add-task-input" onKeyDown={handleAddTask} type="text" placeholder="Add task" />
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                                {/*UPDATE FILE*/}
                                {!useMapStore.getState().publicMap && (
                                    <>
                                        {item.mimeType !== null && (
                                            <div className="update-file-container">
                                                <p className="filename">{item.url.split("/").splice(-1)}</p>
                                                <p className="button" onClick={() => updateFIleInputRef.current.click()}>Browse</p>
                                                <input type="file" ref={updateFIleInputRef} onChange={handleUpdateFile}/>
                                            </div>
                                        )}
                                    </>
                                )}
                                {/*ITEM FIELDS*/}
                                {item.type !== "TASKS" && (
                                    <>
                                        {item.attributes.length > 0 && (
                                            <div className="item-attributes-container">
                                                <p className="fields-title">Item attributes</p>
                                                <div className="attributes-list">
                                                    {item.attributes.map((attribute, index) => (
                                                        <>
                                                            {attribute.visible && (
                                                                <div key={index} className="item-attribute">
                                                                    {attribute.type === "string" && (
                                                                        <input disabled={useMapStore.getState().publicMap} onBlur={(event) => handleValidAttributes(event, attribute.name)} onChange={(event) => handleUpdateAttributes(attribute.name, event.currentTarget.value)} type="text" value={attribute.value} placeholder={attribute.required ? attribute.name + "*" : attribute.name}/>
                                                                    )}
                                                                    {attribute.type === "latex" && (
                                                                        <textarea disabled={useMapStore.getState().publicMap} rows={5} onBlur={(event) => handleValidAttributes(event, attribute.name)} onChange={(event) => handleUpdateAttributes(attribute.name, event.currentTarget.value)} value={attribute.value} placeholder={attribute.required ? attribute.name + "*" : attribute.name}/>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {/*GENERAL FIELDS*/}
                        <div className="associated-objects-container">
                            <p className="fields-title">Connexions</p>
                            <div className="search-input-container">
                                {!useMapStore.getState().publicMap && (
                                    <input value={associatedObjectSearchValue} onChange={handleSearchObjects} type="text" placeholder="Search object" />
                                )}
                                {associatedObjectSearchValue !== "" && (
                                    <div className="search-result-container">
                                        {searchObjectResults.length <= 0 && (
                                            <div className="search-result center">No results</div>
                                        )}
                                        {searchObjectResults.length > 0 && (
                                            <>
                                                {searchObjectResults.map((result, index) => (
                                                    <div className="search-result" onClick={() => handleAssociateItem(result.id.raw)}>
                                                        <img src={result.icon_code !== undefined ? "/static/map/svg/" + result.icon_code.raw + '.svg' : ""} alt="Item icon"/>
                                                        <p key={index} className="text">{result.name.raw}</p>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={item.associatedItems.length > 1 ? "associated-item-list multiple" : "associated-item-list"} >
                                {item.associatedItems.map((aitem, index) => (
                                    <div className="associated-item" key={index}>
                                        <div className="left">
                                            <div className="thumbnail">
                                                {aitem.thumbnailUrl !== null && (
                                                    <img width={10} src={aitem.thumbnailUrl} alt="Item thumbnail"/>
                                                )}
                                            </div>
                                            <p className="item-title">{aitem.name}</p>
                                        </div>
                                        <div className="right">
                                            {!useMapStore.getState().publicMap && (
                                                <p className="delete-btn" onClick={() => handleDissociateItem(aitem.uuid)}><VscChromeClose /></p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="note-container">
                            <p className="fields-title">Notes</p>
                            <textarea disabled={useMapStore.getState().publicMap} onKeyDown={(event) => event.key === "Enter" ? handleUpdateNote() : null} onBlur={handleUpdateNote} value={item.note} rows="4" placeholder="Add note" onChange={(event) => useSidebarMapContentItemStore.getState().updateItemNote(event.currentTarget.value)}></textarea>
                        </div>
                        <div className="tags-container">
                            <p className="fields-title">Tags</p>
                            <div className="tags-input">
                                {!useMapStore.getState().publicMap && (
                                    <input ref={tagInputRef} type="text" onKeyDown={(event) => event.key === "Enter" ? handleCreateTag() : null} placeholder="Add tag and press enter"/>
                                )}
                                {itemTags.length > 0 && (
                                    <div className="tags-list">
                                        {itemTags.map((tag, index) => (
                                            <div className="tag" key={index}>
                                                <p className="tag-name">{tag.name}</p>
                                                {!useMapStore.getState().publicMap && (
                                                    <p className="icon" onClick={() => handleDeleteTag(tag.uuid)}><VscChromeClose /></p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="color-tag-container">
                            <p className="fields-title">Color tag</p>
                            <div className="color" onClick={() => !useMapStore.getState().publicMap ? setColorTagPickerStatus(!colorTagPickerStatus) : null} style={{backgroundColor: item.color}}></div>
                            {colorTagPickerStatus && (
                                <div className="color-pick-container">
                                    {availableColors.map((color, index) => (
                                        <div key={index} style={{backgroundColor: color}} onClick={() => handleUpdateColorTag(color)} className="color-btn"></div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default ObjectSidebarInformations