import React from "react";
import {useGlobalAppStore} from "../../services/Stores/GlobalAppStore";

const PopupOverlay = () => {
	const state = useGlobalAppStore(state1 => state1.popupOverlayState)

	if (state){
		return (
			<div className="custom__global-popup-overlay"></div>
		)
	} else {
		return null
	}
}

export default PopupOverlay