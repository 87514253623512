import {ADMIN_MAP_API, MAP_API, PUBLIC_MAP_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";
import {useMapStore, useMercureStore} from "./Stores";
import AuthAPI from "./AuthAPI";
import {Cookies} from "react-cookie";
import {createLoadingToast, removeToast} from "./CustomToastUtils";

function getMapList(){
    return getAxiosInstance().get(ADMIN_MAP_API + "/get_all")
}

function userGetRecentMaps(){
    return getAxiosInstance().get(MAP_API + "/get_recent_maps")
}

function userGetSharedMaps(){
    return getAxiosInstance().get(MAP_API + "/get_shared_maps")
}

function getProjectMap(projectId){
    return getAxiosInstance().get(MAP_API + "/get_by_project", {
        params: {projectUuid: projectId}
    })
}

function getPublicMapByUuid(mapUuid){
    return getAxiosInstance().get(PUBLIC_MAP_API + "/get_by_uuid", {
        params: {mapUuid: mapUuid}
    })
}

function createMapScreenshot(screenshots){
    const sendData = new FormData()
    sendData.append('screenshots', screenshots)
    sendData.append('mapUuid', useMapStore.getState().mapUuid)

    return getAxiosInstance().post(MAP_API + '/create_screenshot', sendData)
        .then((response) => {
            return response.status === 200
        })
}

function changeMapAccess(mapUuid){
    const sendData = new FormData()
    sendData.append("_method", "PUT")
    sendData.append("mapUuid", mapUuid)

    toast.loading("Map access update in progress", {toastId: "loadAlert"})

    return getAxiosInstance().post(MAP_API + "/change_access", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function updateMapScreenshot(screenshots){
    const sendData = new FormData()
    screenshots.forEach(screenshot => {
        sendData.append('screenshots[]', screenshot)
    })

    return getAxiosInstance().post(MAP_API + '/update_screenshot', sendData)
        .then(response => {
            return response.status === 200;
        })
        .catch((err) => {
            return false
        })
}

function createNewMap(mapName, inviteUserInWorkspace, projectUuid){
    //Open loading toast
    const toastId = createLoadingToast("Map creation in progress")

    const sendData = new FormData()
    sendData.append('mapName', mapName)
    sendData.append('inviteUserInWorkspace', inviteUserInWorkspace)
    sendData.append('projectUuid', projectUuid)

    return getAxiosInstance().post(MAP_API + "/create", sendData)
        .then((response) => {
            //Close toast
            removeToast(toastId)
            if (response){
                if (response.status === 200){
                    return response.data.uuid
                }

                return false
            }
        })
        .catch((error) => {
            //Close toast
            removeToast(toastId)

            return false
        })
}

function exportMap(mapList){
    const sendData = new FormData()
    sendData.append("mapsId", mapList)
    return getAxiosInstance().post(ADMIN_MAP_API + "/export", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return response.data.uuid
                }
            }
        })
}

function copyMaps(mapsId){
    const sendData = new FormData()
    sendData.append("mapsId", mapsId)
    return getAxiosInstance().post(ADMIN_MAP_API + "/copy", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function importMap(file){
    const sendData = new FormData()
    sendData.append("map", file)

    return getAxiosInstance().post(ADMIN_MAP_API + "/import", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function deleteMapPassword(mapId){
    return getAxiosInstance().delete(ADMIN_MAP_API + "/delete_password", {
        params: {
            id: mapId
        }
    })
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function deleteMap(mapId){
    return getAxiosInstance().delete(ADMIN_MAP_API + "/delete", {
        params: {
            id: mapId
        }
    })
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function userDeleteMap(mapId){
    toast.loading("Map delete in progress", {toastId: "loadAlert"})

    return getAxiosInstance().delete(MAP_API + "/delete", {
        params: {
            id: mapId
        }
    })
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function renameMap(uuid, name){
    const sendData = new FormData()
    sendData.append("uuid", uuid)
    sendData.append("name", name)

    return getAxiosInstance().post(MAP_API + "/rename", sendData)
        .then((response) => {
            return response.status === 200
        })
}

function getUserOwnedMap(){
    return getAxiosInstance().get(MAP_API + "/get_owned_maps")
}

function getUserSharedMap(){
    return getAxiosInstance().get(MAP_API + "/get_shared_maps")
}

function getMapByUuid(mapUuid){
    return getAxiosInstance().get(MAP_API + "/get_by_uuid", {
        params: {
            mapUuid: mapUuid
        }
    })
}

function userDeleteMapPassword(mapUuid){
    toast.loading("Map password delete in progress", {toastId: "loadAlert"})

    return getAxiosInstance().delete(MAP_API + "/delete_password", {
        params: {mapUuid: mapUuid}
    })
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function userSetMapPassword(mapUuid, newPassword){
    const sendData = new FormData()
    sendData.append("mapUuid", mapUuid)
    sendData.append("password", newPassword)

    toast.loading("Set map password in progress", {toastId: "loadAlert"})

    return getAxiosInstance().post(MAP_API + "/set_password", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

export default {
    userGetRecentMaps,
    userGetSharedMaps,
    getMapList,
    exportMap,
    deleteMapPassword,
    deleteMap,
    importMap,
    copyMaps,
    getUserOwnedMap,
    userDeleteMap,
    getMapByUuid,
    renameMap,
    changeMapAccess,
    userDeleteMapPassword,
    userSetMapPassword,
    getProjectMap,
    getPublicMapByUuid,
    createNewMap,
    getUserSharedMap,
    updateMapScreenshot,
    createMapScreenshot
}