import {create} from "zustand";

export const useMapContextMenuStore = create((set) => ({
	state: false,
	type: 'map',
	uuid: null,
	position: null,
	feature: null,
	containerPoint: null,
	openItemMenu: (uuid, x, y, feature) => set((state) => ({
		state: true,
		type: 'item',
		uuid: uuid,
		position: {x: x, y: y},
		feature: feature
	})),
	openZoneMenu: (uuid, x, y, feature, containerPoint) => set((state) => ({
		state: true,
		type: 'zone',
		uuid: uuid,
		position: {x: x, y: y},
		feature: feature,
		containerPoint: containerPoint,
	})),
	openShapeMenu: (uuid, x, y, feature) => set((state) => ({
		state: true,
		type: 'shape',
		uuid: uuid,
		position: {x: x, y: y},
		feature: feature
	})),
	openMapMenu: (x, y, containerPoint) => set((state) => ({
		state: true,
		type: 'map',
		position: {x: x, y: y},
		containerPoint: containerPoint
	})),
	resetStore: () => set((state) => ({
		state: false,
		type: 'map',
		uuid: null,
		position: null,
		feature: null,
		containerPoint: null
	}))
}))