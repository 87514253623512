import React, { useEffect } from "react";
import { useObjectTableStore } from "../../../../services/Stores/ObjectTableStore";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";
import { BiSolidFolder } from "react-icons/bi";
import UserProjectsAPI from "../../../../services/API/User/UserProjectsAPI";

const WorkspaceLine = ({ w }) => {
    // Get data from store
    const currentSelectedProject = useObjectTableStore(state => state.currentSelectedProject);
    const currentSelectedMap = useObjectTableStore(state => state.currentSelectedMap);

    // Function to get workspace projects
    const handleGetWorkspaceProject = (uuid) => {
        UserProjectsAPI.userGetAllWorkspaceProjects(uuid).then((response) => {
            if (response.status === 200) {
                // Update the workspace's project list with the API response data
                useObjectTableStore.getState().updateWorkspaceProjectList(uuid, response.data);
            } else {
                // Handle error case by setting empty array
                useObjectTableStore.getState().updateWorkspaceProjectList(uuid, []);
            }
        }).catch(() => {
            useObjectTableStore.getState().updateWorkspaceProjectList(uuid, []);
        });
    };

    // Load projects when workspace is opened
    useEffect(() => {
        if (w.open) {
            handleGetWorkspaceProject(w.uuid);
        }
    }, [w.open, w.uuid]);

    return (
        <>
            <div
                className={"open-line " + (currentSelectedProject === w.uuid && currentSelectedMap === "" ? "active" : "")}
                onClick={(event) => {
                    event.stopPropagation();
                    // Set current selected project and clear other selections
                    useObjectTableStore.setState({
                        currentSelectedProject: w.uuid,
                        currentSelectedMap: '',
                        currentSelectedArea: "",
                        currentSelectedOverlay: ''
                    });
                    // Open the workspace
                    useObjectTableStore.getState().updateWorkspaceOpenState(w.uuid, true);
                    handleGetWorkspaceProject(w.uuid);
                }}
            >
                <div 
                    className="open-icon" 
                    onClick={(event) => {
                        event.stopPropagation();
                        // Toggle open state
                        useObjectTableStore.getState().updateWorkspaceOpenState(w.uuid, !w.open);
                        if (!w.open) {
                            handleGetWorkspaceProject(w.uuid);
                        }
                    }}
                >
                    {w.open ? <HiChevronDown size={20} /> : <HiChevronRight size={20} />}
                </div>
                <div className="line-icon"><BiSolidFolder size={14} /></div>
                <p className="line-text">{w.name}</p>
            </div>
        </>
    );
};

export default WorkspaceLine;