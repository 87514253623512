import {USER_CONTENT_ITEM_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";
import {generateRandomId} from "./Utils";
import {useMapStore} from "./Stores";
import {generateNewItemDatas} from "./Utils/ItemUtils";
import AuthAPI from "./AuthAPI";
import {createErrorToast} from "./CustomToastUtils";

function getContentItemByUuid(contentItemUuid){
    return getAxiosInstance().get(USER_CONTENT_ITEM_API + '/get_by_uuid', {
        params: {uuid: contentItemUuid}
    })
}

function getContentItemThumbnailUrl(contentItemUuid){
    return getAxiosInstance().get(USER_CONTENT_ITEM_API + '/get_thumbnail_url', {
        params: {uuid: contentItemUuid}
    })
}

function getUrlTitle(url){
    return getAxiosInstance().get(USER_CONTENT_ITEM_API + '/get_url_title', {
        params: {url: url}
    })
}

function createContentItem(x, y, zoomLevel, overlay, type, file, attributes = null, objectName = null, thumbnail = null, defaultText = null){
    const sendData = new FormData()
    sendData.append('x', x)
    sendData.append('y', y)
    sendData.append('zoomLevel', Math.floor(parseFloat(zoomLevel)))
    sendData.append('overlay', overlay)
    sendData.append('type', type.code)
    sendData.append('file', file)
    if (objectName){
        sendData.append('objectName', objectName)
    }
    if (attributes){
        sendData.append('attributes', attributes)
    }
    if (thumbnail){
        sendData.append('thumbnail', thumbnail)
    }
    if (defaultText.length > 0 && type.code === "TEXTS"){
        sendData.append('defaultText', defaultText)
    }

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + "/create", sendData)
        .then((response) => {
            if (response.status === 200){
                generateNewItemDatas(response.data.uuid, x, y, zoomLevel, useMapStore.getState().currentOverlay, type, attributes, response.data.name, AuthAPI.getUserUuid())

                return true
            }
        })
}

function createContentItemFromFile(file, lat, long, mapUuid, zoomLevel){
    const sendData = new FormData()
    sendData.append('file', file)
    sendData.append('long', long)
    sendData.append('lat', lat)
    sendData.append('mapUuid', mapUuid)
    sendData.append('zoomLevel', Math.round(parseFloat(zoomLevel)))

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/create_from_file', sendData)
        .then((response) => {
            if (response.status === 200){
                toast.success(file.name + ' successfully created')
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
        })
}

function createContentItemFromBibtexFile(file, lat, long, mapUuid, zoomLevel, code = ""){
    const sendData = new FormData()
    if (code !== ""){
        sendData.append("code", code)
    } else {
        sendData.append('file', file)
    }
    sendData.append('long', long)
    sendData.append('lat', lat)
    sendData.append('mapUuid', mapUuid)
    sendData.append('zoomLevel', Math.round(parseFloat(zoomLevel)))

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/create_from_bibtex_file', sendData)
        .then((response) => {
            if (response.status === 200){
                toast.success(file.name + ' successfully created')
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
        })
}

function createContentItemFromData(lat, long, mapUuid, zoomLevel, name, type = null, url = "", attributes = {}, picture= null, file = null){
    const sendData = new FormData()

    //Set required send data
    sendData.append('long', long)
    sendData.append('lat', lat)
    sendData.append('mapUuid', mapUuid)
    sendData.append('zoomLevel', Math.round(parseFloat(zoomLevel)))
    sendData.append('name', name)

    if (type){
        sendData.append('type', type)
    } else {
        sendData.append('mimeType', file.type)
    }

    //Set optional data
    if (url.length > 0){
        sendData.append('url', url)
    }

    //Set attributes if needed
    if (Object.keys(attributes).length > 0){
        sendData.append('attributes', JSON.stringify(attributes))
    }

    //Set picture if needed
    if (picture){
        sendData.append('picture', picture)
    }

    //Set file if needed
    if (file){
        sendData.append('file', file)
    }

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/create_from_data', sendData)
        .then((response) => {
            if (response.status === 200){
                toast.success(name + ' successfully created')
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function updateContentItemNote(contentItemUuid, note){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('note', note)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_note', sendData)
        .then((response) => {
            return response.status === 200
        })
        .catch(() => false)
}

function updateContentItemTextFile(contentItemUuid, content, regenerateThumbnail = false, textPreview = "", screenshot = ""){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('content', content)
    sendData.append('regenerateThumbnail', String(regenerateThumbnail))
    sendData.append('textPreview', textPreview)
    sendData.append('screenshot', screenshot)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_text_file', sendData)
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
        })
}

function updateContentItemName(contentItemUuid, name){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('name', name)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_name', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    createErrorToast(error.response.data.error)
                }
            }

            return false
        })
}

function updateContentItemColor(contentItemUuid, color){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('color', color)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_color', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            return false
        })
}

function updateContentItemAttributes(contentItemUuid, attributes){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('attributes', JSON.stringify(attributes))

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_attributes', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function updateContentItemThumbnail(contentItemUuid, thumbnail){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('thumbnail', thumbnail)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_thumbnail', sendData)
        .then((response) => {
            if (response.status === 200){
                return response.data.url
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}
function updateContentItemFile(contentItemUuid, file){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('file', file)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_file', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function updateContentItemLocation(contentItemUuid, x, y, needUpdate = false){
    const sendData = new FormData()
    const updateId = generateRandomId()
    if (!needUpdate){
        useMapStore.getState().addNewUpdateId(updateId)
    }

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('y', y)
    sendData.append('x', x)
    sendData.append('updateId', updateId)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_location', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function copyContentItem(contentItemUuid, lat, long, type, zoom){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', contentItemUuid)
    sendData.append('lat', lat)
    sendData.append('long', long)
    sendData.append('type', type)
    sendData.append('zoom', Math.floor(parseFloat(zoom)))

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/copy', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function associateContentItem(sourceUuid, targetUuid){
    const sendData = new FormData()

    //Set required send data
    sendData.append('sourceUuid', sourceUuid)
    sendData.append('targetUuid', targetUuid)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/associate_item', sendData)
        .then((response) => {
            if (response.status === 200){
                return response.data["targetData"]
            }

            return false
        })
        .catch(() => false)
}

function dissociateContentItem(sourceUuid, targetUuid){
    const sendData = new FormData()

    //Set required send data
    sendData.append('sourceUuid', sourceUuid)
    sendData.append('targetUuid', targetUuid)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/dissociate_item', sendData)
        .then((response) => {
            return response.status === 200
        })
        .catch(() => false)
}

function deleteContentItem(itemUuid){

    return getAxiosInstance().delete(USER_CONTENT_ITEM_API + '/delete', {params: {
        uuid: itemUuid
        }})
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function downloadContentItemFile(itemUuid){
    const sendData = new FormData()
    sendData.append('uuid', itemUuid)

    return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/download_file', sendData)
        .then((response) => {
            if (response.status === 200){
                console.log(response.data)

                const link = document.createElement('a');
                link.href = response.data.url;

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }
        })
}

export default {
    getContentItemByUuid,
    createContentItemFromFile,
    createContentItemFromData,
    updateContentItemNote,
    updateContentItemColor,
    updateContentItemAttributes,
    associateContentItem,
    dissociateContentItem,
    getContentItemThumbnailUrl,
    createContentItemFromBibtexFile,
    updateContentItemThumbnail,
    deleteContentItem,
    updateContentItemLocation,
    copyContentItem,
    updateContentItemName,
    createContentItem,
    updateContentItemFile,
    updateContentItemTextFile,
    downloadContentItemFile,
    getUrlTitle
}