import {TFA_CONTROLLER_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function getActiveTotpQrCode(){
    return getAxiosInstance().get(TFA_CONTROLLER_API + "/get_new_totp_infos")
}

function activeTotpTfa(confirmCode){
    toast.loading('Two factor auth activation in progress', {toastId: 'loadAlert'})

    const sendData = new FormData()
    sendData.append('totpCode', confirmCode)

    return getAxiosInstance().post(TFA_CONTROLLER_API + '/active_totp', sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            if (response){
                if (response.status === 200){
                    return true
                }
            }
            return false
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function disableTfa(){

    return getAxiosInstance().post(TFA_CONTROLLER_API + '/disable')
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
            return false
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

export default {
    getActiveTotpQrCode,
    activeTotpTfa,
    disableTfa
}