import React, {useContext, useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import DocumentationPopupContext from "../contexts/DocumentationPopupContext";
import Draggable from "react-draggable";

const DocumentationPopup = () => {
    //Get popup context
    const {documentationPopupStatus, setDocumentationPopupStatus} = useContext(DocumentationPopupContext)

    //Define default status for popup view
    const [currentDoc, setCurrentDoc] = useState('what-map')

    if (documentationPopupStatus){
        return (
            <div className="modal-overlay no-background">
                <Draggable>
                    <div className="modal large">
                        <div className="header">
                            <h1>Weeki functional documentation</h1>
                            <p onClick={() => {
                                setCurrentDoc('what-map')
                                setDocumentationPopupStatus(false)
                            }}><VscChromeClose /></p>
                        </div>
                        <div className="documentation-content">
                            <div className="documentation-list-container">
                                <p id="what-map" className={currentDoc === "what-map" ? "item selected" : "item"} onClick={() => currentDoc !== "what-map" ? setCurrentDoc('what-map') : null}>Quest-ce que les Maps</p>
                                <p id="what-object" className={currentDoc === "what-object" ? "item selected" : "item"} onClick={() => currentDoc !== "what-object" ? setCurrentDoc('what-object') : null}>Quest-ce que les Objets</p>
                                <p id="create-object" className={currentDoc === "create-object" ? "item selected" : "item"} onClick={() => currentDoc !== "create-object" ? setCurrentDoc('create-object') : null}>Créer un objets</p>
                                <p id="create-map" className={currentDoc === "create-map" ? "item selected" : "item"} onClick={() => currentDoc !== "create-map" ? setCurrentDoc('create-map') : null}>Créer une map</p>
                                <p id="create-zone" className={currentDoc === "create-zone" ? "item selected" : "item"} onClick={() => currentDoc !== "create-zone" ? setCurrentDoc('create-zone') : null}>Créer une zone</p>
                            </div>
                            <div className="documentation-text-container">
                                <section>
                                    <h1>Ceci est un titre</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                </section>
                                <section>
                                    <h1>Ceci est un titre</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                </section>
                                <section>
                                    <h1>Ceci est un titre</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                </section>
                                <section>
                                    <h1>Ceci est un titre</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci debitis dolor dolore laborum qui, reiciendis. Eos et fuga harum nemo officia officiis, soluta? A culpa labore pariatur quod rerum! Autem.</p>
                                </section>
                            </div>
                        </div>
                    </div>
                </Draggable>

            </div>
        )
    } else {
        return null
    }
}

export default DocumentationPopup