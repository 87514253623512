import React, {useEffect, useState} from "react";
import {useUserStore} from "../../../services/Stores";
import UserAPI from "../../../services/UserAPI";
import {createSuccessToast} from "../../../services/CustomToastUtils";

const NotConfirmedAccountAlert = () => {
	const [browserSupported, setBrowserSupported] = useState(true)

	//Detect if browser is supported
	useEffect(() => {
		if (/Android|iPhone/i.test(navigator.userAgent)) {
			setBrowserSupported(false)
		} else {
			setBrowserSupported(true)
		}
	}, [])

	//Get confirmed account state in store
	const confirmedAccount = useUserStore(state => state.confirmedAccount)
	const userLoad = useUserStore(state => state.userLoad)

	//Send confirmation email
	const handleSendConfirmationEmail = async () => {
		if (await UserAPI.userSendConfirmationEmail()){
			createSuccessToast("Confirmation email successfully send")
		}
	}

	if (confirmedAccount || !userLoad){
		return null
	}

	if (!browserSupported){
		return null
	}

	return (
		<div className="header-alert-container">
			<p className="text">Before use Weeki you need to confirm your account. <span onClick={handleSendConfirmationEmail}>Re-send confirmation e-mail</span></p>
		</div>
	)
}

export default NotConfirmedAccountAlert