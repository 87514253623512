/* eslint-disable */

import {generateRandomId} from "./Utils";
import {detectAreaTitleClick, detectAreaTitleHover} from "./Utils/ZoneUtils";
import {detectShapeItemClick} from "./Utils/ShapeItemUtils";
import {detectHoverLevel1Item} from "./Utils/Level1ItemUtils";
import {useMapStore} from "./Stores";

function getItemsLayer(mapUuid, publicMap){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_items: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/item/{z}/{x}/{y}.pbf?rev={v}',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: JSON.stringify(useMapStore.getState().mapFilters),
                        publicMap: publicMap
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                selectedItem: ""
            },
            textures: {
                pdf: {url: '/static/map/pdf.png'},
                bibtex: {url: '/static/map/bibtex.png'},
                sound: {url: '/static/map/sound.png'},
                url: {url: '/static/map/url.png'},
                picture: {url: '/static/map/picture.png'},
                code: {url: '/static/map/code.png'},
                texts: {url: '/static/map/texts.png'},
                latex: {url: '/static/map/latex.png'},
                tasks: {url: '/static/map/tasks.png'},
                company: {url: '/static/map/company.png'},
                contact: {url: '/static/map/contact.png'},
                offline_video: {url: '/static/map/offline_video.png'},
                online_video: {url: '/static/map/online_video.png'},
                moocs: {url: '/static/map/moocs.png'},
                git_repository: {url: '/static/map/git_repository.png'},
                gdocs: {url: '/static/map/gdocs.png'},
                gslides: {url: '/static/map/gslides.png'},
                gsheet: {url: '/static/map/gsheet.png'},
                wikipedia: {url: '/static/map/wikipedia.png'},
                gcollab: {url: '/static/map/gcollab.png'},
                notion: {url: '/static/map/notion.png'}
            },
            layers: {
                weeki_level0_items: {
                    data: {source: 'weeki_map_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.icon_zlevel > $zoom}",
                    draw: {
                        points: {
                            color: '#000000',
                            size: 5,
                            collide: false,
                            interactive: false
                        }
                    }
                },
                weeki_level2_items: {
                    data: {source: 'weeki_map_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.small_zlevel2 <= $zoom}",
                    draw: {
                        points: {
                            color: 'transparent',
                            size: 10,
                            collide: false,
                            interactive: true
                        }
                    }
                },
                weeki_icon_items: {
                    data: {source: 'weeki_map_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.icon_zlevel <= $zoom && feature.zlevel1 > $zoom}",
                    pdf: {filter: {type_code: ['PDF']}, draw: {points: {texture: 'pdf', size: "10%",collide: false, interactive: true}}},
                    bibtex: {filter: {type_code: ['BIBTEX']}, draw: {points: {texture: 'bibtex', size: "10%",collide: false, interactive: true}}},
                    sound: {filter: {type_code: ['SOUND']}, draw: {points: {texture: 'sound', size: "10%",collide: false, interactive: true}}},
                    url: {filter: {type_code: ['URL']}, draw: {points: {texture: 'url', size: "10%",collide: false, interactive: true}}},
                    picture: {filter: {type_code: ['PICTURES']}, draw: {points: {texture: 'picture', size: "10%",collide: false, interactive: true}}},
                    code: {filter: {type_code: ['CODE']}, draw: {points: {texture: 'code', size: "10%",collide: false, interactive: true}}},
                    texts: {filter: {type_code: ['TEXTS']}, draw: {points: {texture: 'texts', size: "10%",collide: false, interactive: true}}},
                    latex: {filter: {type_code: ['LATEX']}, draw: {points: {texture: 'latex', size: "10%",collide: false, interactive: true}}},
                    tasks: {filter: {type_code: ['TASKS']}, draw: {points: {texture: 'tasks', size: "10%",collide: false, interactive: true}}},
                    company: {filter: {type_code: ['COMPANY']}, draw: {points: {texture: 'company', size: "10%",collide: false, interactive: true}}},
                    offline_video: {filter: {type_code: ['VIDEOS_OFFLINE']}, draw: {points: {texture: 'offline_video', size: "10%",collide: false, interactive: true}}},
                    online_video: {filter: {type_code: ['VIDEOS_ONLINE']}, draw: {points: {texture: 'online_video', size: "10%",collide: false, interactive: true}}},
                    contact: {filter: {type_code: ['CONTACT']}, draw: {points: {texture: 'contact', size: "10%",collide: false, interactive: true}}},
                    moocs: {filter: {type_code: ['MOOCS']}, draw: {points: {texture: 'moocs', size: "10%",collide: false, interactive: true}}},
                    git_repository: {filter: {type_code: ['GIT_REPOSITORY']}, draw: {points: {texture: 'git_repository', size: "10%",collide: false, interactive: true}}},
                    gdocs: {filter: {type_code: ['GDOCS']}, draw: {points: {texture: 'gdocs', size: "10%",collide: false, interactive: true}}},
                    gslides: {filter: {type_code: ['GSLIDES']}, draw: {points: {texture: 'gslides', size: "10%",collide: false, interactive: true}}},
                    gsheet: {filter: {type_code: ['GSHEET']}, draw: {points: {texture: 'gsheet', size: "10%",collide: false, interactive: true}}},
                    gcollab: {filter: {type_code: ['GCOLLAB']}, draw: {points: {texture: 'gcollab', size: "10%",collide: false, interactive: true}}},
                    wikipedia: {filter: {type_code: ['WIKIPEDIA']}, draw: {points: {texture: 'wikipedia', size: "10%",collide: false, interactive: true}}},
                    notion: {filter: {type_code: ['NOTION']}, draw: {points: {texture: 'notion', size: "10%",collide: false, interactive: true}}},
                },
                weeki_level1_items: {
                    data: {source: 'weeki_map_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.zlevel1 <= $zoom && feature.small_zlevel2 > $zoom && global.selectedItem !== feature.id}",
                    // filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.zlevel1 <= $zoom && global.selectedItem !== feature.id}",
                    pdf: {filter: {type_code: ['PDF']}, draw: {points: {texture: 'pdf', size: "20%",collide: false, interactive: true}}},
                    bibtex: {filter: {type_code: ['BIBTEX']}, draw: {points: {texture: 'bibtex', size: "20%",collide: false, interactive: true}}},
                    sound: {filter: {type_code: ['SOUND']}, draw: {points: {texture: 'sound', size: "20%",collide: false, interactive: true}}},
                    url: {filter: {type_code: ['URL']}, draw: {points: {texture: 'url', size: "20%",collide: false, interactive: true}}},
                    picture: {filter: {type_code: ['PICTURES']}, draw: {points: {texture: 'picture', size: "20%",collide: false, interactive: true}}},
                    code: {filter: {type_code: ['CODE']}, draw: {points: {texture: 'code', size: "20%",collide: false, interactive: true}}},
                    texts: {filter: {type_code: ['TEXTS']}, draw: {points: {texture: 'texts', size: "20%",collide: false, interactive: true}}},
                    latex: {filter: {type_code: ['LATEX']}, draw: {points: {texture: 'latex', size: "20%",collide: false, interactive: true}}},
                    tasks: {filter: {type_code: ['TASKS']}, draw: {points: {texture: 'tasks', size: "20%",collide: false, interactive: true}}},
                    company: {filter: {type_code: ['COMPANY']}, draw: {points: {texture: 'company', size: "20%",collide: false, interactive: true}}},
                    offline_video: {filter: {type_code: ['VIDEOS_OFFLINE']}, draw: {points: {texture: 'offline_video', size: "20%",collide: false, interactive: true}}},
                    online_video: {filter: {type_code: ['VIDEOS_ONLINE']}, draw: {points: {texture: 'online_video', size: "20%",collide: false, interactive: true}}},
                    contact: {filter: {type_code: ['CONTACT']}, draw: {points: {texture: 'contact', size: "20%",collide: false, interactive: true}}},
                    moocs: {filter: {type_code: ['MOOCS']}, draw: {points: {texture: 'moocs', size: "20%",collide: false, interactive: true}}},
                    git_repository: {filter: {type_code: ['GIT_REPOSITORY']}, draw: {points: {texture: 'git_repository', size: "20%",collide: false, interactive: true}}},
                    gdocs: {filter: {type_code: ['GDOCS']}, draw: {points: {texture: 'gdocs', size: "20%",collide: false, interactive: true}}},
                    gslides: {filter: {type_code: ['GSLIDES']}, draw: {points: {texture: 'gslides', size: "20%",collide: false, interactive: true}}},
                    gsheet: {filter: {type_code: ['GSHEET']}, draw: {points: {texture: 'gsheet', size: "20%",collide: false, interactive: true}}},
                    gcollab: {filter: {type_code: ['GCOLLAB']}, draw: {points: {texture: 'gcollab', size: "20%",collide: false, interactive: true}}},
                    wikipedia: {filter: {type_code: ['WIKIPEDIA']}, draw: {points: {texture: 'wikipedia', size: "20%",collide: false, interactive: true}}},
                    notion: {filter: {type_code: ['NOTION']}, draw: {points: {texture: 'notion', size: "20%",collide: false, interactive: true}}},
                    global: {
                        draw: {
                            text: {
                                text_source: `function(){
                                        if(feature.name.length > 10){
                                            return feature.name.slice(0, 10) + '...'
                                        } else {
                                            return feature.name
                                        }
                                    }
                                `,
                                anchor: 'bottom',
                                offset: ['0', '18px'],
                                align: 'center',
                                text_wrap: 32,
                                collide: true,
                                interactive: false,
                                font: {
                                    typeface: 'Basier circle',
                                    fill: `function(){
                                        if(global.selectedItem === feature.id){
                                            return '#2953e8'
                                        } else {
                                            return '#707070'
                                        }
                                    }
                                    `,
                                    size: '11px'
                                }
                            }
                        }
                    }
                }
            }
        }, noWrap: true,
        events: {
            hover: function(selection) { detectHoverLevel1Item(selection) },
        }
    })
}

function getLevel0Layer (mapUuid){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_items: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/item/{z}/{x}/{y}.pbf',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: ""
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                selectedItem: ""
            },
            layers: {
                weeki_map_items: {
                    data: {source: 'weeki_map_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.icon_zlevel > $zoom}",
                    draw: {
                        points: {
                            color: '#000000',
                            // size: `function() {
                            //     var minZ = feature.min_zlevel;
                            //     var iconZ = feature.icon_zlevel;
                            //
                            //     return [[minZ, "1px"], [iconZ, "3px"]]';
                            // }`,
                            // size: "function() {return [[feature.min_zlevel, '1px'], [feature.icon_zlevel, '3px']]}",
                            size: [[1, '4px'], [18, '8px']],
                            collide: false,
                            interactive: false
                        }
                    }
                }
            }
        }, noWrap: true
    })
}

function getIconLevelLayer (mapUuid){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_items: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/item/{z}/{x}/{y}.pbf',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: ""
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                selectedItem: ""
            },
            textures: {
                pdf: {url: '/static/map/pdf.png'},
                bibtex: {url: '/static/map/bibtex.png'},
                sound: {url: '/static/map/sound.png'},
                url: {url: '/static/map/url.png'},
                picture: {url: '/static/map/picture.png'},
                code: {url: '/static/map/code.png'},
                texts: {url: '/static/map/texts.png'},
                latex: {url: '/static/map/latex.png'},
                tasks: {url: '/static/map/tasks.png'},
                company: {url: '/static/map/company.png'},
                contact: {url: '/static/map/contact.png'},
                offline_video: {url: '/static/map/offline_video.png'},
                online_video: {url: '/static/map/online_video.png'},
            },
            layers: {
                weeki_map_items: {
                    data: {source: 'weeki_map_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.icon_zlevel <= $zoom && feature.zlevel1 > $zoom}",
                    pdf: {filter: {type_code: ['PDF']}, draw: {points: {texture: 'pdf', size: "10%",collide: false, interactive: true}}},
                    bibtex: {filter: {type_code: ['BIBTEX']}, draw: {points: {texture: 'bibtex', size: "10%",collide: false, interactive: true}}},
                    sound: {filter: {type_code: ['SOUND']}, draw: {points: {texture: 'sound', size: "10%",collide: false, interactive: true}}},
                    url: {filter: {type_code: ['URL']}, draw: {points: {texture: 'url', size: "10%",collide: false, interactive: true}}},
                    picture: {filter: {type_code: ['PICTURES']}, draw: {points: {texture: 'picture', size: "10%",collide: false, interactive: true}}},
                    code: {filter: {type_code: ['CODE']}, draw: {points: {texture: 'code', size: "10%",collide: false, interactive: true}}},
                    texts: {filter: {type_code: ['TEXTS']}, draw: {points: {texture: 'texts', size: "10%",collide: false, interactive: true}}},
                    latex: {filter: {type_code: ['LATEX']}, draw: {points: {texture: 'latex', size: "10%",collide: false, interactive: true}}},
                    tasks: {filter: {type_code: ['TASKS']}, draw: {points: {texture: 'tasks', size: "10%",collide: false, interactive: true}}},
                    company: {filter: {type_code: ['COMPANY']}, draw: {points: {texture: 'company', size: "10%",collide: false, interactive: true}}},
                    offline_video: {filter: {type_code: ['VIDEOS_OFFLINE']}, draw: {points: {texture: 'offline_video', size: "10%",collide: false, interactive: true}}},
                    online_video: {filter: {type_code: ['VIDEOS_ONLINE']}, draw: {points: {texture: 'online_video', size: "10%",collide: false, interactive: true}}},
                    contact: {filter: {type_code: ['CONTACT']}, draw: {points: {texture: 'contact', size: "10%",collide: false, interactive: true}}},
                }
            }
        }, noWrap: true
    })
}

function getGridLayer(){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_bg_grid: {
                    type: 'Grid',
                    tile_grid_depth: 4
                }
            },
            layers: {
                weeki_grid_line_pattern: {
                    data: {source: 'weeki_bg_grid', layer: 'grid'},
                    grid_main: {
                        filter: {depth: 0},
                        draw: {
                            lines: {
                                order: 0,
                                color: '#e0e0e0',
                                width: '1.5px'
                            }
                        }
                    },
                    grid_depth_1: {
                        filter: {depth: 1},
                        draw: {
                            lines: {
                                order: 0,
                                color: '#e0e0e0',
                                width: '1.5px'
                            }
                        }
                    },
                    grid_depth_2: {
                        filter: {depth: 2},
                        draw: {
                            lines: {
                                order: 0,
                                color: '#d1d0d0',
                                width: '0.5px'
                            }
                        }
                    },
                    grid_depth_3: {
                        filter: {depth: 3},
                        draw: {
                            lines: {
                                order: 0,
                                color: '#d1d0d0',
                                width: '0.5px'
                            }
                        }
                    }
                }
            }
        }, noWrap: true
    })
}

function getLevel1Layer (mapUuid, generateLevel1Popup){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_items: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/item/{z}/{x}/{y}.pbf',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: ""
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                selectedItem: ""
            },
            textures: {
                pdf: {url: '/static/map/pdf.png'},
                bibtex: {url: '/static/map/bibtex.png'},
                sound: {url: '/static/map/sound.png'},
                url: {url: '/static/map/url.png'},
                picture: {url: '/static/map/picture.png'},
                code: {url: '/static/map/code.png'},
                texts: {url: '/static/map/texts.png'},
                latex: {url: '/static/map/latex.png'},
                tasks: {url: '/static/map/tasks.png'},
                company: {url: '/static/map/company.png'},
                contact: {url: '/static/map/contact.png'},
                offline_video: {url: '/static/map/offline_video.png'},
                online_video: {url: '/static/map/online_video.png'},
            },
            layers: {
                weeki_map_items_level1: {
                    data: {source: 'weeki_map_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.zlevel1 <= $zoom && feature.zlevel2 > $zoom && global.selectedItem !== feature.id}",
                    pdf: {filter: {type_code: ['PDF']}, draw: {points: {texture: 'pdf', size: "25%",collide: false, interactive: true}}},
                    bibtex: {filter: {type_code: ['BIBTEX']}, draw: {points: {texture: 'bibtex', size: "25%",collide: false, interactive: true}}},
                    sound: {filter: {type_code: ['SOUND']}, draw: {points: {texture: 'sound', size: "25%",collide: false, interactive: true}}},
                    url: {filter: {type_code: ['URL']}, draw: {points: {texture: 'url', size: "25%",collide: false, interactive: true}}},
                    picture: {filter: {type_code: ['PICTURES']}, draw: {points: {texture: 'picture', size: "25%",collide: false, interactive: true}}},
                    code: {filter: {type_code: ['CODE']}, draw: {points: {texture: 'code', size: "25%",collide: false, interactive: true}}},
                    texts: {filter: {type_code: ['TEXTS']}, draw: {points: {texture: 'texts', size: "25%",collide: false, interactive: true}}},
                    latex: {filter: {type_code: ['LATEX']}, draw: {points: {texture: 'latex', size: "25%",collide: false, interactive: true}}},
                    tasks: {filter: {type_code: ['TASKS']}, draw: {points: {texture: 'tasks', size: "25%",collide: false, interactive: true}}},
                    company: {filter: {type_code: ['COMPANY']}, draw: {points: {texture: 'company', size: "25%",collide: false, interactive: true}}},
                    offline_video: {filter: {type_code: ['VIDEOS_OFFLINE']}, draw: {points: {texture: 'offline_video', size: "25%",collide: false, interactive: true}}},
                    online_video: {filter: {type_code: ['VIDEOS_ONLINE']}, draw: {points: {texture: 'online_video', size: "25%",collide: false, interactive: true}}},
                    contact: {filter: {type_code: ['CONTACT']}, draw: {points: {texture: 'contact', size: "25%",collide: false, interactive: true}}},
                    global: {
                        draw: {
                            text: {
                                text_source: 'name',
                                anchor: 'bottom',
                                offset: ['0', '18px'],
                                align: 'center',
                                text_wrap: 32,
                                collide: false,
                                interactive: false,
                                font: {
                                    typeface: 'Basier circle',
                                    fill: `function(){
                                        if(global.selectedItem === feature.id){
                                            return '#2953e8'
                                        } else {
                                            return '#707070'
                                        }
                                    }
                                    `,
                                    size: '11px'
                                }
                            }
                        }
                    }
                }
            }
        }, noWrap: true, events: {
            hover: function(selection) { generateLevel1Popup(selection) },
        }
    })
}

function getLevel2Layer (mapUuid, publicMap){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_level2_items_source: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/item/{z}/{x}/{y}.pbf?rev={v}',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: "",
                        publicMap: publicMap
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                selectedItem: "",
                itemLevel: 2
            },
            layers: {
                level2_items: {
                    data: {source: 'weeki_level2_items_source', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom && feature.zlevel2 <= $zoom}",
                    draw: {
                        points: {
                            color: 'transparent',
                            size: '10px',
                            collide: false,
                            interactive: true
                        },
                    }
                }
            }
        }, noWrap: true
    })
}

function getTextLayer (mapUuid, publicMap){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_text_items: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/text/{z}/{x}/{y}.pbf',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: JSON.stringify(useMapStore.getState().mapFilters),
                        publicMap: publicMap
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                itemLevel: "text"
            },
            layers: {
                weeki_map_text_items: {
                    data: {source: 'weeki_map_text_items', layer: 'default'},
                    filter: "function() {return feature.min_zlevel <= $zoom && feature.max_zlevel >= $zoom}",
                    draw: {
                        points: {
                            color: 'transparent',
                            size: '10px',
                            collide: false,
                            interactive: true
                        },
                    }
                }
            }
        }, noWrap: true
    })
}

function getAreaTitleLayer(mapUuid, publicMap){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_area_labels: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/area_label/{z}/{x}/{y}.pbf?rev={v}',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: JSON.stringify(useMapStore.getState().mapFilters),
                        publicMap: publicMap
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                edited_item_id: ""
            },
            layers: {
                weeki_map_labels: {
                    data: {source: 'weeki_map_area_labels', layer: 'default'},
                    filter: `function() {
                        return (global.edited_item_id !== feature['id'])
                    }`,
                    draw: {
                        text: {
                            interactive: true,
                            text_source: 'name',
                            text_wrap: 12,
                            collide: true,
                            font: {
                                typeface: 'Helvetica',
                                fill: '#707070',
                                size: [[1, "8px"], [18, "16px"]]
                            }
                        }
                    },
                    sublayer_top_left: {
                        filter: {
                            title_placement: 'top-left'
                        },
                        draw : {
                            text: {
                                anchor: 'bottom-right',
                                align: 'left',
                                offset: ['8px', '5px']
                            }
                        }
                    }
                }
            }
        }, noWrap: true, events: {
            hover: function(selection) { detectAreaTitleHover(selection) },
            click: function (selection) { detectAreaTitleClick(selection) }
        }
    })
}

function getAreaLayer(mapUuid, publicMap){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_areas: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/area/{z}/{x}/{y}.pbf?rev={v}',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: JSON.stringify(useMapStore.getState().mapFilters),
                        publicMap: publicMap
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                hovered_item_id: "",
                mocked_item_id: "",
                edited_item_id: ""
            },
            styles: {
                areas: {
                    base: 'polygons',
                    blend: 'opaque',
                    lighting: false
                },
                polygon_shadows: {
                    base: 'polygon',
                    visible: false,
                }
            },
            layers: {
                weeki_map_areas: {
                    data: {source: 'weeki_map_areas', layer: 'default'},
                    filter: `function() {
                        return (global.edited_item_id !== feature['id'])
                    }`,
                    weeki_map_areas_bg: {
                        filter: `function() { return $zoom <= feature.max_bg_zlevel; }`,
                        draw: {
                            areas: {
                                interactive: true,
                                color: `function() {
                                var op = feature.opacity;
                                    if (global.hovered_item_id === feature['id']) {
                                        if (feature.opacity >= 0.5) { op = op - 0.3; } else { op = op + 0.3; }
                                    }
                                return 'rgba(' + feature.fill_color + ',' + op + ')'; }`,
                                order: `function() { return feature.depth; } `
                            }
                        }
                    },
                    draw: {
                        lines: {
                            color: `function() { return 'rgb(' + feature.border_color + ')'; }`,
                            width: `function() { return feature.border_width * $meters_per_pixel * Math.pow(1.2, $zoom - feature.ref_zlevel); }`,
                            order: `function() { return feature.depth + 0.5; }`
                        }
                    }

                    // draw: {
                    //     areas: {
                    //         interactive: true,
                    //         // color: 'rgba(0, 0, 100)',
                    //         color: `function() {
                    //             var op = feature.opacity;
                    //             if (global.hovered_item_id === feature['id']) {
                    //                 if (feature.opacity >= 0.5) { op = op - 0.3; } else { op = op + 0.3; }
                    //             }
                    //             return 'rgba(' + feature.fill_color + ',' + op + ')';
                    //         }`,
                    //         order: "function() { return feature.depth; }",
                    //     },
                    //     lines: {
                    //         color: "function() { return 'rgb(' + feature.border_color + ')' }",
                    //         width: "function() { return feature.border_width * $meters_per_pixel; }",
                    //         order: "function() { return feature.depth + 0.5; }"
                    //     }
                    // }
                }
            }
        }, noWrap: true
    })
}

function getShapeLayer(mapUuid, publicMap){
    return Tangram.leafletLayer({
        scene: {
            sources: {
                weeki_map_shapes: {
                    type: 'MVT',
                    url: process.env.REACT_APP_TILES_SERVER_URL + '/get_tiles/' + mapUuid + '/shape/{z}/{x}/{y}.pbf',
                    url_subdomains: process.env.REACT_APP_TILES_SUBDOMAIN.split(',').map((v) => parseInt(v)),
                    request_headers: {
                        Authorization: "Bearer " + window.localStorage.getItem('authToken')
                    },
                    url_params: {
                        filters: JSON.stringify(useMapStore.getState().mapFilters),
                        publicMap: publicMap
                    },
                    min_display_zoom: 1,
                    max_display_zoom: 18,
                    min_zoom: 1,
                    max_zoom: 18,
                    tile_size: 512
                }
            },
            global: {
                hovered_item_id: "",
                mocked_item_id: "",
                edited_item_id: ""
            },
            layers: {
                weeki_map_shapes_lines: {
                    data: {source: 'weeki_map_shapes', layer: 'default'},
                    filter: `function() {
                        return (global.edited_item_id !== feature['id'])
                    }`,
                    draw: {
                        lines: {
                            color: `function() {
                                return 'rgb(' + feature.color + ')'; }`,
                            width: `function() { return feature.border_width * $meters_per_pixel;}`,
                            // width: '20px',
                            order: 21,
                            interactive: true
                        }
                    }
                },
                weeki_map_shapes_polygons: {
                    data: {source: 'weeki_map_shapes', layer: 'default'},
                    filter: `function() {
                        return (global.edited_item_id !== feature['id'])
                    }`,
                    draw: {
                        polygons: {
                            color: `function() {
                                var op = feature.opacity;
                                    if (global.hovered_item_id === feature['id']) {
                                        if (feature.opacity >= 0.5) { op = op - 0.3; } else { op = op + 0.3; }
                                    }
                                return 'rgba(' + feature.fill_color + ',' + op + ')'; }`,
                            order: 20,
                            collide: false,
                            interactive: true
                        }
                    }
                }
            }
        }, noWrap: true
    })
}

function getAreaLatLng(featureProps){
    //Get feature center
    let xc = featureProps['xc']
    let yc = featureProps['yc']

    //Get feature height and with
    let height = featureProps['height']
    let width = featureProps['width']

    //Calculate bounds from feature data
    const dx = width * 0.5
    const dy = height * 0.5

    const featureBounds = [
        [xc - dx, yc - dy],
        [xc + dx, yc + dy]
    ]

    return featureBounds.map(c => {
        return L.Projection.SphericalMercator.unproject(new L.Point(c[0], c[1]))
    })
}

export default {
    getItemsLayer,
    getLevel0Layer,
    getIconLevelLayer,
    getLevel1Layer,
    getLevel2Layer,
    getAreaLayer,
    getAreaLatLng,
    getAreaTitleLayer,
    getGridLayer,
    getTextLayer,
    getShapeLayer
}