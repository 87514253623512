import React from "react";

const CustomTooltip = ({position = "left", text}) => {
	return (
		<div className={"custom__tooltip " + position + "-tooltip-arrow"}>
			<p className="text">{text}</p>
		</div>
	)
}

export default CustomTooltip