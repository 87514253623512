import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import AuthAPI from "./js/services/AuthAPI";
import AuthContext from "./js/contexts/AuthContext";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import 'react-loading-skeleton/dist/skeleton.css'

//Import style
import "./css/base.scss"
import SearchBar from './js/components/customs/global_search_bar/SearchBar';
//Import pages
import ConfirmAccount from "./js/pages/Auth/ConfirmAccount";
import OauthSuccess from "./js/pages/OauthSuccess";
import AdminDashboardMaps from "./js/pages/Admin/AdminDashboardMaps";
import AdminDashboard from "./js/pages/Admin/AdminDashboard";
import AdminDashboardUsers from "./js/pages/Admin/AdminDashboardUsers";
import AdminDashboardEmail from "./js/pages/Admin/AdminDashboardEmail";
import AdminDashboardPlans from "./js/pages/Admin/AdminDashboardPlans";
import AdminDashboardStore from "./js/pages/Admin/AdminDashboardStore";
import CookiesPopup from "./js/components/CookiesPopup";
import ForgotPassword from "./js/pages/Auth/ForgotPassword";
import ResetPassword from "./js/pages/Auth/ResetPassword";
import Settings from "./js/pages/Settings";
import NewsletterUnsubscribe from "./js/pages/NewsletterUnsubscribe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Map from "./js/pages/Map";
import AdminDashboardHistory from "./js/pages/Admin/AdminDashboardHistory";
import AdminDashboardHistoryTable from "./js/pages/Admin/AdminDashboardHistoryTable";
import SupportPopupContext from "./js/contexts/SupportPopupContext";
import SupportPopup from "./js/components/SupportPopup";
import AdminDashboardSupport from "./js/pages/Admin/AdminDashboardSupport";
import NotFound from "./js/pages/NotFound";
import PublicMap from "./js/pages/PublicMap";
import AdminDashboardMaintenance from "./js/pages/Admin/AdminDashboardMaintenance";
import DocumentationPopupContext from "./js/contexts/DocumentationPopupContext";
import DocumentationPopup from "./js/components/DocumentationPopup";
import AdminDashboardObjectType from "./js/pages/Admin/AdminDashboardObjectType";
import BrowserNotSupported from "./js/pages/BrowserNotSupported";
import Login2 from "./js/pages/Auth/Login2";
import SubscribePopupContext from "./js/contexts/SubscribePopupContext";
import SubscribePopup from "./js/components/SubscribePopup";
import {useMapStore, useMercureStore, useUserStore} from "./js/services/Stores";
import {MathJaxContext} from "better-react-mathjax";
import Register2 from "./js/pages/Auth/Register2";
import UserAPI from "./js/services/UserAPI";
import UserPaymentAPI from "./js/services/API/User/UserPaymentAPI";
import CustomToastContainer from "./js/components/CustomToastContainer";
import OauthAppleSuccess from "./js/pages/OauthAppleSuccess";
import TutorialTooltip from "./js/components/customs/TutorialTooltip";
import UserNotificationAPI from "./js/services/API/User/UserNotificationAPI";
import { useNotificationStore } from "./js/services/Stores/NotificationsStore";
import { enableNotificationListener } from "./js/services/Utils/EventSourceListerners/NotificationsListener";
import DashboardTemplate from "./js/pages/Dashboard/DashboardTemplate";
import PopupOverlay from "./js/components/customs/PopupOverlay";
import UtilsComponent from "./js/components/UtilsComponent";
import NotConfirmedAccountAlert from "./js/components/customs/header_alert/NotConfirmedAccountAlert";
import UTMParams from "./js/components/UTMParams";
import { mapContentItemUpdateListener } from "./js/services/Utils/MapUpdateListenersUtils";
import ObjectTableContainer from "./js/components/customs/object-table/ObjectTableContainer";
import {useObjectTableStore} from "./js/services/Stores/ObjectTableStore";
import {useMapGlobalSelectionStore} from "./js/services/Stores/MapGlobalSelectionStore";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

export const MAX_WORKSPACE_LOGO_SIZE_MO = 4

export const globalAvailableColor = [
    "#000000",
    "#FFFFFF",
    "#ED1C24",
    "#FF7F27",
    "#FFF200",
    "#22B14C",
    "#00A2E8",
    "#3F48CC",
    "#A349A4",
    "#B97A57",
    "#FFAEC9",
    "#FFC90E",
    "#EFE4B0",
    "#B5E61D",
    "#99D9EA",
    "#7092BE",
    "#C8BFE7",
    "#fdfdfd",
    "#e9f0fc",
    "#fceaea",
    "#e3e9fa",
    "#fdefe5",
    "#fce9ec",
    "#fdf8e5",
    "#f9eafc",
    "#eafaf0",
    "#f1ecfc",
    "#e7f9f7",
    "#e3e3e3"
]

export const globalLicenseType = {
    'Academic Free License v3.0': 'AFL-3.0',
    'Licence Apache 2.0': 'Apache-2.0',
    'Licence Artistic 2.0': 'Artistic-2.0',
    'Licence logicielle Boost 1.0': 'BSL-1.0',
    'Licence BSD « simplifiée » à 2 clauses': 'BSD-2-Clause',
    'Licence BSD « nouvelle» ou «révisée» à 3 clauses': 'BSD-3-Clause',
    'Licence BSD Clear à 3 clauses': 'BSD-3-Clause-Clear',
    'Licence BSD 4-clause « originale » ou « ancienne »': 'BSD-4-Clause',
    'Licence BSD zéro clause': '0BSD',
    'Famille de licences Creative Commons': 'CC',
    'Creative Commons Zero v1.0 Universal': 'CC0-1.0',
    'Creative Commons Attribution 4.0': 'CC-BY-4.0',
    'Creative Commons Attribution ShareAlike 4.0': 'CC-BY-SA-4.0',
    'Do What The F*ck You Want To Public License': 'WTFPL',
    'Educational Community License v2.0': 'ECL-2.0',
    'Eclipse Public License 1.0': 'EPL-1.0',
    'Eclipse Public License 2.0': 'EPL-2.0',
    'Licence publique de l’Union européenne 1.1': 'EUPL-1.1',
    'GNU Affero General Public License v3.0': 'AGPL-3.0',
    'Famille de licences publiques générales GNU': 'GPL',
    'Licence publique générale GNU v2.0': 'GPL-2.0',
    'Licence publique générale GNU v3.0': 'GPL-3.0',
    'Famille de licences publiques générales limitées GNU': 'LGPL',
    "Licence publique générale limitée GNU v2.1": "LGPL-2.1",
    'Licence publique générale limitée GNU v3.0': 'LGPL-3.0',
    'ISC': 'ISC',
    'LaTeX Project Public License v1.3c': 'LPPL-1.3c',
    'Microsoft Public License': 'MS-PL',
    'MIT': 'MIT',
    'Mozilla Public License 2.0': 'MPL-2.0',
    'Open Software License 3.0': 'OSL-3.0',
    'PostgreSQL License': 'PostgreSQL',
    'SIL Open Font License 1.1': 'OFL-1.1',
    'Licence open source Université de l’Illinois/NCSA': 'NCSA',
    'Unlicense': 'Unlicense',
    'zLib License': 'Zlib'
}


function App() {

    //Define default state for browser support status
    const [browserSupported, setBrowserSupported] = useState(true)

    //Get event source from store
    const mercureEventSource = useMercureStore(state => state.mercureEventSource)

    //Get Object table open state in store
    const objectTableOpenState = useObjectTableStore(state => state.openState)

    //Detect eventSource change for enable listeners
    useEffect(() => {
        if (mercureEventSource !== null) {
            mercureEventSource.onmessage = (event) => {
                //Parse data to JSON object
                const data = JSON.parse(event.data)

                mapContentItemUpdateListener(data)
                enableNotificationListener(data)
            }
        }
    }, [mercureEventSource])

    const [cookies, setCookie, removeCookie] = useCookies(['mercureAuthorization', 'authCookie'])

    const [isAuthenticated, setIsAuthenticated] = useState(
        AuthAPI.isAuthenticated()
    )

    const [isAdmin, setIsAdmin] = useState(
        AuthAPI.isAdmin()
    )

    const [supportPopupStatus, setSupportPopupStatus] = useState(false)
    const [documentationPopupStatus, setDocumentationPopupStatus] = useState(false)
    const [subscribePopupStatus, setSubscribePopupStatus] = useState(false)

    //Get user profile if user is authenticated and store user globally in store
    useEffect(() => {
        if (isAuthenticated) {
            useMercureStore.setState({ mercureToken: cookies.mercureAuthorization })

            //Open mercure event source
            AuthAPI.openMercureEventSource()

            UserAPI.getUserProfile().then((response) => {
                if (response.status === 200) {
                    useUserStore.setState({ user: response.data, userLoad: true, confirmedAccount: response.data.accountConfirmed })

                    if (response.data.role === "ROLE_ADMIN") {
                        setIsAdmin(true)
                    }

                    UserPaymentAPI.getCurrentUserSubscription().then((response) => {
                        if (response.status === 200) {
                            useUserStore.setState({ userSubscription: response.data })
                        }
                    })
                }
            })

            //Get user notifications list
            UserNotificationAPI.getAllUserNotification().then((response) => {
                if (response.status === 200) {
                    useNotificationStore.setState({ notifications: response.data, unReadNotificationsCount: response.data.filter(n => !n.read).length })
                }
            })
        } else {
            useUserStore.setState({ user: {}, userSubscription: null, userLoad: false })
            removeCookie('authCookie')
        }
    }, [isAuthenticated])

    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
            ]
        }
    };

    useEffect(() => {
        // document.body.addEventListener('keypress', (event) => {
        //     if (!useObjectTableStore.getState().openState) {
        //         if (event.ctrlKey && event.code === "KeyY") {
        //             event.preventDefault()
        //             event.stopPropagation()
        //             useObjectTableStore.setState({ openState: true })
        //         }
        //     }
        // })
        document.body.addEventListener('keydown', (event) => {
                if (event.key === "Control"){
                    if (!useMapStore.getState().selectMode){
                        useMapStore.setState({selectMode: true})
                    }
                }
                if (event.key === "Shift" && useMapGlobalSelectionStore.getState().selectionLayer !== null){
                    useMapGlobalSelectionStore.setState({copySelectionState: true})

                }
        })
        document.body.addEventListener('keyup', (event) => {
                if (event.key === "Control"){
                    if (useMapStore.getState().selectMode){
                        useMapStore.setState({selectMode: false})
                        useMapGlobalSelectionStore.setState({drawState: false})
                    }
                }
                if (event.key === "Shift" && useMapGlobalSelectionStore.getState().copySelectionState){
                    setTimeout(() => {
                        useMapGlobalSelectionStore.setState({copySelectionState: false})
                    }, 1000)
                }
        })
        document.body.addEventListener('mousemove', (event) => {
            if (useMapGlobalSelectionStore.getState().copySelectionState){
                useMapGlobalSelectionStore.setState({copyIndicatorPosition: {x: event.pageX, y: event.pageY}})
            }
        })
    }, []);



    return (
        <AuthContext.Provider value={{
            isAuthenticated: isAuthenticated,
            setIsAuthenticated: setIsAuthenticated
        }}>
            <SupportPopupContext.Provider value={{
                supportPopupStatus: supportPopupStatus,
                setSupportPopupStatus: setSupportPopupStatus
            }}>
                <DocumentationPopupContext.Provider value={{
                    documentationPopupStatus: documentationPopupStatus,
                    setDocumentationPopupStatus: setDocumentationPopupStatus
                }}>
                    <SubscribePopupContext.Provider value={{
                        subscribePopupStatus: subscribePopupStatus,
                        setSubscribePopupStatus: setSubscribePopupStatus
                    }}>
                        <MathJaxContext version={3} config={config}>
                            <NotConfirmedAccountAlert />
                            <CustomToastContainer />
                            <PopupOverlay />
                            <SupportPopup />
                            <DocumentationPopup />
                            {/*<CookiesPopup/>*/}
                            {isAuthenticated && (
                                <Elements stripe={stripePromise}>
                                    <SubscribePopup />
                                </Elements>
                            )}

                            <BrowserRouter>
                                <UTMParams />
                                {isAuthenticated && (
                                    <>
                                        {/*<SearchBar />*/}
                                        <UtilsComponent />
                                    </>
                                )}
                                {(isAuthenticated && objectTableOpenState) && (
                                    <ObjectTableContainer />
                                )}
                                <Switch>
                                    {/*NO AUTH ROUTE*/}
                                    <Route exact={true} path="/" component={Login2} />
                                    {/*<Route exact={true} path="/login2" component={Login2} />*/}
                                    {process.env.REACT_APP_REGISTER_STATUS === "true" && (
                                        <Route exact={true} path="/register" component={Register2} />
                                    )}
                                    <Route exact={true} path="/forgot-password" component={ForgotPassword} />
                                    <Route exact={true} path="/confirm-account/:token" component={ConfirmAccount} />
                                    <Route exact={true} path="/reset-password/:email/:token" component={ResetPassword} />
                                    <Route exact={true} path="/newsletter-unsubscribe/:email" component={NewsletterUnsubscribe} />
                                    <Route exact={true} path="/oauth/success" component={OauthSuccess} />
                                    <Route exact={true} path="/oauth/apple/success" component={OauthAppleSuccess} />
                                    <Route exact={true} path="/public/map/:mapUuid" component={PublicMap} />
                                    {/*AUTHENTICATED USER ROUTES*/}
                                    {!isAuthenticated && (
                                        <Redirect to="/" />
                                    )}
                                    <Route exact={true} path="/user/settings" component={Settings} />
                                    {/*<Route exact={true} path="/user/dashboard/store" component={UserStore}/>*/}
                                    {/*<Route exact={true} path="/user/dashboard/maps" component={HomeUserDashboard}/>*/}
                                    {/*<Route exact={true} path="/user/dashboard/myboards" component={MyBoards}/>*/}
                                    <Route exact={true} path="/:dashboardType/dashboard/:page" component={DashboardTemplate} />
                                    {/*<Route exact={true} path="/user/dashboard/users" component={UserUsers}/>*/}
                                    <Route exact={true} path="/user/map/:mapUuid" component={Map} />
                                    {/*ADMIN ROUTE*/}
                                    {!isAdmin && (
                                        <Redirect to="/user/dashboard/maps" />
                                    )}
                                    <Route exact={true} path="/admin/dashboard/history/:historyType"
                                        component={AdminDashboardHistoryTable} />
                                    <Route exact={true} path="/admin/dashboard/plans" component={AdminDashboardPlans} />
                                    <Route exact={true} path="/admin/dashboard/maps" component={AdminDashboardMaps} />
                                    <Route exact={true} path="/admin/dashboard/object-type"
                                        component={AdminDashboardObjectType} />
                                    <Route exact={true} path="/admin/dashboard/store" component={AdminDashboardStore} />
                                    <Route exact={true} path="/admin/dashboard/users" component={AdminDashboardUsers} />
                                    <Route exact={true} path="/admin/dashboard/emails" component={AdminDashboardEmail} />
                                    <Route exact={true} path="/admin/dashboard/history"
                                        component={AdminDashboardHistory} />
                                    <Route exact={true} path="/admin/dashboard/support"
                                        component={AdminDashboardSupport} />
                                    <Route exact={true} path="/admin/dashboard/maintenance"
                                        component={AdminDashboardMaintenance} />
                                    <Route exact={true} path="/admin/dashboard" component={AdminDashboard} />
                                    {/*404 ROUTE*/}
                                    <Route exact={true} path="/404" component={NotFound} />
                                    <Redirect to="/404" />
                                </Switch>
                            </BrowserRouter>
                            <ToastContainer style={{ "zIndex": "99999999" }} position="top-right" autoClose={5000} />
                        </MathJaxContext>
                    </SubscribePopupContext.Provider>
                </DocumentationPopupContext.Provider>
            </SupportPopupContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
