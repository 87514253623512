import React, {useContext, useEffect} from "react";
import AuthContext from "../contexts/AuthContext";
import {Redirect, useHistory} from "react-router-dom";
import AuthAPI from "../services/AuthAPI";
import {TailSpin} from "react-loader-spinner";
import isAdminContext from "../contexts/IsAdminContext";

const OauthSuccess = () => {
    const history = useHistory()
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
    const {isAdmin, setIsAdmin} = useContext(isAdminContext)

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const code = urlParams.get("code")

    const validUserAuth = async () => {
        if (await AuthAPI.validGoogleOauth(code)) {
            setIsAuthenticated(true)
            if (AuthAPI.isAdmin()){
                setIsAdmin(true)
            }
            history.push("/user/dashboard/maps")
        } else {
            history.push("/")
        }
    }

    useEffect(() => {
        if (code){
            validUserAuth().then()
        } else {
            history.push("/")
        }
    }, [])

    if (isAuthenticated){
        return (
            <Redirect to="/user/dashboard/maps" />
        )
    }

    return (
        <div className="full-page-loader-container">
            <TailSpin height="128" width="128" color="#000"/>
        </div>
    )
}

export default OauthSuccess