import {useObjectTableStore} from "../../../services/Stores/ObjectTableStore";
import React, {useEffect, useRef, useState} from "react";
import UserContentItemAPI from "../../../services/API/User/UserContentItemAPI";
import { FiFilter } from "react-icons/fi";
import { CiViewColumn } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import { useSidebarMapContentItemStore } from "../../../services/Stores/SidebarMapContentItemStore";
import { BiComment, BiCommentAdd, BiSolidFileExport } from "react-icons/bi";
import { VscChromeClose } from "react-icons/vsc";
import { MdOutlineContentCopy } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaBars } from "react-icons/fa";
import { IoGridSharp } from "react-icons/io5";
import { getContextMenuPosition, removeArrayDuplicate, useOnClickOutside } from "../../../services/Utils";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
// Import the global color array from App.js
import {globalAvailableColor} from "../../../../App";
import ContextMenuContainer from "../content_menu/ContextMenuContainer";
import ContextMenuItem from "../content_menu/ContextMenuItem";
import {GoEye} from "react-icons/go";
import {useHistory} from "react-router-dom";
import {createSuccessToast} from "../../../services/CustomToastUtils";
import UserMapAPI from "../../../services/API/User/UserMapAPI";
import ContentItemAPI from "../../../services/ContentItemAPI";
import ContentItemTagAPI from "../../../services/ContentItemTagAPI";

const ObjectTableItemList = () => {
	// Get the search Query (selectedItem) from the zustand store
	const items = useObjectTableStore((state) => state.items);
	const searchQuery = useObjectTableStore((state) => state.searchQuery);

	const [currentEditingItem, setCurrentEditingItem] = useState(null);
	// ------------------------- //
	// COLOR PICKER SECTION
	// I manage the state of the test block and its position
	const [showColorPicker, setShowColorPicker] = useState(false);
	const [ColorPickerPosition, setColorPickerPosition] = useState({
		top: 0,
		left: 0,
	});
	// I manage as well because i get its uuid the state of the current editing item

	// Using the state manager of the currentEditingItem i get the client X and Y pos of the cursor and display the div block
	const handleColorPicker = (event, item) => {
		event.stopPropagation();
		// Set the position of the div block based on the cursor position
		setCurrentEditingItem(item);
		setColorPickerPosition({
			top: event.clientY,
			left: event.clientX,
		});
		setShowColorPicker(true);
	};

	// I get currentEditingItem set before and the color selected by the user
	const handleColorSelect = (color) => {
		if (currentEditingItem) {
			// Update the item's color in the local state
			useObjectTableStore.setState({
				items: items.map((item) => (item.uuid === currentEditingItem.uuid ? { ...item, color: color } : item)),
			});

			// I use the ContentItemAPI to update the color of the item in the database by using the updateContentItemColor method
			ContentItemAPI.updateContentItemColor(
				// its uuid to identify the item and set the color
				currentEditingItem.uuid,
				color
				// Voir les autres manière de faire avec un await + async parce que await
			).then((success) => {
				if (success) {
					createSuccessToast("The color has been updated !");
				}
			});
		}
		// I clear the state manager for the next one, and hide the color picker
		setShowColorPicker(false);
		setCurrentEditingItem(null);
	};
	// ------------------------- //
	// TAGS SECTION

	const [showTagBlock, setShowTagBlock] = useState(false);
	const [tagBlockPosition, setTagBlockPosition] = useState({
		top: 0,
		left: 0,
	});
	const [tagInput, setTagInput] = useState("");

	// Using the useState to get the modification from the onChange js function, passing trough event parameter
	const handleTagInputChange = (event) => {
		setTagInput(event.target.value);
	};

	const handleTagInputKeyPress = (event) => {
		if (event.key !== "Enter" || !tagInput.trim()) return;
		event.preventDefault();
		const tagName = tagInput.trim();
		setTagInput("");
		ContentItemTagAPI.addItemTag(currentEditingItem.uuid, tagName).then((tagUuid) => {
			if (!tagUuid) return;

			const newTag = { uuid: tagUuid, name: tagName };
			const updatedItem = {
				...currentEditingItem,
				tags: [...(currentEditingItem.tags || []), newTag],
			};
			setCurrentEditingItem(updatedItem);
			useObjectTableStore.setState({
				items: items.maps((item) => (item.uuid === currentEditingItem.uuid ? updatedItem : item)),
			});
		});
	};

	// Using the state manager of the currentEditingTagItem i get the client X and Y pos of the cursor and display the div block
	const handleTagsPicker = (event, item) => {
		event.stopPropagation();
		// Set the position of the div block based on the cursor position
		setCurrentEditingItem(item);
		setTagBlockPosition({
			top: event.clientY,
			left: event.clientX,
		});
		setShowTagBlock(true);
	};

	const handleCloseTagBlock = () => {
		setShowTagBlock(false);
		setCurrentEditingItem(null);
		setTagInput("");
	};

	const history = useHistory();

	//Get data in store
	const currentSelectedMap = useObjectTableStore((state) => state.currentSelectedMap);
	const currentSelectedOverlay = useObjectTableStore((state) => state.currentSelectedOverlay);
	const currentSelectedArea = useObjectTableStore((state) => state.currentSelectedArea);
	const currentView = useObjectTableStore((state) => state.currentView);
	const filters = useObjectTableStore((state) => state.filters);
	const availableTableColumns = useObjectTableStore((state) => state.availableTableColumns);
	const currentMapObject = useObjectTableStore((state) => {
		const selectedProject = state.myBoardsProjects?.find((p) => p.uuid === state.currentSelectedProject);

		return selectedProject?.maps?.find((m) => m.uuid === state.currentSelectedMap);
	});

	//Define default state
	const [search, setSearch] = useState("");
	// My objects store using useState in an array
	const [exportMenuState, setExportMenuState] = useState(false);
	const [closedType, setClosedType] = useState([]);
	const [filtersMenuState, setFiltersMenuState] = useState(false);

	//Get map items
	useEffect(() => {
		if (currentSelectedArea !== "") {
			UserContentItemAPI.userGetAreaContentItems(currentSelectedArea).then((response) => {
				if (response.status === 200) {
					useObjectTableStore.setState({ items: response.data });
				}
			});
		} else {
			if (currentSelectedOverlay !== "") {
				UserContentItemAPI.userGetOverlayContentItems(currentSelectedOverlay).then((response) => {
					if (response.status === 200) {
						useObjectTableStore.setState({ items: response.data });
					}
				});
			} else {
				if (currentSelectedMap !== "") {
					UserContentItemAPI.userGetMapContentItems(currentSelectedMap).then((response) => {
						if (response.status === 200) {
							useObjectTableStore.setState({ items: response.data });
						}
					});
				}
			}
		}
	}, [currentSelectedMap, currentSelectedOverlay, currentSelectedArea]);

	// on Mounted, set the searchQuery to the search state local from the zustand store
	useEffect(() => {
		if (searchQuery) {
			setSearch(searchQuery);
		}
	}, [searchQuery]);

	const filteredItems = items.filter(
		(i) =>
			String(i.name).toLowerCase().includes(String(search).toLowerCase()) &&
			(filters.filter((f) => f.name === "type")[0].value !== null
				? i.type === filters.filter((f) => f.name === "type")[0].value
				: true) &&
			(filters.filter((f) => f.name === "tags")[0].value.length > 0
				? i.tags.some((t) => filters.filter((f) => f.name === "tags")[0].value.includes(t))
				: true) &&
			(filters.filter((f) => f.name === "color")[0].value !== null
				? i.color === filters.filter((f) => f.name === "color")[0].value
				: true) &&
			(filters.filter((f) => f.name === "createdAt")[0]?.startDate !== null
				? i.createdAtTimestamp >=
				  new Date(filters.filter((f) => f.name === "createdAt")[0]?.startDate).getTime()
				: true) &&
			(filters.filter((f) => f.name === "createdAt")[0].endDate !== null
				? i.createdAtTimestamp <= new Date(filters.filter((f) => f.name === "createdAt")[0].endDate).getTime()
				: true) &&
			(filters.filter((f) => f.name === "author")[0].value !== null
				? String(i.author)
						.toLowerCase()
						.includes(String(filters.filter((f) => f.name === "author")[0].value).toLowerCase())
				: true)
	);

	//Get all available type from filtered items
	const availableType = removeArrayDuplicate(items.map((i) => i.type));

	const handleOpenItemSidebar = (item, comment = false) => {
		useSidebarMapContentItemStore.getState().resetStore();
		useSidebarMapContentItemStore.setState({
			state: true,
			properties: item,
			currentPanel: comment ? "comment" : "info",
		});
	};

	const handleCheckItem = (uuid) => {
		// I change the check status of the item
		useObjectTableStore.setState({ items: items.map((i) => (i.uuid === uuid ? { ...i, check: !i.check } : i)) });
	};

	const handleCheckAllItems = () => {
		useObjectTableStore.setState({ items: items.map((i) => ({ ...i, check: true })) });
	};

	const handleUnCheckAllItems = () => {
		useObjectTableStore.setState({ items: items.map((i) => ({ ...i, check: false })) });
	};

	const handleToggleType = (type) => {
		if (closedType.includes(type)) {
			setClosedType(closedType.filter((t) => t !== type));
		} else {
			setClosedType([...closedType, type]);
		}
	};

	const handleAddTagFilter = (event) => {
		if (event.key === "Enter") {
			useObjectTableStore.getState().addTagToFilter(event.currentTarget.value);
			event.currentTarget.value = "";
		}
	};

	const [itemContextMenu, setItemContextMenu] = useState(false);
	const itemContextMenuRef = useRef(null);
	const [contextMenuItem, setContextMenuItem] = useState(null);
	const handleOpenItemContextMenu = (event, item) => {
		event.stopPropagation();
		event.preventDefault();

		setContextMenuItem(item);
		setItemContextMenu(true);
		getContextMenuPosition(event, itemContextMenuRef.current);
	};

	const handleGoToMapItem = () => {
		if (contextMenuItem) {
			useObjectTableStore.setState({ openState: false });
			history.push(
				"/user/map/" +
					contextMenuItem.mapBase32Uuid +
					"?x=" +
					contextMenuItem.location.x +
					"&y=" +
					contextMenuItem.location.y +
					"&z=" +
					contextMenuItem.zlevel2
			);
		}
	};

	const handleDeleteSelectedItems = async () => {
		//Get selected items
		const selectedItems = items.filter((i) => i.check);

		if (selectedItems.length <= 0) {
			return;
		}

		const uuids = selectedItems.map((i) => i.uuid);

		if (await UserContentItemAPI.userDeleteMultipleContentItems(uuids)) {
			//Un check all items
			handleUnCheckAllItems();

			//Remove deleted items from list
			useObjectTableStore.setState({ items: items.filter((i) => !uuids.includes(i.uuid)) });

			createSuccessToast(uuids.length + " item delete");
		}
	};

	const [exportFormat, setExportFormat] = useState("csv");

	const handleExportItems = async () => {
		//Get all checked items
		let exportedItems = items.filter((i) => i.check);

		//If checked items is empty export all current items
		if (exportedItems.length <= 0) {
			exportedItems = items;
		}

		const url = await UserContentItemAPI.exportContentItems(
			exportFormat,
			exportedItems.map((i) => i.uuid)
		);

		if (url) {
			window.open(url, "_blank").focus();
		}
	};

	const handleExportCsv = (all) => {
		const exportData = items.filter((i) => i.check).length > 0 ? items.filter((i) => i.check) : filteredItems;

		//Check if data contain object
		if (exportData.length > 0) {
			//Create empty csv string
			let csv = "";

			//Add first row with key
			for (let j = 0; j < Object.keys(exportData[0]).length; j++) {
				csv += Object.keys(exportData[0])[j] + ";";
			}
			csv += "\n";

			//Loop the data array and add to csv string
			for (let i = 0; i < exportData.length; i++) {
				for (let j = 0; j < Object.keys(exportData[i]).length; j++) {
					csv += Object.values(exportData[i])[j] + ";";
				}
				csv += "\n";
			}

			//Create link element for download csv file
			const link = document.createElement("a");
			link.id = "download-csv";
			link.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(csv));
			link.setAttribute("download", "objects.csv");
			document.body.appendChild(link);
			document.querySelector("#download-csv").click();

			document.body.removeChild(link);
		}
	};

	const filterMenuRef = useRef(null);
	useOnClickOutside(filterMenuRef, () => {
		setFiltersMenuState(false);
	});

	const [columnMenuState, setColumnMenuState] = useState(false);

	const handleOpenStatusColumnMenu = (item) => {
		useObjectTableStore.setState({
			items: items.map((i) => (i.uuid === item.uuid ? { ...i, statusColumnState: !i.statusColumnState } : i)),
		});
	};

	const handleAddAvailableStatus = (event) => {
		event.stopPropagation();

		const currentMapObjectTableColumn = currentMapObject.objectTableColumn;

		const statusColumnIndex = currentMapObjectTableColumn.findIndex((c) => c.type === "status");

		if (statusColumnIndex < 0) {
			const statusColumn = {
				type: "status",
				availableStatus: [{ name: "New status", color: "#F3D369" }],
			};

			currentMapObjectTableColumn.push(statusColumn);

			useObjectTableStore
				.getState()
				.updateMyBoardsMapTableColumn(
					useObjectTableStore.getState().currentSelectedProject,
					useObjectTableStore.getState().currentSelectedMap,
					currentMapObjectTableColumn
				);
		} else {
			const statusColumn = currentMapObjectTableColumn[statusColumnIndex];

			statusColumn.availableStatus.push({
				name: "New status",
				color: "#F3D369",
			});

			useObjectTableStore
				.getState()
				.updateMyBoardsMapTableColumn(
					useObjectTableStore.getState().currentSelectedProject,
					useObjectTableStore.getState().currentSelectedMap,
					currentMapObjectTableColumn
				);
		}

		UserMapAPI.userUpdateMapTableColumn(
			useObjectTableStore.getState().currentSelectedMap,
			currentMapObjectTableColumn
		);
	};

	const handleUpdateAvailableStatusName = (index, newValue) => {
		const currentMapObjectTableColumn = currentMapObject.objectTableColumn;
		const statusColumnIndex = currentMapObjectTableColumn.findIndex((c) => c.type === "status");

		const statusColumn = currentMapObjectTableColumn[statusColumnIndex];

		statusColumn.availableStatus[index].name = newValue;

		useObjectTableStore
			.getState()
			.updateMyBoardsMapTableColumn(
				useObjectTableStore.getState().currentSelectedProject,
				useObjectTableStore.getState().currentSelectedMap,
				currentMapObjectTableColumn
			);

		UserMapAPI.userUpdateMapTableColumn(
			useObjectTableStore.getState().currentSelectedMap,
			currentMapObjectTableColumn
		);
	};

	const handleUpdateContentItemStatus = (itemUuid, as) => {
		const item = items.filter((i) => i.uuid === itemUuid)[0];
		const itemObjectTableProperties = item.objectTableProperties;

		const statusPropertiesIndex = itemObjectTableProperties.findIndex((p) => p.type === "status");

		if (statusPropertiesIndex < 0) {
			const statusProperties = {
				type: "status",
				properties: as,
			};

			itemObjectTableProperties.push(statusProperties);

			useObjectTableStore.setState({
				items: items.map((i) =>
					i.uuid === itemUuid
						? {
								...i,
								objectTableProperties: itemObjectTableProperties,
						  }
						: i
				),
			});
		} else {
			itemObjectTableProperties[statusPropertiesIndex].properties = as;

			useObjectTableStore.setState({
				items: items.map((i) =>
					i.uuid === itemUuid
						? {
								...i,
								objectTableProperties: itemObjectTableProperties,
						  }
						: i
				),
			});
		}

		UserContentItemAPI.userUpdateContentTableProperties(itemUuid, itemObjectTableProperties);
	};

	return (
		<>
			<ContextMenuContainer
				map={false}
				state={itemContextMenu}
				setState={setItemContextMenu}
				ref={itemContextMenuRef}
			>
				<ContextMenuItem
					text="See on map"
					closeAfter={true}
					handler={() => handleGoToMapItem()}
					icon={<GoEye />}
				/>
			</ContextMenuContainer>
			{/* My table displaying the objects the user has imported on a map in certain zone */}
			<div className="custom__object-table-item-list">
				{/* I filter my items array of objects, recreating an array with only the items that have the check and i check if its > 0 and rendered the following elements */}
				{items.filter((i) => i.check).length > 0 && (
					// My action component to export, duplicate and delete the selected objects
					<div className="selected-action-menu">
						<div className="selected-count">{items.filter((i) => i.check).length}</div>
						<div className="actions">
							{/* Differents actions buttons the  */}
							<p className="text">Selected object</p>
							<div className="buttons">
								<div className="btn" onClick={handleExportCsv}>
									<div className="icon">
										<BiSolidFileExport />
									</div>
									<div className="text">Export</div>
								</div>
								<div className="btn">
									<div className="icon">
										<MdOutlineContentCopy />
									</div>
									<div className="text">Duplicate</div>
								</div>
								<div className="btn" onClick={handleDeleteSelectedItems}>
									<div className="icon">
										<RiDeleteBin6Line />
									</div>
									<div className="text">Delete</div>
								</div>
							</div>
						</div>
						<div className="close-btn" onClick={() => handleUnCheckAllItems()}>
							<VscChromeClose />
						</div>
					</div>
				)}
				{columnMenuState && (
					<div className="column-menu-container">
						{availableTableColumns.map((column, index) => (
							<div key={index} className="column">
								<input
									type="checkbox"
									disabled={!column.canDisable}
									checked={column.active}
									onChange={() =>
										useObjectTableStore
											.getState()
											.updateAvailableColumnStatus(column.name, !column.active)
									}
								/>
								<p className="text">{column.name}</p>
							</div>
						))}
					</div>
				)}
				{filtersMenuState && (
					<div className="filter-menu-container" ref={filterMenuRef}>
						<div className="filter-header">
							<p>Filter</p>
							<div className="close-btn" onClick={() => setFiltersMenuState(false)}>
								<VscChromeClose />
							</div>
						</div>
						<div className="filter-content">
							<div className="filter-row">
								<p className="text">Type</p>
								<div className="filter">
									<div
										className="list-selector"
										onClick={() => useObjectTableStore.getState().toggleFilterPanel("type")}
									>
										<div className="selector-text">
											{filters.filter((f) => f.name === "type")[0].value !== null
												? filters.filter((f) => f.name === "type")[0].value
												: "All"}
										</div>
										<div className="selector-icon">
											<HiChevronDown />
										</div>
									</div>
									{filters.filter((f) => f.name === "type")[0].open && (
										<div className="list-selector-content">
											{availableType.map((at) => (
												<div
													className="list-item"
													key={at}
													onClick={() =>
														useObjectTableStore.getState().updateTypeFilterValue(at)
													}
												>
													<div className="icon">
														<img
															src={
																"/static/map/svg/" +
																items.filter((i) => i.type === at)[0].icon +
																".svg"
															}
															alt=""
														/>
													</div>
													<div className="text">{at}</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
							<div className="filter-row">
								<p className="text">Tags</p>
								<div className="filter">
									<div className="tags-input-container">
										<input
											type="text"
											placeholder="Type tags"
											onKeyDown={(event) => handleAddTagFilter(event)}
										/>
										<div className="tags-list">
											{filters
												.filter((f) => f.name === "tags")[0]
												.value.map((t) => (
													<div
														className="tag"
														onClick={() =>
															useObjectTableStore.getState().removeTagFilter(t)
														}
													>
														{t}
													</div>
												))}
										</div>
									</div>
								</div>
							</div>
							<div className="filter-row">
								<p className="text">Color</p>
								<div className="filter">
									<div
										className="color-selector"
										onClick={() => useObjectTableStore.getState().toggleFilterPanel("color")}
									>
										{filters.filter((f) => f.name === "color")[0].value === null ? (
											<div className="preview-text">Choose color</div>
										) : (
											<div
												className="color-preview"
												style={{
													backgroundColor: filters.filter((f) => f.name === "color")[0].value,
												}}
											></div>
										)}
										{/**/}
										<div className="selector-icon">
											<HiChevronDown />
										</div>
									</div>
									{filters.filter((f) => f.name === "color")[0].open && (
										<div className="color-selector-content">
											<div className="color-list">
												{globalAvailableColor.map((c) => (
													<div
														className="color"
														onClick={() =>
															useObjectTableStore.getState().updateColorFilterValue(c)
														}
														style={{
															backgroundColor: c,
														}}
													></div>
												))}
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="filter-row">
								<p className="text">Created date</p>
								<div className="filter">
									<div className="date-range-selector">
										<input
											type="date"
											onChange={(event) =>
												useObjectTableStore
													.getState()
													.updateCreatedAtStartDateFilter(event.currentTarget.value)
											}
											value={
												useObjectTableStore
													.getState()
													.filters.filter((f) => f.name === "createdAt")[0].startDate !== null
													? useObjectTableStore
															.getState()
															.filters.filter((f) => f.name === "createdAt")[0].startDate
													: ""
											}
										/>
										<p>--</p>
										<input
											type="date"
											onChange={(event) =>
												useObjectTableStore
													.getState()
													.updateCreatedAtEndDateFilter(event.currentTarget.value)
											}
											value={
												useObjectTableStore
													.getState()
													.filters.filter((f) => f.name === "createdAt")[0].endDate !== null
													? useObjectTableStore
															.getState()
															.filters.filter((f) => f.name === "createdAt")[0].endDate
													: ""
											}
										/>
									</div>
								</div>
							</div>
							<div className="filter-row">
								<p className="text">Created by</p>
								<div className="filter">
									<div className="tags-input-container">
										<input
											type="text"
											placeholder="Name"
											onChange={(event) =>
												useObjectTableStore
													.getState()
													.updateAuthorFilter(event.currentTarget.value)
											}
											value={
												useObjectTableStore
													.getState()
													.filters.filter((f) => f.name === "author")[0].value !== null
													? useObjectTableStore
															.getState()
															.filters.filter((f) => f.name === "author")[0].value
													: ""
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="filter-footer">
							<div className="btn simple" onClick={() => useObjectTableStore.getState().resetFilters()}>
								Reset filter
							</div>
							<div className="btn">Search</div>
						</div>
					</div>
				)}
				<div className="list-header">
					<div>
						<div
							ref={filterMenuRef}
							className={"icon-btn " + (filtersMenuState ? "open" : "")}
							onClick={(event) => {
								event.stopPropagation();
								setFiltersMenuState(!filtersMenuState);
							}}
						>
							<FiFilter />
						</div>
						<div
							className="icon-btn"
							onClick={(event) => {
								event.stopPropagation();
								setColumnMenuState(!columnMenuState);
							}}
						>
							<CiViewColumn />
						</div>
					</div>
					<div>
						{/* ------------------------------------- ISEARCH -------------------------------------- */}
						<div className="search-input">
							<div className="icon">
								<IoIosSearch />
							</div>
							<input
								type="text"
								placeholder="Search"
								value={search}
								onChange={(event) => setSearch(event.currentTarget.value)}
							/>
						</div>
						<div
							className="text-btn sub-menu"
							onClick={(event) => {
								event.stopPropagation();
								setExportMenuState(!exportMenuState);
							}}
						>
							<p className="btn-text">Export</p>
							{exportMenuState && (
								<div
									className="export-menu-container"
									onClick={(event) => {
										event.stopPropagation();
									}}
								>
									<div className="header">
										<p className="title">Export object</p>
										<div className="icon">
											<VscChromeClose />
										</div>
									</div>
									<div className="menu-content">
										<p className="text">Select the export format</p>
										<div className="selector">
											<p
												className={exportFormat === "csv" ? "selected" : ""}
												onClick={() => setExportFormat("csv")}
											>
												CSV
											</p>
											<p
												className={exportFormat === "zip" ? "selected" : ""}
												onClick={() => setExportFormat("zip")}
											>
												Zip
											</p>
										</div>
									</div>
									<div className="menu-footer">
										<div className="btn" onClick={handleExportItems}>
											Export
										</div>
									</div>
								</div>
							)}
						</div>
						<div
							onClick={() =>
								useObjectTableStore.setState({
									currentView: "list",
								})
							}
							className={"simple-icon-btn " + (currentView === "list" ? "active" : "")}
						>
							<FaBars />
						</div>
						<div
							onClick={() =>
								useObjectTableStore.setState({
									currentView: "grid",
								})
							}
							className={"simple-icon-btn " + (currentView === "grid" ? "active" : "")}
						>
							<IoGridSharp />
						</div>
					</div>
				</div>
				{/* Get the current view  */}
				{currentView === "list" && (
					<div className="item-list">
						{/* //TODO Fix the style */}
						<div className="header-row">
							<div className="size-col">
								<div className="check-col">
									{/* Check every objects */}
									<input
										type="checkbox"
										checked={
											filteredItems.filter((i) => i.check).length === filteredItems.length &&
											filteredItems.length > 0
										}
										onChange={(event) =>
											!event.currentTarget.checked
												? handleUnCheckAllItems()
												: handleCheckAllItems()
										}
									/>
								</div>
								<div className="col">
									<p className="text">Name</p>
								</div>
							</div>
							{/* Checks if the column is active to display it */}
							<div className="simple-col">
								{availableTableColumns.filter((c) => c.name === "type")[0].active && (
									<div className="col">
										<p className="text">Type</p>
									</div>
								)}
								{availableTableColumns.filter((c) => c.name === "createdOn")[0].active && (
									<div className="col">
										<p className="text">Created on</p>
									</div>
								)}
								{availableTableColumns.filter((c) => c.name === "sourceUser")[0].active && (
									<div className="col">
										<p className="text">Source user</p>
									</div>
								)}
								{availableTableColumns.filter((c) => c.name === "tags")[0].active && (
									<div className="col">
										<p className="text">Tags</p>
									</div>
								)}
								{availableTableColumns.filter((c) => c.name === "colorTag")[0].active && (
									<div className="col">
										<p className="text">Color tag</p>
									</div>
								)}
								{availableTableColumns.filter((c) => c.name === "status")[0].active && (
									<div className="col">
										<p className="text">Status</p>
									</div>
								)}
								<div className="col"></div>
							</div>
						</div>
						{/* if no items render full-col with 'no items' */}
						{filteredItems.length <= 0 && (
							<div className="row">
								<div className="full-col">No items</div>
							</div>
						)}
						{/* if items render the following elements */}
						{filteredItems.map((i) => (
							<div
								className="row"
								onClick={(event) => {
									// The stopPropagation() method of the Event interface prevents further propagation of the current event
									event.stopPropagation();
									handleOpenItemSidebar(i);
								}}
								onContextMenu={(event) => handleOpenItemContextMenu(event, i)}
							>
								<div className="size-col">
									<div className="check-col">
										<input
											type="checkbox"
											checked={i.check}
											onClick={(event) => event.stopPropagation()}
											onChange={(event) => {
												event.stopPropagation();
												handleCheckItem(i.uuid);
											}}
										/>
									</div>
									<div className="col">
										<p className="text">{i.name}</p>
									</div>
								</div>
								<div className="simple-col">
									{availableTableColumns.filter((c) => c.name === "type")[0].active && (
										<div className="col">
											<p className="text">{i.type}</p>
										</div>
									)}
									{availableTableColumns.filter((c) => c.name === "createdOn")[0].active && (
										<div className="col">
											<p className="text">{i.createdAt}</p>
										</div>
									)}
									{availableTableColumns.filter((c) => c.name === "sourceUser")[0].active && (
										<div className="col">
											<p className="text">{i.author}</p>
										</div>
									)}
									{/*  */}
									{/* The Tag column */}
									{/*  */}
									{availableTableColumns.filter((c) => c.name === "tags")[0].active && (
										<div
											className="col"
											onClick={(event) => {
												event.stopPropagation();
												handleTagsPicker(event, i);
											}}
											style={{ cursor: "pointer" }}
										>
											{/* Loop through my tags items to display the first one or empty */}
											{i.tags && i.tags.length > 0 ? (
												<div className="tag">{i.tags[0].name}</div>
											) : (
												<div className="empty-tag">Empty</div>
											)}
										</div>
									)}
									{/*  */}
									{/* The ColorTag columns */}
									{/*  */}
									{availableTableColumns.filter((c) => c.name === "colorTag")[0].active && (
										<div
											className="col color"
											// Here I get the actual items.color and I set it as the background color of the div
											style={{ backgroundColor: i.color }}
											onClick={(event) => handleColorPicker(event, i)}
										></div>
									)}
									{availableTableColumns.filter((c) => c.name === "status")[0].active && (
										<div
											className="col status-col"
											style={{
												backgroundColor: i.objectTableProperties.filter(
													(p) => p.type === "status"
												)[0]?.properties.color,
											}}
											onClick={(event) => {
												event.stopPropagation();
												handleOpenStatusColumnMenu(i);
											}}
										>
											<p className="text">
												{
													i.objectTableProperties.filter((p) => p.type === "status")[0]
														?.properties.name
												}
											</p>
											{i.statusColumnState && (
												<div className="status-col-menu-container">
													<div className="available-status-list">
														{currentMapObject.objectTableColumn
															.filter((c) => c.type === "status")[0]
															?.availableStatus.map((as, asIndex) => (
																<div
																	key={asIndex}
																	className="status"
																	onClick={(event) => {
																		event.stopPropagation();
																		handleUpdateContentItemStatus(i.uuid, as);
																	}}
																>
																	<div
																		className="color"
																		style={{
																			backgroundColor: as.color,
																		}}
																	></div>
																	<input
																		onKeyDown={(event) => {
																			if (event.key === "Enter") {
																				handleUpdateAvailableStatusName(
																					asIndex,
																					event.currentTarget.value
																				);
																			}
																		}}
																		onClick={(event) => event.stopPropagation()}
																		type="text"
																		defaultValue={as.name}
																	/>
																</div>
															))}
													</div>
													<p className="add-btn" onClick={handleAddAvailableStatus}>
														Add status
													</p>
												</div>
											)}
										</div>
									)}
									<div className="col icon">
										{i.commentCount > 0 && (
											<div
												className="icon"
												onClick={(event) => {
													event.stopPropagation();
													handleOpenItemSidebar(i, true);
												}}
											>
												<BiComment size={18} />
												<span className="comment-count">{i.commentCount}</span>
											</div>
										)}
										{i.commentCount <= 0 && (
											<div
												className="icon"
												onClick={(event) => {
													event.stopPropagation();
													handleOpenItemSidebar(i, true);
												}}
											>
												<BiCommentAdd size={18} />
											</div>
										)}
									</div>
								</div>
							</div>
						))}
						{/* Display of the colorTag Block */}
						{showColorPicker &&
							(console.log(ColorPickerPosition.top),
							console.log(ColorPickerPosition.left),
							(
								<div
									id="test"
									className="colorPicker"
									style={{
										top: ColorPickerPosition.top,
										left: ColorPickerPosition.left,
									}}
								>
									{/* CLose button */}
									<div className="colorPickerButton">
										<VscChromeClose onClick={() => setShowColorPicker(false)} />
									</div>
									{/* Div to display the colors */}
									<div className="colorPickerDisplay">
										{/* Display the color */}
										{globalAvailableColor.map((color, index) => (
											// Div block of each color
											<div
												className="colorPickerBlock"
												key={index}
												style={{
													backgroundColor: color,
												}}
												onClick={() => handleColorSelect(color)}
											></div>
										))}
									</div>
								</div>
							))}
						{/* Display of the Tag Block */}
						{showTagBlock && (
							<div
								// Flex column for the header and the display of each tags underneath
								className="tagPicker"
								style={{
									top: tagBlockPosition.top,
									left: tagBlockPosition.left,
								}}
							>
								{/* Display of the header */}
								<div className="tagPickerHeader">
									<h4 className="tagPickerHeaderTitle">Tags</h4>
									{/* Close button */}
									<div className="tagPickerHeaderButton" onClick={handleCloseTagBlock}>
										<VscChromeClose />
									</div>
								</div>
								{/* My second div block that actually display the tags using wrap */}
								<div className="tagPickerDisplay">
									{/* Display tags inside the div block */}
									{/* Checking if there are tags and if it superior to 0, ternary  */}
									{currentEditingItem?.tags && currentEditingItem.tags.length > 0 ? (
										// mapping the usestate array of tags
										currentEditingItem.tags.map((tag) => (
											<div className="tagPickerTag" key={tag.uuid}>
												{tag.name}
												{/* My close button with the icon */}
												<VscChromeClose
													className="tagPickerTagButton"
													onClick={(event) => {
														event.stopPropagation();
														// Get the tag UUID and pass it to the deleteItemTag API
														ContentItemTagAPI.deleteItemTag(tag.uuid).then((success) => {
															if (success) {
																// If success
																// I create an array of the remaining tags, so expect the one I deleted
																const updatedTags = currentEditingItem.tags.filter(
																	(test) => test.uuid !== tag.uuid
																);
																// I create a new object, with all the passed currentEditingItem properties, and I update the tags with my updatedTags array ;-)
																const updatedItem = {
																	...currentEditingItem,
																	tags: updatedTags,
																};
																//Setter classical with the new object
																setCurrentEditingItem(updatedItem);

																// I update the items array with the new object
																useObjectTableStore.setState({
																	items: items.map((item) =>
																		item.uuid === currentEditingItem.uuid
																			? updatedItem
																			: item
																	),
																});
															}
														});
													}}
												/>
											</div>
										))
									) : (
										// If there are no tags, display this message
										<div className="tagPickerNoTag">No tags</div>
									)}
								</div>
								{/* My third input div block to add Tags */}
								<div className="tagPickerInputDiv">
									{/* // TODO input style */}
									{/* API ContentItemTagAPI.js */}
									<input
										className="tagPickerInput"
										placeholder="Add tags"
										value={tagInput}
										onChange={handleTagInputChange}
										onKeyDown={handleTagInputKeyPress}
									></input>
								</div>
							</div>
						)}
					</div>
				)}
				{currentView === "grid" && (
					<div className="item-grid">
						{availableType.map((at) => (
							<section className="type-container">
								<div className="type-header" onClick={() => handleToggleType(at)}>
									<div className="icon">
										{closedType.includes(at) ? (
											<HiChevronUp size={20} />
										) : (
											<HiChevronDown size={20} />
										)}
									</div>
									<p className="text">
										{at} ({filteredItems.filter((t) => t.type === at).length})
									</p>
									<hr />
								</div>
								{!closedType.includes(at) && (
									<div className="type-card-list">
										{filteredItems
											.filter((t) => t.type === at)
											.map((i) => (
												<div className="simple-card">
													<div className="card-header">
														<div className="type-icon">
															<img src={"/static/map/svg/" + i.icon + ".svg"} alt="" />
														</div>
														<p className="item-name">{i.name}</p>
													</div>
													{i.thumbnailUrl && (
														<div className="item-thumbnail">
															<img src={i.thumbnailUrl} alt="Item" />
														</div>
													)}
												</div>
											))}
									</div>
								)}
							</section>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default ObjectTableItemList;
