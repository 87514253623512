import React, {useEffect, useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {BiBarChart, BiCommentDetail} from "react-icons/bi";
import {BsArrowBarRight} from "react-icons/bs";
import ObjectSidebarInformations from "./ObjectSidebar/Contents/ObjectSidebarInformations";
import ObjectSidebarComments from "./ObjectSidebar/Contents/ObjectSidebarComments";
import ContentItemAPI from "../../services/ContentItemAPI";
import {LuPanelLeft} from "react-icons/lu";
import FullScreenPDFViewer from "../FullScreenPDFViewer";
import Skeleton from "react-loading-skeleton";
import FullscreenScriptEditor from "./FullscreenObjects/FullscreenScriptEditor";
import FullscreenTextEditor from "./FullscreenObjects/FullscreenTextEditor";
import FullscreenPicture from "./FullscreenObjects/FullscreenPicture";
import FullscreenLatex from "./FullscreenObjects/FullscreenLatex";
import PublicsContentItemAPI from "../../services/API/Publics/PublicsContentItemAPI";
import {useContentItemStore} from "../../services/Stores/ContentItemStore";
import {PiMinusBold, PiPlusBold} from "react-icons/pi";
import CustomTooltip from "../customs/CustomTooltip";

const ObjectFullscreen = ({mapName, publicMap = false}) => {
    //Get item id in store
    const itemId = useContentItemStore(state => state.currentFullscreenItemUuid)

    //Define default state for opened panel
    const [openedPanel, setOpenedPanel] = useState("")

    //Define default state for save status
    const [saveStatus, setSaveStatus] = useState(true)
    const [saveInProgress, setSaveInProgress] = useState(false)
    const [needSave, setNeedSave] = useState(false)

    //Define default state for get item status
    const [getItemStatus, setGetItemStatus] = useState(false)

    //Define default state for item data
    const [item, setItem] = useState({
        'uuid': "",
        'ownerEmail': "",
        'type': "",
        'category': "",
        'attributes': [],
        'name': "",
        'url': "",
        'pictureUrl': "",
        'audioUrl': "",
        'videoUrl': "",
        'note': "",
        'color': "",
        'associatedItems': [],
        'lastUpdate': "",
        'lastUpdateUser': "",
        'fileUrl': "",
        'thumbnailUrl': "",
        'commentCount': "",
        'bibtexCode': "",
        'thumbnailStatus': false,
        'generatedThumbnailStatus': false,
        'mimeType': [],
        'icon': ""
    })

    //Get object data
    useEffect(() => {
        if (itemId !== ""){
            let promise

            if (publicMap){
                promise = PublicsContentItemAPI.getPublicsContentItemByUuid(itemId)
            } else {
                promise = ContentItemAPI.getContentItemByUuid(itemId)
            }

            promise.then((response) => {
                if (response.status === 200){
                    setItem(response.data)
                    setGetItemStatus(true)
                }
            })
        }
    }, [itemId])

    const handleCloseFullscreen = () => {
        if ((item.type === "CODE" || item.type === "TEXTS") && !publicMap){
            setNeedSave(true)
        } else {
            useContentItemStore.setState({currentFullscreenItemUuid: null})
        }
    }

    const [pdfScale, setPdfScale] = useState(1.0)

    return (
        <div className="object-fullscreen-overlay">
            <div className="object-fullscreen-container">
                <div className="object-fullscreen-topbar">
                    <div className="object-infos">
                        <div className="icon-container">
                            {getItemStatus
                                ? <img src={"/static/fullscreen/" + item.icon + ".svg"} alt="Item icon"/>
                                : <Skeleton height={38} width={30} />
                            }
                        </div>
                        <div className="infos">
                            {getItemStatus
                                ? <p className="object-name">{item.name}</p>
                                : <Skeleton height={14} width={150} />

                            }
                            <div className="ariane">
                                <p className="icon"><LuPanelLeft /></p>
                                <p className="text">{mapName}</p>
                                <p className="text">></p>
                                {getItemStatus
                                    ? <p className="text">{item.name}</p>
                                    : <Skeleton height={14} width={100} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="fullscreen-topbar-right">
                        {!publicMap && (
                            <>
                                {item.type === "CODE" && (
                                    <div className={saveStatus ? "save-status green" : "save-status red"}>
                                        {saveInProgress
                                            ? <div className="text">Save in progress</div>
                                            : <div className="text">{saveStatus ? "Save" : "Not Save"}</div>
                                        }
                                    </div>
                                )}
                                {item.type === "TEXTS" && (
                                    <div className={saveStatus ? "save-status green" : "save-status red"}>
                                        {saveInProgress
                                            ? <div className="text">Save in progress</div>
                                            : <div className="text">{saveStatus ? "Save" : "Not Save"}</div>
                                        }
                                    </div>
                                )}
                            </>
                        )}
                        <div className="close-button">
                            <p onClick={handleCloseFullscreen}><VscChromeClose/></p>
                        </div>
                    </div>
                    {item.type === "PDF" && (
                        <div className="zoom-btn-container">
                            <div className="btn custom__tooltip-container" onClick={() => parseFloat(pdfScale) >= 0.3 ? setPdfScale(parseFloat(pdfScale) - 0.2) : null}><CustomTooltip position="bottom" text="Zoom out" /><PiMinusBold fontSize="1.4em"/></div>
                            <div className="btn custom__tooltip-container" onClick={() => setPdfScale(parseFloat(pdfScale) + 0.2)}><CustomTooltip position="bottom" text="Zoom in" /><PiPlusBold fontSize="1.4em"/></div>
                        </div>
                    )}

                </div>
                <div className={openedPanel !== "" ? "object-fullscreen-content sidebar-open" : "object-fullscreen-content"}>
                    <div className="object-content-container">
                        {getItemStatus && (
                            <>
                                {item.type === "PDF" && (
                                    <FullScreenPDFViewer scale={pdfScale} pdfUrl={item.fileUrl} />
                                )}
                                {item.type === "CODE" && (
                                    <FullscreenScriptEditor publicMap={publicMap} needSave={needSave} setNeedSave={setNeedSave} setSaveInProgress={setSaveInProgress} itemUuid={item.uuid} setSaveStatus={setSaveStatus} fileUrl={item.fileUrl}/>
                                )}
                                {item.type === "TEXTS" && (
                                    <FullscreenTextEditor publicMap={publicMap} fileUrl={item.fileUrl} needSave={needSave} setNeedSave={setNeedSave} setSaveInProgress={setSaveInProgress} itemUuid={item.uuid} setSaveStatus={setSaveStatus} />
                                )}
                                {item.type === "PICTURES" && (
                                    <FullscreenPicture fileUrl={item.fileUrl} />
                                )}
                                {item.type === "LATEX" && (
                                    <FullscreenLatex latex={item.attributes.filter(attr => attr.type === "latex")[0].value} />
                                )}
                            </>
                        )}
                    </div>
                    {openedPanel !== "" && (
                        <div className="object-fullscreen-sidebar-container">
                            <div className="section-title">
                                <p className="title">
                                    {openedPanel === "info" ? "Informations" : openedPanel === "comment" ? "Comments" : openedPanel === "activity" ? "Activity" : ""}
                                </p>
                                <p className="close-sidebar-button" onClick={() => setOpenedPanel("")}><BsArrowBarRight /></p>
                            </div>
                            {openedPanel === "info" && (
                                <>
                                    <ObjectSidebarInformations fullscreen={true} />
                                </>
                            )}
                            {!publicMap && (
                                <>
                                    {openedPanel === "comment" && (
                                        <>
                                            <ObjectSidebarComments fullscreen={true}/>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    <div className="right-menu">
                        <div className="button-container">
                            <div onClick={() => openedPanel !== "info" ? setOpenedPanel('info') : setOpenedPanel("")} className={openedPanel === "info" ? "button active" : "button"}><AiOutlineInfoCircle /></div>
                            {!publicMap && (
                                <div onClick={() => openedPanel !== "comment" ? setOpenedPanel('comment') : setOpenedPanel("")} className={openedPanel === "comment" ? "button active" : "button"}><BiCommentDetail /></div>
                            )}
                            {/*<div onClick={() => openedPanel !== "activity" ? setOpenedPanel('activity') : setOpenedPanel("")} className={openedPanel === "activity" ? "button active" : "button"}><BiBarChart /></div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObjectFullscreen