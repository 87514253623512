import React, {useContext, useEffect, useRef, useState} from "react";
import {BiLogoApple, BiLogoGoogle} from "react-icons/bi";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import AuthAPI from "../../services/AuthAPI";
import {toast} from "react-toastify";
import {getBrowser} from "../../services/Utils";
import {useHistory} from "react-router-dom";
import IsAdminContext from "../../contexts/IsAdminContext";
import AuthContext from "../../contexts/AuthContext";
import LoginSessionPopup from "../../components/LoginSessionPopup";
import {TailSpin} from "react-loader-spinner";
import weeki_logo from "../../../images/weeki_logo.svg"
import {createErrorToast} from "../../services/CustomToastUtils";

const Login2 = () => {
    //Get auth context method
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
    const {isAdmin, setIsAdmin} = useContext(IsAdminContext)

    useEffect(() => {
        totpInputs = []
    }, [])

    //Get history
    const history = useHistory()

    //Define default state for load sign button status
    const [signButtonLoading, setSignButtonLoading] = useState(false)

    //Define default state for password visibility
    const [passwordVisible, setPasswordVisible] = useState(false)

    //Define default state for close session popup
    const [closeSessionPopupStatus, setCloseSessionPopupStatus] = useState(false)
    const [currentUserSession, setCurrentUserSession] = useState([])

    //Define default state for credentials
    const [credentials, setCredentials] = useState({
        "email": "",
        "password": ""
    })

    //Define totp ref
    let totpInputsRef = useRef([])
    let totpInputs = []
    for (let i = 0; i < 6; i++){
        if (totpInputs.length < 6){
            totpInputs.push("")
        }
    }
    const availableTotpChar = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    const [totpCode, setTotpCode] = useState("")

    //Define default state for need totp code
    const [needTotpCode, setNeedTotpCode] = useState(false)

    //Check if user need totp code
    const handleCheckTotp = async () => {
        //Check if credentials is not empty
        if (credentials.email !== "" && credentials.password !== ""){
            setSignButtonLoading(true)

            await AuthAPI.getUserTotpStatus(credentials)
                .then((response) => {
                    if (response.data['userTotpStatus']){
                        setSignButtonLoading(false)
                        totpInputsRef.current = []
                        setNeedTotpCode(true)
                    } else {
                        checkUserLoginSession().then()
                    }
                })
                .catch((error) => {
                    setSignButtonLoading(false)
                    if (!error.response){
                        toast.error("Failed to login")
                    }
                })
        }
    }

    const checkUserLoginSession = async (code = null) => {
        await AuthAPI.checkUserLoginSession(credentials).then((response) => {
            if (response.status === 200){
                const data = response.data

                if (data['needSessionClose']){
                    setCurrentUserSession(data['currentSessions'])
                    setCloseSessionPopupStatus(true)
                } else {
                    login(code).then()
                }
            }
        })
    }

    const login = async (code = null) => {
        setSignButtonLoading(true)
        if (credentials.email !== "" && credentials.password !== ""){
            if (await AuthAPI.login(credentials, getBrowser(window) + "/" + navigator.platform, code, false)){
                setIsAuthenticated(true)
                if (AuthAPI.isAdmin()){
                    setIsAdmin(true)
                }
                history.push("/user/dashboard/home")
            } else {
                setSignButtonLoading(false)
            }
        }
    }

    const handlePressTotpKey = (event, index) => {
        if (event.key === "Backspace"){
            setTotpCode("")
            if (totpInputsRef.current[index].value === ""){
                event.preventDefault()
                if (index !== 0){
                    totpInputsRef.current[index - 1].focus()
                }
            }
            return
        }

        if (availableTotpChar.some(char => event.key === char)){
            event.preventDefault()
            totpInputsRef.current[index].value = event.key
            if (index < (totpInputsRef.current.length - 1)){
                setTotpCode("")
                totpInputsRef.current[index + 1].focus()
            }

            if (index === (totpInputsRef.current.length - 1)){
                let code = ""
                for (let i = 0; i < totpInputsRef.current.length; i++){
                    code = code + totpInputsRef.current[i].value
                }

                setTotpCode(code)
                checkUserLoginSession(code).then()
            }
        }
    }

    const handleCancelLogin = () => {
        for (let i = 0; i < totpInputsRef.current.length; i++){
            totpInputsRef.current[i].value = 0
        }
        setCredentials({...credentials, email: ""})
        setCredentials({...credentials, password: ""})
        setNeedTotpCode(false)
        setTotpCode("")
        setCurrentUserSession([])
        setCloseSessionPopupStatus(false)
        setSignButtonLoading(false)
    }

    const loginFormHandlePressKey = (event) => {
        if (event.key === "Enter"){
            if (credentials.email !== "" && credentials.password !== ""){
                handleCheckTotp().then()
            }
        }
    }

    if (isAuthenticated){
        history.push("/user/dashboard/home")
    }

    const handleOauthLogin = (provider) => {
        if (provider === "google"){
            AuthAPI.getOauthGoogleUrl().then((response) => {
                if (response.status === 200){
                    const authUrl = response.data.authUrl

                    //Redirect user to google
                    window.location.assign(authUrl)
                }
            })
        } else if (provider === "apple"){
            AuthAPI.getOauthAppleUrl().then((response) => {
                if (response.status === 200){
                    const authUrl = response.data.authUrl

                    //Redirect user to google
                    window.location.assign(authUrl)
                }
            })
        }
    }

    return (
        <>
            {closeSessionPopupStatus && (
                <LoginSessionPopup cancelLogin={handleCancelLogin} credentials={credentials} login={login} sessions={currentUserSession} state={closeSessionPopupStatus} setState={setCloseSessionPopupStatus} />
            )}
            <div className="auth-container">
                <div className="auth-content">
                    <img className="logo" src={weeki_logo} alt="Weeki logo"/>
                    <h1 className="title">
                        {needTotpCode
                            ? "Insérez votre code d'authentification"
                            :  process.env.REACT_APP_REGISTER_STATUS === "true" ? "Log in to Weeki" : ""
                        }
                    </h1>
                    {!needTotpCode && (
                        <>
                            <div className="sso-container">
                                <div className="sso" onClick={() => handleOauthLogin("google")}>
                                    <p className="icon"><BiLogoGoogle /></p>
                                    <p className="text">Google</p>
                                </div>
                                {/*<div className="sso" onClick={() => handleOauthLogin("apple")}>*/}
                                {/*    <p className="icon"><BiLogoApple /></p>*/}
                                {/*    <p className="text">Apple ID</p>*/}
                                {/*</div>*/}
                            </div>
                            <hr className="separator" />
                            <h1 className="title">Login with an email address</h1>
                            <div className="auth-form-container">
                                <div className="auth-input-container">
                                    <input onKeyDown={loginFormHandlePressKey} value={credentials.email} onChange={(event) => setCredentials({...credentials, email: event.currentTarget.value})} className="auth-input" type="text" placeholder="Email"/>
                                </div>
                                <div className="auth-input-container">
                                    <input onKeyDown={loginFormHandlePressKey} value={credentials.password} onChange={(event) => setCredentials({...credentials, password: event.currentTarget.value})} className="auth-input" type={passwordVisible ? "text" : "password"} placeholder="Password"/>
                                    <p className="input-icon" onClick={() => setPasswordVisible(!passwordVisible)}>
                                        {passwordVisible
                                            ? <FaEyeSlash />
                                            : <FaEye />
                                        }
                                    </p>
                                </div>
                            </div>
                            <p className="sub-form-link" onClick={() => history.push("/forgot-password")}>Forgot your password?</p>
                        </>
                    )}

                    {/*TOTP form*/}
                    {needTotpCode && (
                        <div className="auth-totp-form-container">
                            {totpInputs.map((input, index) => (
                                <input maxLength={1} onKeyDown={(event) => handlePressTotpKey(event, index)} key={index} ref={(element) => totpInputsRef.current.length < 6 ? totpInputsRef.current.push(element) : null}/>
                            ))}
                        </div>
                    )}
                    <p className="sign-btn" onClick={needTotpCode ? handleCancelLogin : handleCheckTotp}>
                        {signButtonLoading
                            ? <TailSpin height="19" width="19" color="#FFFFFF" radius={1} />
                            : needTotpCode ? "Annuler" : "Sign in"
                        }
                    </p>
                    <p className="sub-sign-btn-link">New to Weeki ? <span onClick={() => history.push("/register")}>Create an account</span></p>
                </div>
            </div>
        </>
    )
}

export default Login2