import {create} from "zustand";

export const useMapShapeItemStore = create((set) => ({
	currentSelectedShape: null,
	currentSelectedMock: null,
	currentDragState: false,
	copiedShapeUuid: null,
	copyType: "copy",
	resetStore: () => set((state) => ({
		currentSelectedShape: null,
		currentSelectedMock: null,
		currentDragState: false,
		copiedShapeUuid: null,
		copyType: "copy"
	}))
}))