import React, {useRef} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {createErrorToast} from "../../../services/CustomToastUtils";

const CreateProjectPopup = ({state, setState, handler}) => {
	const inputRef = useRef(null)

	const handleCreateProject = () => {
		const value = inputRef.current.value

		if (value.length > 0 && value.length <= 255){
			handler(value)
		} else {
			createErrorToast("Project name invalid")
		}
	}

	if (state) {
		return createPortal(
			<div className="custom__dashboard-popup-overlay">
				<div className="container">
					<div className="popup-header">
						<h1 className="popup-title">Name your project</h1>
						<p className="popup-close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
					</div>
					<div className="popup-content">
						<div className="input-container">
							<input ref={inputRef} type="text" placeholder="Enter project name" autoFocus={true} onKeyDown={(e) => e.key === "Enter" ? handleCreateProject() : null}/>
						</div>
					</div>
					<div className="popup-footer">
						<p className="popup-btn" onClick={() => setState(false)}>Cancel</p>
						<p className="popup-btn valid" onClick={handleCreateProject}>Create project</p>
					</div>
				</div>
			</div>
			, document.body)
	}

	return null
}

export default CreateProjectPopup