import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import UserUserAuthorizationAPI from "../../../services/API/User/UserUserAuthorizationAPI";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";

const ManageWorkspaceAuthorizationPopup = ({state, setState, handler, currentInvitation, availableAuthorizations}) => {
	const [currentAuthorization, setCurrentAuthorization] = useState([])

	useEffect(() => {
		if (state){
			const tempClone = structuredClone(availableAuthorizations)
			setCurrentAuthorization(tempClone.map((aa) => currentInvitation.userAuthorizations.includes(aa.key) ? {...aa, checked: true} : {...aa, checked: false}))
		} else {
			setCurrentAuthorization([])
		}
	}, [state]);


	const handleValidAuthorization = () => {
		currentAuthorization.forEach(a => {
			if (currentInvitation.userAuthorizations.includes(a.key) && !a.checked){
				UserUserAuthorizationAPI.removeUserAuthorization(useDashboardStore.getState().currentOpenedWorkspace.uuid, a.uuid, currentInvitation.uuid)
			}

			if (!currentInvitation.userAuthorizations.includes(a.key) && a.checked){
				UserUserAuthorizationAPI.addUserAuthorization(useDashboardStore.getState().currentOpenedWorkspace.uuid, a.uuid, currentInvitation.uuid)
			}
		})

		setCurrentAuthorization([])
		setState(false)
	}

	const updateAuthorizationCheckedState = (uuid, newState) => {
		setCurrentAuthorization(currentAuthorization.map((ca => ca.uuid === uuid ? {...ca, checked: newState} : ca)))
	}

	if (state) {
		return createPortal(
			<div className="custom__dashboard-popup-overlay">
				<div className="container">
					<div className="popup-header">
						<h1 className="popup-title">Manage "{currentInvitation.userEmail}" permission</h1>
						<p className="popup-close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
					</div>
					<div className="popup-content">
						<div className="permission-list-container">
							{currentAuthorization.map((a) => (
								<>
									<label><input onChange={(event) => updateAuthorizationCheckedState(a.uuid, event.currentTarget.checked)} checked={a.checked} type="checkbox"/>{a.description}</label>
								</>
							))}
						</div>
					</div>
					<div className="popup-footer">
						<p className="popup-btn" onClick={() => setState(false)}>Cancel</p>
						<p className="popup-btn valid" onClick={handleValidAuthorization}>Save permission</p>
					</div>
				</div>
			</div>
			, document.body)
	}

	return null
}

export default ManageWorkspaceAuthorizationPopup