import {useMapStore} from "../Stores";
import {generateTextItemPopup, updateRenderedTextItemPopup} from "./MapPopupUtils";
import {addTextItemPopupEvents} from "./PopupEventsUtils";
import {useTextItemStore} from "../Stores/TextItemStore";
import ReactDOMServer from "react-dom/server";
import TextItemPopup from "../../components/Map/ObjectPopup/TextItemPopup";
import React from "react";
import {selectMapTextItem} from "./ItemUtils";
import {useContentItemStore} from "../Stores/ContentItemStore";

export function renderTextItems() {
	checkManuallyRenderedTextItems()

	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined){
			if (layer.scene.config !== null) {
				if (layer.scene.config.global.itemLevel !== undefined) {
					if (layer.scene.config.global.itemLevel === "text") {
						layer.scene.queryFeatures({geometry: true, visible: true}).then(features => {

							//If no feature return remove all feature in map and in array
							if (features.length === 0) {
								useTextItemStore.getState().renderedTextItems.filter(i => i.canRemove === true && i.canRender === true).forEach(l => {
									l.popup.remove()
									useTextItemStore.getState().removeRenderedTextItem(l.uuid)
								})

								return
							}

							features = features.filter((obj, index) => {
								return index === features.findIndex(o => obj.properties.uuid === o.properties.uuid);
							});


							//If feature has been return render all feature
							if (features.length > 0) {
								features.forEach(f => {
									//Check if item can be rendered
									if (useTextItemStore.getState().renderedTextItems.filter(r => r.uuid === f.properties.id && r.canRender === false).length > 0) {
										return;
									}

									//Get current zoom level
									const currentZoom = useMapStore.getState().mapInstance.getZoom()

									let minZlevel = null

									if (useTextItemStore.getState().renderedTextItems.filter(r => r.uuid === f.properties.uuid).length > 0){
										minZlevel = useTextItemStore.getState().renderedTextItems.filter(r => r.uuid === f.properties.uuid)[0].properties.min_zlevel
									} else {
										minZlevel = f.properties.min_zlevel
									}

									//Get difference between minZlevel and current zoom
									const zoomDiff = currentZoom - minZlevel

									//Get rendered size with zoom diff
									let renderedSize = null
									if (zoomDiff > 0){
										if (zoomDiff <= 1){
											renderedSize = 'small'
										} else if (zoomDiff > 1 && zoomDiff <= 2){
											renderedSize = 'normal'
										} else if (zoomDiff > 2 && zoomDiff <= 3){
											renderedSize = 'large'
										} else if (zoomDiff > 3){
											renderedSize = 'huge'
										}
									} else {
										return;
									}

									//Check if item is already render
									if (useTextItemStore.getState().renderedTextItems.filter(r => r.uuid === f.properties.uuid).length > 0) {
										//Check if rendered text item need size update
										//Get text item in store
										const textItem = useTextItemStore.getState().renderedTextItems.filter(i => i.uuid ===  f.properties.uuid)[0]

										if (textItem.currentRenderedSize !== renderedSize){
											//Update size in store
											useTextItemStore.getState().updateRenderedTextItemSize(textItem.uuid, renderedSize, textItem.properties.min_zlevel)

											//Update text item popup
											updateRenderedTextItemPopup(textItem.uuid)
										}
										return
									}

									//Generate popup and add to map
									const popup = generateTextItemPopup([f.geometry.coordinates[1], f.geometry.coordinates[0]], f.properties.text, renderedSize, false)
									popup.addTo(useMapStore.getState().mapInstance)

									//Add event for popup and add to render list
									addTextItemPopupEvents(popup, f)
									useTextItemStore.getState().addRenderedTextItem({uuid: f.properties.uuid, popup: popup, canRemove: true, canRender: true, properties: f.properties, update: false, currentRenderedSize: renderedSize})
								})
							}

							//Delete text item popup if is not visible
							useTextItemStore.getState().renderedTextItems.filter(i => i.canRemove === true && i.canRender === true).forEach(l => {
								let needView = false
								features.forEach(feat => {
									if (l.uuid === feat.properties.uuid) {
										needView = true
									}
								})

								if (!needView) {
									l.popup.remove()
									useTextItemStore.getState().removeRenderedTextItem(l.uuid)
								}
							})
						})
					}
				}
			}
		}
	})
}

export function checkManuallyRenderedTextItems() {
	const renderedTextItems = useTextItemStore.getState().renderedTextItems.filter(i => i.canRemove === false && i.canRender === true)
	const mapBounds = useMapStore.getState().mapInstance.getBounds()
	const currentZoomLevel = useMapStore.getState().mapInstance.getZoom()

	renderedTextItems.forEach(item => {
		const minZlevel = item.properties.min_zlevel
		const isVisible = mapBounds.contains(item.popup.getLatLng())

		if (!isVisible || currentZoomLevel <= minZlevel){
			item.popup.close()
		} else {
			if (!item.popup.isOpen()){
				item.popup.openOn(useMapStore.getState().mapInstance)
			}
		}
	})
}

export function updateTextItemPopupContent(uuid){
	//Get text item in store
	const item = useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === uuid)[0]

	//Check if item exist
	if (!item){
		return
	}

	//Update item content
	item.popup.setContent(ReactDOMServer.renderToString(<TextItemPopup text={item.properties.text} size={item.properties.size} update={false}/>))

	//Add event for popup
	addTextItemPopupEvents(item.popup, {'properties': item.properties})

	//Check if we need re-render select popup
	if (useTextItemStore.getState().currentSelectedItem === uuid){
		selectMapTextItem(uuid)
	}
}

export function generateNewTextItem(uuid, value, minZoom, lat, long, overlay, size){
	//Generate new text item data
	const newTextItemData = {
		'uuid': uuid,
		'canRemove': false,
		'canRender': true,
		'properties': {
			'max_zlevel': 20,
			'min_zlevel': minZoom,
			'overlay_id': overlay,
			'size': size,
			'text': value,
			'uuid': uuid
		}
	}

	//Generate text item popup and add to map
	newTextItemData.popup = generateTextItemPopup([lat, long], value, newTextItemData.properties.size, false)
	newTextItemData.popup.addTo(useMapStore.getState().mapInstance)

	//Add event for new popup
	addTextItemPopupEvents(newTextItemData.popup, newTextItemData)

	//Add new text item to store
	useTextItemStore.getState().addRenderedTextItem(newTextItemData)
}