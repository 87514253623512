import {create} from "zustand";

export const useDashboardStore = create((set) => ({
	currentDashboard: null,
	currentWorkspace: null,
	currentProjects: null,
	workspaces: null,
	currentOpenedWorkspace: null,
	currentSelectedProjects: [],
	currentSelectedMaps: [],
	currentDashboardLayout: 'grid',
	currentOpenedProject: null,
	currentContextMenuProject: null,
	currentContextMenuWorkspace: null,
	currentProjectMaps: null,
	currentContextMenuMap: null,
	currentWorkspaceView: 'projects',
	selectProject: (uuid) => set((state) => ({
		currentSelectedProjects: [...state.currentSelectedProjects, uuid]
	})),
	unselectProject: (uuid) => set((state) => ({
		currentSelectedProjects: state.currentSelectedProjects.filter(p => p !== uuid)
	})),
	selectMap: (uuid) => set((state) => ({
		currentSelectedMaps: [...state.currentSelectedMaps, uuid]
	})),
	unselectMap: (uuid) => set((state) => ({
		currentSelectedMaps: state.currentSelectedMaps.filter(p => p !== uuid)
	})),
	addNewProject: (projectData) => set((state) => ({
		currentProjects: state.currentProjects !== null ? [...state.currentProjects, projectData] : [projectData]
	})),
	removeProject: (uuid) => set((state) => ({
		currentProjects: state.currentProjects.filter(p => p.uuid !== uuid)
	})),
	renameProject: (uuid, name) => set((state) => ({
		currentProjects: state.currentProjects.map((p) => p.uuid === uuid ? {...p, name: name} : p)
	})),
	renameMap: (uuid, name) => set((state) => ({
		currentProjectMaps: state.currentProjectMaps.map((p) => p.uuid === uuid ? {...p, name: name} : p)
	})),
	removeMap: (uuid) => set((state) => ({
		currentProjectMaps: state.currentProjectMaps.filter(m => m.uuid !== uuid)
	})),
	addNewWorkspace: (data) => set((state) => ({
		workspaces: [...state.workspaces, data]
	})),
	removeWorkspace: (uuid) => set((state) => ({
		workspaces: state.workspaces.filter(w => w.uuid !== uuid)
	})),
	updateWorkspaceLogo: (uuid, logo) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === uuid ? {...w, logo: logo} : w),
		currentOpenedWorkspace: {...state.currentOpenedWorkspace, logo: logo}
	})),
	updateWorkspaceName: (uuid, name) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === uuid ? {...w, name: name} : w),
		currentOpenedWorkspace: {...state.currentOpenedWorkspace, name: name}
	})),
	resetStore: () => set((state) => ({
		currentDashboard: null,
		currentWorkspace: null,
		currentProjects: null,
		workspaces: null,
		currentOpenedWorkspace: null,
		currentSelectedProjects: [],
		currentSelectedMaps: [],
		currentDashboardLayout: 'grid',
		currentOpenedProject: null,
		currentContextMenuProject: null,
		currentProjectMaps: null,
		currentContextMenuMap: null,
		currentContextMenuWorkspace: null,
		currentWorkspaceView: 'projects'
	}))
}))