import React, {useEffect, useState} from "react";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {BiCheck} from "react-icons/bi";
import {BsFillLayersFill} from "react-icons/bs";
import {HiOutlineZoomOut} from "react-icons/hi";
import {MdDelete, MdDriveFileRenameOutline, MdOutlineCancel} from "react-icons/md";
import {VscChromeClose} from "react-icons/vsc";
import OverlayAPI from "../../services/OverlayAPI";
import {toast} from "react-toastify";

const OverlaySideMenu = ({menuStatus, setMenuStatus, mapUuid}) => {
    //Define default state for get overlay request status
    const [getOverlaysRequestStatus, setGetOverlaysRequestStatus] = useState(false)

    //Set default value for overlay list
    const [overlays, setOverlays] = useState([{
        "id": "",
        "name": "",
        "depth": 0,
        "minZlevel": 0,
        "maxZlevel": 0,
        "active": false,
        "view": true
    }])

    useEffect(() => {
        OverlayAPI.getMapOverlays(mapUuid).then((response) => {
            setOverlays(response.data)
            setGetOverlaysRequestStatus(true)
        })
    }, [])

    //Function for change overlay view status
    const changeOverlayView = (event) => {
        event.stopPropagation()

        //Get overlay index in overlays array
        const overlayIndex = event.currentTarget.id

        //Create copy of overlays array
        let newArray = [...overlays]

        //Change clicked overlay view in array copy
        newArray[overlayIndex].view = !newArray[overlayIndex].view

        //Replace old overlays array with updated copy
        setOverlays(newArray)
    }

    //Define default state for selected overlays
    const [selectedOverlayId, setSelectedOverlayId] = useState("")

    const deleteOverlay = async () => {
        if (selectedOverlayId === ""){
            toast.error("Please select overlay")
            return
        }

        if (await OverlayAPI.deleteOverlay(selectedOverlayId)){
            toast.success("Overlay successfully deleted")
            setSelectedOverlayId("")
            setGetOverlaysRequestStatus(false)
            OverlayAPI.getMapOverlays(mapUuid).then((response) => {
                setOverlays(response.data)
                setGetOverlaysRequestStatus(true)
            })
        }
    }
    
    //Define default state for update coordinates
    const [updateCoordinatesStatus, setUpdateCoordinatesStatus] = useState(false)
    const [newCoordinates, setNewCoordinates] = useState("")

    useEffect(() => {
        if (updateCoordinatesStatus){
            const selectedOverlay = overlays.filter(o => o.id === selectedOverlayId)[0]
            setNewCoordinates(selectedOverlay.minZlevel + ',' + selectedOverlay.maxZlevel)
        }
    }, [updateCoordinatesStatus])
    
    const updateCoordinates = async () => {
        setUpdateCoordinatesStatus(false)
        if (newCoordinates === "" || !newCoordinates.includes(',')){
            toast.error("Invalid zoom level for update")
            return
        }

        const splitCoordinates = newCoordinates.split(',')

        if (!parseInt(splitCoordinates[0])){
            toast.error("Zoom coordinates must uniquely contain numbers")
        }

        if (!parseInt(splitCoordinates[1])){
            toast.error("Zoom coordinates must uniquely contain numbers")
        }

        if (await OverlayAPI.updateOverlay(selectedOverlayId, null, parseInt(splitCoordinates[0]), parseInt(splitCoordinates[1]))){
            toast.success('Overlay successfully updated')
            setGetOverlaysRequestStatus(false)
            OverlayAPI.getMapOverlays(mapUuid).then((response) => {
                setOverlays(response.data)
                setGetOverlaysRequestStatus(true)
            })
        }
    }

    //Define default state for update overlay name
    const [updateOverlayNameStatus, setUpdateOverlayNameStatus] = useState(false)
    const [newOverlayName, setNewOverlayName] = useState("")

    useEffect(() => {
        if (updateOverlayNameStatus){
            const selectedOverlay = overlays.filter(o => o.id === selectedOverlayId)[0]
            setNewOverlayName(selectedOverlay.name)
        }
    }, [updateOverlayNameStatus])

    const updateOverlayName = async () => {
        setUpdateOverlayNameStatus(false)

        if (newOverlayName === ""){
            toast.error("New overlay name cannot be empty")
            return
        }

        if (await OverlayAPI.updateOverlay(selectedOverlayId, newOverlayName)){
            toast.success('Overlay successfully updated')
            setGetOverlaysRequestStatus(false)
            OverlayAPI.getMapOverlays(mapUuid).then((response) => {
                setOverlays(response.data)
                setGetOverlaysRequestStatus(true)
            })
        }
    }

    return (
        <div className="map-side-menu-container open">
            <div className="menu-header">
                <div className="title">
                    <p className="icon"><BsFillLayersFill /></p>
                    <p className="text">Overlays</p>
                </div>
                <p className="close-icon" onClick={() => setMenuStatus(false)}><VscChromeClose /></p>
            </div>
            <div className="button-container">
                <div className="left">
                    {!updateCoordinatesStatus && !updateOverlayNameStatus
                        ?
                        <>
                            <div onClick={() => setUpdateOverlayNameStatus(true)} className={selectedOverlayId === "" ? "btn inactive" : "btn"}>
                                <p className="icon"><MdDriveFileRenameOutline /></p>
                                <p className="text">Rename</p>
                            </div>
                            <div onClick={() => setUpdateCoordinatesStatus(true)} className={selectedOverlayId === "" ? "btn inactive" : "btn"}>
                                <p className="icon"><HiOutlineZoomOut /></p>
                                <p className="text">Change zoom level</p>
                            </div>
                        </>
                        : null
                    }
                    {updateCoordinatesStatus || updateOverlayNameStatus
                        ?
                        <div className="btn" onClick={() => updateOverlayNameStatus ? updateOverlayName() : updateCoordinates()}>
                            <p className="icon"><BiCheck /></p>
                            <p className="text">Confirm</p>
                        </div>
                        : null
                    }
                </div>
                <div className="right">
                    {!updateCoordinatesStatus && !updateOverlayNameStatus
                        ?
                        <div onClick={() => selectedOverlayId !== "" ? deleteOverlay() : null} className={selectedOverlayId === "" ? "btn inactive" : "btn delete"}>
                            <p className="icon"><MdDelete /></p>
                            <p className="text">Delete</p>
                        </div>
                        :
                        <div onClick={() => updateOverlayNameStatus ? setUpdateOverlayNameStatus(false) : setUpdateCoordinatesStatus(false)} className="btn delete">
                            <p className="icon"><MdOutlineCancel /></p>
                            <p className="text">Cancel</p>
                        </div>
                    }
                </div>
            </div>
            <div className="data-container">
                {getOverlaysRequestStatus && (
                    <>
                        {overlays.length > 0 && (
                            <>
                                <div className="item">
                                    <div className="left">
                                        <p className="view-btn"> </p>
                                        <p className="name"> </p>
                                    </div>
                                    <div className="right">
                                        <p className="coordinates">Z</p>
                                    </div>
                                </div>
                                {overlays.map((overlay, index) => (
                                    <div className={selectedOverlayId === overlay.id ? "item selected" : "item"} key={index}>
                                        <div className="left">
                                            <p id={index} onClick={changeOverlayView} className={overlay.view ? "view-btn active" : "view-btn"}>{overlay.view ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}</p>
                                            {updateOverlayNameStatus && selectedOverlayId === overlay.id
                                                ? <input onChange={(event) => setNewOverlayName(event.currentTarget.value)} type="text" autoFocus={true} value={newOverlayName}/>
                                                : <p className="name" onClick={() => {
                                                    if (!updateCoordinatesStatus && !updateOverlayNameStatus){
                                                        selectedOverlayId !== overlay.id ? setSelectedOverlayId(overlay.id) : setSelectedOverlayId("")
                                                    }
                                                }}>{overlay.name}</p>
                                            }

                                        </div>
                                        <div className="right">
                                            {updateCoordinatesStatus && selectedOverlayId === overlay.id
                                                ? <input onChange={(event) => setNewCoordinates(event.currentTarget.value)} type="text" autoFocus={true} value={newCoordinates}/>
                                                : <p className="coordinates">[{overlay.minZlevel},{overlay.maxZlevel}]</p>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default OverlaySideMenu