import i18n from "i18next"
import {initReactI18next} from "react-i18next";
import Backend from "i18next-http-backend";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: "fr",
        debug: false,
        fallbackLng: "fr",
        load: "all",
        react: {
            wait: true,
            useSuspense: false
        },
    })

i18n.loadLanguages(['fr'])


export default i18n