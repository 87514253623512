import getAxiosInstance from "../../AxiosInstance";
import {SHAPE_ITEM_API} from "../../../../Router";
import {useMapStore} from "../../Stores";

function getOverlayShapeItems(overlayUuid){
	return getAxiosInstance().get(SHAPE_ITEM_API + '/get_overlay_shape_items', {params: {overlayUuid: overlayUuid}})
}

function createShapeItem(points, type) {
	const sendData = new FormData()
	sendData.append('points', JSON.stringify(points))
	sendData.append('type', type)
	sendData.append('overlayUuid', useMapStore.getState().currentOverlay)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/create', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function createCircleShapeItem(center, radius) {
	const sendData = new FormData()
	sendData.append('x', center.x)
	sendData.append('y', center.y)
	sendData.append('radius', radius)
	sendData.append('overlayUuid', useMapStore.getState().currentOverlay)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/create_circle', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function updateShapeItem(points, shapeUuid) {
	const sendData = new FormData()
	sendData.append('points', JSON.stringify(points))
	sendData.append('shapeUuid', shapeUuid)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/update', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function updateCircleShapeItem(center, shapeUuid, radius) {
	const sendData = new FormData()
	sendData.append('shapeUuid', shapeUuid)
	sendData.append('x', center.x)
	sendData.append('y', center.y)
	sendData.append('radius', radius)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/update_circle', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function deleteShapeItem(shapeUuid) {
	return getAxiosInstance().delete(SHAPE_ITEM_API + '/delete', {params: {shapeUuid: shapeUuid}})
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function userMoveShapeItemToOverlay(shapeItemUuid, overlayUuid){
	const sendData = new FormData()
	sendData.append('shapeItemUuid', shapeItemUuid)
	sendData.append('overlayUuid', overlayUuid)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/move_to_overlay', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userUpdateShapeItemColor(shapeUuid, color){
	const sendData = new FormData()
	sendData.append('shapeUuid', shapeUuid)
	sendData.append('color', color)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/update_color', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userUpdateShapeItemFillColor(shapeUuid, color, opacity){
	const sendData = new FormData()
	sendData.append('shapeUuid', shapeUuid)
	sendData.append('color', color)
	sendData.append('opacity', opacity)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/update_fill_color', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userUpdateShapeItemBorderWidth(shapeUuid, width){
	const sendData = new FormData()
	sendData.append('shapeUuid', shapeUuid)
	sendData.append('width', width)

	return getAxiosInstance().post(SHAPE_ITEM_API + '/update_width', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	createShapeItem,
	updateShapeItem,
	deleteShapeItem,
	getOverlayShapeItems,
	userMoveShapeItemToOverlay,
	userUpdateShapeItemColor,
	userUpdateShapeItemBorderWidth,
	userUpdateShapeItemFillColor,
	createCircleShapeItem,
	updateCircleShapeItem
}