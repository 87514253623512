import React, {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import SideBarNav from "../../components/SideBarNav";
import TopNavBar from "../../components/TopNavBar";
import AuthAPI from "../../services/AuthAPI";
import {DASHBOARD_MAP} from "../../DashboardMap";
import {useDashboardStore} from "../../services/Stores/DashboardStore";
import {useUserStore} from "../../services/Stores";
import BrowserNotSupported from "../BrowserNotSupported";

const DashboardTemplate = () => {
	//Get url params
	const {dashboardType, page} = useParams()

	const [browserSupported, setBrowserSupported] = useState(true)


	//Detect if browser is supported
	useEffect(() => {
		if (/Android|iPhone/i.test(navigator.userAgent)) {
			setBrowserSupported(false)
		} else {
			setBrowserSupported(true)
		}
	}, [])

	useEffect(() => {
		useDashboardStore.getState().resetStore()
	}, [dashboardType]);

	//Get current dashboard in store
	let currentDashboard = useDashboardStore(state => state.currentDashboard)

	const confirmedAccount = useUserStore(state => state.userLoad ? state.confirmedAccount : true)

	//Check if dashboard type is valid
	if (dashboardType !== "user" && dashboardType !== "admin"){
		return <Redirect to="/" />
	}

	//Check if user can access to dashboard type
	if (dashboardType === "admin"){
		if (!AuthAPI.isAdmin()){
			return <Redirect to="/user/dashboard/home" />
		}
	}

	//Try to get dashboard map value if current dashboard is not defined in store
	if (currentDashboard === null) {
		if (DASHBOARD_MAP[dashboardType] && DASHBOARD_MAP[dashboardType][page]) {
			currentDashboard = DASHBOARD_MAP[dashboardType][page]
			useDashboardStore.setState({currentDashboard: currentDashboard})
		} else {
			return <Redirect to="/"/>
		}
	}

	if (!browserSupported) {
		return <BrowserNotSupported />
	}

	return (
		<>
			<TopNavBar pageTitle={page} />
			<SideBarNav dashboardType={dashboardType} page={page} currentDashboard={currentDashboard} />
			<main className={confirmedAccount ? "dashboard-container" : "dashboard-container header-alert-active"} style={currentDashboard.noPadding ? {padding: 0} : null}>
				{currentDashboard.component}
			</main>
		</>
	)
}

export default DashboardTemplate