import React, {useEffect, useState} from "react";
import ContentItemAPI from "../../services/ContentItemAPI";
import {VscChromeClose} from "react-icons/vsc";

const FullScreenPDF = ({properties, setProperties}) => {
    //Define default state for content item data
    const [contentItem, setContentItem] = useState({
        'name': "",
        'ownerEmail': "",
        'attributes': [],
        'type': "",
        'url': "",
        'pictureUrl': "",
        'category': "",
        'audioUrl': "",
        'videoUrl': "",
        'note': "",
        'color': "",
        'associatedItems': [],
        'lastUpdate': "",
        'lastUpdateUser': "",
        'previewImage': "",
        'thumbnailUrl': "",
        'fileUrl': "",
        'commentCount': 0,
        'bibtexCode': ""
    })

    //Get content item data
    useEffect(() => {
        ContentItemAPI.getContentItemByUuid(properties.id).then((response) => {
            if (response.status === 200){
                setContentItem(response.data)
            }
        })
    }, [])


    return (
        <>
            <div className="full-screen-object-container">
                <iframe src={contentItem.fileUrl} height="100%" width="100%" />
                <p className="close-btn" onClick={() => {
                    setProperties({"type_code": ""})
                }}><VscChromeClose /></p>
            </div>
        </>
    )
}

export default FullScreenPDF