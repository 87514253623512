import React, {useContext, useEffect, useRef, useState} from "react";
import Skeleton from "react-loading-skeleton";
import {FaChevronDown} from "react-icons/fa";
import {MdOutlineSpaceDashboard, MdSettings} from "react-icons/md";
import {FiHelpCircle, FiLogOut} from "react-icons/fi";
import AuthAPI from "../services/AuthAPI";
import {useHistory, useLocation} from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import IsAdminContext from "../contexts/IsAdminContext";
import {useCookies} from "react-cookie";
import SupportPopupContext from "../contexts/SupportPopupContext";
import {useUserStore} from "../services/Stores";
import {use} from "i18next";
import {useDashboardStore} from "../services/Stores/DashboardStore";
import {DASHBOARD_MAP} from "../DashboardMap";

const TopbarUserMenu = () => {
    const userLoad = useUserStore(state => state.userLoad)
    const user = useUserStore(state => state.user)

    const history = useHistory()
    const {setIsAuthenticated} = useContext(AuthContext)
    const {setIsAdmin} = useContext(IsAdminContext)
    const [cookies, setCookie, removeCookie] = useCookies(['mercureAuthorization', 'refreshToken'])
    const location = useLocation()

    const [profileMenuStatus, setProfileMenuStatus] = useState(false)
    const profileMenuRef = useRef(null)
    const profileMenuRef2 = useRef(null)

    const logoutUser = () => {
        removeCookie('refreshToken', {path: "/"})
        removeCookie('mercureAuthorization', {path: "/"})
        setIsAdmin(false)
        setIsAuthenticated(false)
        AuthAPI.logout()
        history.push("/")
    }

    const handleClickMenuOutside = (event) => {
        if (profileMenuRef.current && !profileMenuRef.current.contains(event.target) && profileMenuRef2.current && !profileMenuRef2.current.contains(event.target)){
            setProfileMenuStatus(false)
        }
    }

    //Detect user click
    useEffect(() => {
        document.addEventListener("mousedown", handleClickMenuOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickMenuOutside);
        };
    });

    const {supportPopupStatus, setSupportPopupStatus} = useContext(SupportPopupContext)

    const handleRedirectToAdminDashboard = () => {
        //Get default admin dashboard route
        const route = Object.values(DASHBOARD_MAP.admin).filter(r => r.default)[0].route

        //Redirect user to route
        history.push(route)
    }

    const handleRedirectToUserDashboard = () => {
        //Get default admin dashboard route
        const route = Object.values(DASHBOARD_MAP.user).filter(r => r.default)[0].route

        //Redirect user to route
        history.push(route)
    }

    return (
        <>
                <div className="profile-container" ref={profileMenuRef2} onClick={() => setProfileMenuStatus(!profileMenuStatus)}>
                    <div className="profile">
                        {userLoad && (
                            <>
                                {!user.picture && (
                                    <div className="userimage-container">
                                        <p>{user.firstname[0]}{user.lastname[0]}</p>
                                    </div>
                                )}
                                {user.picture && (
                                    <img src={user.picture} alt="User image"/>
                                )}
                            </>
                        )}
                        {!userLoad && (
                            <Skeleton count={1} width={45} height={45} circle={true} />
                        )}

                        <p className="user-name">{userLoad ? user.firstname + " " + user.lastname : <Skeleton count={1} width={150} />}</p>
                    </div>
                </div>
                <div className="menu-container" ref={profileMenuRef}>
                    <p className="menu-btn" onClick={() => setProfileMenuStatus(!profileMenuStatus)}><FaChevronDown/></p>
                    <div className={profileMenuStatus ? "menu open" : "menu"}>
                        <div className="profile">
                            {userLoad && (
                                <>
                                    {user.picture && (
                                        <div className="image">
                                            <img src={user.picture} alt="User image"/>
                                        </div>
                                    )}
                                    {!user.picture && (
                                        <div className="userimage-container">
                                            <p>{user.firstname[0]}{user.lastname[0]}</p>
                                        </div>
                                    )}
                                </>
                            )}

                            {!userLoad && (
                                <Skeleton count={1} width={75} height={75} circle={true} />
                            )}
                            <div className="infos">
                                <p>{userLoad ? user.firstname + " " + user.lastname : <Skeleton count={1} width={100} />}</p>
                                <p>{userLoad ? user.email : <Skeleton count={1} width={100} />}</p>
                                <p>Member since : {userLoad ? user.creation_date : <Skeleton count={1} width={75} />}</p>
                            </div>
                        </div>
                        <hr/>
                        <div className="link">
                            {location.pathname.split("/")[1] === "admin" && (
                                <p onClick={handleRedirectToUserDashboard}><span><MdOutlineSpaceDashboard /></span>User dashboard</p>
                            )}
                            {userLoad && (
                                <>
                                    {user.role === "ROLE_ADMIN" && (
                                        <>
                                            {location.pathname.split("/")[1] === "user" && (
                                                <p onClick={handleRedirectToAdminDashboard}><span><MdOutlineSpaceDashboard /></span>Admin dashboard</p>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            {/*<p><span><RiArrowUpCircleLine /></span>Upgrade</p>*/}
                            <p onClick={() => history.push("/user/settings")}><span><MdSettings /></span>Settings</p>
                            <p onClick={() => setSupportPopupStatus(true)}><span><FiHelpCircle /></span>Support</p>
                            {/*<p onClick={() => setDocumentationPopupStatus(true)}><span><FiHelpCircle /></span>Documentation</p>*/}
                            <p onClick={logoutUser}><span><FiLogOut /></span>Log out</p>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default TopbarUserMenu