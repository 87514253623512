import getAxiosInstance from "../../AxiosInstance";
import {USER_BIBTEX_ITEM_API} from "../../../../Router";

function userLinkFileToBibtexItem(uuid, file){
	const sendData = new FormData()
	sendData.append('uuid', uuid)
	sendData.append('file', file)

	return getAxiosInstance().post(USER_BIBTEX_ITEM_API + '/link_file', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.key
			}

			return false
		})
		.catch(() => {
			return false
		})
}

function userUnlinkBibtexItemFile(uuid, key){
	const sendData = new FormData()
	sendData.append('uuid', uuid)
	sendData.append('key', key)

	return getAxiosInstance().post(USER_BIBTEX_ITEM_API + '/unlink_file', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function userDownloadBibtexLinkedFile(uuid, key){
	const sendData = new FormData()
	sendData.append('uuid', uuid)
	sendData.append('key', key)

	return getAxiosInstance().post(USER_BIBTEX_ITEM_API + '/download_file', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.url
			}

			return false;
		})
		.catch(() => {
			return false
		})
}



export default {
	userLinkFileToBibtexItem,
	userUnlinkBibtexItemFile,
	userDownloadBibtexLinkedFile
}