import React from "react";
import weeki_logo from "../../images/weeki_logo.svg"
import {useUserStore} from "../services/Stores";
import UserAPI from "../services/UserAPI";
import {createSuccessToast} from "../services/CustomToastUtils";

const BrowserNotSupported = () => {
    //Get confirmed account state in store
    const confirmedAccount = useUserStore(state => state.confirmedAccount)

    const handleResendConfirmationEmail = async () => {
        if (await UserAPI.userSendConfirmationEmail()) {
            createSuccessToast("Confirmation email successfully send")
        }
    }

    return (
        <div className="browser-not-supported-container">
            <img src={weeki_logo} alt="Weeki logo"/>
            {!confirmedAccount && (
                <>
                    <p>Please check your email to confirm your registration and activate your account.</p>
                    <a onClick={handleResendConfirmationEmail} className="btn-black">Resend confirmation email</a>
                </>
            )}
            {confirmedAccount && (
                <p>To enjoy the full experience, please visit Weeki on your desktop. We look forward to seeing you there !</p>

            )}
        </div>

    )
}

export default BrowserNotSupported