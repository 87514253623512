import getAxiosInstance from "../../../AxiosInstance";
import {USER_MOOC_CHAPTER_API} from "../../../../../Router";

function userGetAllMoocChapters(moocUuid){
	return getAxiosInstance().get(USER_MOOC_CHAPTER_API + '/get_all_mooc_chapters', {params: {moocUuid: moocUuid}})
}

function userCreateMoocChapter(moocUuid){
	const sendData = new FormData()
	sendData.append('moocUuid', moocUuid)

	return getAxiosInstance().post(USER_MOOC_CHAPTER_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.uuid
			}

			return false
		})
		.catch((error) => {
			return false
		})
}

function userRenameMoocChapter(uuid, name){
	const sendData = new FormData()
	sendData.append('chapterUuid', uuid)
	sendData.append('name', name)

	return getAxiosInstance().post(USER_MOOC_CHAPTER_API + '/rename', sendData)
		.then((response) => {
				return response.status === 200;
			})
			.catch((error) => {
				return false
			})

}

function userDeleteSection(uuid){
	return getAxiosInstance().delete(USER_MOOC_CHAPTER_API + '/delete', {params: {uuid: uuid}})
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

export default {
	userGetAllMoocChapters,
	userCreateMoocChapter,
	userRenameMoocChapter,
	userDeleteSection
}