export class SpaceBlock {
    static get toolbox() {
        return {
            title: 'Space',
            icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg>`
        };
    }

    constructor({data}){
        this.heightSizeFactor = 20
        this.data = {
            height: data.height !== undefined ? data.height : 1
        };
        this.wrapper = undefined;
        this.text = undefined
        this.settings = [
            {
                name: 'maxHeight',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg>`
            },
            {
                name: 'minHeight',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M439 7c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H296c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39L439 7zM72 272H216c13.3 0 24 10.7 24 24V440c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39L73 505c-9.4 9.4-24.6 9.4-33.9 0L7 473c-9.4-9.4-9.4-24.6 0-33.9l87-87L55 313c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8z"/></svg>`
            }
        ];
    }

    render(){
        //Render wrapper component and add class and set height with component data
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('custom-blocks__space-block-edit-container');
        this.wrapper.style.height = this.data.height * this.heightSizeFactor + "px"

        //Add text in wrapper container
        this.text = document.createElement('p')
        this.wrapper.appendChild(this.text)
        this.text.innerText = "Space"

        return this.wrapper;
    }

    renderSettings(){
        const wrapper = document.createElement('div');

        this.settings.forEach( tune => {
            let button = document.createElement('div');

            button.classList.add('cdx-settings-button');
            button.innerHTML = tune.icon;
            wrapper.appendChild(button);

            button.addEventListener('click', () => {
                this._updateHeight(tune)
            })
        });

        return wrapper;
    }

    _updateHeight(tune){
        if (tune.name === "minHeight"){
            if (this.data.height > 1){
                this.data.height = this.data.height - 1
                this.wrapper.style.height = this.data.height * this.heightSizeFactor + "px"
            }
        } else if (tune.name === "maxHeight"){
            this.data.height = this.data.height + 1
            this.wrapper.style.height = this.data.height * this.heightSizeFactor + "px"
        }
    }

    save(blockContent){
        return {
            height: this.data.height
        }
    }


}