import {ADMIN_MAIL_TEMPLATE_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function getAllEmailTemplate(){
    return getAxiosInstance().get(ADMIN_MAIL_TEMPLATE_API + "/get_all")
}

function createEmailTemplate(name, category, body){
    const sendData = new FormData()
    sendData.append("name", name)
    sendData.append("category", category)
    sendData.append("body", body)
    return getAxiosInstance().post(ADMIN_MAIL_TEMPLATE_API + "/create_template", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function copyTemplate(templateId){
    const sendData = new FormData()
    sendData.append("templateUuid", templateId)
    return getAxiosInstance().post(ADMIN_MAIL_TEMPLATE_API + "/copy_template", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function deleteTemplate(templateId){
    return getAxiosInstance().delete(ADMIN_MAIL_TEMPLATE_API + "/delete", {
        params:{
            templateUuid: templateId
        }
    })
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function updateTemplate(sendData){
    return getAxiosInstance().post(ADMIN_MAIL_TEMPLATE_API + "/update", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

export default {
    getAllEmailTemplate,
    createEmailTemplate,
    copyTemplate,
    deleteTemplate,
    updateTemplate
}