import React, {useEffect, useRef, useState} from "react";
import ContentItemAPI from "../../../services/ContentItemAPI";
import {BsFullscreen, BsThreeDotsVertical} from "react-icons/bs";
import {IoCamera} from "react-icons/io5";
import {RxPause, RxPlay} from "react-icons/rx";
import {VscChromeClose} from "react-icons/vsc";
import {toast} from "react-toastify";
import AppSearchAPI from "../../../services/AppSearchAPI";
import ContentItemTagAPI from "../../../services/ContentItemTagAPI";
import {WaveSurfer, WaveForm} from "wavesurfer-react";

import 'katex/dist/katex.min.css'
import Latex from "react-latex-next";
import ObjectSidebarComments from "./Contents/ObjectSidebarComments";
import {BiTrash} from "react-icons/bi";
import BibtexObjectSidebar from "./Contents/BibtexObjectSidebar";
import ObjectSidebarInformations from "./Contents/ObjectSidebarInformations";
import {useSidebarMapContentItemStore} from "../../../services/Stores/SidebarMapContentItemStore";
import {useMapStore} from "../../../services/Stores";
import {useContentItemStore} from "../../../services/Stores/ContentItemStore";

const ObjectSidebar = () => {
    //Define default state for header menu
    const [headerMenuStatus, setHeaderMenuStatus] = useState(false)

    const handleOpenFullscreen = () => {
        //Get item uuid
        const itemUuid = useSidebarMapContentItemStore.getState().properties.id

        //Open fullscreen
        useContentItemStore.setState({currentFullscreenItemUuid: itemUuid})

        //Close sidebar
        useSidebarMapContentItemStore.getState().resetStore()
    }

    return (
        <>
            <div className="object-sidebar-container-sec">
                <div className="header">
                    {!useMapStore.getState().publicMap && (
                        <>
                            {headerMenuStatus && (
                                <div className="header-sub-menu-container">
                                    <div className="button delete">
                                        <p className="icon"><BiTrash /></p>
                                        <p className="text">Delete</p>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <div className="button-container">
                        <p onClick={() => useSidebarMapContentItemStore.getState().resetStore()}><VscChromeClose /></p>
                        <div className="right">
                            {useSidebarMapContentItemStore.getState().properties.fullscreen && (
                                <p onClick={handleOpenFullscreen}><BsFullscreen /></p>
                            )}
                            {!useMapStore.getState().publicMap && (
                                <p onClick={() => setHeaderMenuStatus(!headerMenuStatus)}><BsThreeDotsVertical /></p>
                            )}
                        </div>
                    </div>
                    <div className="menu-container">
                        <p onClick={() => useSidebarMapContentItemStore.getState().currentPanel !== "info" ? useSidebarMapContentItemStore.setState({currentPanel: 'info'}) : null} className={useSidebarMapContentItemStore.getState().currentPanel === "info" ? "selected" : ""}>Information</p>
                        {!useMapStore.getState().publicMap && (
                            <p onClick={() => useSidebarMapContentItemStore.getState().currentPanel !== "comment" ?  useSidebarMapContentItemStore.setState({currentPanel: 'comment'}) : null} className={useSidebarMapContentItemStore.getState().currentPanel === "comment" ? "selected" : ""}>Comments</p>
                        )}
                        {/*<p onClick={() => selectedMenu !== "activity" ? setSelectedMenu('activity') : null} className={selectedMenu === "activity" ? "selected" : ""}>Activity</p>*/}
                    </div>
                </div>
                {!useMapStore.getState().publicMap && (
                    <>
                        {useSidebarMapContentItemStore.getState().currentPanel === "comment" && (
                            <ObjectSidebarComments />
                        )}
                    </>
                )}
                {useSidebarMapContentItemStore.getState().currentPanel === "info" && (
                    <ObjectSidebarInformations />
                )}
            </div>
        </>
    )
}

export default ObjectSidebar