import React, {useEffect} from "react";
import MapAPI from "../../../services/MapAPI";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import MapsListContainer from "../../../components/customs/projects_list/MapsListContainer";
import ProjectManageActionBar from "../../../components/customs/projects_list/ProjectManageActionBar";
import { PiFolderSimpleUser } from "react-icons/pi";

const SharedWithMe = () => {
	const currentProjectMaps = useDashboardStore(state => state.currentProjectMaps)

	useEffect(() => {
		//Reset data in store
		useDashboardStore.setState({currentOpenedProject: null, currentProjects: null, currentOpenedWorkspace: null})

		MapAPI.userGetSharedMaps().then((response) => {
			if (response.status === 200){
				useDashboardStore.setState({currentProjectMaps: response.data})
			} else {
				useDashboardStore.setState({currentProjectMaps: []})
			}
		})
	}, []);

	return (
		<>
			<ProjectManageActionBar action={false}/>
			{(currentProjectMaps !== null && currentProjectMaps.length > 0) &&(
				<>
					<MapsListContainer />
				</>
			)}
			{(currentProjectMaps !== null && currentProjectMaps.length <= 0) &&(
				<p className="map-list-title" style={{opacity: "0.2", paddingTop: "130px", fontSize: "18px"}} >
					<PiFolderSimpleUser style={{fontSize: "35px", marginBottom: "10px"}} />
					<br />
					No shared board
				</p>
			)}
		</>
	)
}

export default SharedWithMe