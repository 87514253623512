import React from "react";
import {BiExport} from "react-icons/bi";

const Export = ({data, filename}) => {

    const exportData = () => {
        //Check if data contain object
        if (data.length > 0){

            //Create empty csv string
            let csv = ""

            //Add first row with key
            for (let j = 0; j < Object.keys(data[0]).length; j++){
                csv += Object.keys(data[0])[j] + ";"
            }
            csv += "\n"

            //Loop the data array and add to csv string
            for (let i = 0; i < (data.length); i++){
                for (let j = 0; j < Object.keys(data[i]).length; j++){
                    csv += Object.values(data[i])[j] + ";"
                }
                csv += "\n"
            }

            //Create link element for download csv file
            const link = document.createElement("a")
            link.id = "download-csv"
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
            link.setAttribute('download', filename + '.csv')
            document.body.appendChild(link)
            document.querySelector('#download-csv').click()

            document.body.removeChild(link)
        }
    }

    return (
        <div className="export-btn" onClick={exportData}>
            <p className="icon"><BiExport /></p>
            <p className="text">Export data</p>
        </div>
    )
}

export default Export