import React from "react";
import download_icon from "../../../../icons/object-menu/download.svg"
import {TbTextSize} from "react-icons/tb";

const TextItemSelectedMenu = ({properties}) => {

	return (
		<>
			<div className="custom__map-selected-popup-container">
				<div className="selected-menu-button" id="text-size-btn">
					<div className="button-icon">
						<TbTextSize size={20}/>
					</div>
				</div>
				<div className="btn-menu-container" id="text-size-menu">
					<p id="size-btn" data-size="small" className="btn-menu-item">Small</p>
					<p id="size-btn" data-size="normal" className="btn-menu-item">Normal</p>
					<p id="size-btn" data-size="large" className="btn-menu-item">Large</p>
					<p id="size-btn" data-size="huge" className="btn-menu-item">Huge</p>
				</div>
			</div>
		</>
	)
}

export default TextItemSelectedMenu