import {USER_CONTENT_ITEM_TYPE_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function getAllAvailableType(){
    return getAxiosInstance().get(USER_CONTENT_ITEM_TYPE_API + '/get_all_available_type')
}

function getContentItemCategories(){
    return getAxiosInstance().get(USER_CONTENT_ITEM_TYPE_API + '/get_categories')
}

function getContentItemTypeUserList(){
    return getAxiosInstance().get(USER_CONTENT_ITEM_TYPE_API + '/get_user_item_type')
}

function getAllBibtexReference() {
    return getAxiosInstance().get(USER_CONTENT_ITEM_TYPE_API + "/get_all_bibtex_ref")
}

export default {
    getAllAvailableType,
    getContentItemCategories,
    getContentItemTypeUserList,
    getAllBibtexReference
}