import React, {useEffect, useRef, useState} from "react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {VscChromeClose} from "react-icons/vsc";
import {useOnClickOutside, useOutsideClick} from "../../services/Utils";

const ObjectFilterPanel = ({objectCategories, selectedCategory, setSelectedCategory, selectedType, setSelectedType, selectedColors, setSelectedColors, selectedTags, setSelectedTags, setState}) => {
    const [objectTypeList, setObjectTypeList] = useState([])
    const panelRef = useRef(null)
    // useEffect(
    //     () => {
    //         const listener = (event) => {
    //             if (!panelRef.current || panelRef.current.contains(event.target)) {
    //                 return;
    //             }
    //
    //             setState(false)
    //         };
    //
    //         window.addEventListener("click", listener);
    //         window.addEventListener("touchstart", listener);
    //         return () => {
    //             window.removeEventListener("click", listener);
    //             window.removeEventListener("touchstart", listener);
    //         };
    //     },
    //     [panelRef]
    // );

    useEffect(() => {
        if (objectCategories[0].name !== ""){
            objectCategories.forEach(category => {
                category.types.forEach(type => {
                    setObjectTypeList(objectTypeList => [...objectTypeList, {name: type.name, code: type.code}])
                })
            })
        }
    }, [objectCategories])

    const availableColors = [
        "#ED1C24",
        "#FF7F27",
        "#FFF200",
        "#22B14C",
        "#00A2E8",
        "#3F48CC",
        "#A349A4",
        "#B97A57",
        "#FFAEC9",
        "#FFC90E",
        "#EFE4B0",
        "#B5E61D",
        "#99D9EA",
        "#7092BE",
        "#C8BFE7"
    ]

    /***************************************TYPE FILTER**************************************/
    const categorySelectRef = useOutsideClick(() => {
        setSelectCategoryResultStatus(false)
    })
    const typeSelectRef = useOutsideClick(() => {
        setSelectTypeResultStatus(false)
    })
    const colorSelectRef = useOutsideClick(() => {
        setColorFilterSelectStatus(false)
    })

    const [selectCategoryResultStatus, setSelectCategoryResultStatus] = useState(false)

    const [categorySearch, setCategorySearch] = useState("")
    let filteredCategory = objectCategories.filter(m =>
        String(m.name).toLowerCase().includes(categorySearch.toLowerCase()) &&
        !selectedCategory.includes(m.code)
    )

    const handleSelectCategory = (code) => {
        setSelectedCategory(selectedCategory => [...selectedCategory, code])
    }

    const handleUnselectCategory = (code) => {
        setSelectedCategory(selectedCategory.filter(c => c !== code))
    }

    const [selectTypeResultStatus, setSelectTypeResultStatus] = useState(false)

    const [typeSearch, setTypeSearch] = useState("")
    let filteredType = objectTypeList.filter(m =>
        String(m.name).toLowerCase().includes(typeSearch.toLowerCase()) &&
        !selectedType.includes(m.code)
    )

    const handleSelectType = (code) => {
        setSelectedType(selectedType => [...selectedType, code])
    }

    const handleUnselectType = (code) => {
        setSelectedType(selectedType.filter(c => c !== code))
    }

    const handleClearTypeFilter = () => {
        setSelectedCategory([])
        setSelectedType([])
        setCategorySearch("")
        setTypeSearch("")
    }
    /****************************************************************************************/

    /**********************************FILTER BY TAG*****************************************/
    const handleSelectTag = (event) => {
        if (event.key === "Enter"){
            const inputValue = event.currentTarget.value
            setSelectedTags(selectedTags => [...selectedTags, inputValue])

            event.currentTarget.value = ""
        }
    }

    const handleUnselectTag = (tag) => {
        setSelectedTags(selectedTags.filter(t => t !== tag))
    }

    const handleClearTagFilter = () => {
        setSelectedTags([])
    }
    /****************************************************************************************/

    /************************************FILTER COLOR****************************************/
    const [colorFilterSelectStatus, setColorFilterSelectStatus] = useState(false)

    const selectColor = (color) => {
        setSelectedColors(selectedColors => [...selectedColors, color])
    }

    const handleUnselectColor = (color) => {
        setSelectedColors(selectedColors.filter(c => c !== color))
    }

    const handleClearColorFilter = () => {
        setSelectedColors([])
    }
    /****************************************************************************************/

    return (
        <div className="panel-filter-sub-menu" ref={panelRef}>
            <div className="title-container">
                <p className="title">Filter</p>
            </div>
            <hr className="panel-separator"/>
            <div className="panel-filter-container">
                <div className="panel-filter">
                    <div className="filter-title">
                        <p className="title">By type</p>
                        <p className="clear-btn" onClick={handleClearTypeFilter}>Clear</p>
                    </div>
                    <div className="filter-selected-container">
                        {selectedCategory.map((code, index) => (
                            <div key={index} className="selected-value">
                                <p className="text">{objectCategories.filter(c => c.code === code)[0].name}</p>
                                <p className="icon" onClick={() => handleUnselectCategory(code)}><VscChromeClose /></p>
                            </div>
                        ))}
                    </div>
                    <div className="panel-select-container" ref={categorySelectRef} id="filter-panel-category-select">
                        <div className="select-input-container" id="qsfdqsfd">
                            <input onChange={(event) => setCategorySearch(event.currentTarget.value)} onFocus={() => setSelectCategoryResultStatus(true)} value={categorySearch} type="text" placeholder="Select category"/>
                            <p className="open-select-icon" onClick={() => selectCategoryResultStatus ? setSelectCategoryResultStatus(false) : setSelectCategoryResultStatus(true)}>{selectCategoryResultStatus ? <FaChevronUp /> : <FaChevronDown />}</p>
                        </div>
                        {selectCategoryResultStatus && (
                            <div className="select-result-container">
                                {filteredCategory.length > 0 && (
                                    <>
                                        {filteredCategory.map((category, index) => (
                                            <p className="result" onClick={() => handleSelectCategory(category.code)} key={index}>{category.name}</p>
                                        ))}
                                    </>
                                )}
                                {filteredCategory.length <= 0 && (
                                    <p className="result empty">No available category</p>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="filter-selected-container">
                        {selectedType.map((code, index) => (
                            <div key={index} className="selected-value">
                                <p className="text">{objectTypeList.filter(c => c.code === code)[0].name}</p>
                                <p className="icon" onClick={() => handleUnselectType(code)}><VscChromeClose /></p>
                            </div>
                        ))}
                    </div>
                    <div className="panel-select-container" ref={typeSelectRef} id="filter-panel-type-select">
                        <div className="select-input-container" id="kjdf">
                            <input onChange={(event) => setTypeSearch(event.currentTarget.value)} onFocus={() => setSelectTypeResultStatus(true)} value={typeSearch} type="text" placeholder="Select type"/>
                            <p className="open-select-icon" onClick={() => selectTypeResultStatus ? setSelectTypeResultStatus(false) : setSelectTypeResultStatus(true)}>{selectTypeResultStatus ? <FaChevronUp /> : <FaChevronDown />}</p>
                        </div>
                        {selectTypeResultStatus && (
                            <div className="select-result-container">
                                {filteredType.length > 0 && (
                                    <>
                                        {filteredType.map((type, index) => (
                                            <p className="result" onClick={() => handleSelectType(type.code)} key={index}>{type.name}</p>
                                        ))}
                                    </>
                                )}
                                {filteredType.length <= 0 && (
                                    <p className="result empty">No available type</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="panel-filter">
                    <div className="filter-title">
                        <p className="title">By tag</p>
                        <p className="clear-btn" onClick={handleClearTagFilter}>Clear</p>
                    </div>
                    <div className="filter-selected-container">
                        {selectedTags.map((tag, index) => (
                            <div key={index} className="selected-value">
                                <p className="text no-cap">{tag}</p>
                                <p className="icon" onClick={() => handleUnselectTag(tag)}><VscChromeClose /></p>
                            </div>
                        ))}
                    </div>
                    <div className="panel-select-container">
                        <div className="select-input-container">
                            <input type="text" onKeyDown={handleSelectTag} placeholder="Enter tag (press enter for confirm)"/>
                        </div>
                    </div>
                </div>
                {/*COLOR FILTER*/}
                <div className="panel-filter">
                    <div className="filter-title">
                        <p className="title">By color</p>
                        <p className="clear-btn" onClick={handleClearColorFilter}>Clear</p>
                    </div>
                    <div className="filter-selected-container">
                        {selectedColors.map((color, index) => (
                            <p key={index} onClick={() => handleUnselectColor(color)} className="selected-colors" style={{backgroundColor: color}}></p>
                        ))}
                    </div>
                    <div className="panel-select-container" ref={colorSelectRef}>
                        <div className="select-input-container" onClick={() => setColorFilterSelectStatus(!colorFilterSelectStatus)}>
                            <p className="select-title">Select color</p>
                            <p className="open-select-icon">{colorFilterSelectStatus ? <FaChevronUp /> : <FaChevronDown />}</p>
                        </div>
                        {colorFilterSelectStatus && (
                            <div className="select-result-container color-select">
                                {selectedColors.length < availableColors.length && (
                                    <>
                                        {availableColors.filter(c => !selectedColors.includes(c)).map((color, index) => (
                                            <p className="select-color-btn" key={index} onClick={() => selectColor(color)} style={{backgroundColor: color}}></p>
                                        ))}
                                    </>
                                )}
                                {selectedColors.length >= availableColors.length && (
                                    <p className="result empty">No available color</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObjectFilterPanel