import React, {useContext, useEffect, useState} from "react";
import {IoAdd} from "react-icons/io5";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {toast} from "react-toastify";
import UserPaymentAPI, {updateCurrentUserSubscription} from "../services/API/User/UserPaymentAPI";
import SubscribePopupContext from "../contexts/SubscribePopupContext";
import {BsCloudDownload} from "react-icons/bs";

// Import card icon
import mastercard_icon from "../../icons/mastercard.svg"
import visa_icon from "../../icons/visa.svg"
import amex_icon from "../../icons/amex.png"
import {useUserStore} from "../services/Stores";
import Skeleton from "react-loading-skeleton";
import {useSubscribePopupStore} from "../services/Stores/SubscribePopupStore";
import {getSizeName} from "../services/Utils";
import UserSubscriptionAPI from "../services/API/User/UserSubscriptionAPI";


const Billing = ({subscriptionId = null}) => {
    const elements = useElements()
    const stripe = useStripe()

    const userSubscription = useUserStore(state => state.userSubscription)
    const user = useUserStore(state => state.user)

    const [getUserPaymentMethodsState, setGetUserPaymentMethodsState] = useState(false)

    const [userPaymentMethods, setUserPaymentMethods] = useState([{
        'id': "",
        'last4': "",
        'brand': "",
        'exp': "",
        'default': ""
    }])

    const [invoices, setInvoices] = useState([{
        "id": "",
        "amount": 0,
        "date": "",
        "pdf": "",
        "number": ""
    }])

    const [currentSubscription, setCurrentSubscription] = useState({
        'id': "",
        'start': "",
        'end': "",
        'planName': "",
        'userCount': 1,
        'billingPeriod': "",
        'periodPrice': 0
    })

    useEffect(() => {
        UserPaymentAPI.getAllUserPaymentMethods().then((response) => {
            if (response.status === 200){
                setUserPaymentMethods(response.data)
            } else {
                setUserPaymentMethods([])
            }
            setGetUserPaymentMethodsState(true)
        })
        UserPaymentAPI.getAllUserInvoices().then((response) => {
            if (response.status === 200){
                setInvoices(response.data)
            } else {
                setInvoices([])
            }
        })
    }, [])

    const opt = {
        style: {
            base: {
                color: "#4D4F5C",
                fontFamily: '"BasierCircle, sans-serif',
                fontSize: "11px",
                "::placeholder": {
                    color: "#BFBFBF",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
        showIcon: true,
    };

    const addCard = () => {
        const card = elements.getElement(CardNumberElement)

        const cardTokenPromise = stripe.createToken(card)
        cardTokenPromise
            .then(async (response) => {
                const cardToken = response.token.id

                if (await UserPaymentAPI.userAddPaymentMethod(cardToken, defaultCard)) {
                    toast.success("Card successfully add")
                    setAddCardStatus(false)
                    setDefaultCard(true)
                    UserPaymentAPI.getAllUserPaymentMethods().then((response) => {
                        if (response.status === 200){
                            setUserPaymentMethods(response.data)
                        } else {
                            setUserPaymentMethods([])
                        }
                    })
                }
            })
            .catch((error) => {
                toast.error("Invalid card")
            })

    }

    const removeCard = async (cardId) => {
        if (await UserPaymentAPI.userDeleteCard(cardId)) {
            toast.success("Card delete")
            UserPaymentAPI.getAllUserPaymentMethods().then((response) => {
                if (response.status === 200){
                    setUserPaymentMethods(response.data)
                } else {
                    setUserPaymentMethods([])
                }
            })
        }
    }

    const updateDefaultCard = async (cardId) => {
        if (await UserPaymentAPI.userUpdateDefaultCard(cardId)) {
            toast.success("Default card updated")
            UserPaymentAPI.getAllUserPaymentMethods().then((response) => {
                if (response.status === 200){
                    setUserPaymentMethods(response.data)
                } else {
                    setUserPaymentMethods([])
                }
            })
        }
    }

    const [defaultCard, setDefaultCard] = useState(true)
    const [addCardStatus, setAddCardStatus] = useState(false)

    const handleStopSubscription = async () => {
        if (await UserPaymentAPI.userCancelCurrentSubscription()) {
            updateCurrentUserSubscription()
        }
    }

    const handleResumeSubscription = async () => {
        if (await UserPaymentAPI.userResumeCurrentSubscription()) {
            updateCurrentUserSubscription()
        }
    }

    const handleLeaveTeam = async () => {
        if (await UserSubscriptionAPI.userLeaveTeam()) {
            updateCurrentUserSubscription()
        }
    }

    return (
        <>
            <div className="content billing">
                <h1 className="title">Plans and billing</h1>
                <h2 className="subtitle">Manage your plan and billing details</h2>
                <div className="billing-plan-container">
                    <div className="plan-infos-container">
                        <div className="plan-details">
                            <div className="plan">
                                <div className="left">
                                    <p className="plan-name">{userSubscription.planName}</p>
                                    {!userSubscription.team && (
                                        <>
                                            {userSubscription && (
                                                <p className="plan-status">Active {userSubscription.end !== null ? "until " + userSubscription.end : ""}</p>
                                            )}
                                            {!userSubscription && (
                                                <p className="plan-status">Active</p>
                                            )}
                                        </>
                                    )}

                                </div>
                                {!userSubscription.team && (
                                    <p className="plan-price">{userSubscription ? userSubscription.periodPrice * userSubscription.maxUserCount : null}€ <span
                                        className="plan-period">per {userSubscription ? userSubscription.billingPeriod : "month"}</span>
                                    </p>
                                )}
                            </div>
                            <p className="member-since">Member since {user.creation_date}</p>
                            {!userSubscription.team && (
                                <>
                                    <div className="plan-bar-container">
                                        <div className="plan-bar">
                                            <p className="bar-infos">{userSubscription.currentUserCount} of {userSubscription.maxUserCount} users</p>
                                            <div className="bar">
                                                {userSubscription && (
                                                    <span className="bar-progress"
                                                          style={{width: (userSubscription.currentUserCount / userSubscription.maxUserCount) * 100 + "%"}}></span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="plan-bar">
                                            <p className="bar-infos">{getSizeName(userSubscription.currentTeamFileSize)} of {getSizeName(userSubscription.maxTeamFileSize)} storage</p>
                                            <div className="bar">
                                                {userSubscription && (
                                                    <span className="bar-progress"
                                                          style={{width: (userSubscription.currentTeamFileSize / userSubscription.maxTeamFileSize) * 100 + "%"}}></span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="plan-details-footer">
                            {!userSubscription.team && (
                                <>
                                    {userSubscription.planName !== "free" && (
                                        <>
                                            {userSubscription.active && (
                                                <p className="button red" onClick={handleStopSubscription}>Cancel
                                                    subscription</p>
                                            )}
                                            {!userSubscription.active && (
                                                <p className="button red" onClick={handleResumeSubscription}>Resume
                                                    subscription</p>
                                            )}
                                        </>
                                    )}
                                    <p className="button" onClick={() => useSubscribePopupStore.setState({
                                        state: true,
                                        view: "plans",
                                        paymentType: "subscription"
                                    })}>Upgrade plan</p>
                                </>
                            )}
                            {userSubscription.team && (
                                <>
                                    <p className="button red" onClick={() => handleLeaveTeam()}>Leave team</p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="billing-infos-container">
                        <p className="title">Payment method</p>
                        <p className="subtitle">Change how you pay for your plan</p>
                        <div className="cards-list-container">
                            {getUserPaymentMethodsState && (
                                <>
                                    {userPaymentMethods.length > 0 && (
                                        <>
                                            {userPaymentMethods.map((card, index) => (
                                                <div className="card" key={index}>
                                                    <div className="left">
                                                        <div className="logo-container">
                                                            <div className="logo">
                                                                {card.brand === "visa" ? <img src={visa_icon} alt="Visa icon" /> : null}
                                                                {card.brand === "mastercard" ? <img src={mastercard_icon} alt="Mastercard icon"/> : null}
                                                                {card.brand === "amex" ? <img src={amex_icon} alt="American express icon" /> : ""}
                                                            </div>
                                                        </div>
                                                        <div className="card-detail">
                                                            <div className="top">
                                                                <div className="card-name">
                                                                    <p className="name">{card.brand} ending in {card.last4}</p>
                                                                    {card.default && (
                                                                        <p className="card-state">default</p>
                                                                    )}
                                                                </div>
                                                                <p className="card-exp">Expiry 06/2024</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-btn">
                                                        {!card.default && (
                                                            <>
                                                                <p className="button" onClick={() => updateDefaultCard(card.id)}>Set default</p>
                                                                <p className="button red" onClick={() => removeCard(card.id)}>Remove</p>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                            {!getUserPaymentMethodsState && (
                                <>
                                    <Skeleton height={54} borderRadius={8} />
                                </>
                            )}
                        </div>
                        {!addCardStatus && (
                            <div className="add-card-btn" onClick={() => {
                                setAddCardStatus(true)
                                setDefaultCard(true)
                            }}>
                                <div className="icon"><IoAdd /></div>
                                <div className="text">Add new credit card</div>
                            </div>
                        )}
                        {addCardStatus && (
                            <div className="add-card-form">
                                <div className="card-input">
                                    <p className="card-input-title">Card number</p>
                                    <div className="card-element">
                                        <CardNumberElement options={opt} />
                                    </div>
                                </div>
                                <div className="card-element-container">
                                    <div className="card-input">
                                        <p className="card-input-title">Expiry</p>
                                        <div className="card-element">
                                            <CardExpiryElement options={opt} />
                                        </div>
                                    </div>
                                    <div className="card-input">
                                        <p className="card-input-title">CVC</p>
                                        <div className="card-element">
                                            <CardCvcElement options={opt} />
                                        </div>
                                    </div>
                                </div>
                                <div className="default-card-checkbox">
                                    <input id="default-card-checkbox" type="checkbox" checked={defaultCard} onChange={(e) => setDefaultCard(e.currentTarget.checked)} />
                                    <label htmlFor="default-card-checkbox">Set as default payment</label>
                                </div>
                                <div className="add-card-btn-container">
                                    <p className="cancel-add-card-btn" onClick={() => setAddCardStatus(false)}>Cancel</p>
                                    <p className="save-card-btn" onClick={addCard}>Save</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="billing-history-container">
                    <div className="billing-header">
                        <div className="left">
                            <p className="title">Billing history</p>
                            <p className="subtitle">Download your previous plan receipts and usage details.</p>
                        </div>
                    </div>
                    <table className="invoices-table first-large">
                        <thead>
                            <tr>
                                <td>Invoice</td>
                                <td>Billing date</td>
                                <td>Amount</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                        {invoices.length > 0 && (
                            <>
                                {invoices[0].id !== "" && (
                                    <>
                                        {invoices.map((invoice, index) => (
                                            <tr>
                                                <td>{invoice.number} <span>Paid</span></td>
                                                <td>{invoice.date}</td>
                                                <td>{invoice.amount}€</td>
                                                <td className="btn-td">
                                                    <div className="td-btn">
                                                        <div className="download-btn" onClick={() => window.open(invoice.pdf, '_blank').focus()}>
                                                            <p className="icon"><BsCloudDownload /></p>
                                                            <p className="text">Download</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                        {invoices.length <= 0 && (
                            <>
                                <tr>
                                    <td colSpan={4} style={{textAlign: "center"}}>No invoices</td>
                                </tr>
                            </>
                        )}


                        </tbody>
                    </table>

                </div>

            </div>
        </>
    )
}

export default Billing