import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {createErrorToast} from "../../../services/CustomToastUtils";
import UserWorkspaceAPI from "../../../services/API/User/UserWorkspaceAPI";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {EMAIL_REGEX} from "../../../../Regex";
import UserInvitationAPI from "../../../services/API/User/UserInvitationAPI";
import {MAX_WORKSPACE_LOGO_SIZE_MO} from "../../../../App";

const CreateWorkspacePopup = ({state, setState}) => {
	const uploadLogoInputRef = useRef(null)
	const [newWorkspaceData, setNewWorkspaceData] = useState({})
	const [invitedUser, setInvitedUser] = useState([])

	const handleCreateWorkspace = async () => {
		//Check if name is valid
		if (!newWorkspaceData.name || newWorkspaceData.name.length <= 0) {
			createErrorToast("Workspace name can't be empty")
			return
		}

		const data = await UserWorkspaceAPI.userCreateWorkspace(newWorkspaceData)

		if (data){
			useDashboardStore.getState().addNewWorkspace({uuid: data.uuid, name: newWorkspaceData.name, logo: newWorkspaceData.logoPreview ? newWorkspaceData.logoPreview : null, userAuthorizations: data.userAuthorizations})

			//Invite all user in list as viewer
			invitedUser.forEach((email) => {
				UserInvitationAPI.userInviteUser('workspace', data.uuid, 'viewer', email)
			})

			setState(false)
		}
	}

	//Upload logo
	const handleUploadLogo = () => {
		//Get input first files
		const file = uploadLogoInputRef.current.files[0]

		//Check if file exist
		if (file){
			//Check file size
			const fileSize = (file.size / 1024).toFixed(2)
			if (fileSize > (MAX_WORKSPACE_LOGO_SIZE_MO * 1024).toFixed(2)){
				createErrorToast("The file size must not exceed 4 MB")
				return;
			}

			//Check if file type is valid
			if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg"){
				createErrorToast("Image format invalid")
				return
			}

			//Create image preview
			const imagePreview = URL.createObjectURL(file)

			setNewWorkspaceData({...newWorkspaceData, logo: file, logoPreview: imagePreview})
		}
	}

	//Remove logo
	const handleRemoveLogo = () => {
		setNewWorkspaceData({...newWorkspaceData, logo: null, logoPreview: null})
	}

	const inviteUserInputRef = useRef(null)
	const handleAddInvitedUser = () => {
		const email = inviteUserInputRef.current.value

		//Check if email is valid
		if (email.length <= 0 || !EMAIL_REGEX.test(email)){
			createErrorToast("Invalid email")
			return
		}

		//Check if user il already invite
		if (invitedUser.includes(email)){
			createErrorToast("User already invite")
			return;
		}

		setInvitedUser([...invitedUser, email])

		//Clear input
		inviteUserInputRef.current.value = ""
	}

	//Reset new workspace data when state is false
	useEffect(() => {
		if (!state){
			setNewWorkspaceData({})
			setInvitedUser([])
		}
	}, [state]);

	if (state) {
		return createPortal(
			<div className="custom__dashboard-popup-overlay">
				<div className="container">
					<div className="popup-header">
						<h1 className="popup-title">Add new team workspace</h1>
						<p className="popup-close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
					</div>
					<div className="popup-content">
						<div className="upload-picture-container">
							<input accept="image/*" type="file" ref={uploadLogoInputRef} onChange={handleUploadLogo}/>
							<div className="picture-preview">
								{newWorkspaceData.logoPreview && (
									<img src={newWorkspaceData.logoPreview} alt="Preview"/>
								)}
							</div>
							<div className="upload-content">
								<p className="info-text">Image format with max size of 3mb</p>
								<div className="btn-container">
									<div className="btn" onClick={() => uploadLogoInputRef.current.click()}>Upload new
										logo
									</div>
									<div className="btn delete" onClick={handleRemoveLogo}>Remove</div>
								</div>
							</div>
						</div>
						<div className="input-container">
							<label>Team workspace name</label>
							<input onChange={(event) => setNewWorkspaceData({
								...newWorkspaceData,
								name: event.currentTarget.value
							})} type="text" placeholder="Team workspace name" autoFocus={true}/>
						</div>
						<div className="input-container">
							<label>Invite collaborators</label>
							<input ref={inviteUserInputRef} type="text" placeholder="Add by email" onKeyDown={(event) => event.key === "Enter" ? handleAddInvitedUser() : null}/>
						</div>
						{invitedUser.length > 0 && (
							<div className="users-list-container">
								{invitedUser.map((user) => (
									<div className="user">
										<div className="text">{user}</div>
										<div className="icon" onClick={() => setInvitedUser(invitedUser.filter(u => u !== user))}><VscChromeClose/></div>
									</div>
								))}
							</div>
						)}
					</div>
					<div className="popup-footer">
						<p className="popup-btn" onClick={() => setState(false)}>Cancel</p>
						<p className="popup-btn valid" onClick={handleCreateWorkspace}>Create workspace</p>
					</div>
				</div>
			</div>
			, document.body)
	}

	return null
}

export default CreateWorkspacePopup