import React from "react"
import {BiSolidFolder} from "react-icons/bi";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useObjectTableStore} from "../../../services/Stores/ObjectTableStore";

const ObjectTableProjectList = () => {
	const currentPanel = useObjectTableStore(state => state.currentPanel)
	const myBoardsProjects = useObjectTableStore(state => state.myBoardsProjects)

	return (
		<>
			<div className="custom__object-table-project-list-container">
				{currentPanel === "myboards" && (
					<>
						{myBoardsProjects.map((p) => (
							<div className="project">
								<div>
									<div className="icon"><BiSolidFolder/></div>
									<p className="text">{p.name}</p>
								</div>
								<div className="icon"><BsThreeDotsVertical/></div>
							</div>
						))}
					</>
				)}
			</div>
		</>
	)
}

export default ObjectTableProjectList