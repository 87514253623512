import React, {Fragment, useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import {useCookies} from "react-cookie";

const TutorialTooltip = ({sequence, handleEnd}) => {
	const [currentSequencePosition, setCurrentSequencePosition] = useState(0)

	const stylePosition = {}

	if (sequence[currentSequencePosition].top){
		stylePosition.top = sequence[currentSequencePosition].top + 'px'
	}

	if (sequence[currentSequencePosition].left){
		stylePosition.left = sequence[currentSequencePosition].left + 'px'
	}

	if (sequence[currentSequencePosition].right){
		stylePosition.right = sequence[currentSequencePosition].right + 'px'
	}

	console.log(stylePosition)

	return (
		<div className={"tutorial-tooltip-container position-" + sequence[currentSequencePosition].position + " arrow-" + sequence[currentSequencePosition].arrow}
			 style={stylePosition}>
			<div className="header">
				<p className="sequence-title">{sequence[currentSequencePosition].title !== null ? sequence[currentSequencePosition].title : currentSequencePosition + "/" + (sequence.length - 1)}</p>
				<p className="close-icon" onClick={() => handleEnd()}><VscChromeClose /></p>
			</div>
			<div className="content">
				{sequence[currentSequencePosition].content.map((content) => (
					<>
						{content.type === "title-text" && (
							<h1>{content.content}</h1>
						)}
						{content.type === "text" && (
							<div className="text-container">
								{content.icon && (
									<img src={content.icon} alt=""/>
								)}
								<p>{content.content}</p>
								{content.icon2 && (
									<img src={content.icon2} alt=""/>
								)}
							</div>
						)}
						{content.type === "subtitle-text" && (
							<h2>{content.content}</h2>
						)}
						{content.type === "image" && (
							<div className="image-container">
								<img src={content.content} alt="img"/>
							</div>
						)}
					</>
				))}
			</div>
			<div className="footer">
				<div className="previous-btn"
					 onClick={() => currentSequencePosition > 0 ? setCurrentSequencePosition(currentSequencePosition - 1) : handleEnd()}>{currentSequencePosition > 0 ? "Previous" : "Skip"}</div>
				<div className="next-btn"
					 onClick={() => currentSequencePosition < (sequence.length - 1) ? setCurrentSequencePosition(currentSequencePosition + 1) : handleEnd()}>{currentSequencePosition < (sequence.length - 1) ? "Next" : "Finish"}</div>
			</div>
		</div>
	)
}

export default TutorialTooltip