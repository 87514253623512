import {USER_CONTENT_ITEM_COMMENT_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function getAllItemComment(itemUuid){
    return getAxiosInstance().get(USER_CONTENT_ITEM_COMMENT_API + "/get_all_item_comment", {
        params: {uuid: itemUuid}
    })
}

function addComment(itemUuid, content){
    const sendData = new FormData()

    //Set required send data
    sendData.append('uuid', itemUuid)
    sendData.append('content', content)

    return getAxiosInstance().post(USER_CONTENT_ITEM_COMMENT_API + '/add', sendData)
        .then((response) => {
            if (response.status === 200){
                return response.data.uuid
            }

            return false
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function deleteComment(commentUuid){

    return getAxiosInstance().delete(USER_CONTENT_ITEM_COMMENT_API + '/delete', {params: {uuid: commentUuid}})
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

export default {
    getAllItemComment,
    addComment,
    deleteComment
}