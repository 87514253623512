import React, {useState} from "react";
import {VscChromeClose} from "react-icons/vsc";

const AdminCreateContentItemCategoryMenu = ({state, setState}) => {

    const [newCategory, setNewCategory] = useState({
        'code': "",
        'name': "",
        'description': ""
    })

    if (state){
        return (
            <>
                <div className="admin-side-menu-container">
                    <div className="header">
                        <p className="close-btn"><VscChromeClose /></p>
                        <p className="title">Create item category</p>
                    </div>

                    <div className="input-list-container">
                        <input type="text" placeholder="Code" value={newCategory.code} onChange={(event) => setNewCategory({...newCategory, code: event.currentTarget.value.toUpperCase()})}/>
                        <input type="text" placeholder="Name" value={newCategory.name} onChange={(event) => setNewCategory({...newCategory, name: event.currentTarget.value})}/>
                        <input type="text" placeholder="Description" value={newCategory.description} onChange={(event) => setNewCategory({...newCategory, description: event.currentTarget.value})}/>
                    </div>
                    <div className="footer">
                        <p className="valid-btn">Create</p>
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }

}

export default AdminCreateContentItemCategoryMenu