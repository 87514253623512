import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./i18n"
import PiwikPro from '@piwikpro/react-piwik-pro';

PiwikPro.initialize('ac557aa9-7450-4c5a-9695-60c09bc66adf', 'https://weeki.containers.piwik.pro');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);