import getAxiosInstance from "../../AxiosInstance";
import {USER_TEAM_API} from "../../../../Router";
import {createErrorToast, createLoadingToast, createSuccessToast} from "../../CustomToastUtils";
import {useToastStore} from "../../../contexts/CustomToastStore";

function getAllTeamCollaborator(){
    return getAxiosInstance().get(USER_TEAM_API + '/get_all')
}

function inviteTeamCollaborator(emails){
    const sendData = new FormData()
    sendData.append('emails', emails)

    //Create loading toast
    const loadingToastId = createLoadingToast("Invitations in progress")

    return getAxiosInstance().post(USER_TEAM_API + '/invite', sendData)
        .then((response) => {
            useToastStore.getState().removeToast(loadingToastId)
            if (response.data.emailErrors.length > 0){
                const emailErrors = response.data.emailErrors

                emailErrors.forEach(emailError => {
                    createErrorToast(emailError.email + ": " + emailError.error)
                })
            }
            if (response.data.emailSuccess.length > 0){
                const emails = response.data.emailSuccess

                emails.forEach(email => {
                    createSuccessToast(email + " as been invited in your team")
                })
            }

            return response.status === 200
        })
        .catch(() => {
            useToastStore.getState().removeToast(loadingToastId)
            return false
        })
}

function cancelTeamCollaboratorInvitation(uuid){
    const sendData = new FormData()
    sendData.append("uuid", uuid)

    return getAxiosInstance().post(USER_TEAM_API + '/cancel_invitation', sendData)
        .then((response) => {
            return response.status === 200
        })
        .catch(() => {
            return false
        })
}

export default {
    inviteTeamCollaborator,
    getAllTeamCollaborator,
    cancelTeamCollaboratorInvitation
}