import React, {useEffect, useRef, useState} from "react";
import {FaChevronLeft, FaChevronRight, FaChevronUp} from "react-icons/fa";
import {VscChromeClose} from "react-icons/vsc";
import {toast} from "react-toastify";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";
import SupportTicketsAPI from "../../services/SupportTicketsAPI";
import {TailSpin} from "react-loader-spinner";

const AdminDashboardSupport = () => {
    //Define default state for get models request status
    const [getTicketListRequestStatus, setGetTicketListRequestStatus] = useState(false)

    const [tickets, setTickets] = useState([{
        'uuid': "",
        'title': "",
        'openDate': "",
        'closeDate': null,
        'open': false,
        'lastMessageSenderType': "",
        'lastMessageDate': ""
    }])

    //Get Tickets
    useEffect(() => {
        SupportTicketsAPI.adminGetAllTickets().then((response) => {
            if (response.status === 200){
                setTickets(response.data)
            } else {
                setTickets([])
            }
            setGetTicketListRequestStatus(true)
        })
    }, [])

    /*****************************PAGINATION OPENED TICKET*****************/
    const [currentPageOpenedTicket, setCurrentPageOpenedTicket] = useState(1)
    const [itemPerPageOpenedTicket, setItemPerPageOpenedTicket] = useState(10)
    const maxPageOpenedTicket = Math.ceil(tickets.filter(t => t.open === true).length / itemPerPageOpenedTicket)

    const nextPageOpenedTicket = () => {
        setCurrentPageOpenedTicket((currentPageOpenedTicket) => Math.min(currentPageOpenedTicket + 1, maxPageOpenedTicket))
    }
    const previousPageOpenedTicket = () => {
        setCurrentPageOpenedTicket((currentPageOpenedTicket) => Math.max(currentPageOpenedTicket - 1, 1))
    }

    const startOpenedTicket = (currentPageOpenedTicket - 1) * itemPerPageOpenedTicket
    const endOpenedTicket = startOpenedTicket + itemPerPageOpenedTicket
    const paginatedOpenedTicket = tickets.filter(t => t.open === true).slice(startOpenedTicket, endOpenedTicket)

    const pageButtonsOpenedTicket = []

    for (let i = 0; i < maxPageOpenedTicket; i++){
        pageButtonsOpenedTicket.push(<p onClick={() => setCurrentPageOpenedTicket(i + 1)} className={currentPageOpenedTicket === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************PAGINATION CLOSED MODELS******************/
    const [currentPageClosedTicket, setCurrentPageClosedTicket] = useState(1)
    const [itemPerPageClosedTicket, setItemPerPageClosedTicket] = useState(10)
    const maxPageClosedTicket = Math.ceil(tickets.filter(t => t.open === false).length / itemPerPageClosedTicket)

    const nextPageClosedTicket = () => {
        setCurrentPageClosedTicket((currentPageClosedTicket) => Math.min(currentPageClosedTicket + 1, maxPageClosedTicket))
    }
    const previousPageClosedTicket = () => {
        setCurrentPageClosedTicket((currentPageClosedTicket) => Math.max(currentPageClosedTicket - 1, 1))
    }

    const startClosedTicket = (currentPageClosedTicket - 1) * itemPerPageClosedTicket
    const endClosedTicket = startClosedTicket + itemPerPageClosedTicket
    const paginatedClosedTicket = tickets.filter(t => t.open === false).slice(startClosedTicket, endClosedTicket)

    const pageButtonsClosedTicket = []

    for (let i = 0; i < maxPageClosedTicket; i++){
        pageButtonsClosedTicket.push(<p onClick={() => setCurrentPageClosedTicket(i + 1)} className={currentPageClosedTicket === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /******************************TICKET POPUP*******************************/
    const [viewTicketModalStatus, setViewTicketModalStatus] = useState(false)
    const [newResponseMessage, setNewResponseMessage] = useState("")

    //Define default state for ticket object
    const [openedTicket, setOpenedTicket] = useState({
        'uuid': "",
        'title': "",
        'openDate': "",
        'closeDate': null,
        'open': false,
        'messages': []
    })

    const openTicketPopup = (event) => {
        const ticketUuid = event.currentTarget.id

        if (ticketUuid !== ""){
            SupportTicketsAPI.adminGetTicketByUuid(ticketUuid).then((response) => setOpenedTicket(response.data))
            setViewTicketModalStatus(true)
        }
    }

    const closeTicketPopup = () => {
        setOpenedTicket({
            'uuid': "",
            'title': "",
            'openDate': "",
            'closeDate': null,
            'open': false,
            'messages': []
        })
        setViewTicketModalStatus(false)
    }

    const sendResponseToTicket = async (event) => {
        const ticketUuid = event.currentTarget.id

        if (await SupportTicketsAPI.adminSendTicketResponse(ticketUuid, newResponseMessage)){
            toast.success('Response send successfully')
            SupportTicketsAPI.adminGetTicketByUuid(ticketUuid).then((response) => setOpenedTicket(response.data))
            setNewResponseMessage("")
        }
    }

    const closeTicket = async (event) => {
        const ticketUuid = event.currentTarget.id

        if (await SupportTicketsAPI.adminCloseTicket(ticketUuid)){
            toast.success("Ticket successfully closed")
            SupportTicketsAPI.adminGetTicketByUuid(ticketUuid).then((response) => setOpenedTicket(response.data))
            setNewResponseMessage("")

            setGetTicketListRequestStatus(false)
            SupportTicketsAPI.adminGetAllTickets().then((response) => {
                if (response.status === 200){
                    setTickets(response.data)
                } else {
                    setTickets([])
                }
                setGetTicketListRequestStatus(true)
            })
        }
    }

    const reopenTicket = async (event) => {
        const ticketUuid = event.currentTarget.id

        if (await SupportTicketsAPI.adminReopenTicket(ticketUuid)){
            toast.success("Ticket successfully reopened")
            SupportTicketsAPI.adminGetTicketByUuid(ticketUuid).then((response) => setOpenedTicket(response.data))
            setNewResponseMessage("")

            setGetTicketListRequestStatus(false)
            SupportTicketsAPI.adminGetAllTickets().then((response) => {
                if (response.status === 200){
                    setTickets(response.data)
                } else {
                    setTickets([])
                }
                setGetTicketListRequestStatus(true)
            })
        }
    }
    /*************************************************************************/

    const bottomMessagesRef = useRef(null)

    useEffect(() => {
        bottomMessagesRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [openedTicket])

    const dashboard = document.getElementById("scroll-container")

    return (
        <>
            {/*VIEW TICKET MODAL*/}
            {viewTicketModalStatus && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="header">
                            <h1>Ticket: {openedTicket.title} {!openedTicket.open ? "(Closed)" : null}</h1>
                            <p onClick={closeTicketPopup}><VscChromeClose /></p>
                        </div>
                        <div className="content support-ticket">
                            <div className="message-list-container">
                                {openedTicket.messages.length > 0 && (
                                    <>
                                        {openedTicket.messages.map((message, index) => (
                                            <div key={index} className={message.senderType === "user" ? "message-container" : "message-container client"}>
                                                <p className="message">{message.message}</p>
                                                <p className="message-date">{message.senderName} - {message.date}</p>
                                            </div>
                                        ))}
                                        <div ref={bottomMessagesRef}> </div>
                                    </>
                                )}
                            </div>
                            {openedTicket.open && (
                                <div className="ticket-send-response-container">
                                    <div className="text-area-container" style={{marginTop: '1rem'}}>
                                        <div className="label-container">
                                            <p className="label">Your response</p>
                                            <p className="word-counter">{newResponseMessage.length + 1}/500</p>
                                        </div>
                                        <textarea className={newResponseMessage.length > 0 ? "active" : null} name="newTicketMessage" id="newTicketMessage" rows="3" value={newResponseMessage} onChange={(event) => event.currentTarget.value.length < 500 ? setNewResponseMessage(event.currentTarget.value) : null}/>
                                    </div>
                                </div>
                            )}
                            {openedTicket.open && (
                                <div className="footer">
                                    <button className="btn-alert" id={openedTicket.uuid} onClick={closeTicket}>Close ticket</button>
                                    <button className="btn-primary" id={openedTicket.uuid} onClick={sendResponseToTicket}>Send ticket response</button>
                                </div>
                            )}
                            {!openedTicket.open && (
                                <div className="footer">
                                    <p> </p>
                                    <button className="btn-alert" id={openedTicket.uuid} onClick={reopenTicket}>Re-open ticket</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-dashboard">
                    <h1 className="page-title">Overview</h1>
                    <div className="dashboard-table mt">
                        <p className="title">List of opened support ticket</p>
                        <table>
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Open</td>
                                    <td>Open date</td>
                                    <td>Close date</td>
                                    <td>Last response</td>
                                    <td>Last response date</td>
                                </tr>
                            </thead>
                            <tbody>
                            {getTicketListRequestStatus && (
                                <>
                                    {paginatedOpenedTicket.length > 0 && (
                                        <>
                                            {paginatedOpenedTicket.map((ticket, index) => (
                                                <tr key={index} className="clickable" id={ticket.uuid} onClick={openTicketPopup}>
                                                    <td>{ticket.title}</td>
                                                    <td>{ticket.open ? "Yes" : "No"}</td>
                                                    <td>{ticket.openDate}</td>
                                                    <td>{ticket.closeDate ? ticket.closeDate : "N/A"}</td>
                                                    <td>{ticket.lastMessageSenderType === "user" ? "User" : "Weeki support team"}</td>
                                                    <td>{ticket.lastMessageDate}</td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedOpenedTicket.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No opened ticket</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getTicketListRequestStatus && (
                                <tr>
                                    <td colSpan="100%" style={{textAlign: 'center'}}><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPageOpenedTicket(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPageOpenedTicket}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtonsOpenedTicket.length > 0 && (
                                        pageButtonsOpenedTicket
                                    )}
                                    {pageButtonsOpenedTicket.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPageOpenedTicket}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {startOpenedTicket + 1} to {endOpenedTicket} of {paginatedOpenedTicket.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of closed support ticket</p>
                        <table>
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Open</td>
                                    <td>Open date</td>
                                    <td>Close date</td>
                                    <td>Last response</td>
                                    <td>Last response date</td>
                                </tr>
                            </thead>
                            <tbody>
                            {getTicketListRequestStatus && (
                                <>
                                    {paginatedClosedTicket.length > 0 && (
                                        <>
                                            {paginatedClosedTicket.map((ticket, index) => (
                                                <tr key={index} className="clickable" id={ticket.uuid} onClick={openTicketPopup}>
                                                    <td>{ticket.title}</td>
                                                    <td>{ticket.open ? "Yes" : "No"}</td>
                                                    <td>{ticket.openDate}</td>
                                                    <td>{ticket.closeDate ? ticket.closeDate : "N/A"}</td>
                                                    <td>{ticket.lastMessageSenderType === "user" ? "User" : "Weeki support team"}</td>
                                                    <td>{ticket.lastMessageDate}</td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedClosedTicket.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No closed ticket</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getTicketListRequestStatus && (
                                <tr>
                                    <td colSpan="100%" style={{textAlign: 'center'}}><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPageClosedTicket(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPageClosedTicket}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtonsClosedTicket.length > 0 && (
                                        pageButtonsClosedTicket
                                    )}
                                    {pageButtonsClosedTicket.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPageClosedTicket}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {startClosedTicket + 1} to {endClosedTicket} of {paginatedClosedTicket.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="top-btn" onClick={() => dashboard.scrollTo(0, 0)}>
                            <p><FaChevronUp /></p>
                        </div>
                        <p className="copy">&copy; Weeki 2021</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardSupport