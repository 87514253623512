import React from 'react'
import weeki_not_found from "../../images/not_found.svg"
import {useHistory} from "react-router-dom";

const NotFound = () => {
    //Get history
    const history = useHistory()

    return (
        <div className="not-found-container">
            <img className="not_found" src={weeki_not_found} alt="Weeki not found"/>
            <h1>Page not found</h1>
            <p>Sorry we can't find the page you're looking for</p>
            <button className="btn-black" onClick={() => history.push('/')}>Back to home</button>
        </div>

    )
}

export default NotFound