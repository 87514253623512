import React, {Fragment, useEffect, useState} from "react";
import {FaChevronDown, FaChevronUp, FaLayerGroup} from "react-icons/fa";
import {MdEdit} from "react-icons/md";
import {BsTrash} from "react-icons/bs";
import {IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";
import {IoIosInformationCircleOutline} from "react-icons/io";
import {useMapStore} from "../../../services/Stores";
import {useCookies} from "react-cookie";
import OverlayAPI from "../../../services/OverlayAPI";
import {createErrorToast} from "../../../services/CustomToastUtils";
import Skeleton from "react-loading-skeleton";
import AreaRow from "./AreaRow";
import OverlayConfirmDeletePopup from "../../Map/OverlayConfirmDeletePopup";
import ShapeItemAPI from "../../../services/API/User/ShapeItemAPI";
import ShapeRow from "./ShapeRow";
import TextItemAPI from "../../../services/API/User/TextItemAPI";
import TextItemRow from "./TextItemRow";

const OverlayRow = ({overlay, publicMap = false}) => {
	//Get react cookie
	const [cookies, setCookie] = useCookies(['maps-default-overlay'])

	//Get current selected overlay in map store
	const currentOverlay = useMapStore(state => state.currentOverlay)

	//Define default state for overlay name update status and for new overlay name
	const [nameUpdateState, setNameUpdateState] = useState(false)

	//Define default state for overlay open state and for area loading state
	const [openState, setOpenState] = useState(false)
	const [areaLoadingState, setAreaLoadingState] = useState(false)
	const [shapeLoadingState, setShapeLoadingState] = useState(false)

	//Update selected overlay in map store and in cookie
	const updateSelectedOverlay = () => {
		//Return if map is public
		if (publicMap){
			return
		}

		//Update current overlay in store
		useMapStore.setState({currentOverlay: overlay.uuid})

		//Update map overlay in cookie
		const cookieValue = {}
		cookieValue[useMapStore.getState().mapUuid] = overlay.uuid
		setCookie('maps-default-overlay', cookieValue)
	}

	//Update overlay name
	const updateOverlayName = async (name) => {
		//Check if name length is valid
		if (name.length < 1){
			createErrorToast("Overlay name is not valid")
			return
		}

		if (await OverlayAPI.updateOverlayName(overlay.uuid, name)){
			//Update overlay name in map store
			useMapStore.getState().updateOverlayName(overlay.uuid, name)
		}

		setNameUpdateState(false)
	}

	//Delete overlay
	const [deleteOverlayPopupState, setDeleteOverlayPopupState] = useState(false)
	// const deleteOverlay = async () => {
	// 	//Return if map is public
	// 	if (publicMap) {
	// 		return
	// 	}
	//
	// 	if (await OverlayAPI.deleteOverlay(overlay.uuid)) {
	// 		//Remove overlay in map store
	// 		useMapStore.getState().removeOverlay(overlay.uuid)
	// 	}
	// }

	const handleDropArea = (event) => {

	}

	//Define default state for overlay shapes and areas
	const [areas, setAreas] = useState([])
	const [shapes, setShapes] = useState([])
	const [textItems, setTextItems] = useState([])

	//Get overlay areas on open
	useEffect(() => {
		if (openState){
			setAreaLoadingState(true)

			OverlayAPI.getOverlayAreas(overlay.uuid).then((response) => {
				setAreaLoadingState(false)
				if (response.status === 200){
					setAreas(response.data)
				}
			})

			ShapeItemAPI.getOverlayShapeItems(overlay.uuid).then((response) => {
				setShapeLoadingState(true)
				if (response.status === 200){
					setShapes(response.data)
				}
			})

			TextItemAPI.userGetOverlayTextItems(overlay.uuid).then((response) => {
				if (response.status === 200){
					setTextItems(response.data)
				}
			})

		} else {
			setAreaLoadingState(false)
			setShapeLoadingState(false)
		}
	}, [openState])

	const handleToggleOverlayState = () => {
		if (overlay.active){
			//Disable overlay
			useMapStore.getState().updateOverlayState(overlay.uuid, false)
		} else {
			//Enable overlay
			useMapStore.getState().updateOverlayState(overlay.uuid, true)
		}
	}

	return (
		<>
			{/*CONFIRM OVERLAY DELETE POPUP*/}
			{deleteOverlayPopupState && (
				<>
					<OverlayConfirmDeletePopup overlay={overlay} popupState={deleteOverlayPopupState} setPopupState={setDeleteOverlayPopupState} />
				</>
			)}
			{/*COMPONENT RENDER*/}
			<div className="overlay-row-container">
				<div className="overlay" onDrop={!publicMap ? handleDropArea : null} id={overlay.uuid} onDragOver={(event) => event.preventDefault()}>
					<div className="left">
						{!publicMap && (
							<div onClick={() => currentOverlay !== overlay.uuid  ? updateSelectedOverlay(overlay.uuid) : null} className={currentOverlay === overlay.uuid ? "custom-round-checkbox selected" : "custom-round-checkbox"}>
								<span className="custom-round-checkbox-indicator"></span>
							</div>
						)}
						<p className="overlay-icon"><FaLayerGroup/></p>
						{nameUpdateState && (
							<input type="text"
								   onKeyDown={(event) => event.key === "Enter" ? updateOverlayName(event.currentTarget.value) : null}
								   onBlur={(event) => updateOverlayName(event.currentTarget.value)}
								   placeholder="Overlay name"
								   defaultValue={overlay.name} />
						)}
						{!nameUpdateState && (
							<p className="overlay-name" style={!overlay.active ? {textDecoration: "line-through"} : null}>{overlay.name}</p>
						)}
						<p className="open-status-icon" onClick={() => setOpenState(!openState)}>
							{openState
								? <FaChevronDown />
								: <FaChevronUp />
							}
						</p>
					</div>
					{!nameUpdateState && (
						<div className="right">
							{!publicMap && (
								<>
									<div className="icon-button">
										<p className="icon" onClick={() => setNameUpdateState(true)}><MdEdit/></p>
										<p className="tooltip">Edit name</p>
									</div>
									{currentOverlay !== overlay.uuid && (
										<div className="icon-button">
											<p className="icon" onClick={() => setDeleteOverlayPopupState(true)}><BsTrash/></p>
											<p className="tooltip">Delete</p>
										</div>
									)}
								</>
							)}
							<div className="icon-button" onClick={handleToggleOverlayState}>
								<p className="icon">{overlay.active ? <IoEyeOutline/> : <IoEyeOffOutline />}</p>
								<p className="tooltip">{overlay.active ? "Hide" : "Show"}</p>
							</div>
						</div>
					)}
				</div>
				{openState && (
					<>
						{areaLoadingState && (
							<Skeleton height={25} count={overlay.areasCount} style={{marginBottom: "8px"}}/>
						)}
						{!areaLoadingState && (
							<div className="overlay-areas-container">
								<div className="areas-list">
									{areas.length > 0 && (
										<>
											{areas.map((area, index) => (
												<Fragment key={index}>
													<AreaRow overlay={overlay} area={area} position={0} />
												</Fragment>
											))}
										</>
									)}
									{shapes.length > 0 && (
										<>
											{shapes.map((shape) => (
												<ShapeRow overlay={overlay} shape={shape} position={0} />
											))}
										</>
									)}
									{textItems.length > 0 && (
										<>
											{textItems.map((ti) => (
												<TextItemRow overlay={overlay} textItem={ti} position={0} />
											))}
										</>
									)}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</>
	)
}

export default OverlayRow