import React, {useEffect} from "react";
import {useObjectTableStore} from "../../../../services/Stores/ObjectTableStore";
import {HiChevronDown, HiChevronRight} from "react-icons/hi";
import {ReactComponent as MyBoardsIcon} from "../../../../../icons/sidebar/my-boards.svg";
import OverlayAPI from "../../../../services/OverlayAPI";

const MapLine = ({ m, w = null, p = null, panel }) => {
	const currentSelectedMap = useObjectTableStore((state) => state.currentSelectedMap);
	const currentSelectedOverlay = useObjectTableStore((state) => state.currentSelectedOverlay);

	//Get my board project map
	const handleGetMapOverlays = () => {
		OverlayAPI.getMapOverlays(m.uuid).then((response) => {
			if (response.status === 200) {
				if (panel === "workspace") {
					useObjectTableStore
						.getState()
						.updateWorkspaceProjectMapOverlays(w.uuid, p.uuid, m.uuid, response.data);
				} else if (panel === "myboard") {
					useObjectTableStore.getState().updateMyBoardsMapOverlays(p.uuid, m.uuid, response.data);
				} else if (panel === "shared") {
					useObjectTableStore.getState().updateSharedMapOverlays(m.uuid, response.data);
				}
			} else {
				if (panel === "workspace") {
					useObjectTableStore.getState().updateWorkspaceProjectMapOverlays(w.uuid, p.uuid, m.uuid, []);
				} else if (panel === "myboard") {
					useObjectTableStore.getState().updateMyBoardsMapOverlays(p.uuid, m.uuid, []);
				} else if (panel === "shared") {
					useObjectTableStore.getState().updateSharedMapOverlays(m.uuid, []);
				}
			}
		});
	};

	useEffect(() => {
		if (m.open) {
			handleGetMapOverlays();
		}
	}, [m.open]);

	const handleOpenMap = (event) => {
		event.stopPropagation();

		useObjectTableStore.setState({
			currentSelectedMap: m.uuid,
			currentSelectedOverlay: "",
			currentSelectedArea: "",
		});

		if (panel === "workspace") {
			useObjectTableStore.getState().updateWorkspaceMapOpenState(w.uuid, p.uuid, m.uuid, true);
		} else if (panel === "myboard") {
			useObjectTableStore.getState().updateMyBoardsMapOpenState(p.uuid, m.uuid, true);
		} else if (panel === "shared") {
			useObjectTableStore.getState().updateSharedMapOpenState(m.uuid, true);
		}
		handleGetMapOverlays();
	};

	const handleToggleMap = (event) => {
		event.stopPropagation();

		// useObjectTableStore.setState({currentSelectedMap: m.uuid, currentSelectedOverlay: '', currentSelectedArea: ''})

		if (panel === "workspace") {
			useObjectTableStore.getState().updateWorkspaceMapOpenState(w.uuid, p.uuid, m.uuid, !m.open);
		} else if (panel === "myboard") {
			useObjectTableStore.getState().updateMyBoardsMapOpenState(p.uuid, m.uuid, !m.open);
		} else if (panel === "shared") {
			useObjectTableStore.getState().updateSharedMapOpenState(m.uuid, !m.open);
		}
		handleGetMapOverlays();
	};

	return (
		<>
			<div
				className={
					"open-line " + (currentSelectedMap === m.uuid && currentSelectedOverlay === "" ? "active" : "")
				}
				onClick={handleOpenMap}
			>
				<div className="open-icon" onClick={handleToggleMap}>
					{m.open ? <HiChevronDown size={20} /> : <HiChevronRight size={20} />}
				</div>
				<div className="line-icon">
					<MyBoardsIcon />
				</div>
				<p className="line-text">{m.name}</p>
			</div>
		</>
	);
};

export default MapLine;
