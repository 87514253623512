import getAxiosInstance from "../../AxiosInstance";
import {USER_INVITATION_API} from "../../../../Router";

function userGetAllEntityInvitations(entityUuid, entityType, defaultInviteType = null){
	const requestParams = {
		entityUuid: entityUuid,
		entityType: entityType,
	}

	if (defaultInviteType){
		requestParams.invitationType = defaultInviteType
	}

	return getAxiosInstance().get(USER_INVITATION_API + '/get_all_invitations', {params: requestParams})
}

function userInviteUser(entityType, entityUuid, invitationType, userEmail){
	const sendData = new FormData()
	sendData.append('entityType', entityType)
	sendData.append('entityUuid', entityUuid)
	sendData.append('invitationType', invitationType)
	sendData.append('userEmail', userEmail)

	return getAxiosInstance().post(USER_INVITATION_API + '/invite_user', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.uuid
			}

			return false
		})
		.catch(() => false)
}

function userRevokeUser(invitationUuid){
	const sendData = new FormData()
	sendData.append('invitationUuid', invitationUuid)

	return getAxiosInstance().post(USER_INVITATION_API + '/revoke_user', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userAcceptInvitation(invitationUuid){
	const sendData = new FormData()
	sendData.append('invitationUuid', invitationUuid)

	return getAxiosInstance().post(USER_INVITATION_API + '/accept', sendData)
		.then((response) => {
			if (response.data.mapUuid){
				window.location.href = "/user/map/" + response.data.mapUuid
			}
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	userGetAllEntityInvitations,
	userInviteUser,
	userAcceptInvitation,
	userRevokeUser
}