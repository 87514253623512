import {useMapZoneStore} from "../Stores/MapZoneStore";
import {convertZoneBoundsToLocation, getMockBounds, getMockHeight, getMockWidth, updateZoneLocation} from "./ZoneUtils";
import AreaAPI from "../AreaAPI";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import {closeZoneSelectedMenu, generateZoneSelectedMenu} from "./MapSelectedMenusUtils";
import ShapeItemAPI from "../API/User/ShapeItemAPI";
import {useMapShapeItemStore} from "../Stores/MapShapeItemStore";
import {updateShapeLocation} from "./ShapeItemUtils";
import {useMapStore} from "../Stores";

export function addZoneMockEvents(mock){
	//Get start mock bounds position
	const startBounds = getMockBounds(mock)

	//Enable mock drag
	mock.pm.enableLayerDrag()

	//Add drag start event
	dragStartMockEvent(mock)


	//Add event for mock drag end for valid area move
	mock.on("pm:dragend", async (layer, shape) => {
		//Get new mock location
		const newCenterPoint =  L.Projection.SphericalMercator.project(mock.getCenter())
		const newWidth = getMockWidth(mock)
		const newHeight = getMockHeight(mock)

		//Send api request for move area
		await AreaAPI.moveArea(useMapZoneStore.getState().hoveredAreaUuid, newCenterPoint)

		//Reset store data and remove mock
		mock.remove()
		useMapZoneStore.setState({
			hoveredAreaUuid: null,
			hoveredAreaMock: null,
			zoneCurrentDragState: false
		})
	})
}

export function addZoneSelectedMockEvents(mock, properties){
	//Get start mock bounds position
	const startBounds = getMockBounds(mock)

	//Enable mock drag and edit
	mock.pm.enableLayerDrag()
	mock.pm.enable({draggable: true})

	//Add drag start event
	dragStartMockEvent(mock)

	//Add drag end event
	mock.on("pm:dragend", async (layer, shape) => {
		//Enable edit mode
		mock.pm.enable()

		//Generate selected menu
		generateZoneSelectedMenu(mock, properties, false)

		//Get new mock location
		const newCenterPoint =  L.Projection.SphericalMercator.project(mock.getCenter())
		const newWidth = getMockWidth(mock)
		const newHeight = getMockHeight(mock)

		//Send api request for move area
		await AreaAPI.moveArea(properties.id, newCenterPoint)

		//Set current drag state
		useMapZoneStore.setState({zoneCurrentDragState: false})
	})

	mock.on('pm:markerdragstart', () => {
		//Set current drag state
		useMapZoneStore.setState({zoneCurrentDragState: true})

		closeZoneSelectedMenu()
	})

	//Add update event
	mock.on('pm:markerdragend', async () => {
		const newBounds = getMockBounds(mock)

		//Generate selected menu
		generateZoneSelectedMenu(mock, properties, false)

		//Get new mock location
		const newCenterPoint =  L.Projection.SphericalMercator.project(mock.getCenter())
		const newWidth = getMockWidth(mock)
		const newHeight = getMockHeight(mock)

		//Send api request for move area
		await AreaAPI.resizeArea(properties.id, newCenterPoint, newWidth, newHeight)

		//Request new tiles for start and new area bounds
		updateZoneLocation(newBounds, startBounds)

		//Set current drag state
		useMapZoneStore.setState({zoneCurrentDragState: false})
	})
}

function dragStartMockEvent(mock){
	mock.on("pm:dragstart", (layer, shape) => {
		//Remove all corner marker when drag start if edit mode is enabled
		if (mock.pm.enabled()){
			mock.pm['_cornerMarkers'].forEach(m => m.remove())
		}

		closeZoneSelectedMenu()

		//Set current drag state
		useMapZoneStore.setState({zoneCurrentDragState: true})

		//Update mock border color
		mock.setStyle({color: "#3388ff"})
	})
}

export function addShapeMockEvent(mock, shapeType){
	//Get start mock bounds position
	const startBounds = getMockBounds(mock)

	//Disable mock middle marker for line and rectangle
	if (shapeType === "line" || shapeType === "rectangle"){
		mock.pm._createMiddleMarker = () => {}
	}

	//Enable mock drag and edit
	mock.pm.enableLayerDrag()
	mock.pm.enable({draggable: true})

	//Add drag start event
	mock.on('pm:dragstart', () => {
		//Update drag state in store
		useMapShapeItemStore.setState({currentDragState: true})

		//Remove all marker when drag start if edit mode is enabled
		if (mock.pm.enabled()){
			mock.pm['markerCache'].forEach(m => m.remove())
		}
	})

	//Add drag end event
	mock.on("pm:dragend", async (layer, shape) => {
		//Enable edit mode
		mock.pm.enable()

		const latLngList = layer.layer._latlngs.length === 1 ? layer.layer._latlngs[0] : layer.layer._latlngs

		//Get new shape point
		const points = []
		latLngList.forEach(pos => {
			points.push(L.Projection.SphericalMercator.project(pos))
		})

		//Update shape in api
		ShapeItemAPI.updateShapeItem(points, useMapShapeItemStore.getState().currentSelectedShape)

		//Update shape tiles
		updateShapeLocation(getMockBounds(mock), startBounds)

		//Update drag state in store
		useMapShapeItemStore.setState({currentDragState: false})
	})

	mock.on('pm:markerdragstart', () => {
		//Set current drag state
		useMapShapeItemStore.setState({currentDragState: true})
	})

	//Add marker drag end
	mock.on('pm:markerdragend', async (layer) => {
		//Enable edit mode
		mock.pm.enable()

		if (shapeType !== "circle"){
			const latLngList = layer.layer._latlngs.length === 1 ? layer.layer._latlngs[0] : layer.layer._latlngs

			//Get new shape point
			const points = []
			latLngList.forEach(pos => {
				points.push(L.Projection.SphericalMercator.project(pos))
			})

			//Update shape in api
			ShapeItemAPI.updateShapeItem(points, useMapShapeItemStore.getState().currentSelectedShape)

			//Update shape tiles
			updateShapeLocation(getMockBounds(mock), startBounds)

			//Update drag state in store
			useMapShapeItemStore.setState({currentDragState: false})
		} else {
			//Get center point
			const center = L.Projection.SphericalMercator.project(layer.layer._latlng)

			//Get radius
			const radius = layer.layer._mRadius

			//Update circle in API
			ShapeItemAPI.updateCircleShapeItem(center, useMapShapeItemStore.getState().currentSelectedShape, radius)

			//Update shape tiles
			updateShapeLocation(getMockBounds(mock), startBounds)

			//Update drag state in store
			useMapShapeItemStore.setState({currentDragState: false})
		}
	})

}