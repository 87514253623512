import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {useMapZoneStore} from "../../services/Stores/MapZoneStore";
import UserAreaAPI from "../../services/API/User/UserAreaAPI";
import AreaAPI from "../../services/AreaAPI";
import {deleteZone} from "../../services/Utils/ZoneUtils";
import OverlayAPI from "../../services/OverlayAPI";
import {useMapStore} from "../../services/Stores";
import {createSuccessToast} from "../../services/CustomToastUtils";

const OverlayConfirmDeletePopup = ({popupState, setPopupState, overlay}) => {

	const handleDeleteOverlay = async (withContent) => {
		if (await OverlayAPI.deleteOverlay(overlay.uuid, withContent)) {
			//Remove overlay in store
			useMapStore.getState().removeOverlay(overlay.uuid)

			//Close popup
			setPopupState(false)

			createSuccessToast("Overlay successfully deleted")
		}
	}

	const handleCancelDelete = () => {
		setPopupState(false)
	}

	if (popupState){
		return (createPortal(
				<div className="custom__confirm-delete-popup-overlay">
					<div className="container">
						<div className="header">
							<p className="title">Delete overlay "{overlay.name}"</p>
							<p className="icon" onClick={() => handleCancelDelete()}><VscChromeClose/></p>
						</div>
						<div className="content">
							<p className="text mb">Please confirm overlay delete</p>
						</div>
						<div className="footer">
							<div className="btn invert" onClick={() => handleDeleteOverlay(false)}>Delete overlay</div>
							<div className="btn" onClick={() => handleDeleteOverlay(true)}>Delete with content</div>
						</div>
					</div>
				</div>, document.body)
		)
	} else {
		return null
	}

}

export default OverlayConfirmDeletePopup