import React, {forwardRef, useEffect} from "react";
import {useMapContextMenuStore} from "../../../services/Stores/MapContextMenuStore";
import {createPortal} from "react-dom";

const ContextMenuContainer = forwardRef(({children, state, setState, map = false}, ref) => {
	useEffect(
		() => {
			const listener = (event) => {
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}

				setState(false)
			};

			window.addEventListener("click", listener);
			window.addEventListener("touchstart", listener);
			return () => {
				window.removeEventListener("click", listener);
				window.removeEventListener("touchstart", listener);
			};
		},
		[ref]
	);

	//Define style
	const style = {
		display: state ? "block" : "none"
	}

	if (map){
		style.top = useMapContextMenuStore.getState().position.y + "px"
		style.left = useMapContextMenuStore.getState().position.x + "px"
	}

	return createPortal(<div ref={ref} className="custom__content-menu-container" style={style}>{children}</div>, document.body)
})

export default ContextMenuContainer