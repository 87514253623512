import React, {useRef, useState} from "react";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {getContextMenuPosition} from "../../../services/Utils";
import ContextMenuItem from "../content_menu/ContextMenuItem";
import {MdDelete, MdOutlineDriveFileRenameOutline} from "react-icons/md";
import ContextMenuContainer from "../content_menu/ContextMenuContainer";
import ConfirmDeletePopup from "../popups/ConfirmDeletePopup";
import MapCard from "./MapCard";
import Skeleton from "react-loading-skeleton";
import {IoMdAdd} from "react-icons/io";
import {IoAdd} from "react-icons/io5";
import CreateMapPopup from "../dashboard-popups/CreateMapPopup";
import UserMapAPI from "../../../services/API/User/UserMapAPI";
import RenameMapPopup from "../dashboard-popups/RenameMapPopup";

const MapsListContainer = () => {
	//Get current project maps in store
	const currentProjectMaps = useDashboardStore(state => state.currentProjectMaps)
	const currentOpenedProject = useDashboardStore(state => state.currentOpenedProject)
	const currentOpenedWorkspace = useDashboardStore(state => state.currentOpenedWorkspace)

	const contextMenuRef = useRef(null)
	const [createMapPopupState, setCreateMapPopupState] = useState(false)
	const [contextMenuState, setContextMenuState] = useState(false)
	const [confirmDeletePopupState, setConfirmDeletePopupState] = useState(false)
	const [renameMapPopupState, setRenameMapPopupState] = useState(false)

	//Open project context menu
	const handleOpenContextMenu = (event, map) => {
		event.stopPropagation()
		event.preventDefault()

		useDashboardStore.setState({currentContextMenuMap: map})
		setContextMenuState(true)
		getContextMenuPosition(event, contextMenuRef.current)
	}

	//Delete map
	const handleDeleteMap = async () => {
		if (await UserMapAPI.userDeleteMap(useDashboardStore.getState().currentContextMenuMap.uuid)) {
			useDashboardStore.getState().removeMap(useDashboardStore.getState().currentContextMenuMap.uuid)
		}
	}

	return (
		<>
			{/*MAP CONTEXT MENU*/}
			<ContextMenuContainer ref={contextMenuRef} state={contextMenuState} setState={setContextMenuState}>
				<ContextMenuItem handler={() => {
					setContextMenuState(false)
					setRenameMapPopupState(true)
				}} text="Rename map" icon={<MdOutlineDriveFileRenameOutline />}  />
				<ContextMenuItem handler={() => {
					setConfirmDeletePopupState(true)
					setContextMenuState(false)
				}} text="Delete map" icon={<MdDelete />}  />
			</ContextMenuContainer>
			<ConfirmDeletePopup state={confirmDeletePopupState} setState={setConfirmDeletePopupState} handler={handleDeleteMap} deleteType="map" children={null} />
			<RenameMapPopup state={renameMapPopupState} setState={setRenameMapPopupState} />
			<CreateMapPopup state={createMapPopupState} setState={setCreateMapPopupState} />
			<div className="custom__maps-list-container">
				{currentProjectMaps !== null && (
					<>
						{((currentOpenedWorkspace && currentOpenedWorkspace.userAuthorizations.includes('workspace.create_map')) || (!currentOpenedWorkspace && currentOpenedProject && currentOpenedProject.userAuthorizations.includes('project.create_map')))  && (
							<div className="create-map-card" onClick={() => setCreateMapPopupState(true)}>
								<div className="icon"><IoAdd/></div>
								<p className="text">New board</p>
							</div>
						)}
						{currentProjectMaps.length > 0 && (
							<>
								{currentProjectMaps.map((m) => (
									<MapCard map={m} handleOpenContextMenu={handleOpenContextMenu}/>
								))}
							</>
						)}
					</>
				)}
			</div>
			{currentProjectMaps === null && (
				<>
					<Skeleton count={currentOpenedProject !== null ? currentOpenedProject.mapsCount : 10} containerClassName="project-list-skeleton-container" width={382.25} height={235} borderRadius={12} />
				</>
			)}

		</>
	)
}

export default MapsListContainer