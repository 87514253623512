import React, {useEffect, useRef, useState} from "react";
import {IoSearch} from "react-icons/io5";
import CustomTooltip from "../CustomTooltip";
import {BsEnvelope} from "react-icons/bs";
import {VscChromeClose} from "react-icons/vsc";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {EMAIL_REGEX} from "../../../../Regex";
import {createErrorToast} from "../../../services/CustomToastUtils";
import UserWorkspaceAPI from "../../../services/API/User/UserWorkspaceAPI";
import {IoMdSettings} from "react-icons/io";
import ManageWorkspaceAuthorizationPopup from "../dashboard-popups/ManageWorkspaceAuthorizationPopup";
import {useUserStore} from "../../../services/Stores";
import UserInvitationAPI from "../../../services/API/User/UserInvitationAPI";
import {MdAddAPhoto} from "react-icons/md";
import {MAX_WORKSPACE_LOGO_SIZE_MO} from "../../../../App";

const WorkspaceManager = () => {
	//Get data in store
	const currentOpenedWorkspace = useDashboardStore(state => state.currentOpenedWorkspace)

	//Set default page state
	const [workspaceUserInvitations, setWorkspaceUserInvitations] = useState([])
	const [workspaceAvailableAuthorizations, setWorkspaceAvailableAuthorization] = useState([])
	const inviteUserInputRef = useRef(null)
	const [manageWorkspaceAuthorizationPopupState, setManageWorkspaceAuthorizationPopupState] = useState(false)
	const [currentSelectedInvitation, setCurrentSelectedInvitation] = useState(null)

	//Get all workspace invitation
	useEffect(() => {
		UserWorkspaceAPI.userGetAllWorkspaceInvitations(currentOpenedWorkspace.uuid).then((response) => {
			if (response.status === 200){
				setWorkspaceUserInvitations(response.data.invitations)
				setWorkspaceAvailableAuthorization(response.data.availableAuthorizations)
			}
		})
	}, []);

	//Invite user in workspace
	const [invitationTypeSelector, setInvitationTypeSelector] = useState('viewer')
	const handleInviteUser = async () => {
		const value = inviteUserInputRef.current.value

		//Check email address
		if (!EMAIL_REGEX.test(value)) {
			createErrorToast("Invalid email address")
			return
		}

		if (invitationTypeSelector !== "viewer" && invitationTypeSelector !== "editor"){
			createErrorToast("Invalid invitation type")
			return
		}

		const uuid = await UserInvitationAPI.userInviteUser('workspace', currentOpenedWorkspace.uuid, invitationTypeSelector, value)

		//Add new user invitation to list
		if (uuid) {
			setWorkspaceUserInvitations([...workspaceUserInvitations, {'uuid': uuid, 'state': 'pending', 'userEmail': value}])

			inviteUserInputRef.current.value = ""
		}
	}

	//Revoke user workspace authorization
	const handleRevokeUser = async (invitationUuid) => {
		if (await UserWorkspaceAPI.userRevokeUser(currentOpenedWorkspace.uuid, invitationUuid)){
			setWorkspaceUserInvitations(workspaceUserInvitations.filter(i => i.uuid !== invitationUuid))
		}
	}

	const handleManageWorkspaceAuthorization = (invitation) => {
		setCurrentSelectedInvitation(invitation)
		setManageWorkspaceAuthorizationPopupState(true)
	}

	//Update workspace logo
	const logoInputRef = useRef(null)
	const handleUpdateLogo = () => {
		//Get input first files
		const file = logoInputRef.current.files[0]

		//Check if file exist
		if (file){
			//Check file size
			const fileSize = (file.size / 1024).toFixed(2)
			if (fileSize > (MAX_WORKSPACE_LOGO_SIZE_MO * 1024).toFixed(2)){
				createErrorToast("The file size must not exceed 4 MB")
				return;
			}

			//Check if file type is valid
			if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg"){
				createErrorToast("Image format invalid")
				return
			}

			//Create image preview
			const imagePreview = URL.createObjectURL(file)

			//Update workspace logo in store
			useDashboardStore.getState().updateWorkspaceLogo(currentOpenedWorkspace.uuid, imagePreview)

			//Update workspace logo in api
			UserWorkspaceAPI.userUpdateWorkspace(currentOpenedWorkspace.uuid, {logo: file})
		}

		//Reset input value
		logoInputRef.current.value = ""
	}

	//Update workspace name
	const [updateWorkspaceNameState, setUpdateWorkspaceNameState] = useState(false)
	const handleUpdateWorkspaceName = (event) => {
		const name = event.currentTarget.value

		//Check if name is valid
		if (name.length <= 0){
			createErrorToast("Workspace name can't be empty")
			return
		}

		//Check if name has been change
		if (name === currentOpenedWorkspace.name){
			setUpdateWorkspaceNameState(false)
			return;
		}

		//Update name in store
		useDashboardStore.getState().updateWorkspaceName(currentOpenedWorkspace.uuid, name)

		//Close update state
		setUpdateWorkspaceNameState(false)

		//Update name in api
		UserWorkspaceAPI.userUpdateWorkspace(currentOpenedWorkspace.uuid, {name: name})
	}

	return (
		<>
			<ManageWorkspaceAuthorizationPopup state={manageWorkspaceAuthorizationPopupState} setState={setManageWorkspaceAuthorizationPopupState} handler={() => null} currentInvitation={currentSelectedInvitation} availableAuthorizations={workspaceAvailableAuthorizations}/>
			<div className="workspace-manage-container">
				<div className="header">
					<div className="workspace-logo">
						{currentOpenedWorkspace.logo && (
							<img src={currentOpenedWorkspace.logo} alt="Workspace logo"/>
						)}
						<div className="update-logo-overlay" onClick={() => logoInputRef.current.click()}>
							<MdAddAPhoto />
							<input type="file" ref={logoInputRef} onChange={handleUpdateLogo}/>
						</div>
					</div>
					<div className="workspace-infos">
						<p className="name" onClick={() => !updateWorkspaceNameState ? setUpdateWorkspaceNameState(true) : null}>
							{updateWorkspaceNameState
								? <input onBlur={handleUpdateWorkspaceName} onKeyDown={(event) => event.key === "Enter" ? handleUpdateWorkspaceName(event) : null} type="text" placeholder="Workspace name"
										 defaultValue={currentOpenedWorkspace.name} autoFocus={true}/>
								: currentOpenedWorkspace.name
							}
						</p>

						<p className="sub-text">Manage your workspace</p>
					</div>
				</div>
				<div className="search-input-container">
					<p className="icon"><IoSearch/></p>
					<input onKeyDown={(event) => event.key === "Enter" ? handleInviteUser() : null} type="text" placeholder="Invite user by email" ref={inviteUserInputRef}/>
					<select className="input-selector" value={invitationTypeSelector} onChange={(event) => setInvitationTypeSelector(event.currentTarget.value)}>
						<option value="viewer">Viewer</option>
						<option value="editor">Editor</option>
					</select>
				</div>
				<table>
					<thead>
						<tr>
							<td>Email</td>
							<td>Invitation state</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						{workspaceUserInvitations.map((i) => (
							<tr>
								<td>{i.userEmail}</td>
								<td><span className={"state-indicator " + i.state}>{i.owner ? 'owner' : i.state}</span></td>
								<td>
									{(i.userEmail !== useUserStore.getState().user.email && !i.owner) && (
										<>
										{(i.state === "pending" || i.state === "refused") && (
											<span className="cell-icon-btn custom__tooltip-container"><CustomTooltip
												text="Resend invitation"/><BsEnvelope/></span>
										)}
										{i.state === "accepted" && (
											<span className="cell-icon-btn custom__tooltip-container" onClick={() => handleManageWorkspaceAuthorization(i)}><CustomTooltip
												text="Manage user permission"/><IoMdSettings /></span>
										)}
										<span
											className="cell-icon-btn custom__tooltip-container"
											onClick={() => handleRevokeUser(i.uuid)}><CustomTooltip
											text="Remove from this workspace"/><VscChromeClose/></span>

										</>
									)}
								</td>
							</tr>
						))}
						{workspaceUserInvitations.length <= 0 && (
							<tr>
								<td colSpan={3} style={{textAlign: "center"}}>No invited user in workspace</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</>
	)
}

export default WorkspaceManager