import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import React from "react";
import TangramLayerUtils from "../TangramLayerUtils";
import ZoneSelectedMenu from "../../components/Map/SelectedMenus/ZoneSelectedMenu";
import {useMapStore} from "../Stores";
import {useMapZoneStore} from "../Stores/MapZoneStore";
import {
	addItemSelectedMenuEvents,
	addTextSelectedMenuEvents,
	addZoneSelectedMenuEvents
} from "./MapSelectedMenusEventsUtils";
import {useContentItemStore} from "../Stores/ContentItemStore";
import SelectedObjectMenu from "../../components/Map/ObjectPopup/SelectedObjectMenu";
import ContentItemSelectedMenu from "../../components/Map/SelectedMenus/ContentItemSelectedMenu";
import {useTextItemStore} from "../Stores/TextItemStore";
import TextItemSelectedMenu from "../../components/Map/SelectedMenus/TextItemSelectedMenu";

/**
 * Generate selected menu for zone
 * @param mock
 * @param properties
 * @param rename
 */
export function generateZoneSelectedMenu(mock, properties, rename = false) {
	const selectedMenuPopup =  L.popup({
		autoPan: false,
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		minWidth: 0,
		offset: L.point(0,-10)
	}).setLatLng(mock.getBounds().getNorthWest()).setContent(ReactDOMServer.renderToString(<ZoneSelectedMenu properties={properties} rename={rename} />))

	//Add popup to map
	selectedMenuPopup.addTo(useMapStore.getState().mapInstance)

	//Add popup to store
	useMapZoneStore.setState({currentSelectedPopup: selectedMenuPopup})

	//Add selected menu events
	addZoneSelectedMenuEvents(selectedMenuPopup, rename)
}

/**
 * Close zone selected menu
 */
export function closeZoneSelectedMenu(){
	if (useMapZoneStore.getState().currentSelectedPopup !== null){
		useMapZoneStore.getState().currentSelectedPopup.remove()
		useMapZoneStore.setState({currentSelectedPopup: null})
	}
}

/**
 * Create and render selected menu for content item
 * @param popup
 * @param properties
 */
export function generateContentItemSelectedMenu(popup, properties){
	//Check if selected popup already exist and remove id is true
	if (useContentItemStore.getState().selectedItemPopup !== null){
		useContentItemStore.getState().selectedItemPopup.remove()
		useContentItemStore.setState({selectedItemPopup: null})
	}

	const selectedMenuPopup = L.popup({
		autoPan: false,
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		minWidth: 0,
		offset: L.point(-(popup._contentNode.offsetWidth / 2), (-(popup._contentNode.offsetHeight / 2) -10))
		// offset: L.point(-(popup._contentNode.offsetWidth / 2), -(popup._contentNode.offsetHeight + 13))
	})
		.setLatLng(popup._latlng)
		.setContent(ReactDOMServer.renderToString(<ContentItemSelectedMenu properties={properties} />))

	//Add popup to map
	selectedMenuPopup.addTo(useMapStore.getState().mapInstance)

	//Add popup to store
	useContentItemStore.setState({selectedItemPopup: selectedMenuPopup})

	//Add event for popup
	addItemSelectedMenuEvents(selectedMenuPopup, properties)
}

/**
 * Create and render selected menu for content item
 * @param popup
 * @param properties
 */
export function generateTextItemSelectedMenu(popup, properties){
	//Check if selected popup already exist and remove id is true
	if (useTextItemStore.getState().selectedItemPopup !== null){
		useTextItemStore.getState().selectedItemPopup.remove()
		useTextItemStore.setState({selectedItemPopup: null})
	}

	const selectedMenuPopup = L.popup({
		autoPan: false,
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		minWidth: 0,
		offset: L.point(-(popup._contentNode.offsetWidth / 2), (-(popup._contentNode.offsetHeight / 2) -10))
	})
		.setLatLng(popup._latlng)
		.setContent(ReactDOMServer.renderToString(<TextItemSelectedMenu properties={properties} />))

	//Add popup to map
	selectedMenuPopup.addTo(useMapStore.getState().mapInstance)

	//Add popup to store
	useTextItemStore.setState({selectedItemPopup: selectedMenuPopup})

	//Add event for popup
	addTextSelectedMenuEvents(selectedMenuPopup, properties)
}

export function updateSelectedMenuPosition(referencePopup, selectedMenuPopup, properties){
	L.setOptions(selectedMenuPopup, {
		offset: L.point(-(referencePopup._contentNode.offsetWidth / 2), (-(referencePopup._contentNode.offsetHeight / 2) -10))
	})

	selectedMenuPopup.update()

	addTextSelectedMenuEvents(selectedMenuPopup, properties)
}
