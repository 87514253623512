import getAxiosInstance from "../../AxiosInstance";
import {USER_AUTHORIZATION_API} from "../../../../Router";

function addUserAuthorization(entityUuid, appAuthorizationUuid, userInvitationUuid){
	const sendData = new FormData()
	sendData.append('entityUuid', entityUuid)
	sendData.append('appAuthorizationUuid', appAuthorizationUuid)
	sendData.append('userInvitationUuid', userInvitationUuid)

	return getAxiosInstance().post(USER_AUTHORIZATION_API + '/add', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function removeUserAuthorization(entityUuid, appAuthorizationUuid, userInvitationUuid){
	const sendData = new FormData()
	sendData.append('entityUuid', entityUuid)
	sendData.append('appAuthorizationUuid', appAuthorizationUuid)
	sendData.append('userInvitationUuid', userInvitationUuid)

	return getAxiosInstance().post(USER_AUTHORIZATION_API + '/remove', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	addUserAuthorization,
	removeUserAuthorization
}