import {
	globalDeleteMapContentItem,
	globaleUpdateMapContentItemLocation,
	globalUpdateMapContentItemAttributes,
	globalUpdateMapContentItemColorTag,
	globalUpdateMapContentItemName, globalUpdateMapContentItemThumbnail, updateMapLocation
} from "./GlobalMapContentItemUpdateUtils";
import {generateNewItemDatas, updateTextItemPosition, updateTextItemValue} from "./ItemUtils";
import {generateNewTextItem} from "./RenderTextItemsUtils";
import {renderNewZone, updateZoneLocation, updateZoneShape, updateZoneTitle} from "./ZoneUtils";
import {renderNewShapeItem, updateShapeLocation} from "./ShapeItemUtils";
import L from "leaflet"

export function mapContentItemUpdateListener(data) {
	//Check if update is for item
	if (data.type === 'map_item') {
		//Check if datas list is not empty
		if (Object.keys(data['datas']).length > 0) {
			const updatedDatas = data['datas']

			//Check if item need delete
			if (updatedDatas.action !== undefined && updatedDatas.action === "delete") {
				globalDeleteMapContentItem(updatedDatas.uuid, updatedDatas.location)
				return;
			}

			//Check if item need creation
			if (updatedDatas.location) {
				generateNewItemDatas(updatedDatas.uuid, updatedDatas.location.x, updatedDatas.location.y, updatedDatas.zoom, updatedDatas.overlay, updatedDatas.type, updatedDatas.attributes, updatedDatas.name, updatedDatas.owner)
				return
			}

			//Check if we need update name
			if (updatedDatas.name) {
				globalUpdateMapContentItemName(updatedDatas.uuid, updatedDatas.name, updatedDatas['current_location'])
			}

			//Check if we need update color tag
			if (updatedDatas.color) {
				globalUpdateMapContentItemColorTag(updatedDatas.uuid, updatedDatas.color)
			}

			//Check if we need update attributes
			if (updatedDatas.attributes) {
				globalUpdateMapContentItemAttributes(updatedDatas.uuid, updatedDatas.attributes)
			}

			//Check if we need update thumbnail
			if (updatedDatas.thumbnail) {
				globalUpdateMapContentItemThumbnail(updatedDatas.uuid, updatedDatas.thumbnail)
			}

			if (updatedDatas['old_location'] && updatedDatas['new_location']) {
				globaleUpdateMapContentItemLocation(updatedDatas.uuid, updatedDatas['new_location'], updatedDatas['old_location'])
			}
		}
	} else if (data.type === "map_text_item") {
		//Check if datas list is not empty
		if (Object.keys(data['datas']).length > 0) {
			const updatedDatas = data['datas']

			if (updatedDatas.action === "create") {
				//Convert X Y to latLng
				const latLng = L.Projection.SphericalMercator.unproject(L.point(updatedDatas.x, updatedDatas.y))

				generateNewTextItem(updatedDatas.uuid, updatedDatas.text, updatedDatas.minZoom, latLng.lat, latLng.lng, updatedDatas.overlay, updatedDatas.size)
				return;
			}

			if (updatedDatas.value) {
				updateTextItemValue(updatedDatas.uuid, updatedDatas.value)
			}

			if (updatedDatas.x && updatedDatas.y) {
				//Convert X Y to latLng
				const latLng = L.Projection.SphericalMercator.unproject(L.point(updatedDatas.x, updatedDatas.y))

				updateTextItemPosition(updatedDatas.uuid, latLng)
			}
		}
	} else if (data.type === "zone") {
		//Check if datas list is not empty
		if (Object.keys(data['datas']).length > 0) {
			const updatedDatas = data['datas']

			if (updatedDatas.action === "create") {
				renderNewZone(updatedDatas.bounds)
				return
			}

			if (updatedDatas.action === 'move') {
				updateZoneLocation(updatedDatas['new_bounds'], updatedDatas['old_bounds'])
				return
			}

			if (updatedDatas.action === "update-shape") {
				updateZoneShape(updatedDatas.bounds)
				return
			}

			if (updatedDatas.action === "update-title") {
				updateZoneTitle(updatedDatas.bounds)
				return
			}

			if (updatedDatas.action === "delete") {
				updateZoneShape(updatedDatas.bounds)
				return
			}
		}
	} else if (data.type === "shape") {
		//Check if datas list is not empty
		if (Object.keys(data['datas']).length > 0) {
			const updatedDatas = data['datas']

			if (updatedDatas.action === "create") {
				renderNewShapeItem(updatedDatas.bounds)
				return;
			}

			if (updatedDatas.action === "move") {
				updateShapeLocation(updatedDatas['new_bounds'], updatedDatas['old_bounds'])
				return;
			}

			if (updatedDatas.action === "delete") {
				updateShapeLocation(updatedDatas['new_bounds'])
				return;
			}
		}
	} else if (data.type === "location-update") {
		//Check if datas list is not empty
		if (Object.keys(data['datas']).length > 0) {
			const updatedDatas = data['datas']

			if (updatedDatas.bounds) {
				updateMapLocation(updatedDatas.bounds)
			}
		}
	}
}