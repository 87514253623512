import React, {useState} from "react";
import {IoCloudOfflineOutline} from "react-icons/io5";

const OfflinePopup = () => {
    const [onlineStatus, setOnlineStatus] = useState(true)

    window.onoffline = (event) => {
        setOnlineStatus(false)
    }

    window.ononline = (event) => {
        setOnlineStatus(true)
    }

    if (!onlineStatus){
        return (
            <div className="offline-popup-overlay">
                <div className={onlineStatus ? "offline-popup-container" : "offline-popup-container open"}>
                    <p className="icon"><IoCloudOfflineOutline /></p>
                    <p className="text">You appear to be offline. Please check your internet connection and try again.</p>
                </div>
            </div>
        )
    } else {
        return null;
    }

}

export default OfflinePopup