import React, {useEffect, useState} from "react";
import GetStartedDashboardSection from "../../../components/customs/GetStartedDashboardSection";
import MapAPI from "../../../services/MapAPI";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import MapsListContainer from "../../../components/customs/projects_list/MapsListContainer";
import ProjectManageActionBar from "../../../components/customs/projects_list/ProjectManageActionBar";

const Home = () => {
	const [search, setSearch] = useState("");

	useEffect(() => {
		useDashboardStore.setState({
			currentOpenedProject: null,
			currentProjects: null,
			currentOpenedWorkspace: null,
		});

		MapAPI.userGetRecentMaps().then((response) => {
			if (response.status === 200) {
				useDashboardStore.setState({currentProjectMaps: response.data});
			} else {
				useDashboardStore.setState({currentProjectMaps: []});
			}
		});
	}, []);

	return (
		<>
			<GetStartedDashboardSection/>
			<ProjectManageActionBar search={search} setSearch={setSearch} action={true}/>
			<MapsListContainer/>
		</>
	);
};

export default Home;
