import React, {useContext, useEffect} from "react";
import {Redirect, useHistory, useParams} from "react-router-dom"
import "../../../css/pages/confirm-account.scss"
import {TailSpin} from "react-loader-spinner";
import AuthContext from "../../contexts/AuthContext";
import RegisterAPI from "../../services/RegisterAPI";
import {createSuccessToast} from "../../services/CustomToastUtils";
import BrowserNotSupported from "../BrowserNotSupported";

const ConfirmAccount = () => {
    const history = useHistory()
    const {token} = useParams()
    const {isAuthenticated} = useContext(AuthContext)

    useEffect(async () => {
        if (token) {
            if (await RegisterAPI.confirmAccount(token)) {
                createSuccessToast("Account confirmed successfully")
            }

            history.push('/')
        } else {
            history.push("/")
        }
    }, [token])

    if (isAuthenticated){
        if (/Android|iPhone/i.test(navigator.userAgent)) {
            return <BrowserNotSupported />
        } else {
            return (<Redirect to="/user/dashboard/maps" />)
        }
    }

    return (
        <div className="full-page-loader-container">
            <TailSpin height="128" width="128" color="#000"/>
        </div>
    )
}

export default ConfirmAccount