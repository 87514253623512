import {createInfoToast} from "../../services/CustomToastUtils";
import {FiArrowUpRight} from "react-icons/fi";
import youtube_small from "../../../images/user_space_card_thumbnail/youtube_small.svg";
import youtube_large from "../../../images/user_space_card_thumbnail/youtube_large.svg";
import model_img from "../../../images/tuto/map-tuto-image.svg";
import help_icon from "../../../images/user_space_card_thumbnail/help.svg";
import weeki_icon from "../../../images/user_space_card_thumbnail/weeki_icon.svg";
import React from "react";

const GetStartedDashboardSection = () => {
	return (
		<>
			<h1 className="section-title">Get started</h1>
			<div className="help-card-container">
				<div className="help-card medium" onClick={() => createInfoToast("Coming soon !")} style={{ background: '#1a1b1c', width: '24px', border: '1px solid #1a1b1c'}}>
					<p className="help-card-title" style={{color: 'white'}}>Models</p>
					<p className="help-card-subtitle" style={{background: '#3d3d3d', color:'white'}}>Coming soon</p>
					<p className="arrow bottom"><FiArrowUpRight style={{stroke:'white'}}/></p>
					<img style={{left: "-44%", top: "-42%", borderRadius: "6px", opacity: "0.09"}} className="help-card-thumb" src={model_img} alt="model img" />
				</div>
				<div className="help-card large" onClick={() => createInfoToast("Coming soon !")}>
					<p className="help-card-title">Video tutorials</p>
					<p className="help-card-subtitle" style={{background: '#3d3d3d', color:'white'}}>Coming soon</p>
					<p className="arrow top"><FiArrowUpRight /></p>
					<img style={{left: "40%", top: "45%"}} className="help-card-thumb" src={youtube_small} alt="Youtube large icon" />
					<img style={{left: "68%", top: "45%"}} className="help-card-thumb" src={youtube_large} alt="Youtube small icon" />
				</div>
				<div className="slitted-help-card">
					<div className="help-card large">
						<p className="help-card-title">Status</p>
						<div className="status-point"></div>
						{/*<p className="info-badge">Next maintenance 10/08/2022 - 18:30</p>*/}
						<p className="arrow center"><FiArrowUpRight /></p>
					</div>
					<div className="help-card large">
						<p className="help-card-title">Help center</p>
						<p className="arrow center"><FiArrowUpRight /></p>
						<img style={{left: "45%", top: "18%"}} className="help-card-thumb" src={help_icon} alt="help icon" />
					</div>
				</div>
				<div className="help-card small">
					<p className="help-card-title">Releases</p>
					<p className="help-card-subtitle" style={{color: '#000' , background: '#59ffc2'}}>What's news</p>
					<p className="arrow bottom"><FiArrowUpRight /></p>
					<img style={{left: "19%", top: "65%"}} className="help-card-thumb" src={weeki_icon} alt="Weeki icon" />
				</div>
			</div>
		</>
	)
}

export default GetStartedDashboardSection