import getAxiosInstance from "../../AxiosInstance";
import {PUBLICS_CONTENT_ITEM} from "../../../../Router";

function getPublicsContentItemByUuid(uuid){
	return getAxiosInstance().get(PUBLICS_CONTENT_ITEM + '/get_by_uuid', {params: {uuid: uuid}})
}

function getPublicsContentItemThumbnailUrl(uuid){
	return getAxiosInstance().get(PUBLICS_CONTENT_ITEM + '/get_thumbnail_url', {params: {uuid: uuid}})
}

export default {
	getPublicsContentItemByUuid,
	getPublicsContentItemThumbnailUrl
}