import React, {useContext, useEffect, useRef, useState} from "react";
import weeki_logo from "../../images/weeki_logo.svg"
import weeki_logo_beta from "../../images/logo_weeki_beta.svg"
import {FaChevronDown, FaChevronLeft} from "react-icons/fa";
import {FiHelpCircle, FiLogOut} from "react-icons/fi";
import {IoCalendarSharp} from "react-icons/io5";
import {IoMdNotificationsOutline} from "react-icons/io";
import {MdOutlineSpaceDashboard, MdOutlineViewWeek, MdSettings} from "react-icons/md";
import {RiArrowUpCircleLine} from "react-icons/ri";
import UserAPI from "../services/UserAPI";
import {useHistory, useLocation} from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import AuthAPI from "../services/AuthAPI";
import SupportPopupContext from "../contexts/SupportPopupContext";
import {useCookies} from "react-cookie";
import IsAdminContext from "../contexts/IsAdminContext";
import Skeleton from "react-loading-skeleton";
import DocumentationPopupContext from "../contexts/DocumentationPopupContext";
import UserNotificationAPI from "../services/API/User/UserNotificationAPI";
import {useMapStore, useMercureStore, useUserStore} from "../services/Stores";
import UserMapCollaboratorAPI from "../services/API/User/UserMapCollaboratorAPI";
import {toast} from "react-toastify";
import UpdateTempPasswordPopup from "./UpdateTempPasswordPopup";
import TopbarUserMenu from "./TopbarUserMenu";
import UsersOnMapAPI from "../services/UsersOnMapAPI";
import NotificationMenuContainer from "./customs/notifications/NotificationMenuContainer";
import {useMoocStore} from "../services/Stores/MoocStore";
import {useContentItemStore} from "../services/Stores/ContentItemStore";
import {useSidebarMapContentItemStore} from "../services/Stores/SidebarMapContentItemStore";

const TopNavBar = ({simple = false, backLink, pageTitle}) => {
    const mapInstance = useMapStore(state => state.mapInstance)
    const user  = useUserStore(state => state.user)
    const resetMapStore = useMapStore(state => state.resetStore)
    const resetMoocStore = useMoocStore(state => state.resetStore)
    const resetItemStore = useContentItemStore(state => state.resetStore)
    const resetItemSidebarStore = useSidebarMapContentItemStore(state => state.resetStore)
    const confirmedAccount = useUserStore(state => state.userLoad ? state.confirmedAccount : true)

    if (mapInstance){
        mapInstance.remove()
        UsersOnMapAPI.removeUserOnMap(useMapStore.getState().mapUuid)
        resetMapStore()
        resetMoocStore()
        resetItemStore()
        resetItemSidebarStore()
    }

    const history = useHistory()
    const currentDate = new Date().toLocaleDateString()
    const location = useLocation()

    // const userNotifications = useNotificationStore(state => state.notificationList)
    // const unReadNotificationCount = useNotificationStore(state => state.unReadNotificationCount)
    // const updateNotificationReadStatus =  useNotificationStore(state => state.updateNotificationReadStatus)
    // const readAllNotification =  useNotificationStore(state => state.readAllNotification)
    // const removeNotification =  useNotificationStore(state => state.removeNotification)
    const [notificationMenuStatus, setNotificationMenuStatus] = useState(false)
    const notificationListRef = useRef(null)

    // useEffect(async () => {
    //     if (notificationMenuStatus){
    //         readAllNotification()
    //         await UserNotificationAPI.readAllUserNotification()
    //         // handleCheckNotifVisibility(notificationListRef.current)
    //     }
    // }, [notificationMenuStatus])
    //
    // const handleAcceptMapInvitation = async (notificationUuid) => {
    //     const notif = userNotifications.filter(n => n.uuid === notificationUuid)[0]
    //
    //     if (await UserMapCollaboratorAPI.acceptInvitation(notif.collaborator)) {
    //         toast.success("Invitation accepted")
    //         removeNotification(notif.uuid)
    //     }
    // }
    //
    // const handleDeclineMapInvitation = async (notificationUuid) => {
    //     const notif = userNotifications.filter(n => n.uuid === notificationUuid)[0]
    //
    //     if (await UserMapCollaboratorAPI.declineInvitation(notif.collaborator)) {
    //         toast.success("Invitation declined")
    //         removeNotification(notif.uuid)
    //     }
    // }

    return (
        <>
            {user.tempPassword && (
                <UpdateTempPasswordPopup user={user} />
            )}
            <div className={confirmedAccount ? "topbar-container" : "topbar-container header-alert-active"}>
                {!simple && (
                    <>
                    <div className="logo">
                        <img src={weeki_logo} alt="Weeki logo"/>
                    </div>
                    <div className="bar">
                        <div className="page-name">
                            <p className="icon"><MdOutlineViewWeek size={32}/></p>
                            {location.pathname.split("/")[1] === "user" && (
                                <p className="text">User Dashboard</p>
                            )}
                            {location.pathname.split("/")[1] === "admin" && (
                                <p className="text">
                                    Admin Dashboard
                                    {location.pathname.split("/")[3] === "plans" ? "/plans" : ""}
                                    {location.pathname.split("/")[3] === "maps" ? "/maps" : ""}
                                    {location.pathname.split("/")[3] === "store" ? "/store" : ""}
                                    {location.pathname.split("/")[3] === "users" ? "/users" : ""}
                                    {location.pathname.split("/")[3] === "emails" ? "/emails" : ""}
                                    {location.pathname.split("/")[3] === "history" ? "/history" : ""}
                                </p>
                            )}
                        </div>
                        <div className="bar-right">
                            <div className="date">
                                <div className="icon"><IoCalendarSharp /></div>
                                <div className="text">{currentDate}</div>
                            </div>
                            <NotificationMenuContainer />
                        </div>
                    </div>
                    </>
                )}
                {simple && (
                    <div className="bar">
                        <div className="back-btn" onClick={() => history.push(backLink)}>
                            <div className="icon"><FaChevronLeft /></div>
                            <div className="text">Back</div>
                        </div>
                    </div>
                )}
                <div className="right">
                    <TopbarUserMenu />
                </div>
            </div>
        </>
    )
}

export default TopNavBar