import {useEffect, useState} from "react";

const SizedTextarea = ({label, maxSize, value, setValue}) => {
	const [currentTextSize, setCurrentTextSize] = useState(0)

	useEffect(() => {
		if (value){
			setCurrentTextSize(value.length)
		}
	}, [value]);

	const handleUpdateValue = (event) => {
		const newValue = event.currentTarget.value

		if (newValue.length <= maxSize){
			setValue(newValue)
		}
	}
	return (
		<div className="component__sized-textarea-container">
			<div className="sized-textarea-header">
				<p className="label">{label}</p>
				<p className="max-size">{currentTextSize}/{maxSize}</p>
			</div>
			<textarea rows="4" onChange={handleUpdateValue} value={value}></textarea>
		</div>
	)
}

export default SizedTextarea