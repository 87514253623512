import React from "react";
import {FaCheck} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";
import alert_delete from "../../icons/admin-dashboard/emails/alert.svg";
import success_send from "../../icons/admin-dashboard/emails/success-send.svg";

const DeleteModal = ({title, alertMessage, successMessage, deleteMethod, requestStatus, setRequestStatus, modalStatus = false, setModalStatus}) => {
    return (
        <>
        {modalStatus && (
            <div className="modal-overlay">
                <div className={modalStatus ? "modal open" : "modal"}>
                    <div className="header delete">
                        <h1>{title}</h1>
                        <p onClick={() => {
                            setModalStatus(false)
                            setRequestStatus(false)
                        }}><IoMdClose /></p>
                    </div>
                    {!requestStatus && (
                        <div className="content delete-modal">
                            <p className="confirm-message">
                                <img src={alert_delete} alt="Alert icon"/>
                                <p className="text">{alertMessage}</p>
                            </p>
                            <div className="button-container">
                                <div className="btn cancel" onClick={() => {
                                    setModalStatus(false)
                                    setRequestStatus(false)
                                }}>
                                    <p className="text">Cancel</p>
                                    <p className="icon"><IoMdClose /></p>
                                </div>
                                <div className="btn valid" onClick={deleteMethod}>
                                    <p className="text">Confirm </p>
                                    <p className="icon"><FaCheck /></p>
                                </div>
                            </div>
                        </div>
                    )}
                    {requestStatus && (
                        <div className="content delete-template-mail">
                            <div className="content create-template-mail">
                                <div className="success-container">
                                    <img src={success_send} alt="Success send icon"/>
                                    <h1 className="message">{successMessage}</h1>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )}
        </>
    )
}

export default DeleteModal