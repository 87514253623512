import getAxiosInstance from "../../AxiosInstance";
import {USER_CONTENT_ITEM_API} from "../../../../Router";
import {generateNewItemDatas} from "../../Utils/ItemUtils";
import {useMapStore} from "../../Stores";
import AuthAPI from "../../AuthAPI";

function userGetMapContentItems(mapUuid){
	return getAxiosInstance().get(USER_CONTENT_ITEM_API + '/get_map_items', {params: {mapUuid: mapUuid}})
}
function userGetOverlayContentItems(overlayUuid){
	return getAxiosInstance().get(USER_CONTENT_ITEM_API + '/get_overlay_items', {params: {overlayUuid: overlayUuid}})
}
function userGetAreaContentItems(areaUuid){
	return getAxiosInstance().get(USER_CONTENT_ITEM_API + '/get_area_items', {params: {areaUuid: areaUuid}})
}

function userCreateBibtexFromFile(file, overlay, x, y, zoom){
	const sendData = new FormData()
	sendData.append('file', file)
	sendData.append('overlay', overlay)
	sendData.append('zoom', zoom)
	sendData.append('x', x)
	sendData.append('y', y)

	return getAxiosInstance().post(USER_CONTENT_ITEM_API + "/create_bibtex_from_file", sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch((error) => {
			return false
		})
}
function userCreateBibtexFromCode(code, overlay, x, y, zoom, type){
	const sendData = new FormData()
	sendData.append('bibtex', code)
	sendData.append('overlay', overlay)
	sendData.append('zoom', Math.floor(parseFloat(zoom)))
	sendData.append('x', x)
	sendData.append('y', y)

	return getAxiosInstance().post(USER_CONTENT_ITEM_API + "/create_bibtex_from_code", sendData)
		.then((response) => {
			if (response.status === 200){
				generateNewItemDatas(response.data.item.uuid, x, y, zoom, useMapStore.getState().currentOverlay, type, JSON.stringify(response.data.item.attributes), response.data.item.name, AuthAPI.getUserUuid())

				return true
			}
		})
		.catch((error) => {
			return false
		})
}

function userCopyItem(uuid, x, y, originZoom, targetZoom){
	const sendData = new FormData()
	sendData.append("uuid", uuid)
	sendData.append("x", x)
	sendData.append("y", y)
	sendData.append("originZoom", originZoom)
	sendData.append("targetZoom", targetZoom)

	return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/copy', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data
			}

			return false
		})
		.catch(() => false)
}

function userUpdateContentItemZoomLevel(contentItemUuid, zoomOffset){
	const sendData = new FormData()
	sendData.append('contentItemUuid', contentItemUuid)
	sendData.append('zoomOffset', zoomOffset)

	return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_zoom', sendData)
		.then((response) => {
			return response.data === 200
		})
		.catch(() => false)
}

function userUpdateContentTableProperties(contentItemUuid, tableProperties){
	const sendData = new FormData()
	sendData.append('contentItemUuid', contentItemUuid)
	sendData.append('tableProperties', JSON.stringify(tableProperties))

	return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/update_object_table_properties', sendData)
		.then((response) => {
			return response.data === 200
		})
		.catch(() => false)
}

function userDeleteMultipleContentItems(uuids){
	return getAxiosInstance().delete(USER_CONTENT_ITEM_API + '/delete_multiple', {params: {contentItemUuids: uuids}})
		.then((response) => {
			return response.status === 200
		})
		.catch((error) => {
			return false
		})
}

function exportContentItems(format, items){
	const sendData = new FormData()
	sendData.append('format', format)
	sendData.append('contentItemUuids', items)

	return getAxiosInstance().post(USER_CONTENT_ITEM_API + '/export', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.url
			}
			return false
		})
		.catch(() => false)
}

export default {
	userCreateBibtexFromFile,
	userCreateBibtexFromCode,
	userCopyItem,
	userUpdateContentItemZoomLevel,
	userGetMapContentItems,
	userDeleteMultipleContentItems,
	userGetOverlayContentItems,
	userGetAreaContentItems,
	exportContentItems,
	userUpdateContentTableProperties
}