import React, {Fragment, useEffect, useRef, useState} from "react";
import L from "leaflet";
import {useMapStore} from "../../../services/Stores";
import {BsSendFill} from "react-icons/bs";
import {MdEdit} from "react-icons/md";
import {IoIosInformationCircleOutline, IoMdMove} from "react-icons/io";
import AreaAPI from "../../../services/AreaAPI";
import {FaChevronDown, FaChevronLeft, FaChevronRight} from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import {LiaSquare} from "react-icons/lia";
import {TbShape3, TbSquare} from "react-icons/tb";
import ContextMenuItem from "../content_menu/ContextMenuItem";
import {GiCancel} from "react-icons/gi";
import ContextMenuContainer from "../content_menu/ContextMenuContainer";
import {getContextMenuPosition} from "../../../services/Utils";
import ShapeItemAPI from "../../../services/API/User/ShapeItemAPI";
import OverlayAPI from "../../../services/OverlayAPI";
import {useCookies} from "react-cookie";

const ShapeRow = ({shape, overlay, position}) => {
	//Get map instance in map store
	const mapInstance = useMapStore(state => state.mapInstance)
	const publicMap = useMapStore(state => state.publicMap)

	const [cookies, setCookie] = useCookies(['mercureAuthorization', 'maps-default-overlay', 'maps-tutorial'])


	//Define default state for area name change
	const [nameChangeState, setNameChangeState] = useState(false)

	const handleGoToShape = (event, shape) => {
		event.stopPropagation()

		//Get area center
		let x = parseFloat(shape.pos.x)
		let y = parseFloat(shape.pos.y)

		const latLng =  L.Projection.SphericalMercator.unproject(new L.Point(x, y))

		mapInstance.fitBounds([latLng], {padding: [50, 50], animate: false})
	}

	const handleUpdateShapeName = () => {

	}

	const overlays = useMapStore(state => state.overlays)
	const [contextMenuState, setContextMenuState] = useState(false)
	const [contextMenuMoveState, setContextMenuMoveState] = useState(false)
	const contextMenuRef = useRef(null)

	const handleOpenMoveContextMenu = (event) => {
		event.preventDefault()
		event.stopPropagation()

		setContextMenuState(true)

		getContextMenuPosition(event, contextMenuRef.current)
	}

	const handleMoveShapeToOverlay = async (overlayUuid) => {
		setContextMenuMoveState(false)
		setContextMenuState(false)

		if (await ShapeItemAPI.userMoveShapeItemToOverlay(shape.uuid, overlayUuid)) {
			useMapStore.setState({overlayLoading: true})
			useMapStore.setState({overlays: []})

			OverlayAPI.getMapOverlays(useMapStore.getState().mapUuid).then((response) => {
				if (response.status === 200) {

					if (cookies['maps-default-overlay'] !== undefined) {
						if (cookies['maps-default-overlay'][useMapStore.getState().mapUuid] !== undefined) {
							useMapStore.setState({currentOverlay: cookies['maps-default-overlay'][useMapStore.getState().mapUuid]})
						} else {
							const cookieValue = {}
							cookieValue[useMapStore.getState().mapUuid] = response.data[0].uuid
							setCookie('maps-default-overlay', cookieValue)
							useMapStore.setState({currentOverlay: response.data[0].uuid})
						}
					} else {
						const cookieValue = {}
						cookieValue[useMapStore.getState().mapUuid] = response.data[0].uuid
						setCookie('maps-default-overlay', cookieValue)
						useMapStore.setState({currentOverlay: response.data[0].uuid})
					}

					let overlaysList = structuredClone(response.data)
					overlaysList = overlaysList.map(ol => ({...ol, default: useMapStore.getState().currentOverlay === ol.uuid, active: true}))

					const activeOverlay = overlaysList.filter(o => o.active).map(o => o.uuid)
					useMapStore.getState().updateOverlayFilterList(activeOverlay)

					useMapStore.setState({overlays: overlaysList})
					useMapStore.setState({overlayLoading: false})
				}
			})
		}
	}

	return (
		<>
			<ContextMenuContainer map={false} setState={setContextMenuState} state={contextMenuState} ref={contextMenuRef}>
				{!contextMenuMoveState && (
					<ContextMenuItem text="Move" icon={<IoMdMove />} handler={() => setContextMenuMoveState(true)} closeAfter={false} />
				)}
				{contextMenuMoveState && (
					<>
						<ContextMenuItem text="Cancel" icon={<GiCancel />} handler={() => setContextMenuMoveState(false)} closeAfter={false}/>
						{overlays.map((o) => (
							<>
								{o.uuid !== overlay.uuid && (
									<ContextMenuItem text={o.name} handler={() => handleMoveShapeToOverlay(o.uuid)} icon={null} />
								)}
							</>
						))}
					</>
				)}
			</ContextMenuContainer>

		<div className="area-row-container" onContextMenu={handleOpenMoveContextMenu}>
			<div draggable={!publicMap} className="area" style={{marginLeft: position + 'px'}}>
				<div className="left">
					<div className="open-btn" style={{width: '14px', height: '14px'}}></div>
					<div className="type-icon">
						<TbShape3/>
					</div>
					{nameChangeState && (
						<input type="text"
							   onClick={(event) => event.stopPropagation()}
							   autoFocus={true}
							   onKeyDown={(event) => event.key === "Enter" ? handleUpdateShapeName(event.currentTarget.value) : null}
							   onBlur={(event) => handleUpdateShapeName(event.currentTarget.value)}
							   placeholder="Shape name"
							   defaultValue={shape.name}/>
					)}
					{!nameChangeState && (
						<p className="area-name">{shape.name}</p>
					)}
				</div>
				<div className="right">
					<div className="icon-button">
						<p className="icon" onClick={(event) => handleGoToShape(event, shape)}><BsSendFill /></p>
						<p className="tooltip">Go to shape</p>
					</div>
					{!publicMap && (
						<div className="icon-button">
							<p className="icon" onClick={(event) => {
								event.stopPropagation()
								setNameChangeState(true)
							}}><MdEdit/></p>
							<p className="tooltip">Edit name</p>
						</div>
					)}
					<div className="icon-button">
						<p className="icon"><IoIosInformationCircleOutline/></p>
						<p className="tooltip">Infos</p>
					</div>
				</div>
			</div>

		</div>
	</>
	)
}

export default ShapeRow