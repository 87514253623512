import React, {useState} from "react";
import {FaChevronDown} from "react-icons/fa";

const PlansDashboardStatCard = ({icon, title, value, augment = false, augmentPercent = 0, selectedPlan, setSelectedPlan}) => {
    const [menuStatus, setMenuStatus] = useState(false)

    return (
        <div className="admin-card">
            <div className="left">
                <img src={icon} alt="Card icon"/>
                <div className="text">
                    <p>{title}</p>
                    <p>{value}</p>
                </div>
            </div>
            <div className="right">
                <div className="selector date">
                    <div className="btn" onClick={() => setMenuStatus(!menuStatus)}>
                        <p>{selectedPlan}</p>
                        <p><FaChevronDown /></p>
                    </div>
                    <div className={menuStatus ? "menu open" : "menu"}>
                        <p onClick={() => {
                            if (selectedPlan !== "All"){setSelectedPlan("All")}
                            setMenuStatus(false)
                        }}>All</p>
                        <p onClick={() => {
                            if (selectedPlan !== "Basic"){setSelectedPlan("Basic")}
                            setMenuStatus(false)
                        }}>Basic</p>
                        <p onClick={() => {
                            if (selectedPlan !== "Standard"){setSelectedPlan("Standard")}
                            setMenuStatus(false)
                        }}>Standard</p>
                        <p onClick={() => {
                            if (selectedPlan !== "Pro"){setSelectedPlan("Pro")}
                            setMenuStatus(false)
                        }}>Pro</p>
                        <p onClick={() => {
                            if (selectedPlan !== "Enterprise"){setSelectedPlan("Enterprise")}
                            setMenuStatus(false)
                        }}>Enterprise</p>
                        <p onClick={() => {
                            if (selectedPlan !== "Custom"){setSelectedPlan("Custom")}
                            setMenuStatus(false)
                        }}>Custom</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlansDashboardStatCard