import React, {useContext, useEffect, useRef, useState} from "react";
import {BiLogoApple, BiLogoGoogle} from "react-icons/bi";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import AuthAPI from "../../services/AuthAPI";
import {toast} from "react-toastify";
import {getBrowser} from "../../services/Utils";
import {useHistory} from "react-router-dom";
import IsAdminContext from "../../contexts/IsAdminContext";
import AuthContext from "../../contexts/AuthContext";
import LoginSessionPopup from "../../components/LoginSessionPopup";
import {TailSpin} from "react-loader-spinner";
import weeki_logo from "../../../images/weeki_logo.svg"
import {createErrorToast, createSuccessToast} from "../../services/CustomToastUtils";
import {EMAIL_REGEX} from "../../../Regex";

const ForgotPassword = () => {
    //Get auth context method
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)

    //Get history
    const history = useHistory()

    //Define default state for load sign button status
    const [signButtonLoading, setSignButtonLoading] = useState(false)
    const [emailAddress, setEmailAddress] = useState("")

    const handleValid = async () => {
        if (emailAddress.length <= 0){
            createErrorToast("Email cannot be empty")
            return
        }

        if (!EMAIL_REGEX.test(emailAddress)){
            createErrorToast("Invalid email address")
            return
        }

        setSignButtonLoading(true)
        if (await AuthAPI.userForgotPassword(emailAddress)){
            createSuccessToast("An email to reset your password has been sent")
            history.push("/")
        } else {
            setSignButtonLoading(false)
        }
    }

    if (isAuthenticated){
        history.push("/user/dashboard/maps")
    }

    return (
        <>
            <div className="auth-container">
                <div className="auth-content">
                    <img className="logo" src={weeki_logo} alt="Weeki logo"/>
                    <h1 className="title">Mot de passe oublié</h1>
                    <div className="auth-form-container">
                        <div className="auth-input-container">
                            <input value={emailAddress} onChange={(event) => setEmailAddress(event.currentTarget.value)} className="auth-input" type="text" placeholder="Email"/>
                        </div>
                    </div>
                    <p className="sign-btn" onClick={handleValid}>
                        {signButtonLoading
                            ? <TailSpin height="19" width="19" color="#FFFFFF" radius={1} />
                            : "Send"
                        }
                    </p>
                    <p className="sub-sign-btn-link">Already have an account? <span onClick={() => history.push("/")}>Log in</span></p>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword