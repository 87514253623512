import getAxiosInstance from "../../AxiosInstance";
import {USER_TEXT_ITEM_API} from "../../../../Router";
import {generateRandomId} from "../../Utils";
import {useMapStore} from "../../Stores";

function userGetOverlayTextItems(overlayUuid) {
	return getAxiosInstance().get(USER_TEXT_ITEM_API + '/get_overlay_text_items', {params: {overlayUuid: overlayUuid}})
}

function createTextItem(x, y, text, overlay, zoom){
	const sendData = new FormData()
	sendData.append('text', text)
	sendData.append('x', x)
	sendData.append('y', y)
	sendData.append('overlay', overlay)
	sendData.append('zoomLevel', zoom)

	return getAxiosInstance().post(USER_TEXT_ITEM_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.uuid
			}

			return ""
		})
		.catch(() => {
			return ""
		})
}

function updateTextItemSize(uuid, size){
	const sendData = new FormData()
	sendData.append('uuid', uuid)
	sendData.append('size', size)
	sendData.append('zoom', useMapStore.getState().mapInstance.getZoom())

	return getAxiosInstance().post(USER_TEXT_ITEM_API + "/update_size", sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function updateLocation(uuid, x, y){
	const sendData = new FormData()
	const updateId = generateRandomId()

	sendData.append('uuid', uuid)
	sendData.append('x', x)
	sendData.append('y', y)
	sendData.append('updateId', updateId)

	useMapStore.getState().addNewUpdateId(updateId)

	return getAxiosInstance().post(USER_TEXT_ITEM_API + '/update_location', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}
function updateValue(uuid, value){
	const sendData = new FormData()
	const updateId = generateRandomId()

	sendData.append('uuid', uuid)
	sendData.append('value', value)
	sendData.append('updateId', updateId)

	useMapStore.getState().addNewUpdateId(updateId)

	return getAxiosInstance().post(USER_TEXT_ITEM_API + '/update_value', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function deleteTextItem(uuid){
	return getAxiosInstance().delete(USER_TEXT_ITEM_API + '/delete', {params: {uuid: uuid}})
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function userMoveTextItemToOverlay(textItemUuid, overlayUuid){
	const sendData = new FormData()
	sendData.append('textItemUuid', textItemUuid)
	sendData.append('overlayUuid', overlayUuid)

	return getAxiosInstance().post(USER_TEXT_ITEM_API + "/move_to_overlay", sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	createTextItem,
	updateTextItemSize,
	deleteTextItem,
	updateLocation,
	updateValue,
	userGetOverlayTextItems,
	userMoveTextItemToOverlay
}