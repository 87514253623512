import React, {Fragment, useContext, useEffect, useState} from "react";
import event_icon from "../../../../icons/objects/event.svg"
import company_icon from "../../../../icons/objects/company.svg"
import contact_icon from "../../../../icons/objects/contact.svg"
import {BiLoaderAlt} from "react-icons/bi";
import no_bg_bibtex from "../../../../icons/objects/no-background/bibtex.svg"
import {TailSpin} from "react-loader-spinner";
import {MdContentCopy, MdEmail, MdLink, MdNoPhotography, MdPhone} from "react-icons/md";
import {FaYoutube} from "react-icons/fa";
import Latex from "react-latex-next";
import Level2BibtexRender from "./Level2BibtexRender";
import areaAPI from "../../../services/AreaAPI";
import {getOnlineVideoId} from "../../../services/Utils/ItemUtils";
import {IoMdCopy, IoMdPlay} from "react-icons/io";
import {MathJax, MathJaxBaseContext} from "better-react-mathjax";
import LatexEquationRenderer from "../../customs/LatexEquationRenderer";

const TestObjectPopup = ({item, thumbnailUrl = "", loader = false, selected = false, small}) => {
    const attributes = JSON.parse(item.attributes)

    let thumbnailRender = true
    let columnRender = false
    let iconThumbnail = false

    const notThumbnailRender = ["TASKS", "LATEX", "CONTACT", "COMPANY", "SOUND", "TEXTS"]
    const columnRenderTypes = ["BIBTEX", "PDF", "GDOCS", "GSHEET", "GSLIDES", "GCOLLAB", "OTHER_FILES"]
    const iconThumbnailRenderTypes = ["GIT_REPOSITORY", "OTHER_FILES"]

    if (notThumbnailRender.includes(item.type_code)){
        thumbnailRender = false
    }

    if (columnRenderTypes.includes(item.type_code)){
        columnRender = true
    }

    if (iconThumbnailRenderTypes.includes(item.type_code)){
        iconThumbnail = true
    }

    const generateRandomIntegerInRange = (min, max) =>  {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return (
        <>
            <div style={{borderLeft: item.color === "#FFFFFF" ? "none" : "4px solid " + item.color}} className={"object-level-2-container " + (selected ? "selected " : "") + (small ? "small " : "")}>
                <div className="item-container">
                    <div className={"item-title " + (columnRender ? "column" : "")}>
                        {!columnRender && (
                            <div className="title-icon"><img src={"/static/map/svg/" + item.icon_code + '.svg'}
                                                             alt={item.icon_code}/></div>
                        )}
                        <p className="title">{item.name}</p>
                    </div>
                    <div className={"item-content " + (columnRender ? "column" : "")}>
                        {thumbnailRender && (
                            <>
                                <div className="thumbnail-container">
                                    {!iconThumbnail && (
                                        <>
                                            {item.type_code === "VIDEOS_ONLINE" && (
                                                <>
                                                    <img src={"https://img.youtube.com/vi/" + getOnlineVideoId(new URL(attributes.filter(attr => attr.name === "url")[0].value), () => {}, () => {}) + "/maxresdefault.jpg"} alt="Video thumbnail"/>
                                                    <div className="thumbnail-overlay-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             className="external-icon" viewBox="0 0 28.57  20"
                                                             focusable="false"
                                                            style={{pointerEvents: 'none', display: 'block', width: "100%", height: "100%"}}
                                                        >
                                                            <svg viewBox="0 0 28.57 20"
                                                                 preserveAspectRatio="xMidYMid meet"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <g>
                                                                    <path
                                                                        d="M27.9727 3.12324C27.6435 1.89323 26.6768 0.926623 25.4468 0.597366C23.2197 2.24288e-07 14.285 0 14.285 0C14.285 0 5.35042 2.24288e-07 3.12323 0.597366C1.89323 0.926623 0.926623 1.89323 0.597366 3.12324C2.24288e-07 5.35042 0 10 0 10C0 10 2.24288e-07 14.6496 0.597366 16.8768C0.926623 18.1068 1.89323 19.0734 3.12323 19.4026C5.35042 20 14.285 20 14.285 20C14.285 20 23.2197 20 25.4468 19.4026C26.6768 19.0734 27.6435 18.1068 27.9727 16.8768C28.5701 14.6496 28.5701 10 28.5701 10C28.5701 10 28.5677 5.35042 27.9727 3.12324Z"
                                                                        fill="#FF0000"></path>
                                                                    <path
                                                                        d="M11.4253 14.2854L18.8477 10.0004L11.4253 5.71533V14.2854Z"
                                                                        fill="white"></path>
                                                                </g>
                                                            </svg>
                                                        </svg>
                                                    </div>
                                                </>
                                            )}
                                            {item.type_code !== "VIDEOS_ONLINE" && (
                                                <>
                                                    {loader && (
                                                        <TailSpin width={50} height={50} color="#000"/>
                                                    )}
                                                    {!loader && (
                                                        <>
                                                            {thumbnailUrl !== "" && (
                                                                <img src={thumbnailUrl} alt="Item thumbnail"/>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {item.type_code === "VIDEOS_OFFLINE" && (
                                                <div className="thumbnail-overlay-icon white">
                                                    <IoMdPlay />
                                                </div>
                                            )}
                                            {columnRender && (
                                                <div className="thumbnail-icon">
                                                    <img src={"/static/map/svg/" + item.icon_code + '.svg'}
                                                         alt={item.icon_code}/>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {iconThumbnail && (
                                        <div className="icon-thumbnail-container">
                                            <img src={"/static/map/svg/" + item.icon_code + '.svg'}
                                                 alt={item.icon_code}/>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {/*TEXT ITEM RENDER*/}
                        {item.type_code === "TEXTS" && (
                            <div className="item-text-container">
                                {attributes.filter(a => a.type === "text-preview")[0]?.value + " ..."}
                            </div>
                        )}
                        {/*TASK ITEM RENDER*/}
                        {item.type_code === "TASKS" && (
                            <div className="item-task-container">
                                <div className="task-list">
                                    {attributes.length > 0 && (
                                        <>
                                            {attributes.map((task, index) => (
                                                <Fragment key={index}>
                                                    {index < (small ? 3 : 5) && (
                                                        <div className={task.checked ? "task checked" : "task"}>
                                                            <div className="checked-indicator">
                                                                {task.checked ? '✔' : ''}
                                                            </div>
                                                            <p key={index} className="task-name">{task.taskName}</p>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            ))}
                                            {attributes.length > (small ? 3 : 5) && (
                                                <div className="task">
                                                    <p className="task-name">{attributes.length - (small ? 3 : 5)} more ...</p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {attributes.length <= 0 && (
                                        <div className="task">
                                            <p className="task-name">No task</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {/*SOUND ITEM RENDER*/}
                        {item.type_code === "SOUND" && (
                            <div className="level2-sound-container">
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}} className="audio-bar"></span>
                                {!small && (
                                    <>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                        <span style={{height: generateRandomIntegerInRange(8, 30) + 'px'}}
                                              className="audio-bar"></span>
                                    </>
                                )}
                            </div>
                        )}
                        {/*LATEX ITEM RENDER*/}
                        {item.type_code === "LATEX" && (
                            <div className="level2-latex-container" id="item-latex-container">
                                <Latex>{attributes.filter(attr => attr.name === "latexEquation")[0].value}</Latex>
                            </div>
                        )}
                        {/*CONTACT ITEM RENDER*/}
                        {item.type_code === "CONTACT" && (
                            <div className="level2-contact-container">
                                <div className="contact-thumbnail-container">
                                    {thumbnailUrl === "" && (
                                        <img src={"/static/map/svg/contact.svg"} alt="Contact icon"/>
                                    )}
                                    {thumbnailUrl !== "" && (
                                        <img src={thumbnailUrl} alt="User thumbnail"/>
                                    )}
                                </div>
                                <div className="contact-infos-container">
                                    <div className="contact-infos">
                                        <div className="info">
                                            <div className="text">{attributes.filter(attr => attr.name === "email")[0].value !== "" ? attributes.filter(attr => attr.name === "email")[0].value : "no email"}</div>
                                            {attributes.filter(attr => attr.name === "email")[0].value !== "" && (
                                                <div className="icon" id="contact-copy-email-btn"><MdContentCopy size={!small ? 14 : 10}/></div>
                                            )}
                                        </div>
                                        <div className="info">
                                            <div className="text">{attributes.filter(attr => attr.name === "phone")[0].value !== "" ? attributes.filter(attr => attr.name === "phone")[0].value : "no phone"}</div>
                                            {attributes.filter(attr => attr.name === "phone")[0].value !== "" && (
                                                <div id="contact-copy-phone-btn" className="icon"><MdContentCopy size={!small ? 14 : 10}/></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {item.type_code === "COMPANY" && (
                            <div className="level2-contact-container">
                                <div className="contact-thumbnail-container">
                                    {thumbnailUrl === "" && (
                                        <img src={"/static/map/svg/company.svg"} alt="Contact icon"/>
                                    )}
                                    {thumbnailUrl !== "" && (
                                        <img src={thumbnailUrl} alt="Company thumbnail"/>
                                    )}
                                </div>
                                <div className="contact-infos-container">
                                    <div className="contact-infos">
                                        <div className="info">
                                            <div className="text">{attributes.filter(attr => attr.name === "email")[0].value !== "" ? attributes.filter(attr => attr.name === "email")[0].value : "no email"}</div>
                                        </div>
                                        <div className="info">
                                            <div className="text">{attributes.filter(attr => attr.name === "phone")[0].value !== "" ? attributes.filter(attr => attr.name === "phone")[0].value : "no phone"}</div>
                                        </div>
                                        <div className="info">
                                            <div className="text">{attributes.filter(attr => attr.name === "website")[0].value !== "" ? attributes.filter(attr => attr.name === "website")[0].value : "no website"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>





                    {/*{item.type_code === "BIBTEX" && (*/}
                    {/*    <Level2BibtexRender attributes={attributes} thumbnailUrl={thumbnailUrl} name={item.name}/>*/}
                    {/*)}*/}
                </div>
            </div>
        </>
    )
}

export default TestObjectPopup