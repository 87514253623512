import React, {useEffect, useRef, useState} from "react";
import Editor from '@monaco-editor/react';
import ContentItemAPI from "../../../services/ContentItemAPI";
import {useContentItemStore} from "../../../services/Stores/ContentItemStore";
import html2canvas from "html2canvas";

const FullscreenScriptEditor = ({fileUrl, setSaveStatus, itemUuid, setSaveInProgress, needSave, setNeedSave, publicMap = false}) => {
    const editorOptions = {
        "theme": "vs-dark"
    }

    const [editorLanguage, setEditorLanguage] = useState("html")

    const editorRef = useRef()
    const [saveCodeTimeout, setSaveCodeTimeout] = useState(null)

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
    }

    const [editorValue, setEditorValue] = useState("")

    useEffect(() => {
        if (fileUrl){
            const url  = new URL(fileUrl)

            const fileExtension = url.pathname.split('.')[1]

            if (fileExtension === "js"){
                setEditorLanguage('javascript')
            } else if (fileExtension === "py"){
                setEditorLanguage("python")
            } else if (fileExtension === "php"){
                setEditorLanguage('php')
            }

            fetch(fileUrl)
                .then( r => r.text() )
                .then( t => setEditorValue(t) )
        }
    }, [fileUrl])

    const handleChangeCode = (value, event) => {
        if (publicMap){
            return
        }
        setSaveStatus(false)
        if (saveCodeTimeout){
            clearTimeout(saveCodeTimeout)
        }

        setSaveCodeTimeout(setTimeout(() => {
            handleSaveCode(editorRef.current.getValue()).then()
        }, 5000))
    }

    const handleSaveCode = async (editorValue, regenerateThumbnail = true) => {
        const thumbnail = await takeEditorScreenshot()
        setSaveInProgress(true)
        await ContentItemAPI.updateContentItemTextFile(itemUuid, editorValue, true, "", thumbnail)
        setSaveInProgress(false)
        setSaveStatus(true)
    }

    useEffect(() => {
        if (needSave){
            if (saveCodeTimeout){
                clearTimeout(saveCodeTimeout)
            }
            handleSaveCode(editorRef.current.getValue(), true).then()
            setNeedSave(false)
            useContentItemStore.setState({currentFullscreenItemUuid: null})
        }
    }, [needSave])

    const codeEditorContainerRef = useRef(null)

    const takeEditorScreenshot = async () => {
        let promise = html2canvas(codeEditorContainerRef.current)

        return await promise.then(async (canvas) => {
            return canvas.toDataURL('image/png')
        })
    }

    return (
        <div className="fullscreen-script-container" ref={codeEditorContainerRef}>
            <div className="code-editor-container">
                <Editor height="100%" onMount={handleEditorDidMount} language={editorLanguage} value={editorValue} onChange={handleChangeCode} options={editorOptions}/>
            </div>
        </div>
    )
}

export default FullscreenScriptEditor