import {create} from "zustand";

export const useMoocQuizStore = create((set) => ({
	editQuestions: [],
	testQuestions: [],
	addEditQuestion: (question) => set((state) => ({
		editQuestions: [...state.editQuestions, question]
	})),
	removeEditQuestion: (qIndex) => set((state) => ({
		editQuestions: state.editQuestions.filter((q, index) => qIndex !== index)
	})),
	addQuestionResponse: (qIndex, response) => set((state) => ({
		editQuestions: state.editQuestions.map((q, index) =>
			index === qIndex
				? {...q, responses: [...q.responses, response]}
				: q
		)
	})),
	removeQuestionResponse: (qIndex, rIndex) => set((state) => ({
		editQuestions: state.editQuestions.map((q, qi) =>
			qi === qIndex
				? {...q, responses: q.responses.filter((_, index) => rIndex !== index)}
				: q
		)
	})),
	updateQuestionResponseText: (qIndex, rIndex, value) => set((state) => ({
		editQuestions: state.editQuestions.map((q, qi) =>
			qi === qIndex
				? {...q, responses: q.responses.map((r, index) => index === rIndex ? {...r, text: value} : r)}
				: q
		)
	})),
	updateQuestionResponseValidState: (qIndex, rIndex, value) => set((state) => ({
		editQuestions: state.editQuestions.map((q, qi) =>
			qi === qIndex
				? {...q, responses: q.responses.map((r, index) => index === rIndex ? {...r, valid: value} : r)}
				: q
		)
	})),
	updateEditQuestionText: (qIndex, value) => set((state) => ({
		editQuestions: state.editQuestions.map((q, index) =>
			qIndex === index
				? {...q, question: value}
				: q
		)
	})),
	updateEditQuestionPoint: (qIndex, value) => set((state) => ({
		editQuestions: state.editQuestions.map((q, index) =>
			qIndex === index
				? {...q, point: value}
				: q
		)
	})),
	updateEditQuestionResponseExplication: (qIndex, value) => set((state) => ({
		editQuestions: state.editQuestions.map((q, qi) =>
			qi === qIndex
				? {...q, responseExplication: value}
				: q
		)
	})),
	checkTestQuestionResponse: (qIndex, rIndex) => set((state) => ({
		testQuestions: state.testQuestions.map((q, qi) =>
			qi === qIndex
				? {...q, responses: q.responses.map((r, ri) => ri === rIndex ? {...r, checked: true} : {...r, checked: false})}
				: q
		)
	})),
	updateTestQuestionValidateState: (qIndex, value) => set((state) => ({
		testQuestions: state.testQuestions.map((q, qi) =>
			qi === qIndex
				? {...q, validate: value}
				: q
		)
	})),
	updateAllTestQuestionValidateState: (value) => set((state) => ({
		testQuestions: state.testQuestions.map((q) => ({...q, validate: value}))
	})),
	resetStore: () => set((state) => ({
		editQuestions: [],
		testQuestions: []
	}))
}))