import React from "react";
import {useLocationChange} from "../services/Utils";
import {useLocation} from "react-router-dom";

const UtilsComponent = () => {
	useLocationChange()

	return null
}

export default UtilsComponent