import {ADMIN_STATS_API, STATS_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function getAllStats(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_stats")
}

function getStatsForSubscriptionPlanDashboard(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_plans_dashboard")
}

function getStatsForStoreDashboard(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_store_dashboard")
}

function getActiveMaps(dayFilter){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_active_maps", {
        params: {
            dayFilter: dayFilter
        }
    })
}

function getAverageMapSize(dayFilter){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_average_map_size", {
        params: {
            dayFilter: dayFilter
        }
    })
}

function getAverageObjectsPerMap(dayFilter){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_average_object_per_map", {
        params: {
            dayFilter: dayFilter
        }
    })
}

function getMapTotalSize(dayFilter){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_map_total_size", {
        params: {
            dayFilter: dayFilter
        }
    })
}

function getMapsStatusChart(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_map_status_chart")
}

function getModelsSalesChart(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_models_sales_chart")
}

function getUserDashboardChart(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_user_dashboard_chart")
}

function getUserCountChart(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_user_count_chart")
}
function getAverageObjectPerUser(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_average_object_per_user")
}
function getAverageMapToOneUser(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_average_map_to_one_user")
}
function getAverageNewsletterSubRate(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_average_newsletter")
}
function getNewUserPerDay(){
    return getAxiosInstance().get(ADMIN_STATS_API + "/get_new_user_per_day")
}

function getUserMapAverageSize(){
    return getAxiosInstance().get(STATS_API + "/get_average_map_size")
}

function getUserMapAverageObject(){
    return getAxiosInstance().get(STATS_API + "/get_average_map_object")
}

function getUserTotalMapSize(){
    return getAxiosInstance().get(STATS_API + "/get_total_map_size")
}

export default {
    getAllStats,
    getStatsForSubscriptionPlanDashboard,
    getStatsForStoreDashboard,
    getActiveMaps,
    getAverageMapSize,
    getAverageObjectsPerMap,
    getMapTotalSize,
    getMapsStatusChart,
    getModelsSalesChart,
    getUserDashboardChart,
    getUserCountChart,
    getAverageMapToOneUser,
    getAverageObjectPerUser,
    getAverageNewsletterSubRate,
    getNewUserPerDay,
    getUserMapAverageSize,
    getUserMapAverageObject,
    getUserTotalMapSize
}