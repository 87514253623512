import getAxiosInstance from "../../../AxiosInstance";
import {USER_MOOC_CHAPTER_API, USER_MOOC_COURSE_API} from "../../../../../Router";
import {useMoocStore} from "../../../Stores/MoocStore";

function userGetAllChapterCourses(chapterUuid){
	return getAxiosInstance().get(USER_MOOC_COURSE_API + '/get_all_chapter_courses', {params: {chapterUuid: chapterUuid}})
}

function userGetCourseImageUrl(courseUuid, key){
	return getAxiosInstance().get(USER_MOOC_COURSE_API + '/get_course_image_url', {params: {courseUuid: courseUuid, key: key}})
}

function userCreateMoocCourse(chapterUuid){
	const sendData = new FormData()
	sendData.append('chapterUuid', chapterUuid)

	return getAxiosInstance().post(USER_MOOC_COURSE_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return {'uuid': response.data.uuid, 'position': response.data.position}
			}

			return false
		})
		.catch(() => {
			return false
		})
}

function userUploadCourseImage(file){
	const sendData = new FormData()
	sendData.append('file', file)
	sendData.append('courseUuid', useMoocStore.getState().currentSelectedCourse.uuid)

	return getAxiosInstance().post(USER_MOOC_COURSE_API + '/upload_course_image', sendData)
		.then((response) => {
			return {
				success: 1,
				file: {
					url: response.data.url,
					key: response.data.key
				}
			}
		})
}

function userUpdateCourseContent(content){
	const sendData = new FormData()
	sendData.append('content', JSON.stringify(content))
	sendData.append('courseUuid', useMoocStore.getState().currentSelectedCourse.uuid)

	return getAxiosInstance().post(USER_MOOC_COURSE_API + '/update_content', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userUpdateCoursePosition(uuid, newPosition){
	const sendData = new FormData()
	sendData.append('courseUuid', uuid)
	sendData.append('newPosition', newPosition)

	return getAxiosInstance().post(USER_MOOC_COURSE_API + '/update_position', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userRenameCourse(uuid, name){
	const sendData = new FormData()
	sendData.append('courseUuid', uuid)
	sendData.append('name', name)

	return getAxiosInstance().post(USER_MOOC_COURSE_API + '/rename', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userUpdateCourseLocation(uuid, x, y, zoom){
	const sendData = new FormData()
	sendData.append('courseUuid', uuid)
	sendData.append('x', x)
	sendData.append('y', y)
	sendData.append('zoom', zoom)

	return getAxiosInstance().post(USER_MOOC_COURSE_API + '/update_location', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userLinkCourseItem(uuid, itemUuid, zoom){
	const sendData = new FormData()
	sendData.append('courseUuid', uuid)
	sendData.append('itemUuid', itemUuid)
	sendData.append('zoom', zoom)

	return getAxiosInstance().post(USER_MOOC_COURSE_API + '/link_item', sendData)
		.then((response) => {
			//Update course position in store
			useMoocStore.getState().updateCourseLocation(
				useMoocStore.getState().openedMoocChapters.filter(c => c.courses !== undefined && c.courses !== null && c.courses.some(e => e.uuid === uuid))[0].uuid,
				uuid, {x: response.data.location.x, y: response.data.location.y}, response.data.location.zoom
			)

			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userDeleteMoocCourse(uuid){
	return getAxiosInstance().delete(USER_MOOC_COURSE_API + '/delete', {params: {uuid: uuid}})
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

export default {
	userGetAllChapterCourses,
	userCreateMoocCourse,
	userUploadCourseImage,
	userUpdateCourseContent,
	userRenameCourse,
	userUpdateCourseLocation,
	userGetCourseImageUrl,
	userLinkCourseItem,
	userDeleteMoocCourse,
	userUpdateCoursePosition
}