import {useMapStore} from "../Stores";
import RefreshTilesUtils from "../RefreshTilesUtils";
import L from "leaflet";
import {useMapZoneStore} from "../Stores/MapZoneStore";
import {generateAreaMock, generateInvisibleAreaMock} from "./MockUtils";
import TangramLayerUtils from "../TangramLayerUtils";
import {addZoneMockEvents, addZoneSelectedMockEvents} from "./MockEventsUtils";
import AreaAPI from "../AreaAPI";
import {useMapContextMenuStore} from "../Stores/MapContextMenuStore";
import {generateZoneSelectedMenu} from "./MapSelectedMenusUtils";
import {useContentItemStore} from "../Stores/ContentItemStore";
import UserAreaAPI from "../API/User/UserAreaAPI";
import {createLoadingToast, createSuccessToast, removeToast} from "../CustomToastUtils";
import {useMapGlobalSelectionStore} from "../Stores/MapGlobalSelectionStore";

export function renderNewZone(bounds){
	//Refresh area and area title layer
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_areas', bounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_area_labels', bounds, true).then()
		}
	})
}

export function updateZoneLocation(newBounds, oldBounds){
	//Refresh area and area title layer
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_areas', newBounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_area_labels', newBounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_areas', oldBounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_area_labels', oldBounds, true).then()
		}
	})
}

export function updateZoneShape(bounds){
	//Refresh area
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_areas', bounds, true).then()
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_area_labels', bounds, true).then()
		}
	})
}

export function updateZoneTitle(bounds){
	//Refresh area
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_area_labels', bounds, true).then()
		}
	})
}

export function convertZoneLocationToBounds(location) {
	//Calculate bounds
	const dx = location.width * 0.5
	const dy = location.height * 0.5

	return [
		parseFloat(location.x) - dx,
		parseFloat(location.y) - dy,
		parseFloat(location.x) + dx,
		parseFloat(location.y) + dy
	]
}

export function convertZoneBoundsToLocation(bounds){
	return {
		width: Math.abs(bounds.x1 - bounds.x0),
		height: Math.abs(bounds.y1 - bounds.y0),
		x: 0.5 * (bounds.x0 + bounds.x1),
		y: 0.5 * (bounds.y0 + bounds.y1)
	}
}

export function getMockWidth(mock){
	const newBounds = mock.getBounds()
	const northWestPoint = L.Projection.SphericalMercator.project(newBounds.getNorthWest())
	const southEastPoint = L.Projection.SphericalMercator.project(newBounds.getSouthEast())

	return Math.abs(northWestPoint.x - southEastPoint.x).toFixed(2)
}

export function getMockHeight(mock){
	const newBounds = mock.getBounds()
	const northWestPoint = L.Projection.SphericalMercator.project(newBounds.getNorthWest())
	const southEastPoint = L.Projection.SphericalMercator.project(newBounds.getSouthEast())

	return Math.abs(northWestPoint.y - southEastPoint.y).toFixed(2)
}

export function getMockBounds(mock){
	const newBounds = mock.getBounds()
	const northWestPoint = L.Projection.SphericalMercator.project(newBounds.getNorthWest())
	const southEastPoint = L.Projection.SphericalMercator.project(newBounds.getSouthEast())

	return [
		northWestPoint.x,
		southEastPoint.y,
		southEastPoint.x,
		northWestPoint.y
	]
}

export function detectAreaTitleHover(selection){
	//Cancel if global selection
	if (useMapGlobalSelectionStore.getState().selectionLayer !== null){
		return;
	}

	//Get feature from selection
	const feature = selection.feature

	//Check if feature exist
	if (feature !== undefined){
		if (useMapZoneStore.getState().zoneCurrentDragState) {
			return;
		}

		//Ignore hover if current feature is already selected
		if (useMapZoneStore.getState().currentSelectedZone === feature.properties.id){
			return
		}

		//Set feature id in store
		useMapZoneStore.setState({hoveredAreaUuid: feature.properties.id})

		//Check if area is already on move
		if (useMapZoneStore.getState().hoveredAreaUuid !== null && useMapZoneStore.getState().hoveredAreaMock !== null){
			return
		}

		//Generate invisible mock for hovered zone
		const zoneMock = generateInvisibleAreaMock(TangramLayerUtils.getAreaLatLng(feature.properties), feature.properties['border_width'])

		//Add new mock to map instance and set to store
		zoneMock.addTo(useMapStore.getState().mapInstance)
		useMapZoneStore.setState({hoveredAreaMock: zoneMock})

		//Add event to mock
		addZoneMockEvents(zoneMock)
	} else {
		//Check if no area is currently on drag
		if (!useMapZoneStore.getState().zoneCurrentDragState) {
			//Delete mock and reset hovered data if no feature hovered
			if (useMapZoneStore.getState().hoveredAreaMock) {
				useMapZoneStore.getState().hoveredAreaMock.remove()
				useMapZoneStore.setState({hoveredAreaMock: null})
			}
			useMapZoneStore.setState({hoveredAreaUuid: null})
		}
	}
}

export function detectAreaTitleClick(selection) {
	//Cancel if global selection
	if (useMapGlobalSelectionStore.getState().selectionLayer !== null){
		return;
	}

	//Get feature from selection
	const feature = selection.feature

	//Check if feature exist
	if (feature !== undefined){
		//Ignore click event if current feature is already selected
		if (useMapZoneStore.getState().currentSelectedZone === feature.properties.id){
			return
		}

		//Unselect previous selected area if exist
		if (useMapZoneStore.getState().currentSelectedZone !== null){
			unselectZone()
		}

		//Add selected area uuid to store
		useMapZoneStore.setState({currentSelectedZone: feature.properties.id, currentSelectedZoneProperties: feature.properties})

		//Generate mock for area
		const areaMock = generateAreaMock(TangramLayerUtils.getAreaLatLng(feature.properties), feature.properties['border_width'])

		//Add mock to map instance
		areaMock.addTo(useMapStore.getState().mapInstance)

		//Add mock to store
		useMapZoneStore.setState({currentSelectedMock: areaMock})

		//Add mock event
		addZoneSelectedMockEvents(areaMock, feature.properties)

		//Generate selected menu
		generateZoneSelectedMenu(areaMock, feature.properties, false)
	} else {
		if (!useMapZoneStore.getState().zoneCurrentDragState) {
			//Remove area mock if exist
			if (useMapZoneStore.getState().currentSelectedMock !== null) {
				useMapZoneStore.getState().currentSelectedMock.remove()
				useMapZoneStore.setState({currentSelectedMock: null})
			}

			//Remove selected menu if exist
			if (useMapZoneStore.getState().currentSelectedPopup !== null){
				useMapZoneStore.getState().currentSelectedPopup.remove()
				useMapZoneStore.setState({currentSelectedPopup: null})
			}

			useMapZoneStore.setState({currentSelectedZone: null, currentSelectedPopup: null, currentSelectedZoneProperties: null})
		}
	}
}

export function selectZoneFromFeature(feature, rename = false){
	//Close all context menu
	useMapContextMenuStore.getState().resetStore()

	//Ignore if current feature is already selected
	if (useMapZoneStore.getState().currentSelectedZone === feature.properties.id){
		return
	}

	//Add selected area uuid to store
	useMapZoneStore.setState({currentSelectedZone: feature.properties.id})

	//Generate mock for area
	const areaMock = generateAreaMock(TangramLayerUtils.getAreaLatLng(feature.properties), feature.properties['border_width'])

	//Add mock to map instance
	areaMock.addTo(useMapStore.getState().mapInstance)

	//Add mock to store
	useMapZoneStore.setState({currentSelectedMock: areaMock})

	//Add mock event
	addZoneSelectedMockEvents(areaMock, feature.properties)

	//Generate selected menu
	generateZoneSelectedMenu(areaMock, feature.properties, rename)
}

export function unselectZone(){
	//Remove area mock if exist
	if (useMapZoneStore.getState().currentSelectedMock !== null) {
		useMapZoneStore.getState().currentSelectedMock.remove()
		useMapZoneStore.setState({currentSelectedMock: null})
	}

	//Remove selected menu if exist
	if (useMapZoneStore.getState().currentSelectedPopup !== null){
		useMapZoneStore.getState().currentSelectedPopup.remove()
		useMapZoneStore.setState({currentSelectedPopup: null})
	}

	useMapZoneStore.setState({currentSelectedZone: null, currentSelectedPopup: null})
}

export async function deleteSelectedZone() {
	//Get selected area bounds
	const bounds = getMockBounds(useMapZoneStore.getState().currentSelectedMock)

	//Delete selected area
	await UserAreaAPI.userDeleteArea(useMapZoneStore.getState().currentSelectedZone, false)

	//Refresh area tiles
	updateZoneShape(bounds)

	//Update store and remove selected menu and mock
	if (useMapZoneStore.getState().currentSelectedMock){
		useMapZoneStore.getState().currentSelectedMock.remove()
	}
	if (useMapZoneStore.getState().currentSelectedPopup){
		useMapZoneStore.getState().currentSelectedPopup.remove()
	}
	useMapZoneStore.setState({currentSelectedZone: null, currentSelectedPopup: null, currentSelectedMock: null})
}

export async function deleteZone(properties, check = false, withContent = false) {
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Open confirm delete popup
	if (check){
		useMapZoneStore.setState({confirmDeletePopupState: true, deletedZoneProperties: properties})

		return
	}

	//Calculate bounds
	const dx = properties.width * 0.5
	const dy = properties.height * 0.5

	const bounds = [
		parseFloat(properties.xc) - dx,
		parseFloat(properties.yc) - dy,
		parseFloat(properties.xc) + dx,
		parseFloat(properties.yc) + dy
	]

	//Create loading toast
	const toastId = createLoadingToast("Zone delete in progress")

	//Delete area in api
	if (await UserAreaAPI.userDeleteArea(properties.id, withContent)){
		//Close toast
		removeToast(toastId)

		//Unselect zone
		unselectZone()

		//Refresh area tiles
		updateZoneShape(bounds)
	}
}

export function detectAndOpenZoneContextMenu(selection, pixel){
	if (selection === undefined){
		return
	}

	//Get feature from selection
	const feature = selection.feature

	//Check if feature exist
	if (feature !== undefined){
		//Close all context menu
		useMapContextMenuStore.getState().resetStore()

		//Open zone context menu
		useMapContextMenuStore.getState().openZoneMenu(feature.properties.id, pixel.x, pixel.y, feature)
	}
}

export function pasteZone(fromMouse) {
	//Get paste position
	let position;
	if (!fromMouse){
		position = useMapContextMenuStore.getState().containerPoint
		position = useMapStore.getState().mapInstance.containerPointToLatLng(L.point(position.x, position.y))
		position = L.Projection.SphericalMercator.project(position)
	} else {
		position = L.Projection.SphericalMercator.project(L.latLng(useMapStore.getState().mouseLatLng.lat, useMapStore.getState().mouseLatLng.lng))
	}

	//Get zone uuid and copy type
	const zoneUuid = useMapZoneStore.getState().currentCopiedZone
	const copyType = useMapZoneStore.getState().copyType

	//Get target and origin zoom level
	const targetZoom = Math.floor(useMapStore.getState().mapInstance.getZoom())
	const originZoom = Math.floor(useMapZoneStore.getState().copiedOriginZoom)

	//Close context menu and reset copied zone
	useMapContextMenuStore.getState().resetStore()
	// useMapZoneStore.setState({currentCopiedZone: null, copiedOriginZoom: null, copyType: 'copy'})

	//Send api request for move area
	if (copyType === "cut"){
		AreaAPI.moveArea(zoneUuid, position, targetZoom, originZoom)
	} else {
		AreaAPI.copyArea(zoneUuid, position, targetZoom, originZoom)
	}
}