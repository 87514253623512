import React, {useEffect, useState} from "react";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {TailSpin} from "react-loader-spinner";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";
import AdminContentItemCategoryAPI from "../../services/API/Admin/AdminContentItemCategoryAPI";
import AdminContentItemTypeAPI from "../../services/API/Admin/AdminContentItemTypeAPI";
import AdminCreateContentItemCategoryMenu from "../../components/DashboardSideMenus/AdminCreateContentItemCategoryMenu";

const AdminDashboardObjectType = () => {
    //Define default state for get type and get category request status
    const [getCategoriesRequestStatus, setGetCategoriesRequestStatus] = useState(false)
    const [getTypeRequestStatus, setGetTypeRequestStatus] = useState(false)

    //Define default state for categories list
    const [categories, setCategories] = useState([{
        'code': "",
        'name': "",
        'description': "",
        'associatedType': 0
    }])

    //Define default state for types list
    const [types, setTypes] = useState([{
        'code': "",
        'category': "",
        'name': "",
        'icon': "",
        'description': "",
        'mimeType': [],
        'authorizedDomains': [],
        'thumbnail': false,
        'availableFields': []
    }])

    //Get data
    useEffect(() => {
        AdminContentItemCategoryAPI.adminGetAllContentItemCategories().then(response => {
            if (response.status === 200){
                setCategories(response.data)
            } else {
                setCategories([])
            }
            setGetCategoriesRequestStatus(true)
        })

        AdminContentItemTypeAPI.adminGetAllContentItemTypes().then(response => {
            if (response.status === 200){
                setTypes(response.data)
            } else {
                setTypes([])
            }
            setGetTypeRequestStatus(true)
        })
    }, [])

    /***************************** PAGINATION CATEGORIES *************************/
    const [currentPageCategories, setCurrentPageCategories] = useState(1)
    const [itemPerPageCategories, setItemPerPageCategories] = useState(10)
    const maxPageCategories = Math.ceil(categories.length / itemPerPageCategories)

    const nextPageCategories = () => {
        setCurrentPageCategories((currentPageCategories) => Math.min(currentPageCategories + 1, maxPageCategories))
    }
    const previousPageCategories = () => {
        setCurrentPageCategories((currentPageCategories) => Math.max(currentPageCategories - 1, 1))
    }

    const startPageCategories = (currentPageCategories - 1) * itemPerPageCategories
    const endPageCategories = startPageCategories + itemPerPageCategories
    const paginatedCategories = categories.slice(startPageCategories, endPageCategories)

    const pageButtonCategories = []

    for (let i = 0; i < maxPageCategories; i++){
        pageButtonCategories.push(<p onClick={() => setCurrentPageCategories(i + 1)} className={currentPageCategories === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /***************************** PAGINATION CATEGORIES *************************/
    const [currentPageTypes, setCurrentPageTypes] = useState(1)
    const [itemPerPageTypes, setItemPerPageTypes] = useState(10)
    const maxPageTypes = Math.ceil(types.length / itemPerPageTypes)

    const nextPageTypes = () => {
        setCurrentPageTypes((currentPageTypes) => Math.min(currentPageTypes + 1, maxPageTypes))
    }
    const previousPageTypes = () => {
        setCurrentPageTypes((currentPageTypes) => Math.max(currentPageTypes - 1, 1))
    }

    const startPageTypes = (currentPageTypes - 1) * itemPerPageTypes
    const endPageTypes = startPageTypes + itemPerPageTypes
    const paginatedTypes = types.slice(startPageTypes, endPageTypes)

    const pageButtonTypes = []

    for (let i = 0; i < maxPageTypes; i++){
        pageButtonTypes.push(<p onClick={() => setCurrentPageTypes(i + 1)} className={currentPageTypes === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    return (
        <>
            {/*NEW MAINTENANCE MODAL*/}
            <TopNavBar />
            <SideBarNav />
            <AdminCreateContentItemCategoryMenu setState={() => null} state={true} />
            <main className="dashboard">
                <div className="admin-dashboard">
                    <h1 className="page-title">Overview</h1>
                    <div className="dashboard-table mt">
                        <p className="title">List of object categories</p>
                        <div className="top-container">
                            <div className="left">
                                <div className="filter-btn">Create new category</div>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <td>Code</td>
                                    <td>Name</td>
                                    <td>Description</td>
                                    <td>Associated type</td>
                                </tr>
                            </thead>
                            <tbody>
                            {getCategoriesRequestStatus && (
                                <>
                                    {paginatedCategories.length > 0 && (
                                        <>
                                            {paginatedCategories.map((category, index) => (
                                                <tr key={index}>
                                                    <td>{category.code}</td>
                                                    <td>{category.name}</td>
                                                    <td>{category.description}</td>
                                                    <td>{category.associatedType}</td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedCategories.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No category</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getCategoriesRequestStatus && (
                                <tr>
                                    <td colSpan="100%" style={{textAlign: 'center'}}><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPageCategories(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPageCategories}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtonCategories.length > 0 && (
                                        pageButtonCategories
                                    )}
                                    {pageButtonCategories.length === 0 && (
                                        <p className="selected">1</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPageCategories}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {startPageCategories + 1} to {endPageCategories} of {paginatedCategories.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of object type</p>
                        <div className="top-container">
                            <div className="left">
                                <div className="filter-btn">Create new type</div>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <td>Code</td>
                                    <td>Category</td>
                                    <td>Name</td>
                                    <td>Description</td>
                                    <td>thumbnail</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                            {getTypeRequestStatus && (
                                <>
                                    {paginatedTypes.length > 0 && (
                                        <>
                                            {paginatedTypes.map((type, index) => (
                                                <tr key={index}>
                                                    <td>{type.code}</td>
                                                    <td>{type.category}</td>
                                                    <td>{type.name}</td>
                                                    <td>{type.description}</td>
                                                    <td>{type.thumbnail ? "Yes" : "No"}</td>
                                                    <td className='action-cell'>
                                                        <p className="delete"><MdDelete /></p>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedTypes.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No type</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getTypeRequestStatus && (
                                <tr>
                                    <td colSpan="100%" style={{textAlign: 'center'}}><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPageTypes(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPageTypes}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtonTypes.length > 0 && (
                                        pageButtonTypes
                                    )}
                                    {pageButtonTypes.length === 0 && (
                                        <p className="selected">1</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPageTypes}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {startPageTypes + 1} to {endPageTypes} of {paginatedTypes.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <p className="copy">&copy; Weeki 2021</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardObjectType