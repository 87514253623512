import {create} from "zustand";
import map_tuto_image from "../../../images/tuto/map-tuto-image.svg"
import arrow from "../../../images/tuto/arrow.svg"

export const useTutorialStore = create((set) => ({
	mapTutoState: false,
	dashboardTutoState: false,
	mapTutoSequence: [
		{
			"title": "Introducing Weeki:",
			"arrow": null,
			"position": 'center',
			"content": [
				{type: "title-text", content: "Unleashing Hierarchical Knowledge Mapping for Seamless Organisation"},
				{type: "text", content: "Weeki is not your typical canvas or whiteboard - it's a dynamic system that operates as a knowledge map, resembling a sophisticated graph or network. This platform empowers you to construct multi-level boards, creating a robust structure for your hierarchical models."},
				{type: "text", content: "The concept revolves around crafting zones that represent your folders, allowing you to establish a multi-tiered framework. By nesting zones within zones, you effortlessly build a hierarchical structure to neatly organize all your files and ideas."},
				{type: "text", content: "The beauty lies in the simplicity of zooming ion on the specific zone you wish to explore. Each element is intelligently represented across various observation levels to maintain a global view without overwhelming the interface."},
				{type: "subtitle-text", content: "Elevate your capabilities with Weeki - a powerful tool for visualizing, organizing, and navigating complex data structures."},
				{type: "image", content: map_tuto_image},
			]
		},
		{
			"title": null,
			"arrow": 'left-top',
			"position": 'move',
			"top": 244,
			"left": 110,
			"content": [
				{type: "title-text", content: "3 way to add Objects in Weeki"},
				{type: "text", content: `Use the “+” in the Toolbar.`, icon: arrow},
				{type: "text", content: "To add an object, click the \"+\" button in the toolbar. This will open a pop-up selector where you can choose the object type and fill in the necessary details manually."},
				{type: "text", content: `Right-click anywhere on your map.`, icon: arrow},
				{type: "text", content: `Select “Add Object” from the context menu that appears. This will also open the pop-up selector for you to choose the object type and fill in the details.`},
				{type: "text", content: `Drag and drop files directly onto your board.`, icon: arrow},
				{type: "text", content: `Find the file you want to add, drag it onto the map, and drop it in the desired location. Weeki will automatically detect the file type and add it as an object.`},
				{type: "text", content: `About zoom level ⚠`, icon: arrow},
				{type: "text", content: `Your zoom level will determine the zoom interval of the object when you add it !`},
			]
		},
		{
			"title": null,
			"arrow": 'left-top',
			"position": 'move',
			"top": 287,
			"left": 110,
			"content": [
				{type: "title-text", content: "Organize different clusters on your board with zones."},
				{type: "text", content: `Think zone as a simple way to create areas / folders on you board.`},
				{type: "text", content: `The areas can be grouped by overlays.`},
				{type: "text", content: `You will be able to use the areas as filters or as a means of navigating the board.`},
			]
		},
		{
			"title": null,
			"arrow": 'left-top',
			"position": 'move',
			"top": 117,
			"left": 110,
			"content": [
				{type: "title-text", content: "Organize zones through different layers"},
				{type: "text", content: `The layer system, also known as overlays, allows you to create different groups to structure information on several layers. Each time you wants to add an object or an area, these are added to the selected layer.`},
				{type: "text", content: `A small icon opens a menu displaying all the layers present on the whiteboard. From this menu, you can rename layers and move objects and areas to different layers.`}
			]
		},
		{
			"title": null,
			"arrow": 'top-right',
			"position": 'move',
			"top": 75,
			"right": 244,
			"content": [
				{type: "title-text", content: "Two tool are available for you to collaborate"},
				{type: "text", content: `A sharing popup`, icon: arrow},
				{type: "text", content: `The sharing pop-up lets you invite collaborators to Workspaces, projects, tables, zones or even sub-tables. It operates in a hierarchical fashion, allowing you to view all collaborators on each and every element of the hierarchy.`},
				{type: "text", content: `It also allows you to give certain rights to collaborators, so that they can choose whether they are editors, commentators or simply have guest access for viewing purposes only.`},
				{type: "text", content: `A board presentation popup`, icon: arrow},
				{type: "text", content: `On the virtual whiteboard, a small book-shaped icon opens a pop-up summarizing all the useful information on the whiteboard. This pop-up opens to a large screen, providing a global view of the designers' entire project.`},
			]
		},
	]
}))