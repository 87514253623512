import React from "react";

const FullscreenPicture = ({fileUrl}) => {
    return (
        <div className="fullscreen-picture-container">
            <img src={fileUrl} alt="Picture"/>
        </div>
    )
}

export default FullscreenPicture