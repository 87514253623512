import React, {useContext, useEffect, useRef, useState} from "react"
import L from 'leaflet'
import "leaflet-draw"
import "leaflet/dist/leaflet.css"
import "leaflet-path-transform/dist/L.Path.Transform"
import TangramLayerUtils from "../services/TangramLayerUtils";
import TestObjectPopup from "./Map/ObjectPopup/TestObjectPopup";
import ReactDOMServer from "react-dom/server";
import ContentItemAPI from "../services/ContentItemAPI";
import ObjectContextMenu from "./ObjectContextMenu";
import MapContextMenu from "./MapContextMenu";
import {toast} from "react-toastify";
import AreaAPI from "../services/AreaAPI";
import ZoneSideMenu from "./Map/ZoneSideMenu";
import ZoneInfoModal from "./Map/ZoneInfoModal";
import AreaPopupMenu from "./Map/AreaPopupMenu";
import AreaPopupMenuUtils from "../services/AreaPopupMenuUtils";
import {RGBToHex} from "../services/Utils";
import RefreshTilesUtils from "../services/RefreshTilesUtils";
import ZoneContextMenu from "./ZoneContextMenu";
import Level1SelectedPopup from "./Map/ObjectPopup/Level1SelectedPopup";
import {useMapStore, useMercureStore} from "../services/Stores";
import {SimpleMapScreenshoter} from "leaflet-simple-map-screenshoter";
import MapAPI from "../services/MapAPI";
import {TailSpin} from "react-loader-spinner";
import SelectedObjectMenu from "./Map/ObjectPopup/SelectedObjectMenu";
import {addCreateTextItemPopupEvents} from "../services/Utils/PopupEventsUtils";
import {
    generateLevel1Popup,
    generateLevel2Popup,
    generateTextItemAddPopup,
    generateTextItemPopup
} from "../services/Utils/MapPopupUtils";
import TextItemAPI from "../services/API/User/TextItemAPI";
import PublicsContentItemAPI from "../services/API/Publics/PublicsContentItemAPI";
import ShapeItemAPI from "../services/API/User/ShapeItemAPI";
import "@geoman-io/leaflet-geoman-free"
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css"
import {generateInvisibleAreaMock} from "../services/Utils/MockUtils";
import {features} from "monaco-editor/esm/metadata";
import {renderLevel2Items, updateLevel2Content} from "../services/Utils/RenderLevel2Utils";
import {pasteContentItem, unselectMapContentItem, unselectMapTextItem} from "../services/Utils/ItemUtils";
import {mapContentItemUpdateListener} from "../services/Utils/MapUpdateListenersUtils";
import {useContentItemStore} from "../services/Stores/ContentItemStore";
import {renderTextItems} from "../services/Utils/RenderTextItemsUtils";
import {
    deleteSelectedZone, deleteZone,
    detectAndOpenZoneContextMenu,
    getMockBounds, pasteZone,
    renderNewZone,
    updateZoneLocation
} from "../services/Utils/ZoneUtils";
import {
    deleteShapeItem,
    detectAndOpenShapeContextMenu,
    detectShapeItemClick,
    renderNewShapeItem
} from "../services/Utils/ShapeItemUtils";
import {useMapZoneStore} from "../services/Stores/MapZoneStore";
import {useMapContextMenuStore} from "../services/Stores/MapContextMenuStore";
import ZoneContextMenu2 from "./Map/ContextMenu/ZoneContextMenu";
import MapContextMenu2 from "./Map/ContextMenu/MapContextMenu";
import ContentItemContextMenu from "./Map/ContextMenu/ContentItemContextMenu";
import ShapeContextMenu from "./Map/ContextMenu/ShapeContextMenu";
import {useMapShapeItemStore} from "../services/Stores/MapShapeItemStore";
import {MathJaxBaseContext} from "better-react-mathjax";
import {clearHoveredLevel1} from "../services/Utils/Level1ItemUtils";
import {createSuccessToast} from "../services/CustomToastUtils";
import uploadFileComponent from "./customs/UploadFileComponent";
import {useTextItemStore} from "../services/Stores/TextItemStore";
import {deleteSelectedTextItem} from "../services/Utils/TextItemUtils";
import {
    convertLatLngBoundsToCoordinatesBounds,
    isPointInsidePolygon, regenerateSelectionLayer,
    removeGlobalSelection, renderGlobalSelectedArea,
    renderGlobalSelectedItems, renderGlobalSelectedShapes,
    selectMapData, updateGlobalSelectionItemRect
} from "../services/Utils/MapSelectionUtils";
import {useMapGlobalSelectionStore} from "../services/Stores/MapGlobalSelectionStore";
import UserMapAPI from "../services/API/User/UserMapAPI";
import {updateMapLocation} from "../services/Utils/GlobalMapContentItemUpdateUtils";

const LeafletMap = ({setCurrentLayerPoint, currentLayerPoint, zoom, setZoom, mapUuid, handleOpenObject, handleOpenFullScreen, viewObjectOnMap, setViewObjectOnMap, handleDeleteContentItem, zoneSideMenuStatus, setZoneSideMenuStatus, currentMapCenter, setCurrentMapCenter, currentOverlay, mapSearchApiKey, handleOpenSidebarObject, setOpenedSidebarObject, createTextStatus, setCreateTextStatus, publicMap = false}) => {
    const mapCenter = useMapStore(state => state.mapCenter)
    const mapZoom = useMapStore(state => state.mapZoom)

    //Get data in store
    const mapLayers = useMapStore(state => state.leafletLayer)
    const ignoreUpdates = useMapStore(state => state.ignoreUpdates)
    const removeUpdate = useMapStore(state => state.removeUpdateId)
    const ignoreRender = useMapStore(state => state.ignoreRender)
    const addNewIgnoreRender = useMapStore(state => state.addNewIgnoreRender)
    const userAuthorizations = useMapStore(state => state.userAuthorizations)

    const globalSelectedItems = useMapGlobalSelectionStore(state => state.selectedItems)
    const globalSelectedAreas = useMapGlobalSelectionStore(state => state.selectedAreas)
    const globalSelectedShapes = useMapGlobalSelectionStore(state => state.selectedShapes)
    const globalSelectionLayerGroup = useMapGlobalSelectionStore(state => state.selectionLayerGroup)
    const globaleSelectionLayer = useMapGlobalSelectionStore(state => state.selectionLayer)
    const globalDrawCurrentPosition = useMapGlobalSelectionStore(state => state.drawCurrentPosition)
    const globalDrawStartPosition = useMapGlobalSelectionStore(state => state.drawStartPosition)
    const globalDrawState = useMapGlobalSelectionStore(state => state.drawState)

    const copySelectionState = useMapGlobalSelectionStore(state => state.copySelectionState)


    // console.log(globalSelectionLayerGroup)

    // console.log(globalSelectionLayerGroup?.getLayers())
    useEffect(() => {
        //Check if items need to be selected
        if (globalSelectedItems.length > 0) {
            renderGlobalSelectedItems()
        }
    }, [globalSelectedItems])

    useEffect(() => {
        //Check if area need to be selected
        if (globalSelectedAreas.length > 0) {
            renderGlobalSelectedArea()
        }
    }, [globalSelectedAreas])

    useEffect(() => {
        //Check if area need to be selected
        if (globalSelectedShapes.length > 0) {
            renderGlobalSelectedShapes()
        }
    }, [globalSelectedShapes])

    useEffect(() => {
        if (globaleSelectionLayer){
            globaleSelectionLayer.on('pm:dragstart', (event, layer) => {
                useMapGlobalSelectionStore.setState({selectionDragState: true})
            })

            globaleSelectionLayer.on('pm:dragend', async (event, layer) => {
                //Get copy state in store
                const copyState = useMapGlobalSelectionStore.getState().copySelectionState

                useMapGlobalSelectionStore.setState({selectionDragState: false})
                useMapStore.setState({selectMode: false})

                const endBounds = event.layer.getBounds()

                //Get new selection center and start center
                const selectionCenterLatLng = event.layer.getBounds().getCenter()
                const startSelectionCenterLatLng = useMapGlobalSelectionStore.getState().startCenterLatLng

                //Convert center latLng to point
                const selectionCenterPoint = L.Projection.SphericalMercator.project(selectionCenterLatLng)
                const startSelectionCenterPoint = L.Projection.SphericalMercator.project(startSelectionCenterLatLng)

                if (await UserMapAPI.userMoveMapSelection(mapUuid, selectionCenterPoint.x, selectionCenterPoint.y, startSelectionCenterPoint.x, startSelectionCenterPoint.y, copyState)) {
                    //Update start and end bounds
                    updateMapLocation(convertLatLngBoundsToCoordinatesBounds(useMapGlobalSelectionStore.getState().startBounds.extend(endBounds)))

                    removeGlobalSelection()
                }
            })
        }
    }, [globaleSelectionLayer]);

    const areaEditStatus = useRef(false)
    const areaUuid = useRef("")
    const areaEditType = useRef("")
    const areaBorderColorMenu = useRef(false)
    const areaBackgroundColorMenu = useRef(false)
    const areaBorderStyleMenu = useRef(false)
    const areaMoveStatus = useRef(false)
    const areaBorderColor = useRef("#E5E5E5")
    const areaBackgroundColor = useRef("#FDFDFD")
    const areaOpacity = useRef(1)
    const areaBorderWidth = useRef(1)
    const areaPopup = useRef(null)
    const areaRect = useRef(null)

    const shapeMock = useRef(null)
    const shapeMockType = useRef(null)
    const shapeMockUuid = useRef(null)

    const drawAreaStatus = useMapStore(state => state.drawAreaStatus)

    //Detect key up map event
    const detectKeyUpMapEvent = (event) => {
        // if (event.key === 'Control'){
        //     //Disable select mode
        //     if (selectMode){
        //         useMapStore.setState({selectMode: false})
        //     }
        // }
    }

    //Detect key down event on all map
    const detectKeyDownMapEvent = async (event) => {
        //Detect backspace and delete key
        if (event.key === "Delete" || event.key === "Backspace") {
            //Check global selection delete
            if (globaleSelectionLayer) {
                if (await UserMapAPI.userDeleteMapSelection(mapUuid)) {
                    //Update selection bounds
                    updateMapLocation(convertLatLngBoundsToCoordinatesBounds(useMapGlobalSelectionStore.getState().selectionLayer.getBounds()))
                    removeGlobalSelection()
                }
                return;
            }

            //Delete selected area if area has been selected
            if (useMapZoneStore.getState().currentSelectedZone !== null) {
                // deleteSelectedZone()
                deleteZone(useMapZoneStore.getState().currentSelectedZoneProperties, true).then(r => null)
            }

            //Delete selected shape
            if (useMapShapeItemStore.getState().currentSelectedShape !== null) {
                deleteShapeItem(useMapShapeItemStore.getState().currentSelectedShape)
            }

            //Detect selected word
            if (useTextItemStore.getState().currentSelectedItem !== null) {
                const selectedTextPopup = useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === useTextItemStore.getState().currentSelectedItem)[0].popup

                //Get input caret color
                const caretColor = getComputedStyle(selectedTextPopup._contentNode.querySelector('#update-text-item-input')).caretColor

                //Cancel delete if item is in edit
                if (caretColor !== "rgba(0, 0, 0, 0)") {
                    return;
                }

                L.DomEvent.off(selectedTextPopup._contentNode.querySelector('#update-text-item-input'))

                deleteSelectedTextItem()
            }
        }

        //Detect CTRL shortcut
        if (event.ctrlKey) {
            // //Enable select mode
            // if (!selectMode){
            //     useMapStore.setState({selectMode: true})
            // }

            //Detect CTRL X event
            if (event.key === "x") {
                //Detect if zone is selected
                if (useMapZoneStore.getState().currentSelectedZone !== null) {
                    useMapZoneStore.setState({
                        currentCopiedZone: useMapZoneStore.getState().currentSelectedZone,
                        copiedOriginZoom: useMapStore.getState().mapInstance.getZoom(),
                        copyType: 'cut'
                    })
                    createSuccessToast("Zone cut")
                    return;
                }
            }

            //Detect CTRL C event
            if (event.key === "c") {
                //Detect if item is selected
                if (useContentItemStore.getState().currentSelectedItem !== null) {
                    const itemProperties = useContentItemStore.getState().currentSelectedItem
                    useContentItemStore.setState({
                        currentCopiedItem: itemProperties,
                        copyOriginZoom: useMapStore.getState().mapInstance.getZoom(),
                        copyType: 'copy'
                    })
                    createSuccessToast("Item copied")
                    return
                }

                //Detect if zone is selected
                if (useMapZoneStore.getState().currentSelectedZone !== null) {
                    useMapZoneStore.setState({
                        currentCopiedZone: useMapZoneStore.getState().currentSelectedZone,
                        copiedOriginZoom: useMapStore.getState().mapInstance.getZoom(),
                        copyType: 'copy'
                    })
                    createSuccessToast("Zone copied")
                    return;
                }
            }

            //Detect CTRL V event
            if (event.key === "v") {
                //Detect if item has been copied
                if (useContentItemStore.getState().currentCopiedItem !== null) {
                    pasteContentItem(true).then(r => null)
                    return;
                }

                //Detect if zone has been copied
                if (useMapZoneStore.getState().currentCopiedZone !== null) {
                    pasteZone(true)
                    return;
                }
            }
        }
    }

    const handleDetectMapMouseMove = (event) => {
        const latLng = mapInstance.containerPointToLatLng(L.point(event.clientX, event.clientY))
        useMapStore.setState({mouseLatLng: {lat: latLng.lat, lng: latLng.lng}})
    }

    //Generate tangram layer
    const gridLayer = TangramLayerUtils.getGridLayer()
    const areaLayer = TangramLayerUtils.getAreaLayer(mapUuid, publicMap)
    const areaTitleLayer = TangramLayerUtils.getAreaTitleLayer(mapUuid, publicMap)
    const textLayer = TangramLayerUtils.getTextLayer(mapUuid, publicMap)
    const shapeLayer = TangramLayerUtils.getShapeLayer(mapUuid, publicMap)
    const itemsLayer = TangramLayerUtils.getItemsLayer(mapUuid, publicMap)

    //Add leaflet layer to map store
    useEffect(() => {
        useMapStore.getState().addLeafletLayer({'name': "items_layer", 'layer': itemsLayer})
    }, [])

    //Define default state for total layer
    const totalLayerCount = 6
    const [loadedLayer, setLoadedLayer] = useState(0)

    const [selectedItem, setSelectedItem] = useState("")

    const mapInstance = useMapStore(state => state.mapInstance)

    //Get map filters in store
    const mapFilters = useMapStore(state => state.mapFilters)
    useEffect(() => {
        if (mapInstance){
            mapInstance.eachLayer(layer => {
                if (layer._updating_tangram !== undefined) {
                    if (layer.scene.sources['weeki_map_items']){
                        layer.scene.sources['weeki_map_items'].config.url_params.filters = encodeURIComponent(JSON.stringify(mapFilters))
                        layer.scene.updateConfig()
                    } else if (layer.scene.sources['weeki_map_areas']){
                        layer.scene.sources['weeki_map_areas'].config.url_params.filters = encodeURIComponent(JSON.stringify(mapFilters))
                        layer.scene.updateConfig()
                    } else if (layer.scene.sources['weeki_map_area_labels']){
                        layer.scene.sources['weeki_map_area_labels'].config.url_params.filters = encodeURIComponent(JSON.stringify(mapFilters))
                        layer.scene.updateConfig()
                    } else if (layer.scene.sources['weeki_map_shapes']){
                        layer.scene.sources['weeki_map_shapes'].config.url_params.filters = encodeURIComponent(JSON.stringify(mapFilters))
                        layer.scene.updateConfig()
                    }
                }
            })
        }

    }, [mapFilters, mapInstance])

    useEffect(() => {
        if (mapInstance === null){


            let mapInst = L.map('map', {drawControl: false, attributionControl: false, zoomControl: false, maxZoom: 18, minZoom: 3, wheelPxPerZoomLevel: 12, maxBounds: L.latLngBounds(L.latLng(-85, -180), L.latLng(85, 180))}).setView([mapCenter.lat, mapCenter.lng], mapZoom)

            L.LayerGroup.prototype.addLayerOrg = L.LayerGroup.prototype.addLayer;
            L.LayerGroup.prototype.addLayer = function (layer) {
                // console.log("layer add")
                layer.addEventParent(this);
                this.addLayerOrg(layer);
                return this.fire("layeradd", { layer: layer });
            };

            L.LayerGroup.prototype.removeLayerOrg = L.LayerGroup.prototype.removeLayer;
            L.LayerGroup.prototype.removeLayer = function (layer) {
                layer.removeEventParent(this);
                this.removeLayerOrg(layer);
                return this.fire("layerremove", { layer: layer });
            };

            useMapStore.setState({mapInstance: mapInst})
        }
    }, [])

    useEffect(() => {
        if (mapInstance){
            if (globaleSelectionLayer){
                mapInstance.pm.enableGlobalEditMode({
                    syncLayersOnDrag: true,
                })
                mapInstance.pm.enableGlobalDragMode()
            } else {
                mapInstance.pm.disableGlobalEditMode()
                mapInstance.pm.disableGlobalDragMode()
            }
        }
    }, [globaleSelectionLayer]);



    const drawnItems = new L.FeatureGroup();
    var options = {
        position: 'topright',
        draw: {
            rectangle: {
                shapeOptions: {
                    clickable: false,
                    weight: 1.5,
                    stroke: true
                }
            },
            polyline: {
                guidelineDistance: 20,
                shapeOptions: {
                    clickable: false,
                    weight: 1.5,
                    stroke: true
                }
            }
        },
        edit: {
            featureGroup: drawnItems, //REQUIRED!!
            remove: false
        }
    };

    var drawControl = new L.Control.Draw(options);

    const drawShapeStatus = useMapStore(state => state.drawShapeStatus)
    const drawShapeType = useMapStore(state => state.drawShapeType)
    const selectMode = useMapStore(state => state.selectMode)

    // useEffect(() => {
    //     if (!selectMode){
    //         removeGlobalSelection()
    //     }
    // }, [selectMode])

    // console.log(selectMode)

    const [drawContainer, setDrawContainer] = useState(null)
    const [lineDrawContainer, setLineDrawContainer] = useState(null)

    //Enable rectangle draw when select mode or draw area status is true
    useEffect(() => {
        if (mapInstance) {
            if (drawAreaStatus || selectMode){
                const cont = new L.Draw.Rectangle(mapInstance, drawControl.options.draw.rectangle)
                cont.enable()
                setDrawContainer(cont)
            } else {
                if (drawContainer){
                    drawContainer.disable()
                    setDrawContainer(null)
                }
            }
        }

    }, [drawAreaStatus, selectMode, mapInstance])

    //Detect need draw line
    useEffect(() => {
        if (mapInstance){
            if (drawShapeStatus){
                mapInstance.pm.disableDraw()

                if (drawShapeType === "line"){
                    mapInstance.pm.enableDraw("Line", {
                        snappable: true,
                        snapDistance: 40
                    })
                } else if (drawShapeType === "polygon") {
                    mapInstance.pm.enableDraw("Polygon", {
                        snappable: true,
                        snapDistance: 40
                    })
                } else if (drawShapeType === "rectangle") {
                    mapInstance.pm.enableDraw("Rectangle", {
                        snappable: true,
                        snapDistance: 40
                    })
                } else if (drawShapeType === "polyline") {
                    mapInstance.pm.enableDraw("Line", {
                        snappable: true,
                        snapDistance: 40
                    })
                } else if (drawShapeType === "circle") {
                    mapInstance.pm.enableDraw("Circle", {
                        snappable: true,
                        snapDistance: 40
                    })
                } else {
                    mapInstance.pm.disableDraw()
                }
            } else {
                mapInstance.pm.disableDraw()
            }
        }
    }, [drawShapeStatus, drawShapeType])

    useEffect(() => {
        if (mapInstance) {
            mapInstance.off("pm:drawstart")
            mapInstance.off("pm:create")

            mapInstance.on("pm:drawstart", ({workingLayer}) => {
                workingLayer.on("pm:vertexadded", async (event) => {
                    const layers = event.layer._latlngs

                    if (useMapStore.getState().drawShapeType === "line"){
                        if (Object.keys(layers).length >= 2) {
                            useMapStore.setState({drawShapeStatus: false})
                            const points = []

                            Object.values(layers).forEach(layer => {
                                points.push(L.Projection.SphericalMercator.project(layer))
                            })

                            await ShapeItemAPI.createShapeItem(points, 'line')

                            //Refresh new shape tiles
                            renderNewShapeItem(getMockBounds(event.layer))
                        }
                    }
                });
            });

            mapInstance.on("pm:create", async ({shape, layer}) => {
                if (shape !== "Circle"){
                    let layers = []
                    if (shape !== "Line"){
                        layers = layer._latlngs[0]
                    } else {
                        layers = layer._latlngs
                    }
                    useMapStore.setState({drawShapeStatus: false})

                    const points = []

                    layers.forEach(l => {
                        points.push(L.Projection.SphericalMercator.project(l))
                    })

                    await ShapeItemAPI.createShapeItem(points, useMapStore.getState().drawShapeType)

                    // Refresh new shape tiles
                    renderNewShapeItem(getMockBounds(layer))
                    layer.remove()
                } else {
                    //Get center point
                    const center = L.Projection.SphericalMercator.project(layer._latlng)

                    //Get radius
                    const radius = layer._mRadius

                    //Create shape in API
                    await ShapeItemAPI.createCircleShapeItem(center, radius)

                    // Refresh new shape tiles
                    renderNewShapeItem(getMockBounds(layer))
                    layer.remove()
                }

            });
        }
    }, [mapInstance, drawShapeType]);

    useEffect(() => {
        if (mapInstance === null){
            return
        }

        mapInstance.createPane('itemPane');
        mapInstance.createPane('textPane');

        const mapCenter = mapInstance.getCenter()
        const layerCenter = mapInstance.latLngToLayerPoint(mapCenter)
        setCurrentMapCenter({
            'lat': mapCenter.lat,
            'long': mapCenter.lng,
            'x': layerCenter.x,
            'y': layerCenter.y
        })

        //https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw

        const tiles = L.tileLayer('', {
            maxZoom: 18,
            tileSize: 512,
            zoomOffset: -1,
            noWrap: true
        }).addTo(mapInstance);

        mapInstance.doubleClickZoom.disable()

        //Add grid layer
        gridLayer.addTo(mapInstance)

        //Add all layer group to map
        areaLayer.addTo(mapInstance)


        //Add shape layer
        shapeLayer.addTo(mapInstance)

        //Add item layer to map
        itemsLayer.addTo(mapInstance)

        //Add text layer
        textLayer.addTo(mapInstance)

        areaTitleLayer.addTo(mapInstance)

        textLayer.scene.subscribe({
            view_complete: () => {
                renderTextItems()
                setLoadedLayer((loadedLayer) => loadedLayer + 1)
            }
        })

        itemsLayer.scene.subscribe({
            view_complete: () => {
                useContentItemStore.setState({blockRefresh: false})
                renderLevel2Items()
                setLoadedLayer((loadedLayer) => loadedLayer + 1)
            }
        })

        areaLayer.scene.subscribe({
            view_complete: () => {
                setLoadedLayer((loadedLayer) => loadedLayer + 1)
            }
        })

        areaTitleLayer.scene.subscribe({
            view_complete: () => {
                setLoadedLayer((loadedLayer) => loadedLayer + 1)
            }
        })

        gridLayer.scene.subscribe({
            view_complete: () => {
                setLoadedLayer((loadedLayer) => loadedLayer + 1)
            }
        })

        shapeLayer.scene.subscribe({
            view_complete: () => {
                setLoadedLayer((loadedLayer) => loadedLayer + 1)
            }
        })

        //Update cursor coordinates on mouse move
        mapInstance.on('mousemove', (event) => {
            let lat = Math.round(event.latlng.lat * 100000) / 100000;
            let lng = Math.round(event.latlng.lng * 100000) / 100000;
            setCurrentLayerPoint({x: event.layerPoint.x, y: event.layerPoint.y, long: lng, lat: lat})
            renderLevel2Items()
            renderTextItems()
        })

        mapInstance.on('move', (event) => {
            const mapCenter = mapInstance.getCenter()
            const layerCenter = mapInstance.latLngToLayerPoint(mapCenter)
            setCurrentMapCenter({
                'lat': mapCenter.lat,
                'long': mapCenter.lng,
                'x': layerCenter.x,
                'y': layerCenter.y
            })
        })

        let mapClicked = 0

        mapInstance.addLayer(drawnItems)
        mapInstance.on('draw:drawstart', (event) => {
            if (useMapStore.getState().selectMode){
                useMapGlobalSelectionStore.setState({drawState: true})
                // console.log("draw", event)
            }
        })
        mapInstance.on('draw:created', async (event) => {
            //Select mode or create area
            if (useMapStore.getState().selectMode){
                useMapGlobalSelectionStore.setState({drawState: false, drawStartPosition: null})
                useMapGlobalSelectionStore.setState({startBounds: event.layer.getBounds()})
                regenerateSelectionLayer()
            } else {
                const type = event.layerType

                //Handler for zone create
                if (type === "rectangle") {
                    useMapStore.setState({drawAreaStatus: false})
                    const layer = event.layer

                    const newBounds = layer._bounds
                    const northWestPoint = L.Projection.SphericalMercator.project(newBounds.getNorthWest())
                    const southEastPoint = L.Projection.SphericalMercator.project(newBounds.getSouthEast())

                    const x0 = northWestPoint.x
                    const y0 = southEastPoint.y
                    const x1 = southEastPoint.x
                    const y1 = northWestPoint.y

                    //Create area
                    await AreaAPI.createArea(useMapStore.getState().currentOverlay, x0, y0, x1, y1, areaBackgroundColor.current, areaBorderColor.current, areaBorderWidth.current, areaOpacity.current)

                    renderNewZone([x0, y0, x1, y1])
                }
            }

        })

        //Add map click event
        mapInstance.on('click', (event) => {
            //Cancel if shift key is pressed
            if (event.originalEvent.shiftKey){
                return;
            }

            //Unselect all items
            removeGlobalSelection()
            unselectMapContentItem()
            unselectMapTextItem()

            //Close all context menu
            useMapContextMenuStore.getState().resetStore()

            //Get map click position
            const pixel = { x: event.containerPoint.x, y: event.containerPoint.y };
            const pixel2 = { x: event.layerPoint.x, y: event.layerPoint.y };


            //Remove old create text item popup if already exist
            if (useMapStore.getState().createTextPopup){
                useMapStore.getState().createTextPopup.close()
                useMapStore.setState({createTextPopup: null})
            }

            if (useMapStore.getState().createTextStatus){
                const newTextPopup = generateTextItemAddPopup(pixel2.x, pixel2.y)
                useMapStore.setState({createTextStatus: false, createTextPopup: newTextPopup})
                addCreateTextItemPopupEvents(handleCreateTextItem)
                return
            }

            //Check if map is public
            if (useMapStore.getState().publicMap){
                return;
            }

            //Detect shape item on click
            shapeLayer.scene.getFeatureAt(pixel, {radius: 15}).then((selection) => {
                detectShapeItemClick(selection)
            })

            //Detect need valid shape change
            // if (shapeMock.current !== null){
            //     console.log(shapeMock.current)
            //     const latLngShapePoints = shapeMock.current._latlngs
            //     const newPoints = []
            //
            //     latLngShapePoints.forEach(p => {
            //         newPoints.push({lat: p.lat, long: p.lng})
            //     })
            //
            //     ShapeItemAPI.updateShapeItem(newPoints, shapeMockUuid.current, shapeMockType.current)
            //
            //     shapeMock.current.pm.disable()
            //     shapeMock.current.remove()
            //
            //     shapeMock.current = null
            //     shapeMockType.current = null
            //     shapeMockUuid.current = null
            //
            //     shapeLayer.scene.config.global.edited_item_id = ""
            //     shapeLayer.scene.updateConfig()
            // }

            //Get shapes feature on click
            // if (!publicMap){
            //     shapeLayer.scene.getFeatureAt(pixel, {radius: 10}).then((selection) => {
            //         //Check if result is empty
            //         if (!selection) {
            //             clearSelectedItem()
            //             return
            //         } else {
            //             clearSelectedItem()
            //         }
            //
            //         const feature = selection.feature
            //
            //         if (feature){
            //             if (feature.properties){
            //                 if (shapeMock.current !== null){
            //                     shapeMock.current.disable()
            //                     shapeMock.current.remove()
            //
            //                     shapeMock.current = null
            //                     shapeMockType.current = null
            //                     shapeMockUuid.current = null
            //                 }
            //
            //                 if (feature.properties.type === "line"){
            //                     let shape = feature.properties.shape
            //                     shape = shape.replaceAll("LINESTRING(", "")
            //                     shape = shape.replaceAll(")", "")
            //
            //                     const shapePoints = shape.split(',')
            //
            //                     const latLngShapePoints = shapePoints.map(point => {
            //                         point = point.split(" ")
            //                         return L.Projection.SphericalMercator.unproject(new L.Point(point[0], point[1]))
            //                     })
            //
            //                     shapeLayer.scene.config.global.edited_item_id = feature.properties.id
            //                     shapeLayer.scene.updateConfig()
            //
            //                     const polyline = L.polyline(latLngShapePoints, {color: '#3388ff', weight: 2}).addTo(mapInstance)
            //                     polyline.pm._createMiddleMarker = () => {}
            //                     polyline.pm.enable({
            //                         snappable: false,
            //                         allowSelfIntersection: false,
            //                     })
            //
            //                     shapeMock.current = polyline
            //                     shapeMockType.current = feature.properties.type
            //                     shapeMockUuid.current = feature.properties.id
            //                 }
            //             }
            //         }
            //     })
            // }
        })

        mapInstance.on('dblclick', (event) => {

            //Get click location on map
            const pixel = { x: event.containerPoint.x, y: event.containerPoint.y };

            //Get area feature on click
            areaLayer.scene.getFeatureAt(pixel, {radius: 5}).then((selection) => {
                //Check if result is empty
                if (!selection) {
                    return
                }

                //Get feature if selection not empty
                const feature = selection.feature

                //Check if feature properties exist and open level 3 popup
                if (feature != null) {
                    if (feature.properties != null) {
                        const areaCords = TangramLayerUtils.getAreaLatLng(feature.properties)

                        mapInstance.fitBounds(areaCords, {padding: [100, 100], animate: false})
                    }
                }
            })

            shapeLayer.scene.getFeatureAt(pixel, {radius: 5}).then((selection) => {
                if (!selection){
                    return
                }

                //Get feature
                const feature = selection.feature

                //Check if feature exist
                if (!feature){
                    return
                }

                //Check if shape layer is not line
                if (feature.layers.includes("weeki_map_shapes_lines")){
                    return;
                }

                //Convert feature center point to latLng
                const centerLatLng = L.Projection.SphericalMercator.unproject(new L.Point(feature.properties.x, feature.properties.y))

                //Get to shape
                useMapStore.getState().mapInstance.flyTo(centerLatLng, feature.properties.ref_zoom, {
                    animate: true,
                    duration: 2
                })
            })
        })

        //Add map context menu event
        mapInstance.on('contextmenu', (event) => {
            //Check if map is public
            if (useMapStore.getState().publicMap){
                return
            }

            let menuOpen = false

            //Get click location on map
            const pixel = { x: event.containerPoint.x, y: event.containerPoint.y };
            const clientClickPosition = {x: event.originalEvent.clientX, y: event.originalEvent.clientY}

            //Detect context menu on zone
            areaTitleLayer.scene.getFeatureAt(pixel).then((selection) => {
                if (selection !== undefined && selection.feature !== undefined){
                    detectAndOpenZoneContextMenu(selection, clientClickPosition, pixel)
                } else {
                    //Open shape content menu
                    shapeLayer.scene.getFeatureAt(pixel, {radius: 5}).then((selection) => {
                        if (selection !== undefined && selection.feature !== undefined){
                            if (selection){
                                if (selection.feature){
                                    if (selection.feature.layers.includes("weeki_map_shapes_lines")){
                                        detectAndOpenShapeContextMenu(selection, clientClickPosition)
                                        return;
                                    }
                                }
                            }
                        }

                        //Close all context menu
                        useMapContextMenuStore.getState().resetStore()

                        //Open map context menu
                        useMapContextMenuStore.getState().openMapMenu(clientClickPosition.x, clientClickPosition.y, pixel)
                    })
                }
            })



            //TODO open level 1 context menu
        })

        //Detect map move for update level 2 object popup
        mapInstance.on('move zoom zoomlevelschange resize load', (event) => {
            // updateLevel2ObjectPopup()
            renderLevel2Items()
            renderTextItems()

            clearHoveredLevel1()
        })

        mapInstance.on('zoomend', (event) => {
            if (mapInstance !== null){
                setZoom(mapInstance.getZoom())
                renderLevel2Items()
                renderTextItems()
            }
        })

        mapInstance.on('click', (event) => {
            if (event.originalEvent.shiftKey && useMapGlobalSelectionStore.getState().selectionLayer !== null){
                //Get map click position
                const pixel = { x: event.containerPoint.x, y: event.containerPoint.y };

                //Get area label at click position
                areaTitleLayer.scene.getFeatureAt(pixel, {radius: 5}).then((selection) => {
                    if (selection.feature){
                        const feature = selection.feature

                        //Check if feature is already selected
                        if (useMapGlobalSelectionStore.getState().selectedAreas.some(a => a.uuid === feature.properties.id)){
                            const selArea = useMapGlobalSelectionStore.getState().selectedAreas.filter(a => a.uuid === feature.properties.id)[0]
                            //Remove selected area rect
                            if (selArea.rect){
                                useMapGlobalSelectionStore.getState().selectionLayerGroup.removeLayer(selArea.rect)
                                selArea.rect.remove()
                                useMapGlobalSelectionStore.getState().removeSelectedArea(selArea.uuid)
                            }

                            regenerateSelectionLayer()
                        } else {
                            //Get area bounds latLng and convert to latLngBounds
                            const areaLatLng = TangramLayerUtils.getAreaLatLng(feature.properties)
                            const areaLatLngBounds = L.latLngBounds(areaLatLng)

                            //Add feature uuid to selected areas
                            useMapGlobalSelectionStore.getState().addSelectedArea({uuid: feature.properties.id, bounds: areaLatLngBounds})

                            setTimeout(() => {
                                regenerateSelectionLayer()
                            }, 100)
                        }
                    }
                })

                //Get content item at click position
                itemsLayer.scene.getFeatureAt(pixel, {radius: 5}).then((selection) => {
                    if (selection.feature){
                        const feature = selection.feature

                        //Check if content item is already selected
                        if (useMapGlobalSelectionStore.getState().selectedItems.some(i => i.uuid === feature.properties.id)){
                            const selItem = useMapGlobalSelectionStore.getState().selectedItems.filter(i => i.uuid === feature.properties.id)[0]

                            if (selItem.rect){
                                useMapGlobalSelectionStore.getState().selectionLayerGroup.removeLayer(selItem.rect)
                                selItem.rect.remove()
                                useMapGlobalSelectionStore.getState().removeSelectedItem(selItem.uuid)
                            }

                            regenerateSelectionLayer()
                        } else {
                            //Select content item
                            useMapGlobalSelectionStore.getState().addSelectedItem({'uuid': feature.properties.id, 'lat': feature.properties.lat, 'lng': feature.properties.long})

                            setTimeout(() => {
                                regenerateSelectionLayer()
                            }, 100)
                        }
                    }
                })
            }
        })

        mapInstance.on('zoom', (event) => {
            if (useMapGlobalSelectionStore.getState().selectedItems && useMapGlobalSelectionStore.getState().selectedItems.length > 0){
                setTimeout(() => {
                    updateGlobalSelectionItemRect()
                }, 1000)
            }
        })

        mapInstance.on('mousedown', (event) => {
            if (useMapStore.getState().selectMode && useMapGlobalSelectionStore.getState().drawState){
                if (useMapGlobalSelectionStore.getState().drawStartPosition === null){
                    useMapGlobalSelectionStore.setState({drawStartPosition: event.latlng})
                }
            }
        })

        mapInstance.on('mousemove', (event) => {
            if (useMapStore.getState().selectMode && useMapGlobalSelectionStore.getState().drawState && useMapGlobalSelectionStore.getState().drawStartPosition !== null){
                if (useMapGlobalSelectionStore.getState().tempSelectionLayer){
                    useMapGlobalSelectionStore.getState().tempSelectionLayer.remove()
                    useMapGlobalSelectionStore.setState({tempSelectionLayer: null})
                }

                useMapGlobalSelectionStore.setState({drawCurrentPosition: event.latlng})
                //Generate layer
                const latLngBounds = L.latLngBounds(useMapGlobalSelectionStore.getState().drawStartPosition, event.latlng)
                const tempSelectionLayer = L.rectangle(latLngBounds, {color: "transparent"})
                tempSelectionLayer.id = "tempSelectionLayer"
                mapInstance.addLayer(tempSelectionLayer)

                //Add selection layer in store
                useMapGlobalSelectionStore.setState({tempSelectionLayer: tempSelectionLayer})

                selectMapData(tempSelectionLayer.getBounds(), itemsLayer, areaLayer, shapeLayer)
            }
        })
    }, [mapInstance])

    // console.log(globalSelectedAreas)



    useEffect(() => {
        if (mapInstance === null){
            return
        }

        if (zoom !== mapInstance.getZoom()){
            mapInstance.setZoom(zoom)
            renderLevel2Items()
        }
    }, [zoom])

    useEffect(() => {
        if (mapInstance !== null){
            renderLevel2Items()
        }
    }, [currentMapCenter, mapInstance])

    //Detect need move to object on map
    useEffect(() => {
        if (viewObjectOnMap.location){
            const location = viewObjectOnMap.location.split(',')
            mapInstance.setView([location[1], location[0]], viewObjectOnMap.zlevel)
            // updateLevel2ObjectPopup()
            setViewObjectOnMap({location: "", zlevel: ""})
            renderLevel2Items()

            setTimeout(() => {
                renderLevel2Items()
            }, 3000)
        }
    }, [viewObjectOnMap])

    /************************************************************************************************/
    /********************************************TEXT ITEM*******************************************/
    /************************************************************************************************/
    const [textItemPopups, setTextItemPopups] = useState({})

    const handleCreateTextItem = async (value) => {
        const createPopup = useMapStore.getState().createTextPopup

        const uuid = await TextItemAPI.createTextItem(createPopup.getLatLng().lng, createPopup.getLatLng().lat, value, useMapStore.getState().currentOverlay, zoom)

        if (uuid.length > 0){
            const textPopup = generateTextItemPopup(createPopup.getLatLng(), value, 14)

            // addTextItemPopupEvents(textPopup, uuid, renderSelectedObjectMenu)

            textItemPopups[uuid] = {
                'itemId': uuid,
                'mapPopup': textPopup
            }
            createPopup.close()
            useMapStore.setState({createTextPopup: null})
        }
    }


    const addAreaEvents = (popup, rect) => {
        rect.on('dragstart scalestart', (event) => {
            mapInstance.removeLayer(popup)
        })

        rect.on('dragend scaleend', (event) => {
            popup.setLatLng(rect.getBounds().getNorthWest())
            popup.addTo(mapInstance)
            addAreaEvents(popup, rect)
        })

        //Add event for border color menu
        L.DomEvent.on(popup._contentNode.querySelector("#area-border-color-menu-btn"), 'click', (event) => {
            event.stopPropagation()
            popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={areaMoveStatus.current} borderColorMenu={!areaBorderColorMenu.current} borderColor={areaBorderColor.current} backgroundColor={areaBackgroundColor.current} borderWidth={areaBorderWidth.current} opacity={areaOpacity.current}/> ))
            areaBorderColorMenu.current = !areaBorderColorMenu.current
            addAreaEvents(popup, rect)
        })

        //Add event for background color menu
        L.DomEvent.on(popup._contentNode.querySelector("#area-background-color-menu-btn"), 'click', (event) => {
            event.stopPropagation()
            popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={areaMoveStatus.current} backgroundColorMenu={!areaBackgroundColorMenu.current} borderColor={areaBorderColor.current} backgroundColor={areaBackgroundColor.current} borderWidth={areaBorderWidth.current} opacity={areaOpacity.current}/> ))
            areaBackgroundColorMenu.current = !areaBackgroundColorMenu.current
            addAreaEvents(popup, rect)
        })

        //Add event for border style color menu
        L.DomEvent.on(popup._contentNode.querySelector("#area-border-style-menu-btn"), 'click', (event) => {
            event.stopPropagation()
            popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={areaMoveStatus.current} borderStyleMenu={!areaBorderStyleMenu.current} borderColor={areaBorderColor.current} backgroundColor={areaBackgroundColor.current} borderWidth={areaBorderWidth.current} opacity={areaOpacity.current}/> ))
            areaBorderStyleMenu.current = !areaBorderStyleMenu.current
            addAreaEvents(popup, rect)
        })

        //Add event for change border color
        const colorButtons = popup._contentNode.querySelectorAll("#area-update-border-color-btn")
        colorButtons.forEach(button => {
            L.DomEvent.on(button, 'click', (event) => {
                event.stopPropagation()
                const newBorderColor = RGBToHex(event.currentTarget.style.backgroundColor)

                areaBorderColor.current = newBorderColor

                const newRect = AreaPopupMenuUtils.generateAreaRect(rect.getBounds(), areaMoveStatus.current, areaMoveStatus.current, newBorderColor, areaBackgroundColor.current, areaBorderWidth.current, areaOpacity.current)

                if (rect.dragging){
                    rect.transform.disable()
                    rect.dragging.disable()
                }
                rect.remove()
                newRect.addTo(mapInstance)
                if (newRect.dragging){
                    newRect.dragging.enable()
                    newRect.transform.enable({rotation: false, scaling: true, uniformScaling: false})
                }
                areaRect.current = newRect

                popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={areaMoveStatus.current} borderColor={newBorderColor} backgroundColor={areaBackgroundColor.current} borderWidth={areaBorderWidth.current} opacity={areaOpacity.current}/>))
                areaBorderColorMenu.current = !areaBorderColorMenu.current

                if (areaEditType.current === "move"){
                    //Update area with API
                    AreaAPI.updateArea(areaUuid.current, newBorderColor, null, null).then()
                }

                addAreaEvents(popup, newRect)
            })
        })

        //Add event for change background color
        const backgroundColorButton = popup._contentNode.querySelectorAll("#area-update-background-color-btn")
        backgroundColorButton.forEach(button => {
            L.DomEvent.on(button, 'click', (event) => {
                event.stopPropagation()
                const newBackgroundColor = RGBToHex(event.currentTarget.style.backgroundColor)

                areaBackgroundColor.current = newBackgroundColor

                const newRect = AreaPopupMenuUtils.generateAreaRect(rect.getBounds(), areaMoveStatus.current, areaMoveStatus.current, areaBorderColor.current, newBackgroundColor, areaBorderWidth.current, areaOpacity.current)

                if (rect.dragging){
                    rect.transform.disable()
                    rect.dragging.disable()
                }
                rect.remove()

                newRect.addTo(mapInstance)
                if (newRect.dragging){
                    newRect.dragging.enable()
                    newRect.transform.enable({rotation: false, scaling: true, uniformScaling: false})
                }
                areaRect.current = newRect


                popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={areaMoveStatus.current} borderColor={areaBorderColor.current} backgroundColor={newBackgroundColor} borderWidth={areaBorderWidth.current} opacity={areaOpacity.current}/> ))
                areaBackgroundColorMenu.current = !areaBackgroundColorMenu.current

                if (areaEditType.current === "move"){
                    //Update area with API
                    AreaAPI.updateArea(areaUuid.current, null, null, newBackgroundColor).then()
                }

                addAreaEvents(popup, newRect)
            })
        })

        //Add event listener for edit border width
        L.DomEvent.on(popup._contentNode.querySelector("#area-border-width-input"), 'change', (event) => {
            event.stopPropagation()

            const newBorderWidth = event.currentTarget.value
            popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={areaMoveStatus.current} borderStyleMenu={areaBorderStyleMenu.current} borderColor={areaBorderColor.current} backgroundColor={areaBackgroundColor.current} borderWidth={newBorderWidth} opacity={areaOpacity.current}/>))
            areaBorderWidth.current = newBorderWidth

            const newRect = AreaPopupMenuUtils.generateAreaRect(rect.getBounds(), areaMoveStatus.current, areaMoveStatus.current, areaBorderColor.current, areaBackgroundColor.current, newBorderWidth, areaOpacity.current)

            if (rect.dragging){
                rect.transform.disable()
                rect.dragging.disable()
            }

            rect.remove()
            newRect.addTo(mapInstance)
            if (newRect.dragging){
                newRect.dragging.enable()
                newRect.transform.enable({rotation: false, scaling: true, uniformScaling: false})
            }
            areaRect.current = newRect

            if (areaEditType.current === "move"){
                //Update area with API
                AreaAPI.updateArea(areaUuid.current, null, newBorderWidth, null).then()
            }

            addAreaEvents(popup, newRect)
        })

        //Add event listener for edit opacity
        L.DomEvent.on(popup._contentNode.querySelector("#area-opacity-input"), 'change', (event) => {
            event.stopPropagation()

            const newOpacity = event.currentTarget.value
            if (newOpacity === "100"){
                areaOpacity.current = 1
            } else {
                areaOpacity.current = parseFloat("0." + newOpacity)
            }
            popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={areaMoveStatus.current} backgroundColorMenu={areaBackgroundColorMenu.current} borderColor={areaBorderColor.current} backgroundColor={areaBackgroundColor.current} borderWidth={areaBorderWidth.current} opacity={areaOpacity.current}/> ))

            const newRect = AreaPopupMenuUtils.generateAreaRect(rect.getBounds(), areaMoveStatus.current, areaMoveStatus.current, areaBorderColor.current, areaBackgroundColor.current, areaBorderWidth.current, areaOpacity.current)

            if (rect.dragging){
                rect.transform.disable()
                rect.dragging.disable()
            }

            rect.remove()
            newRect.addTo(mapInstance)
            if (newRect.dragging){
                newRect.dragging.enable()
                newRect.transform.enable({rotation: false, scaling: true, uniformScaling: false})
            }
            areaRect.current = newRect

            if (areaEditType.current === "move"){
                // Update area with API
                AreaAPI.updateArea(areaUuid.current, null, null, null, areaOpacity.current).then()
            }

            addAreaEvents(popup, newRect)
        })

        //Add event listener for start move area
        L.DomEvent.on(popup._contentNode.querySelector("#area-move-btn"), 'click', (event) => {
            popup.setContent(ReactDOMServer.renderToString(<AreaPopupMenu editStatus={true} borderColor={areaBorderColor.current} backgroundColor={areaBackgroundColor.current} borderWidth={areaBorderWidth.current} opacity={areaOpacity.current}/> ))
            areaMoveStatus.current = true

            const newRect = AreaPopupMenuUtils.generateAreaRect(rect.getBounds(), true, true, areaBorderColor.current, areaBackgroundColor.current, areaBorderWidth.current, areaOpacity.current)

            if (rect.dragging){
                rect.transform.disable()
                rect.dragging.disable()
            }

            rect.remove()
            newRect.addTo(mapInstance)
            if (newRect.dragging){
                newRect.dragging.enable()
                newRect.transform.enable({rotation: false, scaling: true, uniformScaling: false})
            }
            areaRect.current = newRect

            addAreaEvents(popup, newRect)
        })


        //Add event listener for cancel area edit
        L.DomEvent.on(popup._contentNode.querySelector("#area-cancel"), 'click', (event) => {
            mapInstance.removeLayer(popup)
            if (rect.dragging){
                rect.transform.disable()
                rect.dragging.disable()
            }
            rect.remove()
            areaEditStatus.current = false
            areaUuid.current = ""

            if (areaEditType.current === "move"){
                areaLayer.scene.config.global.edited_item_id = ""
                areaLayer.scene.rebuild()
            }
        })

        //Add event listener for valid area creation
        L.DomEvent.on(popup._contentNode.querySelector("#area-valid"), 'click', async (event) => {
            const newBounds = rect.getBounds()
            const northWestPoint = L.Projection.SphericalMercator.project(newBounds.getNorthWest())
            const southEastPoint = L.Projection.SphericalMercator.project(newBounds.getSouthEast())

            const x0 = northWestPoint.x
            const y0 = southEastPoint.y
            const x1 = southEastPoint.x
            const y1 = northWestPoint.y

            if(areaEditType.current === "create"){
                //Create area
                await AreaAPI.createArea(currentOverlay, x0, y0, x1, y1, areaBackgroundColor.current, areaBorderColor.current, areaBorderWidth.current, areaOpacity.current)
            } else if (areaEditType.current === "move"){
                //Move area
                await AreaAPI.moveArea(areaUuid.current, x0, y0, x1, y1)
            }


            mapInstance.removeLayer(popup)
            if (rect.dragging){
                rect.transform.disable()
                rect.dragging.disable()
            }
            rect.remove()

            areaEditStatus.current = false
            areaMoveStatus.current = false

            if (areaEditType.current === "move"){
                areaLayer.scene.config.global.edited_item_id = ""
                areaTitleLayer.scene.config.global.edited_item_id = ""
            }
        })
    }

    //Take and upload map tangram layer screenshot
    const handleTakeScreenshot = () => {
        return;
        //Check if user have authorization for edit map
        if (!userAuthorizations.includes('map.edit')){
            return;
        }

        const layers = []

        mapInstance.eachLayer(layer => {
            if (layer._updating_tangram !== undefined) {
                layers.push(layer)
            }
        })

        const promises = layers.map(l => {
            return l.scene.screenshot({background: 'transparent'})
        })

        Promise.all(promises).then((res) => {
            const screenshots = []

            res.forEach(s => {
                screenshots.push(s.url)
            })

            MapAPI.createMapScreenshot(JSON.stringify(screenshots))
        })
    }


    useEffect(() => {
        if (loadedLayer >= totalLayerCount){
            if (!useMapStore.getState().screenState){
                handleTakeScreenshot()
                useMapStore.setState({screenState: true})
            }
        }
    }, [loadedLayer])

    const mapContextMenuState = useMapContextMenuStore(state => state.state)
    const mapContextMenuType = useMapContextMenuStore(state => state.type)

    //Render leaflet map
    return (
        <>
            <style>
                {`
               .leaflet-draw-tooltip {
                    display: none;
                }
                .leaflet-popup-tip {
                    width: 0px;
                    height: 0px;
                }
                .leaflet-popup {
                    margin: 0;
                }
                .leaflet-popup-content{
                    margin: 0;
                    padding: 0;
                }
                
                .leaflet-popup-content p {
                    margin: 0;
                    padding: 0;
                }
                
                .leaflet-popup-content-wrapper{
                    border-radius: 0;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    background: none;
                }
                .leaflet-text-pane .leaflet-popup-content {
                    width: auto!important;
                }
                `}
            </style>
            {loadedLayer < totalLayerCount && (
                <div className="map-loader-container">
                    <div className="progress-map-bar" style={{width: (loadedLayer / totalLayerCount) * 100 + "%"}}></div>
                </div>
            )}
            <div onMouseMove={handleDetectMapMouseMove} onKeyUp={(event) => detectKeyUpMapEvent(event)} onKeyDown={(event) => detectKeyDownMapEvent(event)} className="map" id="map" onDrop={() => alert("dropped")} style={useMapStore.getState().createTextStatus ? {cursor: "crosshair"} : {cursor: "default"}}></div>
            {mapContextMenuState && (
                <>
                    {mapContextMenuType === "zone" && (
                        <ZoneContextMenu2 />
                    )}
                    {mapContextMenuType === "map" && (
                        <MapContextMenu2 />
                    )}
                    {mapContextMenuType === "item" && (
                        <ContentItemContextMenu />
                    )}
                    {mapContextMenuType === "shape" && (
                        <ShapeContextMenu />
                    )}
                </>
            )}
        </>
    )
}

export default LeafletMap