import React, {useEffect, useRef, useState} from "react";
import {useMoocStore} from "../../services/Stores/MoocStore";
import {VscChromeClose} from "react-icons/vsc";
import {MdEdit} from "react-icons/md";
import CustomTooltip from "../customs/CustomTooltip";
import {BsFullscreen} from "react-icons/bs";
import {createErrorToast} from "../../services/CustomToastUtils";
import UserMoocCourseAPI from "../../services/API/User/Mooc/UserMoocCourseAPI";
import UserMoocQuizAPI from "../../services/API/User/Mooc/UserMoocQuizAPI";
import {PiPencilSimple} from "react-icons/pi";

const MoocCourseSidebarHeader = ({currentMooc, currentCourse, currentChapter, editState, setEditState, type}) => {
	const [editNameState, setEditNameState] = useState(false)
	const [layoutMenuState, setLayoutMenuState] = useState(false)
	const layoutMenuRef = useRef(null)

	useEffect(() => {
			const listener = (event) => {
				if (!layoutMenuRef.current || layoutMenuRef.current.contains(event.target)) {
					return;
				}

				if (layoutMenuState){
					setLayoutMenuState(false)
				}
			};

			window.addEventListener("click", listener);
			window.addEventListener("touchstart", listener);
			return () => {
				window.removeEventListener("click", listener);
				window.removeEventListener("touchstart", listener);
			};
		},
		[layoutMenuRef, layoutMenuState]
	);

	//Get current layout in store
	const currentLayout = useMoocStore(state => state.currentCourseLayout)

	const handleEditCourseName = async (value) => {
		if (value.length <= 0) {
			createErrorToast("Section name can't be empty")
			return
		}

		let requestState;

		if (type === "course") {
			requestState = await UserMoocCourseAPI.userRenameCourse(currentCourse.uuid, value)
		} else if (type === "quiz"){
			requestState = await UserMoocQuizAPI.userRenameQuiz(currentCourse.uuid, value)
		}

		if (requestState){
			//Update course name in store
			useMoocStore.getState().updateCourseName(currentChapter.uuid, currentCourse.uuid, value, type)

			if (type === "course"){
				useMoocStore.getState().updateCurrentCourseName(value)
			} else if (type === "quiz"){
				useMoocStore.getState().updateCurrentQuizName(value)
			}

			setEditNameState(false)
		}
	}

	return (
		<div className="header">
			<div className="btn-container">
				<p className="close-btn" onClick={() => useMoocStore.setState({currentSelectedCourse: null, currentSelectedQuiz: null})}><VscChromeClose size={18} /></p>
				<div className="btn-group">
					{!editState && (
						<p className="fullscreen-btn custom__tooltip-container" onClick={() => setEditState(true)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 19.999 20">
								<path id="Tracé_3680" data-name="Tracé 3680"
									  d="M51.53,28.93l-4.468-4.469a1.6,1.6,0,0,0-2.263,0L32.469,36.793A1.586,1.586,0,0,0,32,37.924v4.469a1.6,1.6,0,0,0,1.6,1.6h4.469a1.586,1.586,0,0,0,1.131-.469L51.53,31.193A1.6,1.6,0,0,0,51.53,28.93ZM38.069,42.392H33.6V37.924l8.8-8.8,4.469,4.469ZM48,32.461,43.53,27.993l2.4-2.4L50.4,30.061Z"
									  transform="translate(-32 -23.992)" fill="#a4afb7"/>
							</svg>
							<CustomTooltip text="Edit"/>
						</p>
					)}
					<p className="fullscreen-btn custom__tooltip-container layout-btn" ref={layoutMenuRef} onClick={() => setLayoutMenuState(!layoutMenuState)}>
						{currentLayout === "right" && (
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 23.636 20">
								<g id="Groupe_12448" data-name="Groupe 12448" transform="translate(-1817 -20)">
									<path id="Tracé_3629" data-name="Tracé 3629"
										  d="M47.636,41.818V58.182A1.818,1.818,0,0,1,45.818,60h-20A1.818,1.818,0,0,1,24,58.182V41.818A1.818,1.818,0,0,1,25.818,40h20A1.818,1.818,0,0,1,47.636,41.818ZM45.818,58.182V41.818h-20V58.182Z"
										  transform="translate(1793 -20)" fill="#a4afb7"/>
									<rect id="Rectangle_1278" data-name="Rectangle 1278" width="9" height="14" rx="0.5"
										  transform="translate(1828.818 23)" fill="#a4afb7"/>
								</g>
							</svg>
						)}
						{currentLayout === "left" && (
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 23.636 20">
								<g id="Groupe_12448" data-name="Groupe 12448" transform="translate(-1817 -20)">
									<path id="Tracé_3629" data-name="Tracé 3629"
										  d="M47.636,41.818V58.182A1.818,1.818,0,0,1,45.818,60h-20A1.818,1.818,0,0,1,24,58.182V41.818A1.818,1.818,0,0,1,25.818,40h20A1.818,1.818,0,0,1,47.636,41.818ZM45.818,58.182V41.818h-20V58.182Z"
										  transform="translate(1793 -20)" fill="#a4afb7"/>
									<rect id="Rectangle_1278" data-name="Rectangle 1278" width="9" height="14" rx="0.5"
										  transform="translate(1819.818 23)" fill="#a4afb7"/>
								</g>
							</svg>
						)}
						{currentLayout === "full" && (
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 23.636 20">
								<g id="Groupe_12448" data-name="Groupe 12448" transform="translate(-1817 -20)">
									<path id="Tracé_3629" data-name="Tracé 3629"
										  d="M47.636,41.818V58.182A1.818,1.818,0,0,1,45.818,60h-20A1.818,1.818,0,0,1,24,58.182V41.818A1.818,1.818,0,0,1,25.818,40h20A1.818,1.818,0,0,1,47.636,41.818ZM45.818,58.182V41.818h-20V58.182Z"
										  transform="translate(1793 -20)" fill="#a4afb7"/>
									<rect id="Rectangle_1278" data-name="Rectangle 1278" width="18" height="14" rx="0.5"
										  transform="translate(1819.818 23)" fill="#a4afb7"/>
								</g>
							</svg>
						)}
						{layoutMenuState && (
							<div className="layout-menu-container">
								<div className="layout-btn" onClick={() => {
									useMoocStore.setState({currentCourseLayout: "right"})
									setLayoutMenuState(false)
								}}>
									<div className="icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="15.717" height="13.299"
											 viewBox="0 0 15.717 13.299">
											<g id="Groupe_12448" data-name="Groupe 12448" transform="translate(-1817 -20)">
												<path id="Tracé_3629" data-name="Tracé 3629"
													  d="M39.717,41.209V52.09A1.209,1.209,0,0,1,38.508,53.3h-13.3A1.209,1.209,0,0,1,24,52.09V41.209A1.209,1.209,0,0,1,25.209,40h13.3A1.209,1.209,0,0,1,39.717,41.209ZM38.508,52.09V41.209h-13.3V52.09Z"
													  transform="translate(1793 -20)" fill="#a4afb7"/>
												<rect id="Rectangle_1278" data-name="Rectangle 1278" width="5.984"
													  height="9.309"
													  rx="0.5" transform="translate(1824.858 21.995)" fill="#a4afb7"/>
											</g>
										</svg>
									</div>
									<div className="text">Right side</div>
								</div>
								<div className="layout-btn" onClick={() => {
									useMoocStore.setState({currentCourseLayout: "left"})
									setLayoutMenuState(false)
								}}>
									<div className="icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="15.717" height="13.299"
											 viewBox="0 0 15.717 13.299">
											<g id="Groupe_12448" data-name="Groupe 12448" transform="translate(-1817 -20)">
												<path id="Tracé_3629" data-name="Tracé 3629"
													  d="M39.717,41.209V52.09A1.209,1.209,0,0,1,38.508,53.3h-13.3A1.209,1.209,0,0,1,24,52.09V41.209A1.209,1.209,0,0,1,25.209,40h13.3A1.209,1.209,0,0,1,39.717,41.209ZM38.508,52.09V41.209h-13.3V52.09Z"
													  transform="translate(1793 -20)" fill="#a4afb7"/>
												<rect id="Rectangle_1278" data-name="Rectangle 1278" width="5.984"
													  height="9.309"
													  rx="0.5" transform="translate(1818.874 21.995)" fill="#a4afb7"/>
											</g>
										</svg>
									</div>
									<div className="text">Left side</div>
								</div>
								<div className="layout-btn" onClick={() => {
									useMoocStore.setState({currentCourseLayout: "full"})
									setLayoutMenuState(false)
								}}>
									<div className="icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="15.717" height="13.299"
											 viewBox="0 0 15.717 13.299">
											<g id="Groupe_12449" data-name="Groupe 12449" transform="translate(-1817 -20)">
												<path id="Tracé_3629" data-name="Tracé 3629"
													  d="M39.717,41.209V52.09A1.209,1.209,0,0,1,38.508,53.3h-13.3A1.209,1.209,0,0,1,24,52.09V41.209A1.209,1.209,0,0,1,25.209,40h13.3A1.209,1.209,0,0,1,39.717,41.209ZM38.508,52.09V41.209h-13.3V52.09Z"
													  transform="translate(1793 -20)" fill="#a4afb7"/>
												<rect id="Rectangle_1278" data-name="Rectangle 1278" width="11.391"
													  height="8.386"
													  rx="0.5" transform="translate(1819.307 22.456)" fill="#a4afb7"/>
											</g>
										</svg>
									</div>
									<div className="text">Full page</div>
								</div>
							</div>
						)}
						<CustomTooltip text="Layouts"/>
					</p>
					<p className="fullscreen-btn custom__tooltip-container"
					   onClick={() => currentLayout === "full" ? useMoocStore.setState({currentCourseLayout: "left"}) : useMoocStore.setState({currentCourseLayout: "full"})}>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
							<path id="Tracé_3679" data-name="Tracé 3679"
								  d="M60,40.909v5.455a.909.909,0,0,1-1.818,0V43.1l-5.72,5.722a.91.91,0,0,1-1.286-1.286l5.722-5.72h-3.26a.909.909,0,0,1,0-1.818h5.455A.909.909,0,0,1,60,40.909ZM47.539,51.175,41.818,56.9v-3.26a.909.909,0,0,0-1.818,0v5.455a.909.909,0,0,0,.909.909h5.455a.909.909,0,1,0,0-1.818H43.1l5.722-5.72a.91.91,0,1,0-1.286-1.286Z" transform="translate(-40 -40)" fill="#a4afb7"/>
						</svg>
						<CustomTooltip text="Full screen"/>
					</p>
				</div>
			</div>
			<p className="mooc-data">{currentMooc.name} > {currentChapter.name} > {currentCourse.name}</p>
			<p className="course-name" onClick={() => setEditNameState(true)}>
				{!editNameState
					? currentCourse.name
					: <input type="text"
							 autoFocus={true}
							 defaultValue={currentCourse.name}
							 placeholder="Section name"
							 onBlur={(event) => handleEditCourseName(event.currentTarget.value)}
							 onKeyDown={(event) => event.key === "Enter" ? handleEditCourseName(event.currentTarget.value) : ""}/>
				}
			</p>
		</div>
	)
}

export default MoocCourseSidebarHeader