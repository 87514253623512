import {OVERLAY_API, USER_AREA_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";
import {useMapStore} from "./Stores";

/**
 * @param {string} mapUuid
 */
function getMapOverlays(mapUuid){
    return getAxiosInstance().get(OVERLAY_API + "/get_map_overlays", {
        params: {
            uuid: mapUuid
        }
    })
}

function getOverlayAreas(overlayUuid){
    return getAxiosInstance().get(USER_AREA_API + '/get_overlay_areas', {
        params: {uuid: overlayUuid}
    })
}

function deleteOverlay(overlayUuid, withContent){
    toast.loading("Overlay delete in progress", {toastId: "loadAlert"})
    return getAxiosInstance().delete(OVERLAY_API + "/delete", {
        params: {
            uuid: overlayUuid,
            withContent: withContent,
            defaultOverlay: useMapStore.getState().currentOverlay
        }
    })
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }

            return false
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            return false
        })
}

function updateOverlayName(overlayUuid, name){
    const sendData = new FormData()
    sendData.append('uuid', overlayUuid)
    sendData.append('name', name)

    toast.loading("Overlay name update in progress", {toastId: "loadAlert"})
    return getAxiosInstance().post(OVERLAY_API + "/update_name", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

function createOverlay(mapUuid, name){
    const sendData = new FormData()
    sendData.append('uuid', mapUuid)
    sendData.append('name', name)

    return getAxiosInstance().post(OVERLAY_API + "/create", sendData)
        .then((response) => {
            if (response.status === 200){
                return response.data.uuid
            }

            return false
        })
        .catch(() => false)
}

function updateOverlay(overlayUuid, name = null, minZlevel = null, maxZlevel = null){
    toast.loading("Overlay update in progress", {toastId: "loadAlert"})
    const sendData = new FormData()
    sendData.append('_method', 'PUT')
    sendData.append('overlayUuid', overlayUuid)
    if (name){
        sendData.append('name', name)
    }
    if (minZlevel && maxZlevel){
        sendData.append('minZlevel', minZlevel)
        sendData.append('maxZlevel', maxZlevel)
    }
    return getAxiosInstance().post(OVERLAY_API + "/update", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
            return false
        })
}

export default {
    getMapOverlays,
    deleteOverlay,
    updateOverlay,
    updateOverlayName,
    getOverlayAreas,
    createOverlay
}