import {useNotificationStore} from "../../Stores/NotificationsStore";

export function enableNotificationListener(data) {
	//Check if it's notification event
	if (data.type === 'notification'){

		if (data.action === 'add'){
			//Add notification to store
			useNotificationStore.getState().addNewNotification(data['datas'])
		}

		if (data.action === "remove"){
			useNotificationStore.getState().removeNotification(data['datas']['uuid'])
			useNotificationStore.getState().updateNotificationsUnReadCount()
		}
	}
}