import {ADMIN_PROMOCODE_API, ADMIN_SUB_PLAN_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function adminGetAllPromoCode(){
    return getAxiosInstance().get(ADMIN_PROMOCODE_API + "/get_all")
}

function adminCreatePromoCode(codeInfo){
    toast.loading("Promotion code creation in progress", {toastId: "loadAlert"})
    const sendData = new FormData()
    sendData.append("code", codeInfo.code)
    sendData.append("expirationDate", codeInfo.expirationDate)
    sendData.append("discount", codeInfo.discount)
    sendData.append("discountType", codeInfo.discountType)
    sendData.append("durationInMonth", codeInfo.durationInMonth)
    sendData.append("trialDay", codeInfo.trialDay)

    return getAxiosInstance().post(ADMIN_PROMOCODE_API + "/create", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function deleteCode(codeId){
    toast.loading("Promotion code suppression in progress", {toastId: "loadAlert"})

    return getAxiosInstance().delete(ADMIN_SUB_PLAN_API + "/delete_promo_code", {
        params: {
            codeUuid: codeId
        }
    })
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

export default {
    adminGetAllPromoCode,
    adminCreatePromoCode,
    deleteCode
}