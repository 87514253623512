import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {useMapZoneStore} from "../../services/Stores/MapZoneStore";
import UserAreaAPI from "../../services/API/User/UserAreaAPI";
import AreaAPI from "../../services/AreaAPI";
import {deleteZone} from "../../services/Utils/ZoneUtils";

const AreaConfirmDeletePopup = () => {
	//Get data in store
	const popupState = useMapZoneStore(state => state.confirmDeletePopupState)
	const zoneProperties = useMapZoneStore(state => state.deletedZoneProperties)

	const [haveContent, setHaveContent] = useState(false)

	useEffect(async () => {
		if (popupState) {
			const content = await UserAreaAPI.userCheckAreaContent(zoneProperties.id)

			if (content !== null){
				setHaveContent(content)
			} else {
				handleCancelDelete()
			}
		}
	}, [popupState]);

	const handleDeleteArea = (withContent) => {
		deleteZone(zoneProperties, false, withContent).then(r => null)
		handleCancelDelete()
	}

	const handleCancelDelete = () => {
		setHaveContent(false)
		useMapZoneStore.setState({deletedZoneProperties: null, confirmDeletePopupState: false})
	}

	if (popupState){
		return (createPortal(
				<div className="custom__confirm-delete-popup-overlay">
					<div className="container">
						<div className="header">
							<p className="title">Delete zone</p>
							<p className="icon" onClick={() => handleCancelDelete()}><VscChromeClose/></p>
						</div>
						<div className="content">
							<p className="text mb">Please confirm zone delete</p>
						</div>
						<div className="footer">
							<div className="btn invert" onClick={() => handleDeleteArea(false)}>Delete zone</div>
							{haveContent && (
								<div className="btn" onClick={() => handleDeleteArea(true)}>Delete with content</div>
							)}
						</div>
					</div>
				</div>, document.body)
		)
	} else {
		return null
	}

}

export default AreaConfirmDeletePopup