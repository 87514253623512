import React, {useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import weeki_logo_notext from "../../../images/weeki-logo-notext.svg";
import weeki_logo from "../../../images/weeki_logo.svg";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {BiLock, BiLogoApple, BiLogoGoogle} from "react-icons/bi";
import {GoMail} from "react-icons/go";
import {HiOutlineUser} from "react-icons/hi";
import {IoCheckmarkSharp, IoClose} from "react-icons/io5";
import google_icon from "../../../icons/google-icon.svg";
import apple_icon from "../../../icons/apple-icon.svg";
import slack_icon from "../../../icons/slack-icon.svg";
import {SSO_API} from "../../../Router";
import RegisterAPI from "../../services/RegisterAPI";
import {toast} from "react-toastify";
import AuthContext from "../../contexts/AuthContext";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {TailSpin} from "react-loader-spinner";
import {EMAIL_REGEX} from "../../../Regex";
import AuthAPI from "../../services/AuthAPI";
import IsAdminContext from "../../contexts/IsAdminContext";

const Register2 = () => {
    //Get auth as isAdmin context method
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
    const {isAdmin, setIsAdmin} = useContext(IsAdminContext)


    //Get history
    const history = useHistory()

    //Define default state for button loading
    const [signButtonLoading, setSignButtonLoading] = useState(false)

    //Define default state for new user info
    const [user, setUser] = useState({
        "firstname": "",
        "lastname": "",
        "email": "",
        "password": "",
        "newsletter": false,
        "terms": false
    })

    //Define default state for number password conditions
    const [numberConditions, setNumberConditions] = useState(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"])

    //Define default state for symbol password conditions
    const [symbolConditions, setSymbolConditions] = useState(["!", "@", "$", "#", "_"])

    //Define check password uppercase and lowercase regex
    const passwordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).+$")

    //Define default state for password view
    const [viewPassword, setViewPassword] = useState(false)

    const connectWithSSO = (event) => {
        const provider = event.currentTarget.id
        window.location.assign(SSO_API + '/' + provider)
    }

    const handleRegister = async () => {
        //Check username
        if (user.firstname.length === 0){
            toast.error("Please enter your firstname")
            return
        }
        if (user.lastname.length === 0){
            toast.error("Please enter your lastname")
            return
        }

        //Check email length
        if (user.email.length === 0){
            toast.error("Please enter your email")
            return
        }

        //Check email valid
        if (!EMAIL_REGEX.test(user.email)){
            toast.error("Please enter valid email")
            return
        }

        //Check password valid
        if (user.password.length < 12){
            toast.error("Your password must be contain at least 12 characters")
            return
        }
        if (!passwordPattern.test(user.password)){
            toast.error("Your password must be contain at least one upper and lower case letter")
            return
        }
        if (!numberConditions.some(e => user.password.includes(e))){
            toast.error("Your password must be contain at least 1 number")
            return
        }
        if (!symbolConditions.some(e => user.password.includes(e))){
            toast.error("Your password must be contain at least 1 symbol")
            return
        }

        //Check if user accepted terms
        if (!user.terms){
            toast.error("You need accept terms for register")
            return
        }

        if (await RegisterAPI.register(user)){
            setIsAuthenticated(true)
            if (AuthAPI.isAdmin()){
                setIsAdmin(true)
            }
            history.push("/user/dashboard/maps")
        }
    }

    const handleOauthLogin = (provider) => {
        if (provider === "google"){
            AuthAPI.getOauthGoogleUrl().then((response) => {
                if (response.status === 200){
                    const authUrl = response.data.authUrl

                    //Redirect user to google
                    window.location.assign(authUrl)
                }
            })
        } else if (provider === "apple"){
            AuthAPI.getOauthAppleUrl().then((response) => {
                if (response.status === 200){
                    const authUrl = response.data.authUrl

                    //Redirect user to google
                    window.location.assign(authUrl)
                }
            })
        }
    }

    if (isAuthenticated){
        history.push("/user/dashboard/maps")
    }

    return (
        <>
            <div className="auth-container">
                <div className="auth-content">
                    <img className="logo" src={weeki_logo} alt="Weeki logo"/>
                    <h1 className="title">Register to Weeki</h1>
                    <div className="sso-container">
                        <div className="sso" onClick={() => handleOauthLogin("google")}>
                            <p className="icon"><BiLogoGoogle /></p>
                            <p className="text">Google</p>
                        </div>
                        {/*<div className="sso" onClick={() => handleOauthLogin("apple")}>*/}
                        {/*    <p className="icon"><BiLogoApple /></p>*/}
                        {/*    <p className="text">Apple ID</p>*/}
                        {/*</div>*/}
                    </div>
                    <hr className="separator" />
                    <h1 className="title">Register with an email address</h1>
                    <div className="auth-form-container">
                        <div className="auth-input-container">
                            <input value={user.firstname} onChange={(event) => setUser({...user, firstname: event.currentTarget.value})} className="auth-input" type="text" placeholder="First name"/>
                            <input value={user.lastname} onChange={(event) => setUser({...user, lastname: event.currentTarget.value})} className="auth-input" type="text" placeholder="Last name"/>
                        </div>
                        <div className="auth-input-container">
                            <input value={user.email} onChange={(event) => setUser({...user, email: event.currentTarget.value})} className="auth-input" type="text" placeholder="Email"/>
                        </div>
                        <div className="auth-input-container">
                            <input value={user.password} onChange={(event) => setUser({...user, password: event.currentTarget.value})} className="auth-input" type={viewPassword ? "text" : "password"} placeholder="Password"/>
                            <p className="input-icon" onClick={() => setViewPassword(!viewPassword)}>
                                {viewPassword
                                    ? <FaEyeSlash />
                                    : <FaEye />
                                }
                            </p>
                        </div>
                        {user.password.length > 0 && (
                            <>
                                {user.password.length < 12 || !passwordPattern.test(user.password) || !numberConditions.some(e => user.password.includes(e)) || !symbolConditions.some(e => user.password.includes(e))
                                    ?
                                    <div className="password-requirement">
                                        <div className="field">
                                            <div className="icon">
                                                {user.password.length < 12
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use at least 12 characters</div>
                                        </div>
                                        <div className="field">
                                            <div className="icon">
                                                {!passwordPattern.test(user.password)
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use at least one upper and lower case lettre (e.g. Aa)</div>
                                        </div>
                                        <div className="field">
                                            <div className="icon">
                                                {!numberConditions.some(e => user.password.includes(e))
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use at least one number</div>
                                        </div>
                                        <div className="field">
                                            <div className="icon">
                                                {!symbolConditions.some(e => user.password.includes(e))
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use a symbol (e.g. !@$#_)</div>
                                        </div>
                                    </div>
                                    : null
                                }
                            </>

                        )}
                    </div>
                    <div className="checkbox-container">
                        <div className="check">
                            <input id="terms-agree" type="checkbox" checked={user.terms} onChange={(event) => setUser({...user, ['terms']: event.currentTarget.checked})}/>
                            <label htmlFor="terms-agree">I agree to the Weeki <span onClick={() =>  window.open("https://weeki.io/terms", "_blank")}>Terms and Privacy Policy</span>.</label>
                        </div>
                        <div className="check">
                            <input id="newsletter-agree" checked={user.newsletter} onChange={(event) => setUser({...user, ['newsletter']: event.currentTarget.checked})} type="checkbox" />
                            <label htmlFor="newsletter-agree">Yes, i would also like to sign up for the weekly newsletter (optional).</label>
                        </div>
                    </div>
                    <p className="sign-btn" onClick={() => !signButtonLoading ? handleRegister() : null}>
                        {signButtonLoading
                            ? <TailSpin height="19" width="19" color="#FFFFFF" radius={1} />
                            : "Sign in"
                        }
                    </p>
                    <p className="sub-sign-btn-link">Already have an account? <span onClick={() => history.push("/")}>Log in</span></p>
                </div>
            </div>
        </>
    )
}

export default Register2