import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import StatsAPI from "../../services/StatsAPI";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";

const AdminDashboard = () => {
    const [modelSalesChart, setModelSalesChart] = useState({
        "labels": [],
        "data": []
    })

    const [activeMapChart, setActiveMapChart] = useState({
        "labels": [],
        "activeMaps": [],
        "inactiveMaps": []
    })

    const [userCountChart, setUserCountChart] = useState([])

    const [stats, setStats] = useState({
        "totalBreakdownOfAllAccountTypeLabels": [],
        "totalBreakdownOfAllAccountTypeSeries": []
    })

    useEffect(() => {
        // StatsAPI.getMapsStatusChart().then((response) => setActiveMapChart(response.data))
        // StatsAPI.getModelsSalesChart().then((response) => {
        //     setModelSalesChart(response.data)
        // })
        // StatsAPI.getUserCountChart().then((response) => {
        //     setUserCountChart(response.data)
        // })
        // StatsAPI.getStatsForSubscriptionPlanDashboard().then((response) => {
        //     setStats(response.data)
        // })
    }, [])


    return (
        <>
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-dashboard">
                    <h1 className="page-title">Synthesis</h1>
                    <div className="metric-container">
                        <div className="metric-card open">
                            <div className="top">
                                <p>Model sales for two last years</p>
                            </div>
                            <div className="content">
                                <Chart
                                    options={{
                                        chart: {
                                            type: "bar"
                                        },
                                        xaxis: {
                                            categories: modelSalesChart.labels
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: false,
                                                columnWidth: "50%",
                                                endingShape: "rounded"
                                            }
                                        },
                                        dataLabels: {enabled: false},
                                        stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                        },
                                        fill: {
                                            colors: ["#4E70FF"],
                                            opacity: 1,
                                        },
                                        tooltip: {
                                            y: {
                                                formatter: function(val) {
                                                    return  + val + " sales";
                                                },
                                            },
                                        }
                                    }}
                                    series={[
                                        {
                                            name: "Model sales",
                                            data: modelSalesChart.data,
                                        },

                                    ]}
                                    type="bar"
                                    height={350}
                                />
                            </div>
                        </div>
                        <div className="metric-card open">
                            <div className="top">
                                <p>Active maps</p>
                            </div>
                            <div className="content">
                                <Chart options={{
                                    chart: {
                                        type: 'line',
                                        zoom: {enabled: false}
                                    },
                                    stroke: {curve: 'smooth', width: "3"},
                                    labels: activeMapChart.labels,
                                    colors: ["#2dd7a7", "#ffbf66"],
                                    legend: {position: "top", fontFamily: "Roboto, sans-serif", fontWeight: "400", fontsize: "12px", itemMargin: {horizontal: 25}}
                                }} series={[
                                    {
                                        name: "Active maps",
                                        data: activeMapChart.activeMaps
                                    },
                                    {
                                        name: "Inactive maps",
                                        data: activeMapChart.inactiveMaps
                                    }
                                ]} widht="100%" height={350} type="line"
                                       children={null}/>
                            </div>
                        </div>
                        <div className="metric-card open">
                            <div className="top">
                                <p>User account</p>
                            </div>
                            <div className="content">
                                <Chart options={{
                                    chart: {
                                        type: 'line',
                                        zoom: {enabled: false}
                                    },
                                    stroke: {curve: 'smooth', width: "3"},
                                    colors: ["#2dd7a7", "#ffbf66"],
                                    legend: {position: "top", fontFamily: "Roboto, sans-serif", fontWeight: "400", fontsize: "12px", itemMargin: {horizontal: 25}}
                                }} series={[
                                    {
                                        name: "Users",
                                        data: userCountChart
                                    }
                                ]} widht="100%" height={350} type="line"
                                       children={null}/>
                            </div>
                        </div>
                        <div className="metric-card open">
                            <div className="top">
                                <p>Total breakdown of all account type</p>
                            </div>
                            <div className="content">
                                <Chart options={{
                                    chart: {
                                        type: 'donut'
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    plotOptions: {
                                        pie: {
                                            expandOnClick: false
                                        }
                                    },
                                    labels: stats.totalBreakdownOfAllAccountTypeLabels
                                }} series={stats.totalBreakdownOfAllAccountTypeSeries} widht="100%" height={350} type="donut"
                                       children={null}/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboard