import React, {useEffect, useState} from "react";
import UserProjectsAPI from "../../../services/API/User/UserProjectsAPI";
import ProjectManageActionBar from "../../../components/customs/projects_list/ProjectManageActionBar";
import ProjectsListContainer from "../../../components/customs/projects_list/ProjectsListContainer";
import UserMapAPI from "../../../services/API/User/UserMapAPI";
import MapsListContainer from "../../../components/customs/projects_list/MapsListContainer";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";

const MyBoards = () => {
	//Get current opened project in store
	const currentOpenedProject = useDashboardStore(state => state.currentOpenedProject)

	const [search, setSearch] = useState("")

	//Get user projects
	useEffect(() => {
		//Reset data in store
		useDashboardStore.setState({currentOpenedProject: null, currentProjects: null, currentOpenedWorkspace: null})

		UserProjectsAPI.getAllUserProjects().then(response => {
			if (response.status === 200){
				useDashboardStore.setState({currentProjects: response.data})
			} else {
				useDashboardStore.setState({currentProjects: []})
			}
		})
	}, [])

	useEffect(() => {
		if (currentOpenedProject !== null){
			UserMapAPI.userGetProjectMap(currentOpenedProject.uuid).then((response) => {
				if (response.status === 200){
					useDashboardStore.setState({currentProjectMaps: response.data})
				} else {
					useDashboardStore.setState({currentProjectMaps: []})
				}
			})
		} else {
			useDashboardStore.setState({currentProjectMaps: null, currentSelectedMaps: []})
		}
	}, [currentOpenedProject])

	return (
		<>
			<h1 className="section-title"><span className={currentOpenedProject ? "clickable" : ""} onClick={() => currentOpenedProject ? useDashboardStore.setState({currentOpenedProject: null}) : null}>My projects</span> {currentOpenedProject ? "/ " + currentOpenedProject.name : ""}</h1>
			<ProjectManageActionBar search={search} setSearch={setSearch}/>
			{!currentOpenedProject && (
				<ProjectsListContainer />
			)}
			{currentOpenedProject && (
				<MapsListContainer />
			)}
		</>
	)
}

export default MyBoards