import React from "react";
import {MathJax} from "better-react-mathjax";

const LatexEquationRenderer = ({latex}) => {
	if (latex){
		return (
			<MathJax>{latex}</MathJax>
		)
	} else {
		return null
	}

}

export default LatexEquationRenderer