import React, {useEffect, useRef, useState} from "react";
import {FaUserAlt} from "react-icons/fa";
import PublicsSubscriptionPlanAPI from "../services/API/Publics/PublicsSubscriptionPlanAPI";
import {useTranslation} from "react-i18next";
import {VscChromeClose} from "react-icons/vsc";
import {TailSpin} from "react-loader-spinner";
import UserPaymentAPI, {updateCurrentUserSubscription} from "../services/API/User/UserPaymentAPI";
import {SiAmericanexpress, SiMastercard, SiVisa} from "react-icons/si";
import {IoAdd} from "react-icons/io5";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import UserPromoCodeAPI from "../services/API/User/UserPromoCodeAPI";
import {toast} from "react-toastify";
import {useUserStore} from "../services/Stores";
import {useSubscribePopupStore} from "../services/Stores/SubscribePopupStore";
import {createPortal} from "react-dom";
import {createErrorToast, createLoadingToast, createSuccessToast, removeToast} from "../services/CustomToastUtils";
import Skeleton from "react-loading-skeleton";

const SubscribePopup = () => {
    //Import translate
    const {t} = useTranslation("subscribe-popup")

    const elements = useElements()
    const stripe = useStripe()

    //Get data from store
    const popupState = useSubscribePopupStore(state => state.state)
    const popupView = useSubscribePopupStore(state => state.view)
    const userSubscription = useUserStore(state => state.userSubscription)

    //Define default page state
    const [userPaymentMethods, setUserPaymentMethods] = useState([])
    const [subscribeTime, setSubscribeTime] = useState("month")
    const [selectedPlan, setSelectedPlan] = useState(null)

    //Define default state for subscription plans
    const [plans, setPlans] = useState([{
        'uuid': "",
        'name': "",
        'monthlyPrice': 0,
        'yearlyPrice': 0,
        'multiUser': false,
        'minUser': 0,
        'maxUser': 0,
        'attributes': [],
        'position': 0
    }])

    useEffect(() => {
        // UserPaymentAPI.getAllUserPaymentMethods().then((response) => {
        //     if (response.status === 200){
        //         setUserPaymentMethods(response.data)
        //     } else {
        //         setUserPaymentMethods([])
        //         setAddCardStatus(true)
        //     }
        // })

        //Get subscription plans
        if (popupState){
            PublicsSubscriptionPlanAPI.getAll().then((response) => {
                if (response.status === 200){
                    const tempPlans = response.data.map(rp => rp.multiUser ? {...rp, selectedUserCount: rp.minUser} : rp)
                    setPlans(tempPlans)
                } else {
                    useSubscribePopupStore.getState().resetStore()
                }
            })
        }
    }, [popupState])

    const [defaultCard, setDefaultCard] = useState(true)
    const [addCardStatus, setAddCardStatus] = useState(false)

    const handleSubscribe = async () => {
        if (!invoicesDetails){
            return
        }

        //Check user have registered card before subscribe
        if (userPaymentMethods.length <= 0 && invoicesDetails["amount_due"] > 0){
            createErrorToast("Add payment card before subscribe")
            return
        }

        if (userSubscription.planUuid === selectedPlan.uuid){
            const toastId = createLoadingToast("Subscription update in progress, please don't refresh or leave page")
            if (await UserPaymentAPI.userUpdateSubscription(subscribeTime, selectedPlan.selectedUserCount)){
                useSubscribePopupStore.getState().resetStore()
                setValidPromoCode(null)
                updateCurrentUserSubscription()
                removeToast(toastId)
                createSuccessToast("Successful subscription")
            }
        } else {
            const toastId = createLoadingToast("Subscribe in progress, please don't refresh or leave page")
            if (await UserPaymentAPI.userSubscribe(selectedPlan.uuid, subscribeTime, selectedPlan.selectedUserCount, validPromoCode)) {
                useSubscribePopupStore.getState().resetStore()
                setValidPromoCode(null)
                updateCurrentUserSubscription()
                removeToast(toastId)
                createSuccessToast("Successful subscription")
            }
        }


    }

    // useEffect(() => {
    //     if (popupView === "payment"){
    //
    //     }
    // }, [popupView]);

    const opt = {
        style: {
            base: {
                color: "#4D4F5C",
                fontFamily: '"BasierCircle, sans-serif',
                fontSize: "11px",
                "::placeholder": {
                    color: "#BFBFBF",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
        showIcon: true,
    };

    const handleUpdateDefaultCard = async (cardId) => {
        if (await UserPaymentAPI.userUpdateDefaultCard(cardId)) {
            let newCardList = [...userPaymentMethods]
            let newCardIndex = newCardList.findIndex(c => c.id === cardId)
            let oldCardIndex = newCardList.findIndex(c => c.default === true)
            newCardList[newCardIndex].default = true
            if (newCardList[oldCardIndex] !== undefined){
                newCardList[oldCardIndex].default = false
            }
            setUserPaymentMethods(newCardList)
        }
    }

    const handleRemoveCard = async (cardId, event) => {
        event.stopPropagation()
        if (await UserPaymentAPI.userDeleteCard(cardId)){
            setUserPaymentMethods(userPaymentMethods.filter(c => c.id !== cardId))
        }
    }

    // COUPON CHECK
    const [validPromoCode, setValidPromoCode] = useState(null)
    const promoCodeInputRef = useRef(null)
    const handleApplyPromoCode = async () => {
        const promoCode = promoCodeInputRef.current.value

        if (promoCode.length === 0) {
            return
        }

        UserPromoCodeAPI.userGetPromoCodeById(promoCode).then(response => {
            if (response.status === 200){
                if (response.data.duration !== "once"){
                    if (subscribeTime === "year"){
                        createErrorToast("Promo code can't be apply to year subscription")
                        return
                    }
                }
                setValidPromoCode(response.data)
                promoCodeInputRef.current.value = ""
            }
        })
    }

    useEffect(() => {
        if (subscribeTime === "year"){
            if (validPromoCode){
                if (validPromoCode.duration !== "once"){
                    createErrorToast("Promo code can't be apply to year subscription")
                    setValidPromoCode(null)
                }
            }
        }
    }, [subscribeTime]);

    const addCard = () => {
        const card = elements.getElement(CardNumberElement)

        const cardTokenPromise = stripe.createToken(card)
        cardTokenPromise
            .then(async (response) => {
                const cardToken = response.token.id

                if (await UserPaymentAPI.userAddPaymentMethod(cardToken, defaultCard)) {
                    toast.success("Card successfully add")
                    setAddCardStatus(false)
                    setDefaultCard(true)
                    UserPaymentAPI.getAllUserPaymentMethods().then((response) => {
                        if (response.status === 200){
                            setUserPaymentMethods(response.data)
                        } else {
                            setUserPaymentMethods([])
                        }
                    })
                }
            })
            .catch((error) => {
                toast.error("Invalid card")
            })

    }

    const [invoicesDetails, setInvoicesDetails] = useState(null)
    const [invoicesDetailsTimeout, setInvoicesDetailsTimeout] = useState(null)

    useEffect( () => {
        if (popupView === "payment" && selectedPlan !== null) {
            if (invoicesDetailsTimeout !== null){
                clearTimeout(invoicesDetailsTimeout)
            }

            const timeout = setTimeout(() => {
                updateInvoicesDetails().then(r => null)
            }, 300)

            setInvoicesDetailsTimeout(timeout)

        }
    }, [popupView, subscribeTime, selectedPlan, validPromoCode]);

    const updateInvoicesDetails = async () => {
        setInvoicesDetails(null)

        const result = await UserPaymentAPI.generatePrice(selectedPlan.uuid, subscribeTime, selectedPlan.selectedUserCount, validPromoCode)

        if (result) {
            setInvoicesDetails(result)
        }
    }

    const handleStopSubscription = async () => {
        if (await UserPaymentAPI.userCancelCurrentSubscription()) {
            updateCurrentUserSubscription()
            createSuccessToast("Subscription downgrade successfully")
            useSubscribePopupStore.getState().resetStore()
        }
    }

    const getTooltipLeft = (plan) => {
        const range = plan.maxUser - plan.minUser
        const target = plan.selectedUserCount - plan.minUser

        return (target / range) * 100
    }

    if (popupState && userSubscription) {
        if (process.env.REACT_APP_SUBSCRIBE_STATUS === "false"){
            return null
        }

        return (
            createPortal(
            <div className="subscribe-popup-overlay">
                <div className="subscribe-popup-container">
                    {popupView === "payment" && (
                        <div className="payment-view-popup-container">
                            <div className="payment-header">
                                <div className="left">
                                    <p className="back-btn" onClick={() => useSubscribePopupStore.setState({view: "plans"})}>Back</p>
                                    <div>
                                        <p className="title">Payment details</p>
                                        <p className="sub-title">Complete your purchase by providing your payment details</p>
                                    </div>
                                </div>
                                <p className="close-btn" onClick={() => useSubscribePopupStore.getState().resetStore()}><VscChromeClose /></p>
                            </div>
                            <div className="payment-content">
                                <div className="subscribe-infos">
                                    <div className="plans-infos">
                                        <p className="plan-name">{selectedPlan.name} plan</p>
                                        <div className="plan-period-selector">
                                            <div className="selector">
                                                <div className="period-input">
                                                    <input type="radio" id="month-plan-period-input" name="plan-period" checked={subscribeTime === "month"} onChange={() => setSubscribeTime("month")}/>
                                                    <label htmlFor="month-plan-period-input">Monthly</label>
                                                </div>
                                                <div className="period-price">{selectedPlan.monthlyPrice}€/month{selectedPlan.multiUser ? "/user" : ""}</div>
                                            </div>
                                            <div className="selector">
                                                <div className="period-input">
                                                    <input type="radio" id="year-plan-period-input" name="plan-period" checked={subscribeTime === "year"} onChange={() => setSubscribeTime("year")}/>
                                                    <label htmlFor="year-plan-period-input">Annually</label>
                                                </div>
                                                <div className="period-price">{selectedPlan.yearlyPrice}€/month{selectedPlan.multiUser ? "/user" : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="plans-infos">
                                        <p className="plan-name">User</p>
                                        <div className="range-selector">
                                            <p>{selectedPlan.minUser}</p>
                                            <input type="range" onChange={(event) => setSelectedPlan({...selectedPlan, selectedUserCount: parseInt(event.currentTarget.value)})} min={selectedPlan.minUser} max={selectedPlan.maxUser} value={selectedPlan.selectedUserCount} />
                                            <p>{selectedPlan.maxUser}</p>
                                        </div>
                                    </div>
                                    <div className="card-infos-container">
                                        <p className="title">Card information</p>
                                        <p className="sub-title">Well be saved in your account settings</p>
                                        <div className="card-list-container">
                                            {userPaymentMethods.map((card, index) => (
                                                <div className={card.default ? "card default" : "card"} key={index}
                                                     onClick={() => !card.default ? handleUpdateDefaultCard(card.id) : null}>
                                                    <div className="left">
                                                        <div className="logo-container">
                                                            <div className="logo">
                                                                {card.brand === "visa" ? <SiVisa/> : ""}
                                                                {card.brand === "mastercard" ? <SiMastercard/> : ""}
                                                                {card.brand === "amex" ? <SiAmericanexpress/> : ""}
                                                            </div>
                                                        </div>
                                                        <div className="card-detail">
                                                            <div className="top">
                                                                <div className="card-name">
                                                                    <p className="name">{card.brand} ending
                                                                        in {card.last4}</p>
                                                                    {card.default && (
                                                                        <p className="card-state">default</p>
                                                                    )}
                                                                </div>
                                                                <p className="card-exp">Expiry 06/2024</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-btn">
                                                        {!card.default && (
                                                            <>
                                                                <p className="button red"
                                                                   onClick={(event) => handleRemoveCard(card.id, event)}>Remove</p>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="add-card-btn" onClick={() => {
                                            setAddCardStatus(true)
                                            setDefaultCard(true)
                                        }}>
                                            <div className="icon"><IoAdd/></div>
                                            <div className="text">Add new credit card</div>
                                        </div>
                                        {addCardStatus && (
                                            <div className="add-card-form">
                                                <div className="card-input">
                                                    <p className="card-input-title">Card number</p>
                                                    <div className="card-element">
                                                        <CardNumberElement options={opt} />
                                                    </div>
                                                </div>
                                                <div className="card-element-container">
                                                    <div className="card-input">
                                                        <p className="card-input-title">Expiry</p>
                                                        <div className="card-element">
                                                            <CardExpiryElement options={opt} />
                                                        </div>
                                                    </div>
                                                    <div className="card-input">
                                                        <p className="card-input-title">CVC</p>
                                                        <div className="card-element">
                                                            <CardCvcElement options={opt} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="default-card-checkbox">
                                                    <input id="default-card-checkbox" type="checkbox" checked={defaultCard} onChange={(e) => setDefaultCard(e.currentTarget.checked)} />
                                                    <label htmlFor="default-card-checkbox">Set as default payment</label>
                                                </div>
                                                <div className="add-card-btn-container">
                                                    <p className="cancel-add-card-btn" onClick={() => setAddCardStatus(false)}>Cancel</p>
                                                    <p className="save-card-btn" onClick={addCard}>Save</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="order-summary">
                                    <p className="title">Order summary</p>
                                    <div className="payment-container">
                                        <div className="payment-line">
                                            <p className="name">{selectedPlan.name} plan {selectedPlan.multiUser ? "(" + selectedPlan.selectedUserCount + " users)" : ""}</p>
                                            {!selectedPlan.multiUser && (
                                                <p className="price">{subscribeTime === "month" ? selectedPlan.monthlyPrice : selectedPlan.yearlyPrice * 12}€</p>
                                            )}
                                            {selectedPlan.multiUser && (
                                                <p className="price">{subscribeTime === "month" ? selectedPlan.monthlyPrice * selectedPlan.selectedUserCount : (selectedPlan.yearlyPrice * 12) * selectedPlan.selectedUserCount}€</p>
                                            )}
                                        </div>
                                        {validPromoCode !== null && (
                                            <>
                                                <div className="payment-line">
                                                    <p className="name">{validPromoCode.code} ({validPromoCode.discount}{validPromoCode["discount_type"] === "percent" ? "%" : "€"}{validPromoCode["month_duration"] !== null ?  ' for ' + validPromoCode["month_duration"] + ' month': null})</p>
                                                    <p className="price">
                                                        {invoicesDetails === null && (
                                                            <Skeleton height={18} width={100} />
                                                        )}
                                                        {invoicesDetails !== null && (
                                                            "-" + (invoicesDetails.discount / 100).toFixed(2) + "€"
                                                        )}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        {invoicesDetails !== null && (
                                            <>
                                                {invoicesDetails['ending_balance'] !== invoicesDetails['starting_balance'] && (
                                                    <div className="payment-line">
                                                        <p className="name">Applied balance</p>
                                                        <p className="price">{((invoicesDetails['starting_balance'] - invoicesDetails['ending_balance']) / 100).toFixed(2)}€</p>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="promocode-container">
                                        <input type="text" placeholder="Coupon code" ref={promoCodeInputRef} />
                                        <p className="valid-coupon-btn" onClick={handleApplyPromoCode}>Apply</p>
                                    </div>
                                    <div className="total-price">
                                        <p className="name">Order total</p>
                                        <p className="price">
                                            {invoicesDetails === null && (
                                                <Skeleton height={18} width={100} />
                                            )}
                                            {invoicesDetails !== null && (
                                                (invoicesDetails.amount_due / 100).toFixed(2) + "€"
                                            )}
                                        </p>
                                        {/*{!selectedPlan.multiUser && (*/}
                                        {/*    <p className="price">{subscribeTime === "month" ? selectedPlan.monthlyPrice : selectedPlan.yearlyPrice * 12}€</p>*/}
                                        {/*)}*/}
                                        {/*{selectedPlan.multiUser && (*/}
                                        {/*    <p className="price">{subscribeTime === "month" ? selectedPlan.monthlyPrice * selectedPlan.selectedUserCount : (selectedPlan.yearlyPrice * 12) * selectedPlan.selectedUserCount}€</p>*/}
                                        {/*)}*/}
                                    </div>
                                    <div className="purchase-btn" onClick={handleSubscribe}>Purchase</div>
                                    <p className="terms">I expressly agree not to use my retraction right of Article L221.28 as access to the solution is immediately provided by Weeki</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {popupView === "plans" && (
                        <>
                            {plans[0].uuid === "" && (
                                <div className="loader-container">
                                    <TailSpin height="128" width="128" color="#000"/>
                                </div>
                            )}
                            {plans[0].uuid !== "" && (
                                <>
                                    <p className="popup-close-icon" onClick={() => useSubscribePopupStore.getState().resetStore()}><VscChromeClose/></p>
                                    <div className="title-container">
                                        <h2 className="title">Upgrade your plan</h2>
                                    </div>
                                    <div className="subscribe-time-select-container">
                                        <div className="time-select">
                                            <p onClick={() => subscribeTime !== "month" ? setSubscribeTime('month') : null}
                                               className={subscribeTime === "month" ? "time-btn selected" : "time-btn"}>Monthly</p>
                                            <p onClick={() => subscribeTime !== "year" ? setSubscribeTime('year') : null}
                                               className={subscribeTime === "year" ? "time-btn selected" : "time-btn"}>Annually</p>
                                        </div>
                                    </div>
                                    <div className="plan-card-container">
                                        {plans.sort((a, b) => a.position - b.position).map((plan, index) => (
                                            <div className={index === 1 ? "plan-card invert" : "plan-card"} key={index}>
                                                <h1 className="plan-title">{plan.name}</h1>
                                                <p className="plan-comment">
                                                    {plan.name === "free" && (
                                                        "Discover what Weeki can do."
                                                    )}
                                                    {plan.name === "pro" && (
                                                        "For teams up to 5 people."
                                                    )}
                                                    {plan.name === "enterprise" && (
                                                        "All the scalability you need."
                                                    )}
                                                </p>
                                                <p className="plan-price">
                                                    {!plan.multiUser ? subscribeTime === "month" ? plan.monthlyPrice : plan.yearlyPrice : subscribeTime === "month" ? plan.monthlyPrice * plan.selectedUserCount : plan.yearlyPrice * plan.selectedUserCount}
                                                    <span className="money">€</span> <span className="period">/ month</span></p>
                                                {(subscribeTime === "year" && !plan.isDefault) && (
                                                    <>
                                                        <p className="economy">Save {plan.monthlyPrice * 12 - plan.yearlyPrice * 12}€
                                                            /year{plan.multiUser ? "/user" : ""}</p>
                                                        <p className="year-price">*Billing annually
                                                            for {(!plan.multiUser ? subscribeTime === "month" ? plan.monthlyPrice : plan.yearlyPrice : subscribeTime === "month" ? plan.monthlyPrice * plan.selectedUserCount : plan.yearlyPrice * plan.selectedUserCount) * 12},00
                                                            €</p>
                                                    </>
                                                )}
                                                <hr className="plan-card-separator"/>
                                                <div className="user-selector-container">
                                                    {plan.multiUser && (
                                                        <div className="range-container">
                                                            <input  value={plan.selectedUserCount} onChange={(event) => setPlans(plans.map((p, pI) => pI === index ? {...p, selectedUserCount: parseInt(event.currentTarget.value)} : p))}  type="range" min={plan.minUser} max={plan.maxUser} step={1}/>
                                                            <span className="user-count-tooltip" style={{left: getTooltipLeft(plan) + "%", transform: "translateX(calc(-32px + -" + getTooltipLeft(plan) / 8.5 + "px))"}}>{plan.selectedUserCount} Users</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <p className="user-count"><span className="icon"><FaUserAlt/></span>{plan.multiUser ? plan.selectedUserCount : "1"} user{plan.multiUser && plan.selectedUserCount > 1 ? "s" : ""}</p>
                                                <p className="storage">
                                                    {plan.attributes["cloud-files-storage"]["total-storage"]["value"]} {plan.attributes["cloud-files-storage"]["total-storage"]["unit"]} {plan.attributes["cloud-files-storage"]["total-storage"]["per-user"] ? "/ User" : ""}
                                                </p>
                                                {plan.name === userSubscription.planName && (
                                                    <>
                                                        {plan.multiUser && (
                                                            <div className="plan-subscribe-btn" onClick={() => {
                                                                useSubscribePopupStore.setState({view: "payment", paymentType: "subscription"})
                                                                setSelectedPlan(plan)
                                                            }}>Update plan</div>
                                                        )}
                                                        {!plan.multiUser && (
                                                            <div className="plan-subscribe-btn">Current plan</div>
                                                        )}
                                                    </>
                                                )}
                                                {plan.name !== userSubscription.planName && (
                                                    <>
                                                        {plan.position < userSubscription.planPosition && (
                                                            <div className="plan-subscribe-btn" onClick={() => {
                                                                if (plan.position > 0){
                                                                    useSubscribePopupStore.setState({view: "payment", paymentType: "subscription"})
                                                                    setSelectedPlan(plan)
                                                                } else {
                                                                    handleStopSubscription().then(r => null)
                                                                }
                                                            }}>Downgrade to {plan.name}</div>
                                                        )}
                                                        {plan.position > userSubscription.planPosition && (
                                                            <div className="plan-subscribe-btn" onClick={() => {
                                                                useSubscribePopupStore.setState({view: "payment", paymentType: "subscription"})
                                                                setSelectedPlan(plan)
                                                            }}>Upgrade to {plan.name}</div>
                                                        )}
                                                    </>
                                                )}
                                                {/*{(plan.name !== "free" && plan.name !== userSubscription.planName) && (*/}
                                                {/*    <div className="plan-subscribe-btn" onClick={() => {*/}
                                                {/*        useSubscribePopupStore.setState({view: "payment", paymentType: "subscription"})*/}
                                                {/*        setSelectedPlan(plan)*/}
                                                {/*    }}>Upgrade to {plan.name}</div>*/}
                                                {/*)}*/}
                                            </div>
                                        ))}
                                        <div className="compare-features-link">
                                            <a href="https://www.weeki.io/plans" target="_blank" rel="noopener noreferrer">
                                                Compare all features on <br></br> https://www.weeki.io/plans
                                            </a>
                                        </div>
                                    </div>
                                    {/*<div className="matrix-container">*/}
                                    {/*    <h1 className="title">Comparez toutes les fonctionnalités</h1>*/}
                                    {/*    <div className="matrix-table">*/}
                                    {/*        {plans.map((plan, index) => (*/}
                                    {/*            <>*/}
                                    {/*                {index === 0 && (*/}
                                    {/*                    <div className="matrix-column">*/}
                                    {/*                        {Object.entries(plan.attributes).map(([key, value]) => (*/}
                                    {/*                            <>*/}
                                    {/*                                <h1 className="column-title">{t('matrix.' + value['name-key'])}</h1>*/}
                                    {/*                                {Object.entries(value.attributes).map(([subKey, subValue]) => (*/}
                                    {/*                                    <>*/}
                                    {/*                                        <div className="row">{t('matrix.' + subValue['name-key'])}</div>*/}
                                    {/*                                    </>*/}
                                    {/*                                ))}*/}
                                    {/*                            </>*/}
                                    {/*                        ))}*/}
                                    {/*                    </div>*/}
                                    {/*                )}*/}
                                    {/*                <div className="matrix-column">*/}
                                    {/*                    {Object.entries(plan.attributes).map(([key, value]) => (*/}
                                    {/*                        <>*/}
                                    {/*                            <h1 className="column-title">{t('plan.name.' + plan.name)}</h1>*/}
                                    {/*                            {Object.entries(value.attributes).map(([subKey, subValue]) => (*/}
                                    {/*                                <>*/}
                                    {/*                                    <div className={"row " + (subValue.value === true ? "row icon-green" : subValue.value === false ? "icon-red" : "")}>*/}
                                    {/*                                        {subValue.value === true ? <MdOutlineCheck*/}
                                    {/*                                            size={22}/> : subValue.value === false ?*/}
                                    {/*                                            <ImCross size={12}/> : subValue.value}*/}
                                    {/*                                        {subValue['value-key'] ? " " + t('matrix.' + subValue['value-key']) : ""}*/}
                                    {/*                                    </div>*/}
                                    {/*                                </>*/}
                                    {/*                            ))}*/}
                                    {/*                        </>*/}
                                    {/*                    ))}*/}
                                    {/*                </div>*/}
                                    {/*            </>*/}
                                    {/*        ))}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </>
                            )}
                        </>
                    )}


                </div>
            </div>
            , document.body)
        )
    } else{
        return null
    }
}

export default SubscribePopup