import React, {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {TailSpin} from "react-loader-spinner";
import {PiMinusBold, PiPlusBold} from "react-icons/pi";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FullScreenPDFViewer = ({pdfUrl, scale}) => {
    const [numPage, setNumPage] = useState(null)
    const [pdfLoadingStatus, setPDFLoadingStatus] = useState(true)

    const onDocumentLoadSuccess = (pdf) => {
        setNumPage(pdf.numPages)
        setPDFLoadingStatus(false)
    }

    return (
        <div className={pdfLoadingStatus ? "fullscreen-pdf-container loading" : "fullscreen-pdf-container"}>
            <Document loading="" file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {!pdfLoadingStatus && (
                    <>
                        {Array.from(new Array(numPage), (el, index) => (
                            <>
                                <Page renderMode={'canvas'} scale={scale} renderTextLayer={false} renderAnnotationLayer={false} key={`page_${index + 1}`} pageNumber={index + 1} />
                            </>
                        ))}
                    </>
                )}
            </Document>
            {pdfLoadingStatus && (
                <TailSpin height="128" width="128" color="#000"/>
            )}
        </div>
    )
}

export default FullScreenPDFViewer