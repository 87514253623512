import React, {useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ContentItemAPI from "../../../services/ContentItemAPI";
import {use} from "i18next";
import {useContentItemStore} from "../../../services/Stores/ContentItemStore";

const FullscreenTextEditor = ({fileUrl, setSaveStatus, itemUuid, setSaveInProgress, needSave, setNeedSave, publicMap = false}) => {
    const [editorValue, setEditorValue] = useState("")
    const [saveTextTimeout, setSaveTextTimeout] = useState(null)
    const [textLoad, setTextLoad] = useState(false)
    const editorRef = useRef(null)

    useEffect(() => {
        if (fileUrl){
            fetch(fileUrl)
                .then( r => r.text() )
                .then( t => {
                    setEditorValue(t)
                    setTextLoad(true)
                } )
        }
    }, [fileUrl])

    const handleChangeText = (value) => {
        if (publicMap){
            return;
        }
        if (!textLoad){
            return
        }
        setEditorValue(value)
        setSaveStatus(false)

        if (saveTextTimeout){
            clearTimeout(saveTextTimeout)
        }

        setSaveTextTimeout(setTimeout(() => {
            handleSaveText().then()
        }, 5000))

    }

    const handleSaveText = async (regenerateThumbnail = false) => {
        setSaveInProgress(true)
        //Get text preview
        const textPreview = editorRef.current.getEditor().getText(0, 150)
        await ContentItemAPI.updateContentItemTextFile(itemUuid, editorValue, true, textPreview)
        setSaveInProgress(false)
        setSaveStatus(true)
    }

    useEffect(() => {
        if (needSave){
            if (saveTextTimeout){
                clearTimeout(saveTextTimeout)
            }

            handleSaveText(true).then()
            setNeedSave(false)
            useContentItemStore.setState({currentFullscreenItemUuid: null})
        }
    }, [needSave])

    return (
        <div className="fullscreen-text-container">
            <div className="text-editor-container">
                <ReactQuill ref={editorRef} theme="snow" readOnly={publicMap} value={editorValue} onChange={handleChangeText} />
            </div>
        </div>
    )
}

export default FullscreenTextEditor