import React, {useState} from "react"
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";
import {useHistory, useParams} from "react-router-dom";
import {FaChevronLeft} from "react-icons/fa";
import MapsHistoryTable from "../../components/HistoryTables/MapsHistoryTable";
import UsersConnectionHistoryTable from "../../components/HistoryTables/UsersConnectionHistoryTable";
import CookiesHistoryTable from "../../components/HistoryTables/CookiesHistoryTable";
import AutoEmailHistoryTable from "../../components/HistoryTables/AutoEmailHistoryTable";
import UsersHistoryTable from "../../components/HistoryTables/UsersHistoryTable";
import PromoCodeHistoryTable from "../../components/HistoryTables/PromoCodeHistoryTable";
import EmailTemplateHistoryTable from "../../components/HistoryTables/EmailTemplateHistoryTable";
import OverlayHistoryTable from "../../components/HistoryTables/OverlayHistoryTable";
import PlanHistoryTable from "../../components/HistoryTables/PlanHistoryTable";
import ProjectHistoryTable from "../../components/HistoryTables/ProjectHistoryTable";

const AdminDashboardHistoryTable = () => {
    //Get history
    const history = useHistory()

    //Get history type in params
    const {historyType} = useParams()

    const [modalOpened, setModalOpened] = useState(false)

    return (
        <>
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard" style={modalOpened ? {"overflow": 'hidden'} : null}>
                <div className="admin-history-table-container">
                    <div className="back-btn" onClick={() => history.push("/admin/dashboard/history")}>
                        <p className="icon"><FaChevronLeft /></p>
                        <p className="text">Back</p>
                    </div>
                    {historyType === "maps" && (
                        <MapsHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "users-connection" && (
                        <UsersConnectionHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "cookies" && (
                        <CookiesHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "auto-email" && (
                        <AutoEmailHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "users" && (
                        <UsersHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "promo-code" && (
                        <PromoCodeHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "email-template" && (
                        <EmailTemplateHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "overlay" && (
                        <OverlayHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "plan" && (
                        <PlanHistoryTable setModalOpened={setModalOpened} />
                    )}
                    {historyType === "project" && (
                        <ProjectHistoryTable setModalOpened={setModalOpened} />
                    )}
                </div>
            </main>
        </>
    )
}

export default AdminDashboardHistoryTable