import React from "react";
import fullscreen_icon from "../../../../icons/object-menu/fullscreen.svg"
import download_icon from "../../../../icons/object-menu/download.svg"

const ContentItemSelectedMenu = ({properties}) => {
	const attributes = properties.attributes !== null ? JSON.parse(properties.attributes) : null

	if (!properties.fullscreen && !properties.mime_type && !attributes && !properties.authorized_extensions){
		return null
	}

	return (
		<>
			<div className="custom__map-selected-popup-container">
				{attributes && (
					<>
						{attributes.filter(a => a.type === 'url')[0] !== undefined && (
							<>
								<div className="selected-menu-button" id="open-link-btn">
									<div className="button-icon">
										<img src={fullscreen_icon} alt="Open link"/>
									</div>
								</div>
							</>
						)}
					</>
				)}
				{properties.fullscreen && (
					<div className="selected-menu-button" id="fullscreen-btn">
						<div className="button-icon">
							<img src={fullscreen_icon} alt="Full screen"/>
						</div>
					</div>
				)}
				{(properties.mime_type || properties.authorized_extensions) && (
					<div className="selected-menu-button" id="download-btn">
						<div className="button-icon">
							<img src={download_icon} alt="Download"/>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default ContentItemSelectedMenu