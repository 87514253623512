import React, {useEffect, useRef, useState} from "react";
import DashboardStatCard from "../../components/DashboardStatCard";
import icon_card_1 from "../../../icons/admin-dashboard/emails/card-1.svg";
import icon_card_2 from "../../../icons/admin-dashboard/emails/card-2.svg";
import icon_card_3 from "../../../icons/admin-dashboard/emails/card-3.svg";
import icon_card_4 from "../../../icons/admin-dashboard/emails/card-4.svg";
import alert_delete from "../../../icons/admin-dashboard/emails/alert.svg";
import success_send from "../../../icons/admin-dashboard/emails/success-send.svg";
import UserAPI from "../../services/UserAPI";
import StatsAPI from "../../services/StatsAPI";
import {BiHistory} from "react-icons/bi";
import {BsThreeDots} from "react-icons/bs";
import {FaCheck, FaChevronLeft, FaChevronRight, FaChevronUp, FaSort, FaSortDown, FaSortUp, FaUserPlus} from "react-icons/fa";
import {IoMdAdd, IoMdClose, IoMdMail} from "react-icons/io";
import {IoSearchSharp} from "react-icons/io5";
import {RiSendPlaneFill} from "react-icons/ri";
import ShowOrHideColumnMenu from "../../components/ShowOrHideColumnMenu";
import MailAPI from "../../services/MailAPI";
import MailTemplateAPI from "../../services/MailTemplateAPI";
import MailHistoryAPI from "../../services/MailHistoryAPI";
import {Editor} from 'react-draft-wysiwyg'
import {convertToRaw, EditorState, ContentState} from "draft-js"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import {toast} from "react-toastify";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";

const AdminDashboardEmail = () => {
    //Define default state for user list
    const [users, setUsers] = useState([
        {
            "id": "",
            "firstname": "",
            "lastname": "",
            "creation_date": "",
            "newsletter_status": "",
            "mapNumber": 0,
            "objectNumber": 0,
            "accountType": "",
            "status": "",
            "suspendDayLeft": "",
            "expert": "",
            "email": "",
            "activePayment": "NONE"
        }
    ])

    const [openedArraySubMenuTemplateId, setOpenedArraySubMenuTemplateId] = useState(0)

    //Define default state for email template list
    const [templates, setTemplates] = useState([{
        "id": "",
        "name": "",
        "subject": "",
        "createdAt": "",
        "category": "",
        "lastUsage": "",
        "sendTimes": "",
        "body": ""
    }])

    //Define default state for mail history list
    const [mailHistory, setMailHistory] = useState([{
        "id": "",
        "template": "",
        "subject": "",
        "category": "",
        "date": "",
        "recipient": "",
        "status": ""
    }])

    //Define default state for stats
    const [stats, setStats] = useState({
        "mailSend": 0,
        "mailNotSend": 0
    })

    useEffect(() => {
        UserAPI.adminGetUsers().then((response) => {
            if (response.status === 200){
                setUsers(response.data)
            }
        })

        StatsAPI.getAllStats().then((response) => {
            if (response.status === 200){
                setStats(response.data)
            }
        })

        MailTemplateAPI.getAllEmailTemplate().then((response) => {
            if (response.status === 200){
                setTemplates(response.data)
            }
        })

        MailHistoryAPI.getAllMailHistory().then((response) => {
            if (response.status === 200){
                setMailHistory(response.data)
            }
        })
    }, [])

    /******************************USER SEARCH********************************/
    const [userSearch, setUserSearch] = useState("")

    const filteredUsers = users.filter(u =>
        String(u.firstname).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.lastname).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.accountType).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.status).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.creation_date).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.newsletter_status).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.mapNumber).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.objectNumber).toLowerCase().includes(userSearch.toLowerCase()) ||
        String(u.expert).toLowerCase().includes(userSearch.toLowerCase())
    )
    /*************************************************************************/

    /******************************TEMPLATE SEARCH****************************/
    const [templateSearch, setTemplateSearch] = useState("")

    const filteredTemplates = templates.filter(t =>
        String(t.name).toLowerCase().includes(templateSearch.toLowerCase()) ||
        String(t.createdAt).toLowerCase().includes(templateSearch.toLowerCase()) ||
        String(t.lastUsage).toLowerCase().includes(templateSearch.toLowerCase()) ||
        String(t.category).toLowerCase().includes(templateSearch.toLowerCase()) ||
        String(t.sendTimes).toLowerCase().includes(templateSearch.toLowerCase())
    )
    /*************************************************************************/

    /******************************MAIL HISTORY SEARCH************************/
    const [historySearch, setHistorySearch] = useState("")

    const filteredMailHistory = mailHistory.filter(h =>
        String(h.template).toLowerCase().includes(historySearch.toLowerCase()) ||
        String(h.subject).toLowerCase().includes(historySearch.toLowerCase()) ||
        String(h.category).toLowerCase().includes(historySearch.toLowerCase()) ||
        String(h.date).toLowerCase().includes(historySearch.toLowerCase()) ||
        String(h.recipient).toLowerCase().includes(historySearch.toLowerCase())
    )
    /*************************************************************************/

    /*****************************USER PAGINATION*****************************/
    const [currentPage, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const maxPage = Math.ceil(filteredUsers.length / itemPerPage)

    const nextPage = () => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }
    const previousPage = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }

    const start = (currentPage - 1) * itemPerPage
    const end = start + itemPerPage
    const paginatedUsers = filteredUsers.slice(start, end)

    const pageButtons = []

    for (let i = 0; i < maxPage; i++){
        pageButtons.push(<p onClick={() => setCurrentPage(i + 1)} className={currentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************TEMPLATE PAGINATION*****************************/
    const [templateCurrentPage, setTemplateCurrentPage] = useState(1)
    const [templateItemPerPage, setTemplateItemPerPage] = useState(10)
    const templateMaxPage = Math.ceil(filteredTemplates.length / templateItemPerPage)

    const templateNextPage = () => {
        setTemplateCurrentPage((templateCurrentPage) => Math.min(templateCurrentPage + 1, templateMaxPage))
    }
    const templatePreviousPage = () => {
        setTemplateCurrentPage((templateCurrentPage) => Math.max(templateCurrentPage - 1, 1))
    }

    const templateStart = (templateCurrentPage - 1) * templateItemPerPage
    const templateEnd = templateStart + templateItemPerPage
    const paginatedTemplate = filteredTemplates.slice(templateStart, templateEnd)

    const templatePageButton = []

    for (let i = 0; i < templateMaxPage; i++){
        templatePageButton.push(<p onClick={() => setTemplateCurrentPage(i + 1)} className={templateCurrentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************MAIL HISTORY PAGINATION*********************/
    const [historyCurrentPage, setHistoryCurrentPage] = useState(1)
    const [historyItemPerPage, setHistoryItemPerPage] = useState(10)
    const historyMaxPage = Math.ceil(filteredMailHistory.length / historyItemPerPage)

    const historyNextPage = () => {
        setHistoryCurrentPage((historyCurrentPage) => Math.min(historyCurrentPage + 1, historyMaxPage))
    }
    const historyPreviousPage = () => {
        setHistoryCurrentPage((historyCurrentPage) => Math.max(historyCurrentPage - 1, 1))
    }

    const historyStart = (historyCurrentPage - 1) * historyItemPerPage
    const historyEnd = historyStart + historyItemPerPage
    const paginatedHistory = filteredMailHistory.slice(historyStart, historyEnd)

    const historyPageButton = []

    for (let i = 0; i < historyMaxPage; i++){
        historyPageButton.push(<p onClick={() => setHistoryCurrentPage(i + 1)} className={historyCurrentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /******************************SHOW OR HIDE*******************************/
    const [userShowHideStatus, setUserShowHideStatus] = useState(false)
    const [userColumnList, setUserColumnList] = useState({
        "plan": true,
        "active payment": true,
        "status": true,
        "created on": true,
        "newsletter": true,
        "maps": true,
        "objects": true,
        "terms": true,
        "expert": true
    })

    const resetShowOrHide = () => {
        setUserColumnList({
            "plan": true,
            "active payment": true,
            "status": true,
            "created on": true,
            "newsletter": true,
            "maps": true,
            "objects": true,
            "terms": true,
            "expert": true
        })
    }
    /*************************************************************************/
    /*******************************CHECK USER********************************/
    const [checkedUser, setCheckedUser] = useState([])

    const checkAllUser = (event) => {
        setCheckedUser([])
        if (event.currentTarget.checked){
            for (let i = 0; i < users.length; i++){
                setCheckedUser(checked => [...checked, users[i].id])
            }
        }
    }
    /*************************************************************************/

    /******************************SEND EMAIL MODAL***************************/
    const [sendEmailModalStatus, setSendEmailModalStatus] = useState(false)
    const sendEmailInput = useRef(null)
    const [sendMailInputValue, setSendMailInputValue] = useState("")
    const [sendEmailList, setSendEmailList] = useState([])
    const [sendStatus, setSendStatus] = useState(false)
    const [sendEmailSelectedTemplateId, setSendEmailSelectedTemplateId] = useState(0)

    const addCheckedUserToEmailList = () => {
        for (let i = 0; i < checkedUser.length; i++){
            setSendEmailList(sendEmailList => [...sendEmailList, users.filter(u => u.id === checkedUser[i])[0].email])
        }
    }

    const detectSpace = (event) => {
        const keyCode = event.keyCode
        if (keyCode === 32 && sendMailInputValue.length > 5 && sendMailInputValue.includes("@") && !sendEmailList.includes(sendMailInputValue)){
            setSendEmailList(sendEmailList => [...sendEmailList, sendMailInputValue.replaceAll(" ", "")])
            setSendMailInputValue("")
        }
    }

    const sendEmails = async () => {
        if (sendEmailList.length === 0){
            return
        }

        if (await MailAPI.sendGroupMailTemplate(sendEmailSelectedTemplateId, sendEmailList)){
            setSendStatus(true)

            MailHistoryAPI.getAllMailHistory().then((response) => {
                setMailHistory(response.data)
            })
        }
    }
    /*************************************************************************/

    /*****************************CREATE TEMPLATE MODAL***********************/
    const [createTemplateModalStatus, setCreateTemplateModalStatus] = useState(false)
    const [newTemplateName, setNewTemplateName] = useState("")
    const [newTemplateCategory, setNewTemplateCategory] = useState("")
    const [newTemplateBody, setNewTemplateBody] = useState(() => {
        EditorState.createEmpty()
    })

    const createNewTemplate = async () => {
        if (newTemplateName !== "" && newTemplateCategory !== "" && newTemplateBody.getCurrentContent() !== null) {
            if (await MailTemplateAPI.createEmailTemplate(newTemplateName, newTemplateCategory, draftToHtml(convertToRaw(newTemplateBody.getCurrentContent())))) {
                setNewTemplateCategory("")
                setNewTemplateName("")
                setNewTemplateCategory("")
                setCreateTemplateModalStatus(false)
                toast.success("Email template successfully created")
                MailTemplateAPI.getAllEmailTemplate().then((response) => {
                    setTemplates(response.data)
                })
            }
        }
    }
    /*************************************************************************/

    /*******************************COPY TEMPLATE*****************************/
    const copyTemplate = async (event) => {
        const templateId = event.currentTarget.id
        if (await MailTemplateAPI.copyTemplate(templateId)){
            toast.success("Copy successfully created")
            MailTemplateAPI.getAllEmailTemplate().then((response) => {
                setTemplates(response.data)
            })
        }
    }
    /*************************************************************************/

    /***************************DELETE TEMPLATE*******************************/
    const [deleteTemplateModalStatus, setDeleteTemplateModalStatus] = useState(false)
    const [deleteTemplateStatus, setDeleteTemplateStatus] = useState(false)
    const [deletedTemplateId, setDeletedTemplateId] = useState("")

    const deleteTemplate = async (event) => {
        if (await MailTemplateAPI.deleteTemplate(deletedTemplateId)){
            setDeleteTemplateModalStatus(false)
            toast.success("Template successfully deleted")
            MailTemplateAPI.getAllEmailTemplate().then((response) => {
                if (response.status === 200){
                    setTemplates(response.data)
                } else if (response.status === 204){
                    setTemplates([{
                        "id": "",
                        "name": "",
                        "subject": "",
                        "createdAt": "",
                        "category": "",
                        "lastUsage": "",
                        "sendTimes": "",
                        "body": ""
                    }])
                }
            })
        }
    }
    /*************************************************************************/

    /*****************************PREVIEW TEMPLATE****************************/
    const [updatedTemplateId, setUpdatedTemplateId] = useState("")
    const [updateTemplateModalStatus, setUpdateTemplateModalStatus] = useState(false)
    const [updatedTemplateEditor, setUpdatedTemplateEditor] = useState(() => {
        EditorState.createEmpty()
    })

    const [updatedTemplate, setUpdatedTemplate] = useState({
        "id": "",
        "name": "",
        "subject": "",
        "createdAt": "",
        "category": "",
        "lastUsage": "",
        "sendTimes": "",
        "body": ""
    })

    useEffect(() => {
        if (updatedTemplateId !== ""){
            setUpdatedTemplate(templates.filter(t => t.id === updatedTemplateId)[0])
            const blocksFromHtml = htmlToDraft(templates.filter(t => t.id === updatedTemplateId)[0].body);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setUpdatedTemplateEditor(EditorState.createWithContent(contentState))
            setUpdateTemplateModalStatus(true)
        }
    }, [updatedTemplateId])

    const updateTemplate = async () => {
        if (updatedTemplate.name !== "" && updatedTemplate.category !== "" && updatedTemplateEditor.getCurrentContent() !== null){
            const sendData = new FormData()
            sendData.append("_method", "PUT")
            sendData.append('templateUuid', updatedTemplateId)

            if (updatedTemplate.name !== templates.filter(t => t.id === updatedTemplateId)[0].name){
                sendData.append('name', updatedTemplate.name)
            }
            if (updatedTemplate.category !== templates.filter(t => t.id === updatedTemplateId)[0].category){
                sendData.append('category', updatedTemplate.category)
            }
            if (draftToHtml(convertToRaw(updatedTemplateEditor.getCurrentContent())) !== templates.filter(t => t.id === updatedTemplateId)[0].body){
                sendData.append('body', draftToHtml(convertToRaw(updatedTemplateEditor.getCurrentContent())))
            }

            if (await MailTemplateAPI.updateTemplate(sendData)){
                setUpdateTemplateModalStatus(false)
                setUpdatedTemplateEditor(() => EditorState.createEmpty())
                setUpdatedTemplateId("")
                toast.success("Template updated successfully")
                MailTemplateAPI.getAllEmailTemplate().then((response) => {
                    setTemplates(response.data)
                })
            }
        }
    }
    /*************************************************************************/

    /*******************************SORT USER*********************************/
    const [sortedColumnNameTemplate, setSortedColumnNameTemplate] = useState("")
    const [sortedTypeTemplate, setSortedTypeTemplate] = useState("")
    const [sortedColumnNameUser, setSortedColumnNameUser] = useState("")
    const [sortedTypeUser, setSortedTypeUser] = useState("")
    const [sortedColumnNameSendMail, setSortedColumnNameSendMail] = useState("")
    const [sortedTypeSendMail, setSortedTypeSendMail] = useState("")
    let sortedUsers = paginatedUsers

    if (sortedColumnNameUser !== "" && sortedTypeUser !== ""){
        if (sortedTypeUser === "1"){
            sortedUsers = paginatedUsers.sort((a, b) => String(a[sortedColumnNameUser]).localeCompare(String(b[sortedColumnNameUser])))
        } else if (sortedTypeUser === "2") {
            sortedUsers = paginatedUsers.sort((a, b) => String(b[sortedColumnNameUser]).localeCompare(String(a[sortedColumnNameUser])))
        }
    }
    /*************************************************************************/

    /*******************************SORT MAIL HISTORY*************************/
    let sortedSendMail = paginatedHistory

    if (sortedColumnNameSendMail !== "" && sortedTypeSendMail !== ""){
        if (sortedTypeSendMail === "1"){
            sortedSendMail = paginatedHistory.sort((a, b) => String(a[sortedColumnNameSendMail]).localeCompare(String(b[sortedColumnNameSendMail])))
        } else if (sortedTypeSendMail === "2") {
            sortedSendMail = paginatedHistory.sort((a, b) => String(b[sortedColumnNameSendMail]).localeCompare(String(a[sortedColumnNameSendMail])))
        }
    }
    /*************************************************************************/

    /*******************************SORT MAIL TEMPLATE************************/
    let sortedTemplate = paginatedTemplate

    if (sortedColumnNameTemplate !== "" && sortedTypeTemplate !== ""){
        if (sortedTypeTemplate === "1"){
            sortedTemplate = paginatedTemplate.sort((a, b) => String(a[sortedColumnNameTemplate]).localeCompare(String(b[sortedColumnNameTemplate])))
        } else if (sortedTypeTemplate === "2") {
            sortedTemplate = paginatedTemplate.sort((a, b) => String(b[sortedColumnNameTemplate]).localeCompare(String(a[sortedColumnNameTemplate])))
        }
    }
    /*************************************************************************/

    const dashboard = document.getElementById("scroll-container")

    return (
        <>
            {/*SEND EMAIL MODAL*/}
            {sendEmailModalStatus &&(
                <div className="modal-overlay">
                    <div className={sendEmailModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Send mails</h1>
                            <p onClick={() => {
                                setSendEmailList([])
                                setSendMailInputValue("")
                                setSendEmailModalStatus(false)
                                setSendStatus(false)
                                setSendEmailSelectedTemplateId(0)
                            }}><IoMdClose /></p>
                        </div>
                        {!sendStatus && (
                            <div className="content mails">
                                <div className="box-title">
                                    <p className="icon"><IoMdMail /></p>
                                    <p className="text">Emails addresses of selected users</p>
                                </div>
                                <div className="email-box" onClick={() => sendEmailInput.current.focus()}>
                                    {sendEmailList.map((mail, index) => (
                                        <div className="mail-item">
                                            <p className="text">{mail}</p>
                                            <p className="icon" onClick={() => setSendEmailList(sendEmailList.filter(l => l !== mail))}><IoMdClose /></p>
                                        </div>
                                    ))}
                                    <input value={sendMailInputValue} ref={sendEmailInput} type="text" onChange={(event) => setSendMailInputValue(event.currentTarget.value.replaceAll(" ", ""))} onKeyDown={detectSpace}/>
                                </div>
                                <select name="template" className="template-select" onChange={(event) => setSendEmailSelectedTemplateId(event.currentTarget.value)}>
                                    {templates[0].id !== "" && (
                                        <>
                                            {sendEmailSelectedTemplateId === 0 && (
                                                <option value="">Select template</option>
                                            )}
                                            {templates.map((template, index) => (
                                                <option value={template.id} key={index}>{template.name}</option>
                                            ))}
                                        </>
                                    )}
                                    {templates[0].id === "" && (
                                        <option value="">No template available</option>
                                    )}
                                </select>
                                <div className="button-container">
                                    <div className="btn cancel" onClick={() => {
                                        setSendEmailList([])
                                        setSendMailInputValue("")
                                        setSendEmailModalStatus(false)
                                        setSendEmailSelectedTemplateId(0)
                                    }}>
                                        <p className="text">Cancel</p>
                                        <p className="icon"><IoMdClose /></p>
                                    </div>
                                    <div className="btn valid" onClick={sendEmails}>
                                        <p className="text">Send mails</p>
                                        <p className="icon"><RiSendPlaneFill /></p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {sendStatus && (
                            <div className="content mails">
                                <div className="success-container">
                                    <img src={success_send} alt="Success send icon"/>
                                    <h1 className="message">Emails successfully sent to :</h1>
                                    <div className="mail-list">
                                        {sendEmailList.map((email, index) => (
                                            <div key={index} className="mail">
                                                <p className="icon"><FaCheck /></p>
                                                <p className="text">{email}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            )}
            {/*CREATE TEMPLATE MODAL*/}
            {createTemplateModalStatus &&(
                <div className="modal-overlay">
                    <div className={createTemplateModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Create template</h1>
                            <p onClick={() => {
                                setNewTemplateCategory("")
                                setNewTemplateName("")
                                setNewTemplateCategory("")
                                setCreateTemplateModalStatus(false)
                            }}><IoMdClose /></p>
                        </div>
                        <div className="content create-template-mail">
                            <div className="input-group">
                                <p className="title">Name</p>
                                <input className={newTemplateName.length > 0 ? "active" : null} type="text" name="template-name" placeholder="Enter a name for the new template" onChange={(event) => setNewTemplateName(event.currentTarget.value)}/>
                            </div>
                            <div className="category-select">
                                <p className="title">Category</p>
                                <div className="selector">
                                    <p className={newTemplateCategory === "marketing" ? "active" : null} onClick={() => newTemplateCategory !== "marketing" ? setNewTemplateCategory('marketing') : setNewTemplateCategory("")}>Marketing</p>
                                    <p className={newTemplateCategory === "security" ? "active" : null} onClick={() => newTemplateCategory !== "security" ? setNewTemplateCategory('security') : setNewTemplateCategory("")}>Security</p>
                                    <p className={newTemplateCategory === "payment" ? "active" : null} onClick={() => newTemplateCategory !== "payment" ? setNewTemplateCategory('payment') : setNewTemplateCategory("")}>Payment</p>
                                    <p className={newTemplateCategory === "admin" ? "active" : null} onClick={() => newTemplateCategory !== "admin" ? setNewTemplateCategory('admin') : setNewTemplateCategory("")}>Admin</p>
                                </div>
                            </div>
                            <div className="editor-container">
                                <p className="title">Content</p>
                                <Editor initialEditorState={newTemplateBody} onEditorStateChange={setNewTemplateBody} editorClassName="editor-box" toolbarClassName="editor-toolbox"/>
                            </div>
                            <div className="button-container">
                                <div className="btn cancel" onClick={() => {
                                    setNewTemplateCategory("")
                                    setNewTemplateName("")
                                    setNewTemplateCategory("")
                                    setCreateTemplateModalStatus(false)
                                }}>
                                    <p className="text">Cancel</p>
                                    <p className="icon"><IoMdClose /></p>
                                </div>
                                <div className="btn valid" onClick={createNewTemplate}>
                                    <p className="text">Create template</p>
                                    <p className="icon"><IoMdAdd /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*UPDATE TEMPLATE MODAL*/}
            {updateTemplateModalStatus &&(
                <div className="modal-overlay">
                    <div className={updateTemplateModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Preview & Modify *{updatedTemplate.name}*</h1>
                            <p onClick={() => {
                                setUpdateTemplateModalStatus(false)
                            }}><IoMdClose /></p>
                        </div>
                        <div className="content create-template-mail">
                            <div className="input-group">
                                <p className="title">Name</p>
                                <input value={updatedTemplate.name} className={updatedTemplate.name.length > 0 ? "active" : null} type="text" name="template-name" placeholder="Enter a name for the new template" onChange={(event) => setUpdatedTemplate({...updatedTemplate, ['name']: event.currentTarget.value})}/>
                            </div>
                            <div className="category-select">
                                <p className="title">Category</p>
                                <div className="selector">
                                    <p className={updatedTemplate.category === "marketing" ? "active" : null} onClick={() => updatedTemplate.category !== "marketing" ? setUpdatedTemplate({...updatedTemplate, ['category']: "marketing"}) : setUpdatedTemplate({...updatedTemplate, ['category']: ""})}>Marketing</p>
                                    <p className={updatedTemplate.category === "security" ? "active" : null} onClick={() => updatedTemplate.category !== "security" ? setUpdatedTemplate({...updatedTemplate, ['category']: "security"}) : setUpdatedTemplate({...updatedTemplate, ['category']: ""})}>Security</p>
                                    <p className={updatedTemplate.category === "payment" ? "active" : null} onClick={() => updatedTemplate.category !== "payment" ? setUpdatedTemplate({...updatedTemplate, ['category']: "payment"}) : setUpdatedTemplate({...updatedTemplate, ['category']: ""})}>Payment</p>
                                    <p className={updatedTemplate.category === "admin" ? "active" : null} onClick={() => updatedTemplate.category !== "admin" ? setUpdatedTemplate({...updatedTemplate, ['category']: "admin"}) : setUpdatedTemplate({...updatedTemplate, ['category']: ""})}>Admin</p>
                                </div>
                            </div>
                            <div className="editor-container">
                                <p className="title">Content</p>
                                <Editor defaultEditorState={updatedTemplateEditor} initialEditorState={updatedTemplateEditor} onEditorStateChange={setUpdatedTemplateEditor} editorClassName="editor-box" toolbarClassName="editor-toolbox"/>
                            </div>
                            <div className="button-container">
                                <div className="btn cancel" onClick={() => {
                                    setUpdatedTemplateId(0)
                                    setUpdatedTemplateEditor(() => EditorState.createEmpty())
                                    setUpdateTemplateModalStatus(false)
                                }}>
                                    <p className="text">Cancel</p>
                                    <p className="icon"><IoMdClose /></p>
                                </div>
                                <div className="btn valid" onClick={updateTemplate}>
                                    <p className="text">Modify Template</p>
                                    <p className="icon"><IoMdAdd /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*DELETE TEMPLATE MODAL*/}
            {deleteTemplateModalStatus &&(
                <div className="modal-overlay">
                    <div className={deleteTemplateModalStatus ? "modal open" : "modal"}>
                        <div className="header delete">
                            <h1>Delete template {templates[0].id !== "" ? "*" + templates.filter(t => t.id === deletedTemplateId)[0].name + "*" : null}</h1>
                            <p onClick={() => {
                                setDeleteTemplateModalStatus(false)
                            }}><IoMdClose /></p>
                        </div>
                        {!deleteTemplateStatus && (
                            <div className="content delete-template-mail">
                                <p className="confirm-message">
                                    <img src={alert_delete} alt="Alert icon"/>
                                    <p className="text">You are about to remove *{templates.filter(t => t.id === deletedTemplateId)[0].name}* from the list of templates you can use. Do you confirm ?</p>
                                </p>
                                <div className="button-container">
                                    <div className="btn cancel" onClick={() => {
                                        setDeleteTemplateModalStatus(false)
                                    }}>
                                        <p className="text">Cancel</p>
                                        <p className="icon"><IoMdClose /></p>
                                    </div>
                                    <div className="btn valid" onClick={deleteTemplate}>
                                        <p className="text">Confirm </p>
                                        <p className="icon"><FaCheck /></p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {deleteTemplateStatus && (
                            <div className="content delete-template-mail">
                                <div className="content create-template-mail">
                                    <div className="success-container">
                                        <img src={success_send} alt="Success send icon"/>
                                        <h1 className="message">Template successfully deleted</h1>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-dashboard">
                    <h1 className="page-title">Overview</h1>
                    <div className="card-container">
                        <DashboardStatCard icon={icon_card_1} title="Mail send" value={stats.mailSend} augment={false} augmentPercent={0} />
                        <DashboardStatCard icon={icon_card_2} title="Average mail open" value={0} augment={false} augmentPercent={0} />
                        <DashboardStatCard icon={icon_card_3} title="Email not send" value={stats.mailNotSend} augment={false} augmentPercent={0} />
                        <DashboardStatCard icon={icon_card_4} title="Newsletter unsubscribe" value={0} augment={false} augmentPercent={0} />
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of users</p>
                        <div className="top-container">
                            <div className="left">
                                <ShowOrHideColumnMenu resetColumn={resetShowOrHide} columnList={userColumnList} setColumnList={setUserColumnList} open={userShowHideStatus} setOpen={setUserShowHideStatus} />
                                <div className="search-container">
                                    <div className="box">
                                        <div className="icon"><IoSearchSharp /></div>
                                        <input type="text" placeholder="Search by map name" value={userSearch} onChange={(event) => setUserSearch(event.currentTarget.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="btn" onClick={() => {
                                    setSendEmailModalStatus(true)
                                    addCheckedUserToEmailList()
                                }}>
                                    <div className="icon"><FaUserPlus /></div>
                                    <div className="text">Send mail</div>
                                </div>
                            </div>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td className="left input"><input type="checkbox" onChange={checkAllUser}/></td>
                                <td className="left large" onClick={() => {
                                    setSortedColumnNameUser("firstname")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    username
                                    <span className="sort-icon">
                                        {sortedTypeUser === "" || sortedColumnNameUser !== "firstname"
                                            ? <FaSort /> : null
                                        }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "firstname"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "firstname"
                                            ? <FaSortDown /> : null
                                        }
                                </span>
                                </td>
                                {userColumnList.plan && (
                                    <td onClick={() => {
                                        setSortedColumnNameUser("accountType")
                                        if (sortedTypeUser === ""){
                                            setSortedTypeUser("1")
                                        } else if (sortedTypeUser === "1"){
                                            setSortedTypeUser("2")
                                        } else {
                                            setSortedTypeUser("")
                                        }
                                    }}>
                                        Plan
                                        <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "accountType"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeUser === "1" && sortedColumnNameUser === "accountType"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeUser === "2" && sortedColumnNameUser === "accountType"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                )}
                                {userColumnList["active payment"] && (
                                    <td>
                                        Active payment
                                    </td>
                                )}
                                {userColumnList.status && (
                                    <td onClick={() => {
                                        setSortedColumnNameUser("status")
                                        if (sortedTypeUser === ""){
                                            setSortedTypeUser("1")
                                        } else if (sortedTypeUser === "1"){
                                            setSortedTypeUser("2")
                                        } else {
                                            setSortedTypeUser("")
                                        }
                                    }}>
                                        Status
                                        <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "status"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeUser === "1" && sortedColumnNameUser === "status"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeUser === "2" && sortedColumnNameUser === "status"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                )}
                                {userColumnList["created on"] && (
                                    <td onClick={() => {
                                        setSortedColumnNameUser("creation_date")
                                        if (sortedTypeUser === ""){
                                            setSortedTypeUser("1")
                                        } else if (sortedTypeUser === "1"){
                                            setSortedTypeUser("2")
                                        } else {
                                            setSortedTypeUser("")
                                        }
                                    }}>
                                        Created on
                                        <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "creation_date"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeUser === "1" && sortedColumnNameUser === "creation_date"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeUser === "2" && sortedColumnNameUser === "creation_date"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                )}
                                {userColumnList.newsletter && (
                                    <td onClick={() => {
                                        setSortedColumnNameUser("newsletter_status")
                                        if (sortedTypeUser === ""){
                                            setSortedTypeUser("1")
                                        } else if (sortedTypeUser === "1"){
                                            setSortedTypeUser("2")
                                        } else {
                                            setSortedTypeUser("")
                                        }
                                    }}>
                                        news.sub
                                        <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "newsletter_status"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeUser === "1" && sortedColumnNameUser === "newsletter_status"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeUser === "2" && sortedColumnNameUser === "newsletter_status"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                )}
                                {userColumnList.maps && (
                                    <td onClick={() => {
                                        setSortedColumnNameUser("mapNumber")
                                        if (sortedTypeUser === ""){
                                            setSortedTypeUser("1")
                                        } else if (sortedTypeUser === "1"){
                                            setSortedTypeUser("2")
                                        } else {
                                            setSortedTypeUser("")
                                        }
                                    }}>
                                        maps
                                        <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "mapNumber"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeUser === "1" && sortedColumnNameUser === "mapNumber"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeUser === "2" && sortedColumnNameUser === "mapNumber"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                )}
                                {userColumnList.objects && (
                                    <td onClick={() => {
                                        setSortedColumnNameUser("objectNumber")
                                        if (sortedTypeUser === ""){
                                            setSortedTypeUser("1")
                                        } else if (sortedTypeUser === "1"){
                                            setSortedTypeUser("2")
                                        } else {
                                            setSortedTypeUser("")
                                        }
                                    }}>
                                        objects
                                        <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "objectNumber"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeUser === "1" && sortedColumnNameUser === "objectNumber"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeUser === "2" && sortedColumnNameUser === "objectNumber"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                )}
                                {userColumnList.terms && (
                                    <td>terms accepted since</td>
                                )}
                                {userColumnList.expert && (
                                    <td onClick={() => {
                                        setSortedColumnNameUser("expert")
                                        if (sortedTypeUser === ""){
                                            setSortedTypeUser("1")
                                        } else if (sortedTypeUser === "1"){
                                            setSortedTypeUser("2")
                                        } else {
                                            setSortedTypeUser("")
                                        }
                                    }}>
                                        expert
                                        <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "expert"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeUser === "1" && sortedColumnNameUser === "expert"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeUser === "2" && sortedColumnNameUser === "expert"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                )}
                            </tr>
                            </thead>
                            <tbody>
                                {users[0].id !== "" && (
                                    <>
                                        {sortedUsers.map((user, index) => (
                                            <tr key={index}>
                                                <td className="left input"><input checked={checkedUser.includes(user.id)} onChange={() => !checkedUser.includes(user.id) ? setCheckedUser(checkedUser => [...checkedUser, user.id]) : setCheckedUser(checkedUser.filter(v => v !== user.id))} type="checkbox"/></td>
                                                <td className="left large">{user.firstname} {user.lastname}</td>
                                                {userColumnList.plan && (
                                                    <td>{user.accountType}</td>
                                                )}
                                                {userColumnList["active payment"] && (
                                                    <td>NONE</td>
                                                )}
                                                {userColumnList.status && (
                                                    <td>{user.status} {user.suspendDayLeft !== null ? "(" + user.suspendDayLeft + "d)" : null}</td>
                                                )}
                                                {userColumnList["created on"] && (
                                                    <td>{user.creation_date}</td>
                                                )}
                                                {userColumnList.newsletter && (
                                                    <td>{user.newsletter_status}</td>
                                                )}
                                                {userColumnList.maps && (
                                                    <td>{user.mapNumber}</td>
                                                )}
                                                {userColumnList.objects && (
                                                    <td>{user.objectNumber}</td>
                                                )}
                                                {userColumnList.terms && (
                                                    <td>NONE</td>
                                                )}
                                                {userColumnList.expert && (
                                                    <td>{user.expert}</td>
                                                )}
                                            </tr>
                                        ))}
                                    </>
                                )}
                                {users[0].id === "" && (
                                    <tr>
                                        <td colSpan="100%">No users</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPage(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPage}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtons
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPage}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedUsers.length} rows
                            </div>
                        </div>
                    </div>
                    {/*MAIL HISTORY ARRAY*/}
                    <div className="dashboard-table mt">
                        <p className="title">List of sent emails</p>
                        <div className="top-container">
                            <div className="left">
                                <div className="search-container">
                                    <div className="box">
                                        <div className="icon"><IoSearchSharp /></div>
                                        <input type="text" placeholder="Search by map name" value={historySearch} onChange={(event) => setHistorySearch(event.currentTarget.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="btn">
                                    <div className="icon"><BiHistory /></div>
                                    <div className="text">History</div>
                                </div>
                            </div>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td className="left" onClick={() => {
                                    setSortedColumnNameSendMail("template")
                                    if (sortedTypeSendMail === ""){
                                        setSortedTypeSendMail("1")
                                    } else if (sortedTypeSendMail === "1"){
                                        setSortedTypeSendMail("2")
                                    } else {
                                        setSortedTypeSendMail("")
                                    }
                                }}>
                                    template name
                                    <span className="sort-icon">
                                    {sortedTypeSendMail === "" || sortedColumnNameSendMail !== "template"
                                        ? <FaSort /> : null
                                    }
                                    {sortedTypeSendMail === "1" && sortedColumnNameSendMail === "template"
                                        ? <FaSortUp /> : null
                                    }
                                    {sortedTypeSendMail === "2" && sortedColumnNameSendMail === "template"
                                        ? <FaSortDown /> : null
                                    }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameSendMail("subject")
                                    if (sortedTypeSendMail === ""){
                                        setSortedTypeSendMail("1")
                                    } else if (sortedTypeSendMail === "1"){
                                        setSortedTypeSendMail("2")
                                    } else {
                                        setSortedTypeSendMail("")
                                    }
                                }}>
                                    mail object
                                    <span className="sort-icon">
                                    {sortedTypeSendMail === "" || sortedColumnNameSendMail !== "subject"
                                        ? <FaSort /> : null
                                    }
                                        {sortedTypeSendMail === "1" && sortedColumnNameSendMail === "subject"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeSendMail === "2" && sortedColumnNameSendMail === "subject"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameSendMail("category")
                                    if (sortedTypeSendMail === ""){
                                        setSortedTypeSendMail("1")
                                    } else if (sortedTypeSendMail === "1"){
                                        setSortedTypeSendMail("2")
                                    } else {
                                        setSortedTypeSendMail("")
                                    }
                                }}>
                                    category
                                    <span className="sort-icon">
                                    {sortedTypeSendMail === "" || sortedColumnNameSendMail !== "category"
                                        ? <FaSort /> : null
                                    }
                                        {sortedTypeSendMail === "1" && sortedColumnNameSendMail === "category"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeSendMail === "2" && sortedColumnNameSendMail === "category"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameSendMail("date")
                                    if (sortedTypeSendMail === ""){
                                        setSortedTypeSendMail("1")
                                    } else if (sortedTypeSendMail === "1"){
                                        setSortedTypeSendMail("2")
                                    } else {
                                        setSortedTypeSendMail("")
                                    }
                                }}>
                                    sent at
                                    <span className="sort-icon">
                                    {sortedTypeSendMail === "" || sortedColumnNameSendMail !== "date"
                                        ? <FaSort /> : null
                                    }
                                        {sortedTypeSendMail === "1" && sortedColumnNameSendMail === "date"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeSendMail === "2" && sortedColumnNameSendMail === "date"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameSendMail("recipient")
                                    if (sortedTypeSendMail === ""){
                                        setSortedTypeSendMail("1")
                                    } else if (sortedTypeSendMail === "1"){
                                        setSortedTypeSendMail("2")
                                    } else {
                                        setSortedTypeSendMail("")
                                    }
                                }}>
                                    recipient
                                    <span className="sort-icon">
                                    {sortedTypeSendMail === "" || sortedColumnNameSendMail !== "recipient"
                                        ? <FaSort /> : null
                                    }
                                        {sortedTypeSendMail === "1" && sortedColumnNameSendMail === "recipient"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeSendMail === "2" && sortedColumnNameSendMail === "recipient"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameSendMail("status")
                                    if (sortedTypeSendMail === ""){
                                        setSortedTypeSendMail("1")
                                    } else if (sortedTypeSendMail === "1"){
                                        setSortedTypeSendMail("2")
                                    } else {
                                        setSortedTypeSendMail("")
                                    }
                                }}>
                                    status
                                    <span className="sort-icon">
                                    {sortedTypeSendMail === "" || sortedColumnNameSendMail !== "status"
                                        ? <FaSort /> : null
                                    }
                                        {sortedTypeSendMail === "1" && sortedColumnNameSendMail === "status"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeSendMail === "2" && sortedColumnNameSendMail === "status"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                                {mailHistory[0].id !== "" && (
                                    <>
                                        {sortedSendMail.map((history, index) => (
                                            <tr key={index}>
                                                <td className="left">{history.template}</td>
                                                <td>{history.subject}</td>
                                                <td>{history.category}</td>
                                                <td>{history.date}</td>
                                                <td>{history.recipient}</td>
                                                <td>{history.status ? "Send" : "Not send"}</td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                                {mailHistory[0].id === "" && (
                                    <tr>
                                        <td colSpan="100%">No sent mails</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setHistoryItemPerPage(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={historyPreviousPage}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtons
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={historyNextPage}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedHistory.length} rows
                            </div>
                        </div>
                    </div>
                    {/*TEMPLATE ARRAY*/}
                    <div className="dashboard-table mt">
                        <p className="title">List of emails template</p>
                        <div className="top-container">
                            <div className="left">
                                <div className="search-container">
                                    <div className="box">
                                        <div className="icon"><IoSearchSharp /></div>
                                        <input type="text" placeholder="Search by map name" value={templateSearch} onChange={(event) => setTemplateSearch(event.currentTarget.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="btn" onClick={() => {
                                    setCreateTemplateModalStatus(true)
                                }}>
                                    <div className="icon"><IoMdAdd /></div>
                                    <div className="text">Create new template</div>
                                </div>
                                <div className="btn">
                                    <div className="icon"><BiHistory /></div>
                                    <div className="text">History</div>
                                </div>
                            </div>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td className="left large" onClick={() => {
                                    setSortedColumnNameTemplate("name")
                                    if (sortedTypeTemplate === ""){
                                        setSortedTypeTemplate("1")
                                    } else if (sortedTypeTemplate === "1"){
                                        setSortedTypeTemplate("2")
                                    } else {
                                        setSortedTypeTemplate("")
                                    }
                                }}>
                                    template name
                                    <span className="sort-icon">
                                    {sortedTypeTemplate === "" || sortedColumnNameTemplate !== "name"
                                        ? <FaSort /> : null
                                    }
                                        {sortedTypeTemplate === "1" && sortedColumnNameTemplate === "name"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeTemplate === "2" && sortedColumnNameTemplate === "name"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameTemplate("createdAt")
                                    if (sortedTypeTemplate === ""){
                                        setSortedTypeTemplate("1")
                                    } else if (sortedTypeTemplate === "1"){
                                        setSortedTypeTemplate("2")
                                    } else {
                                        setSortedTypeTemplate("")
                                    }
                                }}>
                                    creation date
                                    <span className="sort-icon">
                                        {sortedTypeTemplate === "" || sortedColumnNameTemplate !== "createdAt"
                                            ? <FaSort /> : null
                                        }
                                        {sortedTypeTemplate === "1" && sortedColumnNameTemplate === "createdAt"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeTemplate === "2" && sortedColumnNameTemplate === "createdAt"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameTemplate("lastUsage")
                                    if (sortedTypeTemplate === ""){
                                        setSortedTypeTemplate("1")
                                    } else if (sortedTypeTemplate === "1"){
                                        setSortedTypeTemplate("2")
                                    } else {
                                        setSortedTypeTemplate("")
                                    }
                                }}>
                                    last used
                                    <span className="sort-icon">
                                        {sortedTypeTemplate === "" || sortedColumnNameTemplate !== "lastUsage"
                                            ? <FaSort /> : null
                                        }
                                        {sortedTypeTemplate === "1" && sortedColumnNameTemplate === "lastUsage"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeTemplate === "2" && sortedColumnNameTemplate === "lastUsage"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameTemplate("category")
                                    if (sortedTypeTemplate === ""){
                                        setSortedTypeTemplate("1")
                                    } else if (sortedTypeTemplate === "1"){
                                        setSortedTypeTemplate("2")
                                    } else {
                                        setSortedTypeTemplate("")
                                    }
                                }}>
                                    category
                                    <span className="sort-icon">
                                        {sortedTypeTemplate === "" || sortedColumnNameTemplate !== "category"
                                            ? <FaSort /> : null
                                        }
                                        {sortedTypeTemplate === "1" && sortedColumnNameTemplate === "category"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeTemplate === "2" && sortedColumnNameTemplate === "category"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td onClick={() => {
                                    setSortedColumnNameTemplate("sendTimes")
                                    if (sortedTypeTemplate === ""){
                                        setSortedTypeTemplate("1")
                                    } else if (sortedTypeTemplate === "1"){
                                        setSortedTypeTemplate("2")
                                    } else {
                                        setSortedTypeTemplate("")
                                    }
                                }}>
                                    nb of uses
                                    <span className="sort-icon">
                                        {sortedTypeTemplate === "" || sortedColumnNameTemplate !== "sendTimes"
                                            ? <FaSort /> : null
                                        }
                                        {sortedTypeTemplate === "1" && sortedColumnNameTemplate === "sendTimes"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeTemplate === "2" && sortedColumnNameTemplate === "sendTimes"
                                            ? <FaSortDown /> : null
                                        }
                                    </span>
                                </td>
                                <td> </td>
                            </tr>
                            </thead>
                            <tbody>
                                {templates[0].id !== "" && (
                                    <>
                                        {sortedTemplate.map((template, index) => (
                                            <tr key={index}>
                                                <td className="left large">{template.name}</td>
                                                <td>{template.createdAt}</td>
                                                <td>{template.lastUsage}</td>
                                                <td>{template.category}</td>
                                                <td>{template.sendTimes}</td>
                                                <td className={openedArraySubMenuTemplateId === template.id ? "sub-menu open" : "sub-menu"}>
                                                    <p onClick={() => openedArraySubMenuTemplateId === 0 ? setOpenedArraySubMenuTemplateId(template.id) : openedArraySubMenuTemplateId !== template.id ? setOpenedArraySubMenuTemplateId(template.id) : setOpenedArraySubMenuTemplateId(0)} className="btn"><BsThreeDots /></p>
                                                    <div className="menu" >
                                                        <p id={template.id} onClick={copyTemplate}>Create a copy</p>
                                                        <p onClick={() => {
                                                            setUpdatedTemplateId(template.id)
                                                        }}>Preview & Modify</p>
                                                        <p onClick={() => {
                                                            setDeletedTemplateId(template.id)
                                                            setDeleteTemplateStatus(false)
                                                            setDeleteTemplateModalStatus(true)
                                                        }}>Delete template</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                                {templates[0].id === "" && (
                                    <tr>
                                        <td colSpan="100%">No mail template</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setTemplateItemPerPage(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={templatePreviousPage}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtons
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={templateNextPage}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedTemplate.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="top-btn" onClick={() => dashboard.scrollTo(0, 0)}>
                            <p><FaChevronUp /></p>
                        </div>
                        <p className="copy">&copy; Weeki 2021</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardEmail