import {generateRandomId} from "./Utils";
import {useToastStore} from "../contexts/CustomToastStore";

export function createInfoToast (message, delay = 2){
    const toastId = generateRandomId()

    useToastStore.getState().addToast(toastId, "info", message, false, null)

    setTimeout(() => {
        useToastStore.getState().removeToast(toastId)
    }, delay * 1000)
}

export function createSuccessToast (message, delay = 2){
    const toastId = generateRandomId()

    useToastStore.getState().addToast(toastId, "success", message, false, null)

    setTimeout(() => {
        useToastStore.getState().removeToast(toastId)
    }, delay * 1000)
}

export function createCancellableSuccessToast (message, callback, delay = 25){
    const toastId = generateRandomId()

    useToastStore.getState().addToast(toastId, "success", message, true, callback)

    setTimeout(() => {
        useToastStore.getState().removeToast(toastId)
    }, delay * 1000)
}

export function createErrorToast (message, delay = 3){
    const toastId = generateRandomId()

    useToastStore.getState().addToast(toastId, "error", message, false, null)

    setTimeout(() => {
        useToastStore.getState().removeToast(toastId)
    }, delay * 1000)
}

export function createCancelInProgressToast (){
    const toastId = generateRandomId()
    useToastStore.getState().addToast(toastId, "loading", "Cancel in progress", false, null)

    return toastId
}

export function createLoadingToast (message){
    const toastId = generateRandomId()
    useToastStore.getState().addToast(toastId, "loading", message, false, null)

    return toastId
}

export function removeToast(toastId){
    useToastStore.getState().removeToast(toastId)
}