import getAxiosInstance from "../../AxiosInstance";
import {MAP_API} from "../../../../Router";
import {useMapGlobalSelectionStore} from "../../Stores/MapGlobalSelectionStore";

function userGetProjectMap(projectUuid){
	return getAxiosInstance().get(MAP_API + '/get_project_maps', {params: {projectUuid: projectUuid}})
}

function userGetAllMapInvitations(mapUuid){
	return getAxiosInstance().get(MAP_API + '/get_all_invitations', {params: {mapUuid: mapUuid}})
}

function userDeleteMap(uuid){
	return getAxiosInstance().delete(MAP_API + '/delete', {params: {uuid: uuid}})
		.then((response) => {
			return response.status === 200
		})
}

function userInviteUser(mapUuid, email){
	const sendData = new FormData()
	sendData.append('mapUuid', mapUuid)
	sendData.append('userEmail', email)

	return getAxiosInstance().post(MAP_API + '/invite_user', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.uuid
			}

			return false
		})
		.catch(() => false)
}

function userRevokeUser(mapUuid, invitationUuid){
	const sendData = new FormData()
	sendData.append('mapUuid', mapUuid)
	sendData.append('invitationUuid', invitationUuid)

	return getAxiosInstance().post(MAP_API + '/revoke_user', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userUpdateMapCenter(mapUuid, latLng, zoom){
	const sendData = new FormData()
	sendData.append('mapUuid', mapUuid)
	sendData.append('lat', latLng.lat)
	sendData.append('lng', latLng.lng)
	sendData.append('zoom', zoom)

	return getAxiosInstance().post(MAP_API + '/update_center', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userUpdateMapTableColumn(mapUuid, tableColumn){
	const sendData = new FormData()
	sendData.append('mapUuid', mapUuid)
	sendData.append('tableColumn', JSON.stringify(tableColumn))

	return getAxiosInstance().post(MAP_API + '/update_table_column', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userMoveMapSelection(mapUuid, newX, newY, oldX, oldY, copy){
	const sendData = new FormData()
	sendData.append('mapUuid', mapUuid)
	sendData.append('oldX', oldX)
	sendData.append('oldY', oldY)
	sendData.append('newX', newX)
	sendData.append('newY', newY)
	sendData.append('copy', copy)
	sendData.append('items', JSON.stringify(useMapGlobalSelectionStore.getState().selectedItems.map(i => i.uuid)))
	sendData.append('shapes', JSON.stringify(useMapGlobalSelectionStore.getState().selectedShapes.map(i => i.uuid)))
	sendData.append('areas', JSON.stringify([...new Set(useMapGlobalSelectionStore.getState().selectedAreas.map(i => i.uuid))]))

	return getAxiosInstance().post(MAP_API + '/move_selection', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userDeleteMapSelection(mapUuid){
	const sendData = new FormData()
	sendData.append('mapUuid', mapUuid)
	sendData.append('items', JSON.stringify(useMapGlobalSelectionStore.getState().selectedItems.map(i => i.uuid)))
	sendData.append('shapes', JSON.stringify(useMapGlobalSelectionStore.getState().selectedShapes.map(i => i.uuid)))
	sendData.append('areas', JSON.stringify([...new Set(useMapGlobalSelectionStore.getState().selectedAreas.map(i => i.uuid))]))

	return getAxiosInstance().post(MAP_API + '/delete_selection', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	userGetProjectMap,
	userGetAllMapInvitations,
	userDeleteMap,
	userInviteUser,
	userRevokeUser,
	userUpdateMapCenter,
	userUpdateMapTableColumn,
	userMoveMapSelection,
	userDeleteMapSelection
}