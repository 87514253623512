import {ADMIN_USER_API, USER_CONTROLLER} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function adminGetUsers(){
    return getAxiosInstance().get(ADMIN_USER_API + "/all_users")
}

function getUserProfile(){
    return getAxiosInstance().get(USER_CONTROLLER + "/get_user")
}

function getUserWithMap(){
    return getAxiosInstance().get(ADMIN_USER_API + "/get_user_with_map")
}

function updateUserPassword2(newPassword, newPasswordConfirm){
    const sendData = new FormData()
    sendData.append('newPassword', newPassword)
    sendData.append('newPasswordConfirm', newPasswordConfirm)

    return getAxiosInstance().post(USER_CONTROLLER + '/update_password', sendData)
        .then((response) => {
            return response.status === 200;
        })
        .catch(() => {
            return false
        })
}

function userSendConfirmationEmail(){
    return getAxiosInstance().post(USER_CONTROLLER + '/send_confirmation_email')
        .then((response) => {
            return response.status === 200;
        })
        .catch(() => {
            return false
        })
}

function updateUserPicture(picture = null){
    const sendData = new FormData()

    if (picture !== null){
        sendData.append('picture', picture)
    }

    return getAxiosInstance().post(USER_CONTROLLER + '/update_picture', sendData)
        .then((response) => {
            return response.status === 200;
        })
        .catch(() => {
            return false
        })
}

function suspendUser(userId, duration){
    const sendData = new FormData()
    sendData.append("id", userId)
    sendData.append("duration", duration)
    return getAxiosInstance().post(ADMIN_USER_API + "/suspend", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function unSuspendUser(userId){
    const sendData = new FormData()
    sendData.append("id", userId)
    return getAxiosInstance().post(ADMIN_USER_API + "/unsuspend", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function deleteAccounts(deletedAccountId){
    const sendData = new FormData()
    sendData.append("_method", "DELETE")
    sendData.append("userList", deletedAccountId)

    return getAxiosInstance().post(ADMIN_USER_API + "/delete", sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function updateUserPassword(oldPassword, newPassword){
    const sendData = new FormData()
    sendData.append("_method", "PUT")
    sendData.append("oldPassword", oldPassword)
    sendData.append("newPassword", newPassword)

    toast.loading("Password update in progress", {toastId: "loadAlert"})
    return getAxiosInstance().post(USER_CONTROLLER + "/update_password", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response.status === 200){
                //Update token
                window.localStorage.setItem("authToken", response.data.token)
                return true
            } else {
                return false
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
        })
}

function updateUserEmail(email){
    const sendData = new FormData()
    sendData.append("_method", "PUT")
    sendData.append("email", email)

    toast.loading("Email update in progress", {toastId: "loadAlert"})
    return getAxiosInstance().post(USER_CONTROLLER + "/update_email", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response.status === 200){
                //Update token
                window.localStorage.setItem("authToken", response.data.token)
                return true
            } else {
                return false
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
        })
}

function updateUserProfile(data){
    const sendData = new FormData()

    Object.keys(data).forEach(key => {
        sendData.append(key, data[key])
    })

    toast.loading("Profile update in progress", {toastId: "loadAlert"})
    return getAxiosInstance().post(USER_CONTROLLER + "/update_profile", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
        })
}

function deleteAccount(){
    toast.loading("Delete account in progress", {toastId: "loadAlert"})

    return getAxiosInstance().delete(USER_CONTROLLER + "/delete")
        .then((response) => {
            toast.dismiss("loadAlert")
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
        })
}

export default {
    adminGetUsers,
    getUserProfile,
    getUserWithMap,
    suspendUser,
    unSuspendUser,
    deleteAccounts,
    updateUserPassword,
    updateUserEmail,
    updateUserProfile,
    deleteAccount,
    updateUserPassword2,
    updateUserPicture,
    userSendConfirmationEmail
}