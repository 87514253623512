import React, {useEffect, useState} from "react";
import HistoryAPI from "../../services/HistoryAPI";
import {TailSpin} from "react-loader-spinner";
import {CgDetailsMore} from "react-icons/cg";
import {FaChevronLeft, FaChevronRight, FaFilter} from "react-icons/fa";
import {IoMdAdd} from "react-icons/io";
import {IoReload, IoSearchSharp} from "react-icons/io5";
import {VscChromeClose} from "react-icons/vsc";
import Export from "../Export";
import {toast} from "react-toastify";

const UsersHistoryTable = ({setModalOpened}) => {

    const [getHistoryRequestStatus, setGetHistoryRequestStatus] = useState(false)

    const [historyStartOffset, setHistoryStartOffset] = useState(0)

    const [history, setHistory] = useState([{
        "id": "",
        "time": "",
        "ipAddress": "",
        "requestUserType": "",
        "requestUserId": "",
        "action": "",
        "userId": "",
        "subscriptionId": "",
        "email": "",
        "role": "",
        "firstname": "",
        "lastname": "",
        "creationDate": "",
        "newsletterStatus": false,
        "termsStatus": false,
        "active": false,
        "address": "",
        "postalCode": "",
        "city": "",
        "region": "",
        "country": "",
        "phone": "",
        "website": "",
        "profession": "",
        "activityDomain": "",
        "company": "",
        "laboratory": "",
        "linkedin": "",
        "facebook": "",
        "twitter": "",
        "suspend": false,
        "suspendEndDate": "",
        "expert": false,
    }])

    useEffect(() => {
        HistoryAPI.getHistoryInfosByType("users", historyStartOffset).then((response) => {
            setHistory(response.data)
            setGetHistoryRequestStatus(true)
        })
    }, [])

    //Define default state for search input
    const [search, setSearch] = useState("")
    const filteredHistory = history.filter(mh =>
        String(mh.time).toLowerCase().includes(search.toLowerCase()) ||
        String(mh.ipAddress).toLowerCase().includes(search.toLowerCase()) ||
        String(mh.requestUserType).toLowerCase().includes(search.toLowerCase()) ||
        String(mh.requestUserId).toLowerCase().includes(search.toLowerCase()) ||
        String(mh.action).toLowerCase().includes(search.toLowerCase()) ||
        String(mh.userId).toLowerCase().includes(search.toLowerCase())
    )


    /*****************************PAGINATION**********************************/
    const [currentPage, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const maxPage = Math.ceil(filteredHistory.length / itemPerPage)

    const nextPage = () => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }
    const previousPage = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }

    const start = (currentPage - 1) * itemPerPage
    const end = start + itemPerPage
    const paginatedHistory = filteredHistory.slice(start, end)

    const pageButtons = []

    for (let i = 0; i < maxPage; i++){
        pageButtons.push(<p onClick={() => setCurrentPage(i + 1)} className={currentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }

    /*************************************************************************/
    const [detailsModalStatus, setDetailsModalStatus] = useState(false)
    const [selectedDetailsHistory, setSelectedDetailsHistory] = useState({})

    /****************************FILTER MODAL*********************************/
    const [filterModalStatus, setFilterModalStatus] = useState(false)

    const [filterItem, setFilterItem] = useState([])
    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")

    const addFilterItem = () => {
        setFilterItem(filterItem => [...filterItem, {
            "id": filterItem.length === 0 ? 1 : filterItem[filterItem.length - 1].id + 1,
            "type": "ipAddress",
            "inputValue": ""
        }])
    }

    const updateFilterType = (event) => {
        if (filterItem.some(f => f.type === event.currentTarget.value)){
            toast.error("This filter is already in use")
            return;
        }

        let newArray = [...filterItem]
        const itemId = event.currentTarget.id

        if (event.currentTarget.value === "newsletterStatus" || event.currentTarget.value === "termsStatus" || event.currentTarget.value === "active" || event.currentTarget.value === "suspend" || event.currentTarget.value === "expert"){
            newArray[itemId - 1].inputValue = "true"
        }

        newArray[itemId - 1].type = event.currentTarget.value

        setFilterItem(newArray)
    }

    const updateInputValue = (event) => {
        let newArray = [...filterItem]
        const itemId = event.currentTarget.id

        newArray[itemId - 1].inputValue = event.currentTarget.value

        setFilterItem(newArray)
    }

    const deleteFilterRow = async (event) => {
        const rowId = event.currentTarget.id

        let newArray = filterItem.filter(i => i.id !== parseInt(rowId))

        let updatedIdArray = []

        for (let i = 0; i < newArray.length; i++){
            const object = {
                "id": (i + 1),
                "type": newArray[i].type,
                "inputValue": newArray[i].inputValue
            }

            updatedIdArray.push(object)
        }

        setFilterItem(updatedIdArray)

        HistoryAPI.getHistoryInfosByType("users", historyStartOffset, updatedIdArray, filterStartDate, filterEndDate).then((response) => {
            setHistory(response.data)
            setGetHistoryRequestStatus(true)
            setCurrentPage(1)
        })
    }

    const clearFilter = () => {
        setFilterItem([])
        setFilterStartDate("")
        setFilterEndDate("")

        setGetHistoryRequestStatus(false)
        HistoryAPI.getHistoryInfosByType("users", historyStartOffset).then((response) => {
            setHistory(response.data)
            setGetHistoryRequestStatus(true)
            setCurrentPage(1)
        })
    }

    const searchByFilter = () => {
        if ((filterStartDate !== "" && filterEndDate === "") || (filterStartDate === "" && filterEndDate !== "")){
            toast.error("Please select two date for filter")
            return
        }

        setGetHistoryRequestStatus(false)
        HistoryAPI.getHistoryInfosByType("users", historyStartOffset, filterItem, filterStartDate, filterEndDate).then((response) => {
            setHistory(response.data)
            setGetHistoryRequestStatus(true)
            setFilterModalStatus(false)
            setModalOpened(false)
            setCurrentPage(1)
        })
    }

    const deleteDateFilter = () => {
        setFilterStartDate("")
        setFilterEndDate("")

        setGetHistoryRequestStatus(false)
        HistoryAPI.getHistoryInfosByType("users", historyStartOffset, filterItem, "", "").then((response) => {
            setHistory(response.data)
            setGetHistoryRequestStatus(true)
            setCurrentPage(1)
        })
    }
    /*************************************************************************/

    return (
        <>
            {/*Details modal*/}
            {detailsModalStatus && (
                <div className="modal-overlay">
                    <div className="modal open">
                        <div className="header">
                            <h1>Details</h1>
                            <p onClick={() => {
                                setDetailsModalStatus(false)
                                setModalOpened(false)
                                setSelectedDetailsHistory({})
                            }}><VscChromeClose /></p>
                        </div>
                        <div className="content">
                            <div className="text-infos-container">
                                <div className="line">
                                    <p className="infos-title">History UUID:</p>
                                    <p className="infos-value">{selectedDetailsHistory.id}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Time:</p>
                                    <p className="infos-value">{selectedDetailsHistory.time}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Ip address:</p>
                                    <p className="infos-value">{selectedDetailsHistory.ipAddress}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Request user type:</p>
                                    <p className="infos-value">{selectedDetailsHistory.requestUserType}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Request user UUID:</p>
                                    <p className="infos-value">{selectedDetailsHistory.requestUserId}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Action:</p>
                                    <p className="infos-value">{selectedDetailsHistory.action}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">User UUID:</p>
                                    <p className="infos-value">{selectedDetailsHistory.userId}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Subscription UUID:</p>
                                    <p className="infos-value">{selectedDetailsHistory.subscriptionId}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Email:</p>
                                    <p className="infos-value">{selectedDetailsHistory.email}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Role:</p>
                                    <p className="infos-value">{selectedDetailsHistory.role}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Firstname:</p>
                                    <p className="infos-value">{selectedDetailsHistory.firstname}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Lastname:</p>
                                    <p className="infos-value">{selectedDetailsHistory.lastname}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Creation date:</p>
                                    <p className="infos-value">{selectedDetailsHistory.creationDate}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Newsletter status:</p>
                                    <p className="infos-value">{selectedDetailsHistory.newsletterStatus ? "Yes" : "No"}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Terms status:</p>
                                    <p className="infos-value">{selectedDetailsHistory.termsStatus ? "Yes" : "No"}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Active:</p>
                                    <p className="infos-value">{selectedDetailsHistory.active ? "Yes" : "No"}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Address:</p>
                                    <p className="infos-value">{selectedDetailsHistory.address}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Postal code:</p>
                                    <p className="infos-value">{selectedDetailsHistory.postalCode}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">City:</p>
                                    <p className="infos-value">{selectedDetailsHistory.city}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Country:</p>
                                    <p className="infos-value">{selectedDetailsHistory.country}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Phone:</p>
                                    <p className="infos-value">{selectedDetailsHistory.phone}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Website:</p>
                                    <p className="infos-value">{selectedDetailsHistory.website}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Profession:</p>
                                    <p className="infos-value">{selectedDetailsHistory.profession}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Activity domain:</p>
                                    <p className="infos-value">{selectedDetailsHistory.activityDomain}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Company:</p>
                                    <p className="infos-value">{selectedDetailsHistory.company}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Laboratory:</p>
                                    <p className="infos-value">{selectedDetailsHistory.laboratory}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Linkedin:</p>
                                    <p className="infos-value">{selectedDetailsHistory.linkedin}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Facebook:</p>
                                    <p className="infos-value">{selectedDetailsHistory.facebook}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Twitter:</p>
                                    <p className="infos-value">{selectedDetailsHistory.twitter}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Expert:</p>
                                    <p className="infos-value">{selectedDetailsHistory.expert ? "Yes" : "No"}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Suspend:</p>
                                    <p className="infos-value">{selectedDetailsHistory.suspend ? "Yes" : "No"}</p>
                                </div>
                                <div className="line">
                                    <p className="infos-title">Suspend end date:</p>
                                    <p className="infos-value">{selectedDetailsHistory.suspendEndDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*Filter modal*/}
            {filterModalStatus && (
                <div className="modal-overlay">
                    <div className="modal open">
                        <div className="header">
                            <h1>Advanced search</h1>
                            <p onClick={() => {
                                setFilterModalStatus(false)
                                setModalOpened(false)
                            }}><VscChromeClose /></p>
                        </div>
                        <div className="content">
                            <p className="title">Date filter</p>
                            <div className="date-filter-container">
                                <input value={filterStartDate} onChange={(event) => setFilterStartDate(event.currentTarget.value)} type="date"/>
                                <input value={filterEndDate} onChange={(event) => setFilterEndDate(event.currentTarget.value)} type="date"/>
                            </div>
                            <p className="title mt">Column filter</p>
                            <div className="column-filter-container">
                                {filterItem.map((item, index) => (
                                    <div className="filter-item" key={index} id={item.id}>
                                        <select id={item.id} value={item.type} onChange={updateFilterType}>
                                            <option value="ipAddress">Ip Address</option>
                                            <option value="requestUserType">Request user type</option>
                                            <option value="requestUserId">Request user UUID</option>
                                            <option value="action">Action</option>
                                            <option value="userId">User UUID</option>
                                            <option value="subscriptionId">Subscription UUID</option>
                                            <option value="email">Email</option>
                                            <option value="role">Role</option>
                                            <option value="firstname">Firstname</option>
                                            <option value="lastname">Lastname</option>
                                            <option value="newsletterStatus">Newsletter status</option>
                                            <option value="termsStatus">Terms status</option>
                                            <option value="active">Active</option>
                                            <option value="address">Address</option>
                                            <option value="postalCode">Postal code</option>
                                            <option value="city">City</option>
                                            <option value="region">Region</option>
                                            <option value="country">Country</option>
                                            <option value="phone">Phone</option>
                                            <option value="website">Website</option>
                                            <option value="profession">Profession</option>
                                            <option value="activityDomain">Activity domain</option>
                                            <option value="company">Company</option>
                                            <option value="laboratory">Laboratory</option>
                                            <option value="linkedin">Linkedin</option>
                                            <option value="facebook">Facebook</option>
                                            <option value="twitter">Twitter</option>
                                            <option value="suspend">Suspend</option>
                                            <option value="expert">Expert</option>
                                        </select>
                                        {item.type !== "newsletterStatus" && item.type !== "termsStatus" && item.type !== "active" && item.type !== "suspend" && item.type !== "expert"
                                            ? <input id={item.id} type="text" value={item.inputValue} onChange={updateInputValue}/>
                                            :
                                            <select id={item.id} style={{'width': "100%"}} value={item.inputValue} onChange={updateInputValue}>
                                                <option selected value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        }

                                        <p onClick={deleteFilterRow} id={item.id} className="delete-item-btn"><VscChromeClose /></p>
                                    </div>
                                ))}
                            </div>
                            <p className="add-column-filter-item-btn" onClick={addFilterItem}><IoMdAdd /></p>
                        </div>
                        <div className="footer">
                            <div onClick={clearFilter} className="btn cancel">Clear Filter</div>
                            <div className="btn valid" onClick={searchByFilter}>Search</div>
                        </div>
                    </div>
                </div>
            )}
            <div className="dashboard-table mt">
                <p className="title">Users Connection History</p>
                <div className="top-container">
                    <div className="left">
                        <div className="filter-btn" onClick={() => {
                            setModalOpened(true)
                            setFilterModalStatus(true)
                        }}><FaFilter /></div>
                        <div className="filter-btn" onClick={searchByFilter}><IoReload /></div>
                        <div className="search-container">
                            <div className="box">
                                <div className="icon"><IoSearchSharp /></div>
                                <input type="text" placeholder="Search" value={search} onChange={(event) => {
                                    setCurrentPage(1)
                                    setSearch(event.currentTarget.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <Export data={history} filename="weeki_map_history" />
                    </div>
                </div>
                <div className="table-filter-container">
                    {filterItem.map((filter, index) => (
                        <>
                            {filter.inputValue !== "" && (
                                <div className="filter">
                                    <p className="icon" id={filter.id} onClick={deleteFilterRow}><VscChromeClose /></p>
                                    <p className="text">{filter.type}: {filter.inputValue}</p>
                                </div>
                            )}
                        </>
                    ))}
                    {filterStartDate !== "" && filterEndDate !== "" && (
                        <div className="filter">
                            <p className="icon" onClick={deleteDateFilter}><VscChromeClose /></p>
                            <p className="text">Date filter: {filterStartDate} - {filterEndDate}</p>
                        </div>
                    )}
                </div>
                <table>
                    <thead>
                        <tr>
                            <td>User uuid</td>
                            <td>Time</td>
                            <td>Ip address</td>
                            <td>Request User type</td>
                            <td>Request User uuid</td>
                            <td>Action</td>
                            <td> </td>
                        </tr>
                    </thead>
                    <tbody>
                        {getHistoryRequestStatus && (
                            <>
                                {paginatedHistory.length > 0 && (
                                    <>
                                        {paginatedHistory.map((historyItem, index) => (
                                            <>
                                                <tr key={index} id={historyItem.id}>
                                                    <td>{historyItem.userId}</td>
                                                    <td>{historyItem.time}</td>
                                                    <td>{historyItem.ipAddress}</td>
                                                    <td>{historyItem.requestUserType}</td>
                                                    <td>{historyItem.requestUserId}</td>
                                                    <td>{historyItem.action}</td>
                                                    <td className="details-btn">
                                                        <span onClick={() => {
                                                            setSelectedDetailsHistory(history.filter(h => h.id === historyItem.id)[0])
                                                            setModalOpened(true)
                                                            setDetailsModalStatus(true)
                                                        }}><CgDetailsMore /></span>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </>
                                )}
                                {paginatedHistory.length === 0 && (
                                    <tr><td colSpan="100%" style={{"textAlign": "center"}}>No history</td></tr>
                                )}
                            </>
                        )}
                        {!paginatedHistory && (
                            <tr>
                                <td colSpan="100%" style={{"textAlign": "center"}}><TailSpin height="64" width="64" color="#000" /></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="pagination-container">
                    <div className="row-selector">
                        <div className="text">Rows per page :</div>
                        <select name="rows" onChange={(event) => {
                            setCurrentPage(1)
                            setItemPerPage(parseInt(event.currentTarget.value))
                        }}>
                            <option value={10}>10</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                        </select>
                    </div>
                    <div className="page-selector">
                        <div className="arrow-btn" onClick={previousPage}><FaChevronLeft /></div>
                        <div className="btn-container">
                            {pageButtons.length > 0 && (
                                pageButtons
                            )}
                            {pageButtons.length === 0 && (
                                <p className="selected">0</p>
                            )}
                        </div>
                        <div className="arrow-btn" onClick={nextPage}><FaChevronRight /></div>
                    </div>
                    <div className="page-infos">
                        Showing {start + 1} to {end} of {history.length} rows
                    </div>
                </div>
            </div>
        </>
    )
}

export default UsersHistoryTable