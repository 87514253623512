import {create} from "zustand";

export const useContentItemStore = create((set) => ({
	renderedLevel2Items: [],
	currentSelectedItem: null,
	selectedItemPopup: null,
	currentFullscreenItemUuid: null,
	currentHoveredLevel1: null,
	hoveredLevel1Timeout: null,
	hoveredLevel1Mock: null,
	hoveredLevel1DragState: false,
	updateLatexRender: 0,
	blockRefresh: false,
	currentCopiedItem: null,
	copyType: 'copy',
	copyOriginZoom: null,
	addRenderedLevel2: (data) => set((state) => ({
		renderedLevel2Items: [data, ...state.renderedLevel2Items],
	})),
	removeRenderedLevel2: (uuid) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.filter(r => r.uuid !== uuid),
	})),
	updateRenderedLevel2ThumbnailUrl: (uuid, url) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, thumbnailUrl: url})
				: o
		),
	})),
	updateRenderedLevel2SelectState: (uuid, newState) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((i) =>
			i.uuid === uuid
				? ({...i, selected: newState})
				: i
		),
	})),
	updateRenderedLevel2AllZoomLevel: (uuid, zlevel2, smallZlevel2, zlevel1, iconZlevel, minZlevel) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, properties: {...o.properties, zlevel2: zlevel2, small_zlevel2: smallZlevel2, zlevel1: zlevel1, icon_zlevel: iconZlevel, min_zlevel: minZlevel}})
				: o
		),
	})),
	updateRenderedLevel2SmallValue: (uuid, small) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, small: small})
				: o
		),
	})),
	updateRenderedLevel2LatLng: (uuid, lat, lng) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, properties: {...o.properties, lat: lat, long: lng}})
				: o
		),
	})),
	updateRenderedLevel2SelectedState: (uuid, value) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, selected: value})
				: o
		),
	})),
	disableLevel2Render: (uuid) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, canRender: false})
				: o
		),
	})),
	updateRenderedLevel2Name: (uuid, name) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, properties: {...o.properties, name: name}})
				: o
		),
	})),
	updateRenderedLevel2Properties: (uuid, properties) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, properties: properties})
				: o
		),
	})),
	updateRenderedLevel2ZoomLevel: (uuid, zlevel2) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, zlevel2: zlevel2})
				: o
		),
	})),
	updateRenderedLevel2Attributes: (uuid, attributes) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, properties: {...o.properties, attributes: JSON.stringify(attributes)}})
				: o
		),
	})),
	updateRenderedLevel2Color: (uuid, color) => set((state) => ({
		renderedLevel2Items: state.renderedLevel2Items.map((o) =>
			o.uuid === uuid
				? ({...o, properties: {...o.properties, color: color}})
				: o
		),
	})),
	resetStore: () => set((state) => ({
		renderedLevel2Items: [],
		currentSelectedItem: null,
		selectedItemPopup: null,
		currentFullscreenItemUuid: null,
		currentHoveredLevel1: null,
		hoveredLevel1Timeout: null,
		hoveredLevel1Mock: null,
		hoveredLevel1DragState: false,
		updateLatexRender: 0,
		blockRefresh: false,
		currentCopiedItem: null,
		copyType: 'copy',
		copyOriginZoom: null
	}))
}))