import getAxiosInstance from "../../AxiosInstance";
import {USER_PROMOCODE_API} from "../../../../Router";

function userGetPromoCodeById(code){
    return getAxiosInstance().get(USER_PROMOCODE_API + '/get_by_id', {
        params: {code: code}
    })
}

export default {
    userGetPromoCodeById
}