import border_style_icon from "../../../../icons/zone-menu/border-style.svg"
import {globalAvailableColor} from "../../../../App";


const ZoneSelectedMenu = ({properties, rename = false}) => {

	return (
		<>
			<div className="custom__map-selected-popup-container">
				{!rename && (
					<>
						<div className="selected-menu-button">
							<div className="button-icon">
								<img src={border_style_icon} alt="Border style"/>
							</div>
						</div>
						<div className="selected-menu-button" id="border-color-button">
							<div className="button-fill-icon" style={{backgroundColor: "rgb(" + properties.border_color + ")"}}></div>
						</div>
						<div className="selected-menu-button" id="fill-color-button">
							<div className="button-fill-icon" style={{backgroundColor: "rgb(" + properties.fill_color + ")"}}></div>
						</div>
						<div id="color-selector-container" className="selected-sub-menu-container">
							<div className="color-list-container">
								<div id="transparent-selector-btn" className="color transparent"
									 style={{backgroundColor: "#FFFFFF"}}></div>
								{globalAvailableColor.map((color, index) => (
									<div id="color-selector-btn" className="color"
										 style={{backgroundColor: color}}></div>
								))}
							</div>
						</div>
					</>
				)}
				{rename && (
					<input type="text" className="selected-menu-input" id="zone-rename-input" defaultValue={properties.name}/>
				)}
			</div>

		</>
		// <div className="selected-object-menu-container">
		// 	{!text && (
		// 		<>
		// 			{attributes && (
		// 				<>
		// 					{attributes.filter(a => a.type === 'url')[0] !== undefined && (
		// 						<>
		// 							<div className="selected-object-menu-btn" id="selected-object-popup-url-btn">
		// 								<img src={fullscreen_icon} alt="Open link"/>
		// 							</div>
		// 						</>
		// 					)}
		// 				</>
		// 			)}
		// 			{itemProperties.fullscreen && (
		// 				<div className="selected-object-menu-btn" id="selected-object-popup-fullscreen-btn">
		// 					<img src={fullscreen_icon} alt="Fullscreen"/>
		// 				</div>
		// 			)}
		// 			{itemProperties.mime_type && (
		// 				<div className="selected-object-menu-btn" id="selected-object-popup-download-btn">
		// 					<img src={download_icon} alt="Download"/>
		// 				</div>
		// 			)}
		// 			{(itemProperties.type_code === "COMPANY" || itemProperties.type_code === "CONTACT") && (
		// 				<>
		// 					<div className="selected-object-menu-btn" id="selected-object-popup-mail-btn">
		// 						<img src={mail_icon} alt="Mail"/>
		// 					</div>
		// 					<div className="selected-object-menu-btn" id="selected-object-popup-phone-btn">
		// 						<img src={phone_icon} alt="Phone"/>
		// 					</div>
		// 				</>
		// 			)}
		// 		</>
		// 	)}
		// 	{text && (
		// 		<>
		// 			<div className="selected-object-sub-menu-container" id="font-size-menu-container">
		// 				<div className="item-btn" id="font-size-item-btn">10</div>
		// 				<div className="item-btn" id="font-size-item-btn">12</div>
		// 				<div className="item-btn" id="font-size-item-btn">14</div>
		// 				<div className="item-btn" id="font-size-item-btn">16</div>
		// 				<div className="item-btn" id="font-size-item-btn">18</div>
		// 				<div className="item-btn" id="font-size-item-btn">20</div>
		// 			</div>
		// 			<div className="selected-object-menu-btn" id="selected-object-popup-font-size-menu-btn">
		// 				<img src={download_icon} alt="Font size"/>
		// 			</div>
		// 			<div className="selected-object-menu-btn" id="selected-object-popup-edit-btn">
		// 				<img src={download_icon} alt="Edit"/>
		// 			</div>
		// 			<div className="selected-object-menu-btn" id="selected-object-popup-delete-btn">
		// 				<img src={download_icon} alt="Delete"/>
		// 			</div>
		// 		</>
		// 	)}
		//
		// </div>
	)
}

export default ZoneSelectedMenu