import React from "react";

const SavingBarPopup = ({state, value, mapName}) => {

    if (state){
        return (
            <div className="saving-bar-popup-overlay">
                <div className={state ? "saving-bar-popup-container open" : "saving-bar-popup-container"}>
                    <p className="title">Saving {mapName}</p>
                    <div className="bar-container">
                        <div className="bar" style={{width: value + "%"}}/>
                    </div>
                    <div className="info-container">
                        <p className="info-item">Saving rights...</p>
                        <p className="info-item">...{value}%</p>
                    </div>
                </div>
            </div>

        )
    } else {
        return null
    }

}

export default SavingBarPopup