import React from "react";
import weeki_logo_notext from "../../images/weeki-logo-notext.svg";
import weeki_logo from "../../images/weeki_logo.svg";
import {useHistory} from "react-router-dom";

const NewsletterUnsubscribe = () => {
    //Import history
    const history = useHistory()

    return (
        <div className="auth-page">
            <div className="topbar">
                <div className="logo">
                    <img src={weeki_logo_notext} alt="Weeki logo"/>
                </div>
                <div className="register">
                    <p className="btn" onClick={() => history.push("/")}>Back on Weeki</p>
                </div>
            </div>
            <div className="sidebar" />
            <div className="container">
                <div className="auth-box">
                    <div className="logo">
                        <img src={weeki_logo} alt="Weeki logo"/>
                    </div>
                    <div className="info-text">
                        <h1>You have successfully unsubscribed</h1>
                        <p>If you have a moment, please let us know why you unsubscribed:</p>
                    </div>
                    <div className="radio-form-container">
                        <div className="radio">
                            <input name="unsubscribe" type="radio"/>
                            <label>I no longer want to receive these emails.</label>
                        </div>
                        <div className="radio">
                            <input name="unsubscribe" type="radio"/>
                            <label>I no longer want to receive these emails.</label>
                        </div>
                        <div className="radio">
                            <input name="unsubscribe" type="radio"/>
                            <label>I no longer want to receive these emails.</label>
                        </div>
                        <div className="radio">
                            <input name="unsubscribe" type="radio"/>
                            <label>I no longer want to receive these emails.</label>
                        </div>
                        <div className="radio">
                            <input name="unsubscribe" type="radio"/>
                            <label>I no longer want to receive these emails.</label>
                        </div>
                        <div className="radio">
                            <input name="unsubscribe" type="radio"/>
                            <label>I no longer want to receive these emails.</label>
                        </div>
                    </div>
                    <p className="valid-btn">Submit</p>
                </div>
            </div>
        </div>
    )
}

export default NewsletterUnsubscribe