import {create} from "zustand";

export const useMoocStore = create((set) => ({
	storiesSidebarState: false,
	shareSidebarState: false,
	openedMooc: null,
	mapMoocs: [],
	openedMoocChapters: [],
	currentSelectedCourse: null,
	currentSelectedQuiz: null,
	courseEditorInstance: null,
	courseFullscreenState: false,
	currentCourseLayout: "left",
	setPositionState: false,
	setPositionCourseUuid: null,
	editQuestions: [],
	updateMoocData: (uuid, datas) => set((state) => ({
		mapMoocs: state.mapMoocs.map((m) => m.uuid === uuid ? datas : m),
		openedMooc: datas
	})),
	updateCurrentMoocThumbnail: (uuid, thumbnail) => set((state) => ({
		mapMoocs: state.mapMoocs.map((m) => m.uuid === uuid ? {...m, thumbnail: thumbnail} : m),
		openedMooc: {...state.openedMooc, thumbnail: thumbnail}
	})),
	addEditQuestion: (question) => set((state) => ({
		editQuestions: [...state.editQuestions, question]
	})),
	addMoocToList: (mooc) => set((state) => ({
		mapMoocs: [...state.mapMoocs, mooc]
	})),
	removeMooc: (uuid) => set((state) => ({
		mapMoocs: state.mapMoocs.filter(m => m.uuid !== uuid)
	})),
	updateCurrentMoocOverviewName: (name) => set((state) => ({
		openedMooc: {...state.openedMooc, overviewName: name}
	})),
	updateMoocOverviewName: (uuid, name) => set((state) => ({
		mapMoocs: state.mapMoocs.map((m) =>
			m.uuid === uuid
				? ({...m, overviewName: name})
				: m
		),
	})),
	addMoocChapter: (chapter) => set((state) => ({
		openedMoocChapters: [...state.openedMoocChapters, chapter]
	})),
	removeMoocChapter: (uuid) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.filter(c => c.uuid !== uuid)
	})),
	setChapterCourses: (uuid, courses) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === uuid
				? ({...c, courses: courses})
				: c
		),
	})),
	setChapterQuiz: (uuid, quizzes) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === uuid
				? ({...c, quizzes: quizzes})
				: c
		),
	})),
	addChapterCourse: (uuid, course) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === uuid
				? ({...c, courses: [...c.courses, course]})
				: c
		),
	})),
	addChapterQuiz: (uuid, quiz) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === uuid
				? ({...c, quizzes: [...c.quizzes, quiz]})
				: c
		),
	})),
	updateChapterName: (uuid, name) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === uuid
				? ({...c, name: name})
				: c
		),
	})),
	changeMoocChapterState: (uuid, newState) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === uuid
				? ({...c, open: newState})
				: c
		),
	})),
	changeMoocChapterRenameState: (uuid, newState) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === uuid
				? ({...c, rename: newState})
				: c
		),
	})),
	updateCourseRenameState: (chapterUuid, courseUuid, newState, type) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? type === "course" ? ({...c, courses: c.courses.map((course) => course.uuid === courseUuid ? {...course, rename: newState} : course)}) : ({...c, quizzes: c.quizzes.map((course) => course.uuid === courseUuid ? {...course, rename: newState} : course)})
				: c
		),
	})),
	updateCurrentMoocRenameState: (newState) => set((state) => ({
		openedMooc: {...state.openedMooc, rename: newState}
	})),
	updateCourseName: (chapterUuid, courseUuid, name, type) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? type === "course" ? ({...c, courses: c.courses.map((course) => course.uuid === courseUuid ? {...course, name: name} : course)}) : ({...c, quizzes: c.quizzes.map((course) => course.uuid === courseUuid ? {...course, name: name} : course)})
				: c
		),
	})),
	updateCourseProgression: (chapterUuid, courseUuid, newState) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? {...c, courses: c.courses.map((course) => course.uuid === courseUuid ? {...course, courseProgression: newState} : course)}
				: c
		),
		currentSelectedCourse: {...state.currentSelectedCourse, courseProgression: newState}
	})),
	updateCourseLocation: (chapterUuid, courseUuid, centerLocation, zoomLevel) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, courses: c.courses.map((course) => course.uuid === courseUuid ? {...course, centerLocation: centerLocation, zoom: zoomLevel} : course)})
				: c
		),
	})),
	updateCurrentCourseContent: (content) => set((state) => ({
		currentSelectedCourse: {...state.currentSelectedCourse, content: content}
	})),
	updateCurrentCourseName: (name) => set((state) => ({
		currentSelectedCourse: {...state.currentSelectedCourse, name: name}
	})),
	updateCurrentQuizName: (name) => set((state) => ({
		currentSelectedQuiz: {...state.currentSelectedQuiz, name: name}
	})),
	updateCurrentMoocName: (name) => set((state) => ({
		openedMooc: {...state.openedMooc, name: name}
	})),
	updateMoocName: (uuid, name) => set((state) => ({
		mapMoocs: state.mapMoocs.map((m) =>
			m.uuid === uuid
				? ({...m, name: name})
				: m
		),
	})),
	addCurrentQuizQuestion: (question) => set((state) => ({
		currentSelectedQuiz: {...state.currentSelectedQuiz, questions: [...state.currentSelectedQuiz.questions, question]}
	})),
	removeCurrentQuizQuestion: (questionUuid) => set((state) => ({
		currentSelectedQuiz: {...state.currentSelectedQuiz, questions: state.currentSelectedQuiz.questions.filter(q => q.uuid !== questionUuid)}
	})),
	updateCurrentQuizQuestion: (question) => set((state) => ({
		currentSelectedQuiz: {...state.currentSelectedQuiz, questions: state.currentSelectedQuiz.questions.map(q => q.uuid === question.uuid ? question : q)}
	})),
	updateQuizQuestion: (chapterUuid, quizUuid, question) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, quizzes: c.quizzes.map((quiz) => quiz.uuid === quizUuid ? {...quiz, questions: quiz.questions.map(q => q.uuid === question.uuid ? question : q)} : quiz)})
				: c
		),
	})),
	updateQuizUserResult: (chapterUuid, quizUuid, results) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, quizzes: c.quizzes.map((quiz) => quiz.uuid === quizUuid ? {...quiz, userResult: results, retryCountLeft: quiz.retryCountLeft - 1} : quiz)})
				: c
		),
	})),
	updateCurrentQuizUserResult: (results) => set((state) => ({
		currentSelectedQuiz: {...state.currentSelectedQuiz, userResult: results, retryCountLeft: state.currentSelectedQuiz.retryCountLeft - 1}
	})),
	updateCurrentQuizRetryLeft: (value) => set((state) => ({
		currentSelectedQuiz: {...state.currentSelectedQuiz, retryCountLeft: value}
	})),
	updateQuizRetryLeft: (chapterUuid, quizUuid, value) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, quizzes: c.quizzes.map((quiz) => quiz.uuid === quizUuid ? {...quiz, retryCountLeft: value} : quiz)})
				: c
		),
	})),
	addQuizQuestion: (chapterUuid, quizUuid, question) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, quizzes: c.quizzes.map((quiz) => quiz.uuid === quizUuid ? {...quiz, questions: [...quiz.questions, question]} : quiz)})
				: c
		),
	})),
	removeQuizQuestion: (chapterUuid, quizUuid, questionUuid) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, quizzes: c.quizzes.map((quiz) => quiz.uuid === quizUuid ? {...quiz, questions: quiz.questions.filter(q => q.uuid !== questionUuid)} : quiz)})
				: c
		),
	})),
	removeChapterCourse: (chapterUuid, courseUuid) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? {...c, courses: c.courses.filter(co => co.uuid !== courseUuid), courseCount: c.courseCount - 1}
				: c
		),
	})),
	removeChapterQuiz: (chapterUuid, quizUuid) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? {...c, quizzes: c.quizzes.filter(q => q.uuid !== quizUuid), quizCount: c.quizCount - 1}
				: c
		),
	})),
	updateQuizPosition: (chapterUuid, quizUuid, newPosition) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, quizzes: c.quizzes.map((quiz) => quiz.uuid === quizUuid ? {...quiz, position: newPosition} : quiz)})
				: c
		),
	})),
	updateCoursePosition: (chapterUuid, courseUuid, newPosition) => set((state) => ({
		openedMoocChapters: state.openedMoocChapters.map((c) =>
			c.uuid === chapterUuid
				? ({...c, courses: c.courses.map((c) => c.uuid === courseUuid ? {...c, position: newPosition} : c)})
				: c
		),
	})),
	resetStore: () => set((state) => ({
		storiesSidebarState: false,
		shareSidebarState: false,
		openedMooc: null,
		mapMoocs: [],
		openedMoocChapters: [],
		courseEditorInstance: null,
		currentSelectedCourse: null,
		currentSelectedQuiz: null,
		courseFullscreenState: false,
		setPositionState: false,
		setPositionCourseUuid: null,
		currentCourseLayout: "left"
	}))
}))