import React, {useEffect, useState} from "react";
import "../../../css/pages/admin/dashboard-global.scss"
import {Range} from "rc-slider";
import "rc-slider/assets/index.css"
import {BiHistory, BiImport} from "react-icons/bi";
import {BsThreeDots} from "react-icons/bs";
import {FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp, FaFilter, FaSearch, FaSort, FaSortDown, FaSortUp, FaTrashAlt} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";
import {IoSearchSharp} from "react-icons/io5";
import {MdContentCopy} from "react-icons/md";
import {VscAdd} from "react-icons/vsc";
import MapAPI from "../../services/MapAPI";
import Chart from "react-apexcharts"
import StatsAPI from "../../services/StatsAPI";
import DashboardStatCard from "../../components/DashboardStatCard";
import icon_card_1 from "../../../icons/admin-dashboard/maps/card-1.svg"
import icon_card_2 from "../../../icons/admin-dashboard/maps/card-2.svg"
import icon_card_3 from "../../../icons/admin-dashboard/maps/card-3.svg"
import icon_card_4 from "../../../icons/admin-dashboard/maps/card-4.svg"
import Export from "../../components/Export";
import ShowOrHideColumnMenu from "../../components/ShowOrHideColumnMenu";
import UserAPI from "../../services/UserAPI";
import ObjectsAPI from "../../services/ObjectsAPI";
import DeleteModal from "../../components/DeleteModal";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";
import {TailSpin} from "react-loader-spinner";


const AdminDashboardMaps = () => {

    //Define default state for get map request
    const [getMapsRequestStatus, setGetMapsRequestStatus] = useState(false)

    //Define default state for maps list
    const [maps, setMaps] = useState([
        {
            "id": "",
            "name": "",
            "theme": "",
            "lastModified": null,
            "template": false,
            "visibility": false,
            "collaborator": [{
                "email": "",
                "firstname": "",
                "lastname": ""
            }],
            "objects": [{
                "id": "",
                "name": "",
                "type": "",
                "user": ""
            }],
            "password": false,
            "size": "",
            "createdAt": "",
            "ownerId": 0,
            "deleteDate": ""
        }
    ])

    useEffect(() => {
        MapAPI.getMapList().then((response) => {
            setMaps(response.data)
            setGetMapsRequestStatus(true)
        })
    }, [])

    //Define default state for graph card status
    const [nbMapsCardStatus, setNbMapsCardStatus] = useState(false)
    const [mapActivityCardStatus, setMapActivityCardStatus] = useState(false)
    /*******************************FILTER MODAL******************************/
    const removeAllFilter = () => {
        setSelectedUser([])
        setAccessFilterStatus("none")
        setPasswordFilterStatus("none")
        setTemplateFilterStatus("none")
        setSizeRangeFilterValue([0, 500])
        setUserRangeFilterValue([0, 50])
        setObjectRangeFilterValue([0, 5000])
    }

    const [filterModalStatus, setFilterModalStatus] = useState(false)

    //User filter
    const [userSearchFocus, setUserSearchFocus] = useState(false)
    const [filterUserSearch, setFilterUserSearch] = useState("")
    const [selectedUser, setSelectedUser] = useState([])

    const [users, setUsers] = useState([{
        "id": "",
        "firstname": "",
        "lastname": ""
    }])

    const filteredUsers = users.filter(u =>
        String(u.firstname).toLowerCase().includes(filterUserSearch.toLowerCase()) ||
        String(u.lastname).toLowerCase().includes(filterUserSearch.toLowerCase()) ||
        String(u.id).toLowerCase().includes(filterUserSearch.toLowerCase())
    )

    const filteredUsersWithoutSelected = filteredUsers.filter(fu => !selectedUser.includes(fu.id))

    //Object filter
    const [objectSearchFocus, setObjectSearchFocus] = useState(false)
    const [filterObjectSearch, setFilterObjectSearch] = useState("")
    const [selectedObject, setSelectedObject] = useState([])

    const [objects, setObjects] = useState([{
        "name": "",
        "id": ""
    }])

    const filteredObjects = objects.filter(o =>
        String(o.name).toLowerCase().includes(filterObjectSearch.toLowerCase()) ||
        String(o.id).toLowerCase().includes(filterObjectSearch.toLowerCase())
    )

    const filteredObjectsWithoutSelected = filteredObjects.filter(fu => !selectedObject.includes(fu))

    //Selector filter
    const [accessFilterStatus, setAccessFilterStatus] = useState("none")
    const [passwordFilterStatus, setPasswordFilterStatus] = useState("none")
    const [templateFilterStatus, setTemplateFilterStatus] = useState("none")

    //Range filter
    const [sizeRangeFilterValue, setSizeRangeFilterValue] = useState([0, 500])
    const [userRangeFilterValue, setUserRangeFilterValue] = useState([0, 50])
    const [objectRangeFilterValue, setObjectRangeFilterValue] = useState([0, 5000])


    //Detect filter modal open
    useEffect(() => {
        if (filterModalStatus){
            UserAPI.adminGetUsers().then((response) => setUsers(response.data))
            ObjectsAPI.adminGetAllObjects().then((response) => setObjects(response.data))
        }
    }, [filterModalStatus])
    /*************************************************************************/

    /******************************SEARCH*************************************/
    const [search, setSearch] = useState("")

    let filteredMaps = maps.filter(m =>
        String(m.name).toLowerCase().includes(search.toLowerCase()) ||
        String(m.theme).toLowerCase().includes(search.toLowerCase()) ||
        String(m.createdAt).toLowerCase().includes(search.toLowerCase()) ||
        String(m.lastModified).toLowerCase().includes(search.toLowerCase()) ||
        String(m.size).toLowerCase().includes(search.toLowerCase())

    )

    if (accessFilterStatus !== "none"){
        if (accessFilterStatus === "private"){
            filteredMaps = filteredMaps.filter(m =>
                m.visibility === false
            )
        }
        if (accessFilterStatus === "public"){
            filteredMaps = filteredMaps.filter(m =>
                m.visibility === true
            )
        }
    }
    if (passwordFilterStatus !== "none"){
        if (passwordFilterStatus === "no"){
            filteredMaps = filteredMaps.filter(m =>
                m.password === false
            )
        }
        if (passwordFilterStatus === "yes"){
            filteredMaps = filteredMaps.filter(m =>
                m.password === true
            )
        }
    }
    if (templateFilterStatus !== "none"){
        if (templateFilterStatus === "no"){
            filteredMaps = filteredMaps.filter(m =>
                m.template === false
            )
        }
        if (templateFilterStatus === "yes"){
            filteredMaps = filteredMaps.filter(m =>
                m.template === true
            )
        }
    }

    // if (sizeRangeFilterValue[0] !== 0 || sizeRangeFilterValue[1] !== 500){
    //
    // }

    if (userRangeFilterValue[0] !== 0 || userRangeFilterValue[1] !== 50){
        filteredMaps = filteredMaps.filter(m =>
            m.collaborator.length >= userRangeFilterValue[0]
        )
        filteredMaps = filteredMaps.filter(m =>
            m.collaborator.length <= userRangeFilterValue[1]
        )
    }

    if (objectRangeFilterValue[0] !== 0 || objectRangeFilterValue[1] !== 50){
        filteredMaps = filteredMaps.filter(m =>
            m.objects.length >= objectRangeFilterValue[0]
        )
        filteredMaps = filteredMaps.filter(m =>
            m.objects.length <= objectRangeFilterValue[1]
        )
    }

    if (selectedUser.length > 0){
        filteredMaps = filteredMaps.filter(m =>
            selectedUser.includes(parseInt(m.ownerId))
        )
    }
    // if (selectedObject.length > 0){
    //     maps.map(m => m.objects.filter(o => o.id))
    // }
    /*************************************************************************/

    /*****************************PAGINATION**********************************/
    const [currentPage, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const maxPage = Math.ceil(filteredMaps.length / itemPerPage)

    const nextPage = () => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }
    const previousPage = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }

    const start = (currentPage - 1) * itemPerPage
    const end = start + itemPerPage
    const paginatedMaps = filteredMaps.slice(start, end)

    const pageButtons = []

    for (let i = 0; i < maxPage; i++){
        pageButtons.push(<p onClick={() => setCurrentPage(i + 1)} className={currentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*************************SHOW/HIDE COLUMN********************************/
    const [showHideStatus, setShowHideStatus] = useState(false)
    const [columnList, setColumnList] = useState({
        "theme": true,
        "created on": true,
        "last modified": true,
        "size": true,
        "users": true,
        "objects": true,
        "from template": true,
        "access": true,
        "password": true
    })

    const resetShowOrHide = () => {
        setColumnList({
            "theme": true,
            "created on": true,
            "last modified": true,
            "size": true,
            "users": true,
            "objects": true,
            "from template": true,
            "access": true,
            "password": true
        })
    }
    /*************************************************************************/

    /****************************DELETE MAP PASSWORD**************************/
    const [deleteMapPasswordModalStatus, setDeleteMapPasswordModalStatus] = useState(false)
    const [deleteMapPasswordStatus, setDeleteMapPasswordStatus] = useState(false)
    const [deletedMapPasswordId, setDeletedMapPasswordId] = useState(0)

    const deleteMapPassword = async () => {
        if (deletedMapPasswordId !== 0){
            if (await MapAPI.deleteMapPassword(deletedMapPasswordId)){
                setDeleteMapPasswordStatus(true)
                setGetMapsRequestStatus(false)
                MapAPI.getMapList().then((response) => {
                    if (response.status === 200){
                        setMaps(response.data)
                    }
                    setGetMapsRequestStatus(true)
                })
            }
        }
    }
    /*************************************************************************/

    /****************************DELETE MAP***********************************/
    const [deleteMapModalStatus, setDeleteMapModalStatus] = useState(false)
    const [deleteMapStatus, setDeleteMapStatus] = useState(false)
    const [deletedMapId, setDeletedMapId] = useState(0)

    const deleteMap = async () => {
        if (deletedMapId !== 0){
            if (await MapAPI.deleteMap(deletedMapId)){
                setDeleteMapStatus(true)
                setGetMapsRequestStatus(false)
                MapAPI.getMapList().then((response) => {
                    if (response.status === 200){
                        setMaps(response.data)
                    }
                    setGetMapsRequestStatus(true)
                })
            }
        }
    }
    /*************************************************************************/

    /********************************STATS************************************/
    const [chart1Data, setChart1Data] = useState({
        "labels": [],
        "activeMaps": [],
        "inactiveMaps": []
    })

    const [card1SelectedDay, setCard1SelectedDay] = useState(60)
    const [activeMaps, setActiveMaps] = useState(0)

    const [card2SelectedDay, setCard2SelectedDay] = useState(60)
    const [averageMapSize, setAverageMapSize] = useState(0)

    const [card3SelectedDay, setCard3SelectedDay] = useState(60)
    const [averageObjectPerMap, setAverageObjectPerMap] = useState(0)

    const [card4SelectedDay, setCard4SelectedDay] = useState(60)
    const [mapTotalSize, setMapTotalSize] = useState(0)

    //Get chart stats
    useEffect(() => {
        StatsAPI.getMapsStatusChart().then((response) => setChart1Data(response.data))
    }, [])

    //Get active maps stats
    useEffect(() => {
        StatsAPI.getActiveMaps(card1SelectedDay).then((response) => setActiveMaps(response.data))
    }, [card1SelectedDay])

    //Get average map size stats
    useEffect(() => {
        StatsAPI.getAverageMapSize(card2SelectedDay).then((response) => setAverageMapSize(response.data))
    }, [card2SelectedDay])

    //Get average object per map stats
    useEffect(() => {
        StatsAPI.getAverageObjectsPerMap(card3SelectedDay).then((response) => setAverageObjectPerMap(response.data))
    }, [card3SelectedDay])

    //Get map total size stats
    useEffect(() => {
        StatsAPI.getMapTotalSize(card4SelectedDay).then((response) => setMapTotalSize(response.data))
    }, [card4SelectedDay])
    /*************************************************************************/
    /*******************************IMPORT MAP********************************/
    const importMap = async (event) => {
        const file = event.currentTarget.files[0]

        if (file){
            if (file.type === "application/zip"){
                if (await MapAPI.importMap(file)){
                    toast.success("Map imported successfully")
                    window.location.reload()
                }
            } else {
                toast.error("Please select zip file")
            }
        }
    }
    /*************************************************************************/

    /****************************DELETE ALL MAP*******************************/
    const deleteSelectedMap = async () => {
        if (checkedMaps.length > 0){
            for (let i = 0; i < checkedMaps.length; i++){
                await MapAPI.deleteMap(checkedMaps[i])
            }
            toast.success("Maps deleted successfully")

            setGetMapsRequestStatus(false)
            MapAPI.getMapList().then((response) => {
                if (response.status === 200){
                    setMaps(response.data)
                }
                setGetMapsRequestStatus(true)
            })
        }
    }
    /*************************************************************************/

    /********************************COPY MAPS********************************/
    const copySelectedMap = async () => {
        if (checkedMaps.length > 0){
            if (await MapAPI.copyMaps(checkedMaps)){
                toast.success("Maps copied successfully")

                setGetMapsRequestStatus(false)
                MapAPI.getMapList().then((response) => {
                    if (response.status === 200){
                        setMaps(response.data)
                    }
                    setGetMapsRequestStatus(true)
                })
            }
        }
    }
    /*************************************************************************/

    const [openedSubMenuMapId, setOpenedSubMenuMapId] = useState(0)


    const [viewObjectMapId, setViewObjectMapId] = useState(0)
    const [viewObjectOnMapModalStatus, setViewObjectOnMapModalStatus] = useState(false)

    const [viewUserOnMapId, setViewUserOnMapId] = useState(0)
    const [viewUserOnMapModalStatus, setViewUserOnMapModalStatus] = useState(false)

    const [checkedMaps, setCheckMaps] = useState([])

    const checkAll = (event) => {
        setCheckMaps([])
        if (event.currentTarget.checked){
            for (let i = 0; i < maps.length; i++){
                setCheckMaps(checked => [...checked, maps[i].id])
            }
        }
    }

    /*******************************SORT MAP**********************************/
    const [sortedColumnNameMap, setSortedColumnNameMap] = useState("")
    const [sortedTypeMap, setSortedTypeMap] = useState("")

    if (sortedColumnNameMap !== "" && sortedTypeMap !== ""){
        if (sortedTypeMap === "1"){
            paginatedMaps.sort((a, b) => String(a[sortedColumnNameMap]).localeCompare(String(b[sortedColumnNameMap])))
        }
        if (sortedTypeMap === "2") {
            paginatedMaps.sort((a, b) => String(b[sortedColumnNameMap]).localeCompare(String(a[sortedColumnNameMap])))
        }
    }
    /*************************************************************************/

    const dashboard = document.getElementById("scroll-container")

    return (
        <>
            {/*DELETE MAP PASSWORD MODAL*/}
            {deleteMapPasswordModalStatus && (
                <DeleteModal
                    modalStatus={deleteMapPasswordModalStatus}
                    setModalStatus={setDeleteMapPasswordModalStatus}
                    requestStatus={deleteMapPasswordStatus}
                    setRequestStatus={setDeleteMapPasswordStatus}
                    deleteMethod={deleteMapPassword}
                    title={"Delete password for *" + maps.filter(m => m.id === deletedMapPasswordId)[0].name + "* map"}
                    alertMessage={"Confirm delete password for *" + maps.filter(m => m.id === deletedMapPasswordId)[0].name + "* map"}
                    successMessage={"Password deleted for *" + maps.filter(m => m.id === deletedMapPasswordId)[0].name + "* map"}
                />
            )}
            {/*DELETE MAP MODAL*/}
            {deleteMapModalStatus && (
                <DeleteModal
                    modalStatus={deleteMapModalStatus}
                    setModalStatus={setDeleteMapModalStatus}
                    requestStatus={deleteMapStatus}
                    setRequestStatus={setDeleteMapStatus}
                    deleteMethod={deleteMap}
                    title={"Delete map *" + maps.filter(m => m.id === deletedMapId)[0].name + "*"}
                    alertMessage={"You are about the start the removal process for *" + maps.filter(m => m.id === deletedMapId)[0].name + "* map. Do you confirm ? "}
                    successMessage={"Map *" + maps.filter(m => m.id === deletedMapId)[0].name + "* successfully deleted"}
                />
            )}
            {/*OBJECT ON MAP MODAL*/}
            {viewObjectOnMapModalStatus &&(
                <div className="modal-overlay">
                    <div className={viewObjectOnMapModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Object used on *{maps.filter(m => m.id === viewObjectMapId)[0].name}*</h1>
                            <p onClick={() => setViewObjectOnMapModalStatus(false)}><IoMdClose /></p>
                        </div>
                        <div className="content">
                            <table>
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>User</td>
                                        <td>Type</td>
                                        <td>File extension</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {maps.filter(m => m.id === viewObjectMapId)[0].objects.map((o, index) => (
                                    <tr key={index}>
                                        <td>{o.name}</td>
                                        <td>{o.user}</td>
                                        <td>{o.type}</td>
                                        <td>NONE</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
            {/*USER ON MAP MODAL*/}
            {viewUserOnMapModalStatus &&(
                <div className="modal-overlay">
                    <div className={viewUserOnMapModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>User on *{maps.filter(m => m.id === viewUserOnMapId)[0].name}*</h1>
                            <p onClick={() => setViewUserOnMapModalStatus(false)}><IoMdClose /></p>
                        </div>
                        <div className="content">
                            <table>
                                <thead>
                                    <tr>
                                        <td>User name</td>
                                        <td>Email</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {maps.filter(m => m.id === viewUserOnMapId)[0].collaborator.map((o, index) => (
                                    <tr key={index}>
                                        <td>{o.firstname} {o.lastname}</td>
                                        <td>{o.email}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
            {/*FILTER MODAL*/}
            {filterModalStatus &&(
                <div className="modal-overlay">
                    <div className={filterModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Filter</h1>
                            <p onClick={() => {
                                setFilterModalStatus(false)
                                setUserSearchFocus(false)
                            }}><IoMdClose /></p>
                        </div>
                        <div className="content filter">
                            <div className="input-container">
                                <p className="title">Search for specific user</p>
                                <div className={userSearchFocus ? "input-box open" : "input-box"}>
                                    <div className="input">
                                        <p className="icon"><FaSearch /></p>
                                        <input value={filterUserSearch} onChange={(event) => setFilterUserSearch(event.currentTarget.value)} type="text" placeholder="Search by user name" onFocus={() => setUserSearchFocus(true)}/>
                                        {userSearchFocus && (
                                            <p className="icon close" onClick={() => setUserSearchFocus(false)}><IoMdClose /></p>
                                        )}
                                    </div>
                                    <div className="menu">
                                        {filteredUsersWithoutSelected.length > 0 && (
                                            <>
                                                {filteredUsersWithoutSelected.map((user, index) => (
                                                    <>
                                                        {index <= 5 && (
                                                            <div className="row" key={index}>
                                                                <p className="username">{user.firstname} {user.lastname}</p>
                                                                <div className="add-btn" onClick={() => setSelectedUser(selectedUser => [...selectedUser, user.id])}>
                                                                    <p className="icon"><VscAdd /></p>
                                                                    <p className="text">Add</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        )}
                                        {filteredUsersWithoutSelected.length === 0 && (
                                            <p className="message">No user match</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {selectedUser.length > 0 && (
                                <div className="selected-item-list">
                                    <div className="item">
                                        <p className="text">Remove all</p>
                                        <p className="icon" onClick={() => setSelectedUser([])}><IoMdClose /></p>
                                    </div>
                                    {selectedUser.map((id, index) => (
                                        <div className="item" key={index}>
                                            <p className="text">{users.filter(u => u.id === id)[0].firstname} {users.filter(u => u.id === id)[0].lastname}</p>
                                            <p className="icon" onClick={() => setSelectedUser(selectedUser.filter(u => u !== id))}><IoMdClose /></p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {/*OBJECT SEARCH*/}
                            <div className="input-container">
                                <p className="title">Search for specific object</p>
                                <div className={objectSearchFocus ? "input-box open" : "input-box"}>
                                    <div className="input">
                                        <p className="icon"><FaSearch /></p>
                                        <input value={filterObjectSearch} onChange={(event) => setFilterObjectSearch(event.currentTarget.value)} type="text" placeholder="Search by object name" onFocus={() => setObjectSearchFocus(true)}/>
                                        {objectSearchFocus && (
                                            <p className="icon close" onClick={() => setObjectSearchFocus(false)}><IoMdClose /></p>
                                        )}
                                    </div>
                                    <div className="menu">
                                        {filteredObjectsWithoutSelected.length > 0 && (
                                            <>
                                                {filteredObjectsWithoutSelected.map((object, index) => (
                                                    <>
                                                        {index <= 5 && (
                                                            <div className="row" key={index}>
                                                                <p className="username">{object.name}</p>
                                                                <div className="add-btn" onClick={() => setSelectedObject(selectedObject => [...selectedObject, object])}>
                                                                    <p className="icon"><VscAdd /></p>
                                                                    <p className="text">Add</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        )}
                                        {filteredObjectsWithoutSelected.length === 0 && (
                                            <p className="message">No object match</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {selectedObject.length > 0 && (
                                <div className="selected-item-list">
                                    <div className="item">
                                        <p className="text">Remove all</p>
                                        <p className="icon" onClick={() => setSelectedObject([])}><IoMdClose /></p>
                                    </div>
                                    {selectedObject.map((object, index) => (
                                        <div className="item" key={index}>
                                            <p className="text">{objects.filter(u => u.id === object.id)[0].name}</p>
                                            <p className="icon" onClick={() => setSelectedObject(selectedObject.filter(u => u !== object.id))}><IoMdClose /></p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {/*SELECTOR*/}
                            <div className="selector-container">
                                <div className="selector">
                                    <p className="title">Access</p>
                                    <div className="select-box">
                                        <p className={accessFilterStatus === "private" ? "active" : null} onClick={() => accessFilterStatus === "none" || accessFilterStatus === "public" ? setAccessFilterStatus("private") : setAccessFilterStatus("none")}>Private</p>
                                        <p className={accessFilterStatus === "public" ? "active" : null} onClick={() => accessFilterStatus === "none" || accessFilterStatus === "private" ? setAccessFilterStatus("public") : setAccessFilterStatus("none")}>Public</p>
                                    </div>
                                </div>
                                <div className="selector">
                                    <p className="title">Password</p>
                                    <div className="select-box">
                                        <p className={passwordFilterStatus === "yes" ? "active" : null} onClick={() => passwordFilterStatus === "none" || passwordFilterStatus === "no" ? setPasswordFilterStatus("yes") : setPasswordFilterStatus("none")}>Yes</p>
                                        <p className={passwordFilterStatus === "no" ? "active" : null} onClick={() => passwordFilterStatus === "none" || passwordFilterStatus === "yes" ? setPasswordFilterStatus("no") : setPasswordFilterStatus("none")}>No</p>
                                    </div>
                                </div>
                                <div className="selector">
                                    <p className="title">From Template</p>
                                    <div className="select-box">
                                        <p className={templateFilterStatus === "yes" ? "active" : null} onClick={() => templateFilterStatus === "none" || templateFilterStatus === "no" ? setTemplateFilterStatus("yes") : setTemplateFilterStatus("none")}>Yes</p>
                                        <p className={templateFilterStatus === "no" ? "active" : null} onClick={() => templateFilterStatus === "none" || templateFilterStatus === "yes" ? setTemplateFilterStatus("no") : setTemplateFilterStatus("none")}>No</p>
                                    </div>
                                </div>
                            </div>
                            {/*RANGE*/}
                            <div className="range-container">
                                <div className="range-box">
                                    <p className="title">Size (Gb)</p>
                                    <div className="range">
                                        <Range railStyle={{backgroundColor: "#ebecfc", height: "2px"}} trackStyle={[{backgroundColor: "#2953e8", height: "2px"}]} onChange={(event) => setSizeRangeFilterValue(event)} min={0} max={500} allowCross={false} defaultValue={[0, 500]} value={sizeRangeFilterValue}/>
                                        <div className="info">
                                            <p className="value">{sizeRangeFilterValue[0]}</p>
                                            <p className="value">{sizeRangeFilterValue[1]}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="range-box">
                                    <p className="title">User</p>
                                    <div className="range">
                                        <Range railStyle={{backgroundColor: "#ebecfc", height: "2px"}} trackStyle={[{backgroundColor: "#2953e8", height: "2px"}]} onChange={(event) => setUserRangeFilterValue(event)} min={0} max={50} allowCross={false} defaultValue={[0, 50]} value={userRangeFilterValue}/>
                                        <div className="info">
                                            <p className="value">{userRangeFilterValue[0]}</p>
                                            <p className="value">{userRangeFilterValue[1]}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="range-box">
                                    <p className="title">Objects</p>
                                    <div className="range">
                                        <Range railStyle={{backgroundColor: "#ebecfc", height: "2px"}} trackStyle={[{backgroundColor: "#2953e8", height: "2px"}]} onChange={(event) => setObjectRangeFilterValue(event)} min={0} max={5000} allowCross={false} defaultValue={[0, 5000]} value={objectRangeFilterValue}/>
                                        <div className="info">
                                            <p className="value">{objectRangeFilterValue[0]}</p>
                                            <p className="value">{objectRangeFilterValue[1]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*PAGE VIEW*/}
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-dashboard">
                    <h1 className="page-title">Overview</h1>
                    <div className="card-container">
                        <DashboardStatCard icon={icon_card_1} title="Active maps" value={activeMaps + " Maps"} augment={false} augmentPercent={0} selectedDay={card1SelectedDay} setSelectedDay={setCard1SelectedDay}/>
                        <DashboardStatCard icon={icon_card_2} title="Average map size" value={averageMapSize} augment={false} augmentPercent={0} selectedDay={card2SelectedDay} setSelectedDay={setCard2SelectedDay}/>
                        <DashboardStatCard icon={icon_card_3} title="Average object count per map" value={averageObjectPerMap} augment={false} augmentPercent={0} selectedDay={card3SelectedDay} setSelectedDay={setCard3SelectedDay}/>
                        <DashboardStatCard icon={icon_card_4} title="Maps total size" value={mapTotalSize} augment={false} augmentPercent={0} selectedDay={card4SelectedDay} setSelectedDay={setCard4SelectedDay}/>
                    </div>
                    <div className="metric-container">
                        <div className={nbMapsCardStatus ? "metric-card open" : "metric-card"}>
                            <div className="top">
                                <p>Number of map</p>
                                <p onClick={() => setNbMapsCardStatus(!nbMapsCardStatus)}>
                                    {nbMapsCardStatus
                                        ? <FaChevronUp />
                                        : <FaChevronDown />
                                    }
                                </p>
                            </div>
                            <div className="content">
                                <Chart options={{
                                    chart: {
                                        type: 'line',
                                        zoom: {enabled: false}
                                    },
                                    stroke: {curve: 'smooth', width: "3"},
                                    labels: chart1Data.labels,
                                    colors: ["#2dd7a7", "#ffbf66"],
                                    legend: {position: "top", fontFamily: "Roboto, sans-serif", fontWeight: "400", fontsize: "12px", itemMargin: {horizontal: 25}}
                                }} series={[
                                    {
                                        name: "Active maps",
                                        data: chart1Data.activeMaps
                                    },
                                    {
                                        name: "Inactive maps",
                                        data: chart1Data.inactiveMaps
                                    }
                                ]} widht="100%" height={350} type="line"
                                       children={null}/>
                            </div>
                        </div>
                        <div className={mapActivityCardStatus ? "metric-card open" : "metric-card"}>
                            <div className="top">
                                <p>Map activity (Click numbers of map)</p>
                                <p onClick={() => setMapActivityCardStatus(!mapActivityCardStatus)}>
                                    {mapActivityCardStatus
                                        ? <FaChevronUp />
                                        : <FaChevronDown />
                                    }
                                </p>
                            </div>
                            <div className="content">
                                <Chart
                                    options={{
                                        chart: {
                                            type: "bar"
                                        },
                                        xaxis: {
                                            categories: ["6", "12", "24", "30", "36", "42", "48", "54", "60"],
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: false,
                                                columnWidth: "50%",
                                                endingShape: "rounded"
                                            }
                                        },
                                        dataLabels: {enabled: false},
                                        stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                        },
                                        fill: {
                                            colors: ["#4E70FF"],
                                            opacity: 1,
                                        },
                                        tooltip: {
                                            y: {
                                                formatter: function(val) {
                                                    return  + val + " Click";
                                                },
                                            },
                                        }
                                    }}
                                   series={[
                                       {
                                           name: "Click numbers of map",
                                           data: [44, 55, 57, 56, 61, 58, 63, 60, 66,89,95,65,25,54,20,45,36,74,21,15,16,46,89,54,65,41,20,58,98,65,45,36,74],
                                       },

                                   ]}
                                   type="bar"
                                   height={350}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of maps</p>
                        <div className="top-container">
                            <div className="left">
                                <div className="filter-btn" onClick={() => setFilterModalStatus(true)}><FaFilter /></div>
                                <ShowOrHideColumnMenu resetColumn={resetShowOrHide} columnList={columnList} setColumnList={setColumnList} open={showHideStatus} setOpen={setShowHideStatus} />
                                <div className="search-container">
                                    <div className="box">
                                        <div className="icon"><IoSearchSharp /></div>
                                        <input type="text" placeholder="Search by map name" value={search} onChange={(event) => setSearch(event.currentTarget.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="btn">
                                    <div className="icon"><BiHistory /></div>
                                    <div className="text">History</div>
                                </div>
                                <div className="btn">
                                    <div className="icon"><BiImport /></div>
                                    <div className="text">Import map</div>
                                </div>
                                <Export data={checkedMaps.length === 0 ? maps : maps.filter(u => maps.includes(u.id))} filename="weeki_maps" />
                            </div>
                        </div>
                        {selectedUser.length > 0 ||
                        accessFilterStatus !== "none" ||
                        passwordFilterStatus !== "none" ||
                        templateFilterStatus !== "none" ||
                        sizeRangeFilterValue[0] !== 0 || sizeRangeFilterValue[1] !== 500 ||
                        userRangeFilterValue[0] !== 0 || userRangeFilterValue[1] !== 50 ||
                        objectRangeFilterValue[0] !== 0 || objectRangeFilterValue[1] !== 5000
                            ?
                            <div className="filter-item-list">
                                <div className="item">
                                    <p className="text">Remove all filter</p>
                                    <p className="icon" onClick={removeAllFilter}><IoMdClose /></p>
                                </div>
                                {selectedUser.map((id, index) => (
                                    <div className="item" key={index}>
                                        <p className="text">{users.filter(u => u.id === id)[0].firstname} {users.filter(u => u.id === id)[0].lastname}</p>
                                        <p className="icon" onClick={() => setSelectedUser(selectedUser.filter(u => u !== id))}><IoMdClose /></p>
                                    </div>
                                ))}
                                {accessFilterStatus !== "none" && (
                                    <div className="item">
                                        <p className="text">Access : {accessFilterStatus === "private" ? "Private" : "Public"}</p>
                                        <p className="icon" onClick={() => setAccessFilterStatus("none")}><IoMdClose /></p>
                                    </div>
                                )}
                                {passwordFilterStatus !== "none" && (
                                    <div className="item">
                                        <p className="text">Password : {passwordFilterStatus === "yes" ? "Yes" : "No"}</p>
                                        <p className="icon" onClick={() => setPasswordFilterStatus("none")}><IoMdClose /></p>
                                    </div>
                                )}
                                {templateFilterStatus !== "none" && (
                                    <div className="item">
                                        <p className="text">Template : {templateFilterStatus === "yes" ? "Yes" : "No"}</p>
                                        <p className="icon" onClick={() => setTemplateFilterStatus("none")}><IoMdClose /></p>
                                    </div>
                                )}
                                {sizeRangeFilterValue[0] !== 0 || sizeRangeFilterValue[1] !== 500
                                    ?
                                    <div className="item">
                                        <p className="text">Size : {sizeRangeFilterValue[0]} - {sizeRangeFilterValue[1]}</p>
                                        <p className="icon" onClick={() => setSizeRangeFilterValue([0, 500])}><IoMdClose /></p>
                                    </div>
                                    : null
                                }
                                {userRangeFilterValue[0] !== 0 || userRangeFilterValue[1] !== 50
                                    ?
                                    <div className="item">
                                        <p className="text">User : {userRangeFilterValue[0]} - {userRangeFilterValue[1]}</p>
                                        <p className="icon" onClick={() => setUserRangeFilterValue([0, 50])}><IoMdClose /></p>
                                    </div>
                                    : null
                                }
                                {objectRangeFilterValue[0] !== 0 || objectRangeFilterValue[1] !== 5000
                                    ?
                                    <div className="item">
                                        <p className="text">Objects : {objectRangeFilterValue[0]} - {objectRangeFilterValue[1]}</p>
                                        <p className="icon" onClick={() => setObjectRangeFilterValue([0, 5000])}><IoMdClose /></p>
                                    </div>
                                    : null
                                }
                            </div>
                            : null
                        }
                        {checkedMaps.length > 0 && (
                            <div className="select-action-container">
                                <div className="btn delete" onClick={deleteSelectedMap}>
                                    <p className="text">Delete all</p>
                                    <p className="icon"><FaTrashAlt /></p>
                                </div>
                                <div className="btn copy" onClick={copySelectedMap}>
                                    <p className="text">Copy</p>
                                    <p className="icon"><MdContentCopy /></p>
                                </div>
                            </div>
                        )}
                        <table>
                            <thead>
                                <tr>
                                    <td className="left input">
                                        <input type="checkbox" onChange={checkAll}/>
                                    </td>
                                    <td className="left large" onClick={() => {
                                        setSortedColumnNameMap("name")
                                        if (sortedTypeMap === ""){
                                            setSortedTypeMap("1")
                                        } else if (sortedTypeMap === "1"){
                                            setSortedTypeMap("2")
                                        } else {
                                            setSortedTypeMap("")
                                        }
                                    }}>
                                        name
                                        <span className="sort-icon">
                                            {sortedTypeMap === "" || sortedColumnNameMap !== "name"
                                                ? <FaSort /> : null
                                            }
                                            {sortedTypeMap === "1" && sortedColumnNameMap === "name"
                                                ? <FaSortUp /> : null
                                            }
                                            {sortedTypeMap === "2" && sortedColumnNameMap === "name"
                                                ? <FaSortDown /> : null
                                            }
                                        </span>
                                    </td>
                                    {columnList.theme && (
                                        <td onClick={() => {
                                            setSortedColumnNameMap("theme")
                                            if (sortedTypeMap === ""){
                                                setSortedTypeMap("1")
                                            } else if (sortedTypeMap === "1"){
                                                setSortedTypeMap("2")
                                            } else {
                                                setSortedTypeMap("")
                                            }
                                        }}>
                                            theme
                                            <span className="sort-icon">
                                            {sortedTypeMap === "" || sortedColumnNameMap !== "theme"
                                                ? <FaSort /> : null
                                            }
                                                {sortedTypeMap === "1" && sortedColumnNameMap === "theme"
                                                    ? <FaSortUp /> : null
                                                }
                                                {sortedTypeMap === "2" && sortedColumnNameMap === "theme"
                                                    ? <FaSortDown /> : null
                                                }
                                        </span>
                                        </td>
                                    )}
                                    {columnList['created on'] && (
                                        <td onClick={() => {
                                            setSortedColumnNameMap("createdAt")
                                            if (sortedTypeMap === ""){
                                                setSortedTypeMap("1")
                                            } else if (sortedTypeMap === "1"){
                                                setSortedTypeMap("2")
                                            } else {
                                                setSortedTypeMap("")
                                            }
                                        }}>
                                            created on
                                            <span className="sort-icon">
                                            {sortedTypeMap === "" || sortedColumnNameMap !== "createdAt"
                                                ? <FaSort /> : null
                                            }
                                                {sortedTypeMap === "1" && sortedColumnNameMap === "createdAt"
                                                    ? <FaSortUp /> : null
                                                }
                                                {sortedTypeMap === "2" && sortedColumnNameMap === "createdAt"
                                                    ? <FaSortDown /> : null
                                                }
                                        </span>
                                        </td>
                                    )}
                                    {columnList['last modified'] && (
                                        <td>
                                            last modified
                                        </td>
                                    )}
                                    {columnList.size && (
                                        <td onClick={() => {
                                            setSortedColumnNameMap("size")
                                            if (sortedTypeMap === ""){
                                                setSortedTypeMap("1")
                                            } else if (sortedTypeMap === "1"){
                                                setSortedTypeMap("2")
                                            } else {
                                                setSortedTypeMap("")
                                            }
                                        }}>
                                            size (GB)
                                                <span className="sort-icon">
                                            {sortedTypeMap === "1" && sortedColumnNameMap === "size" ? <FaSortUp /> : sortedTypeMap === "2" && sortedColumnNameMap === "size" ? <FaSortDown /> : sortedTypeMap === "" || sortedColumnNameMap !== "size" ? <FaSort /> : null}
                                        </span>
                                        </td>
                                    )}
                                    {columnList.users && (
                                        <td>users</td>
                                    )}
                                    {columnList.objects && (
                                        <td>objects</td>
                                    )}
                                    {columnList['from template'] && (
                                        <td onClick={() => {
                                            setSortedColumnNameMap("template")
                                            if (sortedTypeMap === ""){
                                                setSortedTypeMap("1")
                                            } else if (sortedTypeMap === "1"){
                                                setSortedTypeMap("2")
                                            } else {
                                                setSortedTypeMap("")
                                            }
                                        }}>
                                            from template
                                            <span className="sort-icon">
                                            {sortedTypeMap === "1" && sortedColumnNameMap === "template" ? <FaSortUp /> : sortedTypeMap === "2" && sortedColumnNameMap === "template" ? <FaSortDown /> : sortedTypeMap === "" || sortedColumnNameMap !== "template" ? <FaSort /> : null}
                                        </span>
                                        </td>
                                    )}
                                    {columnList.access && (
                                        <td onClick={() => {
                                            setSortedColumnNameMap("visibility")
                                            if (sortedTypeMap === ""){
                                                setSortedTypeMap("1")
                                            } else if (sortedTypeMap === "1"){
                                                setSortedTypeMap("2")
                                            } else {
                                                setSortedTypeMap("")
                                            }
                                        }}>
                                            access
                                            <span className="sort-icon">
                                            {sortedTypeMap === "1" && sortedColumnNameMap === "visibility" ? <FaSortUp /> : sortedTypeMap === "2" && sortedColumnNameMap === "visibility" ? <FaSortDown /> : sortedTypeMap === "" || sortedColumnNameMap !== "visibility" ? <FaSort /> : null}
                                        </span>
                                        </td>
                                    )}
                                    {columnList.password && (
                                        <td onClick={() => {
                                            setSortedColumnNameMap("password")
                                            if (sortedTypeMap === ""){
                                                setSortedTypeMap("1")
                                            } else if (sortedTypeMap === "1"){
                                                setSortedTypeMap("2")
                                            } else {
                                                setSortedTypeMap("")
                                            }
                                        }}>
                                            password
                                            <span className="sort-icon">
                                            {sortedTypeMap === "1" && sortedColumnNameMap === "password" ? <FaSortUp /> : sortedTypeMap === "2" && sortedColumnNameMap === "password" ? <FaSortDown /> : sortedTypeMap === "" || sortedColumnNameMap !== "password" ? <FaSort /> : null}
                                            </span>
                                        </td>
                                    )}
                                    <td> </td>
                                </tr>
                            </thead>
                            <tbody>
                                {getMapsRequestStatus && (
                                    <>
                                        {paginatedMaps.length > 0 && (
                                            <>
                                                {paginatedMaps.map((map, index) => (
                                                    <tr className={checkedMaps.includes(map.id) ? "checked" : null} key={index}>
                                                        <td className="left input"><input checked={checkedMaps.includes(map.id)} onChange={() => !checkedMaps.includes(map.id) ? setCheckMaps(checkedMaps => [...checkedMaps, map.id]) : setCheckMaps(checkedMaps.filter(v => v !== map.id))} type="checkbox"/></td>
                                                        <td className="left large">{map.name}</td>
                                                        {columnList.theme && (
                                                            <td>{map.theme}</td>
                                                        )}
                                                        {columnList['created on'] && (
                                                            <td>{map.createdAt}</td>
                                                        )}
                                                        {columnList['last modified'] && (
                                                            <td>NONE</td>
                                                        )}
                                                        {columnList.size && (
                                                            <td>{map.size}</td>
                                                        )}
                                                        {columnList.users && (
                                                            <td>{map.collaborator.length > 0 ? <p onClick={() => {
                                                                setViewUserOnMapId(map.id)
                                                                setViewUserOnMapModalStatus(true)
                                                            }} className="info-clic">{map.collaborator.length}</p> : 0}</td>
                                                        )}
                                                        {columnList.objects && (
                                                            <td>{map.objects.length > 0 ? <p onClick={() => {
                                                                setViewObjectMapId(map.id)
                                                                setViewObjectOnMapModalStatus(true)
                                                            }} className="info-clic">{map.objects.length}</p> : 0}</td>
                                                        )}
                                                        {columnList['from template'] && (
                                                            <td>{map.template ? "Yes": "No"}</td>
                                                        )}
                                                        {columnList.access && (
                                                            <td>{map.visibility ? "Yes": "No"}</td>
                                                        )}
                                                        {columnList.password && (
                                                            <td>{map.password ? "Yes": "No"}</td>
                                                        )}
                                                        <td className={openedSubMenuMapId === map.id ? "sub-menu open" : "sub-menu"}>
                                                            <p onClick={() => openedSubMenuMapId === 0 ? setOpenedSubMenuMapId(map.id) : openedSubMenuMapId !== map.id ? setOpenedSubMenuMapId(map.id) : setOpenedSubMenuMapId(0)} className="btn"><BsThreeDots /></p>
                                                            <div className="menu" >
                                                                <p>Copy map URL</p>
                                                                <p>See map</p>
                                                                {map.password && (
                                                                    <p onClick={() => {
                                                                        setDeletedMapPasswordId(map.id)
                                                                        setDeleteMapPasswordStatus(false)
                                                                        setDeleteMapPasswordModalStatus(true)
                                                                    }}>Delete map password</p>
                                                                )}
                                                                <p onClick={() => {
                                                                    if (map.deleteDate === null){
                                                                        setDeletedMapId(map.id)
                                                                        setDeleteMapStatus(false)
                                                                        setDeleteMapModalStatus(true)
                                                                    }
                                                                }}>{map.deleteDate !== null ? "Delete at " + map.deleteDate : "Delete map"} </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                        {paginatedMaps.length < 1 && (
                                            <tr>
                                                <td style={{'textAlign': 'center'}} colSpan="100%">No maps</td>
                                            </tr>
                                        )}
                                    </>
                                )}
                                {!getMapsRequestStatus && (
                                    <tr>
                                        <td colSpan="100%"><TailSpin height="64" width="64" color="#000" /></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPage(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPage}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtons
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPage}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedMaps.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="top-btn" onClick={() => dashboard.scrollTo(0, 0)}>
                            <p><FaChevronUp /></p>
                        </div>
                        <p className="copy">&copy; Weeki 2021</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardMaps