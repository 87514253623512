import MyBoards from "./pages/Dashboard/User/MyBoards";
import React from "react";
import {ReactComponent as MyBoardsIcon} from "../icons/sidebar/my-boards.svg"
import {ReactComponent as WorkspaceIcon} from "../icons/sidebar/workspace_icon.svg"
import {PiBookBold, PiHouseLineBold, PiShareFatBold, PiUsersBold} from "react-icons/pi";
import Home from "./pages/Dashboard/User/Home";
import TeamWorkspaces from "./pages/Dashboard/User/TeamWorkspaces";
import Story from "./pages/Dashboard/User/Story";
import SharedWithMe from "./pages/Dashboard/User/SharedWithMe";
import AdminHome from "./pages/Dashboard/Admin/AdminHome";
import AdminStories from "./pages/Dashboard/Admin/AdminStories";
import {useObjectTableStore} from "./services/Stores/ObjectTableStore";

export const DASHBOARD_MAP = {
	'user': {
		'home': {
			'route': "/user/dashboard/home",
			'name': "Home",
			'component': <Home />,
			'icon': <PiHouseLineBold  fontSize="1.2em" />,
			'default': true,
			'noPadding': false
		},
		'projects': {
			'route': "/user/dashboard/projects",
			'name': "My projects",
			'component': <MyBoards />,
			'icon': <MyBoardsIcon />,
			'default': false,
			'noPadding': false
		},
		'workspaces': {
			'route': "/user/dashboard/workspaces",
			'name': "Team workspaces",
			'component': <TeamWorkspaces />,
			'icon': <WorkspaceIcon />,
			'default': false,
			'noPadding': true
		},
		'shared-maps': {
			'route': "/user/dashboard/shared-maps",
			'name': "Shared with me",
			'component': <SharedWithMe />,
			'icon': <PiUsersBold size={20} />,
			'default': false,
			'noPadding': false
		},
		'story': {
			'route': "/user/dashboard/story",
			'name': "Stories",
			'component': <Story />,
			'icon': <PiBookBold size={20} />,
			'default': false,
			'noPadding': false
		},
		// 'objects-table': {
		// 	'name': "Object table",
		// 	'icon': <MyBoardsIcon />,
		// 	'action': () => useObjectTableStore.setState({openState: true}),
		// 	'default': false,
		// 	'noPadding': false
		// },
	},
	'admin': {
		'home': {
			'route': "/admin/dashboard/home",
			'name': "Home",
			'component': <AdminHome />,
			'icon': <PiHouseLineBold  fontSize="1.2em" />,
			'default': true,
			'noPadding': false
		},
		'stories': {
			'route': "/admin/dashboard/stories",
			'name': "Stories",
			'component': <AdminStories />,
			'icon': <PiBookBold size={20} />,
			'default': true,
			'noPadding': false
		},
	}
}