import getAxiosInstance from "../../../AxiosInstance";
import {USER_MOOC_API} from "../../../../../Router";

function userGetMapMoocs(mapUuid){
	return getAxiosInstance().get(USER_MOOC_API + '/get_all_map_moocs', {params: {mapUuid: mapUuid}})
}

function userGetUserMoocs(){
	return getAxiosInstance().get(USER_MOOC_API + '/get_all_user_moocs')
}

function userGetAllMoocInvitations(moocUuid){
	return getAxiosInstance().get(USER_MOOC_API + '/get_all_invitations', {params: {moocUuid: moocUuid}})
}

function userCreateMooc(mapUuid){
	const sendData = new FormData()
	sendData.append("mapUuid", mapUuid)

	return getAxiosInstance().post(USER_MOOC_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data
			}
			return false
		})
		.catch(() => {
			return false
		})
}

function userUploadMoocCourseFile(moocUuid, course){
	const sendData = new FormData()
	sendData.append('moocUuid', moocUuid)
	sendData.append('course', course)

	return getAxiosInstance().post(USER_MOOC_API + '/upload_course_file', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userUpdateMooc(moocUuid, datas){
	const sendData = new FormData()
	sendData.append("moocUuid", moocUuid)

	Object.keys(datas).forEach(key => {
		sendData.append(key, datas[key])
	})

	return getAxiosInstance().post(USER_MOOC_API + '/update', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function userInviteUser(moocUuid, userEmail, invitationType){
	const sendData = new FormData()
	sendData.append("moocUuid", moocUuid)
	sendData.append("userEmail", userEmail)
	sendData.append('invitationType', invitationType)

	return getAxiosInstance().post(USER_MOOC_API + '/invite_user', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.uuid
			}

			return false
		})
		.catch(() => {
			return false
		})
}

function userRevokeUser(moocUuid, invitationUuid){
	const sendData = new FormData()
	sendData.append("moocUuid", moocUuid)
	sendData.append("invitationUuid", invitationUuid)

	return getAxiosInstance().post(USER_MOOC_API + '/revoke_user', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function userUpdateThumbnail(uuid, thumbnail){
	const sendData = new FormData()
	sendData.append("moocUuid", uuid)
	sendData.append("thumbnail", thumbnail)

	return getAxiosInstance().post(USER_MOOC_API + '/update_thumbnail', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.thumbnail
			}

			return false
		})
		.catch(() => {
			return false
		})
}

function userRenameMooc(uuid, name){
	const sendData = new FormData()
	sendData.append("moocUuid", uuid)
	sendData.append("name", name)

	return getAxiosInstance().post(USER_MOOC_API + '/rename', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userUpdateMoocOverviewName(uuid, name){
	const sendData = new FormData()
	sendData.append("moocUuid", uuid)
	sendData.append("name", name)

	return getAxiosInstance().post(USER_MOOC_API + '/update_overview_name', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userDeleteMooc(uuid){
	return getAxiosInstance().delete(USER_MOOC_API + '/delete', {params: {uuid: uuid}})
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

export default {
	userGetMapMoocs,
	userGetUserMoocs,
	userGetAllMoocInvitations,
	userCreateMooc,
	userUpdateMooc,
	userInviteUser,
	userRevokeUser,
	userUpdateThumbnail,
	userRenameMooc,
	userDeleteMooc,
	userUpdateMoocOverviewName,
	userUploadMoocCourseFile
}