import React, {Fragment, useRef, useState} from "react";
import {useNotificationStore} from "../../../services/Stores/NotificationsStore";
import {IoMdNotificationsOutline} from "react-icons/io";
import MapInvitationNotification from "./MapInvitationNotification";
import UserNotificationAPI from "../../../services/API/User/UserNotificationAPI";
import {useOnClickOutside} from "../../../services/Utils";
import WorkspaceInvitationNotification from "./WorkspaceInvitationNotification";
import MoocInvitationNotification from "./MoocInvitationNotification";
import SubscriptionInvitationNotification from "./SubscriptionInvitationNotification";

const NotificationMenuContainer = () => {
	//Define menu default state
	const [openState, setOpenState] = useState(false)
	const [currentNotificationFlow, setCurrentNotificationFlow] = useState('all')

	//Define ref and allow close menu on click outside ref
	const menuRef = useRef(null)

	//Get data from store
	const unReadNotificationsCount = useNotificationStore(state => state.unReadNotificationsCount)
	const notifications = useNotificationStore(state => state.notifications)
	const readAllNotifications = useNotificationStore(state => state.readAllNotification)

	const handleReadAllNotification =  () => {
		//Check if user has unread notification
		if (notifications.length > 0 && notifications.filter(n => !n.read).length > 0) {
			readAllNotifications()
			UserNotificationAPI.readAllUserNotification().then()
		}
	}

	return (
		<div className="custom__notification-menu-container">
			<div className={openState ? "notification-btn active" : "notification-btn"} onClick={() => setOpenState(!openState)}>
				<IoMdNotificationsOutline size={24}/>
				{unReadNotificationsCount > 0 && (
					<span className="notif-count">{unReadNotificationsCount}</span>
				)}
			</div>
			{openState && (
				<div className="notification-menu" ref={menuRef}>
					<div className="header">
						<h1>Notifications</h1>
						<p className="read-all-btn" onClick={handleReadAllNotification}>Mark all as read</p>
					</div>
					<div className="notification-flow-selector">
						<p className={currentNotificationFlow === 'all' ? 'selected' : ''}>All</p>
						<p>Following</p>
						<p>Archive</p>
					</div>
					<div className="notification-flow">
						{notifications.length > 0 && (
							<>
								{notifications.map((notif, index) => (
									<Fragment key={index}>
										{notif.type === "map_invitation" && (
											<>
												<MapInvitationNotification notification={notif} />
											</>
										)}
										{notif.type === "workspace_invitation" && (
											<>
												<WorkspaceInvitationNotification notification={notif} />
											</>
										)}
										{notif.type === "mooc_invitation" && (
											<>
												<MoocInvitationNotification notification={notif} />
											</>
										)}
										{notif.type === "subscription_invitation" && (
											<>
												<SubscriptionInvitationNotification notification={notif} />
											</>
										)}
									</Fragment>
								))}
							</>
						)}
						{notifications.length <= 0 && (
							<p className="no-notification-text">Nothing here !</p>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default NotificationMenuContainer