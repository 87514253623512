import {USER_AREA_API} from "../../Router";
import {generateRandomId, hexToRGB} from "./Utils";
import getAxiosInstance from "./AxiosInstance";

function getMapAreas(mapUuid){
    return getAxiosInstance().get(USER_AREA_API + '/get_map_areas', {params: {mapUuid: mapUuid}})
}

function getChildAreas(uuid){
    return getAxiosInstance().get(USER_AREA_API + '/get_child_areas', {params: {uuid: uuid}})
}

function moveArea(uuid, centerPoint, targetZoom = 0, originZoom = 0){
    const sendData = new FormData()

    sendData.append('uuid', uuid)
    sendData.append('x', centerPoint.x)
    sendData.append('y', centerPoint.y)
    sendData.append('targetZoom', String(targetZoom))
    sendData.append('originZoom', String(originZoom))

    return getAxiosInstance().post(USER_AREA_API + '/move', sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function copyArea(uuid, centerPoint, targetZoom = 0, originZoom = 0){
    const sendData = new FormData()

    sendData.append('uuid', uuid)
    sendData.append('x', centerPoint.x)
    sendData.append('y', centerPoint.y)
    sendData.append('targetZoom', String(targetZoom))
    sendData.append('originZoom', String(originZoom))

    return getAxiosInstance().post(USER_AREA_API + '/copy', sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function resizeArea(uuid, centerPoint, width, height){
    const sendData = new FormData()

    sendData.append('uuid', uuid)
    sendData.append('x', centerPoint.x)
    sendData.append('y', centerPoint.y)
    sendData.append('width', width)
    sendData.append('height', height)

    return getAxiosInstance().post(USER_AREA_API + '/resize', sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

// function copyArea(uuid, bounds, type){
//     const sendData = new FormData()
//
//     sendData.append('uuid', uuid)
//     sendData.append('x0', bounds.x0)
//     sendData.append('y0', bounds.y0)
//     sendData.append('x1', bounds.x1)
//     sendData.append('y1', bounds.y1)
//     sendData.append('type', type)
//
//     return getAxiosInstance().post(USER_AREA_API + '/copy', sendData)
//         .then((response) => {
//             if (response){
//                 if (response.status === 200){
//                     return true
//                 }
//             }
//         })
// }

function renameArea(uuid, newName){
    const sendData = new FormData()

    sendData.append('uuid', uuid)
    sendData.append('name', newName)

    return getAxiosInstance().post(USER_AREA_API + '/rename', sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function createArea(overlayUuid, x0, y0, x1, y1, backgroundColor, borderColor, borderWidth, opacity){
    const sendData = new FormData()
    const updateId = generateRandomId()

    sendData.append('overlayUuid', overlayUuid)
    sendData.append('x0', x0)
    sendData.append('y0', y0)
    sendData.append('x1', x1)
    sendData.append('y1', y1)
    sendData.append('backgroundColor', hexToRGB(backgroundColor))
    sendData.append('borderColor', hexToRGB(borderColor))
    sendData.append('borderWidth', borderWidth)
    sendData.append('opacity', opacity)
    sendData.append('updateId', updateId)

    return getAxiosInstance().post(USER_AREA_API + '/create', sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function updateArea(areaUuid, borderColor = null, borderWidth = null, backgroundColor = null, opacity = null){
    const sendData = new FormData()

    sendData.append('uuid', areaUuid)
    if (borderColor){
        sendData.append('borderColor', borderColor)
    }
    if (borderWidth){
        sendData.append('borderWidth', borderWidth)
    }
    if (backgroundColor){
        sendData.append('backgroundColor', backgroundColor)
    }
    if (opacity !== null){
        sendData.append('opacity', opacity)
    }

    return getAxiosInstance().post(USER_AREA_API + '/update', sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
}

function deleteAreas(areasUuid){
    const sendData = []

    areasUuid.forEach(uuid => {
        sendData.push(uuid.area)
    })

    return getAxiosInstance().delete(USER_AREA_API + "/delete", {
        params: {
            uuids: sendData
        }
    })
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
}

function deleteArea(uuid){
    const sendData = []
    sendData.push(uuid)

    return getAxiosInstance().delete(USER_AREA_API + "/delete", {
        params: {
            uuids: sendData
        }
    })
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
}

function exportAreas(areasUuid){
    const sendData = new FormData()

    areasUuid.forEach(uuid => {
        sendData.append('uuids[]', uuid.area)
    })

    return getAxiosInstance().post(USER_AREA_API + "/export", sendData)
}

function updateAreasOverlay(areasUuid, overlayUuid){
    const sendData = new FormData()

    sendData.append('overlayUuid', overlayUuid)
    areasUuid.forEach(areaUuid => {
        sendData.append('areasUuid[]', areaUuid.area)
    })

    return getAxiosInstance().post(USER_AREA_API + '/update_overlay', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
}

export default {
    getMapAreas,
    moveArea,
    createArea,
    updateArea,
    renameArea,
    deleteAreas,
    exportAreas,
    updateAreasOverlay,
    copyArea,
    getChildAreas,
    deleteArea,
    resizeArea
}