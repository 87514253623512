import React, {useRef} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {createErrorToast} from "../../../services/CustomToastUtils";
import UserMapAPI from "../../../services/API/User/UserMapAPI";
import MapAPI from "../../../services/MapAPI";
import {useHistory} from "react-router-dom";

const CreateMapPopup = ({state, setState}) => {
	//Get data in store
	const currentOpenedWorkspace = useDashboardStore(state => state.currentOpenedWorkspace)
	const currentOpenedProject = useDashboardStore(state => state.currentOpenedProject)

	//Get history
	const history = useHistory()

	const mapNameInputRef = useRef(null)
	const inviteWorkspaceInputRef = useRef(null)

	const handleCreateMap = async () => {
		const mapName = mapNameInputRef.current.value
		let inviteWorkspace = false;

		if (currentOpenedWorkspace){
			inviteWorkspace = inviteWorkspaceInputRef.current.checked
		}

		//Check if map name is valid
		if (mapName.length <= 0) {
			createErrorToast("Board name can't be empty")
			return
		}

		setState(false)

		const uuid = await MapAPI.createNewMap(mapName, inviteWorkspace, currentOpenedProject.uuid)

		if (uuid) {
			history.push('/user/map/' + uuid)
		}
	}

	if (state) {
		return createPortal(
			<div className="custom__dashboard-popup-overlay">
				<div className="container">
					<div className="popup-header">
						<h1 className="popup-title">Name your board</h1>
						<p className="popup-close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
					</div>
					<div className="popup-content">
						<div className="input-container">
							<input ref={mapNameInputRef} type="text" placeholder="Enter board name" autoFocus={true} onKeyDown={(e) => e.key === "Enter" ? handleCreateMap() : null}/>
						</div>
						{currentOpenedWorkspace && (
							<div className="permission-list-container">
								<label><input ref={inviteWorkspaceInputRef} type="checkbox" defaultChecked={true}/>Automatically invite every Workspace team member.</label>
							</div>
						)}
					</div>
					<div className="popup-footer">
						<p className="popup-btn" onClick={() => setState(false)}>Cancel</p>
						<p className="popup-btn valid" onClick={handleCreateMap}>Create board</p>
					</div>
				</div>
			</div>
			, document.body)
	}

	return null
}

export default CreateMapPopup