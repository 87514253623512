import {create} from "zustand";

export const useSidebarMapContentItemStore = create((set) => ({
	state: false,
	properties: null,
	data: {},
	tags: [],
	currentPanel: "info",
	updateItemName: (name) => set((state) => ({
		data: {...state.data, name: name}
	})),
	updateItemNote: (note) => set((state) => ({
		data: {...state.data, note: note}
	})),
	updateItemThumbnailUrl: (url) => set((state) => ({
		data: {...state.data, thumbnailUrl: url}
	})),
	updateItemAttribute: (attributes) => set((state) => ({
		data: {...state.data, attributes: attributes}
	})),
	updateItemColor: (color) => set((state) => ({
		data: {...state.data, color: color}
	})),
	addLinkedFile: (fileData) => set((state) => ({
		data: {...state.data, linkedFiles: state.data.linkedFiles !== null ? [...state.data.linkedFiles , fileData] : [fileData]}
	})),
	removeLinkedFile: (key) => set((state) => ({
		data: {...state.data, linkedFiles: state.data.linkedFiles.filter(f => f.key !== key)}
	})),
	addItemTag: (tagData) => set((state) => ({
		tags: [...state.tags, tagData]
	})),
	removeItemTag: (uuid) => set((state) => ({
		tags: state.tags.filter(t => t.uuid !== uuid)
	})),
	addAssociatedItem: (itemData) => set((state) => ({
		data: {...state.data, associatedItems: [...state.data.associatedItems, itemData]}
	})),
	removeAssociatedItem: (uuid) => set((state) => ({
		data: {...state.data, associatedItems: state.data.associatedItems.filter(ai => ai.uuid !== uuid)}
	})),
	resetStore: () => set((state) => ({
		state: false,
		properties: null,
		currentPanel: 'info',
		data: {},
		tags: []
	}))
}))