import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import React from "react";
import AddTextItemPopup from "../../components/Map/ObjectPopup/AddTextItemPopup";
import {useMapStore} from "../Stores";
import TextItemPopup from "../../components/Map/ObjectPopup/TextItemPopup";
import Level1Popup from "../../components/Map/ObjectPopup/Level1Popup";
import TestObjectPopup from "../../components/Map/ObjectPopup/TestObjectPopup";
import SelectedObjectMenu from "../../components/Map/ObjectPopup/SelectedObjectMenu";
import {useContentItemStore} from "../Stores/ContentItemStore";
import {useTextItemStore} from "../Stores/TextItemStore";
import {ScrollablePopupMixin} from "./RenderLevel2Utils";
import {updateSelectedMenuPosition} from "./MapSelectedMenusUtils";
import {addTextItemPopupEvents} from "./PopupEventsUtils";

export function generateTextItemAddPopup(x, y){
	return L.popup({
		autoPan: false,
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		pane: "textPane"
	})
		.setLatLng(useMapStore.getState().mapInstance.layerPointToLatLng(L.point(x, y)))
		.setContent(ReactDOMServer.renderToString(<AddTextItemPopup/>))
		.openOn(useMapStore.getState().mapInstance)
}

export function generateSelectedPopup(position, parentWidth, parentHeight, properties, text = false){
	//Check if selected popup already exist
	if (text){
		if (useTextItemStore.getState().selectedItemPopup !== null){
			useTextItemStore.getState().selectedItemPopup.remove()
			useTextItemStore.setState({selectedItemPopup: null})
		}
	} else {
		if (useContentItemStore.getState().selectedItemPopup !== null){
			useContentItemStore.getState().selectedItemPopup.remove()
			useContentItemStore.setState({selectedItemPopup: null})
		}
	}

	return L.popup({
		autoPan: false,
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		offset: L.point(-(parentWidth / 2), -(parentHeight + 13))
	})
		.setLatLng(position)
		.setContent(ReactDOMServer.renderToString(<SelectedObjectMenu itemProperties={properties} text={text}/>))
}

export function generateTextItemPopup(latLng, text, size){
	return L.popup({
		autoPan: false,
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		pane: "textPane"
	})
		.setLatLng(latLng)
		.setContent(ReactDOMServer.renderToString(<TextItemPopup text={text} size={size} selected={false}/>))
}

export function generateLevel1Popup(latLng, icon){
	const ScrollablePopup = ScrollablePopupMixin()

	return new ScrollablePopup({
		autoPan: false,
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		offset: L.point(0, 0)
	})
		.setLatLng(latLng)
		.setContent(ReactDOMServer.renderToString(<Level1Popup iconCode={icon}/>))
		.openOn(useMapStore.getState().mapInstance)
}

export function generateLevel2Popup(latLng, feature, small = false, thumbnailUrl = ""){
	const ScrollablePopup = ScrollablePopupMixin()
	const popup =  new ScrollablePopup({
			autoPan: false,
			closeButton: false,
			autoClose: false,
			closeOnClick: false,
			pane: "itemPane"
		})

			.setLatLng(latLng)
			.setContent(ReactDOMServer.renderToString(<TestObjectPopup thumbnailUrl={thumbnailUrl} loader={feature.properties['thumbnail_url'] !== undefined} item={feature.properties} selected={false} small={small} />))

	popup._initLayout()

	return popup
}

export function updateRenderedTextItemPopup(uuid){
	//Get item in store
	const item = useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === uuid)[0]
	const selectedTextItem = useTextItemStore.getState().currentSelectedItem

	if (item){
		item.popup.setContent(ReactDOMServer.renderToString(<TextItemPopup text={item.properties.text} size={item.properties.size} selected={item.uuid === selectedTextItem} />))
		addTextItemPopupEvents(item.popup, {properties: item.properties})
	}
}