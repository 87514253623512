import {useMapContextMenuStore} from "../Stores/MapContextMenuStore";
import {useMapStore} from "../Stores";
import L from "leaflet"
import UserMapAPI from "../API/User/UserMapAPI";

export function updateMapCenter(){
	//Get position
	const position = useMapContextMenuStore.getState().position

	//Close all context menu
	useMapContextMenuStore.getState().resetStore()

	//Convert container point to lat lng
	const latLng = useMapStore.getState().mapInstance.containerPointToLatLng(L.point(position.x, position.y))

	//Get map current zoom
	const zoom = Math.floor(parseFloat(useMapStore.getState().mapInstance.getZoom()))

	//Update map center in api
	UserMapAPI.userUpdateMapCenter(useMapStore.getState().mapUuid, latLng, zoom)


}

export function flyToCoordinates(x, y, zoom){
	//Convert x y to lat lng
	const latLng = L.Projection.SphericalMercator.unproject(L.point(x, y))

	//Fly to coordinates and zoom
	useMapStore.getState().mapInstance.flyTo(latLng, zoom, {
		animate: true,
		duration: 2
	})
}