import React, {useState} from "react";
import {toast} from "react-toastify";
import UserAPI from "../services/UserAPI";
import AuthAPI from "../services/AuthAPI";

const UpdateTempPasswordPopup = ({user}) => {
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("")

    const handleUpdatePassword = async () => {
        if (newPassword === "" || newPasswordConfirm === "") {
            toast.error("New password cannot be empty")
            return
        }

        if (newPassword !== newPasswordConfirm) {
            toast.error("Password doesn't match")
            return
        }

        if (await UserAPI.updateUserPassword2(newPassword, newPasswordConfirm)) {
            toast.success("Password updated")
            user.tempPassword = false
        }
    }

    const handleLogout = () => {
        AuthAPI.logout()
        window.location.href = "/"
    }

    return (
        <>
            <div className="update-temp-password-overlay">
                <div className="update-temp-password-container">
                    <h1 className="title">Update your password</h1>
                    <div className="input-container">
                        <input value={newPassword} onChange={(event) => setNewPassword(event.currentTarget.value)} type="password" placeholder="New password"/>
                        <input value={newPasswordConfirm} onChange={(event) => setNewPasswordConfirm(event.currentTarget.value)} type="password" placeholder="Confirmation"/>
                    </div>
                    <div className="button-container">
                        <p className="button red" onClick={handleLogout}>Disconnect</p>
                        <p className="button" onClick={handleUpdatePassword}>Update password</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateTempPasswordPopup