import React, {Fragment, useEffect, useState} from "react";
import {AiOutlineSearch} from "react-icons/ai";
import {BiExport} from "react-icons/bi";
import {FaChevronDown, FaChevronUp, FaLayerGroup} from "react-icons/fa";
import { PiPencilSimpleBold } from "react-icons/pi";
import { PiPaperPlaneTiltBold } from "react-icons/pi";
import { PiInfoBold } from "react-icons/pi";
import { PiEyeBold } from "react-icons/pi";
import { PiEyeSlashBold } from "react-icons/pi";
import { PiTrashBold } from "react-icons/pi";
import {MdContentCopy, MdEdit, MdOutlineDriveFileMove} from "react-icons/md";
import {VscChromeClose} from "react-icons/vsc";
import Skeleton from "react-loading-skeleton";
import OverlayAPI from "../../services/OverlayAPI";
import {useCookies} from "react-cookie";
import AreaAPI from "../../services/AreaAPI";
import L from 'leaflet'
import {toast} from "react-toastify";
import {useMapStore} from "../../services/Stores";
import PublicsOverlayAPI from "../../services/API/Publics/PublicsOverlayAPI";
import {PUBLICS_OVERLAY} from "../../../Router";
import PublicsMapAPI from "../../services/API/Publics/PublicsMapAPI";
import PublicsAreaAPI from "../../services/API/Publics/PublicsAreaAPI";
import OverlayRow from "../customs/overlay_zone_sidebar/OverlayRow";
import {BsTrash} from "react-icons/bs";
import {IoIosInformationCircleOutline} from "react-icons/io";
import {createErrorToast} from "../../services/CustomToastUtils";

const ManageOverlayAndZoneSideMenu = ({state, setState, mapUuid, currentOverlay, setCurrentOverlay, visibleOverlayList, setVisibleOverlayList, publicMap= false}) => {
    //Get cookie
    const [cookies, setCookie] = useCookies(['maps-default-overlay'])

    const mapInstance = useMapStore(state1 => state1.mapInstance)
    const overlays = useMapStore(state1 => state1.overlays)

    // const hideOverlay = (overlayUuid) => {
    //     setVisibleOverlayList(visibleOverlayList.filter(o => o !== overlayUuid))
    // }
    //
    // const showOverlay = (overlayUuid) => {
    //     setVisibleOverlayList([...visibleOverlayList, overlayUuid])
    // }
    //
    // const [areaNameChangeUuid, setAreaNameChangeUuid] = useState("")
    // const [newAreaName, setNewAreaName] = useState("")
    // const startAreaNameChange = (overlayUuid, areaUuid) => {
    //     const overlayList = [...overlays]
    //     const overlay = overlayList.filter(o => o.uuid === overlayUuid)[0]
    //     const area = overlay.areas.filter(a => a.uuid === areaUuid)[0]
    //
    //     setNewAreaName(area.name)
    //     setAreaNameChangeUuid(areaUuid)
    // }
    //
    // const handleValidAreaName = async (overlayUuid) => {
    //     if (await AreaAPI.renameArea(areaNameChangeUuid, newAreaName)){
    //         const overlayList = [...overlays]
    //         const overlay = overlayList.filter(o => o.uuid === overlayUuid)[0]
    //         const area = overlay.areas.filter(a => a.uuid === areaNameChangeUuid)[0]
    //         area.name = newAreaName
    //
    //         setOverlays(overlayList)
    //         setNewAreaName("")
    //         setAreaNameChangeUuid("")
    //     }
    // }
    //
    // const detectValidAreaName = (event, overlayUuid) => {
    //     if (event.key === "Enter"){
    //         handleValidAreaName(overlayUuid).then()
    //     }
    // }

    // const [selectedAreas, setSelectedAreas] = useState([])
    //
    // const handleSelectArea = (areaUuid, overlayUuid) => {
    //     setSelectedAreas([...selectedAreas, {area: areaUuid, overlay: overlayUuid}])
    // }
    //
    // const handleUnselectArea = (areaUuid) => {
    //     setSelectedAreas(selectedAreas.filter(a => a.area !== areaUuid))
    //
    // }
    //
    // const unselectAllAreas = () => {
    //     setSelectedAreas([])
    // }
    //
    // useEffect(() => {
    //     if (selectedAreas.length === 0){
    //         setMoveAreasMenuStatus(false)
    //     }
    // }, [selectedAreas])
    //
    // const handleDeleteAreas = async () => {
    //     if (await AreaAPI.deleteAreas(selectedAreas)){
    //         //Get overlay areas for all opened overlay
    //         const overlayList = [...overlays]
    //
    //         selectedAreas.forEach(sa => {
    //             let overlay = overlayList.filter(o => o.uuid === sa.overlay)[0]
    //             overlay.areas = overlay.areas.filter(a => a.uuid !== sa.area)
    //         })
    //
    //         setSelectedAreas([])
    //         setOverlays(overlayList)
    //     }
    // }
    //
    // const handleDeleteArea = async (event, areaUuid, overlayUuid) => {
    //     event.stopPropagation()
    //     if (await AreaAPI.deleteAreas([areaUuid])){
    //         const overlayList = [...overlays]
    //         const overlay = overlayList.filter(o => o.uuid === overlayUuid)[0]
    //         overlay.areas = overlay.areas.filter(a => a.uuid !== areaUuid)
    //
    //         setOverlays(overlayList)
    //     }
    // }

    // const handleExportAreas = async () => {
    //     await AreaAPI.exportAreas(selectedAreas).then((response) => {
    //         if (response.status === 200){
    //             const exportData = response.data.export
    //
    //             //Generate filename
    //             const filename = 'weeki_areas_' + new Date().toISOString() + '.weeki'
    //
    //             //Generate download link
    //             const link = document.createElement("a")
    //             link.id = "download-export"
    //             link.setAttribute('href', 'data:text/plain;charset=utf-8,' + exportData)
    //             link.setAttribute('download', filename)
    //             document.body.appendChild(link)
    //             document.querySelector('#download-export').click()
    //
    //             document.body.removeChild(link)
    //         }
    //     })
    // }
    //
    // const [moveAreasMenuStatus, setMoveAreasMenuStatus] = useState(false)
    // const [searchMoveArea, setSearchMoveArea] = useState("")
    //
    // const searchedOverlayMoveArea = overlays.filter(o =>
    //     String(o.name).toLowerCase().includes(searchMoveArea.toLowerCase())
    // )
    //
    // const handleMoveAreas = async (overlayUuid) => {
    //     if (await AreaAPI.updateAreasOverlay(selectedAreas, overlayUuid)){
    //         let overlayList = [...overlays]
    //         let newOverlay = overlayList.filter(o => o.uuid === overlayUuid)[0]
    //
    //         newOverlay.open = true
    //         setOverlays(overlayList)
    //
    //         for (const sa of selectedAreas) {
    //             overlayList = [...overlays]
    //             let oldOverlay = overlayList.filter(o => o.uuid === sa.overlay)[0]
    //             oldOverlay.areas = oldOverlay.areas.filter(a => a.uuid !== sa.area)
    //
    //             let selectedList = [...selectedAreas]
    //             let currentSelectedArea = selectedList.filter(s => s.area === sa.area)[0]
    //             currentSelectedArea.overlay = newOverlay.uuid
    //
    //             setSelectedAreas(selectedList)
    //             setOverlays(overlayList)
    //         }
    //
    //         overlayList = [...overlays]
    //         newOverlay = overlayList.filter(o => o.uuid === overlayUuid)[0]
    //         await OverlayAPI.getOverlayAreas(overlayUuid).then((response) => {
    //             if (response.status === 200){
    //                 newOverlay.areas = response.data
    //             } else {
    //                 newOverlay.areas = []
    //             }
    //         })
    //
    //         setOverlays(overlayList)
    //
    //
    //     }
    // }

    // const [draggedArea, setDraggedArea] = useState({overlay: "", area: ""})
    //
    // const handleAreaDragStart = (event, areaUuid, overlayUuid) => {
    //     setDraggedArea({overlay: overlayUuid, area: areaUuid})
    //
    //     //Create drag element
    //     // const dragImage = document.createElement('div')
    //     // dragImage.id = "area-drag-image"
    //     // dragImage.textContent = selectedAreas.length + " selected areas"
    //     // dragImage.style.position = "absolute"
    //     // dragImage.style.top = '-1000px'
    //     // document.body.appendChild(dragImage)
    //     // event.dataTransfer.setDragImage(dragImage, 0, 0)
    // }
    //
    // const handleAreaDragEnd = (event) => {
    //     const dragImage = document.getElementById('area-drag-image')
    //     if (dragImage.parentNode){
    //         dragImage.parentNode.removeChild(dragImage)
    //     }
    // }

    // const handleDropArea = async (event) => {
    //     const overlayUuid = event.currentTarget.id
    //
    //     if (await AreaAPI.updateAreasOverlay([draggedArea], overlayUuid)){
    //         let overlayList = [...overlays]
    //         let newOverlay = overlayList.filter(o => o.uuid === overlayUuid)[0]
    //
    //         newOverlay.open = true
    //         setOverlays(overlayList)
    //
    //
    //         overlayList = [...overlays]
    //         let oldOverlay = overlayList.filter(o => o.uuid === draggedArea.overlay)[0]
    //         oldOverlay.areas = oldOverlay.areas.filter(a => a.uuid !== draggedArea.area)
    //
    //         setOverlays(overlayList)
    //
    //         overlayList = [...overlays]
    //         newOverlay = overlayList.filter(o => o.uuid === overlayUuid)[0]
    //         await OverlayAPI.getOverlayAreas(overlayUuid).then((response) => {
    //             if (response.status === 200){
    //                 newOverlay.areas = response.data
    //             } else {
    //                 newOverlay.areas = []
    //             }
    //         })
    //
    //         setOverlays(overlayList)
    //     }
    // }

    // const [createOverlayStatus, setCreateOverlayStatus] = useState(false)
    // const [createOverlayName, setCreateOverlayName] = useState("")
    //
    const handleCreateNewOverlay = async (event) => {
        const overlayName = event.currentTarget.value
        if (overlayName.length <= 0){
            createErrorToast("Invalid overlay name")
            return
        }


        const uuid = await OverlayAPI.createOverlay(mapUuid, overlayName)

        if (uuid){
            event.target.value = ""
            useMapStore.getState().addNewOverlay({uuid: uuid, name: overlayName, areasCount: 0, areas: null, open: false, visible: true, active: true, default: false})
        }
    }

    if (state) {
        return (
            <>
                <div className="manage-side-menu-container">
                    <div className="header">
                        <p className="map-name">Overlays and zones</p>
                        <p className="close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
                    </div>
                    {/*{!publicMap && (*/}
                    {/*    <>*/}
                    {/*        {selectedAreas.length > 0 && (*/}
                    {/*            <div className="area-selected-menu">*/}
                    {/*                <div className="left">*/}
                    {/*                    <p className="selected-count">{selectedAreas.length}</p>*/}
                    {/*                    <p className="text">Selected</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="middle">*/}
                    {/*                    <div className="button" onClick={handleExportAreas}>*/}
                    {/*                        <p className="icon"><BiExport /></p>*/}
                    {/*                        <p className="text">Export</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="button">*/}
                    {/*                        <p className="icon"><MdContentCopy /></p>*/}
                    {/*                        <p className="text">Copy</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="button" onClick={handleDeleteAreas}>*/}
                    {/*                        <p className="icon"><BsTrash /></p>*/}
                    {/*                        <p className="text">Delete</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="button" onClick={() => setMoveAreasMenuStatus(!moveAreasMenuStatus)}>*/}
                    {/*                        <p className="icon"><MdOutlineDriveFileMove /></p>*/}
                    {/*                        <p className="text">Move</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="right">*/}
                    {/*                    <p className="close-icon" onClick={unselectAllAreas}><VscChromeClose /></p>*/}
                    {/*                </div>*/}
                    {/*                {moveAreasMenuStatus && (*/}
                    {/*                    <div className="move-menu-container">*/}
                    {/*                        <p className="menu-title">Select a parent element</p>*/}
                    {/*                        <div className="search-container">*/}
                    {/*                            <input value={searchMoveArea} onChange={(event) => setSearchMoveArea(event.currentTarget.value)} className="search-input" placeholder="Search" type="text"/>*/}
                    {/*                            <p className="search-icon"><AiOutlineSearch /></p>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="result-list-container">*/}
                    {/*                            {searchedOverlayMoveArea.map((overlay, index) => (*/}
                    {/*                                <div className="result" key={index}>*/}
                    {/*                                    <p className="icon"><FaLayerGroup /></p>*/}
                    {/*                                    <p className="text" onClick={() => handleMoveAreas(overlay.uuid)}>{overlay.name}</p>*/}
                    {/*                                </div>*/}
                    {/*                            ))}*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </>*/}
                    {/*)}*/}
                    <div className="overlay-list-container">
                        <div className="overlay-list">
                            {useMapStore.getState().overlayLoading && (
                                <Skeleton height={25} count={2} style={{marginBottom: "8px"}}/>
                            )}
                            {!useMapStore.getState().overlayLoading && (
                                <>
                                    {overlays.map((overlay, index) => (
                                        <Fragment key={index}>
                                            <OverlayRow overlay={overlay} publicMap={publicMap}/>
                                        </Fragment>
                                    ))}
                                    <div className="create-overlay-row">
                                        <p className="overlay-icon"><FaLayerGroup/></p>
                                        <input onKeyDown={(event) => event.key === "Enter" ? handleCreateNewOverlay(event): null} type="text" placeholder="Create overlay"/>
                                    </div>
                                    {/*{!publicMap && (*/}
                                    {/*    <div className="overlay new">*/}
                                    {/*        <div className="left">*/}
                                    {/*            <div className="custom-round-checkbox">*/}
                                    {/*                <span className="custom-round-checkbox-indicator"></span>*/}
                                    {/*            </div>*/}
                                    {/*            <p className="overlay-icon"><FaLayerGroup/></p>*/}
                                    {/*            {createOverlayStatus && (*/}
                                    {/*                <input type="text" onKeyDown={handleDetectCreateNewOverlay} onBlur={handleCreateNewOverlay} placeholder="new overlay" value={createOverlayName} onChange={(event) => setCreateOverlayName(event.currentTarget.value)} />*/}
                                    {/*            )}*/}
                                    {/*            {!createOverlayStatus && (*/}
                                    {/*                <p className="overlay-name" onClick={() => setCreateOverlayStatus(true)}>Create a new overlay</p>*/}
                                    {/*            )}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}

export default ManageOverlayAndZoneSideMenu