import React from "react";
import { useObjectTableStore } from "../../../../services/Stores/ObjectTableStore";
// import {HiChevronDown, HiChevronRight} from "react-icons/hi";
// import {BiSolidFolder} from "react-icons/bi";
// import UserMapAPI from "../../../../services/API/User/UserMapAPI";
import ProjectLine from "./ProjectLine";
import MapLine from "./MapLine";
import OverlayLine from "./OverlayLine";
import AreaLine from "./AreaLine";
import Skeleton from "react-loading-skeleton";

const MyBoardSidebar = () => {
  //Get data from store
  const myBoardsProjects = useObjectTableStore(
    (state) => state.myBoardsProjects
  );
  // const myWorkspaceList = useObjectTableStore((state) => state.workspaces);
  // const currentSelectedProject = useObjectTableStore(state => state.currentSelectedProject)
  // const currentSelectedMap = useObjectTableStore(state => state.currentSelectedMap)

  // console.log('myBoardsProjects items from Zustand',myBoardsProjects);

  return (
    <>
      <div className="sub-line-container">
        {myBoardsProjects.map((p) => (
          <React.Fragment key={p.uuid}>
            <ProjectLine p={p} panel="myboard" />
            {p.open && p.maps === undefined && (
              <Skeleton
                style={{ marginLeft: "16px", width: "80%" }}
                height={32}
                count={p.mapsCount}
              />
            )}
            {p.open &&
              p.maps !== undefined &&
              (
                <div className="sub-line-container">
                  {p.maps.map((m) => (
                    <React.Fragment key={m.uuid}>
                      <MapLine m={m} p={p} panel="myboard" />
                      {m.open && m.overlays === undefined && (
                        <Skeleton
                          style={{ marginLeft: "16px", width: "80%" }}
                          height={32}
                          count={m.overlayCount}
                        />
                      )}
                      {m.open && m.overlays !== undefined && (
                        <div className="sub-line-container">
                          {m.overlays.map((o) => (
                            <React.Fragment key={o.uuid}>
                              <OverlayLine
                                m={m}
                                p={p}
                                o={o}
                                panel={"myboard"}
                              />
                              {o.open && o.areas === undefined && (
                                <Skeleton
                                  style={{ marginLeft: "16px", width: "80%" }}
                                  height={32}
                                  count={o.areasCount}
                                />
                              )}
                              {o.open && o.areas !== undefined && (
                                <div className="sub-line-container">
                                  {o.areas.map((a) => (
                                    <React.Fragment key={a.uuid}>
                                      <AreaLine o={o} p={p} m={m} a={a} />
                                    </React.Fragment>
                                  ))}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default MyBoardSidebar;
