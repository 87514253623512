import React, {useEffect, useRef, useState} from "react";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {BsEnvelope, BsThreeDots} from "react-icons/bs";
import CreateWorkspacePopup from "../../../components/customs/dashboard-popups/CreateWorkspacePopup";
import UserWorkspaceAPI from "../../../services/API/User/UserWorkspaceAPI";
import ContextMenuItem from "../../../components/customs/content_menu/ContextMenuItem";
import {MdDelete} from "react-icons/md";
import ContextMenuContainer from "../../../components/customs/content_menu/ContextMenuContainer";
import {getContextMenuPosition} from "../../../services/Utils";
import {IoMdSettings} from "react-icons/io";
import WorkspaceManager from "../../../components/customs/dashboard_elements/WorkspaceManager";
import ProjectManageActionBar from "../../../components/customs/projects_list/ProjectManageActionBar";
import UserProjectsAPI from "../../../services/API/User/UserProjectsAPI";
import ProjectsListContainer from "../../../components/customs/projects_list/ProjectsListContainer";
import MapsListContainer from "../../../components/customs/projects_list/MapsListContainer";
import UserMapAPI from "../../../services/API/User/UserMapAPI";
import ConfirmDeletePopup from "../../../components/customs/popups/ConfirmDeletePopup";
import {createSuccessToast} from "../../../services/CustomToastUtils";

const TeamWorkspaces = () => {
	//Get data from store
	const currentOpenedProject = useDashboardStore(state => state.currentOpenedProject)
	const workspaces = useDashboardStore(state => state.workspaces)
	const currentOpenedWorkspace = useDashboardStore(state => state.currentOpenedWorkspace)
	const currentContextMenuWorkspace = useDashboardStore(state => state.currentContextMenuWorkspace)
	const currentWorkspaceView = useDashboardStore(state => state.currentWorkspaceView)

	//Define page state
	const [createWorkspacePopupState, setCreateWorkspacePopupState] = useState(false)
	const [contextMenuState, setContextMenuState] = useState(false)
	const contextMenuRef = useRef(null)
	const [search, setSearch] = useState("")

	useEffect(() => {
		//Reset data in store
		useDashboardStore.setState({currentOpenedProject: null, currentProjects: null})

		UserWorkspaceAPI.userGetAllUserWorkspace().then((response) => {
			if (response.status === 200){
				useDashboardStore.setState({workspaces: response.data})
			} else {
				useDashboardStore.setState({workspaces: []})
			}
		})
	}, []);

	//Detect workspace has been selected
	useEffect(() => {
		if (currentOpenedWorkspace){
			useDashboardStore.setState({currentOpenedProject: null, currentProjects: null, currentProjectMaps: null})

			UserProjectsAPI.userGetAllWorkspaceProjects().then((response) => {
				if (response.status === 200){
					useDashboardStore.setState({currentProjects: response.data})
				} else {
					useDashboardStore.setState({currentProjects: []})
				}
			})
		} else {
			useDashboardStore.setState({currentOpenedProject: null, currentProjects: null})
		}
	}, [currentOpenedWorkspace]);

	//Open project context menu
	const handleOpenContextMenu = (event, workspace) => {
		event.stopPropagation()
		event.preventDefault()

		if (!workspace.userAuthorizations.includes('workspace.manage') && !workspace.userAuthorizations.includes('workspace.delete')){
			setContextMenuState(false)
			return
		}

		useDashboardStore.setState({currentContextMenuWorkspace: workspace})
		setContextMenuState(true)
		getContextMenuPosition(event, contextMenuRef.current)
	}

	useEffect(() => {
		if (currentOpenedProject !== null){
			UserMapAPI.userGetProjectMap(currentOpenedProject.uuid).then((response) => {
				if (response.status === 200){
					useDashboardStore.setState({currentProjectMaps: response.data})
				} else {
					useDashboardStore.setState({currentProjectMaps: []})
				}
			})
		} else {
			useDashboardStore.setState({currentProjectMaps: null, currentSelectedMaps: []})
		}
	}, [currentOpenedProject])

	//Delete workspace
	const [deleteWorkspacePopupState, setDeleteWorkspacePopupState] = useState(false)
	const handleDeleteWorkspace = async () => {
		const workspaceUuid = useDashboardStore.getState().currentOpenedWorkspace.uuid
		if (await UserWorkspaceAPI.userDeleteWorkspace(workspaceUuid)){
			//Unselect current workspace
			useDashboardStore.setState({currentOpenedWorkspace: null, currentContextMenuWorkspace: null})

			//Delete workspace in store
			useDashboardStore.getState().removeWorkspace(workspaceUuid)

			createSuccessToast("Workspace successfully delete")
		}
	}

	const [searchWorkspace, setSearchWorkspace] = useState("")
	const filteredWorkspace = workspaces !== null ? workspaces.filter(w => String(w.name.toLowerCase()).includes(String(searchWorkspace.toLowerCase()))) : null

	return (
		<>
			<ContextMenuContainer ref={contextMenuRef} state={contextMenuState} setState={setContextMenuState}>
				{currentContextMenuWorkspace && (
					<>
						{currentContextMenuWorkspace.userAuthorizations.includes('workspace.manage') && (
							<ContextMenuItem handler={() => {
								setContextMenuState(false)
								useDashboardStore.setState({currentOpenedWorkspace: currentContextMenuWorkspace, currentWorkspaceView: 'settings'})
							}} text="Manage the workspace" icon={<IoMdSettings />}  />
						)}
						{currentContextMenuWorkspace.userAuthorizations.includes("workspace.admin") && (
							<ContextMenuItem handler={() => {
								setContextMenuState(false)
								useDashboardStore.setState({currentOpenedWorkspace: currentContextMenuWorkspace})
								setDeleteWorkspacePopupState(true)
							}} text="Delete workspace" icon={<MdDelete />}  />
						)}
					</>
				)}
			</ContextMenuContainer>
			<ConfirmDeletePopup setState={setDeleteWorkspacePopupState} state={deleteWorkspacePopupState} deleteType={'workspace'} handler={handleDeleteWorkspace} />
			<CreateWorkspacePopup state={createWorkspacePopupState} setState={setCreateWorkspacePopupState} />
			<div className="workspaces-dashboard-container">
				<div className="workspaces-sidebar-container">
					<p className="title">Team workspaces</p>
					<div className="search-wrapper" style={{marginTop: "-20px"}}>
						<svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#ceccd7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            				<circle cx="11" cy="11" r="8"></circle>
            				<line x1="16" y1="16" x2="22" y2="22"></line>
        				</svg>
						<input type="text" className="workspace-search-input" placeholder="Search team workspaces" value={searchWorkspace} onChange={(event) => setSearchWorkspace(event.currentTarget.value)}/>
					</div>
					<div className="workspaces-list">
						{workspaces !== null && (
							<>
								{workspaces.length > 0 && (
									<>
										{filteredWorkspace.map((w) => (
											<div className={currentOpenedWorkspace !== null && currentOpenedWorkspace.uuid === w.uuid ? "workspace selected" : "workspace"} onClick={() => useDashboardStore.setState({currentOpenedWorkspace: w, currentWorkspaceView: 'projects'})} onContextMenu={(event) => handleOpenContextMenu(event, w)}>
												<div className="left">
													<div className="workspace-logo">
													{w.logo ? (
														<img src={w.logo} alt="Workspace logo"/>
													) : (
														<div class="icon">
															<svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="15.886" viewBox="0 0 16.82 15.886">
																<path id="Trac\xE9_3641" data-name="Trac\xE9 3641" d="M36.82,16.361v9.967a1.557,1.557,0,0,1-1.557,1.557H21.557A1.557,1.557,0,0,1,20,26.328V16.361A1.557,1.557,0,0,1,21.557,14.8h2.8V14.18A2.18,2.18,0,0,1,26.541,12h3.738a2.18,2.18,0,0,1,2.18,2.18V14.8h2.8A1.557,1.557,0,0,1,36.82,16.361ZM26.23,14.8H30.59V14.18a.311.311,0,0,0-.311-.311H26.541a.311.311,0,0,0-.311.311Zm-4.361,1.869v2.743a14.033,14.033,0,0,0,13.085,0l0-2.741Zm13.082,9.344V21.5a15.915,15.915,0,0,1-13.085,0l0,4.522Z" transform="translate(-20 -12)" fill="currentColor">
																</path>
															</svg>
														</div>
													)}
													</div>
													<p className="workspace-name">{w.name}</p>
												</div>
												<p className="icon" onClick={(event) => handleOpenContextMenu(event, w)}><BsThreeDots/></p>
											</div>
										))}
									</>
								)}
							</>
						)}

					</div>
					<p className="add-workspace-btn" onClick={() => setCreateWorkspacePopupState(true)}>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-plus"><circle cx="12" cy="12" r="10"/><path d="M8 12h8"/><path d="M12 8v8"/></svg> 
						
					Add workspace</p>
				</div>
				<div className="workspaces-content-container">
					{currentOpenedWorkspace !== null ? (
						<>
							{currentWorkspaceView === "projects" && (
								<>
									<h1 className="section-title">
										<span
											className={currentOpenedProject ? "clickable" : ""}
											onClick={() =>
												currentOpenedProject ? useDashboardStore.setState({currentOpenedProject: null}) : null
											}
										>
											{currentOpenedWorkspace.name}
										</span> 
										{currentOpenedProject ? "/ " + currentOpenedProject.name : ""}
									</h1>
									<ProjectManageActionBar search={search} setSearch={setSearch} />
									{!currentOpenedProject && (
										<ProjectsListContainer />
									)}
									{currentOpenedProject && (
										<MapsListContainer />
									)}
								</>
							)}
							{currentWorkspaceView === "settings" && (
								<WorkspaceManager/>
							)}
						</>
					) : (
						<div className="no-workspace-message" style={{opacity: "0.2", paddingTop: "130px", fontSize: "18px", textAlign: "center"}}>
							<svg xmlns="http://www.w3.org/2000/svg" width="35" height="22" viewBox="0 0 16.82 15.886"><path id="Trac\xE9_3641" data-name="Trac\xE9 3641" d="M36.82,16.361v9.967a1.557,1.557,0,0,1-1.557,1.557H21.557A1.557,1.557,0,0,1,20,26.328V16.361A1.557,1.557,0,0,1,21.557,14.8h2.8V14.18A2.18,2.18,0,0,1,26.541,12h3.738a2.18,2.18,0,0,1,2.18,2.18V14.8h2.8A1.557,1.557,0,0,1,36.82,16.361ZM26.23,14.8H30.59V14.18a.311.311,0,0,0-.311-.311H26.541a.311.311,0,0,0-.311.311Zm-4.361,1.869v2.743a14.033,14.033,0,0,0,13.085,0l0-2.741Zm13.082,9.344V21.5a15.915,15.915,0,0,1-13.085,0l0,4.522Z" transform="translate(-20 -12)" fill="currentColor"></path></svg>
							<p style={{paddingTop: "15px", fontSize: "18px", fontWeight: "500"}}>
								No workspace. 
								<br />
								Please create a workspace to get started.
							</p>
						</div>
					)}
				</div>
			</div>

		</>
	)
}

export default TeamWorkspaces