import React, {useEffect, useState} from "react"
import "../../../css/pages/admin/dashboard-global.scss"
import {BsThreeDots} from "react-icons/bs";
import {FaCheck, FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp, FaFilter, FaSort, FaSortDown, FaSortUp, FaTrashAlt, FaUserPlus} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";
import {IoSearchSharp} from "react-icons/io5";
import {FiExternalLink} from "react-icons/fi";
import StatsAPI from "../../services/StatsAPI";
import UserAPI from "../../services/UserAPI";
import ShowOrHideColumnMenu from "../../components/ShowOrHideColumnMenu";
import DashboardStatCard from "../../components/DashboardStatCard";
import icon_card_1 from "../../../icons/admin-dashboard/user/card-1.svg";
import icon_card_2 from "../../../icons/admin-dashboard/user/card-2.svg";
import icon_card_3 from "../../../icons/admin-dashboard/user/card-3.svg";
import icon_card_4 from "../../../icons/admin-dashboard/user/card-4.svg";
import Export from "../../components/Export";
import Chart from "react-apexcharts";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import alert_delete from "../../../icons/admin-dashboard/emails/alert.svg";
import {Range} from "rc-slider";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";

const AdminDashboardUsers = () => {

    //Define default state for get user request state
    const [getUsersRequestStatus, setGetUsersRequestStatus] = useState(false)

    //Define default state for user list
    const [users, setUsers] = useState([
        {
            "id": "",
            "firstname": "",
            "lastname": "",
            "creation_date": "",
            "newsletter_status": "",
            "mapNumber": 0,
            "objectNumber": 0,
            "accountType": "",
            "status": "",
            "suspendDayLeft": "",
            "expert": ""
        }
    ])

    //Define default state for stats
    const [stats, setStats] = useState({
        "averageObjectsPerUser": 0,
        "averageMapToOneUser": 0,
        "averageNewsletterSubRate": 0
    })

    useEffect(() => {
        UserAPI.adminGetUsers().then((response) => {
            setUsers(response.data)
            setGetUsersRequestStatus(true)
        })

        StatsAPI.getAllStats().then((response) => {
            setStats(response.data)
        })
    }, [])

    /*******************************FILTER MODAL******************************/
    const removeAllFilter = () => {
        setNewsletterSubFilterValue("none")
        setAccountTypeFilterValue("none")
        setMapCreatedFilterValue([0, 50])
    }

    const [filterModalStatus, setFilterModalStatus] = useState(false)
    const [mapCreatedFilterValue, setMapCreatedFilterValue] = useState([0, 50])
    const [accountTypeFilterValue, setAccountTypeFilterValue] = useState("none")
    const [newsletterSubFilterValue, setNewsletterSubFilterValue] = useState("none")

    /*************************************************************************/

    /******************************SEARCH********************************/
    const [search, setSearch] = useState("")

    let filteredUsers = users.filter(u =>
        String(u.firstname).toLowerCase().includes(search.toLowerCase()) ||
        String(u.lastname).toLowerCase().includes(search.toLowerCase()) ||
        String(u.accountType).toLowerCase().includes(search.toLowerCase()) ||
        String(u.status).toLowerCase().includes(search.toLowerCase()) ||
        String(u.creation_date).toLowerCase().includes(search.toLowerCase()) ||
        String(u.newsletter_status).toLowerCase().includes(search.toLowerCase()) ||
        String(u.mapNumber).toLowerCase().includes(search.toLowerCase()) ||
        String(u.objectNumber).toLowerCase().includes(search.toLowerCase()) ||
        String(u.expert).toLowerCase().includes(search.toLowerCase())
    )

    if (accountTypeFilterValue !== "none"){
            filteredUsers = filteredUsers.filter(m =>
                m.accountType.toLowerCase() === accountTypeFilterValue.toLowerCase()
            )
    }

    if (newsletterSubFilterValue !== "none"){
        filteredUsers = filteredUsers.filter(m =>
            m.newsletter_status.toLowerCase() === newsletterSubFilterValue.toLowerCase()
        )
    }

    if (mapCreatedFilterValue[0] !== 0 || mapCreatedFilterValue[1] !== 50){
        filteredUsers = filteredUsers.filter(m =>
            m.mapNumber >= mapCreatedFilterValue[0]
        )
        filteredUsers = filteredUsers.filter(m =>
            m.mapNumber <= mapCreatedFilterValue[1]
        )
    }
    /*************************************************************************/

    /*************************SHOW/HIDE COLUMN********************************/
    const [showHideStatus, setShowHideStatus] = useState(false)
    const [columnList, setColumnList] = useState({
        "plan": true,
        "active payment": true,
        "status": true,
        "created on": true,
        "newsletter": true,
        "maps": true,
        "objects": true,
        "terms": true,
        "expert": true
    })

    const resetShowOrHide = () => {
        setColumnList({
            "plan": true,
            "active payment": true,
            "status": true,
            "created on": true,
            "newsletter": true,
            "maps": true,
            "objects": true,
            "terms": true,
            "expert": true
        })
    }
    /*************************************************************************/

    /*****************************PAGINATION**********************************/
    const [currentPage, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const maxPage = Math.ceil(filteredUsers.length / itemPerPage)

    const nextPage = () => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }
    const previousPage = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }

    const start = (currentPage - 1) * itemPerPage
    const end = start + itemPerPage
    const paginatedUsers = filteredUsers.slice(start, end)

    const pageButtons = []

    for (let i = 0; i < maxPage; i++){
        pageButtons.push(<p onClick={() => setCurrentPage(i + 1)} className={currentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************SUSPEND MODAL*******************************/
    const [suspendModalStatus, setSuspendModalStatus] = useState(false)
    const [suspendDuration, setSuspendDuration] = useState(0)
    const [suspendUserId, setSuspendUserId] = useState(0)

    const suspendAccount = async () => {
        if (await UserAPI.suspendUser(suspendUserId, suspendDuration)){
            setSuspendModalStatus(false)
            toast.success("User suspend successfully")
            UserAPI.adminGetUsers().then((response) => {
                setUsers(response.data)
                setGetUsersRequestStatus(true)
            })
        }
    }

    const unsuspendUser = async (event) => {
        const userId = event.currentTarget.id
        if (await UserAPI.unSuspendUser(userId)){
            toast.success("User unsuspend successfully")
            UserAPI.adminGetUsers().then((response) => {
                setUsers(response.data)
                setGetUsersRequestStatus(true)
            })
        }
    }


    /*************************************************************************/

    /****************************DELETE ACCOUNT*******************************/
    const [confirmDeleteAccountModalStatus, setConfirmDeleteAccountModalStatus] = useState(false)

    const deleteAccounts = async () => {
        if (await UserAPI.deleteAccounts(checkUser)){
            setConfirmDeleteAccountModalStatus(false)
            toast.success("Accounts deleted successfully")
        }
    }
    /*************************************************************************/

    /*****************************STATS***************************************/
    const [chartData, setChartData] = useState({
        "labels": [],
        "userWithMap": [],
        "userWithNoMap": []
    })
    const [chart1Status, setChart1Status] = useState(false)
    const [chart2Status, setChart2Status] = useState(false)

    //Get chart data
    useEffect(() => {
        StatsAPI.getUserDashboardChart().then((response) => {
            setChartData(response.data)
        })
    }, [])


    const [card1SelectedDay, setCard1SelectedDay] = useState(60)
    const [averageCountObjectPerUser, setAverageCountObjectPerUser] = useState(0)

    const [card2SelectedDay, setCard2SelectedDay] = useState(60)
    const [averageMapToOneUser, setAverageMapToOneUser] = useState(0)

    const [card3SelectedDay, setCard3SelectedDay] = useState(60)
    const [averageNewsletterSubRate, setAverageNewsletterSubRate] = useState(0)

    const [card4SelectedDay, setCard4SelectedDay] = useState(60)
    const [newUserPerDay, setNewUserPerDay] = useState(0)

    useEffect(() => {
        StatsAPI.getAverageObjectPerUser().then((response) => setAverageCountObjectPerUser(response.data))
    }, [card1SelectedDay])

    useEffect(() => {
        StatsAPI.getAverageMapToOneUser().then((response) => setAverageMapToOneUser(response.data))
    }, [card2SelectedDay])

    useEffect(() => {
        StatsAPI.getAverageNewsletterSubRate().then((response) => setAverageNewsletterSubRate(response.data))
    }, [card3SelectedDay])

    useEffect(() => {
        StatsAPI.getNewUserPerDay().then((response) => setNewUserPerDay(response.data))
    }, [card4SelectedDay])
    /*************************************************************************/

    const [openedArraySubMenuUserId, setOpenedSubMenuUserId] = useState(0)

    const [checkUser, setCheckUser] = useState([])

    const checkAll = (event) => {
        setCheckUser([])
        if (event.currentTarget.checked){
            for (let i = 0; i < users.length; i++){
                setCheckUser(checked => [...checked, users[i].id])
            }
        }
    }

    /*******************************SORT USER*********************************/
    const [sortedColumnNameUser, setSortedColumnNameUser] = useState("")
    const [sortedTypeUser, setSortedTypeUser] = useState("")

    if (sortedColumnNameUser !== "" && sortedTypeUser !== ""){
        if (sortedTypeUser === "1"){
            paginatedUsers.sort((a, b) => String(a[sortedColumnNameUser]).localeCompare(String(b[sortedColumnNameUser])))
        } else if (sortedTypeUser === "2") {
            paginatedUsers.sort((a, b) => String(b[sortedColumnNameUser]).localeCompare(String(a[sortedColumnNameUser])))
        }
    }
    /*************************************************************************/

    const dashboard = document.getElementById("scroll-container")

    return (
        <>
            {/*FILTER MODAL*/}
            {filterModalStatus &&(
                <div className="modal-overlay">
                    <div className={filterModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Filter</h1>
                            <p onClick={() => {
                                setFilterModalStatus(false)
                            }}><IoMdClose /></p>
                        </div>
                        <div className="content filter">
                            {/*SELECTOR*/}
                            <div className="selector-container">
                                <div className="selector">
                                    <p className="title">Account type</p>
                                    <div className="select-box">
                                        <p className={accountTypeFilterValue === "Basic" ? "active" : null} onClick={() => accountTypeFilterValue === "none" || accountTypeFilterValue !== "Basic" ? setAccountTypeFilterValue("Basic") : setAccountTypeFilterValue("none")}>Basic</p>
                                        <p className={accountTypeFilterValue === "Standard" ? "active" : null} onClick={() => accountTypeFilterValue === "none" || accountTypeFilterValue !== "Standard" ? setAccountTypeFilterValue("Standard") : setAccountTypeFilterValue("none")}>Standard</p>
                                        <p className={accountTypeFilterValue === "Pro" ? "active" : null} onClick={() => accountTypeFilterValue === "none" || accountTypeFilterValue !== "Pro" ? setAccountTypeFilterValue("Pro") : setAccountTypeFilterValue("none")}>Pro</p>
                                        <p className={accountTypeFilterValue === "Enterprise" ? "active" : null} onClick={() => accountTypeFilterValue === "none" || accountTypeFilterValue !== "Enterprise" ? setAccountTypeFilterValue("Enterprise") : setAccountTypeFilterValue("none")}>Enterprise</p>
                                    </div>
                                </div>
                                <div className="selector">
                                    <p className="title">Newsletter subscription</p>
                                    <div className="select-box">
                                        <p className={newsletterSubFilterValue === "Yes" ? "active" : null} onClick={() => newsletterSubFilterValue === "none" || newsletterSubFilterValue === "No" ? setNewsletterSubFilterValue("Yes") : setNewsletterSubFilterValue("none")}>Yes</p>
                                        <p className={newsletterSubFilterValue === "No" ? "active" : null} onClick={() => newsletterSubFilterValue === "none" || newsletterSubFilterValue === "Yes" ? setNewsletterSubFilterValue("No") : setNewsletterSubFilterValue("none")}>No</p>
                                    </div>
                                </div>
                            </div>
                            {/*RANGE*/}
                            <div className="range-container">
                                <div className="range-box">
                                    <p className="title">Number of maps created</p>
                                    <div className="range">
                                        <Range railStyle={{backgroundColor: "#ebecfc", height: "2px"}} trackStyle={[{backgroundColor: "#2953e8", height: "2px"}]} onChange={(event) => setMapCreatedFilterValue(event)} min={0} max={50} allowCross={false} defaultValue={[0, 50]} value={mapCreatedFilterValue}/>
                                        <div className="info">
                                            <p className="value">{mapCreatedFilterValue[0]}</p>
                                            <p className="value">{mapCreatedFilterValue[1]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*CONFIRM DELETE ACCOUNT MODAL*/}
            {confirmDeleteAccountModalStatus && (
                <div className="modal-overlay">
                    <div className={confirmDeleteAccountModalStatus ? "modal open" : "modal"}>
                        <div className="header delete">
                            <h1>Delete selected account</h1>
                            <p onClick={() => {
                                setConfirmDeleteAccountModalStatus(false)
                            }}><IoMdClose /></p>
                        </div>
                        <div className="content delete-modal">
                            <p className="confirm-message">
                                <img src={alert_delete} alt="Alert icon"/>
                                <p className="text">You are about the start the removal process for the selected accounts. Do you confirm ?</p>
                            </p>
                            <div className="button-container">
                                <div className="btn cancel" onClick={() => {
                                    setConfirmDeleteAccountModalStatus(false)
                                }}>
                                    <p className="text">Cancel</p>
                                    <p className="icon"><IoMdClose /></p>
                                </div>
                                <div className="btn valid" onClick={deleteAccounts}>
                                    <p className="text">Confirm </p>
                                    <p className="icon"><FaCheck /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*SUSPEND MODAL*/}
            {suspendModalStatus &&(
                <div className="modal-overlay">
                    <div className={suspendModalStatus ? "modal open" : "modal"}>
                        <div className="header suspend">
                            <h1>Suspend accounts</h1>
                            <p onClick={() => setSuspendModalStatus(false)}><IoMdClose /></p>
                        </div>
                        <div className="content suspend-user">
                            <div className="select-container">
                                <p className="title">Duration</p>
                                <select name="suspendTime" onChange={(event) => setSuspendDuration(event.currentTarget.value)}>
                                    {suspendDuration === 0 && (
                                        <option value="">Select the duration of the suspension</option>
                                    )}
                                    <option value="5">5 Days</option>
                                    <option value="15">15 Days</option>
                                    <option value="30">30 Days</option>
                                    <option value="60">60 Days</option>
                                </select>
                            </div>
                            <div className="button-container">
                                <div className="btn cancel" onClick={() => {
                                    setSuspendDuration(0)
                                    setSuspendUserId(0)
                                    setSuspendModalStatus(false)
                                }}>
                                    <p className="text">Cancel</p>
                                    <p className="icon"><IoMdClose /></p>
                                </div>
                                <div className="btn valid" onClick={suspendAccount}>
                                    <p className="text">Confirm</p>
                                    <p className="icon"><FaCheck /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
            <div className="admin-dashboard">
                <h1 className="page-title">Overview</h1>
                <div className="card-container">
                    <DashboardStatCard icon={icon_card_1} title="Average object count per user" value={averageCountObjectPerUser} augment={false} augmentPercent={0} selectedDay={card1SelectedDay} setSelectedDay={setCard1SelectedDay}/>
                    <DashboardStatCard icon={icon_card_2} title="Average maps linked to one user" value={averageMapToOneUser} augment={false} augmentPercent={0} selectedDay={card2SelectedDay} setSelectedDay={setCard2SelectedDay}/>
                    <DashboardStatCard icon={icon_card_3} title="Average newsletter sub. rate" value={averageNewsletterSubRate} augment={false} augmentPercent={0} selectedDay={card3SelectedDay} setSelectedDay={setCard3SelectedDay}/>
                    <DashboardStatCard icon={icon_card_4} title="Average new users per day" value={newUserPerDay} augment={false} augmentPercent={0} selectedDay={card4SelectedDay} setSelectedDay={setCard4SelectedDay}/>
                </div>
                <div className="metric-container">
                    <div className={chart1Status ? "metric-card open" : "metric-card"}>
                        <div className="top">
                            <p>User with map</p>
                            <p onClick={() => setChart1Status(!chart1Status)}>
                                {chart1Status
                                    ? <FaChevronUp />
                                    : <FaChevronDown />
                                }
                            </p>
                        </div>
                        <div className="content">
                            <Chart options={{
                                chart: {
                                    type: 'line',
                                    zoom: {enabled: false}
                                },
                                stroke: {curve: 'smooth', width: "3"},
                                labels: chartData.labels,
                                colors: ["#2dd7a7", "#ffbf66"],
                                legend: {position: "top", fontFamily: "Roboto, sans-serif", fontWeight: "400", fontsize: "12px", itemMargin: {horizontal: 25}}
                            }} series={[
                                {
                                    name: "Users",
                                    data: chartData.userWithMap
                                }
                            ]} widht="100%" height={350} type="line"
                                   children={null}/>
                        </div>
                    </div>
                    <div className={chart2Status ? "metric-card open" : "metric-card"}>
                        <div className="top">
                            <p>User with no map</p>
                            <p onClick={() => setChart2Status(!chart2Status)}>
                                {chart2Status
                                    ? <FaChevronUp />
                                    : <FaChevronDown />
                                }
                            </p>
                        </div>
                        <div className="content">
                            <Chart options={{
                                chart: {
                                    type: 'line',
                                    zoom: {enabled: false}
                                },
                                stroke: {curve: 'smooth', width: "3"},
                                labels: chartData.labels,
                                colors: ["#36d4a9"],
                                legend: {position: "top", fontFamily: "Roboto, sans-serif", fontWeight: "400", fontsize: "12px", itemMargin: {horizontal: 25}}
                            }} series={[
                                {
                                    name: "Users",
                                    data: chartData.userWithNoMap
                                }
                            ]} widht="100%" height={350} type="line"
                                   children={null}/>
                        </div>
                    </div>
                </div>
                <div className="dashboard-table mt">
                    <p className="title">List of users</p>
                    <div className="top-container">
                        <div className="left">
                            <div className="filter-btn" onClick={() => setFilterModalStatus(true)}><FaFilter /></div>
                            <ShowOrHideColumnMenu resetColumn={resetShowOrHide} columnList={columnList} setColumnList={setColumnList} open={showHideStatus} setOpen={setShowHideStatus} />
                            <div className="search-container">
                                <div className="box">
                                    <div className="icon"><IoSearchSharp /></div>
                                    <input type="text" placeholder="Search" value={search} onChange={(event) => setSearch(event.currentTarget.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="btn">
                                <div className="icon"><FaUserPlus /></div>
                                <div className="text">Offer Account</div>
                            </div>
                            <Export data={checkUser.length === 0 ? users : users.filter(u => checkUser.includes(u.id))} filename="weeki_user" />
                        </div>
                    </div>
                    {accountTypeFilterValue !== "none" ||
                    newsletterSubFilterValue !== "none" ||
                    mapCreatedFilterValue[0] !== 0 || mapCreatedFilterValue[1] !== 50
                        ?
                        <div className="filter-item-list">
                            <div className="item">
                                <p className="text">Remove all filter</p>
                                <p className="icon" onClick={removeAllFilter}><IoMdClose /></p>
                            </div>
                            {accountTypeFilterValue !== "none" && (
                                <div className="item">
                                    <p className="text">Account : {accountTypeFilterValue}</p>
                                    <p className="icon" onClick={() => setAccountTypeFilterValue("none")}><IoMdClose /></p>
                                </div>
                            )}
                            {newsletterSubFilterValue !== "none" && (
                                <div className="item">
                                    <p className="text">Newsletter sub : {newsletterSubFilterValue}</p>
                                    <p className="icon" onClick={() => setNewsletterSubFilterValue("none")}><IoMdClose /></p>
                                </div>
                            )}
                            {mapCreatedFilterValue[0] !== 0 || mapCreatedFilterValue[1] !== 50
                                ?
                                <div className="item">
                                    <p className="text">Map created : {mapCreatedFilterValue[0]} - {mapCreatedFilterValue[1]}</p>
                                    <p className="icon" onClick={() => setMapCreatedFilterValue([0, 50])}><IoMdClose /></p>
                                </div>
                                : null
                            }
                        </div>
                        : null
                    }
                    {checkUser.length > 0 && (
                        <div className="select-action-container">
                            <div className="btn delete" onClick={() => setConfirmDeleteAccountModalStatus(true)}>
                                <p className="text">Delete all</p>
                                <p className="icon"><FaTrashAlt /></p>
                            </div>
                        </div>
                    )}
                    <table>
                        <thead>
                        <tr>
                            <td className="left input"><input type="checkbox" onChange={checkAll}/></td>
                            <td className="left large" onClick={() => {
                                setSortedColumnNameUser("firstname")
                                if (sortedTypeUser === ""){
                                    setSortedTypeUser("1")
                                } else if (sortedTypeUser === "1"){
                                    setSortedTypeUser("2")
                                } else {
                                    setSortedTypeUser("")
                                }
                            }}>
                                username
                                <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "firstname"
                                                ? <FaSort /> : null
                                            }
                                    {sortedTypeUser === "1" && sortedColumnNameUser === "firstname"
                                        ? <FaSortUp /> : null
                                    }
                                    {sortedTypeUser === "2" && sortedColumnNameUser === "firstname"
                                        ? <FaSortDown /> : null
                                    }
                                        </span>
                            </td>
                            {columnList.plan && (
                                <td onClick={() => {
                                    setSortedColumnNameUser("accountType")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    Plan
                                    <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "accountType"
                                                ? <FaSort /> : null
                                            }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "accountType"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "accountType"
                                            ? <FaSortDown /> : null
                                        }
                                        </span>
                                </td>
                            )}
                            {columnList["active payment"] && (
                                <td>Active payment</td>
                            )}
                            {columnList.status && (
                                <td onClick={() => {
                                    setSortedColumnNameUser("status")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    Status
                                    <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "status"
                                                ? <FaSort /> : null
                                            }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "status"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "status"
                                            ? <FaSortDown /> : null
                                        }
                                        </span>
                                </td>
                            )}
                            {columnList["created on"] && (
                                <td onClick={() => {
                                    setSortedColumnNameUser("creation_date")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    Created on
                                    <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "creation_date"
                                                ? <FaSort /> : null
                                            }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "creation_date"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "creation_date"
                                            ? <FaSortDown /> : null
                                        }
                                        </span>
                                </td>
                            )}
                            {columnList.newsletter && (
                                <td onClick={() => {
                                    setSortedColumnNameUser("newsletter_status")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    news.sub
                                    <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "newsletter_status"
                                                ? <FaSort /> : null
                                            }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "newsletter_status"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "newsletter_status"
                                            ? <FaSortDown /> : null
                                        }
                                        </span>
                                </td>
                            )}
                            {columnList.maps && (
                                <td onClick={() => {
                                    setSortedColumnNameUser("mapNumber")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    maps
                                    <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "mapNumber"
                                                ? <FaSort /> : null
                                            }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "mapNumber"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "mapNumber"
                                            ? <FaSortDown /> : null
                                        }
                                        </span>
                                </td>
                            )}
                            {columnList.objects && (
                                <td  onClick={() => {
                                    setSortedColumnNameUser("objectNumber")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    objects
                                    <span className="sort-icon">
                                            {sortedTypeUser === "" || sortedColumnNameUser !== "objectNumber"
                                                ? <FaSort /> : null
                                            }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "objectNumber"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "objectNumber"
                                            ? <FaSortDown /> : null
                                        }
                                        </span>
                                </td>
                            )}
                            {columnList.terms && (
                                <td>terms accepted since</td>
                            )}
                            {columnList.expert && (
                                <td onClick={() => {
                                    setSortedColumnNameUser("expert")
                                    if (sortedTypeUser === ""){
                                        setSortedTypeUser("1")
                                    } else if (sortedTypeUser === "1"){
                                        setSortedTypeUser("2")
                                    } else {
                                        setSortedTypeUser("")
                                    }
                                }}>
                                    expert
                                    <span className="sort-icon">
                                        {sortedTypeUser === "" || sortedColumnNameUser !== "expert"
                                            ? <FaSort /> : null
                                        }
                                        {sortedTypeUser === "1" && sortedColumnNameUser === "expert"
                                            ? <FaSortUp /> : null
                                        }
                                        {sortedTypeUser === "2" && sortedColumnNameUser === "expert"
                                            ? <FaSortDown /> : null
                                        }
                                        </span>
                                </td>
                            )}
                            <td> </td>
                        </tr>
                        </thead>
                        <tbody>
                        {getUsersRequestStatus && (
                            <>
                                {paginatedUsers.map((user, index) => (
                                    <tr className={checkUser.includes(user.id) ? "checked" : null} key={index}>
                                        <td className="left input"><input type="checkbox" checked={checkUser.includes(user.id)} onChange={() => !checkUser.includes(user.id) ? setCheckUser(checkedMaps => [...checkedMaps, user.id]) : setCheckUser(checkUser.filter(v => v !== user.id))}/></td>
                                        <td className="left large">{user.firstname} {user.lastname}</td>
                                        {columnList.plan && (
                                            <td>{user.accountType}</td>
                                        )}
                                        {columnList["active payment"] && (
                                            <td>NONE</td>
                                        )}
                                        {columnList.status && (
                                            <td>{user.status} {user.suspendDayLeft !== null ? "(" + user.suspendDayLeft + "d)" : null}</td>
                                        )}
                                        {columnList["created on"] && (
                                            <td>{user.creation_date}</td>
                                        )}
                                        {columnList.newsletter && (
                                            <td>{user.newsletter_status}</td>
                                        )}
                                        {columnList.maps && (
                                            <td>{user.mapNumber}</td>
                                        )}
                                        {columnList.objects && (
                                            <td>{user.objectNumber}</td>
                                        )}
                                        {columnList.terms && (
                                            <td>NONE</td>
                                        )}
                                        {columnList.expert && (
                                            <td>{user.expert}</td>
                                        )}
                                        <td className={openedArraySubMenuUserId === user.id ? "sub-menu open" : "sub-menu"}>
                                            <p onClick={() => openedArraySubMenuUserId === 0 ? setOpenedSubMenuUserId(user.id) : openedArraySubMenuUserId !== user.id ? setOpenedSubMenuUserId(user.id) : setOpenedSubMenuUserId(0)} className="btn"><BsThreeDots /></p>
                                            <div className="menu" >
                                                <p>Modify personal information</p>
                                                <p>Open on Stripe <span><FiExternalLink /></span></p>
                                                {user.suspendDayLeft === null && (
                                                    <p  onClick={() => {
                                                        setSuspendUserId(user.id)
                                                        setSuspendDuration(0)
                                                        setSuspendModalStatus(true)

                                                    }}>Suspend</p>
                                                )}
                                                {user.suspendDayLeft !== null && (
                                                    <p id={user.id} onClick={unsuspendUser}>Unsuspend</p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                        </tbody>
                    </table>
                    <div className="pagination-container">
                        <div className="row-selector">
                            <div className="text">Rows per page :</div>
                            <select name="rows" onChange={(event) => setItemPerPage(parseInt(event.currentTarget.value))}>
                                <option value={10}>10</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="page-selector">
                            <div className="arrow-btn" onClick={previousPage}><FaChevronLeft /></div>
                            <div className="btn-container">
                                {pageButtons.length > 0 && (
                                    pageButtons
                                )}
                                {pageButtons.length === 0 && (
                                    <p className="selected">0</p>
                                )}
                            </div>
                            <div className="arrow-btn" onClick={nextPage}><FaChevronRight /></div>
                        </div>
                        <div className="page-infos">
                            Showing {start + 1} to {end} of {paginatedUsers.length} rows
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="top-btn" onClick={() => dashboard.scrollTo(0, 0)}>
                        <p><FaChevronUp /></p>
                    </div>
                    <p className="copy">&copy; Weeki 2021</p>
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={5000}/>
            </main>
        </>
    )
}

export default AdminDashboardUsers