import Axios from "axios";
import AuthAPI from "./AuthAPI";
import {toast} from "react-toastify";
import {createErrorToast, createInfoToast} from "./CustomToastUtils";
import {Cookies} from "react-cookie";
import {useSubscribePopupStore} from "./Stores/SubscribePopupStore";

/**
 * @returns {AxiosInstance}
 */
export default function getAxiosInstance() {
    const client = Axios.create()

    client.interceptors.request.use(function (config) {
        // Do something before request is sent
        if (config.method === "post"){
            const data = config.data

            if (data instanceof FormData){
                const utmData = window.sessionStorage.getItem('utmData')
                if (utmData){
                    data.append('utmData', sessionStorage.getItem('utmData'))
                }
            }
        }

        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    client.interceptors.response.use(function (response) {

        //Update auth token
        if (response.headers.authorization !== undefined){
            window.localStorage.setItem("authToken", response.headers.authorization.replaceAll("Bearer ", ""))
            Axios.defaults.headers['Authorization'] = response.headers.authorization

            const authCookie = new Cookies()
            authCookie.set('authCookie', response.headers.authorization.replaceAll("Bearer ", ""), {path: "/", maxAge: Number.MAX_SAFE_INTEGER, domain: "weeki.io"})
        }

        if (response.status === 202){
            // createInfoToast(response.data.error, 5)
            useSubscribePopupStore.setState({state: true})
        }

        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response){
            if (error.response.status === 401){
                AuthAPI.simpleLogout()
                window.location.href = "/"
                toast.error("Vous avez été déconnecté")
            }
            if (error.response.status === 400){
                if (error.response.data.error !== "Account not confirmed"){
                    createErrorToast(error.response.data.error)
                }
                // toast.error(error.response.data.error)
            }
        }
        return Promise.reject(error);
    });

    return client
}