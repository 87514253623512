import React from "react"
import {useLocation} from "react-router-dom";

const UTMParams = () => {
	const location = useLocation()
	const query = new URLSearchParams(location.search)

	const utmSource = query.get('utm_source')
	const utmMedium = query.get('utm_medium')
	const utmCampaign = query.get('utm_campaign')
	const utmContent = query.get('utm_content')

	if (utmSource && utmMedium && utmCampaign && utmContent){
		window.sessionStorage.setItem('utmData', JSON.stringify({'utm_source': utmSource, 'utm_medium': utmMedium, 'utm_campaign': utmCampaign, 'utm_content': utmContent}))
	}

	return null
}

export default UTMParams