import React from "react"
// import {BiSolidFolder} from "react-icons/bi";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useObjectTableStore} from "../../../services/Stores/ObjectTableStore";
import {ReactComponent as MyBoardsIcon} from "../../../../icons/sidebar/my-boards.svg"

const ObjectTableMapList = () => {
	// const currentPanel = useObjectTableStore(state => state.currentPanel)
	const myBoardsProjects = useObjectTableStore(state => state.myBoardsProjects)
	const currentSelectedProject = useObjectTableStore(state => state.currentSelectedProject)

	return (
		<>
			<div className="custom__object-table-project-list-container">
				{myBoardsProjects.filter(p => p.uuid === currentSelectedProject)[0]?.maps?.map((p) => (
					<div className="project">
						<div>
							<div className="icon"><MyBoardsIcon /></div>
							<p className="text">{p.name}</p>
						</div>
						<div className="icon"><BsThreeDotsVertical/></div>
					</div>
				))}
			</div>
		</>
	)
}

export default ObjectTableMapList