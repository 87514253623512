import {create} from "zustand";

export const useMapZoneStore = create((set) => ({
	currentSelectedZone: null,
	currentSelectedZoneProperties: null,
	currentSelectedPopup: null,
	currentSelectedMock: null,
	hoveredAreaMock: null,
	hoveredAreaUuid: null,
	zoneCurrentDragState: false,
	currentCopiedZone: null,
	copiedOriginZoom: null,
	copyType: 'copy',
	confirmDeletePopupState: false,
	deletedZoneProperties: null,
	resetStore: () => set((state) => ({
		currentSelectedZone: null,
		currentSelectedZoneProperties: null,
		currentSelectedPopup: null,
		currentSelectedMock: null,
		hoveredAreaMock: null,
		hoveredAreaUuid: null,
		zoneCurrentDragState: false,
		currentCopiedZone: null,
		copiedOriginZoom: 0,
		copyType: 'copy',
		confirmDeletePopupState: false,
		deletedZoneProperties: null
	}))
}))