import getAxiosInstance from "../../AxiosInstance";
import {USER_SUBSCRIPTION_API} from "../../../../Router";

function userLeaveTeam(){
	return getAxiosInstance().post(USER_SUBSCRIPTION_API + '/leave_team')
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	userLeaveTeam
}