import { create } from "zustand";

export const useObjectTableStore = create((set) => ({
	openState: false,
	myBoardsOpenState: false,
	workspaceOpenState: false,
	sharedOpenState: false,
	searchQuery: '',
	currentView: 'list',
	currentPanelView: 'default',
	items: [],
	// les currents panels
	currentPanelType: '',
	currentPanel: '',
	myBoardsProjects: [],
	workspaces: [],
	sharedMaps: [],
	currentSelectedProject: '',
	currentSelectedMap: '',
	currentSelectedOverlay: '',
	currentSelectedArea: '',
	availableTableColumns: [
		{ 'name': 'name', 'active': true, canDisable: false },
		{ 'name': 'type', 'active': true, canDisable: true },
		{ 'name': 'createdOn', 'active': true, canDisable: true },
		{ 'name': 'sourceUser', 'active': true, canDisable: true },
		{ 'name': 'tags', 'active': true, canDisable: true },
		{ 'name': 'colorTag', 'active': true, canDisable: true },
		{ 'name': 'file', 'active': false, canDisable: true },
		{ 'name': 'status', 'active': false, canDisable: true },
		{ 'name': 'lastModification', 'active': false, canDisable: true },
	],
	filters: [
		{
			'name': 'type',
			'open': false,
			'value': null
		},
		{
			'name': 'tags',
			'open': false,
			'value': []
		},
		{
			'name': 'color',
			'open': false,
			'value': null
		},
		{
			'name': 'createdAt',
			'open': false,
			'startDate': null,
			'endDate': null
		},
		{
			'name': 'author',
			'open': false,
			'value': null
		}
	],
	// Functions to update the openState
	setOpenState: (value) => set(() => ({ openState: value })),
	// Setter for the currentPanels
	setMyBoardsOpenState: (state) => set({ myBoardsOpenState: state }),
	setWorkspaceOpenState: (state) => set({ workspaceOpenState: state }),
	setSharedOpenState: (state) => set({ sharedOpenState: state }),
	// Functions to update the searchQuery
	setSearchQuery: (query) => set({ searchQuery: query }),
	// My useObjectTableStore's functions for anything
	// ----- //
	updateAvailableColumnStatus: (column, newState) => set((state) => ({
		availableTableColumns: state.availableTableColumns.map((c) => c.name === column ? { ...c, active: newState } : c)
	})),
	updateMyBoardsProjectOpenState: (uuid, openState) => set((state) => ({
		myBoardsProjects: state.myBoardsProjects.map((p) => p.uuid === uuid ? { ...p, open: openState } : p)
	})),
	updateMyBoardsMapOpenState: (projectUuid, mapUuid, openState) => set((state) => ({
		myBoardsProjects: state.myBoardsProjects.map((p) => p.uuid === projectUuid ? { ...p, maps: p.maps.map((m) => m.uuid === mapUuid ? { ...m, open: openState } : m) } : p)
	})),
	updateMyBoardsMapTableColumn: (projectUuid, mapUuid, tableColumn) => set((state) => ({
		myBoardsProjects: state.myBoardsProjects.map((p) => p.uuid === projectUuid ? { ...p, maps: p.maps.map((m) => m.uuid === mapUuid ? { ...m, objectTableColumn: tableColumn } : m) } : p)
	})),
	updateSharedMapOpenState: (mapUuid, openState) => set((state) => ({
		sharedMaps: state.sharedMaps.map((m) => m.uuid === mapUuid ? { ...m, open: openState } : m)
	})),
	updateMyBoardsOverlayOpenState: (projectUuid, mapUuid, overlayUuid, openState) => set((state) => ({
		myBoardsProjects: state.myBoardsProjects.map((p) => p.uuid === projectUuid ? { ...p, maps: p.maps.map((m) => m.uuid === mapUuid ? { ...m, overlays: m.overlays.map((o) => o.uuid === overlayUuid ? { ...o, open: openState } : o) } : m) } : p)
	})),
	updateSharedOverlayOpenState: (mapUuid, overlayUuid, openState) => set((state) => ({
		sharedMaps: state.sharedMaps.map((m) => m.uuid === mapUuid ? { ...m, overlays: m.overlays.map((o) => o.uuid === overlayUuid ? { ...o, open: openState } : o) } : m)
	})),
	// MES WORKSPACES's functions
	updateWorkspaceOpenState: (uuid, openState) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === uuid ? { ...w, open: openState } : w)
	})),
	updateWorkspaceProjectList: (uuid, projects) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === uuid ? { ...w, projects: projects } : w)
	})),
	updateWorkspaceProjectOpenState: (workspaceUuid, projectUuid, openState) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === workspaceUuid ? { ...w, projects: w.projects.map((p) => p.uuid === projectUuid ? { ...p, open: openState } : p) } : w)
	})),
	updateWorkspaceProjectMapList: (workspaceUuid, projectUuid, mapList) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === workspaceUuid ? { ...w, projects: Array.isArray(w.projects) ? w.projects.map((p) => p.uuid === projectUuid ? { ...p, maps: mapList } : p) : [] } : w)
	})),
	// Function pour updates les overlays d'un map
	updateWorkspaceProjectMapOverlays: (workspaceUuid, projectUuid, mapUuid, overlays) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === workspaceUuid ? {
			...w,
			projects: Array.isArray(w.projects) ? w.projects.map((p) => p.uuid === projectUuid ? {
				...p,
				maps: Array.isArray(p.maps) ? p.maps.map((m) => m.uuid === mapUuid ? {
					...m,
					overlays: overlays
				} : m) : []
			} : p) : []
		} : w)
	})),
	// Function pour updates Map d'un workspace
	updateWorkspaceMapOpenState: (workspaceUuid, projectUuid, mapUuid, openState) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === workspaceUuid ? {
			...w,
			projects: w.projects.map((p) => p.uuid === projectUuid ? {
				...p,
				maps: p.maps.map((m) => m.uuid === mapUuid ? {
					...m,
					open: openState
				} : m)
			} : p)
		} : w)
	})),
	// Function pour updates les areas d'un overlay
	updateWorkspaceProjectMapOverlayAreas: (workspaceUuid, projectUuid, mapUuid, overlayUuid, areas) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === workspaceUuid ? {
			...w,
			projects: Array.isArray(w.projects) ? w.projects.map((p) => p.uuid === projectUuid ? {
				...p,
				maps: Array.isArray(p.maps) ? p.maps.map((m) => m.uuid === mapUuid ? {
					...m,
					overlays: Array.isArray(m.overlays) ? m.overlays.map((o) => o.uuid === overlayUuid ? {
						...o,
						areas: areas
					} : o) : []
				} : m) : []
			} : p) : []
		} : w)
	})),
	// function pour updates les open state d'un overlay
	updateWorkspaceProjectMapOverlayOpenState: (workspaceUuid, projectUuid, mapUuid, overlayUuid, openState) => set((state) => ({
		workspaces: state.workspaces.map((w) => w.uuid === workspaceUuid ? {
			...w,
			projects: w.projects.map((p) => p.uuid === projectUuid ? {
				...p,
				maps: p.maps.map((m) => m.uuid === mapUuid ? {
					...m,
					overlays: m.overlays.map((o) => o.uuid === overlayUuid ? {
						...o,
						open: openState
					} : o)
				} : m)
			} : p)
		} : w)
	})),

	// -------------------------- END MY WORKSPACES's functions
	updateMyBoardsProjectMaps: (uuid, maps) => set((state) => ({
		myBoardsProjects: state.myBoardsProjects.map((p) => p.uuid === uuid ? { ...p, maps: maps } : p)
	})),
	updateMyBoardsMapOverlays: (projectUuid, mapUuid, overlays) => set((state) => ({
		myBoardsProjects: state.myBoardsProjects.map((p) => p.uuid === projectUuid ? { ...p, maps: p.maps.map((m) => m.uuid === mapUuid ? { ...m, overlays: overlays } : m) } : p)
	})),
	updateSharedMapOverlays: (mapUuid, overlays) => set((state) => ({
		sharedMaps: state.sharedMaps.map((m) => m.uuid === mapUuid ? { ...m, overlays: overlays } : m)
	})),
	updateMyBoardsOverlayAreas: (projectUuid, mapUuid, overlayUuid, areas) => set((state) => ({
		myBoardsProjects: state.myBoardsProjects.map((p) => p.uuid === projectUuid ? { ...p, maps: p.maps.map((m) => m.uuid === mapUuid ? { ...m, overlays: m.overlays.map((o) => o.uuid === overlayUuid ? { ...o, areas: areas } : o) } : m) } : p)
	})),
	updateSharedOverlayAreas: (mapUuid, overlayUuid, areas) => set((state) => ({
		sharedMaps: state.sharedMaps.map((m) => m.uuid === mapUuid ? { ...m, overlays: m.overlays.map((o) => o.uuid === overlayUuid ? { ...o, areas: areas } : o) } : m)
	})),
	toggleFilterPanel: (panel) => set((state) => ({
		filters: state.filters.map((f) => f.name === panel ? { ...f, open: !f.open } : f)
	})),
	updateTypeFilterValue: (value) => set((state) => ({
		filters: state.filters.map((f) => f.name === "type" ? { ...f, value: value } : f)
	})),
	updateColorFilterValue: (value) => set((state) => ({
		filters: state.filters.map((f) => f.name === "color" ? { ...f, value: value } : f)
	})),
	addTagToFilter: (tag) => set((state) => ({
		filters: state.filters.map((f) => f.name === "tags" ? { ...f, value: [...f.value, tag] } : f)
	})),
	removeTagFilter: (tag) => set((state) => ({
		filters: state.filters.map((f) => f.name === "tags" ? { ...f, value: f.value.filter(v => v !== tag) } : f)
	})),
	updateCreatedAtStartDateFilter: (date) => set((state) => ({
		filters: state.filters.map((f) => f.name === "createdAt" ? { ...f, startDate: date } : f)
	})),
	updateCreatedAtEndDateFilter: (date) => set((state) => ({
		filters: state.filters.map((f) => f.name === "createdAt" ? { ...f, endDate: date } : f)
	})),
	updateAuthorFilter: (value) => set((state) => ({
		filters: state.filters.map((f) => f.name === "author" ? { ...f, value: value } : f)
	})),
	resetFilters: () => set((state) => ({
		filters: [
			{
				'name': 'type',
				'open': false,
				'value': null
			},
			{
				'name': 'tags',
				'open': false,
				'value': []
			},
			{
				'name': 'color',
				'open': false,
				'value': null
			},
			{
				'name': 'createdAt',
				'open': false,
				'startDate': null,
				'endDate': null
			},
			{
				'name': 'author',
				'open': false,
				'value': null
			}
		]
	})),
	resetStore: () => set(() => ({
		openState: false,
		myBoardsOpenState: false,
		workspaceOpenState: false,
		sharedOpenState: false,
		searchQuery: '',
		currentView: 'list',
		currentPanelView: 'default',
		currentPanelType: null,
		currentPanel: null,
		myBoardsProjects: [],
		workspaces: [],
		sharedMaps: [],
		currentSelectedProject: '',
		currentSelectedMap: '',
		currentSelectedOverlay: '',
		currentSelectedArea: '',
		filters: [
			{
				'name': 'type',
				'open': false,
				'value': null
			},
			{
				'name': 'tags',
				'open': false,
				'value': []
			},
			{
				'name': 'color',
				'open': false,
				'value': null
			}
		]
	}))
}));