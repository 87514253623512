import {create} from "zustand";

export const useMapGlobalSelectionStore = create((set) => ({
	selectedItems: [],
	selectedAreas: [],
	selectedShapes: [],
	selectionLayerGroup: null,
	selectionLayer: null,
	selectionDragState: false,
	startCenterLatLng: null,
	startBounds: null,
	copySelectionState: false,
	copyIndicatorPosition: {x: 0, y: 0},
	drawState: false,
	drawStartPosition: null,
	drawCurrentPosition: null,
	tempDrawLayer: null,
	tempSelectionLayer: null,
	updateSelectedAreaDrawState: (areaUuid, newState) => set((state) => ({
		selectedAreas: state.selectedAreas.map((a) => a.uuid === areaUuid ? {...a, drawState: newState}: a),
	})),
	updateSelectedItemDrawState: (areaUuid, newState) => set((state) => ({
		selectedItems: state.selectedItems.map((a) => a.uuid === areaUuid ? {...a, drawState: newState}: a),
	})),
	updateSelectedItemRect: (itemUuid, rect) => set((state) => ({
		selectedItems: state.selectedItems.map((i) => i.uuid === itemUuid ? {...i, rect: rect}: i),
	})),
	updateSelectedAreaRect: (areaUuid, rect) => set((state) => ({
		selectedAreas: state.selectedAreas.map((a) => a.uuid === areaUuid ? {...a, rect: rect}: a),
	})),
	updateSelectedShapeDrawState: (areaUuid, newState) => set((state) => ({
		selectedShapes: state.selectedShapes.map((a) => a.uuid === areaUuid ? {...a, drawState: newState}: a),
	})),
	addSelectedArea: (area) => set((state) => ({
		selectedAreas: [...state.selectedAreas, area],
	})),
	addSelectedItem: (item) => set((state) => ({
		selectedItems: [...state.selectedItems, item],
	})),
	removeSelectedItem: (itemUuid) => set((state) => ({
		selectedItems: state.selectedItems.filter((i) => i.uuid !== itemUuid),
	})),
	removeSelectedArea: (areaUuid) => set((state) => ({
		selectedAreas: state.selectedAreas.filter((a) => a.uuid !== areaUuid),
	})),
	addSelectedShape: (shape) => set((state) => ({
		selectedShapes: [...state.selectedShapes, shape],
	})),
	resetStore: () => set((state) => ({
		selectedItems: [],
		selectedAreas: [],
		selectedShapes: [],
		selectionLayerGroup: null,
		selectionLayer: null,
		selectionDragState: false,
		startCenterLatLng: null,
		startBounds: null,
		copySelectionState: false,
		copyIndicatorPosition: {x: 0, y: 0},
		drawState: false,
		drawStartPosition: null,
		drawCurrentPosition: null,
		tempDrawLayer: null,
	}))
}))