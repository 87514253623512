import React from "react";
import {useObjectTableStore} from "../../../../services/Stores/ObjectTableStore";
import {HiChevronDown, HiChevronRight} from "react-icons/hi";
import OverlayAPI from "../../../../services/OverlayAPI";
import {PiStackBold} from "react-icons/pi";

const OverlayLine = ({w = null,p = null, m, o, panel}) => {
	const currentSelectedOverlay = useObjectTableStore(state => state.currentSelectedOverlay)
	const currentSelectedArea = useObjectTableStore(state => state.currentSelectedArea)

	const handleGetOverlayAreas = () => {
		OverlayAPI.getOverlayAreas(o.uuid).then((response) => {
			if (response.status === 200){
				if (panel === 'workspace') {
					useObjectTableStore.getState().updateWorkspaceProjectMapOverlayAreas(w.uuid, p.uuid, m.uuid, o.uuid, response.data)
				} else if (panel === 'myboard') {
					useObjectTableStore.getState().updateMyBoardsOverlayAreas(p.uuid, m.uuid, o.uuid, response.data)					
				} else if (panel === 'shared') {
					useObjectTableStore.getState().updateSharedOverlayAreas(m.uuid, o.uuid, response.data)
				}
			} else {
				if (panel === 'workspace') {
					useObjectTableStore.getState().updateWorkspaceProjectMapOverlayAreas(w.uuid, p.uuid, m.uuid, o.uuid, [])
				} else if (panel === 'myboard'){
					useObjectTableStore.getState().updateMyBoardsOverlayAreas(p.uuid, m.uuid, o.uuid, [])
				} else if (panel === 'shared'){
					useObjectTableStore.getState().updateSharedOverlayAreas(m.uuid, o.uuid, [])
				}
			}
		})
	}

	const handleOpenOverlay = (event) => {
		event.stopPropagation()

		useObjectTableStore.setState({currentSelectedOverlay: o.uuid, currentSelectedArea: '', currentSelectedMap: m.uuid})

		if (panel === "workspace"){
			useObjectTableStore.getState().updateWorkspaceProjectMapOverlayOpenState(w.uuid, p.uuid, m.uuid, o.uuid, true)
		} else if (panel === 'myboard') {
			useObjectTableStore.setState({currentSelectedProject: p.uuid})
			useObjectTableStore.getState().updateMyBoardsOverlayOpenState(p.uuid, m.uuid, o.uuid, true)
		} else if (panel === 'shared') {
			useObjectTableStore.getState().updateSharedOverlayOpenState(m.uuid, o.uuid, true)

		}
		handleGetOverlayAreas()
	}

	const handleToggleOverlay = (event) => {
		event.stopPropagation()

		if (panel === "workspace"){
			useObjectTableStore.getState().updateWorkspaceProjectMapOverlayOpenState(w.uuid, p.uuid, m.uuid, o.uuid, !o.open)
		} else if (panel === 'myboard') {
			useObjectTableStore.setState({currentSelectedProject: p.uuid})
			useObjectTableStore.getState().updateMyBoardsOverlayOpenState(p.uuid, m.uuid, o.uuid, !o.open)
		} else if (panel === 'shared') {
			useObjectTableStore.getState().updateSharedOverlayOpenState(m.uuid, o.uuid, !o.open)
		}
		handleGetOverlayAreas()
	}


	return (
		<>
			<div
				className={"open-line " + (currentSelectedOverlay === o.uuid && currentSelectedArea === "" ? 'active' : "")}
				onClick={handleOpenOverlay}>
				<div className="open-icon" onClick={handleToggleOverlay}>{o.open ?
					<HiChevronDown size={20}/> :
					<HiChevronRight size={20}/>}</div>
				<div className="line-icon"><PiStackBold fontSize="1.8em"/></div>
				<p className="line-text">{o.name}</p>
			</div>
		</>
	)
}

export default OverlayLine