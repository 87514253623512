import React, {useState} from "react";
import {FaChevronDown} from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const DashboardStatCard = ({icon, title, value, augment = false, augmentPercent = 0, selectedDay, setSelectedDay}) => {
    const [menuStatus, setMenuStatus] = useState(false)

    return (
        <div className="admin-card">
            <div className="left">
                <img src={icon} alt="Card icon"/>
                <div className="text">
                    <p>{title}</p>
                    <p>{value !== "0" && value !== "" ? value : <Skeleton count={1} width={50} />}</p>
                </div>
            </div>
            <div className="right">
                <div className="selector date">
                    <div className="btn" onClick={() => setMenuStatus(!menuStatus)}>
                        {selectedDay !== 365 && (
                            <p>Last {selectedDay} days</p>
                        )}
                        {selectedDay === 365 && (
                            <p>Last 1 years</p>
                        )}
                        <p><FaChevronDown /></p>
                    </div>
                    <div className={menuStatus ? "menu open" : "menu"}>
                        <p onClick={() => {
                            setSelectedDay(15)
                            setMenuStatus(false)
                        }}>15 days</p>
                        <p onClick={() => {
                            setSelectedDay(30)
                            setMenuStatus(false)
                        }}>30 days</p>
                        <p onClick={() => {
                            setSelectedDay(60)
                            setMenuStatus(false)
                        }}>60 days</p>
                        <p onClick={() => {
                            setSelectedDay(90)
                            setMenuStatus(false)
                        }}>90 days</p>
                        <p onClick={() => {
                            setSelectedDay(180)
                            setMenuStatus(false)
                        }}>180 days</p>
                        <p onClick={() => {
                            setSelectedDay(365)
                            setMenuStatus(false)
                        }}>1 years</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardStatCard