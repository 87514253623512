import React, {useRef} from "react";
import ContextMenuContainer from "../../customs/content_menu/ContextMenuContainer";
import {useMapContextMenuStore} from "../../../services/Stores/MapContextMenuStore";
import ContextMenuItem from "../../customs/content_menu/ContextMenuItem";
import {MdContentCut, MdCopyAll, MdDelete, MdEdit, MdLineWeight} from "react-icons/md";
import {deleteZone, selectZoneFromFeature} from "../../../services/Utils/ZoneUtils";
import {
	copyShapeItem,
	cutShapeItem,
	deleteShapeItem,
	updateShapeColor, updateShapeFillColor,
	updateShapeWidth
} from "../../../services/Utils/ShapeItemUtils";
import {globalAvailableColor} from "../../../../App";
import {IoIosColorFill} from "react-icons/io";

const ShapeContextMenu = () => {
	const menuRef = useRef(null)

	return (
		<>
			<ContextMenuContainer ref={menuRef} state={useMapContextMenuStore.getState().state} setState={() => null} map={true}>
				<ContextMenuItem text="Delete shape" icon={<MdDelete />} handler={() => deleteShapeItem(useMapContextMenuStore.getState().feature.properties.id)} />
				<ContextMenuItem text="Color" icon={<IoIosColorFill />} handler={() => null}>
					<div className="context-menu-child-container">
						{globalAvailableColor.map((color) => (
							<div className="context-menu-child-color" style={{backgroundColor: color}} onClick={() => updateShapeColor(color, useMapContextMenuStore.getState().feature.properties.id)}></div>
						))}
					</div>
				</ContextMenuItem>
				<ContextMenuItem text="Fill color" icon={<IoIosColorFill />} handler={() => null}>
					<div className="context-menu-child-container">
						<div className="context-menu-child-color no-color"
							 style={{backgroundColor: "#FFFFFF"}} onClick={() => updateShapeFillColor("#000000", 0, useMapContextMenuStore.getState().feature.properties.id)}></div>
						{globalAvailableColor.map((color) => (
							<div className="context-menu-child-color" style={{backgroundColor: color}}
								 onClick={() => updateShapeFillColor(color, 1, useMapContextMenuStore.getState().feature.properties.id)}></div>
						))}
					</div>
				</ContextMenuItem>
				<ContextMenuItem text="Weight" icon={<MdLineWeight/>} handler={() => null}>
					<div className="context-menu-child-container" style={{flexDirection: 'column', rowGap: '8px'}}>
						<div className="weight-container" onClick={() => updateShapeWidth(1, useMapContextMenuStore.getState().feature.properties.id)}>
							<div className="weight" style={{height: '1px'}}></div>
							<div className="text">1px</div>
						</div>
						<div className="weight-container" onClick={() => updateShapeWidth(2, useMapContextMenuStore.getState().feature.properties.id)}>
							<div className="weight" style={{height: '2px'}}></div>
							<div className="text">2px</div>
						</div>
						<div className="weight-container" onClick={() => updateShapeWidth(3, useMapContextMenuStore.getState().feature.properties.id)}>
							<div className="weight" style={{height: '3px'}}></div>
							<div className="text">3px</div>
						</div>
						<div className="weight-container" onClick={() => updateShapeWidth(4, useMapContextMenuStore.getState().feature.properties.id)}>
							<div className="weight" style={{height: '4px'}}></div>
							<div className="text">4px</div>
						</div>
						<div className="weight-container" onClick={() => updateShapeWidth(5, useMapContextMenuStore.getState().feature.properties.id)}>
							<div className="weight" style={{height: '5px'}}></div>
							<div className="text">5px</div>
						</div>
					</div>
				</ContextMenuItem>
				{/*<ContextMenuItem text="Copy shape" icon={<MdCopyAll />} handler={() => copyShapeItem(useMapContextMenuStore.getState().feature.properties.id)} />*/}
				{/*<ContextMenuItem text="Cut shape" icon={<MdContentCut />} handler={() => cutShapeItem(useMapContextMenuStore.getState().feature.properties.id)} />*/}
			</ContextMenuContainer>
		</>
	)
}

export default ShapeContextMenu