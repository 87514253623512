import React, {useEffect, useState} from "react";
import {FaFilter} from "react-icons/fa";
import {IoSearchSharp} from "react-icons/io5";
import UserMoocAPI from "../../../services/API/User/Mooc/UserMoocAPI";
import {AiFillEye} from "react-icons/ai";
import {useHistory} from "react-router-dom";
import {useMoocStore} from "../../../services/Stores/MoocStore";
import {useMapStore} from "../../../services/Stores";
import {ThreeDots} from "react-loader-spinner";

const Story = () => {
	const history = useHistory()
	const [getStoriesState, setGetStoriesState] = useState(false)
	const [createdStories, setCreatedStories] = useState([])
	const [enrolledStories, setEnrolledStories] = useState([])

	useEffect(() => {
		UserMoocAPI.userGetUserMoocs().then((response) => {
			if (response.status === 200){
				setCreatedStories(response.data.filter(s => s.owner === true || s.collaborator === true))
				setEnrolledStories(response.data.filter(s => s.owner === false && s.collaborator === false))
			} else {
				setCreatedStories([])
				setEnrolledStories([])
			}

			setGetStoriesState(true)
		})
	}, []);

	const handleOpenMooc = (story) => {
		//Set mooc data in store
		useMoocStore.setState({storiesSidebarState: true, openedMooc: story})
		useMapStore.setState({mapUuid: story["mapBase32Uuid"]})

		//Redirect to map
		history.push("/user/map/" + story["mapBase32Uuid"])
	}

	const [searchCreatedStory, setSearchCreatedStory] = useState("")
	const [searchEnrolledStory, setSearchEnrolledStory] = useState("")

	const filteredCreatedStories = createdStories.filter(s => String(s.name.toLowerCase()).includes(String(searchCreatedStory.toLowerCase())))
	const filteredEnrolledStories = enrolledStories.filter(s => String(s.name.toLowerCase()).includes(String(searchEnrolledStory.toLowerCase())))

	return (
		<>
			<h1 className="section-title">Story</h1>
			{/*USER CREATED STORY*/}
			<div className="dashboard-table mt">
				<p className="title">Created stories</p>
				<div className="top-container">
					<div className="left">
						<div className="filter-btn"><FaFilter/></div>
						<div className="search-container">
							<div className="box">
								<div className="icon"><IoSearchSharp/></div>
								<input type="text" placeholder="Search" value={searchCreatedStory} onChange={(event) => setSearchCreatedStory(event.currentTarget.value)} />
							</div>
						</div>
					</div>
				</div>
				<table style={{tableLayout: "auto"}}>
					<thead>
					<tr>
						<td>Name</td>
						<td>User state</td>
						<td>Actions</td>
					</tr>
					</thead>
					<tbody>
						{!getStoriesState && (
							<tr>
								<td colSpan={2}><ThreeDots
									wrapperStyle={{display: 'flex', justifyContent: 'center'}}
									color="#2953e8" width={35} height={30}/></td>
							</tr>
						)}
						{getStoriesState && (
							<>
								{filteredCreatedStories.length > 0 && (
									<>
										{filteredCreatedStories.map((story) => (
											<tr onClick={() => handleOpenMooc(story)}>
												<td>{story.name}</td>
												<td>{story.owner ? "Creator" : story.collaborator ? "Collaborator" : ""}</td>
												<td className="action-cell">
													<p><AiFillEye size={18} /></p>
												</td>
											</tr>
										))}
									</>
								)}
								{filteredCreatedStories.length <= 0 && (
									<tr><td colSpan={2} style={{textAlign: "center"}}>No created stories</td></tr>
								)}
							</>
						)}
					</tbody>
				</table>
			</div>
			{/*USER ENROLLED STORY*/}
			<div className="dashboard-table mt">
				<p className="title">Enrolled Stories</p>
				<div className="top-container">
					<div className="left">
						<div className="filter-btn"><FaFilter/></div>
						<div className="search-container">
							<div className="box">
								<div className="icon"><IoSearchSharp/></div>
								<input type="text" placeholder="Search" value={searchEnrolledStory} onChange={(event) => setSearchEnrolledStory(event.currentTarget.value)}/>
							</div>
						</div>
					</div>
				</div>
				<table style={{tableLayout: "auto"}}>
					<thead>
					<tr>
						<td>Name</td>
						<td>Actions</td>
					</tr>
					</thead>
					<tbody>
						{!getStoriesState && (
							<tr>
								<td colSpan={2}><ThreeDots
									wrapperStyle={{display: 'flex', justifyContent: 'center'}}
									color="#2953e8" width={35} height={30}/></td>
							</tr>
						)}
						{getStoriesState && (
							<>
								{filteredEnrolledStories.length > 0 && (
									<>
										{filteredEnrolledStories.map((story) => (
											<tr onClick={() => handleOpenMooc(story)}>
												<td>{story.name}</td>
												<td className="action-cell">
													<p><AiFillEye size={18} /></p>
												</td>
											</tr>
										))}
									</>
								)}
								{filteredEnrolledStories.length <= 0 && (
									<tr><td colSpan={2} style={{textAlign: "center"}}>No enrolled stories</td></tr>
								)}
							</>
						)}
					</tbody>
				</table>
			</div>
		</>
	)
}

export default Story