import React, {useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import {createPortal} from "react-dom";

const AreaConfirmDeletePopup = ({children, state, setState, handler, deleteType}) => {
	const validText = "DELETE"
	const [validInputValue, setValidInputValue] = useState("")

	const handleValid = () => {
		if (validInputValue === validText){
			setValidInputValue("")
			handler()
			setState(false)
		}
	}

	if (state) {
		return (createPortal(
			<div className="custom__confirm-delete-popup-overlay">
				<div className="container">
					<div className="header">
						<p className="title">Delete {deleteType} ?</p>
						<p className="icon" onClick={() => {
							setValidInputValue("")
							setState(false)
						}}><VscChromeClose/></p>
					</div>
					<div className="content">
						{children}
						<p className="text">If you still wish to delete your {deleteType}, type {validText} to confirm in the field below.</p>
						<div className="input-container">
							<input value={validInputValue} onKeyDown={(event) => event.key === "Enter" ? handleValid() : null}
								   onChange={(event) => setValidInputValue(event.currentTarget.value)} type="text"
								   placeholder={"Type " + validText + " to confirm"}/>
						</div>
					</div>
					<div className="footer">
						<div className="btn invert" onClick={() => {
							setValidInputValue("")
							setState(false)
						}}>Cancel</div>
						<div className={validInputValue === validText ? "btn" : "btn invalid"} onClick={handleValid}>Delete</div>

					</div>
				</div>
			</div>, document.body)
		)
	}

	return null
}

export default AreaConfirmDeletePopup