import React, {Fragment, useEffect, useRef, useState} from "react";
import L from "leaflet";
import {useMapStore} from "../../../services/Stores";
import {BsSendFill} from "react-icons/bs";
import {MdEdit} from "react-icons/md";
import {IoIosInformationCircleOutline, IoMdMove} from "react-icons/io";
import AreaAPI from "../../../services/AreaAPI";
import {FaChevronDown, FaChevronLeft, FaChevronRight} from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import {LiaSquare} from "react-icons/lia";
import {TbSquare} from "react-icons/tb";
import ShapeRow from "./ShapeRow";
import ContextMenuContainer from "../content_menu/ContextMenuContainer";
import ContextMenuItem from "../content_menu/ContextMenuItem";
import {getContextMenuPosition} from "../../../services/Utils";
import {GiCancel} from "react-icons/gi";
import UserAreaAPI from "../../../services/API/User/UserAreaAPI";
import AreaConfirmMoveOverlay from "../../Map/AreaConfirmMoveOverlay";
import {useCookies} from "react-cookie";
import OverlayAPI from "../../../services/OverlayAPI";

const AreaRow = ({area, overlay, position}) => {
	//Get map instance in map store
	const mapInstance = useMapStore(state => state.mapInstance)
	const publicMap = useMapStore(state => state.publicMap)

	const [cookies, setCookie] = useCookies(['mercureAuthorization', 'maps-default-overlay', 'maps-tutorial'])


	//Define default state for area name change
	const [nameChangeState, setNameChangeState] = useState(false)

	//Define default state for area open state
	const [openState, setOpenState] = useState(false)
	const [contextMenuState, setContextMenuState] = useState(false)
	const contextMenuRef = useRef(null)

	//Define default state for areas list and for loading areas state
	const [loadingAreaState, setLoadingAreaState] = useState(false)

	const [areas, setAreas] = useState([])
	const [shapes, setShapes] = useState([])

	//Get child areas when open
	useEffect(() => {
		if (openState){
			setLoadingAreaState(true)

			AreaAPI.getChildAreas(area.uuid).then((response) => {
				setLoadingAreaState(false)

				if (response.status === 200){
					console.log(response.data)
					setAreas(response.data.areas)
					setShapes(response.data.shapes)
				}
			})
		} else {
			setLoadingAreaState(false)
		}
	}, [openState])

	//Update area name
	const updateAreaName = async (name) => {
		if (await AreaAPI.renameArea(area.uuid, name)) {
			// //Clone areas list
			// const newAreas = [...prevAreas]
			//
			// //Find area index in areas list
			// const areaIndex = newAreas.findIndex(a => a.uuid === area.uuid)
			//
			// //Update area name
			// newAreas[areaIndex].name = name
			//
			// //Update areas state
			// prevSetAreas(newAreas)
		}

		setNameChangeState(false)
	}

	const handleAreaDragStart = () => {

	}

	const handleGoToArea = (event, area) => {
		event.stopPropagation()

		//Get area center
		let xc = parseFloat(area.pos.xc)
		let yc = parseFloat(area.pos.yc)

		//Get feature height and with
		let height = area.height
		let width = area.width

		//Calculate bounds from feature data
		const dx = width * 0.5
		const dy = height * 0.5

		const featureBounds = [
			[xc - dx, yc - dy],
			[xc + dx, yc + dy]
		]

		const latLngCords = featureBounds.map(c => {
			return L.Projection.SphericalMercator.unproject(new L.Point(c[0], c[1]))
		})

		mapInstance.fitBounds(latLngCords, {padding: [50, 50], animate: false})
	}

	const [contextMenuMoveState, setContextMenuMoveState] = useState(false)
	const handleOpenContextMenu = (event) => {
		event.preventDefault()
		event.stopPropagation()

		setContextMenuState(true)

		getContextMenuPosition(event, contextMenuRef.current)
	}

	const overlays = useMapStore(state => state.overlays)

	const [confirmMovePopupState, setConfirmMovePopupState] = useState(false)
	const [moveOverlayUuid, setMoveOverlayUuid] = useState(null)
	const handleMoveZoneToOverlay = async (overlayUuid) => {
		setConfirmMovePopupState(false)
		setContextMenuState(false)
		setContextMenuMoveState(false)

		if (await UserAreaAPI.userMoveAreaToOverlay(area.uuid, overlayUuid, true)) {
			useMapStore.setState({overlayLoading: true})
			useMapStore.setState({overlays: []})

			OverlayAPI.getMapOverlays(useMapStore.getState().mapUuid).then((response) => {
				if (response.status === 200) {

					if (cookies['maps-default-overlay'] !== undefined) {
						if (cookies['maps-default-overlay'][useMapStore.getState().mapUuid] !== undefined) {
							useMapStore.setState({currentOverlay: cookies['maps-default-overlay'][useMapStore.getState().mapUuid]})
						} else {
							const cookieValue = {}
							cookieValue[useMapStore.getState().mapUuid] = response.data[0].uuid
							setCookie('maps-default-overlay', cookieValue)
							useMapStore.setState({currentOverlay: response.data[0].uuid})
						}
					} else {
						const cookieValue = {}
						cookieValue[useMapStore.getState().mapUuid] = response.data[0].uuid
						setCookie('maps-default-overlay', cookieValue)
						useMapStore.setState({currentOverlay: response.data[0].uuid})
					}

					let overlaysList = structuredClone(response.data)
					overlaysList = overlaysList.map(ol => ({...ol, default: useMapStore.getState().currentOverlay === ol.uuid, active: true}))

					const activeOverlay = overlaysList.filter(o => o.active).map(o => o.uuid)
					useMapStore.getState().updateOverlayFilterList(activeOverlay)

					useMapStore.setState({overlays: overlaysList})
					useMapStore.setState({overlayLoading: false})
				}
			})
		}
	}

	return (
		<>
			{confirmMovePopupState && (
				<AreaConfirmMoveOverlay area={area} handler={handleMoveZoneToOverlay} state={confirmMovePopupState} setState={setConfirmMovePopupState} />
			)}
			<ContextMenuContainer map={false} setState={setContextMenuState} state={contextMenuState} ref={contextMenuRef}>
				{!contextMenuMoveState && (
					<ContextMenuItem text="Move" icon={<IoMdMove />} handler={() => setContextMenuMoveState(true)} closeAfter={false} />
				)}
				{contextMenuMoveState && (
					<>
						<ContextMenuItem text="Cancel" icon={<GiCancel />} handler={() => setContextMenuMoveState(false)} closeAfter={false}/>
						{overlays.map((o) => (
							<>
								{o.uuid !== overlay.uuid && (
									<ContextMenuItem text={o.name} handler={() => handleMoveZoneToOverlay(o.uuid)} icon={null} />
								)}
							</>
						))}
					</>
				)}
			</ContextMenuContainer>
			<div className="area-row-container" onContextMenu={handleOpenContextMenu}>
				<div draggable={!publicMap} className="area" style={{marginLeft: position + 'px'}}>
					<div className="left">
						<div className="open-btn" onClick={(event) => {
							event.stopPropagation()
							setOpenState(!openState)}
						}>
							{openState
								? <FaChevronDown size={14} />
								: <FaChevronRight size={14} />
							}
						</div>
						{nameChangeState && (
							<input type="text"
								   onClick={(event) => event.stopPropagation()}
								   autoFocus={true}
								   onKeyDown={(event) => event.key === "Enter" ? updateAreaName(event.currentTarget.value) : null}
								   onBlur={(event) => updateAreaName(event.currentTarget.value)} placeholder="Area name"
								   defaultValue={area.name} />
						)}
						<div className="type-icon">
							<TbSquare />
						</div>
						{!nameChangeState && (
							<p className="area-name">{area.name}</p>
						)}
					</div>
					<div className="right">
						<div className="icon-button">
							<p className="icon" onClick={(event) => handleGoToArea(event, area)}><BsSendFill /></p>
							<p className="tooltip">Go to area</p>
						</div>
						{!publicMap && (
							<div className="icon-button">
								<p className="icon" onClick={(event) => {
									event.stopPropagation()
									setNameChangeState(true)
								}}><MdEdit/></p>
								<p className="tooltip">Edit name</p>
							</div>
						)}
						<div className="icon-button">
							<p className="icon"><IoIosInformationCircleOutline/></p>
							<p className="tooltip">Infos</p>
						</div>
					</div>
				</div>
				{openState && (
					<>
						{loadingAreaState && (
							<Skeleton height={25} count={area.areasCount} style={{marginBottom: "8px", marginLeft: position + 10 + 'px'}}/>
						)}
						{!loadingAreaState && (
							<>
								{areas.length > 0 && (
									<>
										{areas.map((a, index) => (
											<Fragment key={index}>
												<AreaRow area={a} position={position + 10} overlay={overlay} />
											</Fragment>
										))}
									</>
								)}
								{shapes.length > 0 && (
									<>
										{shapes.map((s, index) => (
											<Fragment key={index}>
												<ShapeRow shape={s} position={position + 10} overlay={overlay} />
											</Fragment>
										))}
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
		</>

	)
}

export default AreaRow