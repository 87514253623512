import React from "react";
import {createPortal} from "react-dom";
import FullScreenPDFViewer from "../FullScreenPDFViewer";
import {VscChromeClose} from "react-icons/vsc";

const SimpleFullscreenContainer = ({fileUrl, setFileUrl}) => {
	return createPortal(
		<>
			<div className="object-fullscreen-overlay">
				<div className="object-fullscreen-container">
					<div className="close-btn" onClick={() => setFileUrl(null)}><VscChromeClose /></div>
					<div className="object-fullscreen-content no-bar">
						<div className="object-content-container">
							<FullScreenPDFViewer pdfUrl={fileUrl} />
						</div>
					</div>
				</div>
			</div>
		</>
	, document.body)
}

export default SimpleFullscreenContainer