import React, {createElement, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useUserStore} from "./Stores";

export function useOnClickOutside (ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };

            window.addEventListener("click", listener);
            window.addEventListener("touchstart", listener);
            return () => {
                window.removeEventListener("click", listener);
                window.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}

export function useLocationChange(){
    const location = useLocation()

    useEffect(() => {
        if (!useUserStore.getState().confirmedAccount && useUserStore.getState().userLoad){
            if (location.pathname !== "/user/dashboard/home") {
                window.location.href = '/user/dashboard/home'
            }
        }
    }, [location.pathname]);
}

// export function useOutsideAlerter(ref, handler) {
//     useEffect(() => {
//         /**
//          * Alert if clicked on outside of element
//          */
//         function handleClickOutside(event) {
//             if (ref.current && !ref.current.contains(event.target)) {
//                 // alert("You clicked outside of me!");
//                 handler()
//             }
//         }
//         // Bind the event listener
//         window.addEventListener("click", handleClickOutside);
//         return () => {
//             // Unbind the event listener on clean up
//             window.removeEventListener("click", handleClickOutside);
//         };
//     }, [ref, handler]);
// }

export function useOutsideClick(callback) {
    const ref = React.useRef();

    React.useEffect(() => {
        const handleClick = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            callback();
        };

        document.addEventListener('mousedown', handleClick);
        document.addEventListener("touchstart", handleClick);


        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener("touchstart", handleClick);

        };
    }, []);

    return ref;
}

export function getBrowser(window) {
    let currentBrowser = 'Unknown';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Explorer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    else { console.log('Others'); }

    return currentBrowser;
}

export function generateRandomId(){
    return (Math.random() * Date.now() * Math.random()).toFixed(0)
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function RGBToHex(rgbString){
    rgbString = rgbString.replaceAll("(", "")
    rgbString = rgbString.replaceAll(")", "")
    rgbString = rgbString.replaceAll(" ", "")
    rgbString = rgbString.replaceAll("rgb", "")
    const rgbArray = rgbString.split(',')

    return "#" + ((1 << 24) + (parseInt(rgbArray[0]) << 16) + (parseInt(rgbArray[1]) << 8) + parseInt(rgbArray[2])).toString(16).slice(1);
}

export function hexToRGB(hex){
    const red = parseInt(hex.substring(1, 3), 16);
    const green = parseInt(hex.substring(3, 5), 16);
    const blue = parseInt(hex.substring(5, 7), 16);

    return red + "," + green + "," + blue;
}

export function getContextMenuPosition(event, contextMenu){
    let mousePosition = {};
    let menuPosition = {};
    let menuDimension = {};

    menuDimension.x = contextMenu.outerWidth;
    menuDimension.y = contextMenu.outerHeight;
    mousePosition.x = event.pageX;
    mousePosition.y = event.pageY;

    if (mousePosition.x + menuDimension.x > window.innerWidth + window.screenLeft) {
        menuPosition.x = mousePosition.x - menuDimension.x;
    } else {
        menuPosition.x = mousePosition.x;
    }

    if (mousePosition.y + menuDimension.y > window.innerHeight + window.screenTop) {
        menuPosition.y = mousePosition.y - menuDimension.y;
    } else {
        menuPosition.y = mousePosition.y;
    }

    contextMenu.style.top = menuPosition.y + 'px'
    contextMenu.style.left = menuPosition.x + 'px'

    return menuPosition;
}

export function convertSecondToStringTime(seconds){
    if (seconds === 0){
        return "0h"
    }

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    return hours + 'h' + (minutes < 10 ? '0' + minutes : minutes)
}

export function getSizeName(octets){
    const unit = ['o', 'ko', 'mo', 'go'];

    if (octets < 1000){
        return octets + unit[0];
    } else {
        if (octets < 1000000){
            const ko = Math.round(octets / 1000).toFixed(0);
            return ko + unit[1];
        } else {
            if (octets < 1000000000){
                const mo = Math.round(octets / (1000 * 1000)).toFixed(0);
                return mo + unit[2];
            } else {
                const go = Math.round(octets / (1000 * 1000 * 1000)).toFixed(0);
                return go + unit[3];
            }
        }
    }
}

export function removeArrayDuplicate(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}