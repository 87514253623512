import React, {useEffect, useState} from "react";
import {use} from "i18next";
import {MathJax} from "better-react-mathjax";

const FullscreenLatex = ({latex}) => {

    return (
        <div className="fullscreen-latex-container">
            <div className="latex-render-container">
                <MathJax>{latex}</MathJax>
            </div>
        </div>
    )
}

export default FullscreenLatex