import React, {Fragment, useEffect, useRef, useState} from "react";
import {IoMdAdd, IoMdCheckmarkCircleOutline, IoMdRemove} from "react-icons/io";
import {IoDocumentTextOutline, IoEyeOutline} from "react-icons/io5";
import {TbListCheck} from "react-icons/tb";
import UserMoocChapterAPI from "../../services/API/User/Mooc/UserMoocChapterAPI";
import {useMoocStore} from "../../services/Stores/MoocStore";
import {
	addNewMoocChapterToStore,
	addNewMoocCourseToStore,
	addNewQuizToStore,
	flyToMoocLocation,
	getCurrentChapterBySubsectionUuid,
	moveDownSubsection,
	moveUpSection,
	moveUpSubsection,
	reIndexSubsectionAfterPosition
} from "../../services/Utils/MoocUtils";
import {MdAddLocationAlt, MdAssignmentAdd, MdDelete, MdNoteAdd} from "react-icons/md";
import UserMoocCourseAPI from "../../services/API/User/Mooc/UserMoocCourseAPI";
import Skeleton from "react-loading-skeleton";
import {createErrorToast, createInfoToast} from "../../services/CustomToastUtils";
import {useMapStore} from "../../services/Stores";
import L from "leaflet"
import UserMoocAPI from "../../services/API/User/Mooc/UserMoocAPI";
import CustomTooltip from "../customs/CustomTooltip";
import UserMoocQuizAPI from "../../services/API/User/Mooc/UserMoocQuizAPI";
import ContextMenuItem from "../customs/content_menu/ContextMenuItem";
import ContextMenuContainer from "../customs/content_menu/ContextMenuContainer";
import {getContextMenuPosition} from "../../services/Utils";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import UserMoocParticipantAPI from "../../services/API/User/Mooc/UserMoocParticipantAPI";
import {FaLock} from "react-icons/fa6";
import {BsMortarboardFill} from "react-icons/bs";
import {PiBookBold} from "react-icons/pi";

const CourseModulesList = () => {
	//Get mooc chapter in store
	const moocChapters = useMoocStore(state => state.openedMoocChapters)

	//Get current selected course and quiz in store
	const currentSelectedCourse = useMoocStore(state => state.currentSelectedCourse)
	const currentSelectedQuiz = useMoocStore(state => state.currentSelectedQuiz)

	//Get current mooc
	const currentMooc = useMoocStore(state => state.openedMooc)

	//Create new chapter
	const handleCreateChapter = async () => {
		const newMoocChapterUuid = await UserMoocChapterAPI.userCreateMoocChapter(useMoocStore.getState().openedMooc.uuid)

		if (newMoocChapterUuid) {
			addNewMoocChapterToStore(newMoocChapterUuid)
		}
	}
	
	//Get all mooc chapters
	useEffect(() => {
		if (moocChapters.length <= 0){
			UserMoocChapterAPI.userGetAllMoocChapters(useMoocStore.getState().openedMooc.uuid).then((response) => {
				if (response.status === 200){
					useMoocStore.setState({openedMoocChapters: response.data})
				}
			})
		}
	}, [])

	//Toggle chapter state
	const handleToggleChapterState = (uuid, currentState) => {
		useMoocStore.getState().changeMoocChapterState(uuid, !currentState)

		if (!currentState){
			//Get all chapter course
			UserMoocCourseAPI.userGetAllChapterCourses(uuid).then((response) => {
				if (response.status === 200){
					useMoocStore.getState().setChapterCourses(uuid, response.data)
				} else {
					useMoocStore.getState().setChapterCourses(uuid, [])
				}
			})

			//Get all chapter quizzes
			UserMoocQuizAPI.userGetAllChapterQuizzes(uuid).then((response) => {
				if (response.status === 200){
					useMoocStore.getState().setChapterQuiz(uuid, response.data)
				} else {
					useMoocStore.getState().setChapterQuiz(uuid, [])
				}
			})
		}
	}

	const handleRenameChapter = async (uuid, value) => {
		//Check if value is not empty
		if (value.length <= 0) {
			createErrorToast("Chapter name can't be empty")
			return
		}

		if (await UserMoocChapterAPI.userRenameMoocChapter(uuid, value)) {
			//Update chapter name in store
			useMoocStore.getState().updateChapterName(uuid, value)

			//Close rename state for chapter
			useMoocStore.getState().changeMoocChapterRenameState(uuid, false)
		}
	}

	const handleRenameCourse = async (chapterUuid, courseUuid, value, type) => {
		//Check if value is not empty
		if (value.length <= 0) {
			createErrorToast("Course name can't be empty")
			return
		}

		let requestState = false;

		if (type === "course"){
			requestState = await UserMoocCourseAPI.userRenameCourse(courseUuid, value)
		} else if(type === "quiz"){
			requestState = await UserMoocQuizAPI.userRenameQuiz(courseUuid, value)
		}

		if (requestState){
			//Update course name in store
			useMoocStore.getState().updateCourseName(chapterUuid, courseUuid, value, type)

			//Update current course if open
			if (currentSelectedCourse){
				if (currentSelectedCourse.uuid === courseUuid){
					useMoocStore.getState().updateCurrentCourseName(value)
				}
			}

			//Update current quiz if open
			if (currentSelectedQuiz){
				if (currentSelectedQuiz.uuid === courseUuid){
					useMoocStore.getState().updateCurrentQuizName(value)
				}
			}

			//Close rename state
			useMoocStore.getState().updateCourseRenameState(chapterUuid, courseUuid, false, type)
		}
	}

	//Create new chapter course
	const handleCreateChapterCourse = async (chapterUuid, currentState) => {
		if (!currentState){
			handleToggleChapterState(chapterUuid, currentState)
		}
		const newCourseData = await UserMoocCourseAPI.userCreateMoocCourse(chapterUuid)

		if (newCourseData){
			addNewMoocCourseToStore(newCourseData, chapterUuid)
		}
	}

	//Create new chapter quiz
	const handleCreateChapterQuiz = async (chapterUuid, currentState) => {
		//Check if course is free
		if (currentMooc.price <= 0){
			createInfoToast("This feature is reserved for paying courses. You can make your course chargeable from the pricing tab.")
			return
		}

		if (!currentState){
			handleToggleChapterState(chapterUuid, currentState)
		}
		const newQuizData = await UserMoocQuizAPI.userCreateMoocQuiz(chapterUuid)

		if (newQuizData){
			addNewQuizToStore(newQuizData, chapterUuid)
		}
	}

	//Open course or quiz on click on eye icon
	const handleOpenCourse = (course, type) => {
		//Unselected old course and quiz in store
		useMoocStore.setState({currentSelectedCourse: null})
		useMoocStore.setState({currentSelectedQuiz: null})

		//Set course or quiz in store as current
		if (type === "course"){
			useMoocStore.setState({currentSelectedCourse: course})

			handleStartCourse(course)
		} else if (type === "quiz"){
			useMoocStore.setState({currentSelectedQuiz: course})
		}

		//Check if map instance exist
		if(!useMapStore.getState().mapInstance){
			return
		}

		//Check if course have location set only for course
		if (type === "course" && course.centerLocation && course.zoom){
			flyToMoocLocation(course.centerLocation, course.zoom)
		}
	}

	//Start set position flow
	const handleStartSetCoursePosition = (uuid) => {
		//Set data in store
		useMoocStore.setState({setPositionState: true, setPositionCourseUuid: uuid})

		//Hide map interface
		useMapStore.setState({hideInterface: true})
	}

	const handleOpenAllMoocChapter = () => {
		moocChapters.forEach(c => {
			if (!c.open){
				handleToggleChapterState(c.uuid, c.open)
			}
		})
	}

	const handleCloseAllMoocChapter = () => {
		moocChapters.forEach(c => {
			if (c.open){
				handleToggleChapterState(c.uuid, c.open)
			}
		})
	}

	const handleRenameCurrentMooc = async (value) => {
		//Check if value is valid
		if (value.length <= 0) {
			createErrorToast("Mooc name can't be empty")
			return
		}

		if (await UserMoocAPI.userRenameMooc(currentMooc.uuid, value)) {
			//Update mooc name in store
			useMoocStore.getState().updateMoocName(currentMooc.uuid, value)
			useMoocStore.getState().updateCurrentMoocName(value)

			//Update current mooc rename state
			useMoocStore.getState().updateCurrentMoocRenameState(false)
		}
	}

	const currentChapter = useMoocStore(state => state.openedMoocChapters.filter(c => currentSelectedCourse !== null && c.courses !== undefined && c.courses !== null && c.courses.some(e => e.uuid === currentSelectedCourse.uuid))[0])

	const handleStartRenameChapter = (event, uuid) => {
		event.stopPropagation()
		event.preventDefault()

		useMoocStore.getState().changeMoocChapterRenameState(uuid, true)
	}

	//Section context menu
	const [sectionContextMenuState, setSectionContextMenuState] = useState(false)
	const [sectionContextMenuUuid, setSectionContextMenuUuid] = useState(null)
	const [sectionContextMenuPosition, setSectionContextMenuPosition] = useState(0)
	const sectionContextMenuRef = useRef(null)
	const handleOpenSectionContextMenu = (event, uuid, position) => {
		event.preventDefault()
		event.stopPropagation()

		setSectionContextMenuUuid(uuid)
		setSectionContextMenuState(true)
		setSectionContextMenuPosition(position)
		getContextMenuPosition(event, sectionContextMenuRef.current)
	}

	const handleMoveUpSection = () => {
		moveUpSection(sectionContextMenuPosition)

		setSectionContextMenuUuid(null)
		setSectionContextMenuPosition(0)
	}

	const handleDeleteSection = async () => {
		if (await UserMoocChapterAPI.userDeleteSection(sectionContextMenuUuid)){
			useMoocStore.getState().removeMoocChapter(sectionContextMenuUuid)
			setSectionContextMenuUuid(null)
		}
	}

	//Subsection & Quiz context menu
	const [subSectionContextMenuState, setSubSectionContextMenuState] = useState(false)
	const [subSectionContextMenuUuid, setSubSectionContextMenuUuid] = useState(null)
	const [subSectionContextMenuType, setSubSectionContextMenuType] = useState(null)
	const [subSectionContextMenuPosition, setSubSectionContextMenuPosition] = useState(0)
	const subSectionContextMenuRef = useRef(null)

	const handleOpenSubSectionContextMenu = (event, uuid, type, position) => {
		event.preventDefault()
		event.stopPropagation()

		setSubSectionContextMenuPosition(position)
		setSubSectionContextMenuUuid(uuid)
		setSubSectionContextMenuType(type)
		setSubSectionContextMenuState(true)
		getContextMenuPosition(event, subSectionContextMenuRef.current)
	}

	const handleDeleteSubSection = async () => {
		const currentChapter = getCurrentChapterBySubsectionUuid(subSectionContextMenuUuid)

		if (subSectionContextMenuType === "course"){
			if (await UserMoocCourseAPI.userDeleteMoocCourse(subSectionContextMenuUuid)){
				useMoocStore.getState().removeChapterCourse(currentChapter.uuid, subSectionContextMenuUuid)
			}
		} else if (subSectionContextMenuType === "quiz"){
			if (await UserMoocQuizAPI.userDeleteMoocQuiz(subSectionContextMenuUuid)){
				useMoocStore.getState().removeChapterQuiz(currentChapter.uuid, subSectionContextMenuUuid)
			}
		}

		reIndexSubsectionAfterPosition(currentChapter.uuid, subSectionContextMenuPosition)

		setSubSectionContextMenuUuid(null)
		setSubSectionContextMenuType(null)
		setSubSectionContextMenuPosition(0)
	}

	const handleMoveUpSubsection = () => {
		const currentChapter = getCurrentChapterBySubsectionUuid(subSectionContextMenuUuid)
		moveUpSubsection(currentChapter.uuid, subSectionContextMenuPosition)

		setSubSectionContextMenuUuid(null)
		setSubSectionContextMenuType(null)
		setSubSectionContextMenuPosition(0)
	}

	const handleMoveDownSubsection = () => {
		const currentChapter = getCurrentChapterBySubsectionUuid(subSectionContextMenuUuid)
		moveDownSubsection(currentChapter.uuid, subSectionContextMenuPosition)

		setSubSectionContextMenuUuid(null)
		setSubSectionContextMenuType(null)
		setSubSectionContextMenuPosition(0)
	}

	const [renameOverviewState, setRenameOverviewState] = useState(false)
	const handleRenameOverview = async (event) => {
		const value = event.currentTarget.value

		//Check if value is not empty
		if (value.length <= 0){
			createErrorToast("Name can't be empty")
			return
		}

		//Close rename state
		setRenameOverviewState(false)

		//Check if new value is same to old value
		if (value === currentMooc.overviewName){
			return
		}

		//Update overview name in api and in store
		if (await UserMoocAPI.userUpdateMoocOverviewName(currentMooc.uuid, value)){
			//Update mooc overview name in store for general mooc list and current mooc
			useMoocStore.getState().updateMoocOverviewName(currentMooc.uuid, value)
			useMoocStore.getState().updateCurrentMoocOverviewName(value)
		}
	}

	useEffect(() => {
		let startPresenceTimestamp = null
		let moocPresenceUuid = null

		if (!currentMooc.userAuthorizations.includes('mooc.edit')){
			startPresenceTimestamp = parseInt((new Date().getTime() / 1000).toFixed(0))
			moocPresenceUuid = currentMooc.uuid
		}

		const executeOnClose = () => {
			let endDate = parseInt((new Date().getTime() / 1000).toFixed(0))
			const presenceTime = endDate - startPresenceTimestamp

			if (presenceTime > 0 && moocPresenceUuid !== null) {
				UserMoocParticipantAPI.userAddPresenceTime(moocPresenceUuid, presenceTime)
			}
		}

		window.addEventListener('beforeunload', executeOnClose)

		return () => {
			window.removeEventListener('beforeunload', executeOnClose)
		}
	}, []);

	useEffect(() => {
		let startPresenceTimestamp = null
		let moocPresenceUuid = null

		if (!currentMooc.userAuthorizations.includes('mooc.edit')){
			startPresenceTimestamp = parseInt((new Date().getTime() / 1000).toFixed(0))
			moocPresenceUuid = currentMooc.uuid
		}

		return () => {
			let endDate = parseInt((new Date().getTime() / 1000).toFixed(0))
			const presenceTime = endDate - startPresenceTimestamp

			if (presenceTime > 0 && moocPresenceUuid !== null) {
				UserMoocParticipantAPI.userAddPresenceTime(moocPresenceUuid, presenceTime)
			}
		}
	}, []);

	const handleStartCourse = (course) => {
		if (currentMooc.userAuthorizations.includes("mooc.edit")){
			return
		}

		//Check if course is not already started
		if (course.courseProgression === null){
			//Update course progression
			UserMoocParticipantAPI.userStartCourse(course.uuid)

			useMoocStore.getState().updateCourseProgression(getCurrentChapterBySubsectionUuid(course.uuid).uuid, course.uuid, 0)
		}
	}

	const [currentDraggedCourse, setCurrentDraggedCourse] = useState(null)
	const [currentHoveredDragCourse, setCurrentHoveredDragCourse] = useState(null)
	const [currentDragPosition, setCurrentDragPosition] = useState("")
	const handleDragStart = (uuid) => {
		setCurrentDraggedCourse(uuid)
	}

	const handleDragEnter = (uuid) => {
		setCurrentHoveredDragCourse(uuid)
	}

	const handleDragOver = (event) => {
		if (event.pageY < (event.target.getBoundingClientRect().top + (event.target.offsetHeight / 2))){
			setCurrentDragPosition('top')
		} else {
			setCurrentDragPosition("bottom")
		}
	}

	const handleDragEnd = () => {
		//Get hovered course chapter
		const hoveredCourseChapter = getCurrentChapterBySubsectionUuid(currentHoveredDragCourse)

		const chapterSubsection = hoveredCourseChapter.courses.concat(hoveredCourseChapter.quizzes)
		//Get current hovered course
		const hoveredCourse = chapterSubsection.filter(s => s.uuid === currentHoveredDragCourse)[0]
		const draggedCourse = chapterSubsection.filter(s => s.uuid === currentDraggedCourse)[0]

		const startDragPos = draggedCourse.position
		let newPosition;

		console.log(currentDragPosition, hoveredCourse.name)

		if (currentDragPosition === "top"){
			if (draggedCourse.position === hoveredCourse.position - 1){
				return;
			}
		} else {
			if (draggedCourse.position === hoveredCourse.position + 1){
				return;
			}
		}

		//Check if hovered course is first course
		if (hoveredCourse.position === 0){

			if (draggedCourse.position === 0){
				return;
			}

			//Check if is drag top or bottom
			if (currentDragPosition === "top"){
				newPosition = 0
			} else {
				newPosition = 1

			}
		} else if (hoveredCourse.position === (chapterSubsection.length - 1)){
			if (currentDragPosition === "bottom"){
				newPosition = (chapterSubsection.length - 1)
			} else {
				newPosition = (chapterSubsection.length - 1) - 1
			}
		} else {
			if (currentDragPosition === "top"){
				if (hoveredCourse.position < startDragPos){
					newPosition = hoveredCourse.position
				} else {
					newPosition = hoveredCourse.position - 1
				}
			} else {
				if (hoveredCourse.position === 0){
					newPosition = 1
				} else {
					if (hoveredCourse.position > startDragPos){
						newPosition = hoveredCourse.position
					} else {
						newPosition = hoveredCourse.position + 1
					}
				}
			}
		}

		//Check if new position is not current drag item position
		if (draggedCourse.position === newPosition){
			return
		}

		//Set new course position
		if (draggedCourse.type === "quiz"){
			useMoocStore.getState().updateQuizPosition(hoveredCourseChapter.uuid, draggedCourse.uuid, newPosition)
			UserMoocQuizAPI.userUpdateQuizPosition(draggedCourse.uuid, newPosition)
		} else {
			useMoocStore.getState().updateCoursePosition(hoveredCourseChapter.uuid, draggedCourse.uuid, newPosition)
			UserMoocCourseAPI.userUpdateCoursePosition(draggedCourse.uuid, newPosition)
		}

		//Get updated chapter subsection list
		const chapter2 = getCurrentChapterBySubsectionUuid(draggedCourse.uuid)
		const chapterSubsection2 = chapter2.courses.concat(chapter2.quizzes)
		chapterSubsection2.forEach(s => {
			if (s.position <= newPosition && s.uuid !== draggedCourse.uuid && s.position >= startDragPos){
				if (s.type === "course"){
					useMoocStore.getState().updateCoursePosition(hoveredCourseChapter.uuid, s.uuid, s.position - 1)
					UserMoocCourseAPI.userUpdateCoursePosition(s.uuid, s.position - 1)

				} else {
					useMoocStore.getState().updateQuizPosition(hoveredCourseChapter.uuid, s.uuid, s.position - 1)
					UserMoocQuizAPI.userUpdateQuizPosition(s.uuid, s.position - 1)
				}
			}

			if (s.position >= newPosition && s.uuid !== draggedCourse.uuid && s.position <= startDragPos){
				if (s.type === "course") {
					useMoocStore.getState().updateCoursePosition(hoveredCourseChapter.uuid, s.uuid, s.position + 1)
					UserMoocCourseAPI.userUpdateCoursePosition(s.uuid, s.position + 1)
				} else {
					useMoocStore.getState().updateQuizPosition(hoveredCourseChapter.uuid, s.uuid, s.position + 1)
					UserMoocQuizAPI.userUpdateQuizPosition(s.uuid, s.position + 1)
				}
			}
		})

		setCurrentHoveredDragCourse(null)
		setCurrentDragPosition("")
		setCurrentDraggedCourse(null)
	}

	return (
		<>
			{/*SECTION CONTEXT MENU*/}
			<ContextMenuContainer ref={sectionContextMenuRef} state={sectionContextMenuState} setState={setSectionContextMenuState} map={false}>
				<ContextMenuItem handler={() => {
					setSectionContextMenuState(false)
					handleDeleteSection().then()
				}} text="Delete section" icon={<MdDelete />}  />
				{subSectionContextMenuPosition > 0 && (
					<ContextMenuItem handler={() => {
						setSubSectionContextMenuState(false)
						handleMoveUpSubsection()
					}} text="Move up" icon={<FaChevronUp />}  />
				)}
			</ContextMenuContainer>
			{/*SUB SECTION CONTEXT MENU*/}
			<ContextMenuContainer ref={subSectionContextMenuRef} state={subSectionContextMenuState} setState={setSubSectionContextMenuState} map={false}>
				{subSectionContextMenuState && (
					<>
						<ContextMenuItem handler={() => {
							setSubSectionContextMenuState(false)
							handleDeleteSubSection().then()
						}} text={subSectionContextMenuType === "course" ? "Delete subsection" : "Delete quiz"} icon={<MdDelete />}  />
						{subSectionContextMenuPosition > 0 && (
							<ContextMenuItem handler={() => {
								setSubSectionContextMenuState(false)
								handleMoveUpSubsection()
							}} text="Move up" icon={<FaChevronUp />}  />
						)}
						{subSectionContextMenuPosition < (getCurrentChapterBySubsectionUuid(subSectionContextMenuUuid).courseCount + getCurrentChapterBySubsectionUuid(subSectionContextMenuUuid).quizCount - 1) && (
							<ContextMenuItem handler={() => {
								setSubSectionContextMenuState(false)
								handleMoveDownSubsection()
							}} text="Move down" icon={<FaChevronDown />}  />
						)}
					</>
				)}
			</ContextMenuContainer>
			<div className="mooc__course_modules_list_container">
				<div className="course-infos-container">
					<div className="course-thumbnail">
						{currentMooc.thumbnail && (
							<img src={currentMooc.thumbnail} alt="Course thumbnail"/>
						)}
						{!currentMooc.thumbnail && (
							<div className="icon-thumbnail"><PiBookBold /></div>
						)}
					</div>
					<div className="course-infos">
						<p className="course-creator">Weeki</p>
						<p className="course-name" onClick={() => currentMooc.userAuthorizations.includes("mooc.edit") ? useMoocStore.getState().updateCurrentMoocRenameState(true) : null}>
							{currentMooc.rename
								? <input type="text" placeholder="Mooc name" autoFocus={true} defaultValue={currentMooc.name}  onKeyDown={(event) => event.key === "Enter" ? handleRenameCurrentMooc(event.currentTarget.value) : null} onBlur={(event) => handleRenameCurrentMooc(event.currentTarget.value)} />
								: currentMooc.name
							}
						</p>
					</div>
				</div>
				<div className="course-modules-container">
					<div className="module-header">
						<p className="text">Content</p>
						{moocChapters.filter(c => c.open === true).length >= moocChapters.length && (
							<p className="open-btn" onClick={handleCloseAllMoocChapter}>Reduce All</p>
						)}
						{moocChapters.filter(c => c.open === true).length < moocChapters.length && (
							<p className="open-btn" onClick={handleOpenAllMoocChapter}>Open All</p>
						)}
					</div>
					<div className="modules-list">
						{currentMooc.userAuthorizations.includes('mooc.edit') && (
							<div className="create-chapter-btn" onClick={handleCreateChapter}>Add new section</div>
						)}
						<div className="module">
							<h2 className="module-name" onClick={() => currentMooc.userAuthorizations.includes('mooc.edit') ? setRenameOverviewState(true) : null}>
								{renameOverviewState
									? <input type="text" onKeyDown={(event) => event.key === "Enter" ? handleRenameOverview(event) : null} onBlur={handleRenameOverview} autoFocus={true} defaultValue={currentMooc.overviewName} placeholder="Overview"/>
									: currentMooc.overviewName
								}
							</h2>
							<p className="expand-btn"><IoEyeOutline /></p>
						</div>
						{moocChapters.length > 0 && (
							<>
								{moocChapters.map((chapter) => (
									<>
										<div onContextMenu={(event) => handleOpenSectionContextMenu(event, chapter.uuid, chapter.position)} className={(currentChapter !== undefined && (chapter.uuid === currentChapter.uuid)) ? "module selected" : "module"} onClick={() => handleToggleChapterState(chapter.uuid, chapter.open)}>
											<h2 className="module-name" onClick={(event) => !chapter.rename ? handleStartRenameChapter(event, chapter.uuid) : null }>
												{chapter.rename
													? <input type="text" onKeyDown={(event) => event.key === "Enter" ? handleRenameChapter(chapter.uuid, event.currentTarget.value) : null} onBlur={(event) => handleRenameChapter(chapter.uuid, event.currentTarget.value)} autoFocus={true} defaultValue={chapter.name} placeholder="Chapter name"/>
													: chapter.name
												}
											</h2>
											<div className="btn-container">
												{currentMooc.userAuthorizations.includes('mooc.edit') && (
													<>
														<p className="btn edit-btn custom__tooltip-container"
														   onClick={(event) => {
															   event.stopPropagation()
															   handleCreateChapterCourse(chapter.uuid, chapter.open).then()
														   }}>
															<MdNoteAdd/><CustomTooltip text={"Create subsection"}/></p>
															<p className="btn edit-btn custom__tooltip-container"
															   onClick={(event) => {
																   event.stopPropagation()
																   handleCreateChapterQuiz(chapter.uuid, chapter.open).then()
															   }}>
																<MdAssignmentAdd/><CustomTooltip text={"Create quiz"}/>
															</p>
													</>
												)}
												<p className="btn" onClick={(event) => {
													event.stopPropagation()
													handleToggleChapterState(chapter.uuid, chapter.open)
												}
												}>
													{chapter.open
														? <IoMdRemove/>
														: <IoMdAdd/>
													}
												</p>
											</div>
										</div>
										{chapter.open && (
											<div className="module-course-list">
												{(chapter.courses !== undefined && chapter.quizzes !== undefined) && (
													<>
														{(chapter.courses !== null || chapter.quizzes.length > 0) && (
															<>
																{chapter.courses.concat(chapter.quizzes).sort((a, b) => a.position - b.position).map((course, index) => (
																	<Fragment key={index}>
																		{index === 0 && (
																			<>
																				{(currentDragPosition === "top" && currentHoveredDragCourse === course.uuid) && (
																					<hr/>
																				)}
																			</>

																		)}
																		<div draggable={true} onDragOver={handleDragOver} onDragEnd={handleDragEnd} onDragStart={() => handleDragStart(course.uuid)} onDragEnter={() => handleDragEnter(course.uuid)} onContextMenu={(event) => handleOpenSubSectionContextMenu(event, course.uuid, course.type, course.position)} className={((currentSelectedCourse !== null && currentSelectedCourse.uuid === course.uuid) || (currentSelectedQuiz !== null && currentSelectedQuiz.uuid === course.uuid)) ? "module-course selected" : "module-course"} onClick={() => handleOpenCourse(course, course.type)}>
																			<div className="infos">
																				<p className="icon">
																					{course.type === "course" && (<IoDocumentTextOutline />)}
																					{course.type === "quiz" && (<TbListCheck />)}
																				</p>
																				{course.rename
																					? <p className="name"><input type="text" placeholder="Course name" onKeyDown={(event) => event.key === "Enter" ? handleRenameCourse(chapter.uuid, course.uuid, event.currentTarget.value, course.type) : null} onBlur={(event) => handleRenameCourse(chapter.uuid, course.uuid, event.currentTarget.value)} autoFocus={true} defaultValue={course.name} /></p>
																					: <p className="name" onClick={() => useMoocStore.getState().updateCourseRenameState(chapter.uuid, course.uuid, true, course.type)}>{course.name}</p>
																				}
																				{!currentMooc.userAuthorizations.includes('mooc.edit') && (
																					<>
																						{course.courseProgression === 1 && (
																							<IoMdCheckmarkCircleOutline color={"#2dd7a7"}/>
																						)}
																					</>
																				)}
																			</div>
																			<div className="btn-container">
																				{currentMooc.userAuthorizations.includes('mooc.edit') && (
																					<>
																						{course.type === "course" && (
																							<p className="btn edit-btn custom__tooltip-container" onClick={() => handleStartSetCoursePosition(course.uuid)}><MdAddLocationAlt /><CustomTooltip text="Link position or item" /></p>
																						)}
																					</>
																				)}
																				<p className="btn custom__tooltip-container" onClick={() => handleOpenCourse(course, course.type)}><IoEyeOutline /><CustomTooltip text={"View " + course.type}/></p>
																			</div>
																		</div>
																		{((currentDragPosition === "bottom" && currentHoveredDragCourse === course.uuid) || (currentDragPosition === "top" && (chapter.courses.concat(chapter.quizzes).sort((a, b) => a.position - b.position).findIndex(c => c.uuid === currentHoveredDragCourse) - 1) === index) ) && (
																			<hr/>
																		)}
																	</Fragment>
																))}
															</>
														)}
														{(chapter.courses.length <= 0 && chapter.quizzes.length <= 0) && (
															<div className="module-course">
																<div className="infos">
																	<p className="name">No subsections</p>
																</div>
															</div>
														)}
													</>
												)}
												{chapter.courses === undefined && (
													<>
														<Skeleton height={40} count={chapter.courseCount + chapter.quizCount} style={{marginBottom: '2px'}} />
													</>
												)}
											</div>
										)}
									</>
								))}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default CourseModulesList