import React, {useEffect, useState} from "react";
import {BsStopCircle} from "react-icons/bs";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {VscChromeClose} from "react-icons/vsc";
import {TailSpin} from "react-loader-spinner";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";
import MaintenanceAPI from "../../services/MaintenanceAPI";
import {toast} from "react-toastify";

const AdminDashboardMaintenance = () => {
    //Define default state for get maintenance request status
    const [getMaintenancesRequestStatus, setGetMaintenancesRequestStatus] = useState(false)

    const [maintenances, setMaintenances] = useState([{
        'uuid': "",
        'title': "",
        'description': "",
        'start': "",
        'end': null
    }])

    //Get maintenances
    useEffect(() => {
        MaintenanceAPI.adminGetAllMaintenances().then((response) => {
            if (response.status === 200){
                setMaintenances(response.data)
            } else {
                setMaintenances([])
            }
            setGetMaintenancesRequestStatus(true)
        })
    }, [])

    /*****************************PAGINATION NEXT MAINTENANCE*****************/
    const [currentPageNextMaintenance, setCurrentPageNextMaintenance] = useState(1)
    const [itemPerPageNextMaintenance, setItemPerPageNextMaintenance] = useState(10)
    const maxPageNextMaintenance = Math.ceil(maintenances.filter(m => m.end === null || new Date(m.end).getTime() >= new Date().getTime()).length / itemPerPageNextMaintenance)

    const nextPageNextMaintenance = () => {
        setCurrentPageNextMaintenance((currentPageNextMaintenance) => Math.min(currentPageNextMaintenance + 1, maxPageNextMaintenance))
    }
    const previousPageNextMaintenance = () => {
        setCurrentPageNextMaintenance((currentPageNextMaintenance) => Math.max(currentPageNextMaintenance - 1, 1))
    }

    const startNextMaintenance = (currentPageNextMaintenance - 1) * itemPerPageNextMaintenance
    const endNextMaintenance = startNextMaintenance + itemPerPageNextMaintenance
    const paginatedNextMaintenance = maintenances.filter(m => m.end === null || new Date(m.end).getTime() >= new Date().getTime()).slice(startNextMaintenance, endNextMaintenance)

    const pageButtonsNextMaintenance = []

    for (let i = 0; i < maxPageNextMaintenance; i++){
        pageButtonsNextMaintenance.push(<p onClick={() => setCurrentPageNextMaintenance(i + 1)} className={currentPageNextMaintenance === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************PAGINATION PREVIOUS MAINTENANCE******************/
    const [currentPagePreviousMaintenance, setCurrentPagePreviousMaintenance] = useState(1)
    const [itemPerPagePreviousMaintenance, setItemPerPagePreviousMaintenance] = useState(10)
    const maxPagePreviousMaintenance = Math.ceil(maintenances.filter(m => m.end !== null && new Date(m.end).getTime() < new Date().getTime()).length / itemPerPagePreviousMaintenance)

    const nextPagePreviousMaintenance = () => {
        setCurrentPagePreviousMaintenance((currentPagePreviousMaintenance) => Math.min(currentPagePreviousMaintenance + 1, maxPagePreviousMaintenance))
    }
    const previousPagePreviousMaintenance = () => {
        setCurrentPagePreviousMaintenance((currentPagePreviousMaintenance) => Math.max(currentPagePreviousMaintenance - 1, 1))
    }

    const startPreviousMaintenance = (currentPagePreviousMaintenance - 1) * itemPerPagePreviousMaintenance
    const endPreviousMaintenance = startPreviousMaintenance + itemPerPagePreviousMaintenance
    const paginatedPreviousMaintenance = maintenances.filter(m => m.end !== null && new Date(m.end).getTime() < new Date().getTime()).slice(startPreviousMaintenance, endPreviousMaintenance)

    const pageButtonsPreviousMaintenance = []

    for (let i = 0; i < maxPagePreviousMaintenance; i++){
        pageButtonsPreviousMaintenance.push(<p onClick={() => setCurrentPagePreviousMaintenance(i + 1)} className={currentPagePreviousMaintenance === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************NEW MAINTENANCE*****************************/
    const [newMaintenanceModalStatus, setNewMaintenanceModalStatus] = useState(false)

    const [newMaintenanceInfos, setNewMaintenanceInfos] = useState({
        'title': "",
        'description': "",
        'start': "",
        'end': ""
    })

    const createNewMaintenance = async () => {
        const startDate = new Date(newMaintenanceInfos.start).getTime()
        const endDate = new Date(newMaintenanceInfos.end).getTime()

        if (newMaintenanceInfos.title === ""){
            toast.error('Maintenance title cannot be empty')
            return
        }
        if (newMaintenanceInfos.description === ""){
            toast.error('Maintenance description cannot be empty')
            return
        }
        if (newMaintenanceInfos.start === ""){
            toast.error('Maintenance start date cannot be empty')
            return
        }
        if (newMaintenanceInfos.end === ""){
            toast.error('Maintenance end date cannot be empty')
            return
        }
        if (startDate >= endDate){
            toast.error('End date cannot be before start date')
            return
        }

        setNewMaintenanceModalStatus(false)

        if (await MaintenanceAPI.adminCreateNewMaintenance(newMaintenanceInfos)){
            toast.success("Maintenance scheduled successfully")
            setGetMaintenancesRequestStatus(false)
            setNewMaintenanceModalStatus(false)
            setNewMaintenanceInfos({
                'title': "",
                'description': "",
                'start': "",
                'end': ""
            })
            MaintenanceAPI.adminGetAllMaintenances().then((response) => {
                if (response.status === 200){
                    setMaintenances(response.data)
                } else {
                    setMaintenances([])
                }
                setGetMaintenancesRequestStatus(true)
            })

        } else {
            setNewMaintenanceModalStatus(true)
        }
    }
    /*************************************************************************/

    const stopMaintenance = async (event) => {
        const maintenanceUuid = event.currentTarget.id

        if (await MaintenanceAPI.adminStopMaintenance(maintenanceUuid)){
            toast.success('Maintenance stopped')
            setGetMaintenancesRequestStatus(false)
            MaintenanceAPI.adminGetAllMaintenances().then((response) => {
                if (response.status === 200){
                    setMaintenances(response.data)
                } else {
                    setMaintenances([])
                }
                setGetMaintenancesRequestStatus(true)
            })
        }
    }

    const deleteMaintenance = async (event) => {
        const maintenanceUuid = event.currentTarget.id

        if (await MaintenanceAPI.adminDeleteMaintenance(maintenanceUuid)){
            toast.success('Maintenance delete successfully')
            setGetMaintenancesRequestStatus(false)
            MaintenanceAPI.adminGetAllMaintenances().then((response) => {
                if (response.status === 200){
                    setMaintenances(response.data)
                } else {
                    setMaintenances([])
                }
                setGetMaintenancesRequestStatus(true)
            })
        }
    }

    return (
        <>
            {/*NEW MAINTENANCE MODAL*/}
            {newMaintenanceModalStatus && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="header">
                            <h1>Schedule maintenance</h1>
                            <p onClick={() => setNewMaintenanceModalStatus(false)}><VscChromeClose /></p>
                        </div>
                        <div className="content">
                            <p className="title">Title:</p>
                            <div className="text-input-container">
                                <input type="text" value={newMaintenanceInfos.title} onChange={(event) => setNewMaintenanceInfos({...newMaintenanceInfos, title: event.currentTarget.value})}/>
                            </div>
                            <p className="title mt">Description</p>
                            <div className="text-area-container">
                                <div className="label-container">
                                    <p className="label"> </p>
                                    <p className="word-counter">{newMaintenanceInfos.description.length + 1}/100</p>
                                </div>
                                <textarea className={newMaintenanceInfos.description.length > 0 ? "active" : null} name="maintenance-description" id="maintenance-description" rows="3" value={newMaintenanceInfos.description} onChange={(event) => event.currentTarget.value.length < 100 ? setNewMaintenanceInfos({...newMaintenanceInfos, description: event.currentTarget.value}) : null}/>
                            </div>
                            <p className="title mt">Start date:</p>
                            <div className="date-filter-container">
                                <input value={newMaintenanceInfos.start} onChange={(event) => setNewMaintenanceInfos({...newMaintenanceInfos, start: event.currentTarget.value})} type="datetime-local"/>
                            </div>
                            <p className="title mt">End date:</p>
                            <div className="date-filter-container">
                                <input value={newMaintenanceInfos.end} onChange={(event) => setNewMaintenanceInfos({...newMaintenanceInfos, end: event.currentTarget.value})} type="datetime-local"/>
                            </div>
                        </div>
                        <div className="footer">
                            <p> </p>
                            <button className="btn-primary" onClick={createNewMaintenance}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-dashboard">
                    <h1 className="page-title">Overview</h1>
                    <div className="dashboard-table mt">
                        <p className="title">List of scheduled maintenances</p>
                        <div className="top-container">
                            <div className="left">
                                <div onClick={() => setNewMaintenanceModalStatus(true)} className="filter-btn">Schedule new maintenance</div>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Description</td>
                                    <td>Start date</td>
                                    <td>End date</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                            {getMaintenancesRequestStatus && (
                                <>
                                    {paginatedNextMaintenance.length > 0 && (
                                        <>
                                            {paginatedNextMaintenance.map((maintenance, index) => (
                                                <tr key={index}>
                                                    <td>{maintenance.title}</td>
                                                    <td>{maintenance.description}</td>
                                                    <td>{new Date(maintenance.start).toLocaleString('fr')}</td>
                                                    <td>{new Date(maintenance.end).toLocaleString('fr')}</td>
                                                    <td className='action-cell'>
                                                        {new Date(maintenance.start).getTime() > new Date().getTime() && (
                                                            <p className="delete" id={maintenance.uuid} onClick={deleteMaintenance}><MdDelete /></p>
                                                        )}
                                                        {new Date(maintenance.start).getTime() <= new Date().getTime() && (
                                                            <>
                                                                {new Date(maintenance.end).getTime() > new Date().getTime() && (
                                                                    <p className="delete" id={maintenance.uuid} onClick={stopMaintenance}><BsStopCircle /></p>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedNextMaintenance.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No scheduled maintenance</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getMaintenancesRequestStatus && (
                                <tr>
                                    <td colSpan="100%" style={{textAlign: 'center'}}><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPageNextMaintenance(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPageNextMaintenance}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtonsNextMaintenance.length > 0 && (
                                        pageButtonsNextMaintenance
                                    )}
                                    {pageButtonsNextMaintenance.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPageNextMaintenance}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {startNextMaintenance + 1} to {endNextMaintenance} of {paginatedNextMaintenance.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of previous maintenances</p>
                        <table>
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Description</td>
                                    <td>Start date</td>
                                    <td>End date</td>
                                </tr>
                            </thead>
                            <tbody>
                            {getMaintenancesRequestStatus && (
                                <>
                                    {paginatedPreviousMaintenance.length > 0 && (
                                        <>
                                            {paginatedPreviousMaintenance.map((maintenance, index) => (
                                                <tr key={index}>
                                                    <td>{maintenance.title}</td>
                                                    <td>{maintenance.description}</td>
                                                    <td>{new Date(maintenance.start).toLocaleString('fr')}</td>
                                                    <td>{new Date(maintenance.end).toLocaleString('fr')}</td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedPreviousMaintenance.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No previous maintenance</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getMaintenancesRequestStatus && (
                                <tr>
                                    <td colSpan="100%" style={{textAlign: 'center'}}><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPagePreviousMaintenance(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPagePreviousMaintenance}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtonsPreviousMaintenance.length > 0 && (
                                        pageButtonsPreviousMaintenance
                                    )}
                                    {pageButtonsPreviousMaintenance.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPagePreviousMaintenance}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {startPreviousMaintenance + 1} to {endPreviousMaintenance} of {paginatedPreviousMaintenance.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <p className="copy">&copy; Weeki 2021</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardMaintenance