import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import MapAPI from "../services/MapAPI";
import {toast} from "react-toastify";

//IMPORT ICONS
import weeki_logo from "../../icons/map/weeki-logo-2.svg"
import my_space_icon from "../../icons/map/my-space.svg"
import SimpleShareMapPanel from "./Map/SimpleShareMapPanel";
import TopbarUserMenu from "./TopbarUserMenu";
import {useMapStore, useUserStore} from "../services/Stores";
import UsersOnMapAPI from "../services/UsersOnMapAPI";
import {useDashboardStore} from "../services/Stores/DashboardStore";
import {DASHBOARD_MAP} from "../DashboardMap";
import {MdInfoOutline} from "react-icons/md";
import CustomTooltip from "./customs/CustomTooltip";

const MapNavBar = ({map, updateMapInfo, publicMap = false, handleRestartTutorial}) => {
    //Get users on map in map store
    const usersOnMap = useMapStore(state => state.usersOnMap)
    const userAuthorizations = useMapStore(state => state.userAuthorizations)

    useEffect(() => {
        const checkUserOnMapInterval = setInterval(() => {
            UsersOnMapAPI.checkUserOnMap(useMapStore.getState().mapUuid)
        }, 240000)

        return () => {
            clearInterval(checkUserOnMapInterval)
        }
    }, [])

    //Define default state for share menu status
    const [shareMapMenuStatus, setShareMapMenuStatus] = useState(false)

    const user = useUserStore(state => state.user)

    //Import history
    const history = useHistory()

    /*******************UPDATE MAP NAME*********************/
    const [updateMapNameInputStatus, setUpdateMapNameInputStatus] = useState(false)
    const [updateMapNameInputValue, setUpdateMapNameInputValue] = useState("")

    const updateMapName = async () => {
        setUpdateMapNameInputStatus(false)

        //Check if map name is not empty
        if (updateMapNameInputValue === ""){
            return
        }

        //Check if new name is not same as old name
        if (updateMapNameInputValue.toLowerCase() === map.name.toLowerCase()){
            return
        }

        if (await MapAPI.renameMap(map.id, updateMapNameInputValue)){
            updateMapInfo()
            toast.success("Map name updated successfully")
        }
    }
    /*******************************************************/

    const handleRedirectToUserDashboard = () => {
        //Get current dashboard
        const currentDashboard = useDashboardStore.getState().currentDashboard

        if (currentDashboard){
            history.push(currentDashboard.route)
        } else {
            history.push(Object.values(DASHBOARD_MAP.user).filter(r => r.default)[0].route)
        }
    }

    return (
        <>
        <div className="topbar-container-map">
            <div className="left">
                <img className="logo" onClick={handleRedirectToUserDashboard} src={weeki_logo} alt="Weeki logo"/>
                <div className="map-name-container">
                    {!publicMap && (
                        <>
                            <img className="icon" src={my_space_icon} alt="my space icon"/>
                            <p className="space-btn" onClick={handleRedirectToUserDashboard}>My Space</p>
                            <p className="separator">/</p>
                        </>
                    )}
                    <div className="map-name-input-container">
                        {!publicMap && (
                            <>
                                {!updateMapNameInputStatus && (
                                    <p className="map-name" onClick={() => setUpdateMapNameInputStatus(true)}>{map.name}</p>
                                )}
                                {updateMapNameInputStatus && (
                                    <input type="text" onBlur={updateMapName} className="input-map-name" placeholder="Map name" value={updateMapNameInputValue} onChange={(event) => setUpdateMapNameInputValue(event.currentTarget.value)}/>
                                )}
                            </>
                        )}
                        {publicMap && (
                            <p className="map-name public">{map.name}</p>
                        )}
                    </div>
                </div>
                <div className="icon-btn-container">
                    <div className="icon-btn custom__tooltip-container" onClick={() => handleRestartTutorial()}><MdInfoOutline size={18} /><CustomTooltip text={"Restart tutorial"} /></div>
                </div>
            </div>
            <div className="right">
                {!publicMap && (
                    <div className="present-user-container">
                        {usersOnMap.filter(u => u.email !== user.email).slice(0, 5).map((userOnMap, index) => (
                            <Fragment key={index}>
                                <p style={index > 0 ? {transform: 'translateX(-' + (index * 11) + 'px)'} : {}} className="user-badge">{userOnMap.firstname.slice(0, 1).toUpperCase()}{userOnMap.lastname.slice(0, 1).toUpperCase()}</p>
                                {usersOnMap.filter(u => u.email !== user.email).length > 5 && (
                                    <>
                                        {index === 4 && (
                                            <p style={index > 0 ? {transform: 'translateX(-' + ((index + 1) * 11) + 'px)'} : {}} className="user-badge count">+{usersOnMap.filter(u => u.email !== user.email).slice(5, usersOnMap.filter(u => u.email !== user.email).length).length}</p>
                                        )}
                                    </>
                                )}
                            </Fragment>
                        ))}
                    </div>
                )}
                {!publicMap && (
                    <div className="share-map-button-container">
                        {userAuthorizations.includes('map.manage') && (
                            <>
                                <p className="share-btn" onClick={() => setShareMapMenuStatus(!shareMapMenuStatus)}>Share</p>
                                {shareMapMenuStatus && (
                                    <SimpleShareMapPanel mapUuid={map.id} />
                                )}
                            </>
                        )}
                    </div>
                )}
                {!publicMap && (
                    <>
                        <TopbarUserMenu />
                    </>
                )}

            </div>
        </div>
        </>
    )
}

export default MapNavBar