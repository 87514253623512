import {ADMIN_MAINTENANCE_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function adminGetAllMaintenances(){
    return getAxiosInstance().get(ADMIN_MAINTENANCE_API + "/get_all")
}

function adminCreateNewMaintenance(maintenanceInfos){
    toast.loading("Maintenance creation in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append('title', maintenanceInfos.title)
    sendData.append('description', maintenanceInfos.description)
    sendData.append('start', maintenanceInfos.start)
    sendData.append('end', maintenanceInfos.end)

    return getAxiosInstance().post(ADMIN_MAINTENANCE_API + '/schedule', sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })

}

function adminStopMaintenance(maintenanceUuid){
    toast.loading("Maintenance stop in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append('maintenanceUuid', maintenanceUuid)

    return getAxiosInstance().post(ADMIN_MAINTENANCE_API + '/stop', sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })

}

function adminDeleteMaintenance(maintenanceUuid){
    toast.loading("Maintenance delete in progress", {toastId: "loadAlert"})

    return getAxiosInstance().delete(ADMIN_MAINTENANCE_API + '/delete', {params: {maintenanceUuid: maintenanceUuid}})
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })

}

export default {
    adminGetAllMaintenances,
    adminCreateNewMaintenance,
    adminStopMaintenance,
    adminDeleteMaintenance
}