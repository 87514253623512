import React, {useEffect, useRef, useState} from "react";
import {EMAIL_REGEX} from "../../../Regex";
import {IoSend} from "react-icons/io5";
import {toast} from "react-toastify";
import UserMapCollaboratorAPI from "../../services/API/User/UserMapCollaboratorAPI";
import Skeleton from "react-loading-skeleton";
import UserMapAPI from "../../services/API/User/UserMapAPI";
import {useMapStore, useUserStore} from "../../services/Stores";
import {createErrorToast} from "../../services/CustomToastUtils";
import ManageAuthorizationArrayPopup from "../customs/popups/ManageAuthorizationArrayPopup";
import UserInvitationAPI from "../../services/API/User/UserInvitationAPI";

const SimpleShareMapPanel = ({mapUuid}) => {
    //Define default component state
    const [getMapCollaboratorsStatus, setGetMapCollaboratorsStatus] = useState(false)
    const [userInvitations, setUserInvitations] = useState([])
    const [availableAuthorizations, setAvailableAuthorizations] = useState([])
    const inviteInputRef = useRef(null)
    const [manageAuthorizationPopupState, setManageAuthorizationPopupState] = useState(false)

    //Get current map user authorization
    const userAuthorizations = useMapStore(state => state.userAuthorizations)

    useEffect(() => {
        if (mapUuid !== ""){
            setGetMapCollaboratorsStatus(false)

            UserMapAPI.userGetAllMapInvitations(mapUuid).then((response) => {
                if (response.status === 200){
                    setUserInvitations(response.data.invitations)
                    setAvailableAuthorizations(response.data.availableAuthorizations)
                }
                setGetMapCollaboratorsStatus(true)
            })
        }

    }, [mapUuid])

    //Invite user on map
    const inviteTypeSelectRef = useRef(null)
    const handleInviteUser = async () => {
        const userEmail = inviteInputRef.current.value
        const invitationType = inviteTypeSelectRef.current.value

        if (invitationType !== "viewer" && invitationType !== "editor"){
            createErrorToast("Invalid invitation type")
            return
        }

        //Check if user email is valid
        if (!EMAIL_REGEX.test(userEmail) || userEmail.length <= 0) {
            createErrorToast("Invalid email address")
            return
        }

        const uuid = await UserInvitationAPI.userInviteUser('map', mapUuid, invitationType, userEmail)

        //Add new user invitation to list
        if (uuid) {
            setUserInvitations([...userInvitations, {'uuid': uuid, 'state': 'pending', 'userEmail': userEmail}])

            inviteInputRef.current.value = ""
        }
    }

    //Revoke user map authorization
    const handleRevokeUser = async (invitationUuid) => {
        if (await UserMapAPI.userRevokeUser(mapUuid, invitationUuid)){
            setUserInvitations(userInvitations.filter(i => i.uuid !== invitationUuid))
        }
    }

    return (
        <>
            {manageAuthorizationPopupState && (
                <ManageAuthorizationArrayPopup setState={setManageAuthorizationPopupState} userInvitations={userInvitations} setUserInvitations={setUserInvitations} availableAuthorizations={availableAuthorizations} entityUuid={mapUuid}/>
            )}
            <div className="simple-share-map-container">
                <h1 className="title">Share your map to registered user</h1>
                <div className="input-container">
                    <input ref={inviteInputRef} onKeyDown={(event) => event.key === "Enter" ? handleInviteUser() : null} type="text" placeholder="Invite user by email"/>
                    <div className="right">
                        <select ref={inviteTypeSelectRef}>
                            <option value="editor">Editor</option>
                            <option value="viewer">Viewer</option>
                        </select>
                        <div className="valid-btn" onClick={handleInviteUser}><IoSend /></div>
                    </div>
                </div>
                <hr className="separator"/>
                <div className="invitation-list-container">
                    {!getMapCollaboratorsStatus && (
                        <Skeleton height={31} count={1} />
                    )}
                    {userInvitations.length <= 0 && (
                        <div className="no-collaborator">No collaborators</div>
                    )}
                    {userInvitations.length > 0 && (
                        <>
                            {userInvitations.map((invitations) => (
                                <>
                                    {(invitations.userEmail !== useUserStore.getState().user.email && !invitations.owner) && (
                                        <div className="invitation">
                                            <div className="invitation-infos">
                                                <p className="user-name">{invitations.userEmail}</p>
                                                <p className="status">{invitations.state}</p>
                                            </div>
                                            <div className="invitation-actions">
                                                {invitations.state === "pending" && (
                                                    <>
                                                        {/*<p className="btn">Re send</p>*/}
                                                        <p className="btn red" onClick={() => handleRevokeUser(invitations.uuid)}>Revoke</p>
                                                    </>
                                                )}
                                                {invitations.state === "accepted" && (
                                                    <>
                                                        <p className="btn red" onClick={() => handleRevokeUser(invitations.uuid)}>Revoke</p>
                                                    </>
                                                )}
                                                {invitations.state === "declined" && (
                                                    <>
                                                        <p className="btn red">Remove</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                        </>
                    )}
                </div>
                {userAuthorizations.includes('map.manage') && (
                    <p className="manage-btn" onClick={() => setManageAuthorizationPopupState(true)}>Manage board authorizations</p>
                )}
            </div>
        </>
    )
}

export default SimpleShareMapPanel