import React, {useRef} from "react";
import ContextMenuContainer from "../../customs/content_menu/ContextMenuContainer";
import {useMapContextMenuStore} from "../../../services/Stores/MapContextMenuStore";
import ContextMenuItem from "../../customs/content_menu/ContextMenuItem";
import {MdDelete, MdEdit} from "react-icons/md";
import {deleteZone, selectZoneFromFeature} from "../../../services/Utils/ZoneUtils";
import {IoMdCopy, IoMdCut} from "react-icons/io";
import {useMapZoneStore} from "../../../services/Stores/MapZoneStore";
import {useMapStore} from "../../../services/Stores";

const ZoneContextMenu2 = () => {
	const menuRef = useRef(null)

	return (
		<>
			<ContextMenuContainer ref={menuRef} state={useMapContextMenuStore.getState().state} setState={() => null} map={true}>
				<ContextMenuItem text="Rename" icon={<MdEdit />} handler={() => selectZoneFromFeature(useMapContextMenuStore.getState().feature, true)} />
				<ContextMenuItem text="Delete" icon={<MdDelete />} handler={() => deleteZone(useMapContextMenuStore.getState().feature.properties, true)} />
				<ContextMenuItem text="Copy" icon={<IoMdCopy />} handler={() => {
					useMapZoneStore.setState({currentCopiedZone: useMapContextMenuStore.getState().feature.properties.id, copiedOriginZoom: useMapStore.getState().mapInstance.getZoom(), copyType: 'copy'})
					useMapContextMenuStore.getState().resetStore()
				}} />
				<ContextMenuItem text="Cut" icon={<IoMdCut />} handler={() => {
					useMapZoneStore.setState({currentCopiedZone: useMapContextMenuStore.getState().feature.properties.id, copiedOriginZoom: useMapStore.getState().mapInstance.getZoom(), copyType: 'cut'})
					useMapContextMenuStore.getState().resetStore()
				}} />
			</ContextMenuContainer>
		</>
	)
}

export default ZoneContextMenu2