import React, {useEffect, useRef, useState} from "react";
import TfaAPI from "../services/TfaAPI";
import {VscChromeClose} from "react-icons/vsc";
import {toast} from "react-toastify";
import Skeleton from "react-loading-skeleton";
import {useUserStore} from "../services/Stores";

const TotpActivationPopup = ({state, setState}) => {
    const [totpQrCode, setTotpQrCode] = useState("")

    const user = useUserStore(state1 => state1.user)

    //Get totp qrcode
    useEffect(() => {
        TfaAPI.getActiveTotpQrCode().then((response) => {
            if (response.status === 200){
                setTotpQrCode(response.data['totpQRCode'])
            } else {
                setState(false)
            }
        })
        totpInputs = []
    }, [])

    let totpInputs = []
    for (let i = 0; i < 6; i++){
        if (totpInputs.length < 6){
            totpInputs.push("")
        }
    }
    const availableTotpChar = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    const [totpCode, setTotpCode] = useState("")

    //Define totp ref
    let totpInputsRef = useRef([])

    const handlePressTotpKey = (event, index) => {

        if (event.key === "Backspace"){
            setTotpCode("")
            if (totpInputsRef.current[index].value === ""){
                event.preventDefault()
                if (index !== 0){
                    totpInputsRef.current[index - 1].focus()
                }
            }
            return
        }

        if (availableTotpChar.some(char => event.key === char)){
            event.preventDefault()
            totpInputsRef.current[index].value = event.key

            if (index < (totpInputsRef.current.length - 1)){
                setTotpCode("")
                totpInputsRef.current[index + 1].focus()
            }

            if (index === (totpInputsRef.current.length - 1)){
                let code = ""
                for (let i = 0; i < totpInputsRef.current.length; i++){
                    code = code + totpInputsRef.current[i].value
                }

                setTotpCode(code)

                handleValidTotpActivation(code).then()
            }
        }
    }

    const handleValidTotpActivation = async (code) => {
        if (await TfaAPI.activeTotpTfa(code)) {
            toast.success("Two factor authentication activated")
            setState(false)
            user.totpStatus = false
        }
    }

    return (
        <div className="totp-popup-overlay">
            <div className="totp-popup-container">
                <p className="close-btn" onClick={() => setState(false)}><VscChromeClose /></p>
                    <div className="qr-code-container">
                        {totpQrCode !== "" && (
                            <img src={totpQrCode} alt="QR code"/>
                        )}
                        {totpQrCode === "" && (
                            <Skeleton height={200} width={200} />
                        )}
                    </div>
                <p className="valid-text">Enter code for valid activation</p>
                <div className="totp-form-container">
                    {totpInputs.map((input, index) => (
                        <input maxLength={1} onKeyDown={(event) => handlePressTotpKey(event, index)} key={index} ref={(element) => totpInputsRef.current.length < 6 ? totpInputsRef.current.push(element) : null}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TotpActivationPopup