import React from "react";

const AdminHome = () => {
	return (
		<>
			<h1>Home test</h1>
		</>
	)
}

export default AdminHome