import {useMapStore} from "../Stores";
import RefreshTilesUtils from "../RefreshTilesUtils";
import {useMapShapeItemStore} from "../Stores/MapShapeItemStore";
import {
	generateAreaMock, generateCircleShapeMock,
	generateLineShapeMock,
	generatePolygonShapeMock,
	generatePolyLineShapeMock, generateRectangleShapeMock
} from "./MockUtils";
import {addShapeMockEvent} from "./MockEventsUtils";
import {useMapContextMenuStore} from "../Stores/MapContextMenuStore";
import ShapeItemAPI from "../API/User/ShapeItemAPI";
import {createSuccessToast} from "../CustomToastUtils";
import {hexToRGB} from "../Utils";

export function renderNewShapeItem(bounds){
	//Refresh area and area title layer
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_shapes', bounds, true).then()
		}
	})
}

export function updateShapeLocation(newBounds, oldBounds = null){
	//Refresh shape old and new tiles
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_shapes', newBounds, true).then()

			if (oldBounds){
				RefreshTilesUtils.invalidateArea(layer, 'weeki_map_shapes', oldBounds, true).then()
			}
		}
	})
}

export function detectShapeItemClick(selection){
	if (selection === undefined){
		return;
	}

	//Get feature in selection
	const feature = selection.feature

	//Check if feature exist
	if (feature !== undefined){
		//Check if click is on shape line
		if (!feature.layers.includes("weeki_map_shapes_lines")){
			return;
		}

		//Check ref zoom for interaction
		if ((feature.properties.ref_zoom - 3) > useMapStore.getState().mapInstance.getZoom()){
			return;
		}

		//Ignore click event if current feature is already selected
		if (useMapShapeItemStore.getState().currentSelectedShape === feature.properties.id){
			return
		}

		//Unselect previous selected shape if exist
		if (useMapShapeItemStore.getState().currentSelectedShape !== null){
			unselectShapeItem()
		}

		//Add selected shape uuid to store
		useMapShapeItemStore.setState({currentSelectedShape: feature.properties.id})

		//Generate mock for shape
		let shapeMock = null
		if (feature.properties.type === "line"){
			shapeMock = generateLineShapeMock(feature.properties.shape, feature.properties['border_width'])
		} else if (feature.properties.type === "polyline"){
			shapeMock = generatePolyLineShapeMock(feature.properties.shape, feature.properties['border_width'])
		} else if (feature.properties.type === "rectangle") {
			shapeMock = generateRectangleShapeMock(feature.properties.shape, feature.properties['border_width'])
		} else if (feature.properties.type === "circle"){
			shapeMock = generateCircleShapeMock(feature.properties.shape, feature.properties['border_width'], feature.properties)
		} else {
			shapeMock = generatePolygonShapeMock(feature.properties.shape, feature.properties['border_width'])
		}

		//Add mock to map instance
		shapeMock.addTo(useMapStore.getState().mapInstance)

		//Add shape mock to store
		useMapShapeItemStore.setState({currentSelectedMock: shapeMock})

		//Add shape mock event
		addShapeMockEvent(shapeMock, feature.properties.type)
	} else {
		if (!useMapShapeItemStore.getState().currentDragState){
			unselectShapeItem()
		}
	}
}

export function unselectShapeItem(){
	if (useMapShapeItemStore.getState().currentDragState){
		return
	}

	//Delete mock if exist
	if (useMapShapeItemStore.getState().currentSelectedMock !== null){
		useMapShapeItemStore.getState().currentSelectedMock.remove()
	}

	//Update store
	useMapShapeItemStore.setState({currentSelectedShape: null, currentSelectedMock: null})
}

export function detectAndOpenShapeContextMenu(selection, pixel){
	if (selection === undefined){
		return
	}

	//Get feature from selection
	const feature = selection.feature

	//Check if feature exist
	if (feature !== undefined){
		//Close all context menu
		useMapContextMenuStore.getState().resetStore()

		//Open shape context menu
		useMapContextMenuStore.getState().openShapeMenu(feature.properties.id, pixel.x, pixel.y, feature)
	}
}

export async function deleteShapeItem(shapeUuid) {
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Delete shape in api
	await ShapeItemAPI.deleteShapeItem(shapeUuid)

	//Unselect shape
	unselectShapeItem()
}

export function copyShapeItem(shapeUuid){
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Set data in shape item store
	useMapShapeItemStore.setState({copiedShapeUuid: shapeUuid, copyType: 'copy'})

	//Send toast
	createSuccessToast("Shape copied successfully")
}

export function cutShapeItem(shapeUuid){
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Set data in shape item store
	useMapShapeItemStore.setState({copiedShapeUuid: shapeUuid, copyType: 'cut'})

	//Send toast
	createSuccessToast("Shape cut successfully")
}

export function pasteShapeItem(){
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	console.log("paste shape")
}

export function updateShapeColor(color, uuid){
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Convert color to rgb
	const rgbColor = hexToRGB(color)

	ShapeItemAPI.userUpdateShapeItemColor(uuid, rgbColor)
}

export function updateShapeFillColor(color, opacity, uuid){
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Convert color to rgb
	const rgbColor = hexToRGB(color)

	ShapeItemAPI.userUpdateShapeItemFillColor(uuid, rgbColor, opacity)
}

export function updateShapeWidth(width, uuid){
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	ShapeItemAPI.userUpdateShapeItemBorderWidth(uuid, width)
}