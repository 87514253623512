import React, {useEffect, useState} from "react";
import AdminMoocAPI from "../../../services/API/Admin/AdminMoocAPI";
import {FaFilter} from "react-icons/fa";
import {IoSearchSharp} from "react-icons/io5";
import {ThreeDots} from "react-loader-spinner";
import {AiFillEye} from "react-icons/ai";

const AdminStories = () => {
	const [getStoriesState, setGetStoriesState] = useState(false)
	const [publishedStories, setPublishedStories] = useState([])
	const [requestedPublishStories, setRequestedPublishStories] = useState([])

	useEffect(() => {
		AdminMoocAPI.adminGetAllMoocs().then((response) => {
			if (response.status === 200){
				setPublishedStories(response.data.filter(s => s.publishState === 2))
				setRequestedPublishStories(response.data.filter(s => s.publishState === 1))
			}
			setGetStoriesState(true)
		})
	}, []);

	const [searchRequestedStory, setSearchRequestedStory] = useState("")
	return (
		<>
			{/*Requested published story*/}
			<div className="dashboard-table mt">
				<p className="title">Requested story publication</p>
				<div className="top-container">
					<div className="left">
						<div className="filter-btn"><FaFilter/></div>
						<div className="search-container">
							<div className="box">
								<div className="icon"><IoSearchSharp/></div>
								<input type="text" placeholder="Search" value={searchRequestedStory}
									   onChange={(event) => setSearchRequestedStory(event.currentTarget.value)}/>
							</div>
						</div>
					</div>
				</div>
				<table style={{tableLayout: "auto"}}>
					<thead>
					<tr>
						<td>Name</td>
						<td>Actions</td>
					</tr>
					</thead>
					<tbody>
					{!getStoriesState && (
						<tr>
							<td colSpan={2}><ThreeDots
								wrapperStyle={{display: 'flex', justifyContent: 'center'}}
								color="#2953e8" width={35} height={30}/></td>
						</tr>
					)}
					{getStoriesState && (
						<>
							{requestedPublishStories.length > 0 && (
								<>
									{requestedPublishStories.map((story) => (
										<tr>
											<td>{story.name}</td>
											<td className="action-cell">
												<p><AiFillEye size={18}/></p>
											</td>
										</tr>
									))}
								</>
							)}
							{requestedPublishStories.length <= 0 && (
								<tr>
									<td colSpan={2} style={{textAlign: "center"}}>No created stories</td>
								</tr>
							)}
						</>
					)}
					</tbody>
				</table>
			</div>
		</>
	)
}

export default AdminStories