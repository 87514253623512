import Axios from "axios"
import jwtDecode from "jwt-decode";
import {LOGIN_API, MERCURE_URL, OAUTH_API, SECURITY_CTRL, SSO_API} from "../../Router";
import {Cookies} from "react-cookie";
import {getCookie} from "./Utils";
import getAxiosInstance from "./AxiosInstance";
import {useMercureStore, useUserStore} from "./Stores";

function login(credentials, platform, totpCode, stayConnected){
    const sendData = new FormData()
    sendData.append('email', credentials.email)
    sendData.append('password', credentials.password)
    sendData.append('platform', platform)
    sendData.append('totpCode', totpCode)
    sendData.append('stayConnected', stayConnected)

    return getAxiosInstance().post(LOGIN_API, sendData)
        .then((response) => {
            if (response){
                if (response.status === 200){
                    window.localStorage.setItem("authToken", response.data.token)
                    setAxiosToken(response.data.token)
                    const mercureToken = response.data.mercureToken
                    const mercureCookie = new Cookies()
                    mercureCookie.set('mercureAuthorization', mercureToken, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})
                    useMercureStore.setState({mercureToken: mercureToken})
                    openMercureEventSource(mercureToken)

                    const authCookie = new Cookies()
                    if (process.env.REACT_APP_ENV_TYPE === "prod"){
                        authCookie.set('authCookie', response.data.token, {path: "/", maxAge: Number.MAX_SAFE_INTEGER, domain: "weeki.io"})
                    } else {
                        authCookie.set('authCookie', response.data.token, {path: "/", maxAge: Number.MAX_SAFE_INTEGER, domain: "localhost"})
                    }

                    if (stayConnected){
                        const refreshTokenCookie = new Cookies()
                        refreshTokenCookie.set('refreshToken', response.data.refreshToken, {path: "/", maxAge: 604800})
                    }

                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
        .catch((error) => {
            return false
        })
}

function checkUserLoginSession (credentials){
    const sendData = new FormData()
    sendData.append('email', credentials.email)
    sendData.append('password', credentials.password)

    return getAxiosInstance().post(SECURITY_CTRL + '/check_login_session', sendData)
}

function closeLoginSession(sessionUuid){
    const sendData = new FormData()
    sendData.append('uuid', sessionUuid)

    return getAxiosInstance().post(SECURITY_CTRL + '/close_session', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }

            return false
        })
        .catch((error) => {
            return false
        })
}

function openMercureEventSource(){
    const mercureToken = useMercureStore.getState().mercureToken

    if (mercureToken !== undefined){

        const topicList = jwtDecode(mercureToken).mercure.subscribe
        const url = new URL(MERCURE_URL)
        for (let i = 0; i < topicList.length; i++){
            if (!topicList[i].includes('defer')){
                url.searchParams.append('topic', topicList[i])
            }
        }

        //Add mercure auth
        url.searchParams.append('authorization', mercureToken)
        const eventSource = new EventSource(url, {withCredentials: false})
        useMercureStore.setState({mercureEventSource: eventSource})
    }

}

function getUserTotpStatus(credentials){
    const sendData = new FormData()
    sendData.append('email', credentials.email)
    sendData.append('password', credentials.password)

    return getAxiosInstance().post(SECURITY_CTRL + '/get_user_totp_status', sendData)
}

//Check if user is authenticated or not
function isAuthenticated(){
    const token = window.localStorage.getItem("authToken")
    if (token){
        const jwtData = jwtDecode(token)
        if ((jwtData.exp * 1000) > new Date().getTime()){
            setAxiosToken(token)
            return true
        } else {
            return refreshAuthToken()
        }
    } else {
        return refreshAuthToken()
    }
}

//Get user email in token
function getUserUuid(){
    const token = window.localStorage.getItem("authToken")
    if (token){
        const jwtData = jwtDecode(token)
        return jwtData.uuid
    }
}

function refreshAuthToken() {
    const refreshToken = getCookie('refreshToken')

    if (refreshToken){
        const sendData = new FormData()
        sendData.append('refreshToken', refreshToken)

        return getAxiosInstance().post(SECURITY_CTRL + "/refresh_token", sendData)
            .then((response) => {
                if (response){
                    if (response.status === 200){
                        window.localStorage.setItem("authToken", response.data.token)
                        setAxiosToken(response.data.token)
                        return true
                    }
                }
                window.localStorage.removeItem("authToken")
                return false

            })
            .catch((error) => {
                window.localStorage.removeItem("authToken")
                return false
            })
    }

    return false
}

function setAxiosToken(token){
    Axios.defaults.headers['Authorization'] = "Bearer " + token
}

function logout() {
    return getAxiosInstance().post(SECURITY_CTRL + '/logout')
        .then((response) => {
            simpleLogout()
            return response.status === 200;
        })
        .catch((error) => {
            simpleLogout()
            return false
        })
}

function simpleLogout() {
    const token = window.localStorage.getItem('authToken')
    if (token){
        window.localStorage.removeItem('authToken')
        window.localStorage.removeItem('userImage')
        if (Axios.defaults.headers['Authorization']){
            delete Axios.defaults.headers['Authorization']
        }
    }
}

function isAdmin(){
    const token = window.localStorage.getItem("authToken")
    if (token){
        if (jwtDecode(token).roles[0] === "ROLE_ADMIN"){
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

function getOauthGoogleUrl(){
    return getAxiosInstance().get(SSO_API + '/google')
}

function getOauthAppleUrl(){
    return getAxiosInstance().get(SSO_API + '/apple')
}

function validGoogleOauth(code){
    const sendData = new FormData()
    sendData.append('code', code)

    return getAxiosInstance().post(OAUTH_API + '/valid/google', sendData)
        .then((response) => {
            if (response.status === 200) {
                useUserStore.setState({confirmedAccount: response.data.accountConfirmed})
                window.localStorage.setItem("authToken", response.data.token)
                setAxiosToken(response.data.token)
                const mercureToken = response.data.mercureToken
                const mercureCookie = new Cookies()
                mercureCookie.set('mercureAuthorization', mercureToken, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})

                const authCookie = new Cookies()

                if (process.env.REACT_APP_ENV_TYPE === "prod"){
                    authCookie.set('authCookie', response.data.token, {path: "/", maxAge: Number.MAX_SAFE_INTEGER, domain: "weeki.io"})
                } else {
                    authCookie.set('authCookie', response.data.token, {path: "/", maxAge: Number.MAX_SAFE_INTEGER, domain: "localhost"})
                }

                useMercureStore.setState({mercureToken: mercureToken})
                openMercureEventSource(mercureToken)

                return true
            }
            return false
        })
        .catch((err) => {
            return false
        })
}

function validAppleOauth(code){
    const sendData = new FormData()
    sendData.append('code', code)

    return getAxiosInstance().post(OAUTH_API + '/valid/apple', sendData)
        .then((response) => {
            if (response.status === 200) {
                console.log(response.data)
                // window.localStorage.setItem("authToken", response.data.token)
                // setAxiosToken(response.data.token)
                // const mercureToken = response.data.mercureToken
                // const mercureCookie = new Cookies()
                // mercureCookie.set('mercureAuthorization', mercureToken, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})
                // useMercureStore.setState({mercureToken: mercureToken})
                // openMercureEventSource(mercureToken)
                //
                // return true
            }
            return false
        })
        .catch((err) => {
            return false
        })
}

function userForgotPassword(email){
    const sendData = new FormData()
    sendData.append('email', email)

    return getAxiosInstance().post(SECURITY_CTRL + '/forgot_password', sendData)
        .then((response) => {
            return response.status === 200
        })
        .catch((err) => {
            return false
        })
}

function userResetPassword(password, passwordConfirm, token){
    const sendData = new FormData()
    sendData.append("password", password)
    sendData.append("passwordConfirm", passwordConfirm)
    sendData.append("token", token)

    return getAxiosInstance().post(SECURITY_CTRL + '/reset_password', sendData)
        .then((response) => {
            return response.status === 200
        })
        .catch(() => {
            return false
        })
}

export default {
    login,
    isAuthenticated,
    logout,
    isAdmin,
    getUserTotpStatus,
    openMercureEventSource,
    getUserUuid,
    checkUserLoginSession,
    closeLoginSession,
    simpleLogout,
    getOauthGoogleUrl,
    validGoogleOauth,
    userForgotPassword,
    userResetPassword,
    getOauthAppleUrl,
    validAppleOauth,
    setAxiosToken
}