import React from "react";
// import { useObjectTableStore } from "../../../services/Stores/ObjectTableStore";
import ProjectLine from "./ProjectLine";
import MapLine from "./MapLine";
import OverlayLine from "./OverlayLine";
import AreaLine from "./AreaLine";
import Skeleton from "react-loading-skeleton";
import WorkspaceLine from "./WorkspaceLine";
import { useObjectTableStore } from "../../../../services/Stores/ObjectTableStore";
// /home/acrasie/Weeki/Front/src/js/services/Stores/ObjectTableStore.js
const WorkspaceSidebar = () => {
  // Get data from store of the workspaces from zustand in workspaceList array
  const workspaceList = useObjectTableStore((state) => state.workspaces);

  return (
    <div className="sub-line-container">
      {workspaceList.map((w) => (
        <React.Fragment key={w.uuid}>
          {/* Display the workspaces alright */}
          <WorkspaceLine w={w} />
          {/* When one of the workspaces is open and workspaces.project === undefined display the Skeleton */}
          {w.open && w.projects === undefined && (
            <Skeleton
              style={{ marginLeft: "16px", width: "80%" }}
              height={32}
              count={5}
            />
          )}
          {/* When one of the workspaces is open and workspaces.project !== undefined display the projects */}
          {w.open && w.projects !== undefined && (
            <>
              {/* I map thourhgt my projects */}
              <div className="sub-line-container">
                {w.projects.map((p) => (
                  <React.Fragment key={p.uuid}>
                    <ProjectLine w={w} p={p} panel="workspace" />
                    {/* When one of the projects is open and projects.maps === undefined display the Skeleton */}
                    {p.open && p.maps === undefined && (
                      <Skeleton
                        style={{ marginLeft: "16px", width: "80%" }}
                        height={32}
                        count={p.mapsCount}
                      />
                    )}
                    {/* When one of the projects is open and projects.maps !== undefined display the maps */}
                    {p.open && p.maps !== undefined && (
                      <>
                        <div className="sub-line-container">
                          {/* Rendering of the maps */}
                          {p.maps.map((m) => (
                            <React.Fragment key={m.uuid}>
                              <MapLine m={m} p={p} w={w} panel="workspace" />
                              {m.open && m.overlays === undefined && (
                                <Skeleton
                                  style={{
                                    marginLeft: "16px",
                                    width: "80%",
                                  }}
                                  height={32}
                                  count={m.overlayCount}
                                />
                              )}
                              {m.open && m.overlays !== undefined && (
                                <>
                                  <div className="sub-line-container">
                                    {/* Rendering of the Area */}
                                    {m.overlays.map((o) => (
                                      <React.Fragment key={o.uuid}>
                                        <OverlayLine
                                          w={w}
                                          m={m}
                                          p={p}
                                          o={o}
                                          panel={"workspace"}
                                        />
                                        {o.open && o.areas === undefined && (
                                          <Skeleton
                                            style={{
                                              marginLeft: "16px",
                                              width: "80%",
                                            }}
                                            height={32}
                                            count={o.areasCount}
                                          />
                                        )}
                                        {o.open && o.areas !== undefined && (
                                          <>
                                            <div className="sub-line-container">
                                              {o.areas.map((a) => (
                                                <React.Fragment key={a.uuid}>
                                                  <AreaLine
                                                    o={o}
                                                    p={p}
                                                    m={m}
                                                    a={a}
                                                  />
                                                </React.Fragment>
                                              ))}
                                            </div>
                                          </>
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default WorkspaceSidebar;

/* <div className="sub-line-container">
{workspaceList.map((w) => (
  <>
    <div
      className={"open-line " + (currentSelectedProject === w.uuid && currentSelectedMap === "" ? "active" : "")}
      onClick={(event) => {
        event.stopPropagation()
        useObjectTableStore.getState().updateWorkspaceOpenState(w.uuid, !w.open)
        handleGetWorkspaceProject(w.uuid)
      }}>
      <div className="open-icon" onClick={(event) => {
        event.stopPropagation()
        useObjectTableStore.getState().updateWorkspaceOpenState(w.uuid, !w.open)
        handleGetWorkspaceProject(w.uuid)
      }}>{w.open ? <HiChevronDown size={20}/> : <HiChevronRight size={20}/>}</div>
      <div className="line-icon"><BiSolidFolder size={14}/></div>
      <p className="line-text">{w.name}</p>
    </div>
    {(w.open && w.projects !== undefined) && (
      <>
        <div className="sub-line-container">
          {w.projects.map((p) => (
            <>
              <div
                className={"open-line " + (currentSelectedMap === p.uuid ? 'active' : "")}
                onClick={(event) => {
                  event.stopPropagation()
                  useObjectTableStore.getState().updateWorkspaceProjectOpenState(w.uuid, p.uuid, !p.open)
                  handleGetProjectMaps(p.uuid, true, w.uuid)
                }}>
                <div className="open-icon" onClick={(event) => {
                  event.stopPropagation()
                  useObjectTableStore.getState().updateWorkspaceProjectOpenState(w.uuid, p.uuid, !p.open)
                  handleGetProjectMaps(p.uuid, true, w.uuid)
                }}>{p.open ?
                  <HiChevronDown size={20}/> :
                  <HiChevronRight size={20}/>}</div>
                <div className="line-icon"><BiSolidFolder size={14}/></div>
                <p className="line-text">{p.name}</p>
              </div>
              {(p.open && p.maps !== undefined) && (
                <>
                  <div className="sub-line-container">
                    {p.maps.map((m) => (
                      <div
                        className={"open-line " + (currentSelectedMap === m.uuid ? 'active' : "")}
                        onClick={() => {
                          useObjectTableStore.setState({currentSelectedMap: m.uuid})
                        }}>
                        <div className="open-icon">{m.open ?
                          <HiChevronDown size={20}/> :
                          <HiChevronRight size={20}/>}</div>
                        <div className="line-icon"><MyBoardsIcon /></div>
                        <p className="line-text">{m.name}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      </>
    )}
  </>
))}
</div> */
