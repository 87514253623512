import L from "leaflet";
import {useMapStore} from "../Stores";

export function generateInvisibleAreaMock(pos, borderWidth){
	return L.rectangle(pos, {
		color: "transparent",
		weight: borderWidth + 1,
		fill: true,
		fillColor: "transparent",
		fillOpacity: 1
	})
}

export function generateAreaMock(pos, borderWidth){
	return L.rectangle(pos, {
		color: "#3388ff",
		weight: borderWidth + 1,
		fill: true,
		fillColor: "transparent",
		fillOpacity: 1,
		pmIgnore: false
	})
}

export function generateLineShapeMock(shape, width){
	shape = shape.replaceAll("LINESTRING(", "")
	shape = shape.replaceAll(")", "")

	const shapePoints = shape.split(',')

	const latLngShapePoints = shapePoints.map(point => {
		point = point.split(" ")
		return L.Projection.SphericalMercator.unproject(new L.Point(point[0], point[1]))
	})

	return L.polyline(latLngShapePoints, {color: '#3388ff', weight: width})
}

export function generatePolyLineShapeMock(shape, width){
	shape = shape.replaceAll("LINESTRING(", "")
	shape = shape.replaceAll(")", "")

	const shapePoints = shape.split(',')

	const latLngShapePoints = shapePoints.map(point => {
		point = point.split(" ")
		return L.Projection.SphericalMercator.unproject(new L.Point(point[0], point[1]))
	})

	return L.polyline(latLngShapePoints, {color: '#3388ff', weight: width})
}

export function generateRectangleShapeMock(shape, width){
	shape = shape.replaceAll("POLYGON((", "")
	shape = shape.replaceAll("))", "")

	const shapePoints = shape.split(',')

	const latLngShapePoints = shapePoints.map(point => {
		point = point.split(" ")
		return L.Projection.SphericalMercator.unproject(new L.Point(point[0], point[1]))
	})

	return L.rectangle(latLngShapePoints, {color: '#3388ff', weight: width})
}

export function generateCircleShapeMock(shape, width, properties){
	//Convert shape center to latLng
	const shapeCenter = L.Projection.SphericalMercator.unproject(new L.Point(properties.x, properties.y))

	return L.circle(shapeCenter, {radius: properties.radius, color: '#3388ff', weight: width})
}

export function generatePolygonShapeMock(shape, width){
	shape = shape.replaceAll("POLYGON((", "")
	shape = shape.replaceAll("))", "")

	const shapePoints = shape.split(',')

	const latLngShapePoints = shapePoints.map(point => {
		point = point.split(" ")
		return L.Projection.SphericalMercator.unproject(new L.Point(point[0], point[1]))
	})

	return L.polygon(latLngShapePoints, {color: '#3388ff', weight: width})
}