import L from "leaflet";
import AreaAPI from "../AreaAPI";
import {useMapZoneStore} from "../Stores/MapZoneStore";
import {getMockBounds, unselectZone, updateZoneShape, updateZoneTitle} from "./ZoneUtils";
import {createErrorToast} from "../CustomToastUtils";
import ContentItemAPI from "../ContentItemAPI";
import {useContentItemStore} from "../Stores/ContentItemStore";
import {useSidebarMapContentItemStore} from "../Stores/SidebarMapContentItemStore";
import TextItemAPI from "../API/User/TextItemAPI";
import {useTextItemStore} from "../Stores/TextItemStore";
import {updateRenderedTextItemPopup} from "./MapPopupUtils";
import {textItemSize} from "./TextItemUtils";
import {useMapStore} from "../Stores";

export function addZoneSelectedMenuEvents(popup, rename = false){
	if (!rename) {
		//Add event for open or close border color menu
		L.DomEvent.on(popup._contentNode.querySelector("#border-color-button"), 'click', (event) => {
			event.stopPropagation()

			//Open or close color menu
			const colorMenuElement = popup._contentNode.querySelector('#color-selector-container')

			//Get all color menu button element
			const colorButtonElements = colorMenuElement.querySelectorAll("#color-selector-btn")
			const transparentButtonElement = colorMenuElement.querySelector("#transparent-selector-btn")

			transparentButtonElement.style.display = "none"

			if (colorMenuElement.style.display === "block") {
				colorMenuElement.style.display = "none"
				//Add event on color button click on color menu
				colorButtonElements.forEach(button => {
					L.DomEvent.off(button, 'click')
				})
			} else {
				colorMenuElement.style.display = "block"

				//Add event on color button click on color menu
				colorButtonElements.forEach(button => {
					L.DomEvent.on(button, 'click', async (event) => {
						event.stopPropagation()

						//Format color code
						let newColor = button.style.backgroundColor
						newColor = newColor.replaceAll('rgb(', "")
						newColor = newColor.replaceAll(')', '')

						//Update area in api
						await AreaAPI.updateArea(useMapZoneStore.getState().currentSelectedZone, newColor)

						//Update area tiles
						updateZoneShape(getMockBounds(useMapZoneStore.getState().currentSelectedMock))
					})
				})
			}
		})

		//Add event for open or close fill color menu
		L.DomEvent.on(popup._contentNode.querySelector("#fill-color-button"), 'click', (event) => {
			event.stopPropagation()

			//Open or close color menu
			const colorMenuElement = popup._contentNode.querySelector('#color-selector-container')
			const transparentButtonElement = colorMenuElement.querySelector("#transparent-selector-btn")

			transparentButtonElement.style.display = "block"

			//Get all color menu button element
			const colorButtonElements = colorMenuElement.querySelectorAll("#color-selector-btn")

			if (colorMenuElement.style.display === "block") {
				colorMenuElement.style.display = "none"
				//Add event on color button click on color menu
				colorButtonElements.forEach(button => {
					L.DomEvent.off(button, 'click')
				})
			} else {
				colorMenuElement.style.display = "block"

				//Add event on color button click on color menu
				colorButtonElements.forEach(button => {
					L.DomEvent.on(button, 'click', async (event) => {
						event.stopPropagation()

						//Format color code
						let newColor = button.style.backgroundColor
						newColor = newColor.replaceAll('rgb(', "")
						newColor = newColor.replaceAll(')', '')

						//Update area in api
						await AreaAPI.updateArea(useMapZoneStore.getState().currentSelectedZone, null, null, newColor, 1)

						//Update area tiles
						updateZoneShape(getMockBounds(useMapZoneStore.getState().currentSelectedMock))
					})
				})

				//Add event for transparent background
				L.DomEvent.on(transparentButtonElement, 'click', async (event) => {
					console.log("transparent")
					event.stopPropagation()

					//Update area in api
					await AreaAPI.updateArea(useMapZoneStore.getState().currentSelectedZone, null, null, '0,0,0', 0)

					//Update area tiles
					updateZoneShape(getMockBounds(useMapZoneStore.getState().currentSelectedMock))
				})
			}
		})
	} else {
		//Focus input
		popup._contentNode.querySelector("#zone-rename-input").focus()

		//Add event on rename input for valid rename
		L.DomEvent.on(popup._contentNode.querySelector('#zone-rename-input'), 'keydown', async (event) => {
			event.stopPropagation()

			//Check if enter key is pressed
			if (event.key === "Enter") {
				//Get input value
				const value = event.target.value

				//Check if value is not empty
				if (value.length <= 0) {
					createErrorToast("Zone name cannot be empty")
				}

				//Rename area in api
				await AreaAPI.renameArea(useMapZoneStore.getState().currentSelectedZone, value)

				//Update area title tiles
				updateZoneShape(getMockBounds(useMapZoneStore.getState().currentSelectedMock))

				//Unselect zone
				unselectZone()
			}
		})
	}
}

export function addItemSelectedMenuEvents(popup, properties){
	//Get fullscreen btn element and add event if exist
	const fullScreenBtnElement = popup._contentNode.querySelector('#fullscreen-btn')
	if (fullScreenBtnElement) {
		L.DomEvent.on(fullScreenBtnElement, 'click', (event) => {
			event.stopPropagation()

			//Close sidebar
			useSidebarMapContentItemStore.getState().resetStore()

			//Open fullscreen
			useContentItemStore.setState({currentFullscreenItemUuid: useContentItemStore.getState().currentSelectedItem.id})
		})
	}

	//Get download btn element and add event if exist
	const downloadBtnElement = popup._contentNode.querySelector('#download-btn')
	if (downloadBtnElement) {
		L.DomEvent.on(downloadBtnElement, 'click', (event) => {
			event.stopPropagation()

			ContentItemAPI.downloadContentItemFile(useContentItemStore.getState().currentSelectedItem.id)
		})
	}

	//Get open link btn element and add event if exist
	const openLinkBtnElement = popup._contentNode.querySelector('#open-link-btn')
	if (openLinkBtnElement) {
		L.DomEvent.on(openLinkBtnElement, 'click', (event) => {
			event.stopPropagation()

			//Get url in item attributes
			const attributes = properties.attributes !== null ? JSON.parse(properties.attributes) : null

			if (attributes){
				const url = attributes.filter(a => a.type === "url")[0].value

				window.open(url, "_blank").focus()
			}
		})
	}
}

export function addTextSelectedMenuEvents(popup, properties){
	//Get text size menu btn element
	const textSizeBtnElement = popup._contentNode.querySelector('#text-size-btn')
	if (textSizeBtnElement) {
		L.DomEvent.on(textSizeBtnElement, 'click', (event) => {
			event.stopPropagation()

			//Get text size menu element
			const textSizeMenuElement = popup._contentNode.querySelector('#text-size-menu')

			//Toggle menu display
			if (!textSizeMenuElement.style.display || textSizeMenuElement.style.display === "none"){
				textSizeMenuElement.style.display = "flex"
			} else {
				textSizeMenuElement.style.display = "none"
			}
		})
	}

	//Get all size btn elements
	const sizeBtnElements = popup._contentNode.querySelectorAll('#size-btn')
	sizeBtnElements.forEach(e => {
		L.DomEvent.on(e, 'click', (event) => {
			//Get data size attributes
			const dataSize = e.dataset.size

			//Check if data size is valid
			if (!Object.keys(textItemSize).includes(dataSize)){
				createErrorToast("Invalid size")
				return
			}

			//Update text item size in api
			TextItemAPI.updateTextItemSize(properties.uuid, dataSize)

			const currentZoomLevel = useMapStore.getState().mapInstance.getZoom()
			let newMinZlevel = null

			if (dataSize === "small"){
				newMinZlevel = currentZoomLevel - 1
			} else if (dataSize === "normal"){
				newMinZlevel = currentZoomLevel - 2
			} else if (dataSize === "large"){
				newMinZlevel = currentZoomLevel - 3
			} else if (dataSize === "huge"){
				newMinZlevel = currentZoomLevel - 4
			}

			console.log(newMinZlevel)

			//Update size in store
			useTextItemStore.getState().updateRenderedTextItemSize(properties.uuid, dataSize, newMinZlevel)

			//Update text item popup
			updateRenderedTextItemPopup(properties.uuid)
		})
	})

	//Get small size btn element
	// const smallSizeBtnElement = popup._contentNode.querySelector('#btn-text-small')
	// if (smallSizeBtnElement){
	// 	L.DomEvent.on(smallSizeBtnElement, 'click', (event) => {
	// 		//Update text item size in api
	// 		TextItemAPI.updateTextItemSize(properties.uuid, 13)
	//
	// 		//Update size in store
	// 		useTextItemStore.getState().updateRenderedTextItemSize(properties.uuid, 13)
	//
	// 		//Update text item popup
	// 		updateRenderedTextItemPopup(properties.uuid)
	// 	})
	// }
	//
	// //Get small size btn element
	// const normalSizeBtnElement = popup._contentNode.querySelector('#btn-text-normal')
	// if (normalSizeBtnElement){
	// 	L.DomEvent.on(normalSizeBtnElement, 'click', (event) => {
	// 		//Update text item size in api
	// 		TextItemAPI.updateTextItemSize(properties.uuid, 15)
	//
	// 		//Update size in store
	// 		useTextItemStore.getState().updateRenderedTextItemSize(properties.uuid, 15)
	//
	// 		//Update text item popup
	// 		updateRenderedTextItemPopup(properties.uuid)
	// 	})
	// }
	//
	// //Get small size btn element
	// const largeSizeBtnElement = popup._contentNode.querySelector('#btn-text-large')
	// if (largeSizeBtnElement){
	// 	L.DomEvent.on(largeSizeBtnElement, 'click', (event) => {
	// 		//Update text item size in api
	// 		TextItemAPI.updateTextItemSize(properties.uuid, 18)
	//
	// 		//Update size in store
	// 		useTextItemStore.getState().updateRenderedTextItemSize(properties.uuid, 18)
	//
	// 		//Update text item popup
	// 		updateRenderedTextItemPopup(properties.uuid)
	// 	})
	// }
	//
	// //Get small size btn element
	// const hugeSizeBtnElement = popup._contentNode.querySelector('#btn-text-huge')
	// if (hugeSizeBtnElement){
	// 	L.DomEvent.on(hugeSizeBtnElement, 'click', (event) => {
	// 		//Update text item size in api
	// 		TextItemAPI.updateTextItemSize(properties.uuid, 22)
	//
	// 		//Update size in store
	// 		useTextItemStore.getState().updateRenderedTextItemSize(properties.uuid, 22)
	//
	// 		//Update text item popup
	// 		updateRenderedTextItemPopup(properties.uuid)
	// 	})
	// }
}