import React, {useEffect, useState} from "react"
import ContentItemCommentAPI from "../../../../services/ContentItemCommentAPI";
import {BsSend, BsTrash} from "react-icons/bs";
import {toast} from "react-toastify";
import AuthAPI from "../../../../services/AuthAPI";
import {BiComment, BiCommentX, BiTrash} from "react-icons/bi";
import {TailSpin} from "react-loader-spinner";
import {useSidebarMapContentItemStore} from "../../../../services/Stores/SidebarMapContentItemStore";
import {useContentItemStore} from "../../../../services/Stores/ContentItemStore";
import {useUserStore} from "../../../../services/Stores";

const ObjectSidebarComments = ({fullscreen = false}) => {
    const [getCommentRequestStatus, setGetCommentRequestStatus] = useState(false)
    const [userUuid, setUserUuid] = useState("")

    //Define default state for comments list
    const [comments, setComments] = useState([{
        'uuid': "",
        'authorFirstname': "",
        'authorLastname': "",
        'authorUuid': "",
        'authorPicture': "",
        'date': "",
        'content': ""
    }])

    useEffect(() => {
        updateComments()
        setUserUuid(AuthAPI.getUserUuid())
    }, [])

    const updateComments = () => {
        setGetCommentRequestStatus(false)

        let itemUuid

        if (fullscreen){
            itemUuid = useContentItemStore.getState().currentFullscreenItemUuid
        } else {
            itemUuid = useSidebarMapContentItemStore.getState().properties.id
        }

        ContentItemCommentAPI.getAllItemComment(itemUuid).then((response) => {
            if (response.status === 200){
                setComments(response.data)
            } else {
                setComments([])
            }
            setGetCommentRequestStatus(true)
        })
    }

    const [newCommentValue, setNewCommentValue] = useState("")
    const handleSendComment = async () => {
        if (newCommentValue === ""){
            toast.error("New comment cannot be empty")
            return
        }

        const uuid = await ContentItemCommentAPI.addComment(useSidebarMapContentItemStore.getState().properties.id, newCommentValue)

        if (uuid){
            setComments([...comments, {
                'uuid': uuid,
                'authorFirstname': useUserStore.getState().user.firstname,
                'authorLastname': useUserStore.getState().user.lastname,
                'authorUuid': useUserStore.getState().user.id,
                'authorPicture': "",
                'date': new Date().toLocaleDateString() + ", " + new Date().toLocaleTimeString().slice(0, -3),
                'content': newCommentValue
            }])

            setNewCommentValue("")
        }
    }

    const handleDeleteComment = async (commentUuid) => {
        ContentItemCommentAPI.deleteComment(commentUuid)

        setComments(comments.filter(c => c.uuid !== commentUuid))
    }

    return (
        <div className={!fullscreen ? "comment-container" : "comment-container fullscreen"}>
            <div className="comment-list">
                {getCommentRequestStatus && (
                    <>
                        {comments.length > 0 && (
                            <>
                                {comments.map((comment, index) => (
                                    <div className="comment" key={index}>
                                        <div className="comment-header">
                                            <p className="comment-thumbnail">{comment.authorFirstname[0] + comment.authorLastname[0]}</p>
                                            <p className="author-name">{comment.authorFirstname} {comment.authorLastname}</p>
                                        </div>
                                        <div className="comment-content">{comment.content}</div>
                                        <div className="sub-comment-infos">
                                            {comment.authorUuid === useUserStore.getState().user.id && (
                                                <p onClick={() => handleDeleteComment(comment.uuid)} className="delete-btn"><BsTrash /></p>
                                            )}
                                            <div className="comment-date">{comment.date}</div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                        {comments.length <= 0 && (
                            <div className="no-comment-container">
                                <div className="icon"><BiComment /></div>
                                <p className="text">No comments</p>
                            </div>
                        )}
                    </>
                )}
                {!getCommentRequestStatus && (
                    <div className="comment-loader-container">
                        <TailSpin height="64" width="64" color="#000"/>
                    </div>
                )}
            </div>
            <div className="new-comment-container">
                <textarea value={newCommentValue} onChange={(event) => setNewCommentValue(event.currentTarget.value)} onKeyDown={(event) => event.key === "Enter" ? handleSendComment() : null} className="new-comment-textarea" rows="4" placeholder="Add comment"></textarea>
                <p className="send-comment-btn" onClick={handleSendComment}><BsSend /></p>
            </div>
        </div>
    )
}

export default ObjectSidebarComments