import React, {useRef, useState} from "react";
import {PiFolderSimplePlusBold, PiFunnelBold} from "react-icons/pi";
import {FiGrid} from "react-icons/fi";
import {BsList} from "react-icons/bs";
import {ReactComponent as SearchIcon} from "../../../../icons/map/search.svg"
import {VscChromeClose} from "react-icons/vsc";
import UserProjectsAPI from "../../../services/API/User/UserProjectsAPI";
import {toast} from "react-toastify";
import CreateProjectPopup from "../dashboard-popups/CreateProjectPopup";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {useHistory} from "react-router-dom";
import {DASHBOARD_MAP} from "../../../DashboardMap";

const ProjectManageActionBar = ({search, setSearch, action = true}) => {
	const history = useHistory()

	//Get dashboard params in store
	const currentDashboardLayout = useDashboardStore(state => state.currentDashboardLayout)
	const currentOpenedProject = useDashboardStore(state => state.currentOpenedProject)
	const currentOpenedWorkspace = useDashboardStore(state => state.currentOpenedWorkspace)

	const [createProjectPopupState, setCreateProjectPopupState] = useState(false)

	const handleCreateProject = async (value) => {
		//Check if project name is not empty
		if (value.length > 0) {
			setCreateProjectPopupState(false)
			const newProjectData = await UserProjectsAPI.userCreateProject(value)

			if (newProjectData.uuid){
				//Add new project in store
				const newProject = {'uuid': newProjectData.uuid, 'name': value, 'creationDate': "", 'mapsCount': 0, "userAuthorizations": newProjectData.userAuthorizations}
				useDashboardStore.getState().addNewProject(newProject)

				//Set dashboard in store
				useDashboardStore.setState({currentDashboard: DASHBOARD_MAP['user']['projects']})

				//Redirect to dashboard
				history.push(DASHBOARD_MAP['user']['projects'].route)

				//Open new project
				useDashboardStore.setState({currentOpenedProject: newProject})
			}
		}
	}

	return (
		<>
			{/*Add all popup*/}
			<CreateProjectPopup state={createProjectPopupState} setState={setCreateProjectPopupState} handler={handleCreateProject}/>
			{/*BAR RENDER*/}
			<div className="custom__project-manage-action-bar">
				<div className="left">
					<div className="icon-btn"><PiFunnelBold  fontSize="1.2em" color="#8a8d94"/></div>
					{action && (
						<>
							{!currentOpenedProject && (
								<>
									{(currentOpenedWorkspace === null || currentOpenedWorkspace.userAuthorizations.includes('workspace.create_project')) && (
										<div className="text-icon-btn" style={{border: 'none'}}
											 onClick={() => setCreateProjectPopupState(true)}>
											<p className="icon"><PiFolderSimplePlusBold fontSize="1.2em"/></p>
											<p className="text">New project</p>
										</div>
									)}
								</>
							)}
						</>
					)}

				</div>
				<div className="right">
					<div className="search-bar-container">
						<div className="icon" style={{ stroke: '#d3dcf1' , position: 'relative', left: '40px' , top: '2px'}}>
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#ceccd7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search">
							<circle cx="11" cy="11" r="8"/>
							<path d="m21 21-4.3-4.3"/></svg>						
						</div>
						<input type="text" placeholder="Search by map name" value={search} onChange={(event) => setSearch(event.currentTarget.value)}/>
					</div>
					<div className="text-btn">Import</div>
					<div className="text-btn">Export</div>
					<div className={currentDashboardLayout === "grid" ? "simple-icon-btn selected": "simple-icon-btn"} onClick={() => useDashboardStore.setState({currentDashboardLayout: 'grid'})}><FiGrid size={22} /></div>
					<div className={currentDashboardLayout === "list" ? "simple-icon-btn selected": "simple-icon-btn"} onClick={() => useDashboardStore.setState({currentDashboardLayout: 'list'})}><BsList size={26} /></div>
				</div>
			</div>
		</>
	)
}

export default ProjectManageActionBar