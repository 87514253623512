import React, {useRef} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {createErrorToast} from "../../../services/CustomToastUtils";
import UserMapAPI from "../../../services/API/User/UserMapAPI";
import MapAPI from "../../../services/MapAPI";
import {useHistory} from "react-router-dom";
import UserProjectsAPI from "../../../services/API/User/UserProjectsAPI";
import ProjectsAPI from "../../../services/ProjectsAPI";

const RenameMapPopup = ({state, setState}) => {
	//Get data in store
	const currentContextMenuMap = useDashboardStore(state => state.currentContextMenuMap)

	const mapNameInputRef = useRef(null)

	const handleRenameMap = async () => {
		setState(false)
		const newName = mapNameInputRef.current.value

		if (newName.length <= 0) {
			createErrorToast("Board name can't be empty")
			return
		}

		if (await MapAPI.renameMap(currentContextMenuMap.uuid, newName)) {
			useDashboardStore.getState().renameMap(currentContextMenuMap.uuid, newName)
		}
	}

	if (state) {
		return createPortal(
			<div className="custom__dashboard-popup-overlay">
				<div className="container">
					<div className="popup-header">
						<h1 className="popup-title">Rename your board</h1>
						<p className="popup-close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
					</div>
					<div className="popup-content">
						<div className="input-container">
							<input ref={mapNameInputRef} defaultValue={currentContextMenuMap.name} type="text" placeholder="Enter board name" autoFocus={true} onKeyDown={(e) => e.key === "Enter" ? handleRenameMap() : null}/>
						</div>
					</div>
					<div className="popup-footer">
						<p className="popup-btn" onClick={() => setState(false)}>Cancel</p>
						<p className="popup-btn valid" onClick={handleRenameMap}>Valid</p>
					</div>
				</div>
			</div>
			, document.body)
	}

	return null
}

export default RenameMapPopup