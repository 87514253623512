import {create} from "zustand";

export const useNotificationStore = create((set, get) => ({
	notifications: [],
	unReadNotificationsCount: 0,
	// updateNotificationReadStatus: (uuid, newState) => set((state) => ({
	// 	notificationList: state.notificationList.map((notif) =>
	// 		notif.uuid === uuid
	// 			? ({...notif, read: newState})
	// 			: notif
	// 	),
	// 	unReadNotificationCount: state.unReadNotificationCount - 1
	// })),
	readAllNotification: () => set((state) => ({
		notifications: state.notifications.map((notif) =>
			({...notif, read: true})
		),
		unReadNotificationsCount: 0
	})),
	addNewNotification: (notif) => set((state) => ({
		notifications: [notif, ...state.notifications],
		unReadNotificationsCount: state.unReadNotificationsCount + 1
	})),
	updateNotificationAttribute: (uuid, key, value) => set((state) => ({
		notifications: state.notifications.map((notif) =>
			notif.uuid === uuid
				? ({...notif, attributes: {...notif.attributes, [key]: value}})
				: notif
		)
	})),
	removeNotification: (uuid) => set((state) => ({
		notifications: state.notifications.filter(n => n.uuid !== uuid)
	})),
	updateNotificationsUnReadCount: () => set((state) => ({
		unReadNotificationsCount: state.notifications.filter(n => !n.read).length
	}))
}))