import React from "react";

const AddTextItemPopup = () => {
	return (
		<div className="add-text-item-popup-container">
			<input type="text" placeholder="Add your text" id="add-text-item-input"/>
		</div>
	)
}

export default AddTextItemPopup