import React from "react";
import {FaEye} from "react-icons/fa";

const ShowOrHideColumnMenu = ({columnList, setColumnList, open = false, setOpen, resetColumn}) => {

    const activeColumn = (event) => {
        const columnName = event.currentTarget.id
        setColumnList({...columnList, [columnName]: true})

    }

    const inactiveColumn = (event) => {
        const columnName = event.currentTarget.id
        setColumnList({...columnList, [columnName]: false})
    }

    return (
        <div className="column-menu-container">
            <div className="btn" onClick={() => setOpen(!open)}>
                <p>Show/Hide Columns</p>
                <p><FaEye /></p>
            </div>
            <div className={open ? "menu open" : "menu"}>
                <p className="reset-btn" onClick={resetColumn}>Reset</p>
                {Object.entries(columnList).map((value, key) => (
                    <div key={key}>
                        <input type="checkbox" id={value[0]} className="checkbox" checked={value[1]} onChange={value[1] ? inactiveColumn : activeColumn}/>
                        <p>{value[0]}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ShowOrHideColumnMenu