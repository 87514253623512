import React, {useEffect, useRef, useState} from "react";
import TopNavBar from "../components/TopNavBar";
import {BsArrowUpRight} from "react-icons/bs";
import {MdNoPhotography} from "react-icons/md";
import UserAPI from "../services/UserAPI";
import {toast} from "react-toastify";
import TotpActivationPopup from "../components/TotpActivationPopup";
import TfaAPI from "../services/TfaAPI";
import professions from "../../json/professional-situation.json"
import activity_domains from "../../json/activity-domain.json"
import {EMAIL_REGEX} from "../../Regex";
import Billing from "../components/Billing";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {useUserStore} from "../services/Stores";
import Skeleton from "react-loading-skeleton";
import {createErrorToast, createSuccessToast} from "../services/CustomToastUtils";
import ManageTeam from "../components/ManageTeam";
import SizedTextarea from "../components/customs/SizedTextarea";

const Settings = () => {
    //Define default state for view
    const [view, setView] = useState("profile")
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

    //Get user from store
    const user = useUserStore(state => state.user)
    const updateUserValue = useUserStore(state => state.updateUserValue)
    const userSubscription = useUserStore(state => state.userSubscription)

    const [newUserPrimaryData, setNewUserPrimaryData] = useState({})
    const [newUserData, setNewUserData] = useState({})
    const [newUserPicture, setNewUserPicture] = useState(null)
    const [userDescription, setUserDescription] = useState("")

    useEffect(() => {
        setUserDescription(user.description)
    }, [user])

    useEffect(() => {
        setNewUserData({...newUserData, description: userDescription})
    }, [userDescription])

    const handleChangeUserData = (event) => {
        const dataName = event.currentTarget.name
        const dataValue = event.currentTarget.value

        setNewUserData({...newUserData, [dataName]: dataValue})
    }

    const uploadPictureInputRef = useRef(null)

    const handleUpdateUserPicture = async () => {
        const file = uploadPictureInputRef.current.files[0]

        if (file) {
            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                createErrorToast("Unsupported file type, only JPG or PNG file")
                return
            }

            if (file.size > 3000000) {
                createErrorToast('File size to large max 3Mb')
                return;
            }

            setNewUserPicture(URL.createObjectURL(file))

            if (await UserAPI.updateUserPicture(file)) {
                updateUserValue("picture", URL.createObjectURL(file))
                createSuccessToast("Picture updated successfully")
            } else {
                setNewUserPicture(null)
            }

        }

        uploadPictureInputRef.current.value = ""
    }

    const handleRemoveUserPicture = async () => {
        if (user.picture){
            if (await UserAPI.updateUserPicture()) {
                setNewUserPicture(null)
                updateUserValue("picture", null)
                createSuccessToast("Picture successfully removed")
            }
        }
    }

    const [totpActivationPopup, setTotpActivationPopup] = useState(false)

    const handleDisableTfa = async () => {
        if (await TfaAPI.disableTfa()) {
            toast.success("Two factor authentication disabled")
            user.totpStatus = false
        }
    }

    const handleSaveUserData = async () => {
        const data = {}

        //Check if new value is different and need to be updated
        Object.keys(newUserData).forEach(key => {
            if (newUserData[key] !== user[key]) {
                data[key] = newUserData[key]
            }
        })

        if (await UserAPI.updateUserProfile(data)) {
            toast.success("Profile updated")

            Object.keys(data).forEach(key => {
                updateUserValue(key, data[key])
            })

            setNewUserData({})
        }
    }

    const [updatePrimaryDataStatus, setUpdatePrimaryDataStatus] = useState(false)
    const nameInputRef = useRef(null)
    const emailInputRef = useRef(null)
    const passwordInputRef = useRef(null)

    const handleEditPrimaryData = () => {
        nameInputRef.current.value = user.firstname + " " + user.lastname
        emailInputRef.current.value = user.email
        passwordInputRef.current.value = ""

        setNewUserPrimaryData({
            'email': user.email,
            'name': user.firstname + " " + user.lastname
        })

        setUpdatePrimaryDataStatus(true)
    }

    const handleCancelPrimaryDataEdit = () => {
        nameInputRef.current.value = user.firstname + " " + user.lastname
        emailInputRef.current.value = user.email
        passwordInputRef.current.value = "weekiforever"

        setUpdatePrimaryDataStatus(false)
        setNewUserPrimaryData({})
    }

    const handleChangePrimaryData = (event) => {
        const dataName = event.currentTarget.name
        const dataValue = event.currentTarget.value

        setNewUserPrimaryData({...newUserPrimaryData, [dataName]: dataValue})
    }

    const handleValidPrimaryData = async () => {
        const data = {}

        //Update name
        if (newUserPrimaryData.name) {
            const splitName = newUserPrimaryData.name.split(" ")
            let firstname = ""
            let lastname = ""

            splitName.forEach((value, index) => {
                if (index === 0) {
                    firstname = value
                } else {
                    if (lastname !== "") {
                        lastname = lastname + " " + value
                    } else {
                        lastname = value
                    }
                }
            })

            if (firstname !== user.firstname) {
                data.firstname = firstname
            }

            if (lastname !== user.lastname) {
                data.lastname = lastname
            }

        } else {
            toast.error("Name cannot be empty")
            return
        }

        //Update email
        if (newUserPrimaryData.email) {
            if (!newUserPrimaryData.email.match(EMAIL_REGEX)) {
                toast.error("Invalid email")
                return;
            }

            if (newUserPrimaryData.email !== user.email) {
                data.email = newUserPrimaryData.email
            }
        } else {
            toast.error("Email cannot be empty")
            return
        }

        //Update password
        if (newUserPrimaryData.newPassword || newUserPrimaryData.newPasswordConfirm){
            if (newUserPrimaryData.newPassword === "" || newUserPrimaryData.newPasswordConfirm === ""){
                toast.error("Please enter valid password and confirmation")
                return
            }

            if (newUserPrimaryData.newPassword !== newUserPrimaryData.newPasswordConfirm){
                toast.error("Password don't match")
                return
            }

            data.password = newUserPrimaryData.newPassword
            data.passwordConfirm = newUserPrimaryData.newPasswordConfirm
        }

        if (Object.keys(data).length === 0){
            setUpdatePrimaryDataStatus(false)
            setNewUserPrimaryData({})
            passwordInputRef.current.value = "weekiforever"

            return
        }

        if (await UserAPI.updateUserProfile(data)) {
            toast.success("Profile updated")
            setUpdatePrimaryDataStatus(false)
            setNewUserPrimaryData({})
            passwordInputRef.current.value = "weekiforever"

            Object.keys(data).forEach(key => {
                updateUserValue(key, data[key])
            })
        }
    }

    return (
        <>
            {/*TOTP POPUP*/}
            {totpActivationPopup && (
                <TotpActivationPopup state={totpActivationPopup} setState={setTotpActivationPopup}/>
            )}
            <TopNavBar simple={true} backLink={"/user/dashboard/maps"} />
            <div className="settings-page-container">
                <div className="settings-container">
                    <div className="header">
                        <p className={view === "profile" ? "button selected" : "button"} onClick={() => view !== "profile" ? setView("profile") : null}>Profile informations</p>
                        <p className={view === "billing" ? "button selected" : "button"} onClick={() => view !== "billing" ? setView("billing") : null}>Subscriptions</p>
                        {userSubscription && (
                            <>
                                {userSubscription['isMultiUser'] && (
                                    <p className={view === "team" ? "button selected" : "button"} onClick={() => view !== "team" ? setView("team") : null}>Manage team</p>
                                )}
                            </>
                        )}
                    </div>
                    {view === "profile" && (
                        <>
                            <div className="content">
                                <h1 className="title">Personal informations</h1>
                                <h2 className="subtitle">Update your photo and personal details here.</h2>
                                <div className="picture-container">
                                    <div className="picture">
                                        {newUserPicture !== null && (
                                            <img src={newUserPicture} alt="User"/>
                                        )}
                                        {newUserPicture === null && (
                                            <>
                                                {user.picture && (
                                                    <img src={user.picture} alt="User"/>
                                                )}
                                            </>
                                        )}
                                        {newUserPicture === null && (
                                            <>
                                                {!user.picture && (
                                                    <p className="no-pic"><MdNoPhotography /></p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="right">
                                        <div className="button-container">
                                            <input onChange={handleUpdateUserPicture} type="file" multiple={false} style={{display: "none"}} ref={uploadPictureInputRef}/>
                                            <p className="button" onClick={() => uploadPictureInputRef.current.click()}>Upload new picture</p>
                                            <p className="button red" onClick={handleRemoveUserPicture}>Remove</p>
                                        </div>
                                        <p className="text">Image format with max size 3mb</p>
                                    </div>
                                </div>
                                <div className="inputs-container">
                                    <div className="input">
                                        <label htmlFor="name">First and lastname</label>
                                        {user.firstname && (
                                            <input onChange={handleChangePrimaryData} ref={nameInputRef} type="text" id="name" name="name" disabled={!updatePrimaryDataStatus} defaultValue={String(user.firstname + " " + user.lastname)}/>
                                        )}
                                        {!user.firstname && (
                                            <Skeleton borderRadius={6} height={44} />
                                        )}
                                    </div>
                                    <div className="input">
                                        <label htmlFor="email">Email</label>
                                        {user.email && (
                                            <input onChange={handleChangePrimaryData} ref={emailInputRef} type="text" id="email" name="email" disabled={!updatePrimaryDataStatus} defaultValue={user.email}/>
                                        )}
                                        {!user.email && (
                                            <Skeleton borderRadius={6} height={44} />
                                        )}
                                    </div>
                                    <div className="input">
                                        <label htmlFor="password">Password</label>
                                        <input ref={passwordInputRef} onChange={handleChangePrimaryData} type="password" name="newPassword" disabled={!updatePrimaryDataStatus} id="password" defaultValue="weekiforever"/>
                                    </div>
                                    {updatePrimaryDataStatus && (
                                        <div className="input">
                                            <label htmlFor="password">Password confirmation</label>
                                            <input onChange={handleChangePrimaryData} type="password" name="newPasswordConfirm" disabled={!updatePrimaryDataStatus} id="passwordConfirm"/>
                                        </div>
                                    )}
                                </div>
                                <SizedTextarea label="Description" value={userDescription} setValue={setUserDescription} maxSize={200} />
                                <div className="buttons-container">
                                    <div className="left">
                                        <div className="tfa-container">
                                            <p className="title">Two factor authentication</p>
                                            <div className="btns">
                                                <p className={user.totpStatus ? "btn on active" : "btn on"} onClick={() => setTotpActivationPopup(true)}>On</p>
                                                <p onClick={handleDisableTfa} className={!user.totpStatus ? "btn off active" : "btn off"}>Off</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="buttons-container">
                                    <div className="left">
                                    </div>
                                    <div className="right">
                                        {!updatePrimaryDataStatus && (
                                            <p className="button" onClick={handleEditPrimaryData}>Edit</p>
                                        )}
                                        {updatePrimaryDataStatus && (
                                            <>
                                                <p className="button red" onClick={handleCancelPrimaryDataEdit}>Cancel</p>
                                                <p className="button inverted" onClick={handleValidPrimaryData}>Save changes</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr className="separator"/>
                            <div className="content">
                                <h1 className="title">Professional</h1>
                                <h2 className="subtitle">Update your professional informations</h2>
                                <div className="inputs-container">
                                    <div className="input">
                                        <label htmlFor="profession">Profession</label>
                                        <select id="profession" name="profession" onChange={handleChangeUserData}>
                                            {user.profession === "" || user.profession === null
                                                ? <option value="">Select profession</option>
                                                : null
                                            }
                                            {professions.map((profession, index) => (
                                                <option selected={user.profession === profession} value={profession} key={index}>{profession}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="activity-domain">Activity domain</label>
                                        <select id="activity-domain" name="activity_domain" onChange={handleChangeUserData}>
                                            {user.activity_domain === "" || user.activity_domain === null
                                                ? <option value="">Select profession</option>
                                                : null
                                            }
                                            {activity_domains.map((domain, index) => (
                                                <option selected={user.activity_domain === domain} value={domain} key={index}>{domain}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="company">Company</label>
                                        <input type="text" name="company" onChange={handleChangeUserData} disabled={false} id="company" defaultValue={user.company}/>
                                    </div>
                                </div>
                            </div>
                            <hr className="separator"/>
                            <div className="content">
                                <h1 className="title">Localisation</h1>
                                <h2 className="subtitle">Update your personal address informations</h2>
                                <div className="inputs-container">
                                    <div className="input">
                                        <label htmlFor="address">Address</label>
                                        <input type="text" name="address" onChange={handleChangeUserData} disabled={false} id="address" defaultValue={user.address}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="region">Region</label>
                                        <input type="text" name="region" onChange={handleChangeUserData} disabled={false} id="region" defaultValue={user.region}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="zip-code">Zip code</label>
                                        <input type="text" name="postal_code" onChange={handleChangeUserData} disabled={false} id="zip-code" defaultValue={user.postal_code}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="city">City</label>
                                        <input type="text" name="city" onChange={handleChangeUserData} disabled={false} id="city" defaultValue={user.city}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="country">Country</label>
                                        <input type="text" name="country" onChange={handleChangeUserData} disabled={false} id="country" defaultValue={user.country}/>
                                    </div>
                                </div>
                            </div>
                            <hr className="separator"/>
                            <div className="content">
                                <h1 className="title">Social media and contact</h1>
                                <h2 className="subtitle">Update your social media</h2>
                                <div className="inputs-container">
                                    <div className="input">
                                        <label htmlFor="linkedin">LinkedIn</label>
                                        <input type="text" name="linkedin" disabled={false} id="linkedin" onChange={handleChangeUserData} defaultValue={user.linkedin}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="phone">Phone</label>
                                        <input type="text" name="phone" disabled={false} id="phone" onChange={handleChangeUserData} defaultValue={user.phone}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="facebook">Facebook</label>
                                        <input type="text" name="facebook" disabled={false} id="facebook" onChange={handleChangeUserData} defaultValue={user.facebook}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="twitter">Twitter</label>
                                        <input type="text" name="twitter" disabled={false} id="twitter" onChange={handleChangeUserData} defaultValue={user.twitter}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="website">Website</label>
                                        <input type="text" name="website" disabled={false} id="website" onChange={handleChangeUserData} defaultValue={user.website}/>
                                    </div>
                                </div>
                            </div>
                            <hr className="separator"/>
                            <div className="content">
                                <div className="buttons-container mb">
                                    <div className="left">
                                        <p className="delete-btn">Delete account</p>
                                    </div>
                                    <div className="right">
                                        <p className="button inverted" onClick={handleSaveUserData}>Save changes</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {view === "billing" && (
                        <Elements stripe={stripePromise}>
                            <Billing subscriptionId={user.subscription} />
                        </Elements>
                    )}
                    {view === "team" && (
                        <ManageTeam />
                    )}
                </div>
            </div>
        </>
    )
}

export default Settings