import {ADMIN_MODELS_API, MODELS_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function adminGetAllModels() {
    return getAxiosInstance().get(ADMIN_MODELS_API + "/get_all")
}

function updateModel(modelInfo){
    toast.loading("Update model in progress", {toastId: "loadAlert"})
    const sendData = new FormData()
    sendData.append("_method", "PUT")
    sendData.append("id", modelInfo.id)
    sendData.append("sellingPrice", modelInfo.sellingPrice)
    sendData.append("type", modelInfo.type)
    sendData.append("field", modelInfo.field)
    sendData.append("description", modelInfo.description)

    return getAxiosInstance().post(ADMIN_MODELS_API + "/update", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function acceptProposal(modelId){
    toast.loading("Accept model proposal in progress", {toastId: "loadAlert"})
    const sendData = new FormData()
    sendData.append("_method", "PUT")
    sendData.append("id", modelId)

    return getAxiosInstance().post(ADMIN_MODELS_API + "/accept_proposal", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function rejectProposal(modelId){
    toast.loading("Reject model proposal in progress", {toastId: "loadAlert"})
    const sendData = new FormData()
    sendData.append("_method", "PUT")
    sendData.append("id", modelId)

    return getAxiosInstance().post(ADMIN_MODELS_API + "/reject_proposal", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function getUserModels(){
    return getAxiosInstance().get(MODELS_API + "/get_models")
}

function convertMapToModel(modelInfo, mapId){
    toast.loading("Convert map to model progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append("mapId", mapId)
    sendData.append("price", modelInfo.price)
    sendData.append("type", modelInfo.type)
    sendData.append("field", modelInfo.field)
    sendData.append("description", modelInfo.description)

    return getAxiosInstance().post(MODELS_API + "/convert_map", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })

}

export default {
    adminGetAllModels,
    updateModel,
    acceptProposal,
    rejectProposal,
    getUserModels,
    convertMapToModel
}