import React, {useEffect, useRef, useState} from "react";
import {BsCloudDownload} from "react-icons/bs";
import {FaUserPlus} from "react-icons/fa";
import {VscChromeClose} from "react-icons/vsc";
import {createErrorToast, createLoadingToast, createSuccessToast, removeToast} from "../services/CustomToastUtils";
import {EMAIL_REGEX} from "../../Regex";
import TeamCollaboratorAPI from "../services/API/User/TeamCollaboratorAPI";
import {useUserStore} from "../services/Stores";
import UserInvitationAPI from "../services/API/User/UserInvitationAPI";
import {createPortal} from "react-dom";
import {updateCurrentUserSubscription} from "../services/API/User/UserPaymentAPI";

const ManageTeam = () => {
    //Get data in store
    const user = useUserStore(state => state.user)
    const userSubscription = useUserStore(state => state.userSubscription)

    //Define default page state
    const [userInvitations, setUserInvitations] = useState([])

    //Get team collaborator list
    useEffect(() => {
        UserInvitationAPI.userGetAllEntityInvitations(userSubscription.id, 'subscription').then(response => {
            if (response.status === 200){
                setUserInvitations(response.data.invitations)
            }
        })
    }, [])


    const [inviteCollaboratorPopupStatus, setInviteCollaboratorPopupStatus] = useState(false)
    const inviteInputRef = useRef(null)

    const handleSendInvitations = async () => {
        const userEmail = inviteInputRef.current.value
        if (userEmail.length <= 0) {
            createErrorToast("Please enter email address for collaborator invitation")
            return
        }

        const toastId = createLoadingToast("Invitation in progress")
        inviteInputRef.current.value = ""
        setInviteCollaboratorPopupStatus(false)

        const uuid = await UserInvitationAPI.userInviteUser('subscription', userSubscription.id, 'viewer', userEmail)

        if (uuid){
            removeToast(toastId)
            setUserInvitations([...userInvitations, {firstname: "N/A", lastname: "", userEmail: userEmail, state: 'pending', uuid: uuid}])
            updateCurrentUserSubscription()
        }

    }

    const handleCancelCollaboratorInvitation = () => {
        inviteInputRef.current.value = ""
        setInviteCollaboratorPopupStatus(false)
    }
    //
    // // SEARCH
    const [search, setSearch] = useState("")

    const handleRevokeUser = async (invitationUuid) => {
        if (await UserInvitationAPI.userRevokeUser(invitationUuid)){
            createSuccessToast("User remove from team")
            setUserInvitations(userInvitations.filter(u => u.uuid !== invitationUuid))
            updateCurrentUserSubscription()
        }
    }

    return (
        <>
            {inviteCollaboratorPopupStatus && (
                createPortal(
                <div className="popup-overlay noleft">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h1 className="popup-title">Invite collaborators in your team</h1>
                            <p className="popup-close-icon" onClick={handleCancelCollaboratorInvitation}><VscChromeClose /></p>
                        </div>
                        <div className="popup-content">
                            <input ref={inviteInputRef} type="text" placeholder="Enter email address" autoFocus={true}/>
                        </div>
                        <div className="popup-footer">
                            <p className="popup-btn" onClick={handleCancelCollaboratorInvitation}>Cancel</p>
                            <p className="popup-btn valid" onClick={handleSendInvitations}>Invite collaborator</p>
                        </div>
                    </div>
                </div>
                , document.body)
            )}

            <div className="content billing">
                <h1 className="title">Manage your team</h1>
                <h2 className="subtitle">Manage your team members</h2>
                <div className="table-action-bar">
                    <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} className="search-input" placeholder="Search by email"/>
                    <div className="icon-btn" onClick={() => setInviteCollaboratorPopupStatus(true)}>
                        <p className="icon"><FaUserPlus /></p>
                        <p className="text">Invite collaborator</p>
                    </div>
                </div>
                <table className="invoices-table">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Invitation state</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                            {userInvitations.map((invitation, index) => (
                                <tr key={index}>
                                    <td>{invitation.firstname} {invitation.lastname}</td>
                                    <td>{invitation.userEmail}</td>
                                    <td>{invitation.state}</td>
                                    <td className="btn-td">
                                        {(!invitation.owner && invitation.userEmail !== user.email) && (
                                            <div className="td-btn">
                                                <div className="remove-btn"
                                                     onClick={() => handleRevokeUser(invitation.uuid)}>
                                                    <p className="icon"><VscChromeClose/></p>
                                                    <p className="text">Remove user</p>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ManageTeam