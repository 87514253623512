import React, {useContext, useEffect, useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import {BsFullscreen} from "react-icons/bs";
import {useMoocStore} from "../../services/Stores/MoocStore";
import {PiBookOpenText} from "react-icons/pi";
import {TbListCheck} from "react-icons/tb";
import {GoVideo} from "react-icons/go";
import EditorJS from "@editorjs/editorjs";
import {MdEdit} from "react-icons/md";
import EJLatex from "editorjs-latex";
import "editorjs-latex/dist/editorjs-latex.bundle.css"
import Header from '@editorjs/header'
import Quote from "@editorjs/quote"
import Delimiter from "@editorjs/delimiter"
import NestedList from "@editorjs/nested-list"
import Embed from '@editorjs/embed';
import ImageTool from "@editorjs/image"
import UserMoocCourseAPI from "../../services/API/User/Mooc/UserMoocCourseAPI";
import edjsParser from "editorjs-parser";
import {customImageParser, customLatexParser, customSpaceParser} from "../../services/Utils/EditorParseUtils";
import {MathJax, MathJaxBaseContext, MathJaxContext} from "better-react-mathjax";
import CustomTooltip from "../customs/CustomTooltip";
import MoocCourseSidebarHeader from "./MoocCourseSidebarHeader";
import {SpaceBlock} from "../EditorCustomBlocks/SpaceBlock";
import UserMoocParticipantAPI from "../../services/API/User/Mooc/UserMoocParticipantAPI";
import {useDashboardStore} from "../../services/Stores/DashboardStore";

const MoocCourseSidebar = () => {
	//Get story sidebar state in store
	const storySidebarState = useMoocStore(state => state.storiesSidebarState)

	//Get current selected course in store
	const currentSelectedCourse = useMoocStore(state => state.currentSelectedCourse)

	//Get current selected mooc in store
	const currentSelectedMooc = useMoocStore(state => state.openedMooc)

	//Get course editor instance
	const courseEditorInstance = useMoocStore(state => state.courseEditorInstance)

	//Get current course chapter
	const currentCourseChapter = useMoocStore(state => state.openedMoocChapters.filter(c => currentSelectedCourse !== null && c.courses !== undefined && c.courses !== null && c.courses.some(e => e.uuid === currentSelectedCourse.uuid))[0])

	//Define default state for edit course state
	const [editCourseState, setEditCourseState] = useState(false)

	//Get mathjax context
	const mathJaxContext = useContext(MathJaxBaseContext)

	//Get fullscreen state in store
	const fullscreenState = useMoocStore(state => state.courseFullscreenState)

	//Define default text for course index
	const [currentCourseIndex, setCurrentCourseIndex] = useState(0)

	//Generate parser for course
	// noinspection JSPotentiallyInvalidConstructorUsage
	const courseParser = new edjsParser(undefined, {
		Math: (data, config) => customLatexParser(data, config),
		image: (data, config) => customImageParser(data, config),
		space: (data, config) => customSpaceParser(data, config)
	})

	//Save course
	const handleSaveCourse = () => {
		//Check if editor instance is valid
		if (!courseEditorInstance){
			return
		}

		courseEditorInstance.save().then((data) => {
			UserMoocCourseAPI.userUpdateCourseContent(data)

			//Disable edit state
			setEditCourseState(false)

			//Update current selected course content
			useMoocStore.getState().updateCurrentCourseContent(data)

			//Get all course chapter
			const chapterCourses = currentCourseChapter.courses

			//Get current course index
			const currentCourseIndex = chapterCourses.findIndex(c => c.uuid === currentSelectedCourse.uuid)

			//Update content in chapter courses
			chapterCourses[currentCourseIndex].content = data

			//Set chapter course in store
			useMoocStore.getState().setChapterCourses(currentCourseChapter.uuid, chapterCourses)
		})
	}


	useEffect(() => {
		if (currentSelectedCourse !== null){
			if (editCourseState) {
				if (courseEditorInstance === null) {
					const editor = new EditorJS({
						holder: "course-editor-container",
						placeholder: "Create your course here",
						tools: {
							header: Header,
							quote: Quote,
							delimiter: Delimiter,
							nestedList: NestedList,
							space: SpaceBlock,
							embed: {
								class: Embed,
								config: {
									services: {
										youtube: true
									}
								}
							},
							Math: {
								class: EJLatex,
								title: "Latex equation"
							},
							image: {
								class: ImageTool,
								config: {
									uploader: {
										uploadByFile: (file) => {
											return UserMoocCourseAPI.userUploadCourseImage(file)
										}
									}
								}
							}
						},
						data: currentSelectedCourse.content !== null ? currentSelectedCourse.content : ""
					})

					useMoocStore.setState({courseEditorInstance: editor})
				}
			} else {
				//Clear course content
				if (currentSelectedCourse.content === null){
					// document.getElementById('course-content-container').innerHTML = ""
				}

				//Destroy editor instance and remove in store
				if (courseEditorInstance !== null){
					courseEditorInstance.destroy()
				}
				useMoocStore.setState({courseEditorInstance: null})

				// setCurrentCourseIndex()

				//Parse course content
				if (currentSelectedCourse.content !== null){
					document.getElementById('course-content-container').innerHTML = courseParser.parse(currentSelectedCourse.content)

					const latexContainer = document.querySelectorAll("#latex-parsed")

					latexContainer.forEach(latexElement => {
						mathJaxContext.promise.then((mathJax) => {
							mathJax.startup.promise.then(() => {
								mathJax.typesetClear([latexElement]);
								mathJax.typesetPromise([latexElement]);
							});
						});
					})
					// if (latexContainer){
					// 	mathJaxContext.promise.then((mathJax) => {
					// 		mathJax.startup.promise.then(() => {
					// 			mathJax.typesetClear([latexContainer]);
					// 			mathJax.typesetPromise([latexContainer]);
					// 		});
					// 	});
					// }
				}
			}
		}
	}, [currentSelectedCourse, editCourseState])

	const handleValidCourse = async () => {
		if (await UserMoocParticipantAPI.userValidCourse(currentSelectedCourse.uuid)){
			useMoocStore.getState().updateCourseProgression(currentCourseChapter.uuid, currentSelectedCourse.uuid, 1)
		}
	}

	const handleNextCourse = () => {
		const currentCourseIndex = currentCourseChapter.courses.findIndex(c => c.uuid ===currentSelectedCourse.uuid)
		const nextCourse = currentCourseChapter.courses[currentCourseIndex + 1]

		//Set new course
		useMoocStore.setState({currentSelectedCourse: nextCourse})
	}

	const handlePreviousCourse = () => {
		const currentCourseIndex = currentCourseChapter.courses.findIndex(c => c.uuid ===currentSelectedCourse.uuid)
		const previousCourse = currentCourseChapter.courses[currentCourseIndex - 1]

		//Set new course
		useMoocStore.setState({currentSelectedCourse: previousCourse})
	}

	const currentLayout = useMoocStore(state => state.currentCourseLayout)
	if (currentSelectedCourse !== null && currentSelectedMooc !== null && currentCourseChapter){
		return (
			<div className={!storySidebarState ? ("mooc__course-sidebar-container large layout-" + currentLayout) : "mooc__course-sidebar-container layout-" + currentLayout}>
				<MoocCourseSidebarHeader type="course" currentCourse={currentSelectedCourse} currentMooc={currentSelectedMooc} currentChapter={currentCourseChapter} editState={editCourseState} setEditState={setEditCourseState} />
				<div className="course-container">
					{/*<div className="tag-container">*/}
					{/*	<p className="tag">Overview</p>*/}
					{/*</div>*/}
					{/*<div className="course-infos-container">*/}
					{/*	<div className="course-info">*/}
					{/*		<p className="icon"><GoVideo /></p>*/}
					{/*		<p className="text">1h30 of video content</p>*/}
					{/*	</div>*/}
					{/*	<div className="course-info">*/}
					{/*		<p className="icon"><PiBookOpenText /></p>*/}
					{/*		<p className="text">3h45 reading time</p>*/}
					{/*	</div>*/}
					{/*	<div className="course-info">*/}
					{/*		<p className="icon"><TbListCheck /></p>*/}
					{/*		<p className="text">6 QCM</p>*/}
					{/*	</div>*/}
					{/*</div>*/}
					<div className="course-editor-container" id="course-editor-container" style={editCourseState ? {display: "block"} : {display: "none"}}></div>
					{!editCourseState && (
						<>
							{currentSelectedCourse.content !== null && (
								<div className="course-content-container" id="course-content-container"></div>
							)}
							{currentSelectedCourse.content === null && (
								<p className="no-course-msg">No course content</p>
							)}
						</>
					)}

				</div>
				<div className="footer">
					<div className="btn-container">
						{editCourseState && (
							<>
								<div className="btn inverted" onClick={() => setEditCourseState(false)}>Cancel</div>
								<div className="btn" onClick={handleSaveCourse}>Save</div>
							</>
						)}
						{!editCourseState && (
							<>
								{currentSelectedCourse.position > 0 && (
									<div className="btn inverted" onClick={handlePreviousCourse}>Previous</div>
								)}
								{(currentSelectedCourse.courseProgression === 1 || currentSelectedMooc.userAuthorizations.includes('mooc.edit')) && (
									<>
										{currentCourseChapter.courses.length > currentCourseChapter.courses.findIndex(c => c.uuid ===currentSelectedCourse.uuid) + 1 && (
											<div className="btn" onClick={handleNextCourse}>Next</div>
										)}
									</>
								)}
								{(currentSelectedCourse.courseProgression !== 1 && !currentSelectedMooc.userAuthorizations.includes('mooc.edit')) && (
									<>
										<div className="btn" onClick={handleValidCourse}>Mark as finished</div>
									</>
								)}
							</>
						)}

					</div>
				</div>
			</div>
		)
	} else {
		return null
	}

}

export default MoocCourseSidebar