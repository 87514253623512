import React, {useState} from "react"
import {textItemSize} from "../../../services/Utils/TextItemUtils";

const TextItemPopup = ({text, size, selected = false}) => {
	let fontSize = textItemSize[size].fontSize + 'px'
	let fontWeight = textItemSize[size].fontWeight

	return (
		<div className={"text-item-popup-container"} id="text-item-popup-container">
			<input type="text" className={selected ? "update" : ""} style={{fontSize: fontSize, fontWeight: fontWeight}} defaultValue={text} id="update-text-item-input"/>
			<span id="textWidthHelper" style={{visibility: 'hidden', position: 'absolute', whiteSpace: 'nowrap', fontSize: fontSize, fontWeight: fontWeight}}>{text}</span>
		</div>
	)
}

export default TextItemPopup