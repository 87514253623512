const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const MERCURE_URL = process.env.REACT_APP_MERCURE_HUB_URL

//Public API
export const PUBLIC_MAP_API = BACKEND_URL + "/maps"

export const LOGIN_API = BACKEND_URL + "/security/login"
export const SECURITY_CTRL = BACKEND_URL + "/security"
export const ADMIN_USER_API = BACKEND_URL + "/api/admin/user_manager"
export const ADMIN_MAP_API = BACKEND_URL + "/api/admin/maps"
export const ADMIN_MAILER_API = BACKEND_URL + "/api/admin/mail"
export const ADMIN_MAIL_TEMPLATE_API = BACKEND_URL + "/api/admin/email_template"
export const ADMIN_STATS_API = BACKEND_URL + "/api/admin/stats"
export const SSO_API = BACKEND_URL + "/oauth/connect"
export const OAUTH_API = BACKEND_URL + "/oauth"
export const USER_CONTROLLER = BACKEND_URL + "/api/user"
export const ADMIN_OBJECT_API = BACKEND_URL + "/api/admin/map_object_manager"
export const ADMIN_MAIL_HISTORY_API = BACKEND_URL + "/api/admin/mail_history"
export const DOWNLOAD_API = BACKEND_URL + "/download"
export const ADMIN_SUB_PLAN_API = BACKEND_URL + "/api/admin/subscription_plan"
export const ADMIN_MODELS_API = BACKEND_URL + "/api/admin/models"
export const ADMIN_HISTORY_API = BACKEND_URL + "/api/admin/history"
export const ADMIN_SUPPORT_TICKET_API = BACKEND_URL + "/api/admin/support"
export const ADMIN_MAINTENANCE_API = BACKEND_URL + "/api/admin/maintenance"
export const USER_ON_MAP_API = BACKEND_URL + "/api/user/user_on_map"


//USER API
export const COLLABORATORS_API = BACKEND_URL + "/api/collaborators"
export const MODELS_API = BACKEND_URL + "/api/models"
export const MAP_API = BACKEND_URL + "/api/maps"
export const STATS_API = BACKEND_URL + "/api/stats"
export const SUBSCRIPTION_API = BACKEND_URL + "/api/subscription"
export const OBJECT_API = BACKEND_URL + "/api/object"
export const COOKIE_API = BACKEND_URL + "/cookie"
export const OVERLAY_API = BACKEND_URL + "/api/user/overlay"
export const ZONE_API = BACKEND_URL + "/api/zone"
export const CONTENT_ITEM_TAG_API = BACKEND_URL + "/api/user/content_item_tag"
export const SUPPORT_TICKET_API = BACKEND_URL + "/api/support"
export const TFA_CONTROLLER_API = BACKEND_URL + "/api/tfa"
export const USER_CONTENT_ITEM_TYPE_API = BACKEND_URL + "/api/user/content_item_type"
export const USER_CONTENT_ITEM_API = BACKEND_URL + "/api/user/content_item"
export const USER_CONTENT_ITEM_COMMENT_API = BACKEND_URL + "/api/user/content_item_comment"
export const USER_AREA_API = BACKEND_URL + "/api/user/area"
export const USER_NOTIFICATION_API = BACKEND_URL + "/api/user/notification"
export const USER_MAP_COLLABORATOR_API = BACKEND_URL + "/api/user/map_collaborator"
export const USER_PAYMENT_API = BACKEND_URL + "/api/user/payment"
export const PROJECTS_API = BACKEND_URL + "/api/user/projects"
export const USER_PROMOCODE_API = BACKEND_URL + "/api/user/promocode"
export const USER_TEAM_API = BACKEND_URL + "/api/user/team"
export const USER_TEXT_ITEM_API = BACKEND_URL + "/api/user/text_item"
export const SHAPE_ITEM_API = BACKEND_URL + "/api/user/shape_item"
export const USER_BIBTEX_ITEM_API = BACKEND_URL + "/api/user/bibtex_item"
export const USER_MOOC_API = BACKEND_URL + '/api/user/mooc'
export const USER_MOOC_CHAPTER_API = BACKEND_URL + '/api/user/mooc_chapter'
export const USER_MOOC_COURSE_API = BACKEND_URL + '/api/user/mooc_course'
export const USER_MOOC_QUIZ_API = BACKEND_URL + '/api/user/mooc_quiz'
export const USER_MOOC_QUIZ_QUESTION_API = BACKEND_URL + '/api/user/mooc_quiz_question'
export const USER_MOOC_QUIZ_RESULT_API = BACKEND_URL + '/api/user/mooc_quiz_result'
export const USER_MOOC_PARTICIPANT_API = BACKEND_URL + '/api/user/mooc_participant'
export const USER_WORKSPACE_API = BACKEND_URL + '/api/user/workspace'
export const USER_INVITATION_API = BACKEND_URL + '/api/user/invitation'
export const USER_AUTHORIZATION_API = BACKEND_URL + '/api/user/authorization'
export const USER_SUBSCRIPTION_API = BACKEND_URL + '/api/user/subscription'
export const USER_SEARCH_API = BACKEND_URL + '/api/user/search'

// ADMIN API
export const ADMIN_CONTENT_ITEM_CATEGORY = BACKEND_URL + '/api/admin/content_item_category'
export const ADMIN_CONTENT_ITEM_TYPE = BACKEND_URL + '/api/admin/content_item_type'
export const ADMIN_PROMOCODE_API = BACKEND_URL + '/api/admin/promocode'
export const ADMIN_MOOC_API = BACKEND_URL + '/api/admin/mooc'

//PUBLICS API
export const PUBLICS_SUBSCRIPTION_PLAN = BACKEND_URL + "/publics/sub_plan"
export const PUBLICS_MAP = BACKEND_URL + '/publics/map'
export const PUBLICS_OVERLAY = BACKEND_URL + '/publics/overlay'
export const PUBLICS_AREA = BACKEND_URL + '/publics/area'
export const PUBLICS_CONTENT_ITEM = BACKEND_URL + '/publics/content_item'

//APP SEARCH
export const APP_SEARCH_ENDPOINT = process.env.REACT_APP_APP_SEARCH_ENDPOINT