import React, { useEffect, useRef, useState } from "react";
import { MdOutlineCropSquare, MdOutlineTextFields } from "react-icons/md";
import { ReactComponent as MyBoardsIcon } from "../../../../icons/sidebar/my-boards.svg";
import { ReactComponent as WorkspaceIcon } from "../../../../icons/sidebar/workspace_icon.svg";
import { PiStackBold, PiUsersBold } from "react-icons/pi";
import { useObjectTableStore } from "../../../services/Stores/ObjectTableStore";
import ObjectTableProjectList from "./ObjectTableProjectList";
import { BiShapeSquare, BiSolidFolder } from "react-icons/bi";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";
import UserProjectsAPI from "../../../services/API/User/UserProjectsAPI";
// import UserMapAPI from "../../../services/API/User/UserMapAPI";
import ObjectTableMapList from "./ObjectTableMapList";
import ObjectTableItemList from "./ObjectTableItemList";
import { useSidebarMapContentItemStore } from "../../../services/Stores/SidebarMapContentItemStore";
import ObjectSidebar from "../../Map/ObjectSidebar/ObjectSidebar";
import { VscChromeClose } from "react-icons/vsc";
// import {useOnClickOutside} from "../../../services/Utils";
import UserWorkspaceAPI from "../../../services/API/User/UserWorkspaceAPI";
import MapAPI from "../../../services/MapAPI";
import { useLocation } from "react-router-dom";
// import {decode, encode} from "uuid-b32";
import MyBoardSidebar from "./sidebar/MyBoardSidebar";
import SharedSidebar from "./sidebar/SharedSidebar";
// import map from "../../../pages/Map";
import UserSearchAPI from "../../../services/API/User/UserSearchAPI";
import WorkspaceSidebar from "./sidebar/WorkspaceSidebar";
import OverlayAPI from "../../../services/OverlayAPI";

const ObjectTableContainer = () => {
	//Get data in store
	const currentPanelType = useObjectTableStore((state) => state.currentPanelType);
	const currentPanel = useObjectTableStore((state) => state.currentPanel);
	const myBoardsProjects = useObjectTableStore((state) => state.myBoardsProjects);
	const currentSelectedProject = useObjectTableStore((state) => state.currentSelectedProject);
	const currentSelectedMap = useObjectTableStore((state) => state.currentSelectedMap);
	// const currentSelectedOverlay = useObjectTableStore(state => state.currentSelectedOverlay)
	// const currentSelectedArea = useObjectTableStore(state => state.currentSelectedArea)
	// const workspaceList = useObjectTableStore(state => state.workspaces)
	// const sharedMapsList = useObjectTableStore(state => state.sharedMaps)
	const currentPanelView = useObjectTableStore((state) => state.currentPanelView);

	const sidebarItemState = useSidebarMapContentItemStore((state) => state.state);

	const location = useLocation().pathname;

	useEffect(() => {
		if (location.includes("/user/map/")) {
			const mapBase32Uuid = location.split("/").slice(-1)[0];
			useObjectTableStore.setState({ currentSelectedMap: mapBase32Uuid });
		}
	});

	const handleOpenProjectPanel = (panel) => {
		useObjectTableStore.setState({
			currentPanelType: "project-list",
			currentPanel: panel,
			currentSelectedProject: "",
			currentSelectedMap: "",
		});

		if (panel === "myboards") {
			useObjectTableStore.getState().setMyBoardsOpenState(true);
		} else if (panel === "workspace") {
			useObjectTableStore.getState().setWorkspaceOpenState(true);
		} else {
			useObjectTableStore.getState().setSharedOpenState(true);
		}
	};

	const myBoardsOpenState = useObjectTableStore((state) => state.myBoardsOpenState);
	const workspaceOpenState = useObjectTableStore((state) => state.workspaceOpenState);
	const sharedOpenState = useObjectTableStore((state) => state.sharedOpenState);

	//GET USER PROJECT LIST
	useEffect(() => {
		if (myBoardsOpenState) {
			UserProjectsAPI.getAllUserProjects().then((response) => {
				if (response.status === 200) {
					useObjectTableStore.setState({
						myBoardsProjects: response.data,
					});
				}
			});
		}
	}, [myBoardsOpenState]);

	//GET USER WORKSPACE LIST
	useEffect(() => {
		if (workspaceOpenState) {
			UserWorkspaceAPI.userGetAllUserWorkspace().then((response) => {
				if (response.status === 200) {
					useObjectTableStore.setState({ workspaces: response.data });
				}
			});
		}
	}, [workspaceOpenState]);

	//GET ALL SHARED MAP
	useEffect(() => {
		if (sharedOpenState) {
			MapAPI.userGetSharedMaps().then((response) => {
				if (response.status === 200) {
					useObjectTableStore.setState({ sharedMaps: response.data });
				}
			});
		}
	}, [sharedOpenState]);

	const handleGetCurrentProjectName = () => {
		if (useObjectTableStore.getState().currentSelectedProject === "") {
			return "";
		}

		const projects = [...myBoardsProjects];

		const currentProject = projects.filter(
			(p) => p.uuid === useObjectTableStore.getState().currentSelectedProject
		)[0];

		if (!currentProject) {
			return "";
		}

		return " / " + currentProject.name;
	};

	const handleGetCurrentMapName = () => {
		if (
			useObjectTableStore.getState().currentSelectedMap === "" ||
			useObjectTableStore.getState().currentSelectedProject === ""
		) {
			return "";
		}

		//Get current project
		const currentProject = myBoardsProjects.filter(
			(p) => p.uuid === useObjectTableStore.getState().currentSelectedProject
		)[0];

		//CHeck if project exist
		if (!currentProject) {
			return "";
		}

		if (!currentProject.maps) {
			return "";
		}

		//Get current map in project
		const currentMap = currentProject.maps.filter(
			(m) => m.uuid === useObjectTableStore.getState().currentSelectedMap
		)[0];

		//Check if map exist
		if (!currentMap) {
			return "";
		}

		return " / " + currentMap.name;
	};

	const handleGetCurrentOverlayName = () => {
		if (
			useObjectTableStore.getState().currentSelectedMap === "" ||
			useObjectTableStore.getState().currentSelectedProject === "" ||
			useObjectTableStore.getState().currentSelectedOverlay === ""
		) {
			return "";
		}

		//Get current project
		const currentProject = myBoardsProjects.filter(
			(p) => p.uuid === useObjectTableStore.getState().currentSelectedProject
		)[0];

		//CHeck if project exist
		if (!currentProject) {
			return "";
		}

		if (!currentProject.maps) {
			return "";
		}

		//Get current map in project
		const currentMap = currentProject.maps.filter(
			(m) => m.uuid === useObjectTableStore.getState().currentSelectedMap
		)[0];

		//Check if map exist
		if (!currentMap || !currentMap.overlays) {
			return "";
		}

		//Get current overlay in map
		const currentOverlay = currentMap.overlays.filter(
			(o) => o.uuid === useObjectTableStore.getState().currentSelectedOverlay
		)[0];

		//Check if overlay exist
		if (!currentOverlay) {
			return "";
		}

		return " / " + currentOverlay.name;
	};

	const handleGetCurrentAreaName = () => {
		if (
			useObjectTableStore.getState().currentSelectedMap === "" ||
			useObjectTableStore.getState().currentSelectedProject === "" ||
			useObjectTableStore.getState().currentSelectedOverlay === "" ||
			useObjectTableStore.getState().currentSelectedArea === ""
		) {
			return "";
		}

		//Get current project
		const currentProject = myBoardsProjects.filter(
			(p) => p.uuid === useObjectTableStore.getState().currentSelectedProject
		)[0];

		//CHeck if project exist
		if (!currentProject) {
			return "";
		}

		if (!currentProject.maps) {
			return "";
		}

		//Get current map in project
		const currentMap = currentProject.maps.filter(
			(m) => m.uuid === useObjectTableStore.getState().currentSelectedMap
		)[0];

		//Check if map exist
		if (!currentMap || !currentMap.overlays) {
			return "";
		}

		//Get current overlay in map
		const currentOverlay = currentMap.overlays.filter(
			(o) => o.uuid === useObjectTableStore.getState().currentSelectedOverlay
		)[0];

		//Check if overlay exist
		if (!currentOverlay || !currentOverlay.areas) {
			return "";
		}

		//Get current area in overlay
		const currentArea = currentOverlay.areas.filter(
			(a) => a.uuid === useObjectTableStore.getState().currentSelectedArea
		)[0];

		//Check if area exist
		if (!currentArea) {
			return "";
		}

		return " / " + currentArea.name;
	};

	const objectTableContainerRef = useRef(null);

	//------------------------------------------------------------- //
	// ----------------- KEYBOARD NAVIGATION ---------------------- //
	//------------------------------------------------------------- //
	useEffect(() => {
		document.body.addEventListener("keydown", handleDetectKeyPress);

		return () => {
			document.body.removeEventListener("keydown", handleDetectKeyPress);
		};
	});

	const handleDetectKeyPress = (event) => {
		if (event.target.nodeName === "INPUT") {
			return;
		}
		// event.preventDefault()
		// event.stopPropagation();

		//Detect arrow up or down pressed
		if (event.key === "ArrowUp") {
			console.log("up");
			handleGoUp();
		} else if (event.key === "ArrowDown") {
			console.log("down");
			handleGoDown();
		} else if (event.code === "Space") {
			console.log("space");
			handleSpaceBar();
		}
	};

	// ----------------- handleSpaceBarTest ----------------- //
	const handleSpaceBar = () => {
		// Get the current state from the store
		const store = useObjectTableStore.getState();

		// here i check if ther are any items selected
		if (
			!store.currentSelectedProject &&
			!store.currentSelectedMap &&
			!store.currentSelectedOverlay &&
			!store.currentSelectedArea
		) {
			// if i press space open or close the currentPanel
			if (store.currentPanel === "myboards") {
				store.setMyBoardsOpenState(!myBoardsOpenState);
				return;
			}
			// if i press space open or close the currentPanel

			if (store.currentPanel === "workspace") {
				store.setWorkspaceOpenState(!workspaceOpenState);
				return;
			}
			// if i press space open or close the currentPanel

			if (store.currentPanel === "shared") {
				store.setSharedOpenState(!sharedOpenState);
				return;
			}
		}

		// Check if we're in the "myboards" panel
		// -------------------- LOGIC FOR MYBOARDS PANEL ---------------------//
		if (store.currentPanel === "myboards") {
			// ----------------LOGIC FOR THE OVERLAYS---------------------------------//
			// Check if an overlay is selected
			if (store.currentSelectedOverlay) {
				// Find which project and map contains the selected overlay
				const projectWithOverlay = store.myBoardsProjects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
						)
				);

				if (!projectWithOverlay) return;

				const mapWithOverlay = projectWithOverlay.maps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;

				const selectedOverlay = mapWithOverlay.overlays.find((o) => o.uuid === store.currentSelectedOverlay);

				if (!selectedOverlay) return;

				// Toggle the open state of the selected overlay
				store.updateMyBoardsOverlayOpenState(
					projectWithOverlay.uuid,
					mapWithOverlay.uuid,
					selectedOverlay.uuid,
					!selectedOverlay.open
				);

				// fetch api to get the areas of the overlay like in the overlay component
				OverlayAPI.getOverlayAreas(selectedOverlay.uuid).then((response) => {
					if (response.status === 200) {
						store.updateMyBoardsOverlayAreas(
							projectWithOverlay.uuid,
							mapWithOverlay.uuid,
							selectedOverlay.uuid,
							response.data
						);
					} else {
						store.updateMyBoardsOverlayAreas(
							projectWithOverlay.uuid,
							mapWithOverlay.uuid,
							selectedOverlay.uuid,
							[]
						);
					}
				});

				return;
			}
			// -------------------------------LOGIC FOR THE MAPS---------------------------------//
			// If a map is currently selected
			if (store.currentSelectedMap) {
				// Find which project contains the currently selected map
				const selectedMapProjectObject = store.myBoardsProjects.find(
					// Search through all projects that have maps and find the one containing our map
					(p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap)
				);

				// If we couldn't find the project, exit the function
				if (!selectedMapProjectObject) return;

				// Find the specific map object within the project
				const selectedMapObject = selectedMapProjectObject.maps.find(
					(m) => m.uuid === store.currentSelectedMap
				);

				// Select the project that contains this map
				useObjectTableStore.setState({
					currentSelectedProject: selectedMapProjectObject.uuid,
				});

				// Toggle the open state of the selected map (expand/collapse)
				// This function is defined elsewhere in the store
				store.updateMyBoardsMapOpenState(
					selectedMapProjectObject.uuid, // Project UUID
					selectedMapObject.uuid, // Map UUID
					!selectedMapObject.open // New state (opposite of current state)
				);

				return;
			}

			//------------------------------LOGIC FOR THE PROJECTS---------------------------------//
			// If a project is selected but no map is selected
			if (store.currentSelectedProject) {
				// Find the project object that's currently selected
				const currentSelectedProjectObject = store.myBoardsProjects.find(
					(p) => p.uuid === store.currentSelectedProject
				);

				// If we couldn't find the project, exit the function
				if (!currentSelectedProjectObject) return;

				// Toggle the open state of the selected project (expand/collapse)
				// This function is defined elsewhere in the store
				store.updateMyBoardsProjectOpenState(
					currentSelectedProjectObject.uuid, // Project UUID
					!currentSelectedProjectObject.open // New state (opposite of current state)
				);
			}
		}
		// -------------------- LOGIC FOR MYBOARDS PANEL -------------------//
		// If we're in the "workspace" panel
		if (store.currentPanel === "workspace") {
			// -------------------------LOGIC FOR THE OVERLAY---------------------------------//
			if (store.currentSelectedOverlay) {
				// Find which workspace contains the project that contains the map that contains the overlay
				const workspaceWithOverlay = store.workspaces.find(
					(w) =>
						w.projects &&
						w.projects.some(
							(p) =>
								p.maps &&
								p.maps.some(
									(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
								)
						)
				);

				if (!workspaceWithOverlay) return;

				// Find the project containing the map with the overlay
				const projectWithOverlay = workspaceWithOverlay.projects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
						)
				);

				if (!projectWithOverlay) return;

				// Find the map containing the overlay
				const mapWithOverlay = projectWithOverlay.maps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;

				// Find the selected overlay
				const selectedOverlay = mapWithOverlay.overlays.find((o) => o.uuid === store.currentSelectedOverlay);

				if (!selectedOverlay) return;

				// Toggle the open state of the selected overlay using the workspace-specific function
				store.updateWorkspaceProjectMapOverlayOpenState(
					workspaceWithOverlay.uuid,
					projectWithOverlay.uuid,
					mapWithOverlay.uuid,
					selectedOverlay.uuid,
					!selectedOverlay.open
				);

				// i fetch the areas of the overlay using the OverlayAPI
				OverlayAPI.getOverlayAreas(selectedOverlay.uuid).then((response) => {
					if (response.status === 200) {
						store.updateWorkspaceProjectMapOverlayAreas(
							workspaceWithOverlay.uuid,
							projectWithOverlay.uuid,
							mapWithOverlay.uuid,
							selectedOverlay.uuid,
							response.data
						);
					} else {
						store.updateWorkspaceProjectMapOverlayAreas(
							workspaceWithOverlay.uuid,
							projectWithOverlay.uuid,
							mapWithOverlay.uuid,
							selectedOverlay.uuid,
							[]
						);
					}
				});

				return;
			}

			// --------------------------------------LOGIC FOR THE MAPS---------------------------------//
			// check the low level of the data so in this case maps like in the myboards panel
			if (store.currentSelectedMap) {
				// 3 nested loops to find the selected map so i must go through the workspace then the project and then the map
				const selectedWorkspace = store.workspaces.find(
					// .some i check i one the elements in the array pass the test
					(w) =>
						w.projects &&
						w.projects.some(
							(p) =>
								// do the same with the projects
								p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap)
						)
				);
				// if it do not find the workspace, stop there exit the function with a return
				if (!selectedWorkspace) return;

				// i find the project that contain the selected map by using the selected workspace
				const selectedProject = selectedWorkspace.projects.find(
					(p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap)
				);

				// if it do not find the project, stop there exit the function with a return
				if (!selectedProject) return;

				//again i go down 1 level to the map so the last stage is the projects in which there are the maps
				const selectedMap = selectedProject.maps.find(
					// i loop through the array of maps to find the selected map by check the uuid
					(m) => m.uuid === store.currentSelectedMap
				);

				//toggle the open state of the selected map, i give it the differents uuids of the corresponding workspace, project and map i check earlier with the .find and .some methods
				store.updateWorkspaceMapOpenState(
					selectedWorkspace.uuid, // workspace uuid
					selectedProject.uuid, // project uuid
					selectedMap.uuid, // map uuid
					!selectedMap.open // new state it's the reverse of the current state
				);

				return;
			}
			// --------------------------------------LOGIC FOR THE PROJECTS---------------------------------//
			// here is the same logic as the myboards panel but with the projects so from the workspace to the project only, because the check is above
			if (store.currentSelectedProject) {
				// i search for the project that is selected inside the workspaces
				const selectedWorkspace = store.workspaces.find((w) => w.uuid === store.currentSelectedProject);

				if (selectedWorkspace) {
					store.updateWorkspaceOpenState(selectedWorkspace.uuid, !selectedWorkspace.open);
					return;
				}

				const workspaceProject = store.workspaces.find(
					(w) => w.projects && w.projects.some((p) => p.uuid === store.currentSelectedProject)
				);
				if (!workspaceProject) return;

				const currentProject = workspaceProject.projects.find((p) => p.uuid === store.currentSelectedProject);

				store.updateWorkspaceProjectOpenState(workspaceProject.uuid, currentProject.uuid, !currentProject.open);
			}
		}
		// -------------------- LOGIC FOR SHARED PANEL -------------------//
		if (store.currentPanel === "shared") {
			//------------------------------LOGIC FOR THE OVERLAYS---------------------------------//
			// one level below the maps so overlay, i check if its an overlay selected
			if (store.currentSelectedOverlay) {
				// the overlay is in a map so i need to find the map that contains the overlay
				const mapWithOverlay = store.sharedMaps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;

				// now i get the overlay by comparing the uuid and the currentSelectedOverlay
				const selectedOverlay = mapWithOverlay.overlays.find((o) => o.uuid === store.currentSelectedOverlay);

				if (!selectedOverlay) return;
				// then i pass the params uuids and the new state to the function that will update the overlay state
				store.updateSharedOverlayOpenState(mapWithOverlay.uuid, selectedOverlay.uuid, !selectedOverlay.open);

				// fetching the areas
				OverlayAPI.getOverlayAreas(selectedOverlay.uuid).then((response) => {
					if (response.status === 200) {
						store.updateSharedOverlayAreas(mapWithOverlay.uuid, selectedOverlay.uuid, response.data);
					} else {
						store.updateSharedOverlayAreas(mapWithOverlay.uuid, selectedOverlay.uuid, []);
					}
				});
				return;
			}
			//------------------------------LOGIC FOR THE MAPS---------------------------------//
			// Check if a map is selected
			if (store.currentSelectedMap) {
				// i search the selected map by comparing the uuid in my sharedMaps array from the zustand store
				const selectedMap = store.sharedMaps.find((m) => m.uuid === store.currentSelectedMap);
				if (!selectedMap) return;
				// update its state
				store.updateSharedMapOpenState(selectedMap.uuid, !selectedMap.open);
			}
		}
	};

	// ----------------- handleGoUp ----------------- //

	const handleGoUp = () => {
		const store = useObjectTableStore.getState();
		// check if im in the level currentPanel basically
		if (
			!store.currentSelectedProject &&
			!store.currentSelectedMap &&
			!store.currentSelectedOverlay &&
			!store.currentSelectedArea
		) {
			// switch between the panels
			if (store.currentPanel === "workspace") {
				useObjectTableStore.setState({
					currentPanel: "myboards",
				});
				return;
			}
			// switch between the panels
			if (store.currentPanel === "shared") {
				useObjectTableStore.setState({
					currentPanel: "workspace",
				});
				return;
			}
			return;
		}

		if (store.currentPanel === "myboards") {
			// ------------------------ Logic to GO UP IN THE AREAS -------------------- //
			if (store.currentSelectedArea) {
				const projectWithArea = store.myBoardsProjects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) =>
								m.overlays &&
								m.overlays.some(
									(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
								)
						)
				);

				if (!projectWithArea) return;

				const mapWithArea = projectWithArea.maps.find(
					(m) =>
						m.overlays &&
						m.overlays.some((o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea))
				);

				if (!mapWithArea) return;

				const overlayWithArea = mapWithArea.overlays.find(
					(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
				);

				if (!overlayWithArea) return;

				const areaIndex = overlayWithArea.areas.findIndex((a) => a.uuid === store.currentSelectedArea);

				if (areaIndex > 0) {
					useObjectTableStore.setState({
						currentSelectedArea: overlayWithArea.areas[areaIndex - 1].uuid,
						currentSelectedOverlay: overlayWithArea.uuid,
						currentSelectedMap: mapWithArea.uuid,
						currentSelectedProject: projectWithArea.uuid,
					});
					return;
				}

				useObjectTableStore.setState({
					currentSelectedArea: "",
					currentSelectedOverlay: overlayWithArea.uuid,
					currentSelectedMap: mapWithArea.uuid,
					currentSelectedProject: projectWithArea.uuid,
				});
				return;
			}

			// ------------------------ Logic to GO UP IN THE OVERLAYS -------------------- //
			if (store.currentSelectedOverlay) {
				// I find the project and map that contains the selected overlay
				const projectWithOverlay = store.myBoardsProjects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
						)
				);

				if (!projectWithOverlay) return;

				const mapWithOverlay = projectWithOverlay.maps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;

				useObjectTableStore.setState({
					currentSelectedOverlay: "",
					currentSelectedMap: mapWithOverlay.uuid,
					currentSelectedProject: projectWithOverlay.uuid,
				});
				return;
			}
			// ------------------------ Logic to GO UP IN THE MAPS -------------------- //
			if (store.currentSelectedMap) {
				// console.log("store.currentSelectedMap: ", store.currentSelectedMap);
				// Get selected map project
				const selectedMapProjectObject = store.myBoardsProjects.find(
					(p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap)
				);

				if (!selectedMapProjectObject) return;

				const selectedMapObject = selectedMapProjectObject.maps.find(
					(m) => m.uuid === store.currentSelectedMap
				);

				const selectedMapIndex = selectedMapProjectObject.maps.findIndex(
					(m) => m.uuid === selectedMapObject.uuid
				);

				// Check if project has map before current map
				if (selectedMapIndex > 0) {
					// Get previous map uuid
					const previousMap = selectedMapProjectObject.maps[selectedMapIndex - 1];

					if (previousMap.overlays && previousMap.overlays.length > 0 && previousMap.open) {
						const lastOverlay = previousMap.overlays[previousMap.overlays.length - 1];

						useObjectTableStore.setState({
							currentSelectedMap: previousMap.uuid,
							currentSelectedOverlay: lastOverlay.uuid,
						});
					} else {
						useObjectTableStore.setState({
							currentSelectedMap: previousMap.uuid,
							currentSelectedOverlay: "",
						});
					}
				} else {
					useObjectTableStore.setState({
						currentSelectedMap: "",
						currentSelectedProject: selectedMapProjectObject.uuid,
						currentSelectedOverlay: "",
					});
				}
				return;
			}
			// ------------------------ Logic to GO UP IN THE PROJECTS -------------------- //
			// Check if project is selected
			if (store.currentSelectedProject) {
				// Get current project index
				const currentProjectIndex = store.myBoardsProjects.findIndex(
					(p) => p.uuid === store.currentSelectedProject
				);

				// Check if current project is not first
				if (currentProjectIndex > 0) {
					// Get previous project object
					const previousProject = store.myBoardsProjects[currentProjectIndex - 1];

					// Check if previous project has maps
					if (previousProject.maps && previousProject.maps.length > 0 && previousProject.open) {
						const lastMap = previousProject.maps[previousProject.maps.length - 1];
						// Check if last map has overlays
						if (lastMap.overlays && lastMap.overlays.length > 0 && lastMap.open) {
							// i get the last overlay of the last map
							const lastOverlay = lastMap.overlays[lastMap.overlays.length - 1];

							if (lastOverlay.open && lastOverlay.areas && lastOverlay.areas.length > 0) {
								useObjectTableStore.setState({
									currentSelectedProject: previousProject.uuid,
									currentSelectedMap: lastMap.uuid,
									currentSelectedOverlay: lastOverlay.uuid,
									currentSelectedArea: lastOverlay.areas[lastOverlay.areas.length - 1].uuid,
								});
								return;
							}
							// if so i get to the last overlay of the last
							useObjectTableStore.setState({
								currentSelectedProject: previousProject.uuid,
								currentSelectedMap: lastMap.uuid,
								currentSelectedOverlay: lastOverlay.uuid,
							});
						} else {
							// if not i just go to the last map of the previous project
							useObjectTableStore.setState({
								currentSelectedProject: previousProject.uuid,
								currentSelectedMap: lastMap.uuid,
								currentSelectedOverlay: "",
							});
						}
					} else {
						// I get to the previous project
						useObjectTableStore.setState({
							currentSelectedProject: previousProject.uuid,
							currentSelectedMap: "",
							currentSelectedOverlay: "",
						});
					}
				} else {
					// If it's the first project, then i should go to the currentPanel, in this case it will be 'mybobards'
					useObjectTableStore.setState({
						currentSelectedProject: "",
						currentSelectedMap: "",
						currentSelectedOverlay: "",
					});
				}
				return;
			}
		}
		if (store.currentPanel === "workspace") {
			// ----------------------------- Logic to GO UP IN THE AREAS -------------------- //
			if (store.currentSelectedArea) {
				const workspaceWithArea = store.workspaces.find(
					(w) =>
						w.projects &&
						w.projects.some(
							(p) =>
								p.maps &&
								p.maps.some(
									(m) =>
										m.overlays &&
										m.overlays.some(
											(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
										)
								)
						)
				);

				if (!workspaceWithArea) return;

				const projectWithArea = workspaceWithArea.projects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) =>
								m.overlays &&
								m.overlays.some(
									(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
								)
						)
				);

				if (!projectWithArea) return;

				const mapWithArea = projectWithArea.maps.find(
					(m) =>
						m.overlays &&
						m.overlays.some((o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea))
				);

				if (!mapWithArea) return;

				const overlayWithArea = mapWithArea.overlays.find(
					(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
				);

				if (!overlayWithArea) return;

				// Find the area index
				const areaIndex = overlayWithArea.areas.findIndex((a) => a.uuid === store.currentSelectedArea);

				// if there is a previous aream select it --> areaIndex - 1.uuid
				if (areaIndex > 0) {
					useObjectTableStore.setState({
						currentSelectedArea: overlayWithArea.areas[areaIndex - 1].uuid,
					});
					return;
				}

				// If this is the first area, go up to the overlay level
				useObjectTableStore.setState({
					currentSelectedArea: "",
					currentSelectedOverlay: overlayWithArea.uuid,
				});
				return;
			}
			// ----------------------------- Logic to GO UP IN THE OVERLAYS -------------------- //
			if (store.currentSelectedOverlay) {
				// Find which workspace contains the project that contains the map that contains the overlay
				const workspaceWithOverlay = store.workspaces.find(
					(w) =>
						w.projects &&
						w.projects.some(
							(p) =>
								p.maps &&
								p.maps.some(
									(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
								)
						)
				);

				if (!workspaceWithOverlay) return;

				// Find the project containing the map with the overlay
				const projectWithOverlay = workspaceWithOverlay.projects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
						)
				);

				if (!projectWithOverlay) return;

				// Find the map containing the overlay
				const mapWithOverlay = projectWithOverlay.maps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;

				// Find the overlay index
				const overlayIndex = mapWithOverlay.overlays.findIndex((o) => o.uuid === store.currentSelectedOverlay);

				// If there's a previous overlay, select it
				if (overlayIndex > 0) {
					const previousOverlayUuid = mapWithOverlay.overlays[overlayIndex - 1].uuid;

					useObjectTableStore.setState({
						currentSelectedOverlay: previousOverlayUuid,
					});
					return;
				}

				// If this is the first overlay, move up to the map level
				useObjectTableStore.setState({
					currentSelectedOverlay: "",
					currentSelectedMap: mapWithOverlay.uuid,
				});
				return;
			}
			// ----------------------------- Logic to GO UP IN THE MAPS -------------------- //
			// i check if there a map selected
			if (store.currentSelectedMap) {
				const selectedWorkspace = store.workspaces.find(
					(w) =>
						w.projects &&
						w.projects.some((p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap))
				);

				if (!selectedWorkspace) return;

				const selectedProject = selectedWorkspace.projects.find(
					(p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap)
				);
				if (!selectedProject) return;

				// i check the map index like i did with the workspaces and project
				const selectedMapIndex = selectedProject.maps.findIndex((m) => m.uuid === store.currentSelectedMap);

				// i check if there is maps above my map so i should go to this other map or not, so just go to the project of the map
				if (selectedMapIndex > 0) {
					const previousMapUuid = selectedProject.maps[selectedMapIndex - 1].uuid;

					// set the map above
					useObjectTableStore.setState({
						currentSelectedMap: previousMapUuid,
					});
				} else {
					useObjectTableStore.setState({
						currentSelectedMap: "",
						currentSelectedProject: selectedProject.uuid,
					});
				}
				return;
			}
			// ----------------------------- Logic to GO UP IN THE PROJECTS -------------------- //
			// check if a project or workspace is selected
			if (store.currentSelectedProject) {
				const selectedWorkspace = store.workspaces.find((w) => w.uuid === store.currentSelectedProject);
				// so if i pass its a workspace
				if (selectedWorkspace) {
					// i should get its index
					const workspaceIndex = store.workspaces.findIndex((w) => w.uuid === selectedWorkspace.uuid);

					// like the maps i check id there are some above
					if (workspaceIndex > 0) {
						const previousWorkspace = store.workspaces[workspaceIndex - 1];

						// here is the logic of going up in the workspace
						// so if there is a workspace then i should check if its open and if it has projects and then if the projects are open and if the porjects has maps
						if (
							previousWorkspace.open &&
							previousWorkspace.projects &&
							previousWorkspace.projects.length > 0
						) {
							const lastProject = previousWorkspace.projects[previousWorkspace.projects.length - 1];

							if (lastProject.open && lastProject.maps && lastProject.maps.length > 0) {
								const lastMap = lastProject.maps[lastProject.maps.length - 1];
								// check if the last map has overlays open
								if (lastMap.open && lastMap.overlays && lastMap.overlays.length > 0) {
									const lastOverlay = lastMap.overlays[lastMap.overlays.length - 1];

									// check if the last overlay has areas open
									if (lastOverlay.open && lastOverlay.areas && lastOverlay.areas.length > 0) {
										useObjectTableStore.setState({
											currentSelectedProject: lastProject.uuid,
											currentSelectedMap: lastMap.uuid,
											currentSelectedOverlay: lastOverlay.uuid,
											currentSelectedArea: lastOverlay.areas[lastOverlay.areas.length - 1].uuid,
										});
										return;
									}

									useObjectTableStore.setState({
										currentSelectedProject: lastProject.uuid,
										currentSelectedMap: lastMap.uuid,
										currentSelectedOverlay: lastOverlay.uuid,
									});
									return;
								}

								useObjectTableStore.setState({
									currentSelectedProject: lastProject.uuid,
									currentSelectedMap: lastMap.uuid,
								});
							} else {
								// no maps so just project
								useObjectTableStore.setState({
									currentSelectedProject: lastProject.uuid,
									currentSelectedMap: "",
								});
							}
							// no projects so just workspace
						} else {
							useObjectTableStore.setState({
								currentSelectedProject: previousWorkspace.uuid,
								currentSelectedMap: "",
							});
						}
					} else {
						useObjectTableStore.setState({
							currentSelectedProject: "",
							currentSelectedMap: "",
							currentSelectedOverlay: "",
							currentSelectedArea: "",
						});
					}
					return;
				}
				// its a project
				// so its in a workspace
				const workspaceProject = store.workspaces.find(
					(w) => w.projects && w.projects.some((p) => p.uuid === store.currentSelectedProject)
				);

				if (!workspaceProject) return;

				// i get the project's index to check if there are projects above it
				const projectIndex = workspaceProject.projects.findIndex(
					(p) => p.uuid === store.currentSelectedProject
				);
				// checking
				if (projectIndex > 0) {
					const previousProject = workspaceProject.projects[projectIndex - 1];

					// like before if check if the previous project is open has maps
					if (previousProject.open && previousProject.maps && previousProject.maps.length > 0) {
						const lastMap = previousProject.maps[previousProject.maps.length - 1];

						if (lastMap.open && lastMap.overlays && lastMap.overlays.length > 0) {
							const lastOverlay = lastMap.overlays[lastMap.overlays.length - 1];

							if (lastOverlay.open && lastOverlay.areas && lastOverlay.areas.length > 0) {
								useObjectTableStore.setState({
									currentSelectedProject: previousProject.uuid,
									currentSelectedMap: lastMap.uuid,
									currentSelectedOverlay: lastOverlay.uuid,
									currentSelectedArea: lastOverlay.areas[lastOverlay.areas.length - 1].uuid,
								});
								return;
							}

							useObjectTableStore.setState({
								currentSelectedProject: previousProject.uuid,
								currentSelectedMap: lastMap.uuid,
								currentSelectedOverlay: lastOverlay.uuid,
							});
							return;
						}

						useObjectTableStore.setState({
							currentSelectedProject: previousProject.uuid,
							currentSelectedMap: lastMap.uuid,
						});
					} else {
						// if not open
						// i just get to the next project
						useObjectTableStore.setState({
							currentSelectedProject: previousProject.uuid,
							currentSelectedMap: "",
						});
					}
				} else {
					// go to the workspace
					useObjectTableStore.setState({
						currentSelectedProject: workspaceProject.uuid,
						currentSelectedMap: "",
					});
				}
			}
		}
		if (store.currentPanel === "shared") {
			console.log("shared currentpanel: ", store.currentPanel);

			// ----------------------- Logic to GO UP IN THE AREAS -------------------- //
			if (store.currentSelectedArea) {
				const mapWithArea = store.sharedMaps.find(
					(m) =>
						m.overlays &&
						m.overlays.some((o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea))
				);

				if (!mapWithArea) return;

				const overlayWithArea = mapWithArea.overlays.find(
					(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
				);

				if (!overlayWithArea) return;

				useObjectTableStore.setState({
					currentSelectedArea: "",
					currentSelectedOverlay: overlayWithArea.uuid,
					currentSelectedMap: mapWithArea.uuid,
				});
				return;
			}
			// ------------------------------ Logic to GO UP IN THE OVERLAYS -------------------- //
			if (store.currentSelectedOverlay) {
				const mapWithOverlay = store.sharedMaps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;

				const overlayIndex = mapWithOverlay.overlays.findIndex((o) => o.uuid === store.currentSelectedOverlay);

				if (overlayIndex > 0) {
					const previousOverlay = mapWithOverlay.overlays[overlayIndex - 1];

					if (previousOverlay.open && previousOverlay.areas && previousOverlay.areas.length > 0) {
						useObjectTableStore.setState({
							currentSelectedOverlay: previousOverlay.uuid,
							currentSelectedArea: previousOverlay.areas[previousOverlay.areas.length - 1].uuid,
						});
						return;
					}
					useObjectTableStore.setState({
						currentSelectedOverlay: previousOverlay.uuid,
						currentSelectedArea: "",
					});
					return;
				}
				useObjectTableStore.setState({
					currentSelectedOverlay: "",
					currentSelectedMap: mapWithOverlay.uuid,
				});
				return;
			}
			// ------------------------ Logic to GO UP IN THE MAPS -------------------- //
			if (store.currentSelectedMap) {
				const selectedMap = store.sharedMaps.find((m) => m.uuid === store.currentSelectedMap);
				if (!selectedMap) return;

				const mapIndex = store.sharedMaps.findIndex((m) => m.uuid === store.currentSelectedMap);

				if (mapIndex > 0) {
					const previousMap = store.sharedMaps[mapIndex - 1];

					if (previousMap.open && previousMap.overlays && previousMap.overlays.length > 0) {
						const lastOverlay = previousMap.overlays[previousMap.overlays.length - 1];

						if (lastOverlay.open && lastOverlay.areas && lastOverlay.areas.length > 0) {
							useObjectTableStore.setState({
								currentSelectedMap: previousMap.uuid,
								currentSelectedOverlay: lastOverlay.uuid,
								currentSelectedArea: lastOverlay.areas[lastOverlay.areas.length - 1].uuid,
							});
							return;
						}

						useObjectTableStore.setState({
							currentSelectedMap: previousMap.uuid,
							currentSelectedOverlay: lastOverlay.uuid,
							currentSelectedArea: "",
						});
					} else {
						useObjectTableStore.setState({
							currentSelectedMap: previousMap.uuid,
							currentSelectedOverlay: "",
							currentSelectedArea: "",
						});
					}
				} else {
					useObjectTableStore.setState({
						currentSelectedMap: "",
						currentSelectedOverlay: "",
						currentSelectedArea: "",
					});
				}
			}
		}
	};
	// ----------------- handleGoDown ----------------- //

	const handleGoDown = () => {
		// I const store = useObjectTableStore.getState(); so it's easier to access the store
		const store = useObjectTableStore.getState();
		// i check if the store current panel if myboards
		if (
			!store.currentSelectedProject &&
			!store.currentSelectedMap &&
			!store.currentSelectedOverlay &&
			!store.currentSelectedArea
		) {
			if (store.currentPanel === "myboards" && !myBoardsOpenState) {
				useObjectTableStore.setState({
					currentPanel: "workspace",
				});
				return;
			}
			if (store.currentPanel === "workspace" && !workspaceOpenState) {
				useObjectTableStore.setState({
					currentPanel: "shared",
				});
				return;
			}

			// If the current panel is open, navigate to its first item instead of changing panels
			if (store.currentPanel === "myboards" && myBoardsOpenState && store.myBoardsProjects.length > 0) {
				useObjectTableStore.setState({
					currentSelectedProject: store.myBoardsProjects[0].uuid,
				});
				return;
			}

			if (store.currentPanel === "workspace" && workspaceOpenState && store.workspaces.length > 0) {
				useObjectTableStore.setState({
					currentSelectedProject: store.workspaces[0].uuid,
				});
				return;
			}

			if (store.currentPanel === "shared" && sharedOpenState && store.sharedMaps && store.sharedMaps.length > 0) {
				useObjectTableStore.setState({
					currentSelectedMap: store.sharedMaps[0].uuid,
				});
				return;
			}

			return;
		}
		if (store.currentPanel === "myboards") {
			// console.log("hello store.currentPanel: ", store.currentPanel);
			// prevent the function to go down the current panel is not opened
			if (
				!myBoardsOpenState &&
				!store.currentSelectedProject &&
				!store.currentSelectedMap &&
				!store.currentSelectedOverlay &&
				!store.currentSelectedArea
			) {
				return;
			}
			// -------------------------------- LOGIC TO GO DOWN IN THE AREAS ------------------------------ //
			if (store.currentSelectedArea) {
				// i find the project->map->overlay->area
				const projectWithArea = store.myBoardsProjects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) =>
								m.overlays &&
								m.overlays.some(
									(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
								)
						)
				);

				if (!projectWithArea) return;

				// descend throught the folders
				const mapWithArea = projectWithArea.maps.find(
					(m) =>
						m.overlays &&
						m.overlays.some((o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea))
				);

				if (!mapWithArea) return;

				// descend again
				const overlayWithArea = mapWithArea.overlays.find(
					(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
				);

				if (!overlayWithArea) return;

				// i get the index of the selected area
				const areaIndex = overlayWithArea.areas.findIndex((a) => a.uuid === store.currentSelectedArea);

				// if there is an area below the selected area
				if (overlayWithArea.areas.length > areaIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: overlayWithArea.areas[areaIndex + 1].uuid,
					});
					return;
				}
				// so at this point i'm navigating throught the areas, in the last if statement but else i should go up to the next overlay ... --> map --> project
				const overlayIndex = mapWithArea.overlays.findIndex((o) => o.uuid === overlayWithArea.uuid);

				if (mapWithArea.overlays.length > overlayIndex + 1) {
					const nextOverlay = mapWithArea.overlays[overlayIndex + 1];
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: nextOverlay.uuid,
					});
					return;
				}

				// if this is the last overlay, i move to the next map
				const mapIndex = projectWithArea.maps.findIndex((m) => m.uuid === mapWithArea.uuid);

				if (projectWithArea.maps.length > mapIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: "",
						currentSelectedMap: projectWithArea.maps[mapIndex + 1].uuid,
					});
					return;
				}

				// if this is the last map, i move to the next project
				const projectIndex = store.myBoardsProjects.findIndex((p) => p.uuid === projectWithArea.uuid);

				if (store.myBoardsProjects.length > projectIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: "",
						currentSelectedMap: "",
						currentSelectedProject: store.myBoardsProjects[projectIndex + 1].uuid,
					});
				}
				return;
			}
			// ------------------------------ LOGIC TO GO DOWN IN THE OVERLAYS ------------------------------ //
			if (store.currentSelectedOverlay) {
				// console.log(
				//   "hello store.currentSelectedOverlay: ",
				//   store.currentSelectedOverlay
				// );
				// i find the selected overlay map project
				const selectedOverlayMapProjectObject = store.myBoardsProjects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
						)
				);
				if (!selectedOverlayMapProjectObject) return;

				// i find the selected map
				const selectedMap = selectedOverlayMapProjectObject.maps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!selectedMap) return;

				const selectedOverlay = selectedMap.overlays.find((o) => o.uuid === store.currentSelectedOverlay);

				if (!selectedOverlay) return;

				const selectedOverlayIndex = selectedMap.overlays.findIndex(
					(o) => o.uuid === store.currentSelectedOverlay
				);

				if (selectedOverlay.open && selectedOverlay.areas && selectedOverlay.areas.length > 0) {
					useObjectTableStore.setState({
						currentSelectedArea: selectedOverlay.areas[0].uuid,
					});
					return;
				}

				if (selectedMap.overlays.length > selectedOverlayIndex + 1) {
					const nextOverlayUuid = selectedMap.overlays[selectedOverlayIndex + 1].uuid;

					useObjectTableStore.setState({
						currentSelectedOverlay: nextOverlayUuid,
					});
					return;
				}

				const selectedMapIndex = selectedOverlayMapProjectObject.maps.findIndex(
					(m) => m.uuid === selectedMap.uuid
				);

				if (selectedOverlayMapProjectObject.maps.length > selectedMapIndex + 1) {
					const nextMapUuid = selectedOverlayMapProjectObject.maps[selectedMapIndex + 1].uuid;

					useObjectTableStore.setState({
						currentSelectedMap: nextMapUuid,
						currentSelectedOverlay: "",
					});
					return;
				}

				const currentProjectIndex = store.myBoardsProjects.findIndex(
					(p) => p.uuid === selectedOverlayMapProjectObject.uuid
				);

				if (store.myBoardsProjects.length > currentProjectIndex + 1) {
					const nextProjectUuid = store.myBoardsProjects[currentProjectIndex + 1].uuid;

					useObjectTableStore.setState({
						currentSelectedProject: nextProjectUuid,
						currentSelectedMap: "",
						currentSelectedOverlay: "",
					});
				}
				return;
			}
			// ----------------------------- LOGIC TO GO DOWN IN THE MAPS ----------------------------- //
			//Check if map is selected
			if (store.currentSelectedMap) {
				// console.log(
				//   "hello store.currentSelectedMap: ",
				//   store.currentSelectedMap
				// );
				//Get selected map project
				// i find with .find the project that has the map that is selected
				const selectedMapProjectObject = store.myBoardsProjects.find(
					(p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap)
				);
				// if not return the function so it stops
				if (!selectedMapProjectObject) return;
				// i find the selected map object
				const selectedMapObject = selectedMapProjectObject.maps.find(
					(m) => m.uuid === store.currentSelectedMap
				);

				if (!selectedMapObject) return;

				if (selectedMapObject.open && selectedMapObject.overlays && selectedMapObject.overlays.length > 0) {
					const firstOverlayUuid = selectedMapObject.overlays[0].uuid;

					useObjectTableStore.setState({
						currentSelectedOverlay: firstOverlayUuid,
					});
					return;
				}

				// i find the index of the selected map
				const selectedMapIndex = selectedMapProjectObject.maps.findIndex(
					(m) => m.uuid === selectedMapObject.uuid
				);

				//Check if current map is last in project
				if (selectedMapProjectObject.maps.length > selectedMapIndex + 1) {
					//Get next map uuid
					const nextMapUuid = selectedMapProjectObject.maps[selectedMapIndex + 1].uuid;

					//Select next map
					useObjectTableStore.setState({
						currentSelectedMap: nextMapUuid,
					});
					return;
				}
				//Get current project index
				const currentProjectIndex = store.myBoardsProjects.findIndex(
					(p) => p.uuid === selectedMapProjectObject.uuid
				);

				//Check if next project is available
				if (store.myBoardsProjects.length > currentProjectIndex + 1) {
					//Get next project uuid
					const nextProjectUuid = store.myBoardsProjects[currentProjectIndex + 1].uuid;

					//Unselect map and select next project
					useObjectTableStore.setState({
						currentSelectedProject: nextProjectUuid,
						currentSelectedMap: "",
					});
				}
				return;
			}
			// ---------------------------- LOGIC TO GO DOWN IN THE PROJECTS ---------------------------- //
			//Check if project is selected
			if (store.currentSelectedProject) {
				// console.log(
				//   "store.currentSelectedProject: ",
				//   store.currentSelectedProject
				// );
				const currentProjectObject = store.myBoardsProjects.find(
					(p) => p.uuid === store.currentSelectedProject
				);

				if (!currentProjectObject) return;

				//Check if project is open
				if (currentProjectObject.open) {
					//Check if open project has maps
					if (currentProjectObject.maps && currentProjectObject.maps.length > 0) {
						//Get first project map object
						const firstMapObject = currentProjectObject.maps[0];

						//Select first map
						useObjectTableStore.setState({
							currentSelectedMap: firstMapObject.uuid,
						});
					} else {
						//Get current project index
						const currentProjectIndex = store.myBoardsProjects.findIndex(
							(p) => p.uuid === currentProjectObject.uuid
						);

						//Check if next project is available
						if (currentProjectIndex + 1 < store.myBoardsProjects.length) {
							//Get next project uuid
							const nextProjectUuid = store.myBoardsProjects[currentProjectIndex + 1].uuid;

							//Select next project and unselect map
							useObjectTableStore.setState({
								currentSelectedProject: nextProjectUuid,
								currentSelectedMap: "",
							});
						}
					}
				} else {
					//Go to next project
					//Get index of current project
					const currentProjectIndex = store.myBoardsProjects.findIndex(
						(p) => p.uuid === store.currentSelectedProject
					);

					//Check user can go to next project
					if (currentProjectIndex + 1 < store.myBoardsProjects.length) {
						//Get next project uuid
						const nextProjectUuid = store.myBoardsProjects[currentProjectIndex + 1].uuid;

						//Set next project as selected
						useObjectTableStore.setState({
							currentSelectedProject: nextProjectUuid,
						});
					}
				}
			}
			// --------------------------- LOGIC TO CHANGE PROJECT --------------------------- //
			// if no project selected, select first project
			else {
				//No project selected, select first project --> It's here when the user presses the down arrow key it shows the first project in this case
				if (store.myBoardsProjects.length > 0) {
					//Get first project uuid
					const firstProjectUuid = store.myBoardsProjects[0].uuid;
					// console.log(
					//   "currentSelectedProject est normalement vide puis [0] firstproject",
					//   currentSelectedProject
					// );

					//Select first project
					useObjectTableStore.setState({
						currentSelectedProject: firstProjectUuid,
					});
					// console.log(
					//   "currentSelectedProject est normalement populate [0] firstproject",
					//   firstProjectUuid
					// );
				}
			}
		}
		//if the current panle if workspace
		if (store.currentPanel === "workspace") {
			// checking if i'm in the currentpanel workspace + getting the right data
			// console.log("Workspace panel detected:", store.currentPanel);
			// console.log("data", store.workspaces);

			// prevent the function to go down the workspace if currentPanel is not open
			if (
				!workspaceOpenState &&
				!store.currentSelectedProject &&
				!store.currentSelectedMap &&
				!store.currentSelectedOverlay &&
				!store.currentSelectedArea
			) {
				return;
			}
			// --------------------------- Logic to GO DOWN IN THE AREAS --------------------------- //
			if (store.currentSelectedArea) {
				const workspaceWithArea = store.workspaces.find(
					(w) =>
						w.projects &&
						w.projects.some(
							(p) =>
								p.maps &&
								p.maps.some(
									(m) =>
										m.overlays &&
										m.overlays.some(
											(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
										)
								)
						)
				);

				if (!workspaceWithArea) return;

				const projectWithArea = workspaceWithArea.projects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) =>
								m.overlays &&
								m.overlays.some(
									(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
								)
						)
				);

				if (!projectWithArea) return;

				const mapWithArea = projectWithArea.maps.find(
					(m) =>
						m.overlays &&
						m.overlays.some((o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea))
				);

				if (!mapWithArea) return;

				const overlayWithArea = mapWithArea.overlays.find(
					(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
				);

				if (!overlayWithArea) return;

				const areaIndex = overlayWithArea.areas.findIndex((a) => a.uuid === store.currentSelectedArea);

				if (overlayWithArea.areas.length > areaIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: overlayWithArea.areas[areaIndex + 1].uuid,
					});
					return;
				}

				const overlayIndex = mapWithArea.overlays.findIndex((o) => o.uuid === overlayWithArea.uuid);

				if (mapWithArea.overlays.length > overlayIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: mapWithArea.overlays[overlayIndex + 1].uuid,
					});
					return;
				}

				const mapIndex = projectWithArea.maps.findIndex((m) => m.uuid === mapWithArea.uuid);

				if (projectWithArea.maps.length > mapIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: "",
						currentSelectedMap: projectWithArea.maps[mapIndex + 1].uuid,
					});
					return;
				}

				const projectIndex = workspaceWithArea.projects.findIndex((p) => p.uuid === projectWithArea.uuid);

				if (workspaceWithArea.projects.length > projectIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: "",
						currentSelectedMap: "",
						currentSelectedProject: workspaceWithArea.projects[projectIndex + 1].uuid,
					});
					return;
				}

				const workspaceIndex = store.workspaces.findIndex((w) => w.uuid === workspaceWithArea.uuid);

				if (store.workspaces.length > workspaceIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: "",
						currentSelectedMap: "",
						currentSelectedProject: store.workspaces[workspaceIndex + 1].uuid,
					});
				}
				return;
			}

			// --------------------------- Logic to GO DOWN IN THE OVERLAYS --------------------------- //
			// Check if overlay is selected
			if (store.currentSelectedOverlay) {
				// console.log("hello store.currentSelectedOverlay: ", store.currentSelectedOverlay);

				// Find which workspace contains the project that contains the map that contains the overlay
				const workspaceWithOverlay = store.workspaces.find(
					(w) =>
						w.projects &&
						w.projects.some(
							(p) =>
								p.maps &&
								p.maps.some(
									(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
								)
						)
				);

				if (!workspaceWithOverlay) return;

				// Find the project containing the map with the overlay
				const projectWithOverlay = workspaceWithOverlay.projects.find(
					(p) =>
						p.maps &&
						p.maps.some(
							(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
						)
				);

				if (!projectWithOverlay) return;

				// Find the map containing the overlay
				const mapWithOverlay = projectWithOverlay.maps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;

				// Find the selected overlay
				const selectedOverlay = mapWithOverlay.overlays.find((o) => o.uuid === store.currentSelectedOverlay);

				if (!selectedOverlay) return;

				// Get the index of the selected overlay
				const selectedOverlayIndex = mapWithOverlay.overlays.findIndex(
					(o) => o.uuid === store.currentSelectedOverlay
				);

				// Check if the overlay is open and has areas
				if (selectedOverlay.open && selectedOverlay.areas && selectedOverlay.areas.length > 0) {
					// Select the first area in the overlay
					useObjectTableStore.setState({
						currentSelectedArea: selectedOverlay.areas[0].uuid,
					});
					return;
				}

				// If there's a next overlay in the map
				if (mapWithOverlay.overlays.length > selectedOverlayIndex + 1) {
					// Get next overlay uuid
					const nextOverlayUuid = mapWithOverlay.overlays[selectedOverlayIndex + 1].uuid;

					// Select next overlay
					useObjectTableStore.setState({
						currentSelectedOverlay: nextOverlayUuid,
					});
					return;
				}

				// If this is the last overlay, move to the next map in the project
				const mapIndex = projectWithOverlay.maps.findIndex((m) => m.uuid === mapWithOverlay.uuid);

				if (projectWithOverlay.maps.length > mapIndex + 1) {
					// Select the next map and clear overlay selection
					useObjectTableStore.setState({
						currentSelectedMap: projectWithOverlay.maps[mapIndex + 1].uuid,
						currentSelectedOverlay: "",
					});
					return;
				}

				// If this is the last map, move to the next project in the workspace
				const projectIndex = workspaceWithOverlay.projects.findIndex((p) => p.uuid === projectWithOverlay.uuid);

				if (workspaceWithOverlay.projects.length > projectIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedProject: workspaceWithOverlay.projects[projectIndex + 1].uuid,
						currentSelectedMap: "",
						currentSelectedOverlay: "",
					});
					return;
				}

				// If this is the last project, move to the next workspace
				const workspaceIndex = store.workspaces.findIndex((w) => w.uuid === workspaceWithOverlay.uuid);

				if (store.workspaces.length > workspaceIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedProject: store.workspaces[workspaceIndex + 1].uuid,
						currentSelectedMap: "",
						currentSelectedOverlay: "",
					});
				}

				return;
			}

			// --------------------------- Logic to GO DOWN IN THE MAPS --------------------------- //
			// Check if map is selected
			if (store.currentSelectedMap) {
				// console.log(
				//   "hello store.currentSelectedMap: ",
				//   store.currentSelectedMap
				// );

				// Find which workspace contains the project that contains the map
				const workspaceWithMap = store.workspaces.find(
					(w) =>
						w.projects &&
						w.projects.some((p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap))
				);

				if (!workspaceWithMap) return;

				const projectWithMap = workspaceWithMap.projects.find(
					(p) => p.maps && p.maps.some((m) => m.uuid === store.currentSelectedMap)
				);

				if (!projectWithMap) return;

				const selectedMap = projectWithMap.maps.find((m) => m.uuid === store.currentSelectedMap);

				if (!selectedMap) return;

				// Check if the map is open and has overlays
				if (selectedMap.open && selectedMap.overlays && selectedMap.overlays.length > 0) {
					// Select the first overlay
					useObjectTableStore.setState({
						currentSelectedOverlay: selectedMap.overlays[0].uuid,
					});
					return;
				}

				// If map has no overlays or is not open, go to the next map in the project
				const mapIndex = projectWithMap.maps.findIndex((m) => m.uuid === selectedMap.uuid);

				if (projectWithMap.maps.length > mapIndex + 1) {
					// Select the next map
					useObjectTableStore.setState({
						currentSelectedMap: projectWithMap.maps[mapIndex + 1].uuid,
					});
					return;
				}

				// If this is the last map, move to the next project in the workspace
				const projectIndex = workspaceWithMap.projects.findIndex((p) => p.uuid === projectWithMap.uuid);

				if (workspaceWithMap.projects.length > projectIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedProject: workspaceWithMap.projects[projectIndex + 1].uuid,
						currentSelectedMap: "",
					});
					return;
				}

				// If this is the last project, move to the next workspace
				const workspaceIndex = store.workspaces.findIndex((w) => w.uuid === workspaceWithMap.uuid);

				if (store.workspaces.length > workspaceIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedProject: store.workspaces[workspaceIndex + 1].uuid,
						currentSelectedMap: "",
					});
				}

				return;
			}
			// ---------------------------- Logic to GO DOWN IN THE PROJECTS ---------------------------- //
			// i check if the project or workspace is selected
			if (store.currentSelectedProject) {
				// i check if its a workspace by checking the currentselectedprojet to w.uuid
				const selectedWorkspace = store.workspaces.find((w) => w.uuid === store.currentSelectedProject);

				// its indeed a workspace
				if (selectedWorkspace) {
					// i get its state.open
					if (selectedWorkspace.open) {
						// check if it has projects
						if (selectedWorkspace.projects && selectedWorkspace.projects.length > 0) {
							// get 1st project index
							const firstProjectUuid = selectedWorkspace.projects[0].uuid;
							// get to this project
							useObjectTableStore.setState({
								currentSelectedProject: firstProjectUuid,
							});
						} else {
							// in the if i check if it has projects so now no projects so i get the next workspace index
							const workspaceIndex = store.workspaces.findIndex((w) => w.uuid === selectedWorkspace.uuid);
							// check if there are workspaces so i can get to it
							if (store.workspaces.length > workspaceIndex + 1) {
								const nextWorkspaceUuid = store.workspaces[workspaceIndex + 1].uuid;
								useObjectTableStore.setState({
									currentSelectedProject: nextWorkspaceUuid,
								});
							}
						}
					} else {
						// workspace is not open, so i go to the next one
						const workspaceIndex = store.workspaces.findIndex((w) => w.uuid === selectedWorkspace.uuid);

						if (store.workspaces.length > workspaceIndex + 1) {
							const nextWorkspaceUuid = store.workspaces[workspaceIndex + 1].uuid;
							useObjectTableStore.setState({
								currentSelectedProject: nextWorkspaceUuid,
							});
						}
					}
				}
				// it's not a workspace
				else {
					// so its a project :0
					// i get the workspace that contains the selected project
					const workspaceProject = store.workspaces.find(
						(w) => w.projects && w.projects.some((p) => p.uuid === store.currentSelectedProject)
					);
					// if it do not find the workspace, stop there exit the function with a return
					if (!workspaceProject) return;
					// get the project
					const currentProject = workspaceProject.projects.find(
						(p) => p.uuid === store.currentSelectedProject
					);
					// the usual check
					if (currentProject.open) {
						// i check if the project has maps
						if (currentProject.maps && currentProject.maps.length > 0) {
							// set state of the currentSelectedmap with the first map
							useObjectTableStore.setState({
								currentSelectedMap: currentProject.maps[0].uuid,
							});
						} else {
							// the project to do has maps so i get the next project index
							const projectIndex = workspaceProject.projects.findIndex(
								(p) => p.uuid === currentProject.uuid
							);
							// i check there are project if no pass to the else
							if (workspaceProject.projects.length > projectIndex + 1) {
								// i move to the next project
								useObjectTableStore.setState({
									currentSelectedProject: workspaceProject.projects[projectIndex + 1].uuid,
								});
							} else {
								// i move to the next workspace
								const workspaceIndex = store.workspaces.findIndex(
									(w) => w.uuid === workspaceProject.uuid
								);
								// i check is there are workspaces
								if (store.workspaces.length > workspaceIndex + 1) {
									useObjectTableStore.setState({
										currentSelectedProject: store.workspaces[workspaceIndex + 1].uuid,
									});
								}
							}
						}
					} else {
						// the project is not open so i move to the next project
						const projectIndex = workspaceProject.projects.findIndex((p) => p.uuid === currentProject.uuid);

						if (workspaceProject.projects.length > projectIndex + 1) {
							useObjectTableStore.setState({
								currentSelectedProject: workspaceProject.projects[projectIndex + 1].uuid,
							});
						} else {
							// if no project then move to workspace
							const workspaceIndex = store.workspaces.findIndex((w) => w.uuid === workspaceProject.uuid);

							if (store.workspaces.length > workspaceIndex + 1) {
								useObjectTableStore.setState({
									currentSelectedProject: store.workspaces[workspaceIndex + 1].uuid,
								});
							}
						}
					}
				}
			}
			// ----------------------------- Logic to switch workspaces ----------------------------- //
			else {
				if (store.workspaces.length > 0) {
					// i get the first workspace
					const firstWorkspaceUuid = store.workspaces[0].uuid;
					// i select the first workspace
					useObjectTableStore.setState({
						currentSelectedProject: firstWorkspaceUuid,
					});
				}
			}
		}
		// else the current panel is shared
		if (store.currentPanel === "shared") {
			// prevent the function from running if the sharedOpenState is false
			if (
				!sharedOpenState &&
				!store.currentSelectedProject &&
				!store.currentSelectedMap &&
				!store.currentSelectedOverlay &&
				!store.currentSelectedArea
			) {
				return;
			}
			//------------------LOGIC TO GO DOWN IN THE AREAS-----------------//
			if (store.currentSelectedArea) {
				const mapWithArea = store.sharedMaps.find(
					(m) =>
						m.overlays &&
						m.overlays.some((o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea))
				);

				if (!mapWithArea) return;

				const overlayWithArea = mapWithArea.overlays.find(
					(o) => o.areas && o.areas.some((a) => a.uuid === store.currentSelectedArea)
				);

				if (!overlayWithArea) return;

				const areaIndex = overlayWithArea.areas.findIndex((a) => a.uuid === store.currentSelectedArea);

				if (overlayWithArea.areas.length > areaIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: overlayWithArea.areas[areaIndex + 1].uuid,
					});
					return;
				}

				const overlayIndex = mapWithArea.overlays.findIndex((o) => o.uuid === overlayWithArea.uuid);

				if (mapWithArea.overlays.length > overlayIndex + 1) {
					const nextOverlay = mapWithArea.overlays[overlayIndex + 1];
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: nextOverlay.uuid,
					});
					return;
				}

				const mapIndex = store.sharedMaps.findIndex((m) => m.uuid === mapWithArea.uuid);

				if (store.sharedMaps.length > mapIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedArea: "",
						currentSelectedOverlay: "",
						currentSelectedMap: store.sharedMaps[mapIndex + 1].uuid,
					});
				}
				return;
			}
			//---------------------------------LOGIC TO GO DOWN IN THE OVERLAYS---------------------------------//
			// overlay level, the overlays are in the maps to access the overlays i must go through the map
			if (store.currentSelectedOverlay) {
				// i find the map that contains the overlay
				const mapWithOverlay = store.sharedMaps.find(
					(m) => m.overlays && m.overlays.some((o) => o.uuid === store.currentSelectedOverlay)
				);

				if (!mapWithOverlay) return;
				// i get the overlay
				const selectedOverlay = mapWithOverlay.overlays.find((o) => o.uuid === store.currentSelectedOverlay);

				if (!selectedOverlay) return;
				// i get the index of the overlay
				const selectedOverlayIndex = mapWithOverlay.overlays.findIndex(
					(o) => o.uuid === store.currentSelectedOverlay
				);
				// if the overlay is open and that there are areas, then i should select the first area
				if (selectedOverlay.open && selectedOverlay.areas && selectedOverlay.areas.length > 0) {
					useObjectTableStore.setState({
						currentSelectedArea: selectedOverlay.areas[0].uuid,
					});
					return;
				}
				// if not overlay is not open then, i get to the next one by adding + 1 to the index
				if (mapWithOverlay.overlays.length > selectedOverlayIndex + 1) {
					const nextOverlayUuid = mapWithOverlay.overlays[selectedOverlayIndex + 1].uuid;
					// here i select it by updating the state of the currentSelectedOverlay
					useObjectTableStore.setState({
						currentSelectedOverlay: nextOverlayUuid,
					});
					return;
				}
				// if there are no overlays left then i should move to the next map
				const mapIndex = store.sharedMaps.findIndex((m) => m.uuid === mapWithOverlay.uuid);

				// so i get the mapIndex and add + 1 to it to get the next map
				if (store.sharedMaps.length > mapIndex + 1) {
					useObjectTableStore.setState({
						currentSelectedMap: store.sharedMaps[mapIndex + 1].uuid,
						currentSelectedOverlay: "",
					});
				}
				return;
			}
			// -------------------------------------- LOGIC TO GO DOWN IN THE MAPS -------------------------------------- //
			if (store.currentSelectedMap) {
				// const select map the map index

				const selectedMap = store.sharedMaps.find((m) => m.uuid === store.currentSelectedMap);

				if (!selectedMap) return;

				if (selectedMap.open && selectedMap.overlays && selectedMap.overlays.length > 0) {
					useObjectTableStore.setState({
						currentSelectedOverlay: selectedMap.overlays[0].uuid,
					});
					return;
				}

				const currentMapIndex = store.sharedMaps.findIndex((m) => m.uuid === store.currentSelectedMap);

				// i check if there are more maps
				if (store.sharedMaps.length > currentMapIndex + 1) {
					// get the next map uuid
					const nextMapUuid = store.sharedMaps[currentMapIndex + 1].uuid;

					useObjectTableStore.setState({
						currentSelectedMap: nextMapUuid,
					});
				}
				return;
			} else {
				if (store.sharedMaps && store.sharedMaps.length > 0) {
					const firstMapUuid = store.sharedMaps[0].uuid;

					useObjectTableStore.setState({
						currentSelectedMap: firstMapUuid,
					});
				}
			}
		}
	};

	//------------------------------------------------------------- //
	// ----------------- END KEYBOARD NAVIGATION ----------------- //
	//------------------------------------------------------------- //

	const [tableSearch, setTableSearch] = useState("");
	const [tableSearchResult, setTableSearchResult] = useState([]);

	const handleTableSearch = (event) => {
		const search = event.currentTarget.value;

		if (search.length <= 0) {
			setTableSearch("");
			setTableSearchResult([]);

			return;
		}

		setTableSearch(search);

		UserSearchAPI.userSearchOnObjectTable(search).then((response) => {
			setTableSearchResult(response.data);
		});
	};

	// ------------------------------------------------------------- //
	// Only for the myboards panel, not working for the others panels
	const handleFocusSearchResult = (res) => {
		useObjectTableStore.setState({ currentPanel: "myboards" });
		useObjectTableStore.getState().setMyBoardsOpenState(true);
		if (res["project_uuid"]) {
			useObjectTableStore.setState({
				currentSelectedProject: res["project_uuid"],
			});
			useObjectTableStore.getState().updateMyBoardsProjectOpenState(res["project_uuid"], true);
		}

		if (res["map_uuid"]) {
			useObjectTableStore.setState({
				currentSelectedMap: res["map_uuid"],
			});
			useObjectTableStore.getState().updateMyBoardsProjectOpenState(res["project_uuid"], true);
			setTimeout(() => {
				useObjectTableStore.getState().updateMyBoardsMapOpenState(res["project_uuid"], res["map_uuid"], true);
			}, 300);
		}
	};

	return (
		<>
			<div className="custom__object-table-container" id="object-table-container" ref={objectTableContainerRef}>
				<div className="global-close-btn" onClick={() => useObjectTableStore.getState().resetStore()}>
					<VscChromeClose />
				</div>
				<div className="custom__item-list-object-sidebar">{sidebarItemState && <ObjectSidebar />}</div>
				<div className="sidebar" onKeyDown={() => console.log("key")}>
					<h1 className="title">Data structure</h1>
					<div className="sidebar-search-container">
						<input
							type="text"
							value={tableSearch}
							onChange={handleTableSearch}
							className="search-input"
							placeholder="Search"
						/>
						{tableSearchResult.length > 0 && (
							<div className="sidebar-search-result">
								{tableSearchResult.map((r) => (
									<div className="result" onClick={() => handleFocusSearchResult(r)}>
										{r.data_type === "project" && (
											<div className="icon">
												<BiSolidFolder size={14} />
											</div>
										)}
										{r.data_type === "text" && (
											<div className="icon">
												<MdOutlineTextFields />
											</div>
										)}
										{r.data_type === "map" && (
											<div className="icon">
												<MyBoardsIcon />
											</div>
										)}
										{r.data_type === "overlay" && (
											<div className="icon">
												<PiStackBold fontSize="1.8em" />
											</div>
										)}
										{r.data_type === "zone" && (
											<div className="icon">
												<MdOutlineCropSquare />
											</div>
										)}
										{r.data_type === "shape" && (
											<div className="icon">
												<BiShapeSquare />
											</div>
										)}
										{r.data_type === "content_item" && (
											<img
												src={
													r.icon_code !== undefined
														? "/static/map/svg/" + r.icon_code.toLowerCase() + ".svg"
														: ""
												}
												alt="Item icon"
											/>
										)}
										<div className="text">{r.name || r.text}</div>
									</div>
								))}
							</div>
						)}
					</div>
					<div className="structure-container">
						<div
							className={
								"open-line " +
								(currentPanel === "myboards" &&
								currentPanelType === "project-list" &&
								currentSelectedProject === "" &&
								currentSelectedMap === ""
									? "active"
									: "")
							}
							onClick={() => handleOpenProjectPanel("myboards")}
						>
							<div
								className="open-icon"
								onClick={(event) => {
									event.stopPropagation();
									useObjectTableStore.getState().setMyBoardsOpenState(!myBoardsOpenState);
								}}
							>
								{myBoardsOpenState ? <HiChevronDown size={20} /> : <HiChevronRight size={20} />}
							</div>
							<div className="line-icon">
								<MyBoardsIcon width={20} height={20} />
							</div>
							<p className="line-text">My boards</p>
						</div>
						{/*MY BOARD PROJECT LIST*/}
						{myBoardsOpenState && <MyBoardSidebar />}
						{/* ---------------------- */}
						{/* WORKSPACES RENDER */}
						<div
							className={
								"open-line " +
								(currentPanel === "workspace" && currentPanelType === "project-list" ? "active" : "")
							}
							onClick={() => handleOpenProjectPanel("workspace")}
						>
							<div
								className="open-icon"
								onClick={(event) => {
									event.stopPropagation();
									useObjectTableStore.getState().setWorkspaceOpenState(!workspaceOpenState);
								}}
							>
								{workspaceOpenState ? <HiChevronDown size={20} /> : <HiChevronRight size={20} />}
							</div>
							<div className="line-icon">
								<WorkspaceIcon width={20} />
							</div>
							<p className="line-text">Team workspace</p>
						</div>
						{/*WORKSPACES LIST*/}
						{workspaceOpenState && (
							// WorkSpace component
							<WorkspaceSidebar />
						)}
						<div
							className={
								"open-line " +
								(currentPanel === "shared" && currentPanelType === "project-list" ? "active" : "")
							}
							onClick={() => handleOpenProjectPanel("shared")}
						>
							<div
								className="open-icon"
								onClick={(event) => {
									event.stopPropagation();
									useObjectTableStore.getState().setSharedOpenState(!sharedOpenState);
								}}
							>
								{sharedOpenState ? <HiChevronDown size={20} /> : <HiChevronRight size={20} />}
							</div>
							<div className="line-icon">
								<PiUsersBold size={20} />
							</div>
							<p className="line-text">Shared with me</p>
						</div>
						{/*SHARED MAP LIST*/}
						{sharedOpenState && (
							// My SharedSideBar component
							<SharedSidebar />
						)}
					</div>
				</div>
				<div className="content">
					<h1 className="title">
						Library{handleGetCurrentProjectName()}
						{handleGetCurrentMapName()}
						{handleGetCurrentOverlayName()}
						{handleGetCurrentAreaName()}
					</h1>
					<div className="view-panel-selector">
						<div
							onClick={() =>
								useObjectTableStore.setState({
									currentPanelView: "default",
								})
							}
							className={"panel " + (currentPanelView === "default" ? "active" : "")}
						>
							By default
						</div>
						<div
							onClick={() =>
								useObjectTableStore.setState({
									currentPanelView: "gallery",
								})
							}
							className={"panel " + (currentPanelView === "gallery" ? "active" : "")}
						>
							Gallery
						</div>
						<div
							onClick={() =>
								useObjectTableStore.setState({
									currentPanelView: "bibtex",
								})
							}
							className={"panel " + (currentPanelView === "bibtex" ? "active" : "")}
						>
							Bibtex
						</div>
					</div>
					<div className="library-content-container">
						{currentSelectedMap === "" && (
							<>
								{currentPanel !== "myboards" && (
									<>
										<ObjectTableItemList />
									</>
								)}
								{currentPanel === "myboards" && (
									<>
										{currentSelectedProject === "" && <ObjectTableProjectList />}
										{currentSelectedProject !== "" && <ObjectTableMapList />}
									</>
								)}
							</>
						)}
						{currentSelectedMap !== "" && (
							<>
								<ObjectTableItemList />
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ObjectTableContainer;
