import {create} from "zustand";

export const useSubscribePopupStore = create((set) => ({
	state: false,
	paymentType: "subscription",
	view: "plans",
	resetStore: () => set((state) => ({
		state: false,
		paymentType: "subscription",
		view: "plans"
	}))
}))