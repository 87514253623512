import React from "react";

const ContextMenuItem = ({text, icon, handler, closeAfter = true, children}) => {
	return (
		<div className="custom__content-menu-item" onClick={(event) => {
			if (!closeAfter){
				event.stopPropagation()
				event.preventDefault()
			}
			handler()
		}}>
			<p className="icon">{icon}</p>
			<p className="text">{text}</p>
			{children}
		</div>
	)
}

export default ContextMenuItem