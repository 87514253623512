import {SECURITY_CTRL} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";
import {Cookies} from "react-cookie";
import {useMercureStore} from "./Stores";
import AuthAPI from "./AuthAPI";

function register(userInfo){
    toast.loading("Register in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append("firstname", userInfo.firstname)
    sendData.append("lastname", userInfo.lastname)
    sendData.append("email", userInfo.email)
    sendData.append("password", userInfo.password)
    sendData.append("newsletter_status", userInfo.newsletter)
    sendData.append("terms_status", userInfo.terms)

    return getAxiosInstance().post(SECURITY_CTRL + "/register", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    window.localStorage.setItem("authToken", response.data.token)
                    AuthAPI.setAxiosToken(response.data.token)
                    const mercureToken = response.data.mercureToken
                    const mercureCookie = new Cookies()
                    mercureCookie.set('mercureAuthorization', mercureToken, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})
                    useMercureStore.setState({mercureToken: mercureToken})
                    AuthAPI.openMercureEventSource(mercureToken)

                    const authCookie = new Cookies()
                    if (process.env.REACT_APP_ENV_TYPE === "prod"){
                        authCookie.set('authCookie', response.data.token, {path: "/", maxAge: Number.MAX_SAFE_INTEGER, domain: "weeki.io"})
                    } else {
                        authCookie.set('authCookie', response.data.token, {path: "/", maxAge: Number.MAX_SAFE_INTEGER, domain: "localhost"})
                    }

                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    return false
                }
            }
            toast.error("An error occurred please try again later")
            return false
        })
}

function confirmAccount(token){
    const sendData = new FormData()
    sendData.set("token", token)

    return getAxiosInstance().post(SECURITY_CTRL + "/confirm_account", sendData)
        .then((response) => {
            return response.status === 200
        })
        .catch((error) => {
            return false
        })
}

export default {
    register,
    confirmAccount
}