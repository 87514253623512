import React, {useRef} from "react";
import {createPortal} from "react-dom";
import {VscChromeClose} from "react-icons/vsc";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {createErrorToast} from "../../../services/CustomToastUtils";
import UserMapAPI from "../../../services/API/User/UserMapAPI";
import MapAPI from "../../../services/MapAPI";
import {useHistory} from "react-router-dom";
import UserProjectsAPI from "../../../services/API/User/UserProjectsAPI";
import ProjectsAPI from "../../../services/ProjectsAPI";

const RenameProjectPopup = ({state, setState}) => {
	//Get data in store
	const currentContextMenuProject = useDashboardStore(state => state.currentContextMenuProject)

	const projectNameInputRef = useRef(null)

	const handleRenameProject = async () => {
		setState(false)
		const newName = projectNameInputRef.current.value

		if (newName.length <= 0) {
			createErrorToast("Project name can't be empty")
			return
		}

		if (await ProjectsAPI.renameProject(currentContextMenuProject.uuid, newName)) {
			useDashboardStore.getState().renameProject(currentContextMenuProject.uuid, newName)
		}
	}

	if (state) {
		return createPortal(
			<div className="custom__dashboard-popup-overlay">
				<div className="container">
					<div className="popup-header">
						<h1 className="popup-title">Rename your project</h1>
						<p className="popup-close-icon" onClick={() => setState(false)}><VscChromeClose/></p>
					</div>
					<div className="popup-content">
						<div className="input-container">
							<input ref={projectNameInputRef} defaultValue={currentContextMenuProject.name} type="text" placeholder="Enter project name" autoFocus={true} onKeyDown={(e) => e.key === "Enter" ? handleRenameProject() : null}/>
						</div>
					</div>
					<div className="popup-footer">
						<p className="popup-btn" onClick={() => setState(false)}>Cancel</p>
						<p className="popup-btn valid" onClick={handleRenameProject}>Valid</p>
					</div>
				</div>
			</div>
			, document.body)
	}

	return null
}

export default RenameProjectPopup