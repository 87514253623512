import getAxiosInstance from "../../../AxiosInstance";
import {USER_MOOC_QUIZ_QUESTION_API} from "../../../../../Router";

function userGetAllQuizQuestions(quizUuid){
	return getAxiosInstance().get(USER_MOOC_QUIZ_QUESTION_API + '/get_all_quiz_questions', {params: {quizUuid: quizUuid}})
}

function userCreateMoocQuizQuestions(quizUuid, questions){
	const sendData = new FormData()
	sendData.append('quizUuid', quizUuid)
	sendData.append('questions', JSON.stringify(questions))

	return getAxiosInstance().post(USER_MOOC_QUIZ_QUESTION_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.questions
			}

			return false
		})
		.catch(() => {
			return false
		})
}

function userUpdateMoocQuizQuestions(quizUuid, questions){
	const sendData = new FormData()
	sendData.append('quizUuid', quizUuid)
	sendData.append('questions', JSON.stringify(questions))

	return getAxiosInstance().post(USER_MOOC_QUIZ_QUESTION_API + '/update', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userDeleteQuizQuestion(questionUuid){

	return getAxiosInstance().delete(USER_MOOC_QUIZ_QUESTION_API + '/delete', {params: {questionUuid: questionUuid}})
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

export default {
	userGetAllQuizQuestions,
	userCreateMoocQuizQuestions,
	userUpdateMoocQuizQuestions,
	userDeleteQuizQuestion
}