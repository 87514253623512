import {create} from "zustand";

export const useToastStore = create((set) => ({
    toastList: [
        // {type: "error", message: "Mon super message d'erreur", 'id': (Math.random() * Date.now() * Math.random()).toFixed(0), cancellable: false},
        // {type: "info", message: "Mon super message d'infos", 'id': (Math.random() * Date.now() * Math.random()).toFixed(0), cancellable: false},
        // {type: "success", message: "Mon super message de succès", 'id': (Math.random() * Date.now() * Math.random()).toFixed(0), cancellable: false},
        // {type: "loading", message: "Mon super message de chargement", 'id': (Math.random() * Date.now() * Math.random()).toFixed(0), cancellable: false},
        // {type: "success", message: "Mon super message annulable", 'id': (Math.random() * Date.now() * Math.random()).toFixed(0), cancellable: true},
    ],
    removeToast: (toastId) => set((state) => ({
        toastList: state.toastList.filter(t => t.id !== toastId)
    })),
    addToast: (toastId, type, message, cancellable, callback) => set((state) => ({
        toastList: [...state.toastList, {id: toastId, type: type, message: message, cancellable: cancellable, callback: callback}]
    }))
}))