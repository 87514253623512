import React, {useContext, useEffect, useRef, useState} from "react";
import SupportPopupContext from "../contexts/SupportPopupContext";
import {VscChromeClose} from "react-icons/vsc";
import SupportTicketsAPI from "../services/SupportTicketsAPI";
import {toast} from "react-toastify";

const SupportPopup = () => {
    //Get popup context
    const {supportPopupStatus, setSupportPopupStatus} = useContext(SupportPopupContext)

    //Define default status for popup view
    const [popupView, setPopupView] = useState('list')

    //Define object for support ticket list
    const [tickets, setTickets] = useState([{
        'uuid': "",
        'title': "",
        'openDate': "",
        'closeDate': null,
        'open': false,
        'lastMessageSenderType': "",
        'lastMessageDate': ""
    }])

    //Get all tickets
    useEffect(() => {
        if (supportPopupStatus){
            SupportTicketsAPI.getAllTickets().then((response) => {
                if (response.status === 200){
                    setTickets(response.data)
                } else {
                    setTickets([])
                }
            })
        }
    }, [supportPopupStatus])

    //Define value for new ticket
    const [newTicketTitle, setNewTicketTitle] = useState("")
    const [newTicketMessage, setNewTicketMessage] = useState("")

    const openTicket = async () => {
        //Check if new ticket title is empty
        if (newTicketTitle === ""){
            toast.error("Ticket title is empty")
            return
        }
        //Check if new ticket message is empty
        if (newTicketMessage === ""){
            toast.error("Ticket message is empty")
            return
        }

        if (await SupportTicketsAPI.openSupportTicket(newTicketTitle, newTicketMessage)){
            toast.success("Your support request has transmitted to Weeki support team")
            setNewTicketTitle("")
            setNewTicketMessage("")
            setPopupView('list')

            SupportTicketsAPI.getAllTickets().then((response) => {
                if (response.status === 200){
                    setTickets(response.data)
                } else {
                    setTickets([])
                }
            })
        }
    }

    const [newResponseMessage, setNewResponseMessage] = useState("")
    const [openedTicket, setOpenedTicket] = useState({
        'uuid': "",
        'title': "",
        'openDate': "",
        'closeDate': null,
        'open': false,
        'messages': []
    })

    const openTicketConversation = (event) => {
        const ticketUuid = event.currentTarget.id

        if (ticketUuid !== ""){
            SupportTicketsAPI.getTicketByUuid(ticketUuid).then((response) => setOpenedTicket(response.data))
            setPopupView('response')
        }
    }

    const closeTicketConversation = () => {
        setOpenedTicket({
            'uuid': "",
            'title': "",
            'openDate': "",
            'closeDate': null,
            'open': false,
            'messages': []
        })
        SupportTicketsAPI.getAllTickets().then((response) => {
            if (response.status === 200){
                setTickets(response.data)
            } else {
                setTickets([])
            }
        })

        setPopupView('list')
    }

    const sendResponseToTicket = async (event) => {
        const ticketUuid = event.currentTarget.id

        if (await SupportTicketsAPI.sendTicketResponse(ticketUuid, newResponseMessage)){
            toast.success('Ticket response successfully send')
            SupportTicketsAPI.getTicketByUuid(ticketUuid).then((response) => setOpenedTicket(response.data))
            setNewResponseMessage("")
        }
    }

    const bottomMessagesRef = useRef(null)

    useEffect(() => {
        bottomMessagesRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [openedTicket])


    if (supportPopupStatus){
        return (
            <div className="modal-overlay">
                <div className="modal large">
                    <div className="header">
                        {popupView === "list" && (<h1>Your support tickets</h1>)}
                        {popupView === "new" && (<h1>Open new support ticket</h1>)}
                        {popupView === "response" && (<h1>Ticket : {openedTicket.title}</h1>)}
                        <p onClick={() => {
                            setPopupView('list')
                            setSupportPopupStatus(false)
                        }}><VscChromeClose /></p>
                    </div>
                    <div className={popupView === "response" ? "content support-ticket" : "content"}>
                        {popupView === 'response' && (
                            <>
                                <div className="message-list-container">
                                    {openedTicket.messages.length > 0 && (
                                        <>
                                            {openedTicket.messages.map((message, index) => (
                                                <div key={index} className={message.senderType === "admin" ? "message-container" : "message-container client"}>
                                                    <p className="message">{message.message}</p>
                                                    <p className="message-date">{message.senderName} - {message.date}</p>
                                                </div>
                                            ))}
                                            <div ref={bottomMessagesRef}> </div>
                                        </>
                                    )}
                                </div>
                                {openedTicket.open && (
                                    <div className="ticket-send-response-container">
                                        <div className="text-area-container" style={{marginTop: '1rem', padding: '0rem 1.5rem 0rem 1.5rem'}}>
                                            <div className="label-container">
                                                <p className="label">Your response</p>
                                                <p className="word-counter">{newResponseMessage.length + 1}/500</p>
                                            </div>
                                            <textarea className={newResponseMessage.length > 0 ? "active" : null} name="newTicketMessage" id="newTicketMessage" rows="3" value={newResponseMessage} onChange={(event) => event.currentTarget.value.length < 500 ? setNewResponseMessage(event.currentTarget.value) : null}/>
                                        </div>
                                    </div>
                                )}
                                {openedTicket.open && (
                                    <div className="footer">
                                        <button className="btn-black" style={{background: "#ededed", color: '#616161', border:'1px solid #dee2ec', fontWeight:'600'}} id={openedTicket.uuid} onClick={closeTicketConversation}>Back</button>
                                        <button className="btn-black" id={openedTicket.uuid} onClick={sendResponseToTicket}>Send ticket response</button>
                                    </div>
                                )}
                            </>
                        )}
                        {popupView === 'new' && (
                            <>

                                <div className="text-input-container" style={{marginTop: "1.5rem", padding: '0rem 1.5rem 0rem 1.5rem'}}>
                                    <label htmlFor="">Title</label>
                                    <input type="text" value={newTicketTitle} onChange={(event) => setNewTicketTitle(event.currentTarget.value)}/>
                                </div>
                                <div className="text-area-container" style={{marginTop: "1.5rem", padding: '0rem 1.5rem 0rem 1.5rem'}}>
                                    <div className="label-container">
                                        <p className="label">Your message</p>
                                        <p className="word-counter">{newTicketMessage.length + 1}/500</p>
                                    </div>
                                    <textarea className={newTicketMessage.length > 0 ? "active" : null} name="newTicketMessage" id="newTicketMessage" rows="10" value={newTicketMessage} onChange={(event) => event.currentTarget.value.length < 500 ? setNewTicketMessage(event.currentTarget.value) : null}/>
                                </div>
                            </>
                        )}
                        {popupView === "list" && (
                            <>
                                <div className="dashboard-table" style={{border: 'none'}}>
                                    <div className="top-container">
                                        <div className="right">
                                            <div className="btn" onClick={() => setPopupView('new')}>
                                                <p className="text">New ticket</p>
                                            </div>
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <td>Title</td>
                                            <td>Status</td>
                                            <td>Request date</td>
                                            <td>Closed date</td>
                                            <td>Last response</td>
                                            <td>Last response date</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tickets.length > 0 && (
                                            <>
                                                {tickets.map((ticket, index) => (
                                                    <tr className="clickable" key={index} id={ticket.uuid} onClick={ticket.open ? openTicketConversation : null}>
                                                        <td>{ticket.title}</td>
                                                        <td>
                                                            <span className={`badge ${ticket.open ? "badge-open" : "badge-close"}`}>
                                                                {ticket.open ? "Open" : "Close"}
                                                            </span>
                                                        </td>
                                                        <td>{ticket.openDate}</td>
                                                        <td>{ticket.closeDate ? ticket.closeDate : "N/A"}</td>
                                                        <td>{ticket.lastMessageSenderType === "user" ? "You" : "Weeki support team"}</td>
                                                        <td>{ticket.lastMessageDate}</td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                        {tickets.length <= 0 && (
                                            <tr>
                                                <td colSpan="100%" style={{"textAlign": 'center'}}>No support ticket</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                    {popupView === "new" && (
                        <div className="footer">
                                <button className="btn-black mb" style={{background: "#ededed", color: '#616161', border:'1px solid #dee2ec', fontWeight:'600'}} onClick={() => {
                                    setNewTicketMessage("")
                                    setNewTicketTitle("")
                                    setPopupView('list')
                                }}>Back</button>
                            <p> </p>
                            <button className="btn-black" onClick={openTicket}>Open ticket</button>
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default SupportPopup