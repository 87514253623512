import {Cookies} from "react-cookie";
import {useTutorialStore} from "../Stores/TutorialStore";

export function checkMapTutorial(){
	//Get tuto cookie
	let cookie = new Cookies().get('tutorials')

	if (cookie){
		if (!cookie.map){
			//Open map tutorial
			useTutorialStore.setState({mapTutoState: true})
		}
	} else {
		//Generate tutorial cookie with map state at false
		cookie = new Cookies()
		cookie.set('tutorials', {map: false}, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})

		//Open map tutorial
		useTutorialStore.setState({mapTutoState: true})
	}
}

export function endMapTuto(){
	//Get tuto cookie
	let cookie = new Cookies().get('tutorials')

	const newCookie = {...cookie}
	newCookie.map = true

	new Cookies().set('tutorials', newCookie, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})

	//Close tutorial
	useTutorialStore.setState({mapTutoState: false})
}

export function restartMapTutorial(){
	//Get tuto cookie
	let cookie = new Cookies().get('tutorials')

	const newCookie = {...cookie}
	newCookie.map = false

	new Cookies().set('tutorials', newCookie, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})

	//Open tutorial
	useTutorialStore.setState({mapTutoState: true})
}