import React, {useRef} from "react";
import ContextMenuContainer from "../../customs/content_menu/ContextMenuContainer";
import {useMapContextMenuStore} from "../../../services/Stores/MapContextMenuStore";
import ContextMenuItem from "../../customs/content_menu/ContextMenuItem";
import {MdDelete, MdEdit, MdOutlineFullscreen} from "react-icons/md";
import {deleteContentItem, updateContentItemZoomLevel} from "../../../services/Utils/ItemUtils";
import {BsFullscreen} from "react-icons/bs";
import {useSidebarMapContentItemStore} from "../../../services/Stores/SidebarMapContentItemStore";
import {useContentItemStore} from "../../../services/Stores/ContentItemStore";
import {IoMdAdd, IoMdCopy, IoMdCut, IoMdRemove} from "react-icons/io";
import {useMapZoneStore} from "../../../services/Stores/MapZoneStore";
import {useMapStore} from "../../../services/Stores";

const ContentItemContextMenu = () => {
	const menuRef = useRef(null)

	return (
		<>
			<ContextMenuContainer ref={menuRef} state={useMapContextMenuStore.getState().state} setState={() => null} map={true}>
				<ContextMenuItem text="Delete" icon={<MdDelete />} handler={() => deleteContentItem(useMapContextMenuStore.getState().feature.properties)} />
				{useMapContextMenuStore.getState().feature.properties.fullscreen && (
					<ContextMenuItem text="Open fullscreen" icon={<BsFullscreen />} handler={() => {
						//Open fullscreen
						useContentItemStore.setState({currentFullscreenItemUuid: useMapContextMenuStore.getState().feature.properties.id})

						//Close sidebar and context menu
						useSidebarMapContentItemStore.getState().resetStore()
						useMapContextMenuStore.getState().resetStore()
					}} />
				)}
				<ContextMenuItem text="Copy" icon={<IoMdCopy />} handler={() => {
					useContentItemStore.setState({currentCopiedItem: useMapContextMenuStore.getState().feature.properties, copyOriginZoom: useMapStore.getState().mapInstance.getZoom(), copyType: 'copy'})
					useMapContextMenuStore.getState().resetStore()
				}} />
				{/*<ContextMenuItem text="Cut" icon={<IoMdCut />} handler={() => {*/}
				{/*	useContentItemStore.setState({currentCopiedItem: useMapContextMenuStore.getState().feature.properties, copyOriginZoom: useMapStore.getState().mapInstance.getZoom(), copyType: 'cut'})*/}
				{/*	useMapContextMenuStore.getState().resetStore()*/}
				{/*}} />*/}
				<ContextMenuItem text="Bring to front" icon={<IoMdAdd />} handler={() => updateContentItemZoomLevel(useMapContextMenuStore.getState().feature.properties.id, -1)} />
				<ContextMenuItem text="Bring to back" icon={<IoMdRemove />} handler={() =>  updateContentItemZoomLevel(useMapContextMenuStore.getState().feature.properties.id, 1)} />
			</ContextMenuContainer>
		</>
	)
}

export default ContentItemContextMenu