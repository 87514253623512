import {ADMIN_MAILER_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function sendGroupMailTemplate(templateId, emailList){
    const sendData = new FormData()
    sendData.set("templateId", templateId)
    sendData.set("emailList", emailList)
    return getAxiosInstance().post(ADMIN_MAILER_API + "/send_template_group", sendData)
}

function sendIndividualEmailTemplate(emailList){
    const sendData = new FormData()
    sendData.set("emailList", emailList)
    return getAxiosInstance().post(ADMIN_MAILER_API + "/send_template_individual", sendData)
}

export default {
    sendGroupMailTemplate,
    sendIndividualEmailTemplate
}