import React, {useContext, useEffect} from "react";
import {Redirect, useHistory} from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import isAdminContext from "../contexts/IsAdminContext";
import AuthAPI from "../services/AuthAPI";
import {TailSpin} from "react-loader-spinner";
import {createErrorToast} from "../services/CustomToastUtils";
import {Cookies} from "react-cookie";
import {useMercureStore} from "../services/Stores";

const OauthAppleSuccess = () => {
    const history = useHistory()
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
    const {isAdmin, setIsAdmin} = useContext(isAdminContext)

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const token = urlParams.get("token")
    const mercureToken = urlParams.get("mercureToken")

    console.log(token, mercureToken)

    useEffect(() => {
        if (token && mercureToken){
            window.localStorage.setItem("authToken", token)
            AuthAPI.setAxiosToken(token)
            const mercureCookie = new Cookies()
            mercureCookie.set('mercureAuthorization', mercureToken, {path: "/", maxAge: Number.MAX_SAFE_INTEGER})
            useMercureStore.setState({mercureToken: mercureToken})
            AuthAPI.openMercureEventSource(mercureToken)

            setIsAuthenticated(true)
            history.push("/user/dashboard/maps")

        } else {
            createErrorToast("Authentication failed")
            history.push("/")
        }
    }, [])

    if (isAuthenticated){
        return (
            <Redirect to="/user/dashboard/maps" />
        )
    }

    return (
        <div className="full-page-loader-container">
            <TailSpin height="128" width="128" color="#000"/>
        </div>
    )
}

export default OauthAppleSuccess