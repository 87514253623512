import React from "react";
import {useObjectTableStore} from "../../../../services/Stores/ObjectTableStore";
import {HiChevronDown, HiChevronRight} from "react-icons/hi";
import {ReactComponent as MyBoardsIcon} from "../../../../../icons/sidebar/my-boards.svg"
import {MdOutlineCropSquare} from "react-icons/md";

const AreaLine = ({p = null, m, o, a}) => {
	const currentSelectedArea = useObjectTableStore(state => state.currentSelectedArea)

	return (
		<>
			<div
				className={"open-line " + (currentSelectedArea === a.uuid ? 'active' : "")}
				onClick={(event) => {
					event.stopPropagation()
					useObjectTableStore.setState({currentSelectedArea: a.uuid, currentSelectedOverlay: o.uuid, currentSelectedMap: m.uuid, currentSelectedProject: p !== null ? p.uuid : ''})
				}}>
				<div className="open-icon">{a.open ?
					<HiChevronDown size={20}/> :
					<HiChevronRight size={20}/>}</div>
				<div className="line-icon"><MdOutlineCropSquare /></div>
				<p className="line-text">{a.name}</p>
			</div>
		</>
	)
}

export default AreaLine