import React from "react";
import {useMapStore} from "../Stores";
import {useContentItemStore} from "../Stores/ContentItemStore";
import {updateLevel2Content} from "./RenderLevel2Utils";
import {generateLevel2Popup, generateSelectedPopup, updateRenderedTextItemPopup} from "./MapPopupUtils";
import L from "leaflet";
import AuthAPI from "../AuthAPI";
import {addLevel2PopupEvent} from "./PopupEventsUtils";
import RefreshTilesUtils from "../RefreshTilesUtils";
import {useTextItemStore} from "../Stores/TextItemStore";
import {updateTextItemPopupContent} from "./RenderTextItemsUtils";
import {addItemSelectedMenuEvents} from "./MapSelectedMenusEventsUtils";
import {generateContentItemSelectedMenu, generateTextItemSelectedMenu} from "./MapSelectedMenusUtils";
import {useMoocStore} from "../Stores/MoocStore";
import {linkItemToMoocCourse} from "./MoocUtils";
import {useMapContextMenuStore} from "../Stores/MapContextMenuStore";
import UserContentItemAPI from "../API/User/UserContentItemAPI";
import ContentItemAPI from "../ContentItemAPI";
import {globalDeleteMapContentItem} from "./GlobalMapContentItemUpdateUtils";
import {useSidebarMapContentItemStore} from "../Stores/SidebarMapContentItemStore";
import {generateRandomId} from "../Utils";

export function getOnlineVideoId(url, setVideoId, setVideoType){
	let videoId

	const host = url.host.replaceAll("www.", "")

	//Check if url is YouTube video
	if (host === "youtube.com" || host === "youtu.be"){
		videoId = url.searchParams.get('v')

		if (!videoId){
			videoId = url.pathname.replace("/", "")
		}

		setVideoId(videoId)
		setVideoType('youtube')

		return videoId
	}
}

/**
 * Select content item on map
 */
export function selectMapContentItem(properties){
	//Check if map is public
	if (useMapStore.getState().publicMap){
		return
	}

	if (useMoocStore.getState().setPositionState){
		linkItemToMoocCourse(properties.id)
		return;
	}

	//Check if current item is already selected
	if (useContentItemStore.getState().currentSelectedItem !== null && useContentItemStore.getState().currentSelectedItem.id === properties.id){
		return;
	}

	//Check if item is already select
	if (useContentItemStore.getState().currentSelectedItem !== null){
		useContentItemStore.getState().updateRenderedLevel2SelectedState(useContentItemStore.getState().currentSelectedItem.id, false)
		updateLevel2Content(useContentItemStore.getState().currentSelectedItem.id)
	}

	//Set selected item uuid in content item store
	useContentItemStore.setState({currentSelectedItem: properties})

	//Check if item is rendered as level2 and if is rendered set selected value to true
	if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === properties.id)[0]){
		useContentItemStore.getState().updateRenderedLevel2SelectedState(properties.id, true)
		updateLevel2Content(properties.id)
	}

	//Get selected item
	const item = useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === properties.id)[0]

	//If item is latex update render
	if (item.properties.type_code === "LATEX"){
		useContentItemStore.setState({updateLatexRender: generateRandomId()})
	}

	//Add selected menu popup
	generateContentItemSelectedMenu(item.popup, item.properties)

	//Bring popup to front
	item.popup.bringToFront()

	//Get items layer in map store
	const itemsLayer = useMapStore.getState().leafletLayer.filter(l => l.name === "items_layer")[0].layer

	//Set selected item in leaflet layer
	if (itemsLayer.scene.config !== undefined && itemsLayer.scene.config !== null){
		itemsLayer.scene.config.global.selectedItem = properties.id
		itemsLayer.scene.updateConfig()
	}
}

export function selectMapTextItem(uuid){
	//Check if map is public
	if (useMapStore.getState().publicMap){
		return
	}

	//Check if item is already selected
	if (useTextItemStore.getState().currentSelectedItem !== null){
		const oldSelectedTextItemUuid = useTextItemStore.getState().currentSelectedItem

		useTextItemStore.setState({currentSelectedItem: null})

		//Update old selected text item popup render
		updateRenderedTextItemPopup(oldSelectedTextItemUuid)
	}

	//Set selected item uuid in content item store
	useTextItemStore.setState({currentSelectedItem: uuid})

	//Update text item popup
	updateRenderedTextItemPopup(uuid)

	//Get selected item
	const item = useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === uuid)[0]

	//Render selected popup
	generateTextItemSelectedMenu(item.popup, item.properties)

}

export function unselectMapTextItem(){
	//Check if map is public
	if (useMapStore.getState().publicMap){
		return
	}

	//Check if current selected item exist
	if (useTextItemStore.getState().currentSelectedItem !== null){
		//Get current selected item
		const currentSelectedItem = useTextItemStore.getState().currentSelectedItem

		//Remove selected item in text item store
		useTextItemStore.setState({currentSelectedItem: null})

		//Update text item render
		updateRenderedTextItemPopup(currentSelectedItem)
	}

	//Remove selected popup if exist
	if (useTextItemStore.getState().selectedItemPopup !== null){
		useTextItemStore.getState().selectedItemPopup.remove()
		useTextItemStore.setState({selectedItemPopup: null})
	}
}

export function unselectMapContentItem(){
	//Check if map is public
	if (useMapStore.getState().publicMap){
		return
	}

	//Get current selected level 2 and unselect
	const selectedLevel2Items = useContentItemStore.getState().renderedLevel2Items.filter(i => i.selected)
	selectedLevel2Items.forEach(i => {
		useContentItemStore.getState().updateRenderedLevel2SelectedState(i.uuid, false)
		updateLevel2Content(i.uuid)
	})

	//Remove selected item in content item stop
	useContentItemStore.setState({currentSelectedItem: null})

	//Remove selected popup if exist
	if (useContentItemStore.getState().selectedItemPopup !== null){
		useContentItemStore.getState().selectedItemPopup.remove()
		useContentItemStore.setState({selectedItemPopup: null})
	}

	//Get items layer in map store
	const itemsLayer = useMapStore.getState().leafletLayer.filter(l => l.name === "items_layer")[0].layer

	//Remove selected item in leaflet layer
	if (itemsLayer.scene.config !== undefined && itemsLayer.scene.config !== null){
		itemsLayer.scene.config.global.selectedItem = ""
		itemsLayer.scene.updateConfig()
	}

	//Close item sidebar
	useSidebarMapContentItemStore.getState().resetStore()
}

export function convertLatLngToPoint(latLng){
	return L.Projection.SphericalMercator.project(latLng)
}

export function generateNewItemDatas(uuid, x, y, zoom, overlay, type, attributes, name, owner, thumbnailUrl = ""){
	let newItemName = ""

	//Convert new item point to latLng
	const position = L.Projection.SphericalMercator.unproject(L.point(x, y))

	//Generate item name
	if (name.length > 0){
		newItemName = name
	} else if (attributes !== null && JSON.parse(attributes).filter(attr => attr.nameBuilder).length > 0){
		const nameBuilderAttributes = JSON.parse(attributes).filter(attr => attr.nameBuilder)

		nameBuilderAttributes.forEach(a => {
			if (a.type === "string"){
				newItemName = newItemName + a.value + ' '
			}
		})
	}

	if (type.code === "TASKS" || type.code === "LATEX" || type.code === "CONTACT" || type.code === "COMPANY" || type.code === "BIBTEX" || type.code === "SOUND" || type.code === "VIDEOS_ONLINE"){
		thumbnailUrl = undefined
	}

	const newItemDatas = {
		'uuid': uuid,
		'canRemove': false,
		'canRender': true,
		'thumbnailUrl': thumbnailUrl,
		'selected': false,
		'properties': {
			'attributes': attributes === null ? "[]" : attributes,
			'color': '#FFFFFF',
			'fullscreen': type.fullscreen,
			'icon_code': type.icon,
			'id': uuid,
			'lat': position.lat,
			'long': position.lng,
			'zlevel2': zoom,
			'small_zlevel2': zoom - 1,
			'zlevel1': zoom - 2,
			'icon_zlevel': zoom - 4,
			'min_zlevel': zoom - 7,
			'max_zlevel': 18,
			'overlay_id': overlay,
			'owner': owner,
			'type_code': type.code,
			'name': newItemName
		}
	}

	//Generate new item popup and add to map
	newItemDatas.popup = generateLevel2Popup([position.lat, position.lng], newItemDatas, false, thumbnailUrl !== undefined ? thumbnailUrl : "")
	newItemDatas.popup.addTo(useMapStore.getState().mapInstance)

	//Add popup event
	addLevel2PopupEvent(newItemDatas.popup, newItemDatas)
	useContentItemStore.getState().addRenderedLevel2(newItemDatas)

	//Update tiles for level 1
	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined) {
			RefreshTilesUtils.invalidateArea(layer, 'weeki_map_items', [parseFloat(x), parseFloat(y)], true).then()
		}
	})
}

export function updateTextItemValue(uuid, value){
	//Update rendered text item value in store
	useTextItemStore.getState().updateRenderedTextItemValue(uuid, value)

	//Update text item popup content
	updateTextItemPopupContent(uuid)
}

export function updateTextItemPosition(uuid, latLng){
	//Get item in store
	const item = useTextItemStore.getState().renderedTextItems.filter(i => i.uuid === uuid)[0]

	//Check if item exist
	if (!item){
		return
	}

	//Update popup location
	item.popup.setLatLng(latLng)

	//Update item can remove state
	useTextItemStore.getState().updateRenderedTextItemCanRemoveState(uuid, false)
}

export async function deleteContentItem(properties) {
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Convert item latLng to point
	const itemLocation = L.Projection.SphericalMercator.project([properties.lat, properties.long])

	//Delete content item in api
	await ContentItemAPI.deleteContentItem(properties.id)

	//Delete item in map
	globalDeleteMapContentItem(properties.id, itemLocation)

	//Unselect item
	unselectMapContentItem()
}

export async function pasteContentItem(fromMouse = false) {
	//Get paste position
	let newLatLng = null
	let position = null

	if (!fromMouse){
		let point = useMapContextMenuStore.getState().containerPoint
		newLatLng = useMapStore.getState().mapInstance.containerPointToLatLng(L.point(point.x, point.y))
		position = L.Projection.SphericalMercator.project(newLatLng)
	} else {
		position = L.Projection.SphericalMercator.project(L.latLng(useMapStore.getState().mouseLatLng.lat, useMapStore.getState().mouseLatLng.lng))
	}

	//Get item and copy type
	const item = useContentItemStore.getState().currentCopiedItem
	const copyType = useContentItemStore.getState().copyType

	//Get target and origin zoom level
	const targetZoom = Math.floor(useMapStore.getState().mapInstance.getZoom())
	const originZoom = Math.floor(useContentItemStore.getState().copyOriginZoom)

	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	if (copyType === "copy"){
		//Get item type
		const itemCategories = useMapStore.getState().itemCategories
		const itemType = itemCategories.filter(ic => ic.types.some(t => t.code === item.type_code))[0].types.filter(t => t.code === item.type_code)[0]

		//Copy item in api
		const newItemData = await UserContentItemAPI.userCopyItem(item.id, position.x, position.y, originZoom, targetZoom)

		if (newItemData){
			generateNewItemDatas(newItemData.uuid, position.x, position.y, targetZoom, useMapStore.getState().currentOverlay, itemType, item.attributes, item.name + ' copy', AuthAPI.getUserUuid(), newItemData.thumbnailUrl)
		}
	} else {
		//Set popup at new lat lng
		// popup.setLatLng(newLatLng)

		//Add selected menu popup
		// generateContentItemSelectedMenu(popup, feature.properties)
		//
		// //Get new item point
		// const newPoint = L.Projection.SphericalMercator.project(newLatLng)
		// const oldPoint = L.Projection.SphericalMercator.project(oldLatLng)
		//
		// //Update popup position with api
		// if (await ContentItemAPI.updateContentItemLocation(feature.properties.id, newPoint.x, newPoint.y)) {
		// 	globaleUpdateMapContentItemLocation(feature.properties.id, newPoint, oldPoint, false)
		// }
	}
}

export function updateContentItemZoomLevel(contentItemUuid, zoomOffset){
	//Close context menu
	useMapContextMenuStore.getState().resetStore()

	//Update in api
	UserContentItemAPI.userUpdateContentItemZoomLevel(contentItemUuid, zoomOffset)

	//Get item in rendered level 2 list
	const item = useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === contentItemUuid)[0]

	if (item){
		useContentItemStore.getState().updateRenderedLevel2AllZoomLevel(contentItemUuid,
			item.properties.zlevel2 + zoomOffset,
			item.properties.small_zlevel2 + zoomOffset,
			item.properties.zlevel1 + zoomOffset,
			item.properties.icon_zlevel + zoomOffset,
			item.properties.min_zlevel + zoomOffset,
		)

		//Convert item latLng to point
		const position = L.Projection.SphericalMercator.project([item.properties.lat, item.properties.long])

		//Update tiles for level 1
		useMapStore.getState().mapInstance.eachLayer(layer => {
			if (layer._updating_tangram !== undefined) {
				RefreshTilesUtils.invalidateArea(layer, 'weeki_map_items', [parseFloat(position.x), parseFloat(position.y)], true).then()
			}
		})
	}
}