import React, {useEffect, useState} from "react"
import {AiFillDollarCircle, AiFillProject} from "react-icons/ai";
import {FaCookieBite, FaUserAlt} from "react-icons/fa";
import {HiTemplate} from "react-icons/hi";
import {ImMap} from "react-icons/im";
import {MdLogin} from "react-icons/md";
import {RiMailSendFill, RiMap2Fill} from "react-icons/ri";
import {TbDiscount} from "react-icons/tb";
import "react-toastify/dist/ReactToastify.css"
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";
import HistoryAPI from "../../services/HistoryAPI";
import {useHistory} from "react-router-dom";

const AdminDashboardHistory = () => {
    //Get history
    const history = useHistory()

    //Define default state for user list
    const [historyInfos, setHistoryInfos] = useState({
        "mapHistoryRowCount": 0,
        "userHistoryRowCount": 0,
        "userConnectionRowCount": 0,
        "cookieHistoryRowCount": 0,
        "autoEmailHistoryRowCount": 0,
        "promoCodeHistoryRowCount": 0,
        "emailTemplateHistoryRowCount": 0,
        "overlayHistoryRowCount": 0,
        "planHistoryRowCount": 0,
        "projectHistoryRowCount": 0
    })

    useEffect(() => {
        HistoryAPI.getAllHistoryInfos().then((response) => setHistoryInfos(response.data))
    }, [])

    return (
        <>
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-history-global-container">
                    <div className="card" onClick={() => history.push(history.location.pathname + "/maps")}>
                        <div className="left">
                            <div className="icon-container">
                                <ImMap />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Maps history</p>
                            <p className="value">{historyInfos.mapHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/overlay")}>
                        <div className="left">
                            <div className="icon-container">
                                <RiMap2Fill />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Overlay history</p>
                            <p className="value">{historyInfos.overlayHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/users")}>
                        <div className="left">
                            <div className="icon-container">
                                <FaUserAlt />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Users history</p>
                            <p className="value">{historyInfos.userHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/users-connection")}>
                        <div className="left">
                            <div className="icon-container">
                                <MdLogin />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Users connection history</p>
                            <p className="value">{historyInfos.userConnectionRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/cookies")}>
                        <div className="left">
                            <div className="icon-container">
                                <FaCookieBite />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Cookies history</p>
                            <p className="value">{historyInfos.cookieHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/auto-email")}>
                        <div className="left">
                            <div className="icon-container">
                                <RiMailSendFill />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Auto email history</p>
                            <p className="value">{historyInfos.autoEmailHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/promo-code")}>
                        <div className="left">
                            <div className="icon-container">
                                <TbDiscount />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Promotion code history</p>
                            <p className="value">{historyInfos.promoCodeHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/email-template")}>
                        <div className="left">
                            <div className="icon-container">
                                <HiTemplate />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Email template history</p>
                            <p className="value">{historyInfos.emailTemplateHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/plan")}>
                        <div className="left">
                            <div className="icon-container">
                                <AiFillDollarCircle />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Subscription plan history</p>
                            <p className="value">{historyInfos.planHistoryRowCount}</p>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push(history.location.pathname + "/project")}>
                        <div className="left">
                            <div className="icon-container">
                                <AiFillProject />
                            </div>
                        </div>
                        <div className="right">
                            <p className="title">Project history</p>
                            <p className="value">{historyInfos.projectHistoryRowCount}</p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardHistory