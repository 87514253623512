import { useObjectTableStore } from "../../../../services/Stores/ObjectTableStore";
import MapLine from "./MapLine";
import Skeleton from "react-loading-skeleton";
import React from "react";
import OverlayLine from "./OverlayLine";
import AreaLine from "./AreaLine";

const SharedSidebar = () => {
  //Get data in store
  const sharedMapsList = useObjectTableStore((state) => state.sharedMaps);

  return (
    <React.Fragment>
      <div className="sub-line-container">
        {sharedMapsList.map((m) => (
          <React.Fragment key={m.uuid}>
            <MapLine m={m} panel="shared" />
            {m.open && m.overlays === undefined && (
              <Skeleton
                style={{ marginLeft: "16px", width: "80%" }}
                height={32}
                count={m.overlayCount}
              />
            )}
            {m.open && m.overlays !== undefined && (
              <>
                <div className="sub-line-container">
                  {m.overlays.map((o) => (
                    <React.Fragment key={o.uuid}>
                      <OverlayLine m={m} o={o} panel="shared" />
                      {o.open && o.areas === undefined && (
                        <Skeleton
                          style={{ marginLeft: "16px", width: "80%" }}
                          height={32}
                          count={o.areasCount}
                        />
                      )}
                      {o.open && o.areas !== undefined && (
                        <>
                          <div className="sub-line-container">
                            {o.areas.map((a) => (
                              <React.Fragment key={a.uuid}>
                                <AreaLine o={o} m={m} a={a} />
                              </React.Fragment>
                            ))}
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

export default SharedSidebar;
