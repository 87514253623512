import React, {useEffect, useRef, useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import {BsCloudDownload} from "react-icons/bs";
import {createErrorToast, createSuccessToast} from "../../services/CustomToastUtils";
import ContentItemAPI from "../../services/ContentItemAPI";
import UploadFileComponent from "../customs/UploadFileComponent";
import {toast} from "react-toastify";
import {useMapStore} from "../../services/Stores";
import LatexEquationRenderer from "../customs/LatexEquationRenderer";
import {TailSpin} from "react-loader-spinner";
import ReactQuill from "react-quill";
import {getOnlineVideoId} from "../../services/Utils/ItemUtils";
import ContentItemTypeAPI from "../../services/ContentItemTypeAPI";
import Skeleton from "react-loading-skeleton";
import UserContentItemAPI from "../../services/API/User/UserContentItemAPI";
import * as L from 'leaflet'
import TestObjectPopup from "./ObjectPopup/TestObjectPopup";

const CreateObjectPopup = ({categoryList, position, overlay, zoom, mapCenter}) => {
    const [searchObject, setSearchObject] = useState("")
    const [selectedType, setSelectedType] = useState(null)
    const [creationInProgressStatus, setCreationInProgressStatus] = useState(false)
    const itemCreateFrom = useMapStore(state => state.itemCreateFrom)

    useEffect(() => {
        setUploadedFiles([])
        setNewObjectData({
            'name': "",
            'attributes': []
        })
    }, [selectedType])

    const filteredCategory = categoryList.filter(c => c.types.some(t => t.name.toLowerCase().includes(searchObject.toLowerCase())))

    //Define default state for new object data
    const [newObjectData, setNewObjectData] = useState({
        'name': "",
        'attributes': []
    })

    // DRAG FILE
    const [dragActive, setDragActive] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState([])
    const uploadFileInputRef = useRef(null)

    const handleDragFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragActive(false)

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const files = e.dataTransfer.files

            checkUploadedFile(files)
        }
    }

    const handleUploadFileByInput = (event) => {
        let files = []

        if (selectedType === ""){
            files = event.currentTarget.files
        } else {
            files = [event.currentTarget.files[0]]
        }

        if (files.length <= 0) {
            createErrorToast("No files upload")
            return
        }

        checkUploadedFile(files)
    }

    const checkUploadedFile = (files) => {
        if (selectedType !== null){
            const fileExtension = files[0].name.split(".").slice(-1)[0]
            const fileType = files[0].type


            if (selectedType.mimeType !== null){
                if (!selectedType.mimeType.includes(fileType)){
                    createErrorToast("Invalid file type")
                    return
                }
            }

            if (selectedType.authorizedExtensions !== null && selectedType.authorizedExtensions !== undefined){
                if (!selectedType.authorizedExtensions.includes(fileExtension)){
                    createErrorToast("Invalid file type")
                    return
                }
            }

            setUploadedFiles([files[0]])
        } else {
            for (let i = 0; i < files.length; i++){
                const fileCategory = categoryList.filter(c => c.types.some(t => t.mimeType !== null && t.mimeType.includes(files[i].type) || t.authorizedExtensions !== null && t.authorizedExtensions.includes(files[i].name.split(".").slice(-1)[0])))[0]

                if (!fileCategory){
                    createErrorToast("Unsupported file type for " + files[i].name)
                    return;
                }

                // console.log(categoryList.filter(c => c.types.some(t => t.mimeType !== null && t.mimeType.includes(files[i].type)))[0].types.filter(t => t.mimeType !== null && t.mimeType.includes(files[i].type))[0])
                setUploadedFiles(uploadedFiles => [...uploadedFiles, files[i]])
            }
        }


    }

    const getFileTypeFromMime = (mimeType) => {
        return categoryList.filter(c => c.types.some(t => t.mimeType !== null && t.mimeType.includes(mimeType)))[0].types.filter(t => t.mimeType !== null && t.mimeType.includes(mimeType))[0]
    }

    const handleRemoveUploadedFile = (index) => {
        setUploadedFiles([
            ...uploadedFiles.slice(0, index),
            ...uploadedFiles.slice(index + 1, uploadedFiles.length)
        ])
    }

    const handleCancelCreation = () => {
        setLatexEquation("")
        setCreationInProgressStatus(false)
        setSelectedType(null)
        setUploadedFiles([])
        setNewObjectData({
            'name': "",
            'attributes': []
        })
    }

    const handleClosePopup = () => {
        setLatexEquation("")
        setCreationInProgressStatus(false)
        setUploadedFiles([])
        setSelectedType(null)
        setDragActive(false)
        setSearchObject("")
        setNewObjectData({
            'name': "",
            'attributes': []
        })

        useMapStore.setState({createItemPopupState: false})
    }

    const handleCreateObjectWithoutType = async () => {
        setCreationInProgressStatus(true)

        if (uploadedFiles.length <= 0) {
            createErrorToast("No file upload")
            setCreationInProgressStatus(false)
            return
        }

        for (const uploadedFile of uploadedFiles){
            const long = itemCreateFrom === "click" ? position.long : mapCenter.long
            const lat = itemCreateFrom === "click" ? position.lat : mapCenter.lat
            if (await ContentItemAPI.createContentItem(long, lat, zoom, useMapStore.getState().currentOverlay, getFileTypeFromMime(uploadedFile.type).code, uploadedFile, null, uploadedFile.name)) {
                createSuccessToast("Object " + uploadedFile.name + " created")
            }
        }

        setCreationInProgressStatus(false)
        handleClosePopup()
    }

    const taskInputRef = useRef(null)
    const addNewTask = (event) => {
        if (event.key === "Enter"){
            const value = event.currentTarget.value

            if (value.length > 0){
                const newTask = {"taskName": value, checked: false}
                setNewObjectData({...newObjectData, attributes: [...newObjectData.attributes, newTask]})
                taskInputRef.current.value = ""
            }
        }
    }
    const toggleTaskCheck = (taskIndex) => {
        newObjectData.attributes[taskIndex].checked = !newObjectData.attributes[taskIndex].checked;
    }

    const [bibtexRefs, setBibtexRefs] = useState([])
    const [selectedRefCode, setSelectedRefCode] = useState("")

    const fieldFormRef = useRef(null)
    const handleValidCreation = async () => {
        setCreationInProgressStatus(true)

        const long = itemCreateFrom === "click" ? position.long : mapCenter.long
        const lat = itemCreateFrom === "click" ? position.lat : mapCenter.lat
        
        //Convert lat lng to point
        const point = L.Projection.SphericalMercator.project(L.latLng(lat, long))

        const validInputs = []
        if (selectedType.availableFields) {
            if ((selectedType.availableFields && selectedType.availableFields.length > 0)) {

                for (let i = 0; i < fieldFormRef.current.elements.length; i++) {
                    const element = fieldFormRef.current.elements[i]

                    if (element.required) {
                        if (element.value === "") {
                            createErrorToast(element.name + " field can't be empty")
                            setCreationInProgressStatus(false)
                            return
                        }
                    }

                    if (element.dataset.type === "url"){
                        try {
                            const url = new URL(element.value)

                            if (selectedType.authorizedDomains){
                                const host = url.host.replaceAll('www.', "")

                                if (!selectedType.authorizedDomains.includes(host)){
                                    createErrorToast("Invalid URL")
                                    setCreationInProgressStatus(false)
                                    return
                                }
                            }

                        } catch (error){
                            createErrorToast("Invalid URL")
                            setCreationInProgressStatus(false)
                            return
                        }
                    }

                    validInputs.push({
                        'name': element.name,
                        'value': element.value,
                        'required': element.required,
                        'nameBuilder': element.dataset.nameBuilder === "true",
                        'type': element.dataset.type,
                        'visible': true
                    })
                }

                if (selectedType.availableFields && selectedType.availableFields.filter(f => f.force === true).length > validInputs.length) {
                    setCreationInProgressStatus(false)
                    return
                }
            }
        }

        if (!selectedType.needCreateFile){
            if (selectedType.mimeType !== null){
                if (uploadedFiles[0] === undefined){
                    toast.error("Uploaded file missing")
                    setCreationInProgressStatus(false)
                    return
                }
            }
        }

        if (selectedType.needObjectName){
            if (newObjectData.name === ""){
                toast.error("Object name missing")
                setCreationInProgressStatus(false)
                return
            }
        }

        let objectAttributes = newObjectData.attributes
        objectAttributes = objectAttributes.concat(validInputs)

        setLatexEquation("")


        if (await ContentItemAPI.createContentItem(point.x, point.y, zoom, useMapStore.getState().currentOverlay, selectedType, uploadedFiles[0], objectAttributes.length <= 0 ? null : JSON.stringify(objectAttributes), newObjectData.name, null, textEditorValue)){
            setCreationInProgressStatus(false)
            handleClosePopup()
        } else {
            setCreationInProgressStatus(false)
        }
    }

    const onChangeUrl = (event) => {
        const element = event.currentTarget

        if (element.value !== ""){
            try {
                const url = new URL(element.value)
                if (element.classList.contains('error')){
                    element.classList.remove('error')
                }

                if (selectedType.code === "VIDEOS_ONLINE"){
                    getOnlineVideoId(url, setOnlineVideoId, setOnlineVideoType)
                }
            } catch (error){
                element.classList.add('error')
            }
        } else {
            element.classList.remove('error')
        }
    }

    const [latexEquation, setLatexEquation] = useState("")

    //TEXT ITEM
    const [textEditorValue, setTextEditorValue] = useState("")

    const [onlineVideoId, setOnlineVideoId] = useState("")
    const [onlineVideoType, setOnlineVideoType] = useState("")

    //BIBTEX
    const [getBibtexRefRequestState, setGetBibtexRefRequestState] = useState(false)
    useEffect(() => {
        if (selectedType) {
            if (selectedType.code === "BIBTEX") {
                setGetBibtexRefRequestState(false)
                ContentItemTypeAPI.getAllBibtexReference().then((response) => {
                    if (response.status === 200) {
                        setBibtexRefs(response.data)
                        setGetBibtexRefRequestState(true)
                    }
                })
            } else {
                setBibtexRefs([])
            }
        }
    }, [selectedType])

    const [bibtexCodeValue, setBibtexCodeValue] = useState("")
    const [bibtexUploadedFiles, setBibtexUploadedFiles] = useState([])

    const handleImportBibtexFile = (files) => {
        const file = files[0]

        if (file) {
            if (file.type === "" || file.type === "text/plain") {
                setBibtexUploadedFiles([...bibtexUploadedFiles, file])
            }
        }
    }

    const handleCreateBibtex = async () => {
        //Get coords for item creation
        const long = itemCreateFrom === "click" ? position.long : mapCenter.long
        const lat = itemCreateFrom === "click" ? position.lat : mapCenter.lat

        //Convert lat lng to point
        const point = L.Projection.SphericalMercator.project(L.latLng(lat, long))

        //Check if bibtex files has been uploaded
        if (bibtexUploadedFiles.length > 0) {
            setCreationInProgressStatus(true)
            if (await UserContentItemAPI.userCreateBibtexFromFile(bibtexUploadedFiles[0], useMapStore.getState().currentOverlay, point.x, point.y, zoom)) {
                setCreationInProgressStatus(false)
                handleClosePopup()
            } else {
                setCreationInProgressStatus(false)
            }

            return
        }

        //Check if bibtex code is not empty
        if (bibtexCodeValue.length > 0){
            setCreationInProgressStatus(true)
            if (await UserContentItemAPI.userCreateBibtexFromCode(bibtexCodeValue, useMapStore.getState().currentOverlay, point.x, point.y, zoom, selectedType)) {
                setCreationInProgressStatus(false)
                handleClosePopup()
            } else {
                setCreationInProgressStatus(false)
            }

            return
        }

        //Create bibtex from form
        if (selectedRefCode !== ""){
            //Get form inputs
            const formElements = fieldFormRef.current.elements

            const validInputs = []
            for (let i = 0; i < formElements.length; i++){
                const element = formElements[i]

                if (element.required) {
                    if (element.value === "") {
                        createErrorToast(element.name + " field can't be empty")
                        setCreationInProgressStatus(false)
                        return
                    }
                }

                validInputs.push({
                    'name': element.name,
                    'value': element.value,
                    'required': element.required,
                    'nameBuilder': element.dataset.nameBuilder === "true",
                    'type': element.dataset.type,
                    'visible': true
                })
            }

            validInputs.push({
                'name': 'reference-type',
                'value': selectedRefCode,
                'required': true,
                'nameBuilder': false,
                'type': 'string',
                'visible': false
            })

            if (await ContentItemAPI.createContentItem(point.x, point.y, zoom, useMapStore.getState().currentOverlay, selectedType, uploadedFiles[0], JSON.stringify(validInputs), newObjectData.name, null, textEditorValue)){
                setCreationInProgressStatus(false)
                handleClosePopup()
            } else {
                setCreationInProgressStatus(false)
            }
        }
    }

    return (
        <div className="add-object-pop-container">
            <div className="header">
                <h1 className="title">Select and add a file</h1>
                <div className="close-icon" onClick={handleClosePopup}><VscChromeClose /></div>
            </div>
            <div className="content">
                <input ref={uploadFileInputRef} onChange={handleUploadFileByInput} type="file" multiple={true} style={{display: "none"}}/>
                {dragActive && (
                    <div id="drag-container" onDragEnter={handleDragFile} onDragLeave={handleDragFile} onDrop={handleDrop}
                         onDragStart={(e) => e.preventDefault()}
                         onDragEnd={(e) => e.preventDefault()}
                         onDragOver={(e) => e.preventDefault()}
                         onDrag={(e) => e.preventDefault()}
                    ></div>
                )}
                <div className="object-selector-container">
                    <div className="iconSearch">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-search"><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M4.268 21a2 2 0 0 0 1.727 1H18a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v3"/><path d="m9 18-1.5-1.5"/><circle cx="5" cy="14" r="3"/></svg>
                    </div>
                    <input type="text" className="search-object-input"  placeholder="Search type of file..." value={searchObject} onChange={(e) => setSearchObject(e.currentTarget.value)}/>
                    <div className="object-type-list-container">
                        {filteredCategory.map((category, indexCategory) => (
                            <div className="object-category" key={indexCategory}>
                                <h2 className="category-title">{category.name}</h2>
                                <div className="object-types-list">
                                    {category.types.filter(t => t.name.toLowerCase().includes(searchObject.toLowerCase())).map((type, indexType) => (
                                        <div className={selectedType && selectedType.code === type.code ? "object-type selected" : "object-type"} key={indexType} onClick={() => {
                                            if (selectedType !== type) {
                                                setUploadedFiles([])
                                                setSelectedType(type)
                                            } else {
                                                handleCancelCreation()
                                            }
                                        }}>
                                            <div className="object-type-icon">
                                                <img src={"/static/map/svg/" + type.icon + ".svg"} alt="Contact & Users"/>
                                            </div>
                                            <p className="object-type-name">{type.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="object-creation-container">
                    {/*OBJECT CREATION WITHOUT TYPE*/}
                    {!selectedType && (
                        <>
                            <div className={dragActive ? "unselected-upload-container active": "unselected-upload-container"} onDragEnter={handleDragFile}>
                                <p className="upload-icon"><BsCloudDownload /></p>
                                <p className="upload-text">Drag and drop your file <br /> or <span onClick={() => uploadFileInputRef.current.click()}>browse</span> your files</p>
                                <p className="max-text">
                                    Maximum file size 50 Mo
                                    <div class="icon-container" data-tooltip="1Go for PRO et ENTREPRISE plan">
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        >
                                        <circle cx="12" cy="12" r="10" />
                                        <path d="M12 16v-4" />
                                        <path d="M12 8h.01" />
                                        </svg>
                                    </div>
                                </p>
                                <div className="uploaded-file-list">
                                    {uploadedFiles.map((uploadedFile, index) => (
                                        <div className="uploaded-file" key={index}>
                                            <p className="remove-icon" onClick={() => handleRemoveUploadedFile(index)}><VscChromeClose /></p>
                                            <p className="uploaded-filename">{uploadedFile.name} ({getFileTypeFromMime(uploadedFile.type).name})</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {uploadedFiles.length > 0 && (
                                <div className="footer">
                                    <div className="btn invert" onClick={handleCancelCreation}>Cancel</div>
                                    <div className="btn" onClick={handleCreateObjectWithoutType}>Add</div>
                                </div>
                            )}
                        </>
                    )}
                    {selectedType && (
                        <>
                            <div className="select-type-container">
                                <h1 className="type-title">{selectedType.name}</h1>
                                {selectedType.needObjectName && (
                                    <div className="field-container">
                                        <label htmlFor="object-name"><span style={{ textTransform: 'none' }}>Object title*</span></label>
                                        <input id="object-name" type="text" onChange={(event) => setNewObjectData({...newObjectData, name: event.currentTarget.value})} className={(selectedType.mimeType === null || selectedType.authorizedExtensions === null) ? "field-input title" : "field-input title"} />
                                    </div>
                                )}
                                {selectedType.code === "TEXTS" && (
                                    <ReactQuill theme="snow" value={textEditorValue} onChange={(value) => setTextEditorValue(value)} />
                                )}
                                {(selectedType.mimeType !== null || selectedType.authorizedExtensions !== null) && (
                                    <UploadFileComponent multiple={false} processUploadFile={checkUploadedFile} uploadedFiles={uploadedFiles} />
                                )}
                                {/*LATEX EQUATION RENDER*/}
                                {selectedType.code === "LATEX" && (
                                    <LatexEquationRenderer latex={latexEquation} />
                                )}
                                {/*RENDER TYPE COMMOM FIELDS*/}
                                {selectedType.availableFields && (
                                    <>
                                        {selectedType.availableFields.length > 0 && (
                                            <form ref={fieldFormRef}>
                                                {selectedType.availableFields.map((field, index) => (
                                                    <>
                                                        {field.type === "string" && (
                                                            <div className="field-container">
                                                                <label htmlFor={field.name}>{field.name}{field.force ? "*" : ""}</label>
                                                                <input className="field-input" id={field.name} required={field.force} name={field.name} data-type={field.type} data-name-builder={field.nameBuilder} type="text" />
                                                            </div>
                                                        )}
                                                        {field.type === "url" && (
                                                            <div className="field-container">
                                                                <label htmlFor={field.name}>{field.name}{field.force ? "*" : ""}</label>
                                                                <input className="field-input" onChange={onChangeUrl} id={field.name} required={field.force} name={field.name} data-type={field.type} data-name-builder={field.nameBuilder} type="text" />
                                                            </div>
                                                        )}
                                                        {field.type === "latex" && (
                                                            <textarea onChange={(event) => setLatexEquation(event.currentTarget.value)}  required={field.force} name={field.name} data-type={field.type} data-name-builder={field.nameBuilder} placeholder="Equation*" rows={10}></textarea>
                                                        )}
                                                    </>
                                                ))}
                                            </form>
                                        )}
                                    </>
                                )}
                                {/*CUSTOM INPUT FOR TASK TYPE*/}
                                {selectedType.code === "TASKS" && (
                                    <div className="task-creation-container">
                                        <div className="task-list">
                                            {newObjectData.attributes.map((task, index) => (
                                                <div className="task" key={index}>
                                                    <div onClick={() => toggleTaskCheck(index)} className={task.checked ? "task-check checked" : "task-check"}></div>
                                                    <div className="task-name">{task.taskName}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <input type="text" ref={taskInputRef} onKeyDown={addNewTask} placeholder="Add task" />
                                    </div>
                                )}
                                {selectedType.code === "VIDEOS_ONLINE" && (
                                    <>
                                        {onlineVideoId !== "" && (
                                            <>
                                                {onlineVideoType === "youtube" && (
                                                    <div className="online-video-thumbnail-container">
                                                        <img src={"https://img.youtube.com/vi/" + onlineVideoId + "/maxresdefault.jpg"} alt="Video thumbnail"/>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {selectedType.code === "BIBTEX" && (
                                    <>
                                        {!getBibtexRefRequestState && (
                                            <Skeleton height={36} style={{marginBottom: '12px'}} />
                                        )}
                                        {getBibtexRefRequestState && (
                                            <>
                                                {bibtexRefs.length > 0 && (
                                                    <>
                                                        {bibtexUploadedFiles.length === 0 && (
                                                            <>
                                                                {bibtexCodeValue.length === 0 && (
                                                                    <>
                                                                        <h2>Add entry manually</h2>
                                                                        <select onChange={(event) => setSelectedRefCode(event.currentTarget.value)}>
                                                                            <option value="" selected={true}>Choose reference type</option>
                                                                            {bibtexRefs.map((ref, index) => (
                                                                                <option key={index} value={ref.code}>{ref.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        {selectedRefCode !== "" && (
                                                                            <form ref={fieldFormRef}>
                                                                                {bibtexRefs.filter(r => r.code === selectedRefCode)[0].availableFields.map((field, index) => (
                                                                                    <>
                                                                                        {field.type === "string" && (
                                                                                            <input required={field.force} name={field.name} data-type={field.type} data-name-builder={field.nameBuilder} type="text" placeholder={field.force ? field.name + "*" : field.name} />
                                                                                        )}
                                                                                    </>
                                                                                ))}
                                                                            </form>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}

                                                        {selectedRefCode === "" && (
                                                            <>
                                                                {bibtexUploadedFiles.length === 0 && (
                                                                    <>
                                                                        <h2>Create from BibTeX code</h2>
                                                                        <textarea rows={7} onChange={(event) => setBibtexCodeValue(event.currentTarget.value)} value={bibtexCodeValue} placeholder="Your BibTeX code"></textarea>
                                                                    </>
                                                                )}
                                                                {bibtexCodeValue.length === 0 && (
                                                                    <>
                                                                        <h2>Import library</h2>
                                                                        <UploadFileComponent multiple={false} processUploadFile={handleImportBibtexFile} uploadedFiles={bibtexUploadedFiles} />
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="footer">
                                {!creationInProgressStatus && (
                                    <>
                                        <div className="btn invert" onClick={handleClosePopup}>Cancel</div>
                                        <div className="btn" onClick={selectedType.code === "BIBTEX" ? handleCreateBibtex : handleValidCreation}>Add</div>
                                    </>
                                )}
                                {creationInProgressStatus && (
                                    <div className="btn loader">
                                        <TailSpin height="25" width="25" color="#FFFFFF" radius={1} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CreateObjectPopup