import {PROJECTS_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function getAllProjects(){
    return getAxiosInstance().get(PROJECTS_API + "/get_all")
}

function getUserProjects(){
    return getAxiosInstance().get(PROJECTS_API + "/get_user_projects")
}

function renameProject(uuid, name){
    const sendData = new FormData()
    sendData.append('uuid', uuid)
    sendData.append('name', name)

    return getAxiosInstance().post(PROJECTS_API + '/rename', sendData)
        .then((response) => {
            return response.status === 200
        })
}

export default {
    getAllProjects,
    getUserProjects,
    renameProject
}