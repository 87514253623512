import React from "react";
import UserMapCollaboratorAPI from "../../../services/API/User/UserMapCollaboratorAPI";
import {useNotificationStore} from "../../../services/Stores/NotificationsStore";
import UserInvitationAPI from "../../../services/API/User/UserInvitationAPI";

const MoocInvitationNotification = ({notification}) => {
	const handleAcceptInvitation = async () => {
		if (await UserInvitationAPI.userAcceptInvitation(notification.attributes.invitationUuid)){
			useNotificationStore.getState().removeNotification(notification.uuid)
			useNotificationStore.getState().updateNotificationsUnReadCount()
		}
	}

	const handleDeclineInvitation = async () => {

	}

	return (
		<div className={notification.read ? "map-invitation-notification-container" : "map-invitation-notification-container unread"}>
			<div className="map-invitation-notification-left">
				<div className="user-picture">
					{notification.senderThumbnail !== null && (
						<img src={notification.senderThumbnail} alt="sender"/>
					)}
					{notification.senderThumbnail === null && (
						<p className="user-picture-text">{notification.sender.split(' ')[0][0]}{notification.sender.split(' ')[1][0]}</p>
					)}
				</div>
			</div>
			<div className="map-invitation-notification-right">
				<p className="notification-text"><span>{notification.sender}</span> invites you to join "{notification.attributes.name}" story</p>
				<p className="notification-time">{notification.sendAt}</p>
				<div className="notification-invitation-button-container">
					{notification.attributes.state === "pending" && (
						<>
							<p className="btn" onClick={handleAcceptInvitation}>Accept</p>
							<p className="btn deny" onClick={handleDeclineInvitation}>Deny</p>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default MoocInvitationNotification