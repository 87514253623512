import getAxiosInstance from "../../../AxiosInstance";
import {USER_MOOC_PARTICIPANT_API} from "../../../../../Router";

function userAddPresenceTime(moocUuid, presenceTime){
	const sendData = new FormData()
	sendData.append('moocUuid', moocUuid)
	sendData.append('presenceTime', presenceTime)

	return getAxiosInstance().post(USER_MOOC_PARTICIPANT_API + '/add_presence', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

function userStartCourse(courseUuid){
	const sendData = new FormData()
	sendData.append('courseUuid', courseUuid)
	return getAxiosInstance().post(USER_MOOC_PARTICIPANT_API + '/start_course', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

function userValidCourse(courseUuid){
	const sendData = new FormData()
	sendData.append('courseUuid', courseUuid)
	return getAxiosInstance().post(USER_MOOC_PARTICIPANT_API + '/valid_course', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

export default {
	userAddPresenceTime,
	userStartCourse,
	userValidCourse
}