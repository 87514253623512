import getAxiosInstance from "../../AxiosInstance";
import {USER_CONTROLLER, USER_PAYMENT_API} from "../../../../Router";
import {useUserStore} from "../../Stores";

function getAllUserPaymentMethods(){
    return getAxiosInstance().get(USER_PAYMENT_API + '/get_all')
}

function getAllUserInvoices(){
    return getAxiosInstance().get(USER_PAYMENT_API + '/get_all_invoices')
}

function getCurrentUserSubscription(){
    return getAxiosInstance().get(USER_CONTROLLER + '/get_current_subscription')
}

function generatePrice(planUuid, billingPeriod, userCount, promoCode){
    const sendData = new FormData()
    sendData.append('planUuid', planUuid)
    sendData.append('billingPeriod', billingPeriod)
    sendData.append('userCount', userCount)

    if (promoCode){
        sendData.append('promoCode', promoCode.code)
    }

    return getAxiosInstance().post(USER_PAYMENT_API + '/generate_price', sendData)
        .then((response) => {
            if (response.status === 200){
                return response.data
            }

            return false
        })
        .catch(() => false)
}

export function updateCurrentUserSubscription(){
    getAxiosInstance().get(USER_CONTROLLER + '/get_current_subscription').then(response => {
        if (response.status === 200){
            useUserStore.setState({userSubscription: response.data})
        } else {
            useUserStore.setState({userSubscription: null})
        }
    })
}

function userAddPaymentMethod(cardToken, isDefault){
    const sendData = new FormData()
    sendData.append("cardToken", cardToken)
    sendData.append("isDefault", isDefault)

    return getAxiosInstance().post(USER_PAYMENT_API + '/add_method', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

function userDeleteCard(cardId){
    const sendData = new FormData()
    sendData.append("cardId", cardId)

    return getAxiosInstance().post(USER_PAYMENT_API + '/remove_card', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

function userUpdateDefaultCard(cardId){
    const sendData = new FormData()
    sendData.append("cardId", cardId)

    return getAxiosInstance().post(USER_PAYMENT_API + '/update_default_card', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

function userSubscribe(planUuid, paymentPeriod, userCount, promoCodeList){
    const sendData = new FormData()
    sendData.append("planUuid", planUuid)
    sendData.append("paymentTime", paymentPeriod)
    sendData.append("userCount", userCount)

    if (promoCodeList){
        sendData.append('promocode', promoCodeList.code)
    }

    return getAxiosInstance().post(USER_PAYMENT_API + '/subscribe', sendData)
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

function userUpdateSubscription(billingPeriod, userCount){
    const sendData = new FormData()
    sendData.append('billingPeriod', billingPeriod)
    sendData.append('userCount', userCount)

    return getAxiosInstance().post(USER_PAYMENT_API + '/update_subscription', sendData)
        .then((response) => {
            return response.status === 200
        })
        .catch(() => false)
}

function userCancelCurrentSubscription(){
    return getAxiosInstance().post(USER_PAYMENT_API + '/cancel_current_subscription')
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

function userResumeCurrentSubscription(){
    return getAxiosInstance().post(USER_PAYMENT_API + '/resume_current_subscription')
        .then((response) => {
            if (response.status === 200){
                return true
            }
        })
        .catch(() => {
            return false
        })
}

export default {
    getAllUserPaymentMethods,
    userAddPaymentMethod,
    userDeleteCard,
    userUpdateDefaultCard,
    userSubscribe,
    getAllUserInvoices,
    getCurrentUserSubscription,
    userCancelCurrentSubscription,
    updateCurrentUserSubscription,
    userResumeCurrentSubscription,
    userUpdateSubscription,
    generatePrice
}