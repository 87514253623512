import {ADMIN_OBJECT_API, OBJECT_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function adminGetAllObjects (){
    return getAxiosInstance().get(ADMIN_OBJECT_API + "/get_all")
}

function getObjectByMap(mapUuid){
    return getAxiosInstance().get(OBJECT_API + "/get_by_map", {
        params: {
            mapUuid: mapUuid
        }
    })
}

function getContentItemCategories(){
    return getAxiosInstance().get(OBJECT_API + "/get_categories")
}

function getContentItemTypes(){
    return getAxiosInstance().get(OBJECT_API + "/get_types")
}

function getMapItems(mapUuid){
    return getAxiosInstance().get(OBJECT_API + "/get_map_items", {
        params: {mapUuid: mapUuid}
    })
}

function getItemByUuid(itemUuid){
    return getAxiosInstance().get(OBJECT_API + "/get_item_by_uuid", {
        params: {itemUuid: itemUuid}
    })
}

function updateItemNote(itemUuid, note){
    toast.loading("Note update in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append("objectUuid", itemUuid)
    sendData.append("note", note)

    return getAxiosInstance().post(OBJECT_API + "/set_note", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }

            return false
        })
}

function updateItemColor(itemUuid, color){
    toast.loading("Note update in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append("objectUuid", itemUuid)
    sendData.append("color", color)

    return getAxiosInstance().post(OBJECT_API + "/set_color", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }

            return false
        })
}

function linkItem(sourceItemUuid, targetItemUuid){
    toast.loading("Object association in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append("sourceObjectUuid", sourceItemUuid)
    sendData.append("targetObjectUuid", targetItemUuid)

    return getAxiosInstance().post(OBJECT_API + "/link_object", sendData)
        .then((response) => {
            toast.dismiss("loadAlert")
            if (response){
                if (response.status === 200){
                    return true
                }
            }
        })
        .catch((error) => {
            toast.dismiss("loadAlert")
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }

            return false
        })
}

export default {
    adminGetAllObjects,
    getObjectByMap,
    getContentItemTypes,
    getContentItemCategories,
    getMapItems,
    getItemByUuid,
    updateItemNote,
    updateItemColor,
    linkItem
}