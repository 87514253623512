import {CONTENT_ITEM_TAG_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function getObjectTags(itemUuid){
    return getAxiosInstance().get(CONTENT_ITEM_TAG_API + "/get_item_tags", {params: {contentItemUuid: itemUuid}})
}

function deleteItemTag(tagUuid){
    return getAxiosInstance().delete(CONTENT_ITEM_TAG_API + "/delete", {
        params: {tagUuid: tagUuid}
    })
        .then((response) => {
            return response.status === 200
        })
        .catch(() => false)
}

function addItemTag(itemUuid, name){
    const sendData = new FormData()
    sendData.append("contentItemUuid", itemUuid)
    sendData.append("name", name)

    return getAxiosInstance().post(CONTENT_ITEM_TAG_API + "/add", sendData)
        .then((response) => {
            if (response.status === 200){
                return response.data.uuid
            }

            return false
        })
        .catch(() => false)
}

export default {
    deleteItemTag,
    addItemTag,
    getObjectTags
}