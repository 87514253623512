import getAxiosInstance from "../../AxiosInstance";
import {USER_SEARCH_API} from "../../../../Router";

function userSearchOnMap(query, mapUuid){
	return getAxiosInstance().get(USER_SEARCH_API + '/map', {params: {query: query, mapUuid: mapUuid}})
}

function userSearchOnObjectTable(query){
	return getAxiosInstance().get(USER_SEARCH_API + '/object-table', {params: {query: query}})
}

function userSearchGlobal(query) {
	return getAxiosInstance().get(USER_SEARCH_API + '/global_search', {params: {query: query}})
}

export default {
	userSearchOnMap,
	userSearchOnObjectTable,
	userSearchGlobal
}