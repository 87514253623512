import React, {useEffect, useState} from "react";
import weeki_logo from "../../images/weeki_logo.svg"
import {VscChromeClose} from "react-icons/vsc";
import {GoAlertFill} from "react-icons/go";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {HiOutlineBan} from "react-icons/hi";
import AuthAPI from "../services/AuthAPI";
import {TailSpin} from "react-loader-spinner";
import {toast} from "react-toastify";

const LoginSessionPopup = ({state, setState, sessions, credentials, login, cancelLogin}) => {
    const [closedSession, setClosedSession] = useState([])
    const [closedSessionInProgress, setClosedSessionInProgress] = useState("")
    const [validLogin, setValidLogin] = useState(false)

    const handleCloseSession = async (sessionUuid) => {
        setClosedSessionInProgress(sessionUuid)

        if (await AuthAPI.closeLoginSession(sessionUuid)){
            setClosedSessionInProgress("")
            if (!closedSession.includes(sessionUuid)){
                setClosedSession([...closedSession, sessionUuid])
            }
        } else {
            toast.error("Failed to close session")
            setClosedSessionInProgress("")
        }
    }

    useEffect(() => {
        if (closedSession.length >= 1){
            setValidLogin(true)
        }
    }, [closedSession])

    return (
        <div className="login-session-overlay">
            <div className="login-session-container">
                <div className="header">
                    <img className="weeki-logo" src={weeki_logo} alt="Weeki logo"/>
                    <p className="close-btn" onClick={cancelLogin}><VscChromeClose /></p>
                </div>
                <div className="alert-banner">
                    <div className="icon"><GoAlertFill /></div>
                    <p className="text">You have reached the limit of activated devices.</p>
                </div>
                <div className="content">
                    <h1 className="title">Disconnect from one of the devices below to continue.</h1>
                    <p className="text">Weeki can only be activated on 2 devices simultaneously. Click «Disconnect» on at least one of the devices below to continue using Weeki. Your files on these devices will not be affected when you disconnect.</p>
                    <p className="text">You are currently logged in with ID &#123;<span className='strong'>{credentials.email}</span>&#125;. <span className="click" onClick={cancelLogin}>Disconnect and use another Weeki ID</span>.</p>
                    <div className="session-list-container">
                        <div className="session ban">
                            <p className="icon ban"><HiOutlineBan /></p>
                            <p className="session-name">This device</p>
                            <p className="last-activity">(not activated)</p>
                        </div>
                        {sessions.map((session, index) => (
                            <div className="session" key={index}>
                                <p className="icon"><AiOutlineCheckCircle /></p>
                                <p className="session-name">{session['lastIp']}</p>
                                <p className="last-activity">{session['lastActivity']}</p>
                                <p className="disconnect-btn" onClick={() => handleCloseSession(session['uuid'])}>
                                    {closedSessionInProgress === session['uuid']
                                        ? <TailSpin width={16} height={16} wrapperClass="btn-loader-wrapper" color="#000" />
                                        : closedSession.includes(session['uuid']) ? "Closed" : "Disconnect"
                                    }

                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="footer">
                    <p className="button" onClick={cancelLogin}>Quit</p>
                    <p className={!validLogin ? "button disabled" : "button continue"} onClick={validLogin ? login : null}>Continue</p>
                </div>
            </div>
        </div>
    )
}

export default LoginSessionPopup