import getAxiosInstance from "../../../AxiosInstance";
import {USER_MOOC_QUIZ_RESULT_API} from "../../../../../Router";

function userCreateQuizResult(quizUuid, results){
	const sendData = new FormData()
	sendData.append('quizUuid', quizUuid)
	sendData.append('results', JSON.stringify(results))
	return getAxiosInstance().post(USER_MOOC_QUIZ_RESULT_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.uuid
			}

			return false
		})
		.catch(() => {
			return false
		})
}

export default {
	userCreateQuizResult
}