import {useMapStore} from "../Stores";
import {generateLevel2Popup} from "./MapPopupUtils";
import {addLevel2PopupEvent} from "./PopupEventsUtils";
import {useContentItemStore} from "../Stores/ContentItemStore";
import PublicsContentItemAPI from "../API/Publics/PublicsContentItemAPI";
import ContentItemAPI from "../ContentItemAPI";
import ReactDOMServer from "react-dom/server";
import TestObjectPopup from "../../components/Map/ObjectPopup/TestObjectPopup";
import {throttle} from "leaflet/src/core/Util";
import L, {DomEvent, DomUtil} from 'leaflet'

/**
 * Render content item level 2 on map
 */
function _renderLevel2Items() {
	if (useContentItemStore.getState().blockRefresh){
		return
	}

	checkManuallyRenderedLevel2Items()

	useMapStore.getState().mapInstance.eachLayer(layer => {
		if (layer._updating_tangram !== undefined){
			if (layer.scene.config !== null) {
				if (layer.scene.sources.weeki_map_items) {
					layer.scene.queryFeatures({geometry: true, visible: true}).then(features => {
						//If no feature return remove all feature in map and in array
						if (features.length === 0) {
							useContentItemStore.getState().renderedLevel2Items.filter(i => i.canRemove === true && i.canRender === true).forEach(l => {
								l.popup.remove()
								useContentItemStore.getState().removeRenderedLevel2(l.uuid)
							})

							return
						}

						//If feature has been return render all feature
						if (features.length > 0) {
							features.forEach(f => {
								if (useMapStore.getState().mapInstance.getZoom() < f.properties["small_zlevel2"] ) {
									return;
								}

								//Check if item is already render
								if (useContentItemStore.getState().renderedLevel2Items.filter(r => r.uuid === f.properties.id).length > 0) {
									const renderedItem = useContentItemStore.getState().renderedLevel2Items.filter(r => r.uuid === f.properties.id)[0]

									//Update rendered data
									if (renderedItem.properties.lat !== f.properties.lat || renderedItem.properties.long !== f.properties.long){
										//Move popup
										renderedItem.popup.setLatLng([f.properties.lat, f.properties.long])

										//Update item properties in store and level 2
										useContentItemStore.getState().updateRenderedLevel2Properties(renderedItem.uuid, f.properties)
										useContentItemStore.getState().updateRenderedLevel2ZoomLevel(renderedItem.uuid, f.properties.zlevel2)
									}

									return
								}

								//Check if item can be rendered
								if (useContentItemStore.getState().renderedLevel2Items.filter(r => r.uuid === f.properties.id && r.canRender === false).length > 0) {
									return;
								}

								const currentZoom = useMapStore.getState().mapInstance.getZoom()
								let smallItem = false;
								if (currentZoom <= f.properties.zlevel2){
									smallItem = true
								}

								//Generate popup for level 2 and add to rendered level2 list
								const itemPopup = generateLevel2Popup([f.geometry.coordinates[1], f.geometry.coordinates[0]], f, smallItem)
								itemPopup.addTo(useMapStore.getState().mapInstance)

								//Add event for popup
								addLevel2PopupEvent(itemPopup, f)
								useContentItemStore.getState().addRenderedLevel2({
									uuid: f.properties.id,
									popup: itemPopup,
									thumbnailUrl: "",
									properties: f.properties,
									selected: useContentItemStore.getState().currentSelectedItem === f.properties.id,
									canRemove: true,
									canRender: true,
									zlevel2: f.properties.zlevel2,
									small: smallItem
								})

								//Check if popup need get thumbnail
								if (f.properties['thumbnail_url'] !== undefined) {
									getLevel2ThumbnailUrl(f.properties.id).then()
								}
							})
						}

						//Delete object level2 popup if is not visible
						useContentItemStore.getState().renderedLevel2Items.filter(i => i.canRemove === true && i.canRender === true).forEach(l => {
							let needView = false
							features.forEach(feat => {
								if (l.uuid === feat.properties.id && l.properties["small_zlevel2"] <= useMapStore.getState().mapInstance.getZoom()) {
									needView = true

									//Check if popup need small or large
									if (l.properties.zlevel2 > useMapStore.getState().mapInstance.getZoom()){
										if (!l.small){
											useContentItemStore.getState().updateRenderedLevel2SmallValue(l.uuid, true)
											updateLevel2Content(l.uuid)
										}
									} else {
										if (l.small){
											useContentItemStore.getState().updateRenderedLevel2SmallValue(l.uuid, false)
											updateLevel2Content(l.uuid)
										}
									}
								}
							})

							if (!needView) {
								l.popup.remove()
								useContentItemStore.getState().removeRenderedLevel2(l.uuid)
							}
						})
					})
				}
			}
		}
	})
}

export const renderLevel2Items = throttle(_renderLevel2Items, 200)

export function checkManuallyRenderedLevel2Items() {
	const renderedLevel2 = useContentItemStore.getState().renderedLevel2Items.filter(i => i.canRemove === false && i.canRender === true)
	const mapBounds = useMapStore.getState().mapInstance.getBounds()
	const currentZoomLevel = useMapStore.getState().mapInstance.getZoom()

	renderedLevel2.forEach(item => {
		const level2ZoomLevel = item.properties.zlevel2
		const smallLevel2ZoomLevel = item.properties.small_zlevel2
		const isVisible = mapBounds.contains(item.popup.getLatLng())

		//Check if popup is visible on map bounds
		if (!isVisible || currentZoomLevel < smallLevel2ZoomLevel){
			item.popup.close()
		} else {
			//Open popup if is not open
			if (!item.popup.isOpen()){
				item.popup.openOn(useMapStore.getState().mapInstance)
			}

			//Check if popup need small or large
			if (level2ZoomLevel > currentZoomLevel){
				if (!item.small){
					useContentItemStore.getState().updateRenderedLevel2SmallValue(item.uuid, true)
					updateLevel2Content(item.uuid)
				}
			} else {
				if (item.small){
					useContentItemStore.getState().updateRenderedLevel2SmallValue(item.uuid, false)
					updateLevel2Content(item.uuid)
				}
			}
		}
	})
}


export async function getLevel2ThumbnailUrl(itemId) {
	let thumbnailUrl = ""

	let promise;

	if (useMapStore.getState().publicMap){
		promise = PublicsContentItemAPI.getPublicsContentItemThumbnailUrl(itemId)
	} else {
		promise = ContentItemAPI.getContentItemThumbnailUrl(itemId)
	}

	await promise.then((response) => {
		if (response.status === 200){
			thumbnailUrl = response.data
		}
	})

	useContentItemStore.getState().updateRenderedLevel2ThumbnailUrl(itemId, thumbnailUrl)

	updateLevel2Content(itemId)
}

export function updateLevel2Content(uuid) {
	const item = useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === uuid)[0]

	//Check if item exist
	if (!item){
		return
	}

	item.popup.setContent(ReactDOMServer.renderToString(<TestObjectPopup thumbnailUrl={item.thumbnailUrl} loader={(item.properties['thumbnail_url'] !== undefined && item.thumbnailUrl === "")} item={item.properties} selected={item.selected} small={item.small} />))
	addLevel2PopupEvent(item.popup, item)
}

export function ScrollablePopupMixin(){
	return class extends L.Popup {
		constructor (options={}, source) {
			options.className = options.className || 'leaflet-popup-draggable'
			super(options, source)
		}

		_initLayout(){
			const prefix = 'leaflet-popup',
			 container = this._container = DomUtil.create('div', `${prefix} ${this.options.className || ''} leaflet-zoom-animated`);
			const wrapper = this._wrapper = DomUtil.create('div', `${prefix}-content-wrapper`, container);

			this._contentNode = DomUtil.create('div', `${prefix}-content`, wrapper);

			DomEvent.disableClickPropagation(container);

			// ======= PATCH START - allow mouse wheel events propagation to map
			// L.DomEvent.disableScrollPropagation(this._contentNode);
			// ======= PATCH END
		}
	}
}