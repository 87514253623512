import React, {useEffect, useState} from "react";
import {useMoocStore} from "../../services/Stores/MoocStore";
import MoocCourseSidebarHeader from "./MoocCourseSidebarHeader";
import UserMoocQuizQuestionAPI from "../../services/API/User/Mooc/UserMoocQuizQuestionAPI";
import QuizQuestionCreation from "./QuizQuestionCreation";
import {useMoocQuizStore} from "../../services/Stores/MoocQuizStore";
import UserMoocQuizResultAPI from "../../services/API/User/Mooc/UserMoocQuizResultAPI";
import Latex from "react-latex-next";
import {useUserStore} from "../../services/Stores";
import {createErrorToast} from "../../services/CustomToastUtils";

const MoocQuizSidebar = () => {
	//Get full screen state in store
	const fullscreenState = useMoocStore(state => state.courseFullscreenState)

	//Get current selected quiz in store
	const currentSelectedQuiz = useMoocStore(state => state.currentSelectedQuiz)

	//Get current selected mooc in store
	const currentSelectedMooc = useMoocStore(state => state.openedMooc)

	//Get current quiz chapter
	const currentChapter = useMoocStore(state => state.openedMoocChapters.filter(c => currentSelectedQuiz !== null && c.quizzes !== undefined && c.quizzes !== null && c.quizzes.some(e => e.uuid === currentSelectedQuiz.uuid))[0])

	//Define default state for edit state
	const [editState, setEditState] = useState(false)

	//Get edit questions in quiz store
	const editQuestions = useMoocQuizStore(state => state.editQuestions)

	//Get test questions in quiz store
	const testQuestions = useMoocQuizStore(state => state.testQuestions)

	useEffect(() => {
		if (currentSelectedQuiz) {
			const currentQuestions = structuredClone(currentSelectedQuiz.questions)

			if (editState) {
				console.log("edit")
				useMoocQuizStore.setState({editQuestions: currentQuestions})
				// useMoocQuizStore.setState({testQuestions: []})
			} else {
				useMoocQuizStore.setState({editQuestions: []})
				useMoocQuizStore.setState({testQuestions: currentQuestions})
			}
		}
	}, [editState, currentSelectedQuiz])

	//Add new question to edit questions state
	const handleAddNewEditQuestion = () => {
		const newQuestionData = {
			'point': 1,
			'question': "",
			'responseExplication': "",
			'responses': []
		}

		//Add questions to state
		useMoocQuizStore.getState().addEditQuestion(newQuestionData)
	}

	const handleSaveQuiz = async () => {
		if (editQuestions.length <= 0){
			setEditState(false)
			return
		}

		// console.log(editQuestions)
		//
		// editQuestions.forEach((eq, index) => {
		// 	let questionErrors = {}
		//
		// 	//Check question point
		// 	if (isNaN(parseInt(eq.point))){
		// 		questionErrors.points = "Invalid question point value"
		// 	}
		//
		// 	//Check question text
		// 	if (eq.question.length <= 0){
		// 		questionErrors.question = "Question can't be empty"
		// 	}
		//
		// 	//Check answer explanation
		// 	if (eq.responseExplication.length <= 0){
		// 		questionErrors.responseExplication = "Answer explanation can't be empty"
		// 	}
		//
		// 	//Check if response array is empty
		// 	if (eq.responses.length < 2){
		// 		questionErrors.responses = "Question need 2 answer"
		// 	} else {
		// 		// eq.responses.forEach((r, rIndex) => {
		// 		//
		// 		// })
		// 	}
		//
		// 	if (Object.keys(questionErrors).length > 0){
		// 		useMoocStore.getState().addEditQuestionError(index, questionErrors)
		// 	}
		// })

		//Get new questions from edit questions
		const updatedQuestions = []
		const newEditQuestions = editQuestions.filter(q => q.uuid === undefined)
		const updatedEditQuestions = editQuestions.filter(q => q.uuid !== undefined)

		//Create new question
		if (newEditQuestions.length > 0){
			const newQuestions = await UserMoocQuizQuestionAPI.userCreateMoocQuizQuestions(currentSelectedQuiz.uuid, newEditQuestions)

			if (newQuestions){
				newQuestions.forEach(q => {
					useMoocStore.getState().addCurrentQuizQuestion(q)
					useMoocStore.getState().addQuizQuestion(currentChapter.uuid, currentSelectedQuiz.uuid, q)
				})
			}
		}

		if (updatedEditQuestions.length > 0){
			updatedEditQuestions.forEach(q => {
				//Get original question
				const question = currentSelectedQuiz.questions.filter(cq => cq.uuid === q.uuid)[0]

				if ((q.question !== question.question) || (q.point !== question.point) || (JSON.stringify(q.responses) !== JSON.stringify(question.responses)) || q.responseExplication !== question.responseExplication){
					updatedQuestions.push(q)
				}

			})

			if (updatedQuestions.length > 0){
				await UserMoocQuizQuestionAPI.userUpdateMoocQuizQuestions(currentSelectedQuiz.uuid, updatedQuestions)

				updatedQuestions.forEach(q => {
					useMoocStore.getState().updateCurrentQuizQuestion(q)
					useMoocStore.getState().updateQuizQuestion(currentChapter.uuid, currentSelectedQuiz.uuid, q)
				})
			}
		}

		setEditState(false)
		// useMoocQuizStore.setState({testQuestions: structuredClone(currentSelectedQuiz.questions)})
	}

	const currentLayout = useMoocStore(state => state.currentCourseLayout)
	const storySidebarState = useMoocStore(state => state.storiesSidebarState)

	const handleCheckQuizResponse = (qIndex, rIndex) => {
		useMoocQuizStore.getState().checkTestQuestionResponse(qIndex, rIndex)
	}

	const handleValidQuiz = async () => {
		//Create empty array for quiz results
		const results = []

		if (testQuestions.length <= 0){
			return
		}


		for (let i = 0; i < testQuestions.length; i++) {
			const resultData = {}
			resultData.questionUuid = testQuestions[i].uuid
			resultData.userResponse = testQuestions[i].responses.filter(r => r.checked === true)[0]

			results.push(resultData)
		}

		if (results.some(r => r.userResponse === undefined)){
			createErrorToast("You need to check one answer for all question")
			return
		}

		//Send result to api
		const resultUuid = await UserMoocQuizResultAPI.userCreateQuizResult(currentSelectedQuiz.uuid, results)
		if (resultUuid) {
			const attempt = currentSelectedQuiz.userResult === null ? 1 : currentSelectedQuiz.userResult['attempt'] + 1
			//Update user result in store
			useMoocStore.getState().updateCurrentQuizUserResult({'uuid': resultUuid, 'results': results, 'attempt': attempt})
			useMoocStore.getState().updateQuizUserResult(currentChapter.uuid, currentSelectedQuiz.uuid, {'uuid': resultUuid, 'results': results, 'attempt': attempt})

			setCurrentScreen('results')
		}
	}

	const getSelectedStateForInput = (questionIndex, questionUuid, responseText) => {

		if (currentSelectedQuiz.userResult === null || currentSelectedQuiz.retryCountLeft > 0){
			if (useMoocQuizStore.getState().testQuestions[questionIndex].validate !== undefined){
				useMoocQuizStore.getState().updateTestQuestionValidateState(questionIndex, undefined)
			}
			return ''
		}

		//Get question result
		const questionResult = currentSelectedQuiz.userResult.results.filter(r => r.questionUuid === questionUuid)[0]
		if (!questionResult){
			return ''
		}

		if (questionResult.userResponse.checked && questionResult.userResponse.valid && responseText === questionResult.userResponse.text){
			if (useMoocQuizStore.getState().testQuestions[questionIndex].validate !== true){
				useMoocQuizStore.getState().updateTestQuestionValidateState(questionIndex, true)
			}
			return 'selected valid'
		} else if (questionResult.userResponse.checked && !questionResult.userResponse.valid && responseText === questionResult.userResponse.text) {
			if (useMoocQuizStore.getState().testQuestions[questionIndex].validate !== false){
				useMoocQuizStore.getState().updateTestQuestionValidateState(questionIndex, false)
			}
			return 'selected error'
		}
	}

	useEffect(() => {
		setEditState(false)
		setCurrentScreen('quiz')
	}, [currentSelectedQuiz])

	const [currentScreen, setCurrentScreen] = useState('quiz')

	const handleRestartQuiz = () => {
		useMoocStore.getState().updateCurrentQuizRetryLeft(1)
		useMoocStore.getState().updateQuizRetryLeft(currentChapter.uuid, currentSelectedQuiz.uuid, 1)
		setCurrentScreen('quiz')
	}

	const checkIfQuizIsCompleted = () => {
		const correctResponse = currentSelectedQuiz.userResult.results.filter(r => r.userResponse.valid === r.userResponse.checked).length
		const wrongResponse = currentSelectedQuiz.userResult.results.filter(r => r.userResponse.valid !== r.userResponse.checked).length
		const questionCount = currentSelectedQuiz.questions.length

		return (correctResponse / questionCount) * 100
	}

	if (currentSelectedQuiz !== null && currentSelectedMooc !== null && currentChapter){
		return (
			<div
				className={!storySidebarState ? ("mooc__course-sidebar-container large layout-" + currentLayout) : "mooc__course-sidebar-container layout-" + currentLayout}>
				<MoocCourseSidebarHeader type="quiz" currentCourse={currentSelectedQuiz} currentMooc={currentSelectedMooc} currentChapter={currentChapter} editState={editState} setEditState={setEditState} />
				<div className="quiz-container">
					{!editState && (
						<>
							{currentScreen === "quiz" && (
								<>
									{testQuestions.length > 0 && (
										<>
											{testQuestions.map((q, qIndex) => (
												<div key={qIndex} className="question-container">
													<p className="question-point">{q.point} point</p>
													<p className="question-text">{q.question}</p>
													<div className="responses-container">
														{q.responses.map((r, rIndex) => (
															<div className="response">
																<label>
																	<input className={getSelectedStateForInput(qIndex, q.uuid, r.text)} disabled={(currentSelectedQuiz.userResult !== null && currentSelectedQuiz.retryCountLeft === 0)} onChange={() => handleCheckQuizResponse(qIndex, rIndex)} checked={r.checked} type="radio" name={qIndex + "-check"}/>
																	<Latex>{r.text}</Latex>
																</label>
															</div>
														))}
													</div>
													{q.validate !== undefined && (
														<>
															<div className={q.validate ? "response-explication" : "response-explication error"}>
																{q.validate && (
																	<p className="icon">
																		<svg xmlns="http://www.w3.org/2000/svg" width="20"
																			 height="20"
																			 viewBox="0 0 20 20">
																			<path id="Tracé_3666" data-name="Tracé 3666"
																				  d="M46.756,38.91a.833.833,0,0,1,0,1.179l-5.833,5.833a.833.833,0,0,1-1.179,0l-2.5-2.5a.834.834,0,1,1,1.179-1.179l1.91,1.911,5.244-5.245a.833.833,0,0,1,1.179,0ZM52,33.667V50.333A1.667,1.667,0,0,1,50.333,52H33.667A1.667,1.667,0,0,1,32,50.333V33.667A1.667,1.667,0,0,1,33.667,32H50.333A1.667,1.667,0,0,1,52,33.667ZM50.333,50.333V33.667H33.667V50.333Z"
																				  transform="translate(-32 -32)"
																				  fill="#2dd7a7"/>
																		</svg>
																	</p>
																)}
																{!q.validate && (
																	<p className="icon">
																		<svg xmlns="http://www.w3.org/2000/svg" width="20"
																			 height="20" viewBox="0 0 20 20">
																			<path id="Tracé_3666" data-name="Tracé 3666"
																				  d="M52,33.667V50.333A1.667,1.667,0,0,1,50.333,52H33.667A1.667,1.667,0,0,1,32,50.333V33.667A1.667,1.667,0,0,1,33.667,32H50.333A1.667,1.667,0,0,1,52,33.667ZM50.333,50.333V33.667H33.667V50.333Z"
																				  transform="translate(-32 -32)"
																				  fill="#ff5252"/>
																		</svg>
																	</p>
																)}
																<p className="text">{q.responseExplication}</p>
															</div>
														</>
													)}

												</div>
											))}
										</>
									)}
								</>
							)}
							{currentScreen === "results" && (
								<>
									<div className="score-container">
										<div className="score">
											<p className="icon">
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
													 viewBox="0 0 20 20">
													<path id="Tracé_3666" data-name="Tracé 3666"
														  d="M46.756,38.91a.833.833,0,0,1,0,1.179l-5.833,5.833a.833.833,0,0,1-1.179,0l-2.5-2.5a.834.834,0,1,1,1.179-1.179l1.91,1.911,5.244-5.245a.833.833,0,0,1,1.179,0ZM52,33.667V50.333A1.667,1.667,0,0,1,50.333,52H33.667A1.667,1.667,0,0,1,32,50.333V33.667A1.667,1.667,0,0,1,33.667,32H50.333A1.667,1.667,0,0,1,52,33.667ZM50.333,50.333V33.667H33.667V50.333Z"
														  transform="translate(-32 -32)" fill="#2dd7a7"/>
												</svg>
											</p>
											<p className="text">Correct answers: {currentSelectedQuiz.userResult.results.filter(r => r.userResponse.valid === r.userResponse.checked).length}</p>
										</div>
										<div className="score error">
											<p className="icon">
												<svg xmlns="http://www.w3.org/2000/svg" width="20"
													 height="20" viewBox="0 0 20 20">
													<path id="Tracé_3666" data-name="Tracé 3666"
														  d="M52,33.667V50.333A1.667,1.667,0,0,1,50.333,52H33.667A1.667,1.667,0,0,1,32,50.333V33.667A1.667,1.667,0,0,1,33.667,32H50.333A1.667,1.667,0,0,1,52,33.667ZM50.333,50.333V33.667H33.667V50.333Z"
														  transform="translate(-32 -32)"
														  fill="#ff5252"/>
												</svg>
											</p>
											<p className="text">Wrong answers: {currentSelectedQuiz.userResult.results.filter(r => r.userResponse.valid !== r.userResponse.checked).length}</p>
										</div>
										{checkIfQuizIsCompleted() < currentSelectedQuiz.validPercent && (
											<p className="score-text">Sorry {useUserStore.getState().user.firstname} {useUserStore.getState().user.lastname}, your score is {currentSelectedQuiz.userResult.results.filter(r => r.userResponse.valid === r.userResponse.checked).length} correct answer or {checkIfQuizIsCompleted()}% to validate the evaluation the score must be {currentSelectedQuiz.validPercent}%.</p>
										)}
										{checkIfQuizIsCompleted() >= currentSelectedQuiz.validPercent && (
											<p className="score-text">Congratulations {useUserStore.getState().user.firstname} {useUserStore.getState().user.lastname}, your score is {currentSelectedQuiz.userResult.results.filter(r => r.userResponse.valid === r.userResponse.checked).length} correct answer or {checkIfQuizIsCompleted()}%, you have validated your evaluation, you can mark it as completed.</p>
										)}
										{checkIfQuizIsCompleted() < currentSelectedQuiz.validPercent && (
											<>
												{(currentSelectedQuiz.maxAttempt - currentSelectedQuiz.userResult['attempt']) > 0 && (
													<div className="restart-btn">
														<p className="icon">
															<svg xmlns="http://www.w3.org/2000/svg" width="19.438" height="18"
																 viewBox="0 0 19.438 18">
																<path id="Tracé_3668" data-name="Tracé 3668"
																	  d="M47.469,30.527v4.32a1.08,1.08,0,0,1-1.08,1.08h-4.32a1.08,1.08,0,1,1,0-2.16h1.539L41.9,32.2c-.012-.011-.023-.022-.034-.033a6.84,6.84,0,1,0-4.925,11.678h.09a6.8,6.8,0,0,0,4.694-1.865,1.08,1.08,0,0,1,1.484,1.571,8.951,8.951,0,0,1-6.178,2.454H36.9a9,9,0,1,1,6.469-15.385l1.934,1.769V30.527a1.08,1.08,0,0,1,2.16,0Z"
																	  transform="translate(-28.031 -28.008)" fill="#2953e8"/>
															</svg>
														</p>
														<p className="text" onClick={handleRestartQuiz}>Restart the evaluation ({currentSelectedQuiz.maxAttempt - currentSelectedQuiz.userResult['attempt']} left)</p>
													</div>
												)}
											</>
										)}
									</div>
								</>
							)}
						</>
					)}
					{editState && (
						<>
							<div className="edit-quiz-container">
								<p className="create-btn" onClick={handleAddNewEditQuestion}>Add new question</p>
								{editQuestions.length > 0 && (
									<>
										{editQuestions.map((q, index) => (
											<QuizQuestionCreation question={q} questionIndex={index}
																  currentChapter={currentChapter}
																  currentQuiz={currentSelectedQuiz}/>
										))}
									</>
								)}
							</div>
						</>
					)}
				</div>
				<div className="footer">
					<div className="btn-container">
						{editState && (
							<>
								<div className="btn inverted" onClick={() => {
									setEditState(false)
									useMoocQuizStore.getState().resetStore()
								}}>Cancel
								</div>
								<div className="btn" onClick={handleSaveQuiz}>Save</div>
							</>
						)}
						{!editState && (
							<>
								{(currentSelectedQuiz.userResult === null || currentSelectedQuiz.retryCountLeft > 0) && (
									<div className="btn" onClick={handleValidQuiz}>Check answer</div>
								)}
								{(currentSelectedQuiz.userResult !== null && currentSelectedQuiz.retryCountLeft === 0) && (
									<>
										{currentScreen === "quiz" && (
											<div className="btn" onClick={() => setCurrentScreen('results')}>View score</div>
										)}
										{currentScreen === "results" && (
											<div className="btn" onClick={() => setCurrentScreen("quiz")}>View answers</div>
										)}

									</>
								)}
							</>
						)}

					</div>
				</div>
			</div>
		)
	} else {
		return null
	}
}

export default MoocQuizSidebar