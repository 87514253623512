import React, {useEffect, useRef, useState} from "react";
import {VscChromeClose} from "react-icons/vsc";
import {FaArrowLeft, FaArrowRight, FaUserEdit} from "react-icons/fa";
import {useMoocStore} from "../../services/Stores/MoocStore";
import CourseModulesList from "./CourseModulesList";
import {useMapStore} from "../../services/Stores";
import UserMoocAPI from "../../services/API/User/Mooc/UserMoocAPI";
import {addNewMoocToStore} from "../../services/Utils/MoocUtils";
import {useMapContextMenuStore} from "../../services/Stores/MapContextMenuStore";
import ContextMenuItem from "../customs/content_menu/ContextMenuItem";
import {IoMdAdd, IoMdSettings} from "react-icons/io";
import ContextMenuContainer from "../customs/content_menu/ContextMenuContainer";
import {getContextMenuPosition, useOnClickOutside} from "../../services/Utils";
import {MdDelete} from "react-icons/md";
import {HiMiniChevronDoubleLeft} from "react-icons/hi2";
import MoocShareSidebar from "./MoocShareSidebar";
import StorySettingsPanel from "../customs/StorySettingsPanel";
import UsersOnMapAPI from "../../services/UsersOnMapAPI";
import UserMoocParticipantAPI from "../../services/API/User/Mooc/UserMoocParticipantAPI";
import {BsMortarboardFill} from "react-icons/bs";
import {PiBookBold} from "react-icons/pi";

const MoocStoriesSidebar = () => {
	//Get current user map authorizations
	const userAuthorizations = useMapStore(state => state.userAuthorizations)

	//Get stories sidebar open state
	const sidebarOpenState = useMoocStore(state => state.storiesSidebarState)
	const shareSidebarState = useMoocStore(state => state.shareSidebarState)

	//Get current opened course
	const currentOpenedMooc = useMoocStore(state => state.openedMooc)

	//Get moocs list in store
	const moocs = useMoocStore(state => state.mapMoocs)

	//Define default state for story settings panel
	const [storySettingsPanelState, setStorySettingsPanelState] = useState(false)

	//Open course
	const handleOpenCourse = (mooc) => {
		setStorySettingsPanelState(false)
		useMoocStore.setState({openedMooc: mooc})
	}

	//Close course
	const handleCloseCourse = () => {
		useMoocStore.setState({openedMooc: null, currentSelectedCourse: null, openedMoocChapters: []})
	}

	//Create mooc
	const handleCreateMooc = async () => {
		//Create mooc
		const newData = await UserMoocAPI.userCreateMooc(useMapStore.getState().mapUuid)

		if (newData) {
			const newMoocData = {...newData.data}
			newMoocData.userAuthorizations = newData.userAuthorizations
			useMoocStore.getState().addMoocToList(newMoocData)
		}
	}

	//Get all map mooc on load sidebar
	useEffect(() => {
		if (sidebarOpenState){
			UserMoocAPI.userGetMapMoocs(useMapStore.getState().mapUuid).then((response) => {
				if (response.status === 200){
					useMoocStore.setState({mapMoocs: response.data})
				} else {
					useMoocStore.setState({mapMoocs: []})
				}
			})
		}
	}, [sidebarOpenState])

	//Story context menu
	const [storyContextMenuState, setStoryContextMenuState] = useState(false)
	const [storyContextMenuUuid, setStoryContextMenuUuid] = useState(null)
	const storyContextMenuRef = useRef(null)

	const handleOpenStoryContextMenu = (event, uuid) => {
		event.preventDefault()
		event.stopPropagation()

		setStoryContextMenuUuid(uuid)
		setStoryContextMenuState(true)
		getContextMenuPosition(event, storyContextMenuRef.current)
	}

	const handleDeleteStory = async () => {
		if (await UserMoocAPI.userDeleteMooc(storyContextMenuUuid)){
			useMoocStore.getState().removeMooc(storyContextMenuUuid)
			setStoryContextMenuUuid(null)
		}
	}

	// useEffect(() => {
	// 	let checkMoocPresenceInterval = null
	//
	// 	if (currentOpenedMooc){
	// 		checkMoocPresenceInterval = setInterval(() => {
	// 			UserMoocParticipantAPI.userAddPresenceTime(currentOpenedMooc.uuid, 60)
	// 		}, (5 * 1000))
	// 	} else {
	// 		if (checkMoocPresenceInterval){
	// 			clearInterval(checkMoocPresenceInterval)
	// 			checkMoocPresenceInterval = null
	// 		}
	// 	}
	//
	//
	// 	return () => {
	// 		if (checkMoocPresenceInterval){
	// 			clearInterval(checkMoocPresenceInterval)
	// 		}
	// 	}
	// }, [currentOpenedMooc])

	if (sidebarOpenState){
		return (
			<>
				{(currentOpenedMooc && currentOpenedMooc.userAuthorizations.includes('mooc.manage')) && (
					<StorySettingsPanel state={storySettingsPanelState} setState={setStorySettingsPanelState}/>
				)}
				<ContextMenuContainer ref={storyContextMenuRef} state={storyContextMenuState} setState={setStoryContextMenuState} map={false}>
					<ContextMenuItem handler={() => {
						setStoryContextMenuState(false)
						handleDeleteStory().then()
					}} text="Delete story" icon={<MdDelete />}  />
				</ContextMenuContainer>
				<div className="mooc__stories-sidebar-container" >
					<div className="header">
						{currentOpenedMooc === null && (
							<h1 className="title">Stories</h1>
						)}
						{currentOpenedMooc !== null && (
							<div className="return-btn" onClick={handleCloseCourse}>
								<p className="icon"><FaArrowLeft /></p>
								<p className="text">Return to all storyline{moocs.length > 1 ? "s" : ""}</p>
							</div>
						)}
						<div className="right-icons">
							{currentOpenedMooc && (
								<>
									{currentOpenedMooc.userAuthorizations.includes('mooc.manage') && (
										<>
										<div className="close-icon"
											 onClick={() => storySettingsPanelState ? setStorySettingsPanelState(false) : setStorySettingsPanelState(true)}>
											<IoMdSettings size={18}/></div>
										</>
									)}
								</>
							)}
							<div className="close-icon"
								 onClick={() => useMoocStore.setState({storiesSidebarState: false})}>
								<HiMiniChevronDoubleLeft size={22}/></div>
						</div>
					</div>
					{currentOpenedMooc === null && (
						<div className="course-list-container">
							{userAuthorizations.includes('map.create_mooc') && (
								<div className="create-mooc-btn" onClick={handleCreateMooc}>Create new story</div>
							)}
							{moocs.length > 0 && (
								<>
									{moocs.map((mooc) => (
										<div className="course" onContextMenu={(event) => handleOpenStoryContextMenu(event, mooc.uuid)}>
											<div className="course-thumbnail">
												{mooc.thumbnail && (
													<img src={mooc.thumbnail} alt="Course thumbnail"/>
												)}
												{!mooc.thumbnail && (
													<div className="icon-thumbnail"><PiBookBold /></div>
												)}
											</div>
											<div className="course-infos">
												<p className="type free">{mooc.price <= 0 ? "free" : "premium"}</p>
												<p className="course-name">{mooc.name.slice(0, 30)} {mooc.name.length > 30 ? "..." : ""}</p>
												<div className="course-creator">
													{/*<div className="logo"><img src="https://placehold.co/400" alt="Course creator logo"/></div>*/}
													<p className="creator-name">{mooc.organisation}</p>
												</div>
												<p className="price">{mooc.price === null ? "0€" : mooc.price + "€"}</p>
											</div>
											<div className="course-action-btn" onClick={() => handleOpenCourse(mooc)}>
												<p className="text">Start</p>
												<p className="icon"><FaArrowRight /></p>
											</div>
										</div>
									))}
								</>
							)}
						</div>
					)}
					{currentOpenedMooc !== null && (
						<CourseModulesList />
					)}
				</div>
			</>
		)
	} else {
		return null
	}

}

export default MoocStoriesSidebar