import React, {useEffect, useState} from "react";
import icon_card_1 from "../../../icons/admin-dashboard/plans/card-1.svg";
import icon_card_2 from "../../../icons/admin-dashboard/plans/card-2.svg";
import icon_card_3 from "../../../icons/admin-dashboard/plans/card-3.svg";
import icon_card_4 from "../../../icons/admin-dashboard/plans/card-4.svg";
import {BiHistory} from "react-icons/bi";
import {BsThreeDots} from "react-icons/bs";
import {FaCheck, FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp, FaSort, FaSortDown, FaSortUp} from "react-icons/fa";
import {IoMdAdd, IoMdClose} from "react-icons/io";
import {IoSearchSharp} from "react-icons/io5";
import ShowOrHideColumnMenu from "../../components/ShowOrHideColumnMenu";
import Export from "../../components/Export";
import SubscriptionPlansAPI from "../../services/SubscriptionPlansAPI";
import {TailSpin} from "react-loader-spinner";
import DeleteModal from "../../components/DeleteModal";
import success_send from "../../../icons/admin-dashboard/emails/success-send.svg";
import Chart from "react-apexcharts";
import StatsAPI from "../../services/StatsAPI";
import PlansDashboardStatCard from "../../components/PlansDashboardStatCard";
import {toast} from "react-toastify";
import PromoCodeAPI from "../../services/PromoCodeAPI";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";

const AdminDashboardPlans = () => {
    //Define default state for get plans list request
    const [getPlansRequestStatus, setGetPlansRequestStatus] = useState(false)

    //Define default state for plans list
    const [plans, setPlans] = useState([{
        "id": "",
        "name": "",
        "monthlyPriceHT": 0,
        "monthlyPriceTTC": 0,
        "yearlyPriceHT": 0,
        "yearlyPriceTTC": 0,
        "storageLimit": 0,
        "guestLimit": 0,
        "weeklyExportLimit": 0,
        "subscriptionWithPlan": 0,
        "custom": false,
        "customCode": ""
    }])

    //Define default state for promo code list
    const [promoCode, setPromoCode] = useState([{
        "uuid": "",
        "code": "",
        "discountType": "",
        "discount": "",
        'durationInMonth': "",
        'trialDay': '',
        "expirationDate": ""
    }])

    //Define default state for stats list
    const [stats, setStats] = useState({
        "totalBreakdownOfAllAccountTypeLabels": [],
        "totalBreakdownOfAllAccountTypeSeries": [],
        "totalBreakdownOfOfferedAccountLabels": [],
        "totalBreakdownOfOfferedAccountSeries": [],
        "card-1": {
            "totalObjectsCount": 0,
            "averageObjectCountRelatedToBasicPlan": 0,
            "averageObjectCountRelatedToStandardPlan": 0,
            "averageObjectCountRelatedToProPlan": 0,
            "averageObjectCountRelatedToEnterprisePlan": 0,
            "averageObjectCountRelatedToCustomPlan": 0
        },
        "card-2": {
            "totalDataUsage": "0",
            "dataUsageWithBasicPlan": "0",
            "dataUsageWithStandardPlan": "0",
            "dataUsageWithProPlan": "0",
            "dataUsageWithEnterprisePlan": "0",
            "dataUsageWithCustomPlan": "0"
        },
        "card-3": {
            "totalInvitedUser": 0,
            "totalInvitedUserWithBasicPlan": 0,
            "totalInvitedUserWithStandardPlan": 0,
            "totalInvitedUserWithProPlan": 0,
            "totalInvitedUserWithEnterprisePlan": 0,
            "totalInvitedUserWithCustomPlan": 0
        },
        "card-4": {
            "totalMapsCount": 0,
            "averageMapCountRelatedToBasicPlan": 0,
            "averageMapCountRelatedToStandardPlan": 0,
            "averageMapCountRelatedToProPlan": 0,
            "averageMapCountRelatedToEnterprisePlan": 0,
            "averageMapCountRelatedToCustomPlan": 0
        },
        "chart-3": {
            "labels": [],
            "basicAccountData": [],
            "standardAccountData": [],
            "proAccountData": [],
            "enterpriseAccountData": [],
            "customAccountData": []
        }
    })

    //Get plans list and stats and promo code
    useEffect(() => {
        // SubscriptionPlansAPI.adminGetAll().then((response) => {
        //     if (response.status === 200){
        //         setPlans(response.data)
        //     }
        //     setGetPlansRequestStatus(true)
        // })
        // StatsAPI.getStatsForSubscriptionPlanDashboard().then((response) => {
        //     setStats(response.data)
        // })
        setGetPlansRequestStatus(true)


        PromoCodeAPI.adminGetAllPromoCode().then((response) => {
            if (response.status === 200){
                setPromoCode(response.data)
            } else {
                setPromoCode([])
            }
        })

    }, [])

    /*************************SHOW/HIDE COLUMN********************************/
    const [showHideStatus, setShowHideStatus] = useState(false)
    const [columnList, setColumnList] = useState({
        "monthly price ht": true,
        "monthly price ttc": true,
        "yearly price ht": true,
        "yearly price ttc": true,
        "storage limit": true,
        "guests": true,
        "backup cap": true,
        "users": true,
        "custom code": true
    })

    const resetShowOrHide = () => {
        setColumnList({
            "monthly price ht": true,
            "monthly price ttc": true,
            "yearly price ht": true,
            "yearly price ttc": true,
            "storage limit": true,
            "guests": true,
            "backup cap": true,
            "users": true,
            "custom code": true
        })
    }
    /*************************************************************************/

    /********************************SEARCH***********************************/
    const [search, setSearch] = useState("")

    const filteredPlans = plans.filter(p =>
        String(p.name).toLowerCase().includes(search.toLowerCase()) ||
        String(p.monthlyPriceHT).toLowerCase().includes(search.toLowerCase()) ||
        String(p.monthlyPriceTTC).toLowerCase().includes(search.toLowerCase()) ||
        String(p.yearlyPriceHT).toLowerCase().includes(search.toLowerCase()) ||
        String(p.yearlyPriceTTC).toLowerCase().includes(search.toLowerCase()) ||
        String(p.storageLimit).toLowerCase().includes(search.toLowerCase()) ||
        String(p.guestLimit).toLowerCase().includes(search.toLowerCase()) ||
        String(p.weeklyExportLimit).toLowerCase().includes(search.toLowerCase()) ||
        String(p.subscriptionWithPlan).toLowerCase().includes(search.toLowerCase())

    )
    /*************************************************************************/

    /*****************************PAGINATION**********************************/
    const [currentPage, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const maxPage = Math.ceil(filteredPlans.length / itemPerPage)

    const nextPage = () => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }
    const previousPage = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }

    const start = (currentPage - 1) * itemPerPage
    const end = start + itemPerPage
    const paginatedPlans = filteredPlans.slice(start, end)

    const pageButtons = []

    for (let i = 0; i < maxPage; i++){
        pageButtons.push(<p onClick={() => setCurrentPage(i + 1)} className={currentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************PAGINATION PROMO CODE***********************/
    const [currentPagePromoCode, setCurrentPagePromoCode] = useState(1)
    const [itemPerPagePromoCode, setItemPerPagePromoCode] = useState(10)
    const maxPagePromoCode = Math.ceil(promoCode.length / itemPerPagePromoCode)

    const nextPagePromoCode = () => {
        setCurrentPagePromoCode((currentPagePromoCode) => Math.min(currentPagePromoCode + 1, maxPagePromoCode))
    }
    const previousPagePromoCode = () => {
        setCurrentPagePromoCode((currentPagePromoCode) => Math.max(currentPagePromoCode - 1, 1))
    }

    const startPromoCode = (currentPagePromoCode - 1) * itemPerPagePromoCode
    const endPromoCode = startPromoCode + itemPerPagePromoCode
    const paginatedPromoCode = promoCode.slice(startPromoCode, endPromoCode)

    const pageButtonsPromoCode = []

    for (let i = 0; i < maxPagePromoCode; i++){
        pageButtonsPromoCode.push(<p onClick={() => setCurrentPagePromoCode(i + 1)} className={currentPagePromoCode === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************DELETE PLAN*********************************/
    const [deletePlanModalStatus, setDeletePlanModalStatus] = useState(false)
    const [deletePlanStatus, setDeletePlanStatus] = useState(false)
    const [deletedPlanId, setDeletedPlanId] = useState(0)

    const deletePlan = async () => {
        if (deletedPlanId !== 0){
            setDeletePlanModalStatus(false)
            if (await SubscriptionPlansAPI.deleteSubscriptionPlan(deletedPlanId)){
                toast.success("Subscription plan deleted successfully")
                setDeletePlanStatus(true)
                SubscriptionPlansAPI.adminGetAll().then((response) => {
                    if (response.status === 200){
                        setPlans(response.data)
                    } else if (response.status === 204) {
                        setPlans([{
                            "id": "",
                            "name": "",
                            "monthlyPriceHT": 0,
                            "monthlyPriceTTC": 0,
                            "yearlyPriceHT": 0,
                            "yearlyPriceTTC": 0,
                            "storageLimit": 0,
                            "guestLimit": 0,
                            "weeklyExportLimit": 0,
                            "subscriptionWithPlan": 0,
                            "custom": false,
                            "customCode": ""
                        }])
                    }
                    setGetPlansRequestStatus(true)
                })
            }
        }
    }
    /*************************************************************************/

    /******************************ADD NEW PLAN*******************************/
    const [addPlanModalStatus, setAddPlanModalStatus] = useState(false)
    const [addPlanStatus, setAddPlanStatus] = useState(false)
    const [newPlanInfo, setNewPlanInfo] = useState({
        "name": "",
        "monthlyPriceHT": 0,
        "yearlyPriceHT": 0,
        "storageLimit": 1,
        "guestLimit": 0,
        "weeklyBackupLimit": 0,
        "customCode": ""
    })

    const addPlan = async () => {
        if (newPlanInfo.name !== ""){
            if (await SubscriptionPlansAPI.addNewSubscriptionPlan(newPlanInfo)){
                setAddPlanModalStatus(false)
                setNewPlanInfo({
                    "name": "",
                    "monthlyPriceHT": 0,
                    "yearlyPriceHT": 0,
                    "storageLimit": 1,
                    "guestLimit": 0,
                    "weeklyBackupLimit": 0,
                    "customCode": ""
                })
                setGetPlansRequestStatus(false)
                toast.success("Subscription plan created successfully")
                SubscriptionPlansAPI.adminGetAll().then((response) => {
                    if (response.status === 200){
                        setPlans(response.data)
                    }
                    setGetPlansRequestStatus(true)
                })
            }
        }
    }
    /*************************************************************************/

    /******************************UPDATE PLAN********************************/
    const [updatePlanModalStatus, setUpdatePlanModalStatus] = useState(false)
    const [updatePlanStatus, setUpdatePlanStatus] = useState(false)
    const [updatedPlanId, setUpdatedPlanId] = useState(0)
    const [updatedPlanInfo, setUpdatedPlanInfo] = useState({
        "id": "",
        "name": "",
        "monthlyPriceHT": 0,
        "monthlyPriceTTC": 0,
        "yearlyPriceHT": 0,
        "yearlyPriceTTC": 0,
        "storageLimit": 0,
        "guestLimit": 0,
        "weeklyExportLimit": 0,
        "subscriptionWithPlan": 0,
        "custom": false,
        "customCode": ""
    })

    const updatePlan = async () => {
        if (updatedPlanInfo.name !== ""){
            setUpdatePlanModalStatus(false)
            if (await SubscriptionPlansAPI.updateSubscriptionPlan(updatedPlanInfo)){
                toast.success("Subscription plan updated successfully")
                setGetPlansRequestStatus(false)
                SubscriptionPlansAPI.adminGetAll().then((response) => {
                    if (response.status === 200){
                        setPlans(response.data)
                    }
                    setGetPlansRequestStatus(true)
                })
            }
        }
    }

    useEffect(() => {
        if (updatedPlanId !== 0){
            setUpdatedPlanInfo(plans.filter(p => p.id === updatedPlanId)[0])
        }
    }, [updatedPlanId])

    /*************************************************************************/

    /*******************************METRIC CARD STATUS************************/
    const [totalBreakdownOfAllAccountTypeMetricCardStatus, setTotalBreakdownOfAllAccountTypeMetricCardStatus] = useState(false)
    const [totalBreakdownOfInvitedAccountMetricCardStatus, setTotalBreakdownOfInvitedAccountMetricCardStatus] = useState(false)
    const [chart3Status, setChart3Status] = useState(false)
    /*************************************************************************/

    /****************************ADMIN CARD***********************************/
    const [card1SelectedPlan, setCard1SelectedPlan] = useState("All")
    const [card2SelectedPlan, setCard2SelectedPlan] = useState("All")
    const [card3SelectedPlan, setCard3SelectedPlan] = useState("All")
    const [card4SelectedPlan, setCard4SelectedPlan] = useState("All")
    /*************************************************************************/

    const [openedArraySubMenuPlanId, setOpenedSubMenuPlanId] = useState(0)
    const [openedArraySubMenuCodeId, setOpenedSubMenuCodeId] = useState(0)

    /*******************************SORT MAP**********************************/
    const [sortedColumnName, setSortedColumnName] = useState("")
    const [sortedType, setSortedType] = useState("")

    if (sortedColumnName !== "" && sortedType !== ""){
        if (sortedType === "1"){
            paginatedPlans.sort((a, b) => String(a[sortedColumnName]).localeCompare(String(b[sortedColumnName])))
        }
        if (sortedType === "2") {
            paginatedPlans.sort((a, b) => String(b[sortedColumnName]).localeCompare(String(a[sortedColumnName])))
        }
    }
    /*************************************************************************/

    /******************************CREATE PROMO CODE**************************/
    const [createPromoCodeModalStatus, setCreatePromoCodeModalStatus] = useState(false)
    const [newPromoCodeInfo, setNewPromoCodeInfo] = useState({
        "code": "",
        "expirationDate": "",
        "discount": "",
        "discountType": "%",
        "durationInMonth": "1",
        "trialDay": ""
    })

    const createCode = async () => {
        if (newPromoCodeInfo.code === ""){
            toast.error("Please fill code input")
            return
        }
        if (newPromoCodeInfo.expirationDate === ""){
            toast.error("Please select expiration date")
            return
        }
        if (newPromoCodeInfo.discount === ""){
            toast.error("Please fill discount percentage input")
            return
        }
        if (newPromoCodeInfo.trialDay === ""){
            toast.error("Please fill trial day")
            return
        }

        if (await PromoCodeAPI.adminCreatePromoCode(newPromoCodeInfo)){
            toast.success("New promotion code created")
            setCreatePromoCodeModalStatus(false)
            setNewPromoCodeInfo({
                "code": "",
                "expirationDate": "",
                "discount": "",
                "discountType": "%",
                "durationInMonth": "1",
                "trialDay": ""
            })

            PromoCodeAPI.adminGetAllPromoCode().then((response) => {
                if (response.status === 200){
                    setPromoCode(response.data)
                } else {
                    setPromoCode([])
                }
            })
        }
    }
    /*************************************************************************/

    /******************************DELETE PROMOTION CODE**********************/
    const deletePromotionCode = async (event) => {
        const codeId = event.currentTarget.id

        if (await PromoCodeAPI.deleteCode(codeId)){
            toast.success("Promotion code deleted successfully")

            PromoCodeAPI.getAllPromoCode().then((response) => {
                if (response.status === 200){
                    setPromoCode(response.data)
                } else {
                    setPromoCode([])
                }
            })
        }
    }
    /*************************************************************************/

    const dashboard = document.getElementById("scroll-container")

    return (
        <>
            {/*DELETE PLAN MODAL*/}
            {deletePlanModalStatus && (
                <DeleteModal
                    modalStatus={deletePlanModalStatus}
                    setModalStatus={setDeletePlanModalStatus}
                    requestStatus={deletePlanStatus}
                    setRequestStatus={setDeletePlanStatus}
                    deleteMethod={deletePlan}
                    title={"Delete *" + plans.filter(p => p.id === deletedPlanId)[0].name + "*"}
                    alertMessage={"You are about to remove *" + plans.filter(p => p.id === deletedPlanId)[0].name + "* from the list of plans you can offer to users. Do you confirm ?"}
                    successMessage={"*" + plans.filter(p => p.id === deletedPlanId)[0].name + "* successfully deleted"}
                />
            )}
            {/*ADD NEW PLAN MODAL*/}
            {addPlanModalStatus && (
                <div className="modal-overlay">
                    <div className={addPlanModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Create new custom plan</h1>
                            <p onClick={() => {
                                setAddPlanModalStatus(false)
                                setAddPlanStatus(false)
                                setNewPlanInfo({
                                    "name": "",
                                    "monthlyPriceHT": 0,
                                    "yearlyPriceHT": 0,
                                    "storageLimit": 1,
                                    "guestLimit": 0,
                                    "weeklyBackupLimit": 0,
                                    "customCode": ""
                                })
                            }}><IoMdClose /></p>
                        </div>
                        {!addPlanStatus && (
                            <div className="content create-template">
                                <div className="input-container">
                                    <div className="input-box">
                                        <p className="title">Plan name</p>
                                        <input placeholder="Input a name for the new plan" className={newPlanInfo.name.length > 0 ? "text-input active" : "text-input"} type="text" value={newPlanInfo.name} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['name']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Plan code</p>
                                        <input placeholder="Input a code for the new plan" className={newPlanInfo.customCode.length > 0 ? "text-input active" : "text-input"} type="text" value={newPlanInfo.customCode} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['customCode']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Monthly charge (€ - EXCL. TAXES)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={1000} value={newPlanInfo.monthlyPriceHT} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['monthlyPriceHT']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={newPlanInfo.monthlyPriceHT} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['monthlyPriceHT']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Yearly charge (€ - EXCL. TAXES)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={5000} value={newPlanInfo.yearlyPriceHT} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['yearlyPriceHT']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={newPlanInfo.yearlyPriceHT} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['yearlyPriceHT']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Storage limit (Gb)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={1} max={1000} value={newPlanInfo.storageLimit} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['storageLimit']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={newPlanInfo.storageLimit} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['storageLimit']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Number of guests allowed</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={50} value={newPlanInfo.guestLimit} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['guestLimit']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={newPlanInfo.guestLimit} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['guestLimit']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Back-up cap (Per Week)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={20} value={newPlanInfo.weeklyBackupLimit} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['weeklyBackupLimit']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={newPlanInfo.weeklyBackupLimit} onChange={(event) => setNewPlanInfo({...newPlanInfo, ['weeklyBackupLimit']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <div className="btn cancel" onClick={() => {
                                        setAddPlanModalStatus(false)
                                        setAddPlanStatus(false)
                                        setNewPlanInfo({
                                            "name": "",
                                            "monthlyPriceHT": 0,
                                            "yearlyPriceHT": 0,
                                            "storageLimit": 1,
                                            "guestLimit": 0,
                                            "weeklyBackupLimit": 0,
                                            "customCode": ""
                                        })
                                    }}>
                                        <p className="text">Cancel</p>
                                        <p className="icon"><IoMdClose /></p>
                                    </div>
                                    <div className="btn valid" onClick={addPlan}>
                                        <p className="text">Confirm </p>
                                        <p className="icon"><FaCheck /></p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {addPlanStatus && (
                            <div className="content delete-template-mail">
                                <div className="content create-template-mail">
                                    <div className="success-container">
                                        <img src={success_send} alt="Success send icon"/>
                                        <h1 className="message">Plan successfully created</h1>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {/*ADD PROMO CODE MODAL*/}
            {createPromoCodeModalStatus && (
                <div className="modal-overlay">
                    <div className={createPromoCodeModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Create new promotion code</h1>
                            <p onClick={() => {
                                setCreatePromoCodeModalStatus(false)
                                setNewPromoCodeInfo({
                                    "code": "",
                                    "expirationDate": "",
                                    "discount": "",
                                    "discountType": "%",
                                    "durationInMonth": "1",
                                    "trialDay": ""
                                })
                            }}><IoMdClose /></p>
                        </div>
                            <div className="content create-template">
                                <div className="input-container">
                                    <div className="input-box">
                                        <p className="title">Code</p>
                                        <input placeholder="New promotion code" className={newPromoCodeInfo.code.length > 0 ? "text-input active" : "text-input"} type="text" value={newPromoCodeInfo.code} onChange={(event) => setNewPromoCodeInfo({...newPromoCodeInfo, ['code']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Discount</p>
                                        <input placeholder="New promotion code discount" className={newPromoCodeInfo.discount.length > 0 ? "text-input active" : "text-input"} type="text" value={newPromoCodeInfo.discount} onChange={(event) => setNewPromoCodeInfo({...newPromoCodeInfo, ['discount']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Trial day</p>
                                        <input placeholder="Trial day" className={newPromoCodeInfo.trialDay.length > 0 ? "text-input active" : "text-input"} type="text" value={newPromoCodeInfo.trialDay} onChange={(event) => setNewPromoCodeInfo({...newPromoCodeInfo, ['trialDay']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="selector-box">
                                        <p className="title">Discount type</p>
                                        <div className="selector">
                                            <p onClick={() => newPromoCodeInfo.discountType !== "%" ? setNewPromoCodeInfo({...newPromoCodeInfo, ['discountType']: "%"}) : null} className={newPromoCodeInfo.discountType === "%" ? "active" : null}>%</p>
                                            <p onClick={() => newPromoCodeInfo.discountType !== "€" ? setNewPromoCodeInfo({...newPromoCodeInfo, ['discountType']: "€"}) : null} className={newPromoCodeInfo.discountType === "€" ? "active" : null}>€</p>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Expiration date</p>
                                        <input className={newPromoCodeInfo.expirationDate.length > 0 ? "text-input active" : "text-input"} type="date" value={newPromoCodeInfo.expirationDate} onChange={(event) => setNewPromoCodeInfo({...newPromoCodeInfo, ['expirationDate']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Maximum use</p>
                                        <select className="select-input" value={newPromoCodeInfo.durationInMonth} onChange={(event) => setNewPromoCodeInfo({...newPromoCodeInfo, ['durationInMonth']: event.currentTarget.value})}>
                                            <option value="1">1 Month</option>
                                            <option value="2">2 Month</option>
                                            <option value="3">3 Month</option>
                                            <option value="4">4 Month</option>
                                            <option value="5">5 Month</option>
                                            <option value="6">6 Month</option>
                                            <option value="7">7 Month</option>
                                            <option value="8">8 Month</option>
                                            <option value="9">9 Month</option>
                                            <option value="10">10 Month</option>
                                            <option value="11">11 Month</option>
                                            <option value="12">12 Month</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <div className="btn cancel" onClick={() => {
                                        setCreatePromoCodeModalStatus(false)
                                        setNewPromoCodeInfo({
                                            "code": "",
                                            "expirationDate": "",
                                            "discount": "",
                                            "discountType": "%",
                                            "maxUse": "1"
                                        })
                                    }}>
                                        <p className="text">Cancel</p>
                                        <p className="icon"><IoMdClose /></p>
                                    </div>
                                    <div className="btn valid" onClick={createCode}>
                                        <p className="text">Confirm</p>
                                        <p className="icon"><FaCheck /></p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            )}
            {/*UPDATE PLAN MODAL*/}
            {updatePlanModalStatus && (
                <div className="modal-overlay">
                    <div className={updatePlanModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Update *{updatedPlanInfo.name}* subscription plan</h1>
                            <p onClick={() => {
                                setUpdatePlanModalStatus(false)
                                setUpdatePlanStatus(false)
                                setUpdatedPlanId(0)
                            }}><IoMdClose /></p>
                        </div>
                        {!updatePlanStatus && (
                            <div className="content create-template">
                                <div className="input-container">
                                    <div className="input-box">
                                        <p className="title">Plan name</p>
                                        <input disabled={!updatedPlanInfo.custom} placeholder="Input a name for the new plan" className={updatedPlanInfo.name.length > 0 ? "text-input active" : "text-input"} type="text" value={updatedPlanInfo.name} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['name']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Monthly charge (€ - EXCL. TAXES)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={1000} value={updatedPlanInfo.monthlyPriceHT} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['monthlyPriceHT']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={updatedPlanInfo.monthlyPriceHT} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['monthlyPriceHT']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Yearly charge (€ - EXCL. TAXES)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={5000} value={updatedPlanInfo.yearlyPriceHT} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['yearlyPriceHT']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={updatedPlanInfo.yearlyPriceHT} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['yearlyPriceHT']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Storage limit (Gb)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={1} max={1000} value={updatedPlanInfo.storageLimit} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['storageLimit']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={updatedPlanInfo.storageLimit} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['storageLimit']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Number of guests allowed</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={50} value={updatedPlanInfo.guestLimit} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['guestLimit']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={updatedPlanInfo.guestLimit} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['guestLimit']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Back-up cap (Per Week)</p>
                                        <div className="input">
                                            <input className="range-input" type="range" min={0} max={20} value={updatedPlanInfo.weeklyExportLimit} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['weeklyExportLimit']: parseInt(event.currentTarget.value)})}/>
                                            <input className="value-input" type="number" value={updatedPlanInfo.weeklyExportLimit} onChange={(event) => setUpdatedPlanInfo({...updatedPlanInfo, ['weeklyExportLimit']: parseInt(event.currentTarget.value !== "" ? event.currentTarget.value : 0)})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <div className="btn cancel" onClick={() => {
                                        setUpdatePlanModalStatus(false)
                                        setUpdatePlanStatus(false)
                                        setUpdatedPlanId(0)
                                    }}>
                                        <p className="text">Cancel</p>
                                        <p className="icon"><IoMdClose /></p>
                                    </div>
                                    <div className="btn valid" onClick={updatePlan}>
                                        <p className="text">Confirm </p>
                                        <p className="icon"><FaCheck /></p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {updatePlanStatus && (
                            <div className="content delete-template-mail">
                                <div className="content create-template-mail">
                                    <div className="success-container">
                                        <img src={success_send} alt="Success send icon"/>
                                        <h1 className="message">Plan successfully updated</h1>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-dashboard" id="dashboard">
                    <h1 className="page-title">Overview</h1>
                    <div className="card-container">
                        <PlansDashboardStatCard icon={icon_card_1} title="Average object count per plan" value={
                            card1SelectedPlan === "All" ? stats["card-1"].totalObjectsCount + " Objects" :
                            card1SelectedPlan === "Basic" ? stats["card-1"].averageObjectCountRelatedToBasicPlan + " Objects" :
                            card1SelectedPlan === "Standard" ? stats["card-1"].averageObjectCountRelatedToStandardPlan + " Objects" :
                            card1SelectedPlan === "Pro" ? stats["card-1"].averageObjectCountRelatedToProPlan + " Objects" :
                            card1SelectedPlan === "Enterprise" ? stats["card-1"].averageObjectCountRelatedToEnterprisePlan + " Objects" :
                            card1SelectedPlan === "Custom" ? stats["card-1"].averageObjectCountRelatedToCustomPlan + " Objects" : null
                        } augment={false} augmentPercent={0} selectedPlan={card1SelectedPlan} setSelectedPlan={setCard1SelectedPlan}/>
                        <PlansDashboardStatCard icon={icon_card_2} title="Data usage per plan" value={
                            card2SelectedPlan === "All" ? stats["card-2"].totalDataUsage :
                            card2SelectedPlan === "Basic" ? stats["card-2"].dataUsageWithBasicPlan :
                            card2SelectedPlan === "Standard" ? stats["card-2"].dataUsageWithStandardPlan :
                            card2SelectedPlan === "Pro" ? stats["card-2"].dataUsageWithProPlan :
                            card2SelectedPlan === "Enterprise" ? stats["card-2"].dataUsageWithEnterprisePlan :
                            card2SelectedPlan === "Custom" ? stats["card-2"].dataUsageWithCustomPlan : "0"
                        } augment={false} augmentPercent={0} selectedPlan={card2SelectedPlan} setSelectedPlan={setCard2SelectedPlan}/>
                        <PlansDashboardStatCard icon={icon_card_3} title="Average number of invited users" value={
                            card3SelectedPlan === "All" ? stats["card-3"].totalInvitedUser + " Guests" :
                            card3SelectedPlan === "Basic" ? stats["card-3"].totalInvitedUserWithBasicPlan + " Guests" :
                            card3SelectedPlan === "Standard" ? stats["card-3"].totalInvitedUserWithStandardPlan + " Guests" :
                            card3SelectedPlan === "Pro" ? stats["card-3"].totalInvitedUserWithProPlan + " Guests" :
                            card3SelectedPlan === "Enterprise" ? stats["card-3"].totalInvitedUserWithEnterprisePlan + " Guests" :
                            card3SelectedPlan === "Custom" ? stats["card-3"].totalInvitedUserWithCustomPlan + " Guests" : null
                        } augment={false} augmentPercent={0} selectedPlan={card3SelectedPlan} setSelectedPlan={setCard3SelectedPlan}/>
                        <PlansDashboardStatCard icon={icon_card_4} title="Average map count per plan" value={
                            card4SelectedPlan === "All" ? stats["card-4"].totalMapsCount + " Maps" :
                            card4SelectedPlan === "Basic" ? stats["card-4"].averageMapCountRelatedToBasicPlan + " Maps" :
                            card4SelectedPlan === "Standard" ? stats["card-4"].averageMapCountRelatedToStandardPlan + " Maps" :
                            card4SelectedPlan === "Pro" ? stats["card-4"].averageMapCountRelatedToProPlan + " Maps" :
                            card4SelectedPlan === "Enterprise" ? stats["card-4"].averageMapCountRelatedToEnterprisePlan + " Maps" :
                            card4SelectedPlan === "Custom" ? stats["card-4"].averageMapCountRelatedToCustomPlan + " Maps" : null
                        } augment={false} augmentPercent={0} selectedPlan={card4SelectedPlan} setSelectedPlan={setCard4SelectedPlan}/>
                    </div>
                    <div className="metric-container">
                        <div className={totalBreakdownOfAllAccountTypeMetricCardStatus ? "metric-card open" : "metric-card"}>
                            <div className="top">
                                <p>Total breakdown of all account type</p>
                                <p onClick={() => setTotalBreakdownOfAllAccountTypeMetricCardStatus(!totalBreakdownOfAllAccountTypeMetricCardStatus)}>
                                    {totalBreakdownOfAllAccountTypeMetricCardStatus
                                        ? <FaChevronUp />
                                        : <FaChevronDown />
                                    }
                                </p>
                            </div>
                            <div className="content">
                                <Chart options={{
                                    chart: {
                                        type: 'donut'
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    colors: ["#3737e2", "#7878e1", "#a8a8ec", "#cd71f3", "#a41ae6"],
                                    plotOptions: {
                                        pie: {
                                            expandOnClick: false
                                        }
                                    },
                                    labels: stats.totalBreakdownOfAllAccountTypeLabels
                                }} series={stats.totalBreakdownOfAllAccountTypeSeries} widht="100%" height={350} type="donut"
                                 children={null}/>
                            </div>
                        </div>
                        <div className={totalBreakdownOfInvitedAccountMetricCardStatus ? "metric-card open" : "metric-card"}>
                            <div className="top">
                                <p>Total breakdown of offered accounts</p>
                                <p onClick={() => setTotalBreakdownOfInvitedAccountMetricCardStatus(!totalBreakdownOfInvitedAccountMetricCardStatus)}>
                                    {totalBreakdownOfInvitedAccountMetricCardStatus
                                        ? <FaChevronUp />
                                        : <FaChevronDown />
                                    }
                                </p>
                            </div>
                            <div className="content">
                                <Chart options={{
                                    chart: {
                                        type: 'donut'
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    colors: ["#3737e2", "#7878e1", "#a8a8ec", "#cd71f3", "#a41ae6"],
                                    plotOptions: {
                                        pie: {
                                            expandOnClick: false
                                        }
                                    },
                                    labels: stats.totalBreakdownOfOfferedAccountLabels
                                }} series={stats.totalBreakdownOfOfferedAccountSeries} widht="100%" height={350} type="donut"
                                 children={null}/>
                            </div>
                        </div>
                        <div className={chart3Status ? "metric-card open": "metric-card"}>
                            <div className="top">
                                <p>Breakdown of all paying accounts over time</p>
                                <p onClick={() => setChart3Status(!chart3Status)}>
                                    {chart3Status
                                        ? <FaChevronUp />
                                        : <FaChevronDown />
                                    }
                                </p>
                            </div>
                            <div className="content">
                                <Chart options={{
                                    chart: {
                                        type: 'line',
                                        zoom: {enabled: false}
                                    },
                                    stroke: {curve: 'smooth', width: "3"},
                                    labels: stats["chart-3"].labels,
                                    colors: ["#3737e2", "#7878e1", "#a8a8ec", "#cd71f3", "#a41ae6"],
                                    legend: {position: "top", fontFamily: "Roboto, sans-serif", fontWeight: "400", fontsize: "12px", itemMargin: {horizontal: 25}}
                                }} series={[
                                    {
                                        name: "Basic",
                                        data: stats["chart-3"].basicAccountData
                                    },
                                    {
                                        name: "Standard",
                                        data: stats["chart-3"].standardAccountData
                                    },
                                    {
                                        name: "Pro",
                                        data: stats["chart-3"].proAccountData
                                    },
                                    {
                                        name: "Enterprise",
                                        data: stats["chart-3"].enterpriseAccountData
                                    },
                                    {
                                        name: "Custom",
                                        data: stats["chart-3"].customAccountData
                                    }
                                ]} widht="100%" height={350} type="line"
                                 children={null}/>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of plans</p>
                        <div className="top-container">
                            <div className="left">
                                <ShowOrHideColumnMenu resetColumn={resetShowOrHide} columnList={columnList} setColumnList={setColumnList} open={showHideStatus} setOpen={setShowHideStatus} />
                                <div className="search-container">
                                    <div className="box">
                                        <div className="icon"><IoSearchSharp /></div>
                                        <input type="text" placeholder="Search by map name" value={search} onChange={(event) => setSearch(event.currentTarget.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="btn" onClick={() => {
                                    setAddPlanStatus(false)
                                    setAddPlanModalStatus(true)
                                }}>
                                    <div className="icon"><IoMdAdd /></div>
                                <div className="text">Create custom plan</div>
                                </div>
                                <div className="btn">
                                    <div className="icon"><BiHistory /></div>
                                    <div className="text">History</div>
                                </div>
                                <Export data={plans} filename="weeki_subscription_plans" />
                            </div>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td className="left large" onClick={() => {
                                    setSortedColumnName("name")
                                    if (sortedType === ""){
                                        setSortedType("1")
                                    } else if (sortedType === "1"){
                                        setSortedType("2")
                                    } else {
                                        setSortedType("")
                                    }
                                }}>
                                    plan name
                                    <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "name" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "name" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "name" ? <FaSort /> : null}
                                        </span>
                                </td>
                                {columnList["custom code"] && (
                                    <td  onClick={() => {
                                        setSortedColumnName("customCode")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        custom code
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "customCode" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "customCode" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "customCode" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList["monthly price ht"] && (
                                    <td  onClick={() => {
                                        setSortedColumnName("monthlyPriceHT")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        monthly charge
                                        <br/>
                                        <span className="sub-name">(€ - EXCL. TAXES)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "monthlyPriceHT" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "monthlyPriceHT" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "monthlyPriceHT" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList["monthly price ttc"] && (
                                    <td onClick={() => {
                                        setSortedColumnName("monthlyPriceTTC")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        monthly charge
                                        <br/>
                                        <span className="sub-name">(€ - INCL. TAXES)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "monthlyPriceTTC" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "monthlyPriceTTC" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "monthlyPriceTTC" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList["yearly price ht"] && (
                                    <td onClick={() => {
                                        setSortedColumnName("yearlyPriceHT")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        yearly charge
                                        <br/>
                                        <span className="sub-name">(€ - EXCL. TAXES)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "yearlyPriceHT" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "yearlyPriceHT" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "yearlyPriceHT" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList["yearly price ttc"] && (
                                    <td onClick={() => {
                                        setSortedColumnName("yearlyPriceTTC")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        yearly charge
                                        <br/>
                                        <span className="sub-name">(€ - INCL. TAXES)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "yearlyPriceTTC" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "yearlyPriceTTC" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "yearlyPriceTTC" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList["storage limit"] && (
                                    <td onClick={() => {
                                        setSortedColumnName("storageLimit")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        storage limit
                                        <br/>
                                        <span className="sub-name">(GB)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "storageLimit" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "storageLimit" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "storageLimit" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList.guests && (
                                    <td onClick={() => {
                                        setSortedColumnName("guestLimit")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        guests
                                        <br/>
                                        <span className="sub-name">(MAX)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "guestLimit" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "guestLimit" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "guestLimit" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList["backup cap"] && (
                                    <td onClick={() => {
                                        setSortedColumnName("weeklyExportLimit")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        back-up cap
                                        <br/>
                                        <span className="sub-name">(PER WEEK)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "weeklyExportLimit" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "weeklyExportLimit" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "weeklyExportLimit" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                {columnList.users && (
                                    <td onClick={() => {
                                        setSortedColumnName("subscriptionWithPlan")
                                        if (sortedType === ""){
                                            setSortedType("1")
                                        } else if (sortedType === "1"){
                                            setSortedType("2")
                                        } else {
                                            setSortedType("")
                                        }
                                    }}>
                                        users
                                        <br/>
                                        <span className="sub-name">(AS OF TODAY)</span>
                                        <span className="sort-icon">
                                        {sortedType === "1" && sortedColumnName === "subscriptionWithPlan" ? <FaSortUp /> : sortedType === "2" && sortedColumnName === "subscriptionWithPlan" ? <FaSortDown /> : sortedType === "" || sortedColumnName !== "subscriptionWithPlan" ? <FaSort /> : null}
                                        </span>
                                    </td>
                                )}
                                <td> </td>
                            </tr>
                            </thead>
                            <tbody>
                            {getPlansRequestStatus && (
                                <>
                                    {paginatedPlans.length > 0 && (
                                        <>
                                            {paginatedPlans.map((plan, index) => (
                                                <tr key={index}>
                                                    <td className="left large">{plan.name}</td>
                                                    {columnList["custom code"] && (
                                                        <td>{plan.customCode ? plan.customCode : "N/A"}</td>
                                                    )}
                                                    {columnList["monthly price ht"] && (
                                                        <td>{plan.monthlyPriceHT}</td>
                                                    )}
                                                    {columnList["monthly price ttc"] && (
                                                        <td>{plan.monthlyPriceTTC}</td>
                                                    )}
                                                    {columnList["yearly price ht"] && (
                                                        <td>{plan.yearlyPriceHT}</td>
                                                    )}
                                                    {columnList["yearly price ttc"] && (
                                                        <td>{plan.yearlyPriceTTC}</td>
                                                    )}
                                                    {columnList["storage limit"] && (
                                                        <td>{plan.storageLimit}</td>
                                                    )}
                                                    {columnList.guests && (
                                                        <td>{plan.guestLimit}</td>
                                                    )}
                                                    {columnList["backup cap"] && (
                                                        <td>{plan.weeklyExportLimit}</td>
                                                    )}
                                                    {columnList.users && (
                                                        <td>{plan.subscriptionWithPlan}</td>
                                                    )}
                                                    <td className={openedArraySubMenuPlanId === plan.id ? "sub-menu open" : "sub-menu"}>
                                                        <p onClick={() => openedArraySubMenuPlanId === 0 ? setOpenedSubMenuPlanId(plan.id) : openedArraySubMenuPlanId !== plan.id ? setOpenedSubMenuPlanId(plan.id) : setOpenedSubMenuPlanId(0)} className="btn"><BsThreeDots /></p>
                                                        <div className="menu" >
                                                            <p onClick={() => {
                                                                setUpdatedPlanId(plan.id)
                                                                setUpdatePlanStatus(false)
                                                                setUpdatePlanModalStatus(true)
                                                            }}>Modify plan parameters</p>
                                                            {plan.custom && (
                                                                <>
                                                                    {plan.subscriptionWithPlan === 0 && (
                                                                        <p onClick={() => {
                                                                            setDeletedPlanId(plan.id)
                                                                            setDeletePlanStatus(false)
                                                                            setDeletePlanModalStatus(true)
                                                                        }}>Delete plan</p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedPlans.length < 1 && (
                                        <tr>
                                            <td style={{"textAlign": "center"}} colSpan="100%">No subscription plan</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getPlansRequestStatus && (
                                <tr>
                                    <td style={{"textAlign": "center"}} colSpan="100%"><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPage(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPage}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtons
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPage}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedPlans.length} rows
                            </div>
                        </div>
                    </div>
                    {/*PROMO CODE ARRAY*/}
                    <div className="dashboard-table mt">
                        <p className="title">List of promos code</p>
                        <div className="top-container">
                            <div className="right">
                                <div className="btn">
                                    <div className="icon"><IoMdAdd /></div>
                                    <div className="text" onClick={() => setCreatePromoCodeModalStatus(true)}>Create promo code</div>
                                </div>
                            </div>
                            <div className="right">
                                <Export data={promoCode} filename="weeki_promo_code" />
                            </div>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td>Code</td>
                                <td>expiration date</td>
                                <td>discount</td>
                                <td>maximum use</td>
                                <td> </td>
                            </tr>
                            </thead>
                            <tbody>
                                    {paginatedPromoCode.length > 0 && (
                                        <>
                                            {paginatedPromoCode.map((code, index) => (
                                                <tr key={index}>
                                                    <td>{code.code}</td>
                                                    <td>{code.expirationDate}</td>
                                                    <td>{code.discount}{code.discountType}</td>
                                                    <td>{code.durationInMonth} months</td>
                                                    <td className={openedArraySubMenuCodeId === code.id ? "sub-menu open" : "sub-menu"}>
                                                        <p onClick={() => openedArraySubMenuCodeId === 0 ? setOpenedSubMenuCodeId(code.id) : openedArraySubMenuCodeId !== code.id ? setOpenedSubMenuCodeId(code.id) : setOpenedSubMenuCodeId(0)} className="btn"><BsThreeDots /></p>
                                                        <div className="menu" >
                                                            <p id={code.id} onClick={deletePromotionCode}>Delete code</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedPromoCode.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No promotion code</td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPagePromoCode(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPagePromoCode}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtons
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPagePromoCode}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedPromoCode.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="top-btn" onClick={() => dashboard.scrollTo(0, 0)}>
                            <p><FaChevronUp /></p>
                        </div>
                        <p className="copy">&copy; Weeki 2021</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardPlans