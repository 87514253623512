import React, {Fragment, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import MapNavBar from "../components/MapNavBar";
import MapAPI from "../services/MapAPI";
import {HiEllipsisHorizontal} from "react-icons/hi2";
import {IoMdSettings} from "react-icons/io";
import {MdClose} from "react-icons/md";
import {VscChromeClose} from "react-icons/vsc";
import {toast} from "react-toastify";
import SavingBarPopup from "../components/SavingBarPopup";
import OfflinePopup from "../components/OfflinePopup";
import MapObjectsTable from "../components/MapObjectsTable";
import {useMenuState} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css"
import "@szhsin/react-menu/dist/index.css"
import OverlaySideMenu from "../components/Map/OverlaySideMenu";
import AppSearchAPI from "../services/AppSearchAPI";
import {useOnClickOutside} from "../services/Utils";
import ObjectsAPI from "../services/ObjectsAPI";
import {Range} from "rc-slider";
import LeafletMap from "../components/LeafletMap";

//IMPORT ICON
import {ReactComponent as SearchIcon} from "../../icons/map/search.svg"
import { PiFunnelBold } from "react-icons/pi"
import { PiStackBold } from "react-icons/pi"
import { PiPlusBold } from "react-icons/pi"
import { PiSelectionAllBold } from "react-icons/pi"
import { PiUploadSimpleBold } from "react-icons/pi"
import {ReactComponent as CenterIcon} from "../../icons/map/center.svg"
import {ReactComponent as ZoomInIcon} from "../../icons/map/zoom-in.svg"
import {ReactComponent as ZoomOutIcon} from "../../icons/map/zoom-out.svg"
import {ReactComponent as ZoneOutIcon} from "../../icons/map/zone.svg"
import UsersOnMapAPI from "../services/UsersOnMapAPI";
import {MERCURE_URL} from "../../Router";
import {useCookies} from "react-cookie";
import AddObjectPopup from "../components/Map/ObjectPopup/AddObjectPopup";
import ObjectLevel3Popup from "../components/Map/ObjectPopup/ObjectLevel3Popup";

//IMPORT OBJECT NO BACKGROUND ICONS
import FullScreenPDF from "../components/Map/FullScreenPDF";
import ContentItemAPI from "../services/ContentItemAPI";
import ObjectSidebar from "../components/Map/ObjectSidebar/ObjectSidebar";
import ContentItemTypeAPI from "../services/ContentItemTypeAPI";
import ObjectFilterPanel from "../components/Map/ObjectFilterPanel";
import ManageOverlayAndZoneSideMenu from "../components/Map/ManageOverlayAndZoneSideMenu";
import ObjectFullscreen from "../components/Map/ObjectFullscreen";
import {useMapStore} from "../services/Stores";
import CreateObjectPopup from "../components/Map/CreateObjectPopup";
import {BiText} from "react-icons/bi";
import PublicsMapAPI from "../services/API/Publics/PublicsMapAPI";
import publicsMapAPI from "../services/API/Publics/PublicsMapAPI";

const PublicMap = () => {
    const mapInstance = useMapStore(state => state.mapInstance)

    const {mapUuid} = useParams()

    const [cookies] = useCookies(['mercureAuthorization'])

    //Define default state for opened sidebar object
    const [openedSidebarObject, setOpenedSidebarObject] = useState("")
    const [openedSidebarPanel, setOpenedSidebarPanel] = useState("info")

    //Define default state for opened full screen object
    const [openedFullscreenObject, setOpenedFullscreenObject] = useState("")

    //Define default state for mercure online user event source and tiles refresh event source
    const [onlineUserEventSource, setOnlineUserEventSource] = useState(null)
    const [tilesRefreshEventSource, setTilesRefreshEventSource] = useState(null)

    const handleOpenSidebarObject = (itemUuid, panel = null) => {
        setOpenedSidebarObject(itemUuid)

        if (panel !== null){
            setOpenedSidebarPanel(panel)
        }
    }

    //Define default state for users on map list
    const [usersOnMap, setUsersOnMap] = useState([{
        'uuid': "",
        'email': "",
        'firstname': "",
        'lastname': "",
        'picture': ""
    }])

    //Define default state for content item categories list
    const [contentItemCategoriesList, setContentItemCategoriesList] = useState([{
        'name': "",
        'code': "",
        'types': []
    }])

    //Define default state for user content item type list
    const [contentItemTypeUserList, setContentItemTypeUserList] = useState([{
        'code': "",
        'name': "",
        'category': "",
        'icon': "",
        'mimeType': [],
        'authorizedDomain': [],
        'availableFields': [],
        'thumbnail': false,
        'needObjectName': false,
        'authorizedExtensions': [],
        'needCreateFile': false
    }])

    //Get all user on map
    useEffect(() => {
        useMapStore.setState({publicMap: true})
        //Get content item categories list
        // ContentItemTypeAPI.getContentItemCategories().then((response) => {
        //     if (response.status === 200){
        //         setContentItemCategoriesList(response.data)
        //     }
        // })
        //
        // //Get content item type user list
        // ContentItemTypeAPI.getContentItemTypeUserList().then((response) => {
        //     if (response.status === 200){
        //         setContentItemTypeUserList(response.data)
        //     }
        // })
    }, [mapUuid])

    //Define default state for map object
    const [map, setMap] = useState({
        "id": "",
        "name": "",
        "searchApiKey": "",
        'mercureTilesRefreshTopic': "",
    })

    //Get map info and get map items
    useEffect(() => {
        PublicsMapAPI.getPublicMapByUuid(mapUuid).then((response) => {
            setMap(response.data)

            //Open event source for tiles refresh
            // const url = new URL(MERCURE_URL)
            // url.searchParams.append('topic', response.data.mercureTilesRefreshTopic)
            // url.searchParams.append('authorization', cookies.mercureAuthorization)
            //
            // setTilesRefreshEventSource(new EventSource(url, {withCredentials: false}))
        })
            .catch(() => {
                window.location.href = "/"
            })

        // ObjectsAPI.getMapItems(mapUuid).then((response) => setMapItems(response.data))
    }, [mapUuid, cookies.mercureAuthorization])

    //Define default state for item category object
    const [contentItemCategories, setContentItemCategories] = useState([{
        "code": "",
        "name": ""
    }])

    //Define default state for item type object
    const [contentItemTypes, setContentItemTypes] = useState([{
        "code": "",
        "name": ""
    }])

    //Define default state for zoom level
    const [zoomLevel, setZoomLevel] = useState(10)
    const maxZoom = 18
    const minZoom = 1

    //Define default state for coordinates
    const [coordinates, setCoordinates] = useState({x: 1234, y: 1234})

    const copyCoordinatesToClipboard = () => {
        navigator.clipboard.writeText(coordinates.x + "," + coordinates.y)
        toast.success("Coordinates copied to clipboard")
    }

    //Define default state for objects table
    const [objectsTableStatus, setObjectsTableStatus] = useState(false)

    const updateMapInfos = () => {
        MapAPI.getMapByUuid(mapUuid).then((response) => setMap(response.data))
    }


    const [mapContextMenuState, setMapContextMenuState] = useMenuState({
        transition: true
    })
    const [mapContextMenuAnchorPoint, setMapContextMenuAnchorPoint] = useState({x: 0, y: 0})

    const openMapContextMenu = (event) => {
        event.preventDefault();
        setMapContextMenuAnchorPoint({x: event.clientX, y: event.clientY})
        setMapContextMenuState(true)
    }


    const [zoneContextMenuState, setZoneContextMenuState] = useMenuState({
        transition: true
    })
    const [zoneContextMenuAnchorPoint, setZoneContextMenuAnchorPoint] = useState({x: 0, y: 0})

    const openZoneContextMenu = (event) => {
        event.stopPropagation()
        event.preventDefault();
        setZoneContextMenuAnchorPoint({x: event.clientX, y: event.clientY})
        setZoneContextMenuState(true)
    }


    const [objectContextMenuState, setObjectContextMenuState] = useMenuState({
        transition: true
    })

    const [objectContextMenuAnchorPoint, setObjectContextMenuAnchorPoint] = useState({x: 0, y: 0})

    const openObjectContextMenu = (event) => {
        event.stopPropagation()
        event.preventDefault();
        setObjectContextMenuAnchorPoint({x: event.clientX, y: event.clientY})
        setObjectContextMenuState(true)
    }

    //Panel sub menu
    const [addObjectSubMenu, setAddObjectSubMenu] = useState(false)

    /******************SAVING BAR********************/
    const [savingBarStatus, setSavingBarStatus] = useState(false)
    const [savingBarValue, setSavingBarValue] = useState(0)
    const [savingBarRunning, setSavingBarRunning] = useState(false)
    let interval = undefined;

    const testSavingBar = () => {
        setSavingBarStatus(true)
        setSavingBarRunning(true)
    }

    useEffect(() => {
        if (savingBarRunning){
            interval = setInterval(() => {
                setSavingBarValue((prevState) => prevState + 1)
            }, 50)
            return () => clearInterval(interval)
        }
    }, [savingBarRunning])

    useEffect(() => {
        if (savingBarValue >= 100){
            setSavingBarRunning(false)
            setSavingBarValue(0)
            setSavingBarStatus(false)
            return () => clearInterval(interval)
        }
    }, [savingBarValue])
    /************************************************/

    /*******************SETTINGS PANEL***********************/
    const [settingsPanelStatus, setSettingsPanelStatus] = useState(false)

    const [zoomSensitivityValue, setZoomSensitivityValue] = useState(1)
    const [selectedNavigationMode, setSelectedNavigationMode] = useState("mouse")
    /********************************************************/

    //Define default state for object modal
    const [objectModalStatus, setObjectModalStatus] = useState(false)
    const [zoneModalStatus, setZoneModalStatus] = useState(false)
    const [exportModalStatus, setExportModalStatus] = useState(false)
    const [selectedExportType, setSelectedExportType] = useState("")
    const [selectedExportFormat, setSelectedExportFormat] = useState("")
    const [exportFileName, setExportFileName] = useState("")


    useEffect(() => {
        if (selectedExportType !== ""){
            setSelectedExportFormat("")
        }
    }, [selectedExportType])

    const exportData = () => {
        setExportModalStatus(false)
        toast.success("Data exported")
    }

    const [overlaySideMenuStatus, setOverlaySideMenuStatus] = useState(false)
    const [zoneSideMenuStatus, setZoneSideMenuStatus] = useState(false)

    /***********************************APP SEARCH**************************************/
    const [globalSearchInput, setGlobalSearchInput] = useState("")
    const [globalSearchScope, setGlobalSearchScope] = useState("area")
    const [globalSearchResults, setGlobalSearchResults] = useState([])
    const searchContainerRef = useRef(null)
    useOnClickOutside(searchContainerRef, () => {setGlobalSearchResults([])})

    useEffect(() => {
        setGlobalSearchResults([])
    }, [globalSearchScope])

    //Object owner filter
    const [searchOwnerResults, setSearchOwnerResults] = useState([])
    const [searchOwnerInputValue, setSearchOwnerInputValue] = useState("")
    const [selectedOwner, setSelectedOwner] = useState([])

    const searchOwner = async (event) => {
        const query = event.currentTarget.value

        setSearchOwnerInputValue(query)

        await AppSearchAPI.searchOwner(query, map.id, map.searchApiKey).then((response) => {
            const results = response.data.results

            const ownerList = []

            for (let i = 0; i < results.length; i++){
                if (!ownerList.includes(results[i].owner.raw)){
                    ownerList.push(results[i].owner.raw)
                }
            }

            setSearchOwnerResults(ownerList)
        })
    }

    const addOwnerToSelectedArray = (event) => {
        const owner = event.currentTarget.id

        const ownerExist = selectedOwner.some(e => {
            if (e === owner){
                toast.error("User already add to filter")
                return true
            }

            return false
        })

        if (!ownerExist){
            setSelectedOwner(selectedOwner => [...selectedOwner, owner])
        }
    }

    const removeOwnerFromSelectedArray = (event) => {
        const owner = event.currentTarget.id

        setSelectedOwner(selectedOwner.filter(o => o !== owner))
    }

    //Object tags filter
    const [searchTagsResults, setSearchTagsResults] = useState([])
    const [searchTagsInputValue, setSearchTagsInputValue] = useState("")
    // const [selectedTags, setSelectedTags] = useState([])

    const searchTags = async (event) => {
        const query = event.currentTarget.value

        setSearchTagsInputValue(query)

        await AppSearchAPI.searchTags(query, map.id, map.searchApiKey).then((response) => {
            const results = response.data.results

            const tagList = []

            for (let i = 0; i < results.length; i++){
                if (!tagList.includes(results[i].name.raw)){
                    tagList.push(results[i].name.raw)
                }
            }

            setSearchTagsResults(tagList)
        })
    }

    const addTagToSelectedArray = (event) => {
        const tag = event.currentTarget.id

        const tagExist = selectedTags.some(e => {
            if (e === tag){
                toast.error("Tag already add to filter")
                return true
            }

            return false
        })

        if (!tagExist){
            setSelectedTags(selectedTags => [...selectedTags, tag])
        }
    }

    const removeTagFromSelectedArray = (event) => {
        const tag = event.currentTarget.id

        setSelectedTags(selectedTags.filter(o => o !== tag))
    }

    //Overlay filter
    const [searchOverlayResults, setSearchOverlayResults] = useState([])
    const [searchOverlayInputValue, setSearchOverlayInputValue] = useState("")
    const [selectedOverlays, setSelectedOverlays] = useState([])

    const searchOverlay = async (event) => {
        const query = event.currentTarget.value

        setSearchOverlayInputValue(query)

        await AppSearchAPI.searchOverlay(query, map.id, map.searchApiKey).then((response) => {
            setSearchOverlayResults(response.data.results)
        })
    }

    const addOverlayToSelectedArray = (event) => {
        const overlayId = event.currentTarget.id.split('_')[0]
        const overlayName = event.currentTarget.id.split('_')[1]

        const overlayExist = selectedOverlays.some(e => {
            if (e.id === overlayId){
                toast.error("Overlay already add to filter")
                return true
            }

            return false
        })

        if (!overlayExist){
            setSelectedOverlays(selectedOverlays => [...selectedOverlays, {id: overlayId, name: overlayName}])
        }
    }

    const removeOverlayFromSelectedArray = (event) => {
        const overlayId = event.currentTarget.id

        setSelectedOverlays(selectedOverlays.filter(overlay => overlay.id !== overlayId))
    }

    //Global search
    const search = async (event) => {
        const searchInputValue = event.currentTarget.value

        setGlobalSearchInput(searchInputValue)

        if (globalSearchScope === 'area'){
            await AppSearchAPI.searchArea(searchInputValue, map.id, map.searchApiKey).then((response) => {
                setGlobalSearchResults(response.data.results)
            })
        } else {
            await AppSearchAPI.searchItemWithFilter(searchInputValue, map.id, map.searchApiKey, selectedOverlays, selectedOwner, selectedTags, []).then((response) => {
                setGlobalSearchResults(response.data.results)
            })
        }
    }
    /***********************************************************************************/
    const overlaySearchContainerRef = useRef(null)
    const ownerSearchContainerRef = useRef(null)
    const tagsSearchContainerRef = useRef(null)
    useOnClickOutside(overlaySearchContainerRef, () => {
        setSearchOverlayResults([])
        setSearchOverlayInputValue("")
    })
    useOnClickOutside(ownerSearchContainerRef, () => {
        setSearchOwnerResults([])
        setSearchOwnerInputValue("")
    })
    useOnClickOutside(tagsSearchContainerRef, () => {
        setSearchTagsResults([])
        setSearchTagsInputValue("")
    })

    /*********************************MAP***********************************************/
    const [currentLayerPoint, setCurrentLayerPoint] = useState({
        x: 0,
        y: 0,
        long: 0,
        lat: 0
    })
    /***********************************************************************************/

    /***********************************MAP DRAG & DROP*********************************/
    const [mapDragBoxStatus, setMapDragBoxStatus] = useState(false)

    const dragLeave = () => {
        setTimeout(() => {
            setMapDragBoxStatus(false)
        }, 50)
    }

    const dropFiles = (event) => {
        event.preventDefault()
    }
    /***********************************************************************************/

    /**********************************ADD OBJECT POPUP*********************************/
    const [panelAddObjectStatus, setPanelAddObjectStatus] = useState(false)

    const [addObjectPopupStatus, setAddObjectPopupStatus] = useState(false)
    const [addObjectPopupType, setAddObjectPopupType] = useState(null)

    const handleCreateObject = (type) => {
        if (type !== null){
            setAddObjectPopupType(type)
        }

        setAddObjectPopupStatus(true)
        setPanelAddObjectStatus(false)
    }
    /***********************************************************************************/

    /**************************************MAP SEARCH***********************************/
    const [mapSearchQuery, setMapSearchQuery] = useState("")
    const [mapSearchQueryResults, setMapSearchQueryResults] = useState([])
    const mapSearchRef = useRef(null)
    useOnClickOutside(mapSearchRef, () => {
        setMapSearchQueryResults([])
        setMapSearchQuery("")
    })

    const handleMapSearch = async (event) => {
        const query = event.currentTarget.value

        setMapSearchQuery(query)

        await AppSearchAPI.searchOnMap(query, map.id, map.searchApiKey).then(response => {
            if (response.status === 200){
                setMapSearchQueryResults(response.data.results)
            }
        })
    }

    const [viewObjectOnMapData, setViewObjectOnMapData] = useState({
        'location': "",
        'zlevel': ""
    })

    const viewObjectOnMap = (location, zlevel) => {
        setViewObjectOnMapData({location: location, zlevel: zlevel})
    }
    /***********************************************************************************/


    const [openedContentItem, setOpenedContentItem] = useState([])

    const handleOpenObject = (objectProperties, clientX, clientY) => {
        objectProperties.active = true
        objectProperties.clientX = clientX
        objectProperties.clientY = clientY

        const alreadyOpen = openedContentItem.some(item => {
            return item.uuid === objectProperties.id
        })

        if (!alreadyOpen){
            setOpenedContentItem(openedContentItem => [...openedContentItem, objectProperties])
        }
    }

    const [fullScreenObjectProperties, setFullScreenObjectProperties] = useState({
        "type_code": ""
    })

    const handleCloseItem = (objectUuid) => {
        setOpenedContentItem(openedContentItem.filter(oci => oci.id !== objectUuid))
    }

    /**********************************OBJECT SIDEBAR*****************************/
    const [bibtexSidebarUuid, setBibtexSidebarUuid] = useState("")
    const [bibtexSidebarState, setBibtexSidebarState] = useState(false)

    const [objectSidebarUuid, setObjectSidebarUuid] = useState("")
    const [objectSidebarState, setObjectSidebarState] = useState(false)

    const handleOpenBibtexSidebar = (objectUuid) => {
        if (objectSidebarState){
            handleCloseObjectSidebar()
        }
        setBibtexSidebarUuid(objectUuid)
        setBibtexSidebarState(true)
        handleCloseItem(objectUuid)
    }

    const handleOpenObjectSidebar = (objectUuid) => {
        if (bibtexSidebarState){
            handleCloseBibtexSidebar()
        }
        setObjectSidebarUuid(objectUuid)
        setObjectSidebarState(true)
        handleCloseItem(objectUuid)
    }

    const handleCloseBibtexSidebar = () => {
        setBibtexSidebarState(false)
        setBibtexSidebarUuid("")
    }

    const handleCloseObjectSidebar = () => {
        setObjectSidebarState(false)
        setObjectSidebarUuid("")
    }

    const handleMinimizeBibtexSidebar = () => {
        setBibtexSidebarState(!bibtexSidebarState)
    }

    const handleMinimizeObjectSidebar = () => {
        setObjectSidebarState(!objectSidebarState)
    }
    /*****************************************************************************/

    const handleDeleteContentItem = async (itemUuid) => {
        if (await ContentItemAPI.deleteContentItem(itemUuid)){
            toast.success("delete ok")
        }
    }

    const [currentMapCenter, setCurrentMapCenter] = useState({
        "lat": 0,
        "long": 0,
        "x": 0,
        "y": 0
    })

    const [objectFilterPanelStatus, setObjectFilterPanelStatus] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedType, setSelectedType] = useState( [])
    const [selectedColors, setSelectedColors] = useState([])
    const [selectedTags, setSelectedTags] = useState([])

    const [mapObjectFilters, setMapObjectFilters] = useState({
        'category': [],
        'type': [],
        'color': [],
        'tag': [],
        'overlay': []
    })

    useEffect(() => {
        setMapObjectFilters({...mapObjectFilters, category: selectedCategory})
    }, [selectedCategory])
    useEffect(() => {
        setMapObjectFilters({...mapObjectFilters, type: selectedType})
    }, [selectedType])
    useEffect(() => {
        setMapObjectFilters({...mapObjectFilters, color: selectedColors})
    }, [selectedColors])
    useEffect(() => {
        setMapObjectFilters({...mapObjectFilters, tag: selectedTags})
    }, [selectedTags])

    const [manageSideMenuStatus, setManageSideMenuStatus] = useState(false)
    const [currentOverlay, setCurrentOverlay] = useState("")
    const [visibleOverlayList, setVisibleOverlayList] = useState([])

    useEffect(() => {
        setMapObjectFilters({...mapObjectFilters, overlay: visibleOverlayList})
    }, [visibleOverlayList])

    const handleOpenFullScreen = (itemId) => {
        setOpenedFullscreenObject(itemId)
        setOpenedSidebarObject("")
    }

    const handleResetZoomAndPosition = () => {
        if (mapInstance){
            mapInstance.setView([49.1, 1.8], 10)
        }
    }

    const [createObjectPopupState, setCreateObjectPopupState] = useState(false)

    const [createTextStatus, setCreateTextStatus] = useState(false)

    const createTextState = useMapStore(state => state.createTextStatus)



    return (
        <>
            {/*FULL SCREEN PDF*/}
            {fullScreenObjectProperties.type_code === "PDF" && (
                <FullScreenPDF properties={fullScreenObjectProperties} setProperties={setFullScreenObjectProperties} />
            )}
            {/*OBJECT SIDEBAR*/}
            {openedSidebarObject !== "" && (
                <ObjectSidebar panel={openedSidebarPanel} handleOpenFullscreen={handleOpenFullScreen} setItemId={setOpenedSidebarObject} itemId={openedSidebarObject} mapUuid={mapUuid} mapSearchApiKey={map.searchApiKey} publicMap={true}/>
            )}
            {/*OBJECT FULL SCREEN*/}
            {openedFullscreenObject !== "" && (
                <ObjectFullscreen mapName={map.name} setItemId={setOpenedFullscreenObject} itemId={openedFullscreenObject} mapUuid={mapUuid} mapSearchApiKey={map.searchApiKey} publicMap={true}/>
            )}
            <ManageOverlayAndZoneSideMenu publicMap={true} visibleOverlayList={visibleOverlayList} setVisibleOverlayList={setVisibleOverlayList} currentOverlay={currentOverlay} setCurrentOverlay={setCurrentOverlay} state={manageSideMenuStatus} setState={setManageSideMenuStatus} mapUuid={mapUuid} />
            {/*Page view*/}
            {overlaySideMenuStatus && (
                <OverlaySideMenu menuStatus={overlaySideMenuStatus} setMenuStatus={setOverlaySideMenuStatus} mapUuid={map.id} />
            )}
            <MapNavBar map={map} updateMapInfo={updateMapInfos} usersOnMap={usersOnMap} publicMap={true} />
            <SavingBarPopup state={savingBarStatus} value={savingBarValue} mapName={map.name} />
            <OfflinePopup />
            <div className="map-container" onContextMenu={openMapContextMenu}>
                <div className="map-search-bar-container" ref={mapSearchRef}>
                    <div className="map-search-bar">
                        <SearchIcon className="icon" />
                        <input value={mapSearchQuery} onChange={handleMapSearch} type="text" name="map-search" placeholder="Search for objects" />
                    </div>
                    {mapSearchQueryResults.length > 0 && (
                        <>
                            <div className="map-search-result-container">
                                {mapSearchQueryResults.map((res, index) => (
                                    <div key={index} className="map-search-result" onClick={() => viewObjectOnMap(res.location.raw, res.zlevel2.raw)}>
                                        <div className="left">
                                            <img src={res.icon_code !== undefined ? "/static/map/svg/" + res.icon_code.raw + '.svg' : ""} alt="Item icon"/>
                                            <p className="text">{res.name.raw}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className="map-buttons-panel-container left">
                    <div className="panel-container">
                        <div className="panel">
                            <div className="panel-btn fill-icon" onClick={() => setObjectFilterPanelStatus(!objectFilterPanelStatus)}>
                                <div className="hover-btn">
                                    <PiFunnelBold fontSize="1.8em" color="#4d4f5c"/>
                                </div>
                                {!objectFilterPanelStatus && (
                                    <p className="button-tooltip">Filter objects</p>
                                )}
                            </div>
                            <div className="panel-btn fill-icon" onClick={() => setManageSideMenuStatus(true)}>
                                <div className="hover-btn">
                                    <PiStackBold fontSize="1.8em" color="#4d4f5c"/>
                                </div>
                                <p className="button-tooltip">Manage overlays and zones</p>
                            </div>
                            {objectFilterPanelStatus && (
                                <ObjectFilterPanel objectCategories={contentItemCategoriesList} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedType={selectedType} setSelectedType={setSelectedType} selectedColors={selectedColors} setSelectedColors={setSelectedColors} selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="map-buttons-panel-container right">
                    <div className="panel-container"></div>
                    <div className="panel-container">
                        <div className="panel">
                            <div className="panel-btn fill-icon" onClick={handleResetZoomAndPosition}>
                                <div className="hover-btn">
                                    <CenterIcon />
                                </div>
                                <p className="button-tooltip">Reset zoom and position</p>
                            </div>
                        </div>
                        <div className="panel">
                            <div className="panel-btn fill-icon" onClick={() => zoomLevel < maxZoom ? setZoomLevel(Math.floor(zoomLevel) + 1) : null}>
                                <div className="hover-btn">
                                    <ZoomInIcon />
                                </div>
                                <p className="button-tooltip">Zoom in</p>
                            </div>
                            <p className="panel-zoom-level">{Math.floor(zoomLevel)}</p>
                            <div className="panel-btn fill-icon" onClick={() => zoomLevel > minZoom ? setZoomLevel(Math.floor(zoomLevel) - 1) : null}>
                                <div className="hover-btn">
                                    <ZoomOutIcon style={{transform: "rotate(-90deg)"}} />
                                </div>
                                <p className="button-tooltip">Zoom out</p>
                            </div>
                        </div>
                    </div>
                </div>
                <LeafletMap publicMap={true} createTextStatus={createTextStatus} setCreateTextStatus={setCreateTextStatus} setOpenedSidebarObject={setOpenedSidebarObject} handleOpenSidebarObject={handleOpenSidebarObject} mapSearchApiKey={map.searchApiKey} currentOverlay={currentOverlay} mapObjectFilter={mapObjectFilters} handleCreateObject={handleCreateObject} refreshTilesEventSource={tilesRefreshEventSource} currentMapCenter={currentMapCenter} setCurrentMapCenter={setCurrentMapCenter} zoneSideMenuStatus={zoneSideMenuStatus} setZoneSideMenuStatus={setZoneSideMenuStatus} currentLayerPoint={currentLayerPoint} handleDeleteContentItem={handleDeleteContentItem} viewObjectOnMap={viewObjectOnMapData} setViewObjectOnMap={setViewObjectOnMapData} setCurrentLayerPoint={setCurrentLayerPoint} zoom={zoomLevel} setZoom={setZoomLevel} mapUuid={mapUuid} handleOpenObject={handleOpenObject} handleOpenFullScreen={handleOpenFullScreen}/>
                <div className="coordinates-container" onClick={copyCoordinatesToClipboard}>
                    <p className="coordinate">X: {currentMapCenter.x}</p>
                    <p className="separator">|</p>
                    <p className="coordinate">Y: {currentMapCenter.y}</p>
                </div>
            </div>
        </>
    )
}

export default PublicMap