import getAxiosInstance from "../../AxiosInstance";
import {PROJECTS_API} from "../../../../Router";
import {useDashboardStore} from "../../Stores/DashboardStore";

function getAllUserProjects(){
    return getAxiosInstance().get(PROJECTS_API + "/get_user_projects")
}

function userGetAllWorkspaceProjects(uuid = null){
    return getAxiosInstance().get(PROJECTS_API + "/get_workspace_projects", {params: {workspaceUuid: (uuid !== null ? uuid : useDashboardStore.getState().currentOpenedWorkspace.uuid)}})
}

function userCreateProject(name){
    const sendData = new FormData()
    sendData.append('projectName', name)
    if (useDashboardStore.getState().currentOpenedWorkspace){
        sendData.append('workspaceUuid', useDashboardStore.getState().currentOpenedWorkspace.uuid)
    }

    return getAxiosInstance().post(PROJECTS_API + '/create', sendData)
        .then((response) => {
            if (response.status === 200){
                return {
                    'uuid': response.data.uuid,
                    'userAuthorizations': response.data.userAuthorizations
                }
            }
        })
        .catch(() => {
            return false
        })
}

function userDeleteProject(uuid){
    return getAxiosInstance().delete(PROJECTS_API + '/delete', {params: {uuid: uuid}})
        .then((response) => {
            return response.status === 200
        })
}

export default {
    userCreateProject,
    userGetAllWorkspaceProjects,
    getAllUserProjects,
    userDeleteProject
}