import React, {useRef, useState} from "react";
import upload_file_icon from "../../../icons/upload_file.svg"

const UploadFileComponent = ({multiple, processUploadFile, uploadedFiles}) => {
	const uploadInputRef = useRef(null)
	const [dragActive, setDragActive] = useState(false)

	const handleDragFile = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	}

	const handleDrop = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setDragActive(false)

		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			const files = e.dataTransfer.files

			processUploadFile(files)
		}
	}

	const handleChangeFileInput = (event) => {
		const files = event.currentTarget.files

		processUploadFile(files)
	}

	return (
		<>
			{dragActive && (
				<div id="drag-container" onDragEnter={handleDragFile} onDragLeave={handleDragFile} onDrop={handleDrop}
					 onDragStart={(e) => e.preventDefault()}
					 onDragEnd={(e) => e.preventDefault()}
					 onDragOver={(e) => e.preventDefault()}
					 onDrag={(e) => e.preventDefault()}
				></div>
			)}
			<div className="custom__upload-file-container" onDragEnter={handleDragFile} onClick={() => uploadInputRef.current.click()}>
				<input type="file" onChange={handleChangeFileInput} multiple={multiple} ref={uploadInputRef}/>
				<div className="upload-icon"><img src={upload_file_icon} alt="Upload"/></div>
				{uploadedFiles.length <= 0 && (
					<>
					<p className="upload-text">
					  Drag and drop your file <br /> or <span>browse</span> your files
					</p>
					<p className="max-text">
					  Maximum file size 50Mo
					  <div className="icon-container" data-tooltip="1Go for PRO and ENTREPRISE plan">
						<svg
						  xmlns="http://www.w3.org/2000/svg"
						  width="16"
						  height="16"
						  viewBox="0 0 24 24"
						  fill="none"
						  stroke="currentColor"
						  strokeWidth="2"
						  strokeLinecap="round"
						  strokeLinejoin="round"
						>
						  <circle cx="12" cy="12" r="10" />
						  <path d="M12 16v-4" />
						  <path d="M12 8h.01" />
						</svg>
					  </div>
					</p>
				  </>
				)}
				{uploadedFiles.length > 0 && (
					<>
						{uploadedFiles.map((file, index) => (
							<p key={index} className="text">{file.name}</p>
						))}
					</>
				)}
			</div>
		</>

	)
}

export default UploadFileComponent