import L from "leaflet"
import {useMapGlobalSelectionStore} from "../Stores/MapGlobalSelectionStore";
import {useContentItemStore} from "../Stores/ContentItemStore";
import {updateLevel2Content} from "./RenderLevel2Utils";
import {useMapStore} from "../Stores";

export function selectMapData(selectBounds, itemsLayer, areasLayer, shapesLayer){
	//Create layer group for all selected layers
	let selectionLayerGroup
	if (useMapGlobalSelectionStore.getState().selectionLayerGroup === null){
		selectionLayerGroup = L.layerGroup()
		selectionLayerGroup.pm.enable({syncLayersOnDrag: true})
	} else {
		selectionLayerGroup = useMapGlobalSelectionStore.getState().selectionLayerGroup
	}

	useMapGlobalSelectionStore.setState({selectionLayerGroup: selectionLayerGroup})
	//Save start center in store

	useMapGlobalSelectionStore.setState({startCenterLatLng: selectBounds.getCenter(), startBounds: selectBounds})
	//Save layer group in store

	//Add selection layer to group
	// useMapGlobalSelectionStore.getState().selectionLayerGroup.addLayer(useMapGlobalSelectionStore.getState().selectionLayer)

	getSelectedItemsUuid(itemsLayer, selectBounds)
	getSelectedAreaUuid(areasLayer, selectBounds)
	getSelectedShapesUuid(shapesLayer, selectBounds)
}

export function removeGlobalSelection(){
	//Cancel remove if selection drag in progress
	if (useMapGlobalSelectionStore.getState().selectionDragState || useMapGlobalSelectionStore.getState().drawState){
		return
	}

	//Get layer group and selection layer
	const layerGroup = useMapGlobalSelectionStore.getState().selectionLayerGroup
	const selectionLayer = useMapGlobalSelectionStore.getState().selectionLayer
	const tempDrawLayer = useMapGlobalSelectionStore.getState().tempDrawLayer
	const tempSelectionLayer = useMapGlobalSelectionStore.getState().tempSelectionLayer

	//Remove all layer group layer
	if (layerGroup){
		layerGroup.eachLayer(layer => {
			layer.remove()
		})
	}

	//Remove selection layer
	if (selectionLayer){
		selectionLayer.remove()
	}

	if (tempDrawLayer){
		tempDrawLayer.remove()
	}

	if (tempSelectionLayer){
		tempSelectionLayer.remove()
	}

	//Reset selection store
	useMapGlobalSelectionStore.getState().resetStore()
	useMapStore.setState({selectMode: false})
}

function getSelectedItemsUuid(itemsLayer, selectBounds){
	//Get all visible items feature on map
	itemsLayer.scene.queryFeatures({visible: true, geometry: true}).then((results) => {
		if (results){
			const validItems = []

			results.forEach((result) => {
				//Convert item coordinates to latLng object
				const itemPoint = L.latLng(result.geometry.coordinates[1], result.geometry.coordinates[0])

				//Check if selection bounds contain item
				if (selectBounds.contains(itemPoint)) {
					validItems.push({'uuid': result.properties.id, 'lat': result.properties.lat, 'lng': result.properties.long})
				}
			})

			if (useMapGlobalSelectionStore.getState().drawState){
				const currentSelectedItemsUuid = useMapGlobalSelectionStore.getState().selectedItems.map(a => a.uuid)

				validItems.forEach(va => {
					if (!currentSelectedItemsUuid.includes(va.uuid)){
						useMapGlobalSelectionStore.getState().addSelectedItem(va)
					}
				})

				//Get current selected items
				const currentSelectedItems = useMapGlobalSelectionStore.getState().selectedItems
				currentSelectedItems.forEach(selectedItem => {
					//Check if selected item is not in valid item
					if (!validItems.some(vi => vi.uuid === selectedItem.uuid)){
						//Remove selected item rect
						if (selectedItem.rect){
							useMapGlobalSelectionStore.getState().selectionLayerGroup.removeLayer(selectedItem.rect)
							selectedItem.rect.remove()
							useMapGlobalSelectionStore.getState().removeSelectedItem(selectedItem.uuid)
						}
					}
				})

				return
			}



			useMapGlobalSelectionStore.setState({selectedItems: validItems})
		}
	})
}

function getSelectedShapesUuid(shapesLayer, selectBounds){
	//Get all visible items feature on map
	shapesLayer.scene.queryFeatures({visible: true, geometry: true}).then((results) => {
		if (results){
			const shapes = []

			results.forEach((result) => {
				let featurePoints = []

				if (result.geometry.type === "LineString"){
					const shapeString = result.properties.points.replaceAll('LINESTRING(', '').replaceAll(')', '')
					const points = shapeString.split(',')

					points.forEach((p) => {
						const splittedPoint = p.split(' ')
						const latLng = L.Projection.SphericalMercator.unproject(L.point(splittedPoint[0], splittedPoint[1]))

						featurePoints.push(latLng)
					})
				} else if (result.geometry.type === "Polygon"){
					const shapeString = result.properties.points.replaceAll('POLYGON((', '').replaceAll('))', '')
					const points = shapeString.split(',')

					points.forEach((p) => {
						const splittedPoint = p.split(' ')
						const latLng = L.Projection.SphericalMercator.unproject(L.point(splittedPoint[0], splittedPoint[1]))

						featurePoints.push(latLng)
					})
				}

				shapes.push({'uuid': result.properties.id, 'type': result.geometry.type, 'points': featurePoints})
			})

			//Remove shapes with same uuid
			const validShapes = []
			shapes.forEach(s => {
				if (!validShapes.some(nvs => nvs.uuid === s.uuid)){
					validShapes.push(s)
				}
			})

			const selectedShapes = []

			validShapes.forEach(s => {
				if (s.points.every(p => selectBounds.contains(p))){
					selectedShapes.push(s)
				}
			})

			if (useMapGlobalSelectionStore.getState().drawState){
				const currentSelectedShapeUuid = useMapGlobalSelectionStore.getState().selectedShapes.map(a => a.uuid)

				selectedShapes.forEach(va => {
					if (!currentSelectedShapeUuid.includes(va.uuid)){
						useMapGlobalSelectionStore.getState().addSelectedShape(va)
					}
				})

				return
			}

			useMapGlobalSelectionStore.setState({selectedShapes: selectedShapes})
		}
	})
}

function getSelectedAreaUuid(areasLayer, selectBounds){
	//Get all visible area on map
	areasLayer.scene.queryFeatures({visible: true, geometry: true, unique: "id"}).then((results) => {
		if (results){
			const validAreas = []

			results.forEach((result) => {
				//Get all area latLng
				const latLng = []
				result.geometry.coordinates[0].forEach(c => {
					latLng.push(L.latLng(c[1], c[0]))
				})

				//Get area bounds
				const areaBounds = L.latLngBounds(latLng)
				validAreas.push({'uuid': result.properties.id, 'bounds': areaBounds})
			})

			//Remove area with same uuid and merge bounds
			const newValidAreas = []
			validAreas.forEach(va => {
				if (!newValidAreas.some(nva => nva.uuid === va.uuid)){
					const ava = validAreas.filter(a => a.uuid === va.uuid)

					let areaBounds = L.latLngBounds();
					ava.forEach(a => {
						areaBounds.extend(a.bounds)
					})

					if (selectBounds.contains(areaBounds)) {
						newValidAreas.push({'uuid': va.uuid, 'bounds': areaBounds})
					}
				}
			})

			if (useMapGlobalSelectionStore.getState().drawState){
				const currentSelectedAreaUuid = useMapGlobalSelectionStore.getState().selectedAreas.map(a => a.uuid)

				newValidAreas.forEach(va => {
					if (!currentSelectedAreaUuid.includes(va.uuid)){
						useMapGlobalSelectionStore.getState().addSelectedArea(va)
					}
				})

				//Get current selected areas
				const currentSelectedAreas = useMapGlobalSelectionStore.getState().selectedAreas
				currentSelectedAreas.forEach(selectedArea => {
					//Check if selected area is not in valid area
					if (!newValidAreas.some(va => va.uuid === selectedArea.uuid)){
						//Remove selected area rect
						if (selectedArea.rect){
							useMapGlobalSelectionStore.getState().selectionLayerGroup.removeLayer(selectedArea.rect)
							selectedArea.rect.remove()
							useMapGlobalSelectionStore.getState().removeSelectedArea(selectedArea.uuid)
						}
					}
				})

				return
			}

			//Add valid area to store
			useMapGlobalSelectionStore.setState({selectedAreas: newValidAreas})
		}
	})
}

export function renderGlobalSelectedItems(regenerateSelection = false){
	//Get all selected items
	const items = useMapGlobalSelectionStore.getState().selectedItems

	items.forEach(item => {
		//Check if item is already draw
		if (!item.drawState && !item.rect){
			//Get item in rendered lvl 2 list
			const lvl2Item = useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === item.uuid)[0]

			//Check if item is rendered as lvl 2
			if (lvl2Item){
				//Get lvl2 popup width and height
				const lvl2ItemWidthOffset = (lvl2Item.popup._contentNode.offsetWidth / 2) + 10
				const lvl2ItemHeightOffset = (lvl2Item.popup._contentNode.offsetHeight / 2) + 10

				//Get item latLng and convert to container point
				const itemLatLng = L.latLng(item.lat, item.lng)
				const itemContainerPoint = useMapStore.getState().mapInstance.latLngToContainerPoint(itemLatLng)

				//Calculate top left point and convert to latLng
				const topLeftPoint = L.point(itemContainerPoint.x - lvl2ItemWidthOffset, itemContainerPoint.y - lvl2ItemHeightOffset)
				const topLeftLatLng = useMapStore.getState().mapInstance.containerPointToLatLng(topLeftPoint)

				//Calculate bottom right point and convert to latLng
				const bottomRightPoint = L.point(itemContainerPoint.x + lvl2ItemWidthOffset, itemContainerPoint.y + lvl2ItemHeightOffset)
				const bottomRightLatLng = useMapStore.getState().mapInstance.containerPointToLatLng(bottomRightPoint)

				//Create item latLngBounds
				const latLngBounds = L.latLngBounds(topLeftLatLng, bottomRightLatLng)

				//Draw item rect for select item
				const rect = L.rectangle(latLngBounds).addTo(useMapStore.getState().mapInstance)

				//Store rect in item
				useMapGlobalSelectionStore.getState().updateSelectedItemRect(item.uuid, rect)
				useMapGlobalSelectionStore.getState().selectionLayerGroup.addLayer(rect)
			} else {
				//Define width and height
				const width = 30
				const height = 30

				//Get item latLng and convert to container point
				const itemLatLng = L.latLng(item.lat, item.lng)
				const itemContainerPoint = useMapStore.getState().mapInstance.latLngToContainerPoint(itemLatLng)

				//Calculate top left point and convert to latLng
				const topLeftPoint = L.point(itemContainerPoint.x - width, itemContainerPoint.y - height)
				const topLeftLatLng = useMapStore.getState().mapInstance.containerPointToLatLng(topLeftPoint)

				//Calculate bottom right point and convert to latLng
				const bottomRightPoint = L.point(itemContainerPoint.x + width, itemContainerPoint.y + height)
				const bottomRightLatLng = useMapStore.getState().mapInstance.containerPointToLatLng(bottomRightPoint)

				//Create item latLngBounds
				const latLngBounds = L.latLngBounds(topLeftLatLng, bottomRightLatLng)

				//Draw item rect for select item
				const rect = L.rectangle(latLngBounds).addTo(useMapStore.getState().mapInstance)

				//Store rect in item and add to layer group
				useMapGlobalSelectionStore.getState().updateSelectedItemRect(item.uuid, rect)
				useMapGlobalSelectionStore.getState().selectionLayerGroup.addLayer(rect)
			}

			//Update item draw state
			useMapGlobalSelectionStore.getState().updateSelectedItemDrawState(item.uuid, true)
		}
	})

	if (regenerateSelection){
		regenerateSelectionLayer()
	}
	//
	// console.log(items)

	// items.forEach(item => {
	// 	if (!item.drawState){
	// 		//Check if item is render in popup
	// 		if (useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === item.uuid)[0]) {
	// 			//Select item popup
	// 			useContentItemStore.getState().updateRenderedLevel2SelectState(item.uuid, true)
	// 			updateLevel2Content(item.uuid)
	//
	// 			//Get item popup
	// 			const itemPopup = useContentItemStore.getState().renderedLevel2Items.filter(i => i.uuid === item.uuid)[0].popup
	//
	// 			//Create transparent layer for selection bounds
	// 			const rect = L.rectangle(L.latLngBounds(itemPopup.getLatLng(), itemPopup.getLatLng()), {color: "transparent"}).addTo(useMapStore.getState().mapInstance)
	// 			useMapGlobalSelectionStore.getState().selectionLayerGroup.addLayer(rect)
	// 		} else {
	// 			//Generate select rectangle layer
	// 			const circle = L.circle([item.lat, item.lng], {radius: 8000}).addTo(useMapStore.getState().mapInstance)
	// 			const rect = L.rectangle(circle.getBounds(), {weight: 1}).addTo(useMapStore.getState().mapInstance)
	// 			circle.remove()
	//
	// 			//Add select rectangle ton global selection layer group
	// 			useMapGlobalSelectionStore.getState().selectionLayerGroup.addLayer(rect)
	// 		}
	//
	// 		useMapGlobalSelectionStore.getState().updateSelectedItemDrawState(item.uuid, true)
	// 	}
	//
	// })
	//
	// if (!useMapGlobalSelectionStore.getState().drawState){
	// 	regenerateSelectionLayer()
	// } else {
	// 	generateTempDrawLayer()
	// }
}

export function renderGlobalSelectedArea(regenerateSelection = false){
	//Get all selected areas
	const areas = useMapGlobalSelectionStore.getState().selectedAreas

	areas.forEach(area => {
		if (!area.drawState){
			console.log("draw area")
			//Render area selection rectangle
			const rect = L.rectangle(area.bounds, {weight: 1}).addTo(useMapStore.getState().mapInstance)

			//Add selection rectangle to layer group and add rect to item
			useMapGlobalSelectionStore.getState().selectionLayerGroup.addLayer(rect)
			useMapGlobalSelectionStore.getState().updateSelectedAreaRect(area.uuid, rect)

			//Update area draw state
			useMapGlobalSelectionStore.getState().updateSelectedAreaDrawState(area.uuid, true)
		}
	})

	if (regenerateSelection){
		regenerateSelectionLayer()
	}

	// if (!useMapGlobalSelectionStore.getState().drawState){
	// 	regenerateSelectionLayer()
	// } else {
	// 	generateTempDrawLayer()
	// }
}

export function renderGlobalSelectedShapes(){
	//Get all selected shapes
	const shapes = useMapGlobalSelectionStore.getState().selectedShapes

	shapes.forEach(shape => {
		if (!shape.drawState){
			let layer = null
			if (shape.type === "LineString"){
				//Generate shape line
				layer = L.polyline(shape.points, {weight: 1}).addTo(useMapStore.getState().mapInstance)
			} else if (shape.type === "Polygon"){
				//Generate shape polygon
				layer = L.polygon(shape.points, {weight: 1}).addTo(useMapStore.getState().mapInstance)
			}

			//Add selection rectangle to layer group
			const selectionLayerGroup = useMapGlobalSelectionStore.getState().selectionLayerGroup
			selectionLayerGroup.addLayer(layer)
			useMapGlobalSelectionStore.setState({selectionLayerGroup: selectionLayerGroup})
			useMapGlobalSelectionStore.getState().updateSelectedShapeDrawState(shape.uuid, true)
		}

	})

	// if (!useMapGlobalSelectionStore.getState().drawState){
	// 	regenerateSelectionLayer()
	// } else {
	// 	generateTempDrawLayer()
	// }
}

export function convertLatLngBoundsToCoordinatesBounds(bounds){
	const northWestPoint = L.Projection.SphericalMercator.project(bounds.getNorthWest())
	const southEastPoint = L.Projection.SphericalMercator.project(bounds.getSouthWest())

	return [northWestPoint.x, southEastPoint.y, southEastPoint.x, northWestPoint.y]
}

export function regenerateSelectionLayer(){
	if (useMapGlobalSelectionStore.getState().tempSelectionLayer){
		useMapGlobalSelectionStore.getState().tempSelectionLayer.remove()
		useMapGlobalSelectionStore.setState({tempSelectionLayer: null})
	}

	const newSelectionBounds = L.latLngBounds()
	const selectionLayerGroup = useMapGlobalSelectionStore.getState().selectionLayerGroup
	let hasLayer = false
	let allLayers = []

	selectionLayerGroup.eachLayer(layer => {
		if (layer.id !== "selectionLayer"){
			hasLayer = true
			newSelectionBounds.extend(layer.getBounds())
			allLayers.push(layer)
		}
	})

	if (useMapGlobalSelectionStore.getState().selectionLayer){
		selectionLayerGroup.removeLayer(useMapGlobalSelectionStore.getState().selectionLayer)
	}

	if (!hasLayer){
		return
	}

	//Generate new selection layer and add to map
	const newSelectionLayer = L.rectangle(newSelectionBounds, {color: "#2953e8", fillColor: "transparent", dashArray: "10, 10", weight: 2})
	newSelectionLayer.id = "selectionLayer"

	//Add new selection later to layer group
	selectionLayerGroup.addLayer(newSelectionLayer)

	newSelectionLayer.addTo(useMapStore.getState().mapInstance)
	newSelectionLayer.bringToFront()

	//Remove old selection layer
	if (useMapGlobalSelectionStore.getState().selectionLayer){
		useMapGlobalSelectionStore.getState().selectionLayer.remove()
	}

	newSelectionLayer.pm.enableLayerDrag()

	//Update selection later in store
	useMapGlobalSelectionStore.setState({selectionLayer: newSelectionLayer, selectionLayerGroup: selectionLayerGroup})
}

export function updateGlobalSelectionItemRect(){
	const selectedItems = useMapGlobalSelectionStore.getState().selectedItems;

	//Delete all selected item rect
	selectedItems.forEach(i => {
		//Remove item rect from selection layer group
		useMapGlobalSelectionStore.getState().selectionLayerGroup.removeLayer(i.rect)

		//Remove rect from map
		i.rect.remove()

		//Update item draw state and item rect
		useMapGlobalSelectionStore.getState().updateSelectedItemDrawState(i.uuid, false)
		useMapGlobalSelectionStore.getState().updateSelectedItemRect(i.uuid, null)
	})

	//Regenerate all item selection layer
	renderGlobalSelectedItems(true)
}

function generateTempDrawLayer(){
	console.log("temp draw layer")
}

