import React from "react";
import {BiSolidFolder} from "react-icons/bi";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useDashboardStore} from "../../../services/Stores/DashboardStore";
import {useHistory} from "react-router-dom";

const MapCard = ({map, handleOpenContextMenu}) => {
	//Get current selected projects in store
	const currentSelectedMaps = useDashboardStore(state => state.currentSelectedMaps)

	//Get history
	const history = useHistory()

	//Toggle project selection
	const handleToggleMapSelection = () => {
		if (!currentSelectedMaps.includes(map.uuid)){
			useDashboardStore.getState().selectMap(map.uuid)
		} else {
			useDashboardStore.getState().unselectMap(map.uuid)
		}
	}

	//Open map
	const handleOpenMap = () => {
		history.push("/user/map/" + map["base32Uuid"])
	}

	return (
		<div onContextMenu={(event) => handleOpenContextMenu(event, map)} onDoubleClick={handleOpenMap} className={currentSelectedMaps.includes(map.uuid) ? "custom__project-card-container noBoarderImp selected" : "custom__project-card-container noBoarderImp noPadCard"} onClick={handleToggleMapSelection}>
			<div className="map-screenshot-container">
				{map.screenshot && (
					<>
						{map.screenshot !== "" && (
							<>
								<img src={map.screenshot} alt="Map screen"/>
							</>
						)}
					</>
				)}
			</div>
			<div className="project-card-infos-container">
				<p className="project-name" title={map.name}>{map.name}</p>
				<p className="project-menu-btn" onClick={(event) => handleOpenContextMenu(event, map)}>
					<BsThreeDotsVertical />
				</p>
			</div>
		</div>
	)
}

export default MapCard