import React, {useContext, useEffect, useRef, useState} from "react";
import {BiLogoApple, BiLogoGoogle} from "react-icons/bi";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import AuthAPI from "../../services/AuthAPI";
import {toast} from "react-toastify";
import {getBrowser} from "../../services/Utils";
import {useHistory, useParams} from "react-router-dom";
import IsAdminContext from "../../contexts/IsAdminContext";
import AuthContext from "../../contexts/AuthContext";
import LoginSessionPopup from "../../components/LoginSessionPopup";
import {TailSpin} from "react-loader-spinner";
import weeki_logo from "../../../images/weeki_logo.svg"
import {createErrorToast, createSuccessToast} from "../../services/CustomToastUtils";
import {EMAIL_REGEX} from "../../../Regex";
import {use} from "i18next";
import {IoCheckmarkSharp, IoClose} from "react-icons/io5";

const ResetPassword = () => {
    //Get auth context method
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)

    const {token} = useParams()

    //Get history
    const history = useHistory()

    //Define default state for load sign button status
    const [signButtonLoading, setSignButtonLoading] = useState(false)

    //Define default state for number password conditions
    const [numberConditions, setNumberConditions] = useState(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"])

    //Define default state for symbol password conditions
    const [symbolConditions, setSymbolConditions] = useState(["!", "@", "$", "#", "_"])

    //Define check password uppercase and lowercase regex
    const passwordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).+$")

    //Define default state for password view
    const [viewPassword, setViewPassword] = useState(false)

    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")

    const handleValid = async () => {
        if (password.length < 12){
            createErrorToast("Your password must be contain at least 12 characters")
            return
        }
        if (!passwordPattern.test(password)){
            createErrorToast("Your password must be contain at least one upper and lower case letter")
            return
        }
        if (!numberConditions.some(e => password.includes(e))){
            createErrorToast("Your password must be contain at least 1 number")
            return
        }
        if (!symbolConditions.some(e => password.includes(e))){
            createErrorToast("Your password must be contain at least 1 symbol")
            return
        }

        //Check if password and confirmation are the same
        if (password !== passwordConfirm){
            createErrorToast("Password and confirmation need match")
            return
        }

        setSignButtonLoading(true)
        if (await AuthAPI.userResetPassword(password, passwordConfirm, token)){
            createSuccessToast("Password successfully reset")
            history.push("/")
        } else {
            setSignButtonLoading(false)
        }
    }

    if (isAuthenticated){
        history.push("/user/dashboard/maps")
    }

    return (
        <>
            <div className="auth-container">
                <div className="top-button" onClick={() => history.push('/')}>Se connecter</div>
                <div className="auth-content">
                    <img className="logo" src={weeki_logo} alt="Weeki logo"/>
                    <h1 className="title">Reset your password</h1>
                    <div className="auth-form-container">
                        <div className="auth-input-container">
                            <input value={password} onChange={(event) => setPassword(event.currentTarget.value)} className="auth-input" type={viewPassword ? "text" : "password"} placeholder="Mot de passe"/>
                            <p className="input-icon" onClick={() => setViewPassword(!viewPassword)}>
                                {viewPassword
                                    ? <FaEyeSlash />
                                    : <FaEye />
                                }
                            </p>
                        </div>
                        <div className="auth-input-container">
                            <input value={passwordConfirm} onChange={(event) => setPasswordConfirm(event.currentTarget.value)} className="auth-input" type="password" placeholder="Confirmation"/>
                        </div>
                        {password.length > 0 && (
                            <>
                                {password.length < 12 || !passwordPattern.test(password) || !numberConditions.some(e => password.includes(e)) || !symbolConditions.some(e => password.includes(e))
                                    ?
                                    <div className="password-requirement">
                                        <div className="field">
                                            <div className="icon">
                                                {password.length < 12
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use at least 12 characters</div>
                                        </div>
                                        <div className="field">
                                            <div className="icon">
                                                {!passwordPattern.test(password)
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use at least one upper and lower case lettre (e.g. Aa)</div>
                                        </div>
                                        <div className="field">
                                            <div className="icon">
                                                {!numberConditions.some(e => password.includes(e))
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use at least one number</div>
                                        </div>
                                        <div className="field">
                                            <div className="icon">
                                                {!symbolConditions.some(e => password.includes(e))
                                                    ? <IoClose color="#ff5252" />
                                                    : <IoCheckmarkSharp color="#2dd7a7" />
                                                }
                                            </div>
                                            <div className="text">Use a symbol (e.g. !@$#_)</div>
                                        </div>
                                    </div>
                                    : null
                                }
                            </>

                        )}
                    </div>
                    <p className="sign-btn" onClick={handleValid}>
                        {signButtonLoading
                            ? <TailSpin height="19" width="19" color="#FFFFFF" radius={1} />
                            : "Valider"
                        }
                    </p>
                </div>
            </div>
        </>
    )
}

export default ResetPassword