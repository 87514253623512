import getAxiosInstance from "../../../AxiosInstance";
import {USER_MOOC_COURSE_API, USER_MOOC_QUIZ_API} from "../../../../../Router";

function userGetAllChapterQuizzes(chapterUuid){
	return getAxiosInstance().get(USER_MOOC_QUIZ_API + '/get_all_chapter_quizzes', {params: {chapterUuid: chapterUuid}})
}

function userCreateMoocQuiz(chapterUuid){
	const sendData = new FormData()
	sendData.append('chapterUuid', chapterUuid)

	return getAxiosInstance().post(USER_MOOC_QUIZ_API + '/create', sendData)
		.then((response) => {
			if (response.status === 200){
				return {'uuid': response.data.uuid, 'position': response.data.position}
			}

			return false
		})
		.catch(() => {
			return false
		})
}

function userRenameQuiz(uuid, name){
	const sendData = new FormData()
	sendData.append('quizUuid', uuid)
	sendData.append('name', name)

	return getAxiosInstance().post(USER_MOOC_QUIZ_API + '/rename', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userUpdateQuizPosition(uuid, newPosition){
	const sendData = new FormData()
	sendData.append('quizUuid', uuid)
	sendData.append('newPosition', newPosition)

	return getAxiosInstance().post(USER_MOOC_QUIZ_API + '/update_position', sendData)
		.then((response) => {
			return response.status === 200;
		})
		.catch(() => {
			return false
		})
}

function userDeleteMoocQuiz(uuid){
	return getAxiosInstance().delete(USER_MOOC_QUIZ_API + '/delete', {params: {uuid: uuid}})
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

export default {
	userGetAllChapterQuizzes,
	userCreateMoocQuiz,
	userRenameQuiz,
	userDeleteMoocQuiz,
	userUpdateQuizPosition
}