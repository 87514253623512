import React, {useEffect, useState} from "react";
import icon_card_1 from "../../../icons/admin-dashboard/store/card-1.svg";
import icon_card_2 from "../../../icons/admin-dashboard/store/card-2.svg";
import icon_card_3 from "../../../icons/admin-dashboard/store/card-3.svg";
import icon_card_4 from "../../../icons/admin-dashboard/store/card-4.svg";
import {FaCheck, FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp, FaSort, FaSortDown, FaSortUp} from "react-icons/fa";
import {BiHistory} from "react-icons/bi";
import {BsThreeDots} from "react-icons/bs";
import {IoMdClose} from "react-icons/io";
import {IoSearchSharp} from "react-icons/io5";
import {FiAlertTriangle} from "react-icons/fi";
import ShowOrHideColumnMenu from "../../components/ShowOrHideColumnMenu";
import Export from "../../components/Export";
import ModelsAPI from "../../services/ModelsAPI";

import success_send from "../../../icons/admin-dashboard/emails/success-send.svg";
import StoreDashboardStatCard from "../../components/StoreDashboardStatCard";
import StatsAPI from "../../services/StatsAPI";
import Chart from "react-apexcharts";
import {toast} from "react-toastify";
import TopNavBar from "../../components/TopNavBar";
import SideBarNav from "../../components/SideBarNav";
import {TailSpin} from "react-loader-spinner";

const AdminDashboardStore = () => {
    //Define default state for get models request status
    const [getModelsRequestStatus, setGetModelsRequestStatus] = useState(false)

    const [models, setModels] = useState([{
        "id": "",
        "authorName": "",
        "authorEmail": "",
        "name": "",
        "description": "",
        "status": "",
        "type": "",
        "field": "",
        "createdAt": "",
        "purchasedPrice": "",
        "sellingPrice": "",
        "submittedPrice": "",
        "submissionDate": "",
        "sales": ""
    }])

    //Define default state for stats list
    const [stats, setStats] = useState({
        "card-2": {
            "sales": [{
                "year": "",
                "type": "",
                "field": ""
            }]
        },
        "card-3": {
            "totalModelTransactionSum": 0
        },
        "card-4": {
            "averagePriceModelSales": 0
        }
    })

    const [modelSalesChart, setModelSalesChart] = useState({
        "labels": [],
        "data": []
    })

    //Get models
    useEffect(() => {
        ModelsAPI.adminGetAllModels().then((response) => {
            if (response.status === 200){
                setModels(response.data)
            }
            setGetModelsRequestStatus(true)
        })

        StatsAPI.getStatsForStoreDashboard().then((response) => {
            setStats(response.data)
        })

        StatsAPI.getModelsSalesChart().then((response) => {
            setModelSalesChart(response.data)
        })
    }, [])

    /******************************SEARCH********************************/
    const [submittedModelsSearch, setSubmittedModelsSearch] = useState("")
    const filteredSubmittedModels = models.filter(m =>
        m.status === 0 && (
            String(m.authorName).toLowerCase().includes(submittedModelsSearch.toLowerCase()) ||
            String(m.authorEmail).toLowerCase().includes(submittedModelsSearch.toLowerCase()) ||
            String(m.name).toLowerCase().includes(submittedModelsSearch.toLowerCase()) ||
            String(m.submissionDate).toLowerCase().includes(submittedModelsSearch.toLowerCase()) ||
            String(m.type).toLowerCase().includes(submittedModelsSearch.toLowerCase()) ||
            String(m.field).toLowerCase().includes(submittedModelsSearch.toLowerCase()) ||
            String(m.submittedPrice).toLowerCase().includes(submittedModelsSearch.toLowerCase())
        )
    )

    const [acceptedModelsSearch, setAcceptedModelsSearch] = useState("")
    const filteredAcceptedModel = models.filter(m =>
        (m.status === 1 || m.status === 2 || m.status === 3) && (
            String(m.authorName).toLowerCase().includes(acceptedModelsSearch.toLowerCase()) ||
            String(m.authorEmail).toLowerCase().includes(acceptedModelsSearch.toLowerCase()) ||
            String(m.name).toLowerCase().includes(acceptedModelsSearch.toLowerCase()) ||
            String(m.submissionDate).toLowerCase().includes(acceptedModelsSearch.toLowerCase()) ||
            String(m.type).toLowerCase().includes(acceptedModelsSearch.toLowerCase()) ||
            String(m.field).toLowerCase().includes(acceptedModelsSearch.toLowerCase()) ||
            String(m.submittedPrice).toLowerCase().includes(acceptedModelsSearch.toLowerCase())
        )
    )
    /*************************************************************************/

    /*************************SHOW/HIDE COLUMN********************************/
    const [showHideStatusSubmittedModels, setShowHideStatusSubmittedModels] = useState(false)
    const [columnListSubmittedModels, setColumnListSubmittedModels] = useState({
        "email": true,
        "model name": true,
        "proposal date": true,
        "type": true,
        "field": true,
        "size": true,
        "price": true
    })

    const resetSubmittedModelsColumnList = () => {
        setColumnListSubmittedModels({
            "email": true,
            "model name": true,
            "proposal date": true,
            "type": true,
            "field": true,
            "size": true,
            "price": true
        })
    }

    const [showHideStatusAcceptedModels, setShowHideStatusAcceptedModels] = useState(false)
    const [columnListAcceptedModels, setColumnListAcceptedModels] = useState({
        "author": true,
        "status": true,
        "type": true,
        "field": true,
        "size": true,
        "date of creation": true,
        "bought at": true,
        "sale price": true,
        "sales": true
    })

    const resetAcceptedModelsColumnList = () => {
        setColumnListAcceptedModels({
            "author": true,
            "status": true,
            "type": true,
            "field": true,
            "size": true,
            "date of creation": true,
            "bought at": true,
            "sale price": true,
            "sales": true
        })
    }
    /*************************************************************************/

    /*****************************PAGINATION SUBMITTED MODELS*****************/
    const [currentPage, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const maxPage = Math.ceil(filteredSubmittedModels.length / itemPerPage)

    const nextPage = () => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }
    const previousPage = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }

    const start = (currentPage - 1) * itemPerPage
    const end = start + itemPerPage
    const paginatedSubmittedModels = filteredSubmittedModels.slice(start, end)

    const pageButtons = []

    for (let i = 0; i < maxPage; i++){
        pageButtons.push(<p onClick={() => setCurrentPage(i + 1)} className={currentPage === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /*****************************PAGINATION ACCEPTED MODELS******************/
    const [currentPageAcceptedModel, setCurrentPageAcceptedModel] = useState(1)
    const [itemPerPageAcceptedModel, setItemPerPageAcceptedModel] = useState(10)
    const maxPageAcceptedModel = Math.ceil(filteredAcceptedModel.length / itemPerPageAcceptedModel)

    const nextPageAcceptedModel = () => {
        setCurrentPageAcceptedModel((currentPageAcceptedModel) => Math.min(currentPageAcceptedModel + 1, maxPageAcceptedModel))
    }
    const previousPageAcceptedModel = () => {
        setCurrentPageAcceptedModel((currentPageAcceptedModel) => Math.max(currentPageAcceptedModel - 1, 1))
    }

    const startAcceptedModel = (currentPageAcceptedModel - 1) * itemPerPageAcceptedModel
    const endAcceptedModel = startAcceptedModel + itemPerPageAcceptedModel
    const paginatedAcceptedModels = filteredAcceptedModel.slice(startAcceptedModel, endAcceptedModel)

    const pageButtonsAcceptedModel = []

    for (let i = 0; i < maxPageAcceptedModel; i++){
        pageButtonsAcceptedModel.push(<p onClick={() => setCurrentPageAcceptedModel(i + 1)} className={currentPageAcceptedModel === (i + 1) ? "selected" : null}>{i + 1}</p>)
    }
    /*************************************************************************/

    /******************************MINI CARD FILTER***************************/
    const currentYear = new Date().getFullYear()
    const yearList = [currentYear, currentYear - 1, currentYear -2]
    const [card1SelectedYear, setCard1SelectedYear] = useState("All")
    const [card1SelectedType, setCard1SelectedType] = useState("All")
    const [card1SelectedField, setCard1SelectedField] = useState("All")
    
    const [card2SelectedYear, setCard2SelectedYear] = useState("All")
    const [card2SelectedType, setCard2SelectedType] = useState("All")
    const [card2SelectedField, setCard2SelectedField] = useState("All")
    
    const [card3SelectedYear, setCard3SelectedYear] = useState("All")
    const [card3SelectedType, setCard3SelectedType] = useState("All")
    const [card3SelectedField, setCard3SelectedField] = useState("All")
    
    const [card4SelectedYear, setCard4SelectedYear] = useState("All")
    const [card4SelectedType, setCard4SelectedType] = useState("All")
    const [card4SelectedField, setCard4SelectedField] = useState("All")

    const [chartStatus, setChartStatus] = useState(false)
    /*************************************************************************/

    /**********************VIEW SUBMITTED MODEL DESCRIPTION MODAL*************/
    const [viewSubmittedModelDescriptionModalStatus, setViewSubmittedModelDescriptionModalStatus] = useState(false)
    const [viewedSubmittedModelDescriptionId, setViewedSubmittedModelDescriptionId] = useState(0)
    /*************************************************************************/

    /***************************UPDATE ACCEPTED MODEL MODAL*******************/
    const [updateAcceptedModelModalStatus, setUpdateAcceptedModelModalStatus] = useState(false)
    const [updateAcceptedModelStatus, setUpdateAcceptedModelStatus] = useState(false)
    const [updatedModelId, setUpdatedModelId] = useState(0)
    const [updateAcceptedModelInfo, setUpdateAcceptedModelInfo] = useState({
        "id": "",
        "authorName": "",
        "authorEmail": "",
        "name": "",
        "description": "",
        "status": "",
        "type": "",
        "field": "",
        "createdAt": "",
        "purchasedPrice": "",
        "sellingPrice": "",
        "submittedPrice": "",
        "submissionDate": "",
        "sales": ""
    })

    useEffect(() => {
        if (updatedModelId !== 0){
            setUpdateAcceptedModelInfo(models.filter(m => m.id === updatedModelId)[0])
        }
    }, [updatedModelId])

    const updateModel = async () => {
        if (updateAcceptedModelInfo.description !== ""){
            if (await ModelsAPI.updateModel(updateAcceptedModelInfo)){
                toast.success("Model updated successfully")
                setUpdateAcceptedModelModalStatus(false)
                setGetModelsRequestStatus(false)
                ModelsAPI.adminGetAllModels().then((response) => {
                    if (response.status === 200){
                        setModels(response.data)
                    } else if (response.status === 204){
                        setModels([{
                            "id": "",
                            "authorName": "",
                            "authorEmail": "",
                            "name": "",
                            "description": "",
                            "status": "",
                            "type": "",
                            "field": "",
                            "createdAt": "",
                            "purchasedPrice": "",
                            "sellingPrice": "",
                            "submittedPrice": "",
                            "submissionDate": "",
                            "sales": ""
                        }])
                    }
                    setGetModelsRequestStatus(true)
                })
            }
        }
    }
    /*************************************************************************/

    /*******************************ACCEPT PROPOSAL***************************/
    const [acceptProposalModalStatus, setAcceptProposalModalStatus] = useState(false)

    const acceptProposal = async (event) => {
        const modelId = event.currentTarget.id

        if (await ModelsAPI.acceptProposal(modelId)){
            toast.success("Model proposal accepted successfully")
            setGetModelsRequestStatus(false)
            ModelsAPI.adminGetAllModels().then((response) => {
                if (response.status === 200){
                    setModels(response.data)
                }
                setGetModelsRequestStatus(true)
            })
        }
    }
    /*************************************************************************/

    /*******************************REJECT PROPOSAL***************************/
    const [rejectProposalModalStatus, setRejectProposalModalStatus] = useState(false)

    const rejectProposal = async (event) => {
        const modelId = event.currentTarget.id

        if (await ModelsAPI.rejectProposal(modelId)){
            toast.success("Model proposal rejected successfully")
            setGetModelsRequestStatus(false)
            ModelsAPI.adminGetAllModels().then((response) => {
                if (response.status === 200){
                    setModels(response.data)
                } else if (response.status === 204){
                    setModels([{
                        "id": "",
                        "authorName": "",
                        "authorEmail": "",
                        "name": "",
                        "description": "",
                        "status": "",
                        "type": "",
                        "field": "",
                        "createdAt": "",
                        "purchasedPrice": "",
                        "sellingPrice": "",
                        "submittedPrice": "",
                        "submissionDate": "",
                        "sales": ""
                    }])
                }
                setGetModelsRequestStatus(true)
            })
        }
    }
    /*************************************************************************/

    const [openedArraySubMenuSubmittedModelId, setOpenedArraySubMenuSubmittedModelId] = useState(0)

    /*******************************SORT PROPOSED MODELS**********************/
    const [sortedColumnNameProposedModels, setSortedColumnNameProposedModels] = useState("")
    const [sortedTypeProposedModels, setSortedTypeProposedModels] = useState("")

    if (sortedColumnNameProposedModels !== "" && sortedTypeProposedModels !== ""){
        if (sortedTypeProposedModels === "1"){
            paginatedSubmittedModels.sort((a, b) => String(a[sortedColumnNameProposedModels]).localeCompare(String(b[sortedColumnNameProposedModels])))
        }
        if (sortedTypeProposedModels === "2") {
            paginatedSubmittedModels.sort((a, b) => String(b[sortedColumnNameProposedModels]).localeCompare(String(a[sortedColumnNameProposedModels])))
        }
    }
    /*************************************************************************/

    /*******************************SORT ACCEPTED MODELS**********************/
    const [sortedColumnNameAcceptedModels, setSortedColumnNameAcceptedModels] = useState("")
    const [sortedTypeAcceptedModels, setSortedTypeAcceptedModels] = useState("")

    if (sortedColumnNameAcceptedModels !== "" && sortedTypeAcceptedModels !== ""){
        if (sortedTypeAcceptedModels === "1"){
            paginatedAcceptedModels.sort((a, b) => String(a[sortedColumnNameAcceptedModels]).localeCompare(String(b[sortedColumnNameAcceptedModels])))
        }
        if (sortedTypeAcceptedModels === "2") {
            paginatedAcceptedModels.sort((a, b) => String(b[sortedColumnNameAcceptedModels]).localeCompare(String(a[sortedColumnNameAcceptedModels])))
        }
    }
    /*************************************************************************/

    const dashboard = document.getElementById("scroll-container")

    return (
        <>
            {/*VIEW SUBMITTED MODEL DESCRIPTION MODAL*/}
            {viewSubmittedModelDescriptionModalStatus &&(
                <div className="modal-overlay">
                    <div className={viewSubmittedModelDescriptionModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Model *{models.filter(m => m.id === viewedSubmittedModelDescriptionId)[0].name}* description</h1>
                            <p onClick={() => setViewSubmittedModelDescriptionModalStatus(false)}><IoMdClose /></p>
                        </div>
                        <div className="content">
                            {models.filter(m => m.id === viewedSubmittedModelDescriptionId)[0].description}
                        </div>
                    </div>
                </div>
            )}
            {/*Accept proposal modal*/}
            {acceptProposalModalStatus &&(
                <div className="modal-overlay">
                    <div className={acceptProposalModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Accept proposal</h1>
                            <p onClick={() => setAcceptProposalModalStatus(false)}><IoMdClose /></p>
                        </div>
                        <div className="content proposal">
                            <p className="message"><span><FaCheck /></span>Conversion proposal accepted. Entry added in the second table.</p>
                            <p className="message alert"><span><FiAlertTriangle /></span>Price still has to be set for the model to be made available in the store.</p>
                        </div>
                    </div>
                </div>
            )}
            {/*REFUSE proposal modal*/}
            {rejectProposalModalStatus &&(
                <div className="modal-overlay">
                    <div className={rejectProposalModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Refuse proposal</h1>
                            <p onClick={() => setRejectProposalModalStatus(false)}><IoMdClose /></p>
                        </div>
                        <div className="content proposal">
                            <p className="message danger"><span><IoMdClose /></span>Conversion proposal refused. Entry removed in the first table.</p>
                        </div>
                    </div>
                </div>
            )}
            {/*UPDATE ACCEPTED MODEL MODAL*/}
            {updateAcceptedModelModalStatus && (
                <div className="modal-overlay">
                    <div className={updateAcceptedModelModalStatus ? "modal open" : "modal"}>
                        <div className="header">
                            <h1>Update *{updateAcceptedModelInfo.name}* model</h1>
                            <p onClick={() => {
                                setUpdateAcceptedModelModalStatus(false)
                                setUpdateAcceptedModelStatus(false)
                                setUpdatedModelId(0)
                            }}><IoMdClose /></p>
                        </div>
                        {!updateAcceptedModelStatus && (
                            <div className="content create-template">
                                <div className="input-container">
                                    <div className="input-box">
                                        <p className="title">Sale price (€)</p>
                                        <input placeholder="Model sale price" className="text-input" type="text" value={updateAcceptedModelInfo.sellingPrice} onChange={(event) => setUpdateAcceptedModelInfo({...updateAcceptedModelInfo, ['sellingPrice']: event.currentTarget.value})}/>
                                    </div>
                                    <div className="selector-box">
                                        <p className="title">Type</p>
                                        <div className="selector">
                                            <p onClick={() => updateAcceptedModelInfo.type !== "Model" ? setUpdateAcceptedModelInfo({...updateAcceptedModelInfo, ['type']: "Model"}) : null} className={updateAcceptedModelInfo.type === "Model" ? "active" : null}>Model</p>
                                            <p onClick={() => updateAcceptedModelInfo.type !== "Webinar" ? setUpdateAcceptedModelInfo({...updateAcceptedModelInfo, ['type']: "Webinar"}) : null} className={updateAcceptedModelInfo.type === "Webinar" ? "active" : null}>Webinar</p>
                                            <p onClick={() => updateAcceptedModelInfo.type !== "Mooc" ? setUpdateAcceptedModelInfo({...updateAcceptedModelInfo, ['type']: "Mooc"}) : null} className={updateAcceptedModelInfo.type === "Mooc" ? "active" : null}>Mooc</p>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Field</p>
                                        <select className="select-input" value={updateAcceptedModelInfo.field} onChange={(event) => setUpdateAcceptedModelInfo({...updateAcceptedModelInfo, ['field']: event.currentTarget.value})}>
                                            <option value="Cybersecurity">Cybersecurity</option>
                                            <option value="Statistics">Statistics</option>
                                        </select>
                                    </div>
                                    <div className="input-box">
                                        <p className="title">Description</p>
                                        <textarea rows="10" value={updateAcceptedModelInfo.description} onChange={(event) => setUpdateAcceptedModelInfo({...updateAcceptedModelInfo, ['description']: event.currentTarget.value})}>{updateAcceptedModelInfo.description}</textarea>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <div className="btn cancel" onClick={() => {
                                        setUpdateAcceptedModelModalStatus(false)
                                        setUpdateAcceptedModelStatus(false)
                                        setUpdatedModelId(0)
                                    }}>
                                        <p className="text">Cancel</p>
                                        <p className="icon"><IoMdClose /></p>
                                    </div>
                                    <div className="btn valid" onClick={updateModel}>
                                        <p className="text">Confirm </p>
                                        <p className="icon"><FaCheck /></p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {updateAcceptedModelStatus && (
                            <div className="content delete-template-mail">
                                <div className="content create-template-mail">
                                    <div className="success-container">
                                        <img src={success_send} alt="Success send icon"/>
                                        <h1 className="message">Model successfully updated</h1>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <TopNavBar />
            <SideBarNav />
            <main className="dashboard">
                <div className="admin-dashboard">
                    <h1 className="page-title">Overview</h1>
                    <div className="card-container">
                        <StoreDashboardStatCard icon={icon_card_1} title="Total numbers of available models" value={
                            card1SelectedYear === "All" && card1SelectedType === "All" && card1SelectedField === "All" ? models.filter(m => m.status === 2).length :
                            card1SelectedYear !== "All" && card1SelectedType === "All" && card1SelectedField === "All" ? models.filter(m => m.createdAt.includes(card1SelectedYear) && m.status === 2).length :
                            card1SelectedYear === "All" && card1SelectedType !== "All" && card1SelectedField === "All" ? models.filter(m => m.type === card1SelectedType && m.status === 2).length :
                            card1SelectedYear === "All" && card1SelectedType === "All" && card1SelectedField !== "All" ? models.filter(m => m.field === card1SelectedField && m.status === 2).length :
                            card1SelectedYear !== "All" && card1SelectedType !== "All" && card1SelectedField === "All" ? models.filter(m => m.createdAt.includes(card1SelectedYear) && m.type === card1SelectedType && m.status === 2).length :
                            card1SelectedYear === "All" && card1SelectedType !== "All" && card1SelectedField !== "All" ? models.filter(m => m.field === card1SelectedField && m.type === card1SelectedType && m.status === 2).length :
                            card1SelectedYear !== "All" && card1SelectedType === "All" && card1SelectedField !== "All" ? models.filter(m => m.field === card1SelectedField && m.createdAt.includes(card1SelectedYear) && m.status === 2).length :
                            card1SelectedYear !== "All" && card1SelectedType !== "All" && card1SelectedField !== "All" ? models.filter(m => m.field === card1SelectedField && m.createdAt.includes(card1SelectedYear) && m.type === card1SelectedType && m.status === 2).length : 0
                        } augment={false} augmentPercent={0} yearList={yearList} selectedYear={card1SelectedYear} setSelectedYear={setCard1SelectedYear} selectedType={card1SelectedType} setSelectedType={setCard1SelectedType} selectedField={card1SelectedField} setSelectedField={setCard1SelectedField}/>
                        <StoreDashboardStatCard icon={icon_card_3} title="Total number of models sold" value={
                            card2SelectedYear === "All" && card2SelectedType === "All" && card2SelectedField === "All" ? stats["card-2"].sales.length :
                            card2SelectedYear !== "All" && card2SelectedType === "All" && card2SelectedField === "All" ? stats["card-2"].sales.filter(s => s.year === card2SelectedYear).length :
                            card2SelectedYear === "All" && card2SelectedType !== "All" && card2SelectedField === "All" ? stats["card-2"].sales.filter(s => s.type === card2SelectedType).length :
                            card2SelectedYear === "All" && card2SelectedType === "All" && card2SelectedField !== "All" ? stats["card-2"].sales.filter(s => s.field === card2SelectedField).length :
                            card2SelectedYear !== "All" && card2SelectedType !== "All" && card2SelectedField === "All" ? stats["card-2"].sales.filter(s => s.type === card2SelectedType && s.year === card2SelectedYear).length :
                            card2SelectedYear === "All" && card2SelectedType !== "All" && card2SelectedField !== "All" ? stats["card-2"].sales.filter(s => s.field === card2SelectedField && s.type === card2SelectedType).length :
                            card2SelectedYear !== "All" && card2SelectedType === "All" && card2SelectedField !== "All" ? stats["card-2"].sales.filter(s => s.field === card2SelectedField && s.year === card2SelectedYear).length :
                            card2SelectedYear !== "All" && card2SelectedType !== "All" && card2SelectedField !== "All" ? stats["card-2"].sales.filter(s => s.field === card2SelectedField && s.year === card2SelectedYear && s.type === card2SelectedType).length : 0
                        } augment={false} augmentPercent={0} yearList={yearList} selectedYear={card2SelectedYear} setSelectedYear={setCard2SelectedYear} selectedType={card2SelectedType} setSelectedType={setCard2SelectedType} selectedField={card2SelectedField} setSelectedField={setCard2SelectedField}/>
                        <StoreDashboardStatCard icon={icon_card_2} title="Total sum of models sales" value={
                            card3SelectedYear === "All" && card3SelectedType === "All" && card3SelectedField === "All" ? stats["card-3"].totalModelTransactionSum + " €" : 0 + " €"
                        } augment={false} augmentPercent={0} yearList={yearList} selectedYear={card3SelectedYear} setSelectedYear={setCard3SelectedYear} selectedType={card3SelectedType} setSelectedType={setCard3SelectedType} selectedField={card3SelectedField} setSelectedField={setCard3SelectedField}/>
                        <StoreDashboardStatCard icon={icon_card_4} title="Average selling price of a model" value={
                            card4SelectedYear === "All" && card4SelectedType === "All" && card4SelectedField === "All" ? stats["card-4"].averagePriceModelSales + " €" : 0 + " €"
                        } augment={false} augmentPercent={0} yearList={yearList} selectedYear={card4SelectedYear} setSelectedYear={setCard4SelectedYear} selectedType={card4SelectedType} setSelectedType={setCard4SelectedType} selectedField={card4SelectedField} setSelectedField={setCard4SelectedField}/>
                    </div>
                    <div className="metric-container store">
                        <div className={chartStatus ? "metric-card open" : "metric-card"}>
                            <div className="top">
                                <p>Model sales for two last years</p>
                                <p onClick={() => setChartStatus(!chartStatus)}>
                                    {chartStatus
                                        ? <FaChevronUp />
                                        : <FaChevronDown />
                                    }
                                </p>
                            </div>
                            <div className="content">
                                <Chart
                                    options={{
                                        chart: {
                                            type: "bar"
                                        },
                                        xaxis: {
                                            categories: modelSalesChart.labels
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: false,
                                                columnWidth: "50%",
                                                endingShape: "rounded"
                                            }
                                        },
                                        dataLabels: {enabled: false},
                                        stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                        },
                                        fill: {
                                            colors: ["#4E70FF"],
                                            opacity: 1,
                                        },
                                        tooltip: {
                                            y: {
                                                formatter: function(val) {
                                                    return  + val + " sales";
                                                },
                                            },
                                        }
                                    }}
                                    series={[
                                        {
                                            name: "Model sales",
                                            data: modelSalesChart.data,
                                        },

                                    ]}
                                    type="bar"
                                    height={350}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of proposed models</p>
                        <div className="top-container">
                            <div className="left">
                                <ShowOrHideColumnMenu resetColumn={resetSubmittedModelsColumnList} columnList={columnListSubmittedModels} setColumnList={setColumnListSubmittedModels} open={showHideStatusSubmittedModels} setOpen={setShowHideStatusSubmittedModels} />
                                <div className="search-container">
                                    <div className="box">
                                        <div className="icon"><IoSearchSharp /></div>
                                        <input type="text" placeholder="Search by map name" value={submittedModelsSearch} onChange={(event) => setSubmittedModelsSearch(event.currentTarget.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="btn">
                                    <div className="icon"><BiHistory /></div>
                                    <div className="text">History</div>
                                </div>
                                <Export data={models.filter(m => m.status === 0)} filename="weeki_submitted_models" />
                            </div>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td className="left large" onClick={() => {
                                    setSortedColumnNameProposedModels("authorName")
                                    if (sortedTypeProposedModels === ""){
                                        setSortedTypeProposedModels("1")
                                    } else if (sortedTypeProposedModels === "1"){
                                        setSortedTypeProposedModels("2")
                                    } else {
                                        setSortedTypeProposedModels("")
                                    }
                                }}>
                                    User name
                                    <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "authorName" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "authorName" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "authorName" ? <FaSort /> : null}
                                    </span>
                                </td>
                                {columnListSubmittedModels.email && (
                                    <td onClick={() => {
                                        setSortedColumnNameProposedModels("authorEmail")
                                        if (sortedTypeProposedModels === ""){
                                            setSortedTypeProposedModels("1")
                                        } else if (sortedTypeProposedModels === "1"){
                                            setSortedTypeProposedModels("2")
                                        } else {
                                            setSortedTypeProposedModels("")
                                        }
                                    }}>
                                        Email
                                        <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "authorEmail" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "authorEmail" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "authorEmail" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListSubmittedModels["model name"] && (
                                    <td onClick={() => {
                                        setSortedColumnNameProposedModels("name")
                                        if (sortedTypeProposedModels === ""){
                                            setSortedTypeProposedModels("1")
                                        } else if (sortedTypeProposedModels === "1"){
                                            setSortedTypeProposedModels("2")
                                        } else {
                                            setSortedTypeProposedModels("")
                                        }
                                    }}>
                                        Model name
                                        <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "name" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "name" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "name" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListSubmittedModels["proposal date"] && (
                                    <td onClick={() => {
                                        setSortedColumnNameProposedModels("submissionDate")
                                        if (sortedTypeProposedModels === ""){
                                            setSortedTypeProposedModels("1")
                                        } else if (sortedTypeProposedModels === "1"){
                                            setSortedTypeProposedModels("2")
                                        } else {
                                            setSortedTypeProposedModels("")
                                        }
                                    }}>
                                        Proposal date
                                        <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "submissionDate" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "submissionDate" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "submissionDate" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListSubmittedModels.type && (
                                    <td onClick={() => {
                                        setSortedColumnNameProposedModels("type")
                                        if (sortedTypeProposedModels === ""){
                                            setSortedTypeProposedModels("1")
                                        } else if (sortedTypeProposedModels === "1"){
                                            setSortedTypeProposedModels("2")
                                        } else {
                                            setSortedTypeProposedModels("")
                                        }
                                    }}>
                                        Type
                                        <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "type" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "type" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "type" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListSubmittedModels.field && (
                                    <td onClick={() => {
                                        setSortedColumnNameProposedModels("field")
                                        if (sortedTypeProposedModels === ""){
                                            setSortedTypeProposedModels("1")
                                        } else if (sortedTypeProposedModels === "1"){
                                            setSortedTypeProposedModels("2")
                                        } else {
                                            setSortedTypeProposedModels("")
                                        }
                                    }}>
                                        Field
                                        <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "field" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "field" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "field" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListSubmittedModels.size && (
                                    <td onClick={() => {
                                        setSortedColumnNameProposedModels("size")
                                        if (sortedTypeProposedModels === ""){
                                            setSortedTypeProposedModels("1")
                                        } else if (sortedTypeProposedModels === "1"){
                                            setSortedTypeProposedModels("2")
                                        } else {
                                            setSortedTypeProposedModels("")
                                        }
                                    }}>
                                        Size (GB)
                                        <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "size" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "size" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "size" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListSubmittedModels.price && (
                                    <td onClick={() => {
                                        setSortedColumnNameProposedModels("price")
                                        if (sortedTypeProposedModels === ""){
                                            setSortedTypeProposedModels("1")
                                        } else if (sortedTypeProposedModels === "1"){
                                            setSortedTypeProposedModels("2")
                                        } else {
                                            setSortedTypeProposedModels("")
                                        }
                                    }}>
                                        Price
                                        <span className="sort-icon">
                                    {sortedTypeProposedModels === "1" && sortedColumnNameProposedModels === "price" ? <FaSortUp /> : sortedTypeProposedModels === "2" && sortedColumnNameProposedModels === "price" ? <FaSortDown /> : sortedTypeProposedModels === "" || sortedColumnNameProposedModels !== "price" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                <td> </td>
                            </tr>
                            </thead>
                            <tbody>
                            {getModelsRequestStatus && (
                                <>
                                    {paginatedSubmittedModels.length > 0 && (
                                        <>
                                            {paginatedSubmittedModels.map((model, index) => (
                                                <tr key={index}>
                                                    <td className="left large">{model.authorName}</td>
                                                    {columnListSubmittedModels.email && (
                                                        <td>{model.authorEmail}</td>
                                                    )}
                                                    {columnListSubmittedModels["model name"] && (
                                                        <td>{model.name}</td>
                                                    )}
                                                    {columnListSubmittedModels["proposal date"] && (
                                                        <td>{model.submissionDate}</td>
                                                    )}
                                                    {columnListSubmittedModels.type && (
                                                        <td>{model.type}</td>
                                                    )}
                                                    {columnListSubmittedModels.field && (
                                                        <td>{model.field}</td>
                                                    )}
                                                    {columnListSubmittedModels.size && (
                                                        <td>0</td>
                                                    )}
                                                    {columnListSubmittedModels.price && (
                                                        <td>{model.submittedPrice}</td>
                                                    )}
                                                    <td className={openedArraySubMenuSubmittedModelId === model.id ? "sub-menu open" : "sub-menu"}>
                                                        <p onClick={() => openedArraySubMenuSubmittedModelId === 0 ? setOpenedArraySubMenuSubmittedModelId(model.id) : openedArraySubMenuSubmittedModelId !== model.id ? setOpenedArraySubMenuSubmittedModelId(model.id) : setOpenedArraySubMenuSubmittedModelId(0)} className="btn"><BsThreeDots /></p>
                                                        <div className="menu">
                                                            <p onClick={() => {
                                                                setViewedSubmittedModelDescriptionId(model.id)
                                                                setViewSubmittedModelDescriptionModalStatus(true)
                                                            }}>View description</p>
                                                            <p>View model</p>
                                                            <p id={model.id} onClick={acceptProposal}>Accept proposal</p>
                                                            <p id={model.id} onClick={rejectProposal}>Refuse proposal</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedSubmittedModels.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No proposed models</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getModelsRequestStatus && (
                                <tr>
                                    <td colSpan="100%"><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPage(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPage}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtons
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPage}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedSubmittedModels.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table mt">
                        <p className="title">List of accepted models</p>
                        <div className="top-container">
                            <div className="left">
                                <ShowOrHideColumnMenu resetColumn={resetAcceptedModelsColumnList} columnList={columnListAcceptedModels} setColumnList={setColumnListAcceptedModels} open={showHideStatusAcceptedModels} setOpen={setShowHideStatusAcceptedModels} />
                                <div className="search-container">
                                    <div className="box">
                                        <div className="icon"><IoSearchSharp /></div>
                                        <input type="text" placeholder="Search by map name" value={acceptedModelsSearch} onChange={(event) => setAcceptedModelsSearch(event.currentTarget.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="btn">
                                    <div className="icon"><BiHistory /></div>
                                    <div className="text">History</div>
                                </div>
                                <Export data={models.filter(m => m.status !== 0)} filename="weeki_accepted_models" />
                            </div>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td className="left large" onClick={() => {
                                    setSortedColumnNameAcceptedModels("name")
                                    if (sortedTypeAcceptedModels === ""){
                                        setSortedTypeAcceptedModels("1")
                                    } else if (sortedTypeAcceptedModels === "1"){
                                        setSortedTypeAcceptedModels("2")
                                    } else {
                                        setSortedTypeAcceptedModels("")
                                    }
                                }}>
                                    Model name
                                    <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "name" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "name" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "name" ? <FaSort /> : null}
                                    </span>
                                </td>
                                {columnListAcceptedModels.author && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("authorName")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        Author
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "authorName" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "authorName" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "authorName" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels.status && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("status")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        status
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "status" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "status" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "status" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels.type && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("type")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        Type
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "type" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "type" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "type" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels.field && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("field")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        Field
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "field" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "field" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "field" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels.size && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("size")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        Size (GB)
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "size" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "size" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "size" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels["date of creation"] && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("createdAt")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        date of creation
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "createdAt" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "createdAt" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "createdAt" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels["bought at"] && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("purchasedPrice")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        Bought at (€)
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "purchasedPrice" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "purchasedPrice" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "purchasedPrice" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels["sale price"] && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("sellingPrice")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        Sale price (€)
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "sellingPrice" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "sellingPrice" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "sellingPrice" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                {columnListAcceptedModels.sales && (
                                    <td onClick={() => {
                                        setSortedColumnNameAcceptedModels("sales")
                                        if (sortedTypeAcceptedModels === ""){
                                            setSortedTypeAcceptedModels("1")
                                        } else if (sortedTypeAcceptedModels === "1"){
                                            setSortedTypeAcceptedModels("2")
                                        } else {
                                            setSortedTypeAcceptedModels("")
                                        }
                                    }}>
                                        Sales
                                        <span className="sort-icon">
                                    {sortedTypeAcceptedModels === "1" && sortedColumnNameAcceptedModels === "sales" ? <FaSortUp /> : sortedTypeAcceptedModels === "2" && sortedColumnNameAcceptedModels === "sales" ? <FaSortDown /> : sortedTypeAcceptedModels === "" || sortedColumnNameAcceptedModels !== "sales" ? <FaSort /> : null}
                                    </span>
                                    </td>
                                )}
                                <td> </td>
                            </tr>
                            </thead>
                            <tbody>
                            {getModelsRequestStatus && (
                                <>
                                    {paginatedAcceptedModels.length > 0 && (
                                        <>
                                            {paginatedAcceptedModels.map((model, index) => (
                                                <tr key={index}>
                                                    <td className="left large">{model.name}</td>
                                                    {columnListAcceptedModels.author && (
                                                        <td>{model.authorName}</td>
                                                    )}
                                                    {columnListAcceptedModels.status && (
                                                        <td className="status-info">
                                                            {model.status === 1 && (
                                                                <span className="waiting">Waiting</span>
                                                            )}
                                                            {model.status === 2 && (
                                                                <span className="available">Available</span>
                                                            )}
                                                        </td>
                                                    )}
                                                    {columnListAcceptedModels.type && (
                                                        <td>{model.type}</td>
                                                    )}
                                                    {columnListAcceptedModels.field && (
                                                        <td>{model.field}</td>
                                                    )}
                                                    {columnListAcceptedModels.size && (
                                                        <td>0</td>
                                                    )}
                                                    {columnListAcceptedModels["date of creation"] && (
                                                        <td>{model.createdAt}</td>
                                                    )}
                                                    {columnListAcceptedModels["bought at"] && (
                                                        <td>{model.purchasedPrice}</td>
                                                    )}
                                                    {columnListAcceptedModels["sale price"] && (
                                                        <td>{model.sellingPrice === null ? "---" : model.sellingPrice}</td>
                                                    )}
                                                    {columnListAcceptedModels.sales && (
                                                        <td>{model.sales}</td>
                                                    )}
                                                    <td className={openedArraySubMenuSubmittedModelId === model.id ? "sub-menu open" : "sub-menu"}>
                                                        <p onClick={() => openedArraySubMenuSubmittedModelId === 0 ? setOpenedArraySubMenuSubmittedModelId(model.id) : openedArraySubMenuSubmittedModelId !== model.id ? setOpenedArraySubMenuSubmittedModelId(model.id) : setOpenedArraySubMenuSubmittedModelId(0)} className="btn"><BsThreeDots /></p>
                                                        <div className="menu">
                                                            <p>View model</p>
                                                            <p onClick={() => {
                                                                setUpdatedModelId(model.id)
                                                                setUpdateAcceptedModelStatus(false)
                                                                setUpdateAcceptedModelModalStatus(true)
                                                            }}>Modify attributes</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    {paginatedAcceptedModels.length < 1 && (
                                        <tr>
                                            <td style={{'textAlign': "center"}} colSpan="100%">No accepted models</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            {!getModelsRequestStatus && (
                                <tr>
                                    <td colSpan="100%"><TailSpin height="64" width="64" color="#000" /></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <div className="row-selector">
                                <div className="text">Rows per page :</div>
                                <select name="rows" onChange={(event) => setItemPerPageAcceptedModel(parseInt(event.currentTarget.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="page-selector">
                                <div className="arrow-btn" onClick={previousPageAcceptedModel}><FaChevronLeft /></div>
                                <div className="btn-container">
                                    {pageButtons.length > 0 && (
                                        pageButtonsAcceptedModel
                                    )}
                                    {pageButtons.length === 0 && (
                                        <p className="selected">0</p>
                                    )}
                                </div>
                                <div className="arrow-btn" onClick={nextPageAcceptedModel}><FaChevronRight /></div>
                            </div>
                            <div className="page-infos">
                                Showing {start + 1} to {end} of {paginatedAcceptedModels.length} rows
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="top-btn" onClick={() => dashboard.scrollTo(0, 0)}>
                            <p><FaChevronUp /></p>
                        </div>
                        <p className="copy">&copy; Weeki 2021</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboardStore