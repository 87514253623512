import {useMapStore} from "../Stores";
import {useContentItemStore} from "../Stores/ContentItemStore";
import {generateLevel1Popup} from "./MapPopupUtils";
import {addLevel1PopupEvents} from "./PopupEventsUtils";

export function detectHoverLevel1Item (selection){
	return;
	//Check if map is public
	if (useMapStore.getState().publicMap){
		return
	}

	//Check if level 1 item is not in drag
	if (useContentItemStore.getState().hoveredLevel1DragState){
		return;
	}

	//Get feature from selection
	const feature = selection.feature

	//Check if feature is hovered
	if (feature){
		//Check if hovered feature is lvl 1 item
		if (feature.layers.includes('weeki_level1_items:global')){
			//Check if hovered feature is not already hovered
			if (useContentItemStore.getState().currentHoveredLevel1 !== feature.properties.id){
				//Clear lvl 1 hovered timeout if exist
				if (useContentItemStore.getState().hoveredLevel1Timeout){
					clearTimeout(useContentItemStore.getState().hoveredLevel1Timeout)
					useContentItemStore.setState({hoveredLevel1Timeout: null})
				}

				//Remove hovered level1 mock if exist
				if (useContentItemStore.getState().hoveredLevel1Mock){
					useContentItemStore.getState().hoveredLevel1Mock.remove()
					useContentItemStore.setState({hoveredLevel1Mock: null})
				}

				//Set hovered feature in store
				useContentItemStore.setState({currentHoveredLevel1: feature.properties.id})

				//Set time out before generate hovered level 1 mock
				const timeout = setTimeout(() => generateHoveredLevel1Mock(feature), 100)

				//Set open timeout in store
				useContentItemStore.setState({hoveredLevel1Timeout: timeout})

				return;
			} else {
				return;
			}
		}
	}

	clearHoveredLevel1()
}

function generateHoveredLevel1Mock(feature){
	//Generate hovered level 1 mock and open on map
	const mock = generateLevel1Popup([feature.properties.lat, feature.properties.long], feature.properties.icon_code)

	//Add event on mock
	addLevel1PopupEvents(mock, feature)

	//Set mock in store
	useContentItemStore.setState({hoveredLevel1Mock: mock})
}

export function clearHoveredLevel1(){
	//Clear lvl 1 hovered timeout if exist
	if (useContentItemStore.getState().hoveredLevel1Timeout){
		clearTimeout(useContentItemStore.getState().hoveredLevel1Timeout)
		useContentItemStore.setState({hoveredLevel1Timeout: null})
	}

	//Remove hovered level1 mock if exist
	if (useContentItemStore.getState().hoveredLevel1Mock){
		useContentItemStore.getState().hoveredLevel1Mock.remove()
		useContentItemStore.setState({hoveredLevel1Mock: null})
	}

	//Remove current hovered level 1 in store
	useContentItemStore.setState({currentHoveredLevel1: null})
}