import {ADMIN_HISTORY_API} from "../../Router";
import getAxiosInstance from "./AxiosInstance";

function getAllHistoryInfos(){
    return getAxiosInstance().get(ADMIN_HISTORY_API + "/get_all_history_infos")
}

function getHistoryInfosByType(historyType, startOffset, filter = [], startDate = "", endDate = ""){
    const sendData = {
        "historyType": historyType,
        "startOffset": startOffset
    }

    if (filter){
        for (let i = 0; i < filter.length; i++){
            if (filter[i].inputValue !== ""){
                sendData[filter[i].type] = filter[i].inputValue
            }
        }
    }

    if (startDate !== "" && endDate !== ""){
        sendData['startDate'] = startDate
        sendData['endDate'] = endDate
    }

    return getAxiosInstance().get(ADMIN_HISTORY_API + "/get_history_by_type", {
        params: sendData
    })
}

export default {
    getAllHistoryInfos,
    getHistoryInfosByType
}