import getAxiosInstance from "../../AxiosInstance";
import {USER_AREA_API} from "../../../../Router";

function userCheckAreaContent(uuid){
	const sendData = new FormData()
	sendData.append("areaUuid", uuid)

	return getAxiosInstance().post(USER_AREA_API + '/check_content', sendData)
		.then((response) => {
			if (response.status === 200){
				return response.data.content
			}

			return null
		})
		.catch(() => null)
}

function userDeleteArea(uuid, withContent){
	return getAxiosInstance().delete(USER_AREA_API + '/delete', {params: {areaUuid: uuid, deleteContent: withContent}})
		.then((response) => {
			console.log(response.data)
			return true
		})
		.catch(() => false)
}

function userMoveAreaToOverlay(areaUuid, overlayUuid, withContent){
	const sendData = new FormData()
	sendData.append('areaUuid', areaUuid)
	sendData.append('overlayUuid', overlayUuid)
	sendData.append('withContent', withContent)

	return getAxiosInstance().post(USER_AREA_API + '/move_to_overlay', sendData)
		.then((response) => {
			return response.status === 200
		})
		.catch(() => false)
}

export default {
	userCheckAreaContent,
	userDeleteArea,
	userMoveAreaToOverlay
}