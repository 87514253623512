import {ADMIN_SUPPORT_TICKET_API, SUPPORT_TICKET_API} from "../../Router";
import {toast} from "react-toastify";
import getAxiosInstance from "./AxiosInstance";

function getAllTickets(){
    return getAxiosInstance().get(SUPPORT_TICKET_API + "/get_all")
}

function adminGetAllTickets(){
    return getAxiosInstance().get(ADMIN_SUPPORT_TICKET_API + "/get_all")
}

function adminGetTicketByUuid(ticketUuid){
    return getAxiosInstance()
        .get(ADMIN_SUPPORT_TICKET_API + "/get_by_uuid", {params: {ticketUuid: ticketUuid}})
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
        })
}

function getTicketByUuid(ticketUuid){
    return getAxiosInstance()
        .get(SUPPORT_TICKET_API + "/get_by_uuid", {params: {ticketUuid: ticketUuid}})
        .catch((error) => {
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                }
            }
        })
}

function openSupportTicket(title, message){
    toast.loading("Support ticket opening in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append('title', title)
    sendData.append('message', message)

    return getAxiosInstance()
        .post(SUPPORT_TICKET_API + "/open_ticket", sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            return false
        })
}

function sendTicketResponse(ticketUuid, message){
    toast.loading("Support ticket response sending in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append('ticketUuid', ticketUuid)
    sendData.append('message', message)

    return getAxiosInstance()
        .post(SUPPORT_TICKET_API + "/send_response", sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            return false
        })
}

function adminSendTicketResponse(ticketUuid, message){
    toast.loading("Support ticket response sending in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append('ticketUuid', ticketUuid)
    sendData.append('message', message)

    return getAxiosInstance()
        .post(ADMIN_SUPPORT_TICKET_API + "/send_response", sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            return false
        })
}

function adminCloseTicket(ticketUuid){
    toast.loading("Support ticket close in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append('_method', "PUT")
    sendData.append('ticketUuid', ticketUuid)

    return getAxiosInstance()
        .post(ADMIN_SUPPORT_TICKET_API + "/close_ticket", sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            return false
        })
}

function adminReopenTicket(ticketUuid){
    toast.loading("Support ticket reopen in progress", {toastId: "loadAlert"})

    const sendData = new FormData()
    sendData.append('_method', "PUT")
    sendData.append('ticketUuid', ticketUuid)

    return getAxiosInstance()
        .post(ADMIN_SUPPORT_TICKET_API + "/reopen_ticket", sendData)
        .then((response) => {
            toast.dismiss('loadAlert')
            return response.status === 200;
        })
        .catch((error) => {
            toast.dismiss('loadAlert')
            if (error.response){
                if (error.response.status === 400){
                    toast.error(error.response.data.error)
                    return false
                }
            }
            return false
        })
}

export default {
    getAllTickets,
    adminGetAllTickets,
    openSupportTicket,
    adminGetTicketByUuid,
    adminSendTicketResponse,
    adminCloseTicket,
    adminReopenTicket,
    sendTicketResponse,
    getTicketByUuid
}

